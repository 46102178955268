import React, { useState, useEffect } from "react";

export function useForm(initial, callback) {
  const [values, setValues] = useState(initial);

  const onChange = (e) => {
    e.persist();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    callback(values);
  };

  useEffect(() => {
    return () => setValues({});
  }, []);

  return [values, onChange, onSubmit, setValues];
}

export default useForm;
