import React, { useState, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import {
  ButtonMenu,
  MenuItem,
  Modal,
  useModal,
  ActionButtons,
  useTableOverflow,
  Field,
} from "@plan";
import cx from "classnames";
import moment from "moment";
import copy from "copy-to-clipboard";

export const Subscribed = ({ value }) => {
  const text = value ? "Yes" : "No";
  const classes = cx("admin-table__subscription", { "is-subscribed": value });
  return (
    <div style={{ display: "flex", alignContent: "center" }}>
      <span className={classes} style={{ marginTop: ".25rem" }} />
      {text}
    </div>
  );
};

export const trialEnd = (endDate) => {
  const today = moment(new Date());
  if (today.isAfter(endDate)) return 0;

  const daysRemaining = today.diff(endDate);
  return moment(daysRemaining).format("D");
};

export const DatePicker = ({ date, onChange }) => {
  const { overflow } = useTableOverflow();
  const [focus, setFocus] = useState(null);
  const handleFocus = useCallback(
    ({ focused }) => setFocus(focused),
    [setFocus]
  );

  const dateObject = date ? moment(date) : null;

  return (
    <Field
      as="date"
      size="small"
      date={dateObject}
      onDateChange={onChange}
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      placeholder="No Date"
      withPortal={!!overflow}
    />
  );
};

const UPDATE_ORGANIZATION_TRIAL = gql`
  mutation UpdateOrganizationTrialMutation($id: ID!, $trialEndDate: String!) {
    admin {
      updateOrganizationTrial(input: { id: $id, trialEndDate: $trialEndDate }) {
        organization {
          id
          trialEndDate
        }
      }
    }
  }
`;

export const Trial = ({ id, trialEndDate }) => {
  const [updateOrganizationTrial] = useMutation(UPDATE_ORGANIZATION_TRIAL);

  return (
    <>
      <DatePicker
        date={trialEndDate}
        onChange={(date) =>
          updateOrganizationTrial({
            variables: { id, trialEndDate: moment(date).format("YYYY-MM-DD") },
          })
        }
      />
    </>
  );
};

const UPDATE_ORGANIZATION_STATUS = gql`
  mutation UpdateOrganizationStatusMutation(
    $id: ID!
    $status: OrganizationStatusEnum!
  ) {
    admin {
      updateOrganizationStatus(input: { id: $id, status: $status }) {
        organization {
          id
          status
        }
      }
    }
  }
`;

export const OnBoarding = ({ id, status }) => {
  const [updateOrganizationStatus] = useMutation(UPDATE_ORGANIZATION_STATUS);

  return (
    <select
      className={`admin-table__status${
        status === "booked"
          ? " is-booked"
          : status === "completed"
          ? " is-completed"
          : ""
      }`}
      onChange={(e) =>
        updateOrganizationStatus({ variables: { id, status: e.target.value } })
      }
      name="admin[status]"
      id="admin_status"
      value={status?.toUpperCase() || ""}
    >
      <option value="NOT_BOOKED">1. not booked</option>
      <option value="BOOKED">2. booked</option>
      <option value="COMPLETED">3. completed</option>
    </select>
  );
};

const UPDATE_ORGANIZATION_QUALIFIED = gql`
  mutation UpdateOrganizationQualifiedMutation(
    $id: ID!
    $isQualified: Boolean!
  ) {
    admin {
      updateOrganizationQualified(
        input: { id: $id, isQualified: $isQualified }
      ) {
        organization {
          id
          isQualified
        }
      }
    }
  }
`;

export const Qualified = ({ id, isQualified }) => {
  const [updateOrganizationQualified] = useMutation(
    UPDATE_ORGANIZATION_QUALIFIED,
    {
      update: (cache) => cache.evict({ id: `Organization:${id}` }),
    }
  );

  const buttonText = isQualified ? "Disqualify" : "Qualify";
  return (
    <button
      type="button"
      onClick={() =>
        updateOrganizationQualified({
          variables: { id, isQualified: !isQualified },
        })
      }
    >
      {buttonText}
    </button>
  );
};

const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganizationMutation($id: ID!) {
    admin {
      deleteOrganization(input: { id: $id }) {
        organization {
          id
        }
      }
    }
  }
`;

export const Delete = ({ id, isQualified }) => {
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION, {
    update: (cache) => cache.evict({ id: `Organization:${id}` }),
  });

  const [isOpen, modalActions] = useModal();

  const handleDelete = () => {
    deleteOrganization({ variables: { id } });
    modalActions.close();
  };

  return (
    <button type="button" onClick={modalActions.open}>
      Destroy
      <Modal
        isOpen={isOpen}
        actions={modalActions}
        title="Delete this organization?"
        subHeading="This action is permanent"
      >
        <ActionButtons
          buttons={[
            {
              buttonStyle: "cancel",
              text: "Cancel",
              color: "outline",
              action: modalActions.close,
            },
            {
              buttonStyle: "action",
              buttonType: "submit",
              color: "delete",
              text: "Delete",
              action: handleDelete,
            },
          ]}
        />
      </Modal>
    </button>
  );
};

export function Actions({ id, demoId, isQualified }) {
  return (
    <ButtonMenu>
      <MenuItem>
        <a href={`/admin/set_organization_id?organization_id=${demoId}`}>
          View Demo
        </a>
      </MenuItem>
      <MenuItem>
        <button onClick={() => copy(id)}>Copy ID ({id})</button>
      </MenuItem>
      <MenuItem>
        <Qualified id={id} isQualified={isQualified} />
      </MenuItem>
      {!isQualified && (
        <MenuItem>
          <Delete id={id} isQualified={isQualified} />
        </MenuItem>
      )}
    </ButtonMenu>
  );
}
