import React, { useEffect, useRef, useState, useCallback } from "react";
import { string } from "prop-types";
import { createPortal } from "react-dom";
import { Toggle } from "@plan";
import { Exclamation } from "Components/blocks/icons";
import { gql } from "@apollo/client";
import classNames from "classnames/bind";
import { useApollo } from "../hooks/useApollo";
import styles from "./InvoiceSwitchBanner.module.css";
import InvoiceSpinner from "./InvoiceSpinner";

const cx = classNames.bind(styles);

const ChangeVersionDoc = gql`
  mutation InvoiceBuilderChangeInvoiceVersion(
    $input: InvoiceChangeVersionInput!
  ) {
    changeInvoiceVersion(input: $input) {
      invoice {
        id
        slug
        invoiceVersion
      }
    }
  }
`;

const learnMoreUrl = "https://support.monograph.com/en/articles/5982576";

const InvoiceSwitchVersion = ({ slug }) => {
  const [mounted, setMounted] = useState(false);
  const nodeRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const client = useApollo();

  const onChange = useCallback(() => {
    setLoading(true);
    client
      .mutate({
        mutation: ChangeVersionDoc,
        variables: {
          input: {
            slug,
            invoiceVersion: 2,
          },
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.changeInvoiceVersion.invoice.invoiceVersion === 2
        ) {
          location.href = `/invoices/v2/${slug}`;
        } else {
          throw new Error(res.error);
        }
      })
      .catch(() => {
        setLoading(false);
        // TODO handle error
      });
  }, [client]);

  useEffect(() => {
    const id = "switch-banner-portal";
    const parent = document.querySelector("main.content");
    if (!parent) return;
    const node = document.getElementById(id) || document.createElement("div");
    node.id = id;
    node.id = id;
    nodeRef.current = node;
    parent.prepend(node);
    setMounted(true);
    // eslint-disable-next-line consistent-return
    return () => {
      parent.removeChild(nodeRef.current);
    };
  }, []);
  if (!mounted) return null;

  return createPortal(
    <div className={cx("invoiceSwitchBanner")}>
      <div className={cx("content")}>
        <div className={cx("title")}>
          <Exclamation />
          New Faster Invoicing
        </div>
        <div className={cx("body")}>
          Switch for more streamlined invoice creation, enhanced invoice design,
          and real-time financial reports.{" "}
          <a
            href={learnMoreUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={cx("link")}
          >
            Learn More
          </a>
        </div>
      </div>
      <div>
        {loading ? (
          <InvoiceSpinner />
        ) : (
          <Toggle label="New version" value={false} onChange={onChange} />
        )}
      </div>
    </div>,
    nodeRef.current
  );
};

InvoiceSwitchVersion.propTypes = {
  slug: string.isRequired,
};

export default InvoiceSwitchVersion;
