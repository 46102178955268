import React from "react";
import { useAction } from "Hooks";
import {
  deletePhase as deletePhaseAction,
  deletePhaseWarnings,
} from "Components/api";
import {
  DeleteConfirmation,
  useDeleteConfirmation,
} from "Components/blocks/alert/DeleteWarnings";
import Alert from "Components/blocks/alert/Alert";
import Message from "Components/blocks/alert/Message";
import Modal from "Components/blocks/modal/Modal";
import ActionButtons from "Components/blocks/buttons/ActionButtons";

export function RemovePhaseModal({ isOpen, actions, phase, id, handleDelete }) {
  const [{ loading, data, error }, deletePhase] = useAction(deletePhaseAction);

  const deleteAction = async () => {
    await deletePhase(id);
    handleDelete(id);
    return actions.close();
  };

  const [canDelete, confirmation, onSubmit] = useDeleteConfirmation({
    matchPhrase: phase,
    deleteAction,
  });

  return (
    <Modal
      isOpen={isOpen}
      actions={actions}
      title="Delete Phase"
      subHeading={phase}
      size="medium"
    >
      <Alert
        message="Deleting this phase will delete all related data and entries."
        variant="warning"
      />
      <form onSubmit={onSubmit}>
        <DeleteConfirmation
          id={id}
          fetchAction={deletePhaseWarnings}
          confirmation={confirmation}
          noDataText="No roles or entries associated with this Phase"
        />
        <Message
          message="Warning: this action cannot be undone"
          variant="warning"
        />
        <ActionButtons
          buttons={[
            {
              buttonStyle: "cancel",
              text: "Cancel",
              action: actions.close,
            },
            {
              buttonStyle: "delete",
              buttonType: "submit",
              text: loading ? "Removing..." : "Delete",
              disabled: !canDelete || loading,
              action: onSubmit,
            },
          ]}
        />
      </form>
    </Modal>
  );
}

export default React.memo(RemovePhaseModal);
