import {
  createComponent,
  createScaleVariant,
  keyframes,
  styled,
} from "@plan/core";

const component = createComponent();

export const spinnerSize = 15;
export const spinnerThickness = 1.5;
export const spinnerDelta = 0.1;
export const spinnerLength = Math.PI * (spinnerSize - spinnerThickness);

export const spinnerAnimation = keyframes({
  "0%": {
    strokeDasharray: `${(1 - spinnerDelta) * spinnerLength}, ${
      spinnerDelta * spinnerLength
    }`,
    strokeDashoffset: 0,
  },
  "50%": {
    strokeDasharray: `${spinnerDelta * spinnerLength}, ${
      (1 - spinnerDelta) * spinnerLength
    }`,
    strokeDashoffset: `${spinnerLength}`,
  },
  "100%": {
    strokeDasharray: `${(1 - spinnerDelta) * spinnerLength}, ${
      spinnerDelta * spinnerLength
    }`,
    strokeDashoffset: `${2 * spinnerLength}`,
  },
});

export const SpinnerCircle = styled("circle", {
  fill: "none",
  strokeWidth: `${spinnerThickness}`,
  animation: `${spinnerAnimation} 2000ms infinite ease-in-out`,
  strokeDasharray: `${(1 - spinnerDelta) * spinnerLength}, ${
    spinnerDelta * spinnerLength
  }`,
  strokeDashoffset: 0,
  stroke: "$$spinnerStrokeColor",
});

export const size = createScaleVariant({
  scale: "sizes",
  css: (token) => ({
    $$spinnerSize: token,
  }),
});

export const SpinnerContainer = styled("span", {
  ...component,
  fontSize: 0,
  variants: {
    ...component.variants,
    color: {
      /**
       * Note: Required colors are hard-coded until we have an `intent` strategy
       *       agreed with design.
       */
      brand: {
        $$spinnerStrokeColor: "$colors$brand",
      },
      "-neutral40": {
        $$spinnerStrokeColor: "$colors$-neutral40",
      },
      success: {
        $$spinnerStrokeColor: "$colors$success",
      },
    },
    size,
  },
  defaultVariants: {
    ...component.defaultVariants,
    color: "brand",
    size: "lg",
  },
});

export const SpinnerSvg = styled("svg", {
  width: "$$spinnerSize",
  height: "$$spinnerSize",
});
