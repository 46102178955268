import React from "react";

import { createComponent, styled } from "@plan/core";

import type * as Polymorphic from "@radix-ui/react-polymorphic";
// https://www.radix-ui.com/docs/primitives/components/toggle-group
import * as ToggleGroupBase from "@radix-ui/react-toggle-group";

const component = createComponent();

const StyledToggleGroup = styled(ToggleGroupBase.Root, {
  ...component,
  display: "flex",
});

const StyledToggleItem = styled(ToggleGroupBase.Item, {
  ...component,
  $$borderWidth: "$borderWidths$1",
  $$borderColor: "$colors$-neutral20",
  $$borderColorError: "$colors$danger10",
  $$borderColorHover: "$colors$-neutral10",
  $$border: "$$borderWidth solid $$borderColor",
  border: "$$border",
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "baseline",
  background: "$white",
  width: "100%",
  textStyle: "large",
  padding: "$space$3",
  color: "$colors$neutral40",
  transitionDuration: "150ms",
  transitionProperty: "color, background-color, border-color",
  transitionTimingFunction: "$transitions$cb",
  justifyContent: "center",
  "&:disabled": {
    cursor: "not-allowed",
    background: "$colors$-neutral40",
    color: "$colors$-neutral10",
  },
  "&:hover, &:focus": {
    borderColor: "$$borderColorHover",
    zIndex: 1, // Accounts for the negative margin
  },
  "&:hover&:disabled": {
    border: "$$border",
  },
  "&:first-child": {
    borderRadius: "$radii$default 0 0 $radii$default",
  },
  "&:last-child": {
    borderRadius: "0 $radii$default $radii$default 0",
  },
  '&[data-state="on"]': {
    backgroundColor: "$colors$brand",
    borderColor: "$colors$brand",
    color: "$white",
  },
  "& + &": {
    marginLeft: "-$$borderWidth",
  },
});

type ToggleGroupProps = React.ComponentProps<typeof StyledToggleGroup> &
  Polymorphic.OwnProps<typeof ToggleGroupBase.Root>;

export const ToggleGroup = ({ ...props }: ToggleGroupProps) => (
  <StyledToggleGroup {...props} />
);

type ToggleItemProps = React.ComponentProps<typeof StyledToggleItem> &
  Polymorphic.OwnProps<typeof ToggleGroupBase.ToggleGroupItem>;

export const ToggleItem = ({ ...props }: ToggleItemProps) => (
  <StyledToggleItem {...props} />
);
