import React from "react";
import cx from "classnames";
import styles from "./alert.module.css";

export function Alert({ variant, message, children, style, className }) {
  return (
    <div
      className={cx(styles.alert, { [styles[variant]]: variant }, className)}
      style={style}
    >
      {message}
      {children}
    </div>
  );
}

Alert.defaultProps = {
  variant: "warning",
};

export default React.memo(Alert);
