import React from "react";
import { Modal, useModal, Message, ActionButtons } from "@plan";
import { useMutation } from "@apollo/client";
import { Close } from "Components/blocks/icons";
import { motion } from "framer-motion";
import { renderError } from "Blocks/alert";
import { usePageStore } from "../Consultants";
import styles from "../consultants.module.css";
import { DELETE_CONSULTANT } from "../queries";

export const Delete = ({ name, id, disabled }) => {
  const { isTemplate } = usePageStore();
  const [isOpen, actions] = useModal(false);

  // If this is a template, we need to refetch the projectTemplateConsultants query; otherwise it's the projectConsultants query
  const refetchQueries = isTemplate
    ? ["projectTemplateConsultants"]
    : ["projectConsultants"];

  const [deleteConsultant, { loading }] = useMutation(DELETE_CONSULTANT, {
    refetchQueries,
    onError: () => renderError(),
  });

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.1 }}
        type="button"
        onClick={actions.open}
        className={styles.iconButton}
        disabled={disabled}
      >
        <Close />
      </motion.button>
      <Modal title={`Delete: ${name}`} actions={actions} isOpen={isOpen}>
        <form
          onSubmit={() => deleteConsultant({ variables: { input: { id } } })}
        >
          <Message
            message="Warning: this action cannot be undone"
            variant="warning"
          />
          <ActionButtons
            buttons={[
              {
                color: "cancel",
                text: "Cancel",
                action: actions.close,
              },
              {
                color: "delete",
                buttonType: "submit",
                text: "Delete",
                disabled: loading,
                action: () =>
                  deleteConsultant({ variables: { input: { id } } }),
              },
            ]}
          />
        </form>
      </Modal>
    </>
  );
};
