import React from "react";

export default function StatusProposed(classes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <circle cx="16" cy="16" r="16" fill="#F7F9FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 12C14 11.4477 14.4477 11 15 11H16C16.5523 11 17 11.4477 17 12V15.5845C17 15.9358 17.1843 16.2613 17.4855 16.442L20.4143 18.1993C20.8878 18.4834 21.0414 19.0977 20.7572 19.5713L20.2428 20.4287C19.9586 20.9023 19.3443 21.0559 18.8708 20.7717L14.4855 18.1406C14.1843 17.9599 14 17.6344 14 17.2831V12Z"
        fill="#DFE2E4"
      />
    </svg>
  );
}
