import * as React from "react";

type ModalTrapContextValue = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

export const ModalTrapContext = React.createContext<ModalTrapContextValue>(
  {} as ModalTrapContextValue
);

export const ModalTrapProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = React.useState(false);
  return (
    <ModalTrapContext.Provider value={value}>
      {children}
    </ModalTrapContext.Provider>
  );
};
