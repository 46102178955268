// This can be removed when new planner launches!
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Segment from "Components/blocks/Segment";
import {
  Field,
  useModal,
  Modal,
  ActionButtons,
  Button,
  HStack,
  FlexWrapper,
} from "@plan";
import { useCreateProject, useProjectTemplates } from "Components/api";
import { useColorOptions, useUrlParams } from "@hooks";
import moment from "moment";
import numeral from "numeral";
import eventTracker from "Blocks/tracker.js";
import { AlertIcon } from "./AlertIcon.jsx";
import { ContactAdminModal, UpgradeModal } from "./modals";
import ColorSelectOption from "./ColorSelectOption";
import styles from "./createProject.module.css";

const formatDollars = (value) => numeral(value).format("0.00");

export const CreateProjectModal = ({
  fromTemplateId,
  organizationId,
  colors,
  isOpen,
  actions,
}) => {
  const { templates, templateOptions } = useProjectTemplates();
  const { colorOptions, randomColor } = useColorOptions(colors);
  const [loading, setLoading] = useState(false);
  const [, createProject] = useCreateProject({
    renderErrors: true,
  });

  const initialForm = {
    name: "",
    template: "",
    number: "",
    totalFee: "",
    billable: true,
    color: randomColor,
    beginDate: moment(),
  };

  const [form, setForm] = useState(initialForm);

  const updateFormValue = (key, value) =>
    setForm({
      ...form,
      [key]: value,
    });

  const handleDateChange = (e) => {
    if (!e) return updateFormValue("beginDate", null);
    updateFormValue("beginDate", moment(e));
  };

  // Reset the form when modal is closed
  useEffect(() => {
    if (!isOpen) setForm(initialForm);
  }, [isOpen]);

  // If we've passed a templateId we'll prepoluate the
  // template select dropdown
  useEffect(() => {
    if (fromTemplateId && templates) {
      const selectedTemplate = templateOptions.find(
        (template) => template.id === fromTemplateId
      );
      updateFormValue("template", selectedTemplate);
    }
  }, [templates, fromTemplateId, isOpen]);

  useEffect(() => {
    setForm({
      ...form,
      totalFee: form?.template?.totalFee ?? "",
      billable: form?.template?.isBillable ?? true,
    });
  }, [form?.template?.id]);

  const onSubmit = async () => {
    const beginDate = (() => {
      if (!form?.template?.id) return null; // Don't pass date if user doesn't select a template
      if (form.beginDate) return moment(form.beginDate).format("YYYY-MM-DD"); // if the date is filled out, use it
      return moment().format("YYYY-MM-DD"); // fallback to "today"
    })();

    const data = {
      project: {
        organization_id: organizationId,
        name: form.name.trim(),
        is_billable: form.billable,
        number: form.number,
        color: form.color?.value,
        begin_date: beginDate,
        total_fee: form.totalFee,
      },
      // Only pass template and begin date if a template is selected
      template_id: form.template?.id ? form.template.id : null,
    };
    try {
      setLoading(true);
      const { slug } = await createProject(data);
      window.location.href = `/projects/${slug}/edit/planner`;
    } catch (err) {
      setLoading(false);
      throw new Error("There was an issue creating a project");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      actions={actions}
      title="Create a New Project"
      size="medium"
    >
      <div className={styles.formWrapper}>
        <Field
          as="text"
          label="Project Name"
          name="name"
          placeholder=""
          value={form.name}
          handleChange={(e) => updateFormValue("name", e.target.value)}
        />
        <HStack gap="1.5rem">
          <div style={{ width: "30%" }}>
            <Field
              label="Number"
              value={form.number}
              handleChange={(e) => updateFormValue("number", e.target.value)}
            />
          </div>
          <div style={{ width: "70%" }}>
            <Field
              as="select"
              label="Project Color"
              options={colorOptions}
              formatOptionLabel={ColorSelectOption}
              value={form.color}
              handleChange={(e) => updateFormValue("color", e)}
            />
          </div>
        </HStack>
        <Field
          as="select"
          label="Template"
          placeholder="Select a Template (Optional)"
          options={templateOptions}
          value={form.template}
          handleChange={(e) => updateFormValue("template", e)}
          isClearable
        />
        {form.template?.value && (
          <HStack gap=".75rem" style={{ width: "max-content" }}>
            <Field
              as="date"
              label="Start Date"
              date={form.beginDate}
              name="begin_date"
              size="large"
              iconPosition="right"
              onDateChange={handleDateChange}
              showClearDates
              required
            />
            <Field
              as="number"
              format="currency"
              label="Total Fee"
              value={form.totalFee}
              placeholder="0.00"
              name="total_fee"
              step=".01"
              handleChange={(e) => updateFormValue("totalFee", e.target.value)}
              handleFocus={(e) =>
                updateFormValue("totalFee", formatDollars(e.target.value))
              }
              size="large"
              required
              noFloatingLabel
            />
          </HStack>
        )}
        <Segment
          options={[
            { name: "Billable", value: true },
            { name: "Non-Billable", value: false },
          ]}
          selectedValue={form.billable}
          onSelect={(value) => updateFormValue("billable", value)}
        />

        <ActionButtons
          buttons={[
            {
              color: "cancel",
              text: "Cancel",
              action: actions.close,
            },
            {
              color: "action",
              buttonType: "submit",
              text: "Create",
              disabled: loading || form.name.length < 1,
              action: onSubmit,
            },
          ]}
        />
      </div>
    </Modal>
  );
};

// TODO: This logic should be split out
// (shouldn't have the button conditionals – they should be their own components)
// we will be remaking this for new accounts so leaving it as is for now.
// eslint-disable-next-line react/prop-types
export default function CreateProject({
  organizationId,
  newAccount,
  colors,
  policyIsAdmin,
  stripePubKey,
  projectsCount,
  projectLimit,
  projectCreationDisabled,
  hasPermission,
}) {
  const url = useUrlParams();

  const [modal, modalActions] = useModal(url.has("addProject"));
  const [upgradeIsOpen, upgradeModalActions] = useModal(false);

  function navigateNewCreate() {
    window.location.href = "/projects/create";
  }

  const upgradeModalTitle = "Need more projects?";
  const pathString = "/projects";

  const openUpgradeModal = () => {
    eventTracker("trackPlanModalDisplay", { upgradeModalTitle, pathString });
    upgradeModalActions.open();
  };

  const projectBundleonSubmit = async (projectAddOn) => {
    const stripeKey = stripePubKey || "";
    const stripe = await loadStripe(stripeKey);

    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Project bundle",
      buttonText: "Purchase now",
      planIntervalSetting: null,
    });

    fetch(`/stripe/checkout?add_on=${projectAddOn}`)
      .then((response) => response.json())
      .then((json) => {
        if (stripe) {
          stripe
            .redirectToCheckout({
              sessionId: json.session_id,
            })
            .then((result) => {
              console.log(result);
            });
        }
      });
  };

  const upgradePlanOnSubmit = async () => {
    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Grow",
      buttonText: "Upgrade to Grow",
      planIntervalSetting: null,
    });

    eventTracker("trackModalGrowUpgradeClick", { pathString });

    fetch("/stripe/billing.json", { method: "POST" })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = json.portal_url;
      });
  };

  const projectsCountText = projectsCount === null ? "0" : projectsCount;

  const projectLimitText = () => {
    if (projectLimit) {
      if (projectCreationDisabled) {
        return (
          <div>
            <span className={styles.alert}>
              <AlertIcon />
            </span>
            {projectsCountText} of {projectLimit} projects used
          </div>
        );
      }
      return `${projectsCountText} of ${projectLimit} projects used`;
    }
    return "";
  };

  const disabledNewButton = (
    <button
      type="button"
      className="header-actions__button header-actions__button--primary"
      onClick={() => openUpgradeModal()}
    >
      Get more projects
    </button>
  );
  const enabledNewButton = (
    <button
      type="button"
      className="header-actions__button header-actions__button--primary"
      onClick={() => navigateNewCreate()}
    >
      New Project
    </button>
  );
  const newButton = projectCreationDisabled
    ? disabledNewButton
    : enabledNewButton;

  const upgradeModal = () => {
    if (policyIsAdmin || hasPermission) {
      return (
        <UpgradeModal
          projectBundleonSubmit={projectBundleonSubmit}
          upgradePlanOnSubmit={upgradePlanOnSubmit}
          upgradeIsOpen={upgradeIsOpen}
          upgradeModalActions={upgradeModalActions}
          upgradeModalTitle={upgradeModalTitle}
        />
      );
    }
    if (policyIsAdmin === false || hasPermission === false) {
      return (
        <ContactAdminModal
          upgradeIsOpen={upgradeIsOpen}
          upgradeModalActions={upgradeModalActions}
          projectLimit={projectLimit}
        />
      );
    }
    if (policyIsAdmin === undefined) {
      return null;
    }
  };

  return (
    <>
      {newAccount ? (
        <Button
          variant="default"
          color="action"
          action={() => {
            navigateNewCreate();
            return false;
          }}
          text="Create a Project"
        />
      ) : (
        <FlexWrapper>
          <button
            type="submit"
            className="project-limit__button"
            onClick={() => openUpgradeModal()}
          >
            {projectLimitText()}
          </button>
          {newButton}
        </FlexWrapper>
      )}
      <CreateProjectModal
        isOpen={modal}
        actions={modalActions}
        colors={colors}
        organizationId={organizationId}
      />
      {upgradeModal()}
    </>
  );
}
