import {
  OnboardingEvent,
  useLogOnboardingEventMutation,
} from "../../../../../apps/web/packages/app/generated/graphql";

export const useLogOnboardingEvent = () => {
  const [logOnboardingEvent] = useLogOnboardingEventMutation({
    variables: {
      event: OnboardingEvent.UsedTimer,
    },
    update: (cache) => {
      cache.modify({
        fields: {
          onboarding(existingOnboarding) {
            const currentUrl = new URL(window.location.href);
            const onGettingStarted =
              currentUrl.pathname.startsWith("/get-started");

            if (!onGettingStarted) return existingOnboarding;

            return { ...existingOnboarding, usedTimer: true };
          },
        },
      });
    },
  });

  return logOnboardingEvent;
};
