import { getLuminance } from "./getLuminance";
import { hexToRgb } from "./hexToRgb";

const BLACK = "#000";
const WHITE = "#fff";

export const getAccessibleTextColor = (hexcolor: string) => {
  const { r, g, b } = hexToRgb(hexcolor);

  const colorLuminance = getLuminance(r, g, b);
  const blackLuminance = getLuminance(0, 0, 0);
  const whiteLuminance = getLuminance(255, 255, 255);

  const ratioWhite =
    colorLuminance > whiteLuminance
      ? (whiteLuminance + 0.05) / (colorLuminance + 0.05)
      : (colorLuminance + 0.05) / (whiteLuminance + 0.05);

  const ratioBlack =
    colorLuminance > blackLuminance
      ? (blackLuminance + 0.05) / (colorLuminance + 0.05)
      : (colorLuminance + 0.05) / (blackLuminance + 0.05);

  const aaWhiteSmall = ratioWhite < 1 / 4.5;
  const aaBlackSmall = ratioBlack < 1 / 4.5;

  if (aaWhiteSmall && aaBlackSmall) {
    return ratioWhite > ratioBlack ? BLACK : WHITE;
  }
  if (aaBlackSmall) return BLACK;

  return WHITE;
};
