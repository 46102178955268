/* eslint-disable react/prop-types */
import React, { useContext, useMemo, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import TeamOrderProvider, { TeamOrderContext } from "./TeamOrderProvider";
import PhaseOrderProvider, { PhaseOrderContext } from "./PhaseOrderProvider";
import reducer, {
  CREATE_ALLOCATION,
  UPDATE_ALLOCATION,
  REMOVE_ALLOCATION,
  RESET_DATA,
} from "./reducer";

// Reducer actions
export function resetData({ data }) {
  return {
    type: RESET_DATA,
    payload: { data },
  };
}

export function createAllocation(payload) {
  return {
    type: CREATE_ALLOCATION,
    payload,
  };
}

export function updateAllocation({ id, value }) {
  return {
    type: UPDATE_ALLOCATION,
    payload: { id, value },
  };
}

export function removeAllocation({ id }) {
  return {
    type: REMOVE_ALLOCATION,
    payload: { id },
  };
}

// Create all the contexts
export const DispatchContext = React.createContext({});
export const AllocationsContext = React.createContext({});
export const OverheadsContext = React.createContext({});
export const TimeOffContext = React.createContext({});
export const PhasesContext = React.createContext({});
export const ProjectsContext = React.createContext({});
export const RolesContext = React.createContext({});
export const ProfilesContext = React.createContext({});
export const GridViewContext = React.createContext([]);
export const SelectedPhaseContext = React.createContext([]);
export const UnallocatedEntryGroupContext = React.createContext([]);
export const NoFilterDataContext = React.createContext({});

// Utility function to get all the entries related to a profile
export const getAllProfileEntries = (entries, profileId) => {
  const entryIds = Object.keys(entries);
  const profileEntryIds = entryIds.filter(
    (id) => entries[id].profileId === profileId && entries[id].phaseId
  );
  return profileEntryIds;
};

export function Store({
  children,
  initial,
  unfilteredData,
  gridView,
  projectSort = "name",
  selectedPhaseId,
  setSelectedPhaseId,
  filteredProjectId,
  filteredCategoryIds,
  filteredProfileIds,
}) {
  const [state, dispatch] = useImmerReducer(reducer, initial);
  const [noFilterState, noFilterDispatch] = useImmerReducer(
    reducer,
    unfilteredData
  );

  // Since the store is a child of the 'Schedule' component that
  // fetches the data, we reset the reducer with new data when passed in
  useEffect(() => dispatch(resetData({ data: initial })), [initial]);
  useEffect(
    () => noFilterDispatch(resetData({ data: unfilteredData })),
    [unfilteredData]
  );

  // Memoize the individual state objects
  const allocations = useMemo(
    () => state.entryGroups.byId,
    [state.entryGroups.byId]
  );
  const phases = useMemo(() => state.phases.byId, [state.phases.byId]);
  const phaseList = useMemo(() => state.phases.allIds, [state.phases.allIds]);
  const roles = useMemo(() => state.roles.byId, [state.roles.byId]);
  const team = useMemo(() => state.profiles.byId, [state.profiles.byId]);
  const timeOffRequestDays = useMemo(
    () => state.timeOffRequestDays.byId,
    [state.timeOffRequestDays.byId]
  );

  const timeOffHolidays = useMemo(
    () => state.timeOffHolidays.byId,
    [state.timeOffHolidays.byId]
  );

  const teamList = useMemo(
    () => state.profiles.allIds,
    [state.profiles.allIds]
  );
  const hasProjects = useMemo(
    () => state.projects.allIds.length > 0,
    [state.projects.allIds]
  );
  const overheads = useMemo(
    () => state.overheadEntryGroups.byId,
    [state.overheadEntryGroups.byId]
  );
  const unallocatedEntryGroups = useMemo(
    () => state.unallocatedEntryGroups.byId,
    [state.unallocatedEntryGroups.byId]
  );

  return (
    <DispatchContext.Provider value={dispatch}>
      <NoFilterDataContext.Provider value={{ noFilterState, noFilterDispatch }}>
        <ProjectsContext.Provider value={hasProjects}>
          <PhasesContext.Provider value={phases}>
            <RolesContext.Provider value={roles}>
              <PhaseOrderProvider
                byId={phases}
                allIds={phaseList}
                allocations={allocations}
                selectedPhase={selectedPhaseId}
                filteredProject={filteredProjectId}
                projectSort={projectSort}
                setSelectedPhaseId={setSelectedPhaseId}
                gridView={gridView}
              >
                <ProfilesContext.Provider value={team}>
                  <OverheadsContext.Provider value={overheads}>
                    <TimeOffContext.Provider
                      value={{
                        timeOffRequestDays,
                        timeOffHolidays,
                      }}
                    >
                      <AllocationsContext.Provider value={allocations}>
                        <UnallocatedEntryGroupContext.Provider
                          value={unallocatedEntryGroups}
                        >
                          <TeamOrderProvider
                            allIds={teamList}
                            allocations={allocations}
                            selectedPhase={selectedPhaseId}
                            filteredProject={filteredProjectId}
                            filteredCategoryIds={filteredCategoryIds}
                            filteredProfileIds={filteredProfileIds}
                          >
                            <SelectedPhaseContext.Provider
                              value={[selectedPhaseId, setSelectedPhaseId]}
                            >
                              <GridViewContext.Provider value={gridView}>
                                {children}
                              </GridViewContext.Provider>
                            </SelectedPhaseContext.Provider>
                          </TeamOrderProvider>
                        </UnallocatedEntryGroupContext.Provider>
                      </AllocationsContext.Provider>
                    </TimeOffContext.Provider>
                  </OverheadsContext.Provider>
                </ProfilesContext.Provider>
              </PhaseOrderProvider>
            </RolesContext.Provider>
          </PhasesContext.Provider>
        </ProjectsContext.Provider>
      </NoFilterDataContext.Provider>
    </DispatchContext.Provider>
  );
}

// Hooks for pulling the data

// Data
export const useDispatch = () => useContext(DispatchContext);
export const useAllocations = () => useContext(AllocationsContext);
export const useProjects = () => useContext(ProjectsContext);
export const usePhases = () => useContext(PhasesContext);
export const useRoles = () => useContext(RolesContext);
export const useProfiles = () => useContext(ProfilesContext);
export const useOverheads = () => useContext(OverheadsContext);
export const useTimeOff = () => useContext(TimeOffContext);

export const useUnallocatedEntryGroups = () =>
  useContext(UnallocatedEntryGroupContext);
export const useNoFilterData = () => useContext(NoFilterDataContext);

// Orders
export const usePhaseOrder = () => useContext(PhaseOrderContext);
export const useTeamOrder = () => useContext(TeamOrderContext);

// Grid States
export const useGridView = () => useContext(GridViewContext);
export const useSelectedPhase = () => useContext(SelectedPhaseContext);
