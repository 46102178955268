import React, { useState, useEffect } from "react";
import { makeWeekDates } from "Blocks/utils/makeWeek";
import HeaderEntry from "Components/timesheets/timesheet/HeaderEntry";
import Currency from "Components/blocks/currency/Currency";
import { useGlobalState } from "./state/GlobalContext.jsx";
import styles from "./header.module.css";

function Header({ canEdit }) {
  const { state } = useGlobalState();

  const countActivities = (rows) =>
    Object.values(rows).reduce(
      (count, row) => (row.activityId ? count + 1 : count),
      0
    );
  const calculateAllocationTotal = (groups) => {
    const total = Object.values(groups).reduce(
      (sum, group) => sum + Number(group.allocation),
      0
    );
    return total;
  };
  const [totals, setTotals] = useState([]);
  const [activityCount, setActivityCount] = useState(
    countActivities(state.rows.byId)
  );
  const [allocationTotal, setAllocationTotal] = useState(
    calculateAllocationTotal(state.projectGroups.byId)
  );

  const hoursForDateFromRow = (date, rowId) =>
    Number(state.entriesByRowId[rowId][date].hours);

  const timeOffRequestDays = Object.values(state.timeOffRequests).flatMap(
    ({ requestDays }) => requestDays
  );
  const timeOffHoursGroupedByDate = timeOffRequestDays.reduce(
    (result, requestDay) => {
      const requestDayHours = parseFloat(requestDay.hours);
      if (!requestDayHours) return result;

      if (result[requestDay.date]) {
        result[requestDay.date] += requestDayHours;
      } else {
        result[requestDay.date] = requestDayHours;
      }

      return result;
    },
    {}
  );
  const timeOffHolidays = Object.values(state.timeOffHolidays).flatMap(
    ({ holidays }) => holidays
  );
  const timeOffHolidaysGroupedByDate = timeOffHolidays.reduce(
    (result, holiday) => {
      const holidayHours = parseFloat(holiday.hours);
      if (!holidayHours) return result;

      if (result[holiday.date]) {
        result[holiday.date] += holidayHours;
      } else {
        result[holiday.date] = holidayHours;
      }

      return result;
    },
    {}
  );

  const hoursForDate = (date) => {
    const rows = Object.values(state.rows.byId);
    return rows.reduce(
      (hours, row) => hours + hoursForDateFromRow(date, row.id),
      0
    );
  };

  useEffect(() => {
    const calculateTotals = (startOfWeek) => {
      const weekDates = makeWeekDates(startOfWeek);
      const weekTotals = weekDates.map((date) => ({
        date,
        hours:
          hoursForDate(date) +
          (timeOffHoursGroupedByDate[date] || 0) +
          (timeOffHolidaysGroupedByDate[date] || 0),
      }));
      return setTotals(weekTotals);
    };
    calculateTotals(state.date);
    setActivityCount(countActivities(state.rows.byId));
    setAllocationTotal(calculateAllocationTotal(state.projectGroups.byId));
  }, [state]);

  const weekTotal = totals.reduce((total, day) => total + day.hours, 0.0);

  const expenseTotal = state.expenses.allIds.reduce((acc, id) => {
    if (!state.expenses.byId[id]?.amount) return acc;
    return acc + Number(state.expenses.byId[id]?.amount);
  }, 0);

  return (
    <header className={canEdit ? styles.header : styles.headerView}>
      <div className={styles.headerProjects}>
        <div className={`${styles.headerTitle} ${styles.headerStart}`}>
          Project
        </div>
        <div className={`${styles.headerGroup} ${styles.headerStart}`}>
          <span className={`${styles.headerLabel}`}>Allocated Hours</span>
          <span className={styles.headerValue}>{allocationTotal}</span>
        </div>
      </div>
      <div className={styles.headerActivities}>
        <div className={styles.headerTitle}>Activity</div>
        <div className={styles.headerGroup}>
          <span className={styles.headerLabel}>Activities</span>
          <span className={styles.headerValue}>{activityCount}</span>
        </div>
      </div>
      <div className={styles.headerEntries}>
        {totals.map((day, key) => (
          <HeaderEntry
            date={day.date}
            hours={Math.round(day.hours * 100) / 100}
            key={key}
          />
        ))}
      </div>
      <div className={styles.headerTotals}>
        <div className={styles.headerTotalHours}>
          <div className={styles.headerTitle}>Hours</div>
          <div className={styles.headerGroup}>
            <span className={styles.headerValue}>
              {Math.round(weekTotal * 100) / 100}
            </span>
          </div>
        </div>
        <div className={styles.headerTotalExpenses}>
          <div
            className={canEdit ? styles.headerTitle : styles.headerViewTitle}
          >
            Expenses
          </div>
          <div
            className={canEdit ? styles.headerGroup : styles.headerViewGroup}
          >
            <span className={styles.headerLabel}>{currencySymbol}</span>
            <span className={styles.headerValue}>
              <Currency value={expenseTotal} format="noSymbol" />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
