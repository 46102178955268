/**
 * Text Styles
 *
 * Combination of font size, line height, and font weight to match our Text Styles in Figma
 *
 * These are intended to be used with the `textStyles:` util
 *
 * TODO: Add responsive behaviors per text style here
 */
export const textStyles = {
  // Headings
  heading1: {
    lineHeight: "$base",
    fontSize: "$5xl",
    fontWeight: "$semibold",
  },
  heading2: {
    lineHeight: "$base",
    fontSize: "$4xl",
    fontWeight: "$semibold",
  },
  heading3: {
    lineHeight: "$base",
    fontSize: "$3xl",
    fontWeight: "$semibold",
  },
  heading4: {
    lineHeight: "$base",
    fontSize: "$2xl",
    fontWeight: "$semibold",
  },
  heading5: {
    lineHeight: "$base",
    fontSize: "$xl",
    fontWeight: "$semibold",
  },
  heading6: {
    lineHeight: "$base",
    fontSize: "$md",
    fontWeight: "$semibold",
  },
  // Body
  extraLarge: {
    lineHeight: "$tight",
    fontSize: "$xl",
    fontWeight: "$normal",
  },
  large: {
    lineHeight: "$tight",
    fontSize: "$lg",
    fontWeight: "$normal",
  },
  medium: {
    lineHeight: "$tight",
    fontSize: "$md",
    fontWeight: "$normal",
  },
  small: {
    lineHeight: "$tight",
    fontSize: "$sm",
    fontWeight: "$normal",
  },
  extraSmall: {
    lineHeight: "$tight",
    fontSize: "$xs",
    fontWeight: "$normal",
  },
};

export type TextElementStrings =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "blockquote"
  | "dd"
  | "li"
  | "abbr"
  | "cite"
  | "q"
  | "s"
  | "tspan";
