import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./avatar.module.css";

export const Avatar = ({ image, size, initials }) => {
  const [useImage, setUseImage] = useState(!!image);

  // We can also use imgix.js if we'd like
  function imgixifyImage(imageUrl) {
    const modifiedImageUrl = imageUrl.split("amazonaws.com/")[1];
    return `https://monograph-dashboard-media.imgix.net/${modifiedImageUrl}?crop=faces&fit=crop&h=100&w=100`;
  }

  const classes = cx(styles.avatar, styles[size], {
    [styles.avatarInitials]: !!initials,
  });

  if (useImage) {
    return (
      <img
        onError={() => setUseImage(false)}
        className={classes}
        alt="Avatar"
        src={imgixifyImage(image)}
      />
    );
  }

  if (initials) {
    return <div className={classes}>{initials}</div>;
  }

  return <div className={classes} />;
};

Avatar.propTypes = {
  image: PropTypes.string,
  initials: PropTypes.string,
  size: PropTypes.oneOf(["xSmall", "small", "medium"]),
};

Avatar.defaultProps = {
  image: "",
  initials: "",
  size: "medium",
};

export default Avatar;
