import React from "react";
import cx from "classnames";
import PropTypes, { oneOfType } from "prop-types";
import { Currency } from "@plan";
import styles from "../chart.module.css";

// props:
// value - dollar amount for the label.
// x - x position for label.
// y - y position for label.
// dy - Shifts the element's position vertically by the passed value.
// // align - Text align for the label. Default is "start", pass in "middle" or "end"
// variant - if passed "highlighted", then will use fill (--color-neutral-40).
// Add more styles in ./chart-label.module.css if wish to use other align or variant props.

export const CurrencyLabel = ({ value, x, y, dy, align, variant }) => (
  <text x={x} y={y} dy={dy}>
    <tspan
      className={cx(
        styles.chartLabel,
        { [styles[align]]: align },
        { [styles[variant]]: variant }
      )}
    >
      <Currency value={value} forSvg format="abbreviated" />
    </tspan>
  </text>
);

CurrencyLabel.propTypes = {
  value: oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  dy: oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(["middle", "end"]),
  variant: PropTypes.oneOf(["highlighted"]),
};
