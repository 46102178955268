import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useModal } from "Components/blocks/modal/useModal";
import eventTracker from "Blocks/tracker.js";
import { TemplateModal } from "./ProjectTemplateModal";
import style from "../duplicate/duplicateProject.module.css";
import TriangleIcon from "../duplicate/TriangleIcon";
import { UpgradeModal } from "../create/modals";

const MenuItem = ({
  onClick,
  showHover,
  setShowHover,
  projectCreationDisabled,
  templateCreationDisabled,
  stripePubKey,
}) => {
  const [upgradeIsOpen, upgradeModalActions] = useModal(false);
  const upgradeModalTitle = "Need more projects?";
  const pathString = "/projects";

  const projectBundleonSubmit = async (projectAddOn) => {
    const stripeKey = stripePubKey || "";
    const stripe = await loadStripe(stripeKey);

    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Project bundle",
      buttonText: "Purchase now",
      planIntervalSetting: null,
    });

    fetch(`/stripe/checkout?add_on=${projectAddOn}`)
      .then((response) => response.json())
      .then((json) => {
        if (stripe) {
          stripe
            .redirectToCheckout({
              sessionId: json.session_id,
            })
            .then((result) => {
              console.log(result);
            });
        }
      });
  };

  const upgradePlanOnSubmit = async () => {
    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Grow",
      buttonText: "Upgrade to Grow",
      planIntervalSetting: null,
    });

    fetch("/stripe/billing.json", { method: "POST" })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = json.portal_url;
      });
  };

  if (projectCreationDisabled || templateCreationDisabled) {
    return (
      <>
        <button
          type="button"
          style={{ position: "relative" }}
          className={style.buttonDisabled}
          onMouseEnter={() => setShowHover("block")}
          onMouseLeave={() => setShowHover("none")}
        >
          <h3 className={style.titleDisabled}>Save as Template</h3>
          <p className={style.descriptionDisabled}>
            Save this project, its phases, roles, milestones, and tasks as a
            template.
          </p>
          <div
            className={style.hoverCardTemplate}
            style={{ display: showHover }}
            onMouseEnter={() => setShowHover("block")}
            onMouseLeave={() => setShowHover("none")}
          >
            Upgrade to save any project as a template for faster project
            creation.{" "}
            <span
              className={style.link}
              onClick={() => upgradeModalActions.open()}
            >
              Learn more
            </span>
          </div>
          <div
            className={style.triangleTemplate}
            style={{ display: showHover }}
          >
            <TriangleIcon />
          </div>
        </button>
        <UpgradeModal
          projectBundleonSubmit={projectBundleonSubmit}
          upgradePlanOnSubmit={upgradePlanOnSubmit}
          upgradeIsOpen={upgradeIsOpen}
          upgradeModalActions={upgradeModalActions}
          upgradeModalTitle={upgradeModalTitle}
        />
      </>
    );
  }
  return (
    <button
      type="button"
      className="menu-page-button__link menu-page-button__link--archive"
      onClick={onClick}
    >
      <h3 className="menu-page-button__title">Save as Template</h3>
    </button>
  );
};

export const TemplateMenuLink = ({
  organizationId,
  colors,
  project,
  projectCreationDisabled,
  templateCreationDisabled,
  stripePubKey,
}) => {
  const [showHover, setShowHover] = useState("none");
  const [modal, modalActions] = useModal(false);

  return (
    <>
      <MenuItem
        onClick={modalActions.open}
        showHover={showHover}
        setShowHover={setShowHover}
        projectCreationDisabled={projectCreationDisabled}
        templateCreationDisabled={templateCreationDisabled}
        stripePubKey={stripePubKey}
      />
      <TemplateModal
        isOpen={modal}
        actions={modalActions}
        project={project}
        organizationId={organizationId}
        colors={colors}
      />
    </>
  );
};

export default TemplateMenuLink;
