import React, { useMemo } from "react";
import { Card, Field } from "@plan";
import styles from "../consultants.module.css";

export function Budget({ project = {}, consultants = [] }) {
  const consultantBudget = project.consultantsFee;
  const allocatedBudget = consultants.reduce(
    (acc, consultant) => Number(acc) + Number(consultant.budget),
    0
  );
  const remainingBudget = consultantBudget - allocatedBudget;
  const isOverBudget = useMemo(() => remainingBudget < 0, [remainingBudget]);

  return (
    <Card classes={[styles.budgetGrid]}>
      <div className={styles.cardTitle}>Consultants Planner</div>
      <Field
        as="number"
        label="Consultant Budget"
        noFloatingLabel
        isDisabled
        format="currency"
        variant="gray"
        borderless
        value={Number(consultantBudget)}
      />
      <Field
        as="number"
        label="Remaining Budget"
        noFloatingLabel
        isDisabled
        negative={isOverBudget}
        classes={isOverBudget ? styles.overBudget : ""}
        format="currency"
        variant="gray"
        borderless
        value={Number(remainingBudget)}
      />
      <Field
        as="number"
        label="Allocated Budget"
        noFloatingLabel
        isDisabled
        format="currency"
        variant="gray"
        borderless
        value={Number(allocatedBudget)}
      />
    </Card>
  );
}
