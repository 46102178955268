import React from "react";
import { useToggle } from "Hooks";
import Accordion from "Components/blocks/accordion/Accordion";
import { Currency, Hours } from "@plan";
import styles from "./table.module.css";

export default function ExpandableRow({
  rowHeader,
  groupAttribute,
  entries,
  hours,
  value,
  children,
}) {
  const [isOpen, toggleOpen] = useToggle();

  return (
    <>
      <div
        className={` ${isOpen && styles.isOpen} ${styles.expandableRow}`}
        onClick={toggleOpen}
      >
        <div className={styles.rowValueWrapper}>
          <span className={`${styles.rowHeader} ${styles.rowValue}`}>
            {rowHeader}
          </span>
          <span className={styles.rowValue}>{groupAttribute}</span>
          <span className={styles.rowValue}>{entries}</span>
          <span className={styles.rowValue}>
            {hours ? <Hours value={hours} format="twoDecimal" /> : null}
          </span>
          <span className={styles.rowValue}>
            <Currency value={value} format="withCents" />
          </span>
        </div>
        <div className={styles.toggleWrapper}>
          <span className={styles.toggleRow} />
        </div>
      </div>
      <Accordion isOpen={isOpen}>{children}</Accordion>
    </>
  );
}
