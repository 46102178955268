import ajax from "Blocks/utils/ajax";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

function changeEntryColor(entryRowNode, newStatus) {
  entryRowNode.classList.remove("is-rejected", "is-approved", "is-updated");
  entryRowNode.classList.add(`is-${newStatus}`);
}

function disableButton(button) {
  const buttonToDisable = button;
  buttonToDisable.classList.add("is-disabled");
  buttonToDisable.disabled = true;
}

function enableButton(button) {
  const buttonToEnable = button;
  buttonToEnable.classList.remove("is-disabled");
  buttonToEnable.disabled = false;
}

function updateEntryState(entryRowNode, status) {
  const approveButton = entryRowNode.querySelector(".js-approve");
  const rejectButton = entryRowNode.querySelector(".js-reject");

  changeEntryColor(entryRowNode, status);

  if (status === "approved") {
    enableButton(rejectButton);
    disableButton(approveButton);
  } else if (status === "rejected") {
    enableButton(approveButton);
    disableButton(rejectButton);
  }
}

function approveEntryRow(event) {
  const { entryRowId } = event.target.dataset;
  const entryRowNode = event.target.closest(".js-entry-row");

  const data = {
    is_approved: true,
  };

  ajax({
    method: "PUT",
    path: `/entry_rows/${entryRowId}/`,
    data,
    success: () => {
      updateEntryState(entryRowNode, "approved");
    },
  });
}

function rejectEntryRow(event) {
  const { entryRowId } = event.target.dataset;
  const entryRowNode = event.target.closest(".js-entry-row");

  ajax({
    method: "PUT",
    path: `/entry_rows/${entryRowId}/reject`,
    success: () => {
      updateEntryState(entryRowNode, "rejected");
    },
  });
}

class ProjectsTimesheets extends Route {
  // eslint-disable-next-line class-methods-use-this
  initialLoad() {
    bindListenerToDocument("click", "js-approve", (event) => {
      approveEntryRow(event);
    });
    bindListenerToDocument("click", "js-reject", (event) => {
      rejectEntryRow(event);
    });
  }
}

const projectsTimesheets = new ProjectsTimesheets();
export default projectsTimesheets;
