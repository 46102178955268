import React, { useEffect, useRef } from "react";
import styles from "./InvoiceSpinner.module.css";

const size = 15;
const thickness = 1.5;
const delta = 0.1;
const length = Math.PI * (size - thickness);

const dashKeyFrames = [
  {
    strokeDasharray: `${(1 - delta) * length}, ${delta * length}`,
    strokeDashoffset: 0,
    offset: 0,
  },
  {
    strokeDasharray: `${delta * length}, ${(1 - delta) * length}`,
    strokeDashoffset: `${length}`,
    offset: 0.5,
  },
  {
    strokeDasharray: `${(1 - delta) * length}, ${delta * length}`,
    strokeDashoffset: `${2 * length}`,
    offset: 1,
  },
];

const Spinner = () => {
  const ref = useRef(null);
  useEffect(() => {
    const circle = ref.current && ref.current.querySelector("circle");
    if (circle) {
      circle.animate(dashKeyFrames, {
        duration: 2000,
        iterations: Infinity,
      });
    }
  });

  return (
    <div role="progressbar" className={styles.spinner} ref={ref}>
      <svg viewBox={`${size / 2} ${size / 2} ${size} ${size}`}>
        <circle
          cx={size}
          cy={size}
          r={(size - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          style={{
            strokeDashoffset: 0,
            strokeDasharray: `${(1 - delta) * length}, ${delta * length}`,
            stroke: "currentColor",
          }}
        />
      </svg>
    </div>
  );
};

export default Spinner;
