import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import DateDisplay from "Components/blocks/DateDisplay";
import { LoadingRing } from "Components/blocks/icons";
import { Table, Card, FlexWrapper } from "@plan";
import { Subscribed, Actions, OnBoarding, Trial } from "./TableElements";

export const ORG_FIELDS = gql`
  fragment OrgFields on Organization {
    name
    id
    isActive
    slug
    demo {
      id
    }
    declaredSize
    profileCount
    declaredReferrer
    status
    trialEndDate
    isQualified
    createdAt
    demo {
      id
    }
    subscription {
      id
      isActive
    }
  }
`;

export const OrgTable = ({ data }) => {
  const columns = useMemo(() => [
    {
      Header: "Name",
      headerClass: `page-table__title`,
      classes: "page-table__cell page-table__cell--nowrap",
      accessor: "name",
      Cell: ({ cell, row }) => (
        <a
          href={`/admin/set_organization_id?organization_id=${row.original.id}`}
        >
          <strong>{cell.value}</strong>
        </a>
      ),
    },
    {
      Header: "Actions",
      headerClass: `page-table__title`,
      accessor: "demo",
      Cell: ({ cell, row }) => (
        <Actions
          key={row?.original?.id}
          id={row?.original?.id}
          demoId={row?.original?.demo?.id}
          isQualified={row?.original?.isQualified}
        />
      ),
    },
    {
      Header: "Size",
      headerClass: `page-table__title`,
      accessor: "declaredSize",
      Cell: ({ cell, row }) => (
        <>
          {cell.value} ({row.original.profileCount})
        </>
      ),
    },
    {
      Header: "Referrer",
      headerClass: `page-table__title`,
      accessor: "declaredReferrer",
      Cell: ({ cell }) => (
        <div
          style={{
            width: "160px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {cell.value}
        </div>
      ),
    },
    {
      Header: "Subscribed",
      headerClass: `page-table__title`,
      accessor: (d) => (d.subscription?.isActive ? "Yes" : "No"),
      Cell: ({ cell }) => <Subscribed value={cell?.value === "Yes" ?? false} />,
    },
    {
      Header: "Created",
      headerClass: `page-table__title`,
      accessor: "createdAt",
      Cell: ({ cell }) => <DateDisplay date={cell.value} format="YYYY/MM/DD" />,
    },
    {
      Header: "Trial End",
      headerClass: `page-table__title`,
      accessor: "trialEndDate",
      Cell: ({ cell, row }) => (
        <Trial id={row.original.id} trialEndDate={cell.value} />
      ),
    },
    {
      Header: "OnBoarded",
      headerClass: `page-table__title`,
      accessor: "status",
      Cell: ({ cell, row }) => (
        <OnBoarding id={row.original.id} status={cell.value} />
      ),
    },
  ]);

  return (
    <Table
      as="sticky"
      hideFooter
      columns={columns}
      data={data}
      rowProps={({ original }) => {
        const subscribedOrInTrial =
          new Date(original?.trialEndDate).valueOf() > new Date().valueOf() ||
          original?.subscription?.isActive;

        return {
          className: subscribedOrInTrial
            ? "page-table__row page-table__row--large"
            : "page-table__row page-table__row--large page-table__row--expired",
        };
      }}
    />
  );
};

export const GET_ORGANIZATIONS = gql`
  query organizations($isQualified: Boolean) {
    admin {
      organizations(isQualified: $isQualified) {
        nodes {
          ...OrgFields
        }
      }
    }
  }
  ${ORG_FIELDS}
`;

export const TableWrapper = ({ heading, data, loading, error }) => {
  if (loading)
    return (
      <>
        <h2>{heading}</h2>
        <Card>
          <FlexWrapper center>
            <LoadingRing />
          </FlexWrapper>
        </Card>
      </>
    );

  if (error)
    return (
      <>
        <h2>{heading}</h2>
        <Card>
          <FlexWrapper center>Error Loading Data...</FlexWrapper>
        </Card>
      </>
    );

  return (
    <>
      <h2>{heading}</h2>
      <OrgTable data={data} />
    </>
  );
};

export const ActiveOrganizations = () => {
  const { data, loading, error } = useQuery(GET_ORGANIZATIONS, {
    variables: { isQualified: true },
  });
  return (
    <TableWrapper
      heading="Recent Signups"
      data={data?.admin?.organizations?.nodes ?? []}
      loading={loading}
      error={error}
    />
  );
};

export const DisqualifiedOrganizations = () => {
  const { data, loading, error } = useQuery(GET_ORGANIZATIONS, {
    variables: { isQualified: false },
  });
  return (
    <TableWrapper
      heading="Disqualified Organizations"
      data={data?.admin?.organizations?.nodes ?? []}
      loading={loading}
      error={error}
    />
  );
};
