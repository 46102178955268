import React from "react";

const Consultants = ({ consultants }) => (
  <div className="page-module">
    <h4
      style={{
        margin: "1.5rem 0 0.5rem 0",
        fontSize: "1rem",
        lineHeight: "1",
        letterSpacing: "0.01875rem",
      }}
    >
      Consultants
    </h4>
    {consultants.map((consultant) => (
      <div style={{ marginTop: "1rem" }}>
        <a
          href={`/directory/consultants/company/${consultant.slug}`}
          style={{
            color: "#5840E0",
            fontWeight: "600",
          }}
        >
          {consultant.name}
        </a>
        <div
          style={{
            color: "#4B5563",
            fontSize: "0.8rem",
          }}
        >
          {consultant.template_name}
        </div>
      </div>
    ))}
  </div>
);

export default Consultants;
