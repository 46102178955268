import util from "Blocks/utils/util";
import moment from "moment";
import Url from "domurl";
import ajax from "Blocks/utils/ajax";
import { renderError, renderSuccess } from "Blocks/alert";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import { selectMethods } from "Blocks/select";
import { addHasValue } from "Blocks/field";

class TasksIndex extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-assign", (event) => {
      this.assign(event);
    });
    bindListenerToDocument("click", "js-unassign", (event) => {
      this.triggerUnassign(event);
    });
    bindListenerToDocument("click", "js-create-task", (event) => {
      this.createTask(event);
    });
    bindListenerToDocument("click", "js-filter", (event) => {
      this.triggerFilter(event);
    });
    bindListenerToDocument("click", "js-open-task-modal", (event) => {
      this.updatePhaseInput(event);
    });
    bindListenerToDocument("click", "js-open-milestone-modal", (event) => {
      this.updateMilestoneModal(event);
    });
    bindListenerToDocument("click", "js-create-milestone", (event) => {
      this.createMilestone(event);
    });
    bindListenerToDocument("click", "js-open-edit-milestone-modal", (event) => {
      this.updateEditMilestoneModal(event);
    });
    bindListenerToDocument("click", "js-update-milestone", (event) => {
      this.updateMilestone(event);
    });
    bindListenerToDocument("click", "js-destroy-milestone", (event) => {
      this.destroyMilestone(event);
    });
    bindListenerToDocument("click", "js-toggle-delete-milestone", (event) => {
      this.toggleDeleteMilestone(event);
    });
    bindListenerToDocument("click", "js-select-phase", (event) => {
      this.triggerMilestoneSelectUpdate(event);
    });

    // Do we call this on initialLoad? Or every page load?
    // Downside of initialLoad is that if you visit a different project, it'll be sorted the same.
    // Downside of every page load would be that if you go back from a task it'll be different.
    this.setFilters();
  }

  load() {
    this.setProjectSlug();
    this.applyFilters();
  }

  beforeCache() {}

  // Task creation
  assign(event) {
    const fakeAssignee = $(".js-fake-assignee")[0];
    const name = event.target.innerHTML;
    const assignees = $(".js-assignees")[0];
    const selectList = event.target.closest(".js-select-list");
    const selectElement = selectList.closest(".js-select");

    // Create Node
    const assigneeNode = fakeAssignee.cloneNode(true);
    assigneeNode.classList.remove("is-hidden");
    assigneeNode.classList.remove("js-fake-assignee");
    assigneeNode.classList.add("js-assignee");
    assigneeNode.dataset.profileId = event.target.dataset.value;
    assigneeNode.querySelector(".js-assignee-name").innerHTML = name;
    assignees.appendChild(assigneeNode);

    // Remove li from selectList
    util.deleteElement(event.target);
    selectMethods.deselect(selectElement);
  }

  triggerUnassign(event) {
    const assigneeNode = event.target.closest(".js-assignee");
    this.unassign(assigneeNode);
  }

  unassign(assigneeNode) {
    // Grab innerHTML and the id of the assignee that is about to be deleted
    const id = assigneeNode.dataset.profileId;
    const name = assigneeNode.querySelector(".js-assignee-name").innerHTML;

    // Delete the assignee
    util.deleteElement(assigneeNode);

    // Populate the select with the id and the name of the assignee
    const newLiString = `<li class="select__item js-select-item js-assign" data-value="${id}">${name}</li>`;
    const newLi = util.htmlToElement(newLiString);
    const assigneeSelectData = $(".js-select-assignee")[0];
    const assigneeSelect = assigneeSelectData.closest(".js-select");
    const assigneeSelectList = assigneeSelect.querySelector(".js-select-list");
    assigneeSelectList.appendChild(newLi);
  }

  updatePhaseInput() {
    if (this.filters.phase !== "") {
      const phaseInput = $(".js-create-task-phase")[0];
      const phaseInputSelect = phaseInput.closest(".js-select");
      selectMethods.selectItemByValue(phaseInputSelect, this.filters.phase);
      // Update milestone input
      this.updateMilestoneSelect(this.filters.phase);
    }
  }

  createTask(event) {
    this.startSave();

    // Find task list to append to
    const taskList = $(".js-tasks")[0];
    const data = this.getTaskData();

    ajax({
      method: "POST",
      path: `/projects/${this.projectSlug}/tasks`,
      data,
      success: (response) => {
        console.log(response);
        const newTask = util.htmlToElement(response);
        this.insertNewTask(newTask);
        this.clearNewTaskInputs();
        this.filters.phase = data.phase_id.toString();
        this.filters.milestone = "";
        this.filters.status = "open";
        this.filters.assignment = "";
        this.applyFilters();
        renderSuccess("Created task");
        this.endSave();
      },
    });
  }

  getTaskData() {
    const name = $(".js-create-task-name")[0].value;
    const description = $(".js-create-task-description")[0].value;
    const due_date = $(".js-create-task-due-date")[0].value;
    const assignees = Array.from($(".js-assignee")).map(
      (node) => node.dataset.profileId
    );
    const phase_id = $(".js-create-task-phase")[0].dataset.value;
    const milestone_id = $(".js-create-task-milestone")[0].dataset.value;
    const data = {
      name,
      description,
      due_date,
      assignees,
      phase_id,
      milestone_id,
    };
    return data;
  }

  clearNewTaskInputs() {
    $(".js-create-task-name")[0].value = "";
    $(".js-create-task-description")[0].value = "";
    $(".js-create-task-due-date")[0].value = moment()
      .add(1, "day")
      .format("YYYY-MM-DD");
    Array.from($(".js-assignee")).forEach((assigneeNode) => {
      this.unassign(assigneeNode);
    });
  }

  // Filtering
  // $(".js-filter") == Filter buttons, each one has a data-filter-type and a data-filter-value
  // We set the state of the filters with the object this.filters.
  // Update this.filters, then applyFilters is called to the HTML to determine what
  // is rendered as active.
  // This applyFilters method calls #filterTasks, and this is what actually filters the tasks

  triggerFilter(event) {
    const { filterValue } = event.target.dataset;
    const { filterType } = event.target.dataset;
    this.filters[filterType] = filterValue;

    // Changing the phase resets the milestone
    if (filterType === "phase") {
      this.filters.milestone = "";
    }

    this.clearParams();
    this.applyFilters();
  }

  // Clears the query params on the url
  // so that filters are set by the user selection
  clearParams() {
    const u = new Url();
    u.clearQuery();
    window.history.replaceState("object or string", "Title", u);
  }

  // If there are query params in the url
  // set them for phase and milestones
  setParams() {
    const phase = util.getUrlParameter("phase");
    const milestone = util.getUrlParameter("milestone");

    if (phase) this.filters.phase = phase;
    if (milestone) this.filters.milestone = milestone;
  }

  setFilters() {
    this.filters = {
      phase: "",
      milestone: "",
      status: "open",
      assignment: "",
    };

    // Checks and sets phase & milestone
    this.setParams();

    if ($(".js-filter[data-filter-type='phase'].is-selected")[0]) {
      this.filters.phase = $(
        ".js-filter[data-filter-type='phase'].is-selected"
      )[0].dataset.filterValue;
    }
  }

  applyFilters(timesCalled = 1) {
    // Checks and sets phase & milestone
    this.setParams();

    const filterKeys = Object.keys(this.filters);
    for (let i = 0; i < filterKeys.length; i++) {
      const filterKey = filterKeys[i];
      const filterNodes = $(`.js-filter[data-filter-type="${filterKey}"]`);
      let selected = false;
      Array.from(filterNodes).forEach((filterNode) => {
        if (filterNode.dataset.filterValue === this.filters[filterKey]) {
          filterNode.classList.add("is-selected");
          selected = true;
        } else {
          filterNode.classList.remove("is-selected");
        }
      });

      // This is a catch in case something on the page has been removed.
      // If someone deletes a milestone and comes back to this page where it is selected, this
      // will deselect it, reset the filters and try again.
      if (!selected && filterKey != "milestone") {
        this.filters = {
          phase: "",
          milestone: "",
          status: "open",
          assignment: "",
        };

        // This prevents recursive loops
        if (timesCalled >= 2) {
          renderError();
        } else {
          this.applyFilters(timesCalled + 1);
          break;
        }
      }
    }

    this.filterTasks();
  }

  filterTasks() {
    Array.from($(".js-task")).forEach((task) => {
      this.filterTask(task);
    });
    Array.from($(".js-milestone")).forEach((milestone) => {
      this.filterMilestone(milestone);
    });
  }

  filterTask(task) {
    if (
      (this.filters.phase === "" ||
        task.dataset.phase === this.filters.phase) &&
      task.dataset.status === this.filters.status &&
      (this.filters.assignment === "" ||
        task.dataset.assignment === this.filters.assignment) &&
      (this.filters.milestone === "" ||
        task.dataset.milestone === this.filters.milestone)
    ) {
      task.classList.add("is-visible");
    } else {
      task.classList.remove("is-visible");
    }
  }

  filterMilestone(milestone) {
    if (this.filters.milestone === milestone.dataset.milestone) {
      milestone.classList.add("is-visible");
    } else {
      milestone.classList.remove("is-visible");
    }
  }

  insertNewTask(newTask) {
    const newTaskDueDate = newTask.dataset.dueDate;
    const newTaskName = newTask.querySelector(".js-task-name").innerHTML;
    const parentNode = $(".js-tasks")[0];
    const tasks = Array.from($(".js-task"));
    let inserted = false;

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      const { dueDate } = task.dataset;
      const name = task.querySelector(".js-task-name").innerHTML;
      if (dueDate > newTaskDueDate) {
        parentNode.insertBefore(newTask, task);
        inserted = true;
        break;
      } else if (dueDate < newTaskDueDate) {
        continue;
        // If they have the same due date
      } else if (name > newTaskName) {
        parentNode.insertBefore(newTask, task);
        inserted = true;
        break;
      } else if (name < newTaskName) {
        continue;
      }
    }

    // If it has not been inserted, insert the task at the end
    if (inserted == false) {
      parentNode.appendChild(newTask);
    }
  }

  updateMilestoneModal(event) {
    const phaseNode = event.target.closest(
      ".js-filter[data-filter-type='phase']"
    );
    const phaseId = phaseNode.dataset.id;
    const phaseName = phaseNode.dataset.name;
    const milestonePhaseNode = $(".js-create-milestone-phase")[0];
    milestonePhaseNode.innerHTML = phaseName;
    milestonePhaseNode.dataset.phaseId = phaseId;
  }

  createModalItem(item) {
    const itemNode = document.createElement("li");
    itemNode.className = "select__item js-select-item";
    itemNode.dataset.value = item.id;
    itemNode.innerHTML = item.name;
    return itemNode;
  }

  createMilestone() {
    this.startSave();

    // Find milestone list to append
    const phaseNode = $(".js-filter.is-selected[data-filter-type='phase']")[0];
    const phaseMilestoneList = phaseNode.querySelector(".js-phase-milestones");
    const milestones = $(".js-milestones")[0];
    const modalMilestoneSelect = $(".js-create-task-milestone")[0]
      .nextElementSibling;

    const data = {
      phase_id: $(".js-create-milestone-phase")[0].dataset.phaseId,
      name: $(".js-create-milestone-name")[0].value,
      due_date: $(".js-create-milestone-date")[0].value,
      description: $(".js-create-milestone-description")[0].value,
    };

    ajax({
      method: "POST",
      path: `/projects/${this.projectSlug}/milestones`,
      data,
      success: (response) => {
        const partials = JSON.parse(response);

        const item = {
          id: partials.milestone_id,
          name: partials.milestone_name,
        };

        // Add milestone to modal
        const newModalMilestone = this.createModalItem(item);
        modalMilestoneSelect.appendChild(newModalMilestone);

        const newPhaseMilestone = util.htmlToElement(partials.phaseMilestone);
        const newMilestone = util.htmlToElement(partials.milestone);
        phaseMilestoneList.appendChild(newPhaseMilestone);
        milestones.appendChild(newMilestone);

        this.clearMilestoneInputs();
        this.filters.milestone = partials.milestone_id.toString();
        this.filters.status = "open";
        this.filters.assignment = "";
        this.applyFilters();
        renderSuccess("Created milestone");
        this.endSave();
      },
    });
  }

  clearMilestoneInputs() {
    $(".js-create-milestone-phase")[0].dataset.phaseId = "";
    $(".js-create-milestone-name")[0].value = "";
    $(".js-create-milestone-description")[0].value = "";
  }

  updateEditMilestoneModal(event) {
    const milestoneNode = event.target.closest(".js-milestone");
    const milestoneId = milestoneNode.dataset.milestone;
    const milestoneName =
      milestoneNode.querySelector(".js-milestone-name").innerText;
    const { milestoneDate } = milestoneNode.dataset;
    const milestoneDescription = milestoneNode.querySelector(
      ".js-milestone-description"
    ).innerText;

    const editMilestoneNameNode = $(".js-edit-milestone-name")[0];
    editMilestoneNameNode.value = milestoneName;
    addHasValue(editMilestoneNameNode);
    $(".js-edit-milestone-id")[0].value = milestoneId;
    $(".js-edit-milestone-date")[0].value = milestoneDate;
    $(".js-edit-milestone-description")[0].value = milestoneDescription;
  }

  updateMilestone() {
    this.startSave();

    const milestoneId = $(".js-edit-milestone-id")[0].value;
    const milestoneNode = $(
      `.js-milestone[data-milestone="${milestoneId}"]`
    )[0];

    const data = {
      name: $(".js-edit-milestone-name")[0].value,
      due_date: $(".js-edit-milestone-date")[0].value,
      description: $(".js-edit-milestone-description")[0].value,
    };

    ajax({
      method: "PUT",
      path: `/projects/${this.projectSlug}/milestones/${milestoneId}`,
      data,
      success: (response) => {
        console.log(response);
        milestoneNode.querySelector(".js-milestone-name").innerHTML = data.name;
        milestoneNode.querySelector(".js-milestone-description").innerHTML =
          data.description;
        milestoneNode.dataset.milestoneDate = data.due_date;
        // Date node might not exist if originally rendered without a date
        if (milestoneNode.querySelector(".js-milestone-date")) {
          if (moment(data.due_date).isValid())
            milestoneNode.querySelector(".js-milestone-date").innerHTML =
              moment(data.due_date).format("MMMM D, YYYY");
          else {
            // If there is a node, but now there is no due date, we need text to fill the node
            milestoneNode.querySelector(".js-milestone-date").innerHTML =
              "date not set";
          }
        }

        renderSuccess("Updated milestone");
        this.endSave();
      },
    });
  }

  toggleDeleteMilestone(event) {
    event.target.closest(".js-milestone").classList.toggle("is-deletable");
  }

  destroyMilestone(event) {
    const milestoneNode = event.target.closest(".js-milestone");
    const milestoneId = milestoneNode.dataset.milestone;
    const phaseMilestoneNode = $(
      `li .js-filter[data-filter-type="milestone"][data-filter-value="${milestoneId}"]`
    )[0];

    ajax({
      method: "DELETE",
      path: `/projects/${this.projectSlug}/milestones/${milestoneId}`,
      success: (response) => {
        console.log(response);
        util.deleteElement(milestoneNode);
        util.deleteElement(phaseMilestoneNode);
        this.filters.milestone = "";
        this.applyFilters();
        renderSuccess("Deleted milestone");
        this.endSave();
      },
    });
  }

  // tasksIndex.updateMilestoneSelect
  // Update milstone select in task create to use milestones from the selected phase
  updateMilestoneSelect(phase_id) {
    // Get milestone select node
    const milestoneSelectDataNode = $(".js-create-task-milestone")[0];
    const milestoneSelect = milestoneSelectDataNode.closest(".js-select");
    const milestoneSelectList =
      milestoneSelect.querySelector(".js-select-list");

    // Reset value of selector
    selectMethods.deselect(milestoneSelect);

    // Get milestones from selected phase and turn them into strings of html
    const phaseNode = $(
      `.js-filter[data-filter-type="phase"][data-filter-value="${phase_id}"]`
    )[0];
    const milestoneNodes = phaseNode.querySelectorAll(".js-phase-milestone");
    const newMilestoneListItems = Array.from(milestoneNodes).map(
      (milestoneNode) => {
        const id = milestoneNode.dataset.filterValue;
        const name = milestoneNode.innerHTML;
        return `<li class="select__item js-select-item " data-value="${id}">${name}</li>`;
      }
    );

    // Remove current milestone list items
    while (milestoneSelectList.firstChild) {
      milestoneSelectList.removeChild(milestoneSelectList.firstChild);
    }

    // Add new milestone list items
    newMilestoneListItems.forEach((milestoneListItem) => {
      milestoneSelectList.appendChild(util.htmlToElement(milestoneListItem));
    });
  }

  triggerMilestoneSelectUpdate(event) {
    const phaseId = event.target.dataset.value;
    this.updateMilestoneSelect(phaseId);
  }

  isTemplate() {
    const u = new Url();
    return u.paths()[1] === "templates";
  }

  // Helpers
  // Get project slug from url
  setProjectSlug() {
    const u = new Url();
    if (this.isTemplate()) {
      this.projectSlug = `templates/${u.paths()[2]}`;
    } else {
      this.projectSlug = u.paths()[1];
    }
  }
}

const tasksIndex = new TasksIndex();
export default tasksIndex;
