import React from "react";
import { useModalState } from "./ModalContext";
import styles from "./modal.module.css";

export default function ModalBody({ children }) {
  const { content } = useModalState();
  return (
    <div className={styles.body}>
      {content ? content : ""}
      {children}
    </div>
  );
}
