import React from "react";
import { gql, useQuery, ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";

const LinkComp = () => {
  const GET_RECEIVED_INVOICES_COUNT = gql`
    query useReceivedInvoicesCountQuery {
      receivedInvoices {
        invoices {
          totalCount
        }
      }
    }
  `;

  const { data } = useQuery(GET_RECEIVED_INVOICES_COUNT);

  const invoicesCount = data?.receivedInvoices?.invoices.totalCount || 0;

  if (invoicesCount === 0) return null;

  return (
    <li class="menu-mobile__item">
      <a
        href="/invoices/invoices-received"
        style={{ display: "flex", marginLeft: "0.8rem" }}
      >
        <span class="sidebar-item__title">Invoices</span>
      </a>
    </li>
  );
};

export const InvoicesReceivedLink = () => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <LinkComp />
    </ApolloProvider>
  );
};

export default React.memo(InvoicesReceivedLink);
