import React from "react";
import { sumBy } from "lodash";
import { Table, Cell } from "Components/blocks/Table/Table";
import Loading from "Components/blocks/Loading";
import EmptyState from "Components/blocks/EmptyState";
import DateDisplay from "Components/blocks/DateDisplay";
import { Currency, Hours } from "@plan";
import { useTableData } from "./useTableData";
import styles from "./table.module.css";
import { useTimelogContext } from "../TimelogContext";

export default function Entries() {
  const { canEditAllTimesheets } = useTimelogContext();
  const [data, loading, error] = useTableData("entries");

  if (error) return <EmptyState title={error} />;
  if (loading === "fetching") return <Loading title="Loading..." />;

  const { data: tableData } = data;

  if (tableData.length === 0)
    return <EmptyState title="No Entries for this Date Range" />;

  const entriesColumns = [
    {
      Header: "Employee",
      accessor: "profile_name",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ cell }) => {
        if (canEditAllTimesheets) {
          return (
            <a
              href={`/timesheets/${cell.row.original.profile_slug}/${cell.row.original.date}`}
            >
              <DateDisplay date={cell.value} />
            </a>
          );
        }
        return <DateDisplay date={cell.value} />;
      },
    },
    {
      Header: "Note",
      headerClass: "page-table__title page-table__column--note",
      accessor: "notes",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Rate",
      accessor: "rate",
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell
          type="right"
          value={<Currency value={cell.value} format="withCents" />}
        />
      ),
    },
    {
      Header: `Hours`,
      accessor: "hours",
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell
          type="right"
          value={<Hours value={cell.value} format="twoDecimal" />}
        />
      ),
      Footer: () => {
        const totalHours = sumBy(tableData, (row) => parseFloat(row.hours));
        return (
          <Cell
            type="footer"
            value={
              <span>
                Total:
                <span className={styles.entriesTotal}>
                  <Hours value={totalHours} format="twoDecimal" />
                </span>
              </span>
            }
          />
        );
      },
    },
    {
      Header: "Amount",
      accessor: "value",
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell
          type="right"
          value={<Currency value={cell.value} format="withCents" />}
        />
      ),
      Footer: () => {
        const totalAmount = sumBy(tableData, (row) => parseFloat(row.value));
        return (
          <Cell
            type="footer"
            value={
              <span>
                Total:
                <span className={styles.entriesTotal}>
                  <Currency value={totalAmount} format="withCents" />
                </span>
              </span>
            }
          />
        );
      },
    },
  ];

  return (
    <div className={styles.entries}>
      <Table
        as="sticky"
        data={tableData}
        columns={entriesColumns}
        emptyState="No Entries for this Date Range"
        loading={loading}
      />
    </div>
  );
}
