import * as React from "react";

import { createComponent, styled } from "@plan/core";

import { useField } from "../Field";
import {
  InputTransitionalProps,
  InputWrapper,
  Label,
  useGetMemoizedHtmlId,
} from "../Input";

const component = createComponent();

const StyledTextarea = styled("textarea", {
  ...component,
  $$defaultBorder: "$borderWidths$1 solid $colors$-neutral10",
  $$hoverBorder: "$borderWidths$1 solid $colors$neutral40",
  $$errorBorder: "$borderWidths$1 solid $colors$danger10",
  border: "$$defaultBorder",
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "baseline",
  background: "$white",
  borderRadius: "$radii$default",
  width: "100%",
  textStyle: "large",
  padding: "$space$3",
  color: "$colors$neutral40",
  "&:disabled": {
    cursor: "not-allowed",
    background: "$colors$-neutral40",
    color: "$colors$-neutral10",
  },
  "&:hover, &:focus": {
    border: "$$hoverBorder",
  },
  "&:hover&:disabled": {
    border: "$$defaultBorder",
  },
  "&::placeholder": {
    color: "$colors$-neutral10",
  },
  variants: {
    ...component.variants,
    invalid: {
      false: {},
      true: {
        "&&": {
          borderColor: "$colors$danger",
        },
      },
    },
    resizeVertical: {
      true: {
        resize: "vertical",
      },
    },
    isResizable: {
      false: {
        resize: "none",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    isResizable: false,
  },
});

export type TextareaProps = React.ComponentProps<typeof StyledTextarea> &
  InputTransitionalProps;

export const Textarea = ({
  id,
  disabled,
  showLabel = true,
  label,
  required,
  withinField,
  ...props
}: TextareaProps) => {
  const { getInputProps } = useField();
  const inputProps = getInputProps({ id, disabled, required });

  const memoizedId = useGetMemoizedHtmlId(label || "within-field");

  return withinField ? (
    <StyledTextarea {...inputProps} {...props} />
  ) : (
    <InputWrapper>
      {label && (
        <Label htmlFor={memoizedId} showLabel={showLabel}>
          {label}
        </Label>
      )}
      <StyledTextarea
        id={memoizedId}
        disabled={disabled}
        required={required}
        {...props}
      />
    </InputWrapper>
  );
};
