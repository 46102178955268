import React from "react";
import { Modal } from "@plan";
import {
  PROJECT_ADD_ON,
  PLAN_DETAILS,
  PLAN_GROW,
} from "Components/upgrade/constants";
import styles from "./createProject.module.css";

export const UpgradeModal = ({
  upgradeIsOpen,
  upgradeModalActions,
  projectBundleonSubmit,
  upgradePlanOnSubmit,
  upgradeModalTitle,
}) => (
  <Modal
    isOpen={upgradeIsOpen}
    actions={upgradeModalActions}
    title={upgradeModalTitle}
    size="medium"
    subHeading="Add more projects or upgrade to the Grow plan to get unlimited projects."
  >
    <div className={styles.upgradeModal}>
      <ProjectBundle projectBundleonSubmit={projectBundleonSubmit} />
      <Pro upgradePlanOnSubmit={upgradePlanOnSubmit} />
    </div>
  </Modal>
);

export const ContactAdminModal = ({
  upgradeIsOpen,
  upgradeModalActions,
  projectLimit,
}) => (
  <Modal
    isOpen={upgradeIsOpen}
    actions={upgradeModalActions}
    title="Contact your admin"
    size="medium"
  >
    <div className={styles.contactModal}>
      <div>
        Your current plan includes up to {projectLimit} projects. Upgrade your
        plan to get unlimited projects or purchase a project bundle.
      </div>
      <div style={{ marginTop: "1rem" }}>
        Contact your account admin or email&nbsp;
        <a href="mailto:subscriptions@monograph.com?subject=Upgrade">
          subscriptions@monograph.com
        </a>{" "}
        to upgrade.
      </div>
      <button
        type="submit"
        className={styles.contactModalButton}
        onClick={upgradeModalActions.close}
      >
        Okay
      </button>
    </div>
  </Modal>
);

const Pro = ({ upgradePlanOnSubmit }) => (
  <div className={styles.box}>
    <div className={styles.boxContent}>Grow</div>
    <div className={styles.textBottom}>
      Everything in your current plan plus
    </div>
    <ul className={styles.textBottom}>
      <li className={styles.text}>• Unlimited projects</li>
      <li className={styles.text}>• Forecast project performance</li>
      <li className={styles.text}>• Forecast staff workload</li>
      <li className={styles.text}>
        • Guided onboarding + support for US based firms
      </li>
    </ul>
    <a
      href="https://monograph.com/pricing"
      className={styles.link}
      target="_blank"
      rel="noreferrer"
    >
      Compare features
    </a>
    <div className={styles.borderLinePro}>
      <div className={styles.example}>
        <span className={styles.exampleBoldText}>
          ${PLAN_DETAILS[PLAN_GROW].monthlyPrice}&nbsp;
        </span>
        <span className={styles.exampleText}>member/month</span>
      </div>
      <div className={styles.exampleMinimum}>
        Includes {PLAN_DETAILS[PLAN_GROW].seatMinimum} seats
      </div>
    </div>
    <button
      className={styles.button}
      type="submit"
      onClick={() => upgradePlanOnSubmit()}
    >
      Upgrade to Grow
    </button>
  </div>
);

const ProjectBundle = ({ projectBundleonSubmit }) => (
  <div className={styles.boxBundle}>
    <div className={styles.boxContent}>Project bundle</div>
    <div className={styles.textBottom}>Add more projects</div>
    <ul className={styles.textBottom}>
      <li className={styles.text}>
        • Access {PROJECT_ADD_ON.quantity} projects
      </li>
      <li className={styles.text}>• One time purchase</li>
    </ul>
    <div className={styles.borderLineBundle}>
      <div className={styles.exampleBundle}>
        <span className={styles.exampleBoldText}>
          ${PROJECT_ADD_ON.price}&nbsp;
        </span>
        <span className={styles.exampleText}>
          for {PROJECT_ADD_ON.quantity} projects
        </span>
      </div>
      <button
        className={styles.buttonPurchaseNow}
        type="submit"
        onClick={() => projectBundleonSubmit("project_add_on")}
      >
        Purchase now
      </button>
    </div>
  </div>
);
