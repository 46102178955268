import util from "Blocks/utils/util";
import numeral from "numeral";
import Url from "domurl";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import ajax from "Blocks/utils/ajax";
import { selectMethods } from "Blocks/select";

class TimesheetsEdit extends Route {
  initialLoad() {
    bindListenerToDocument("input", "js-entry-hour-input", (event) => {
      this.updateHours(event);
    });
    bindListenerToDocument("click", "js-create-block", (event) => {
      this.createBlock(event);
    });
    bindListenerToDocument("click", "js-open-delete-entry-options", (event) => {
      this.openDeleteOptions(event);
    });
    bindListenerToDocument(
      "click",
      ["js-cancel-entry-delete", "js-cancel-block-delete"],
      (event) => {
        this.closeDeleteOptions(event);
      }
    );
    bindListenerToDocument(
      "click",
      ["js-delete-entry", "js-delete-block"],
      (event) => {
        this.deleteEntry(event);
      }
    );
    bindListenerToDocument("click", "js-create-entry", (event) => {
      this.createEntry(event);
    });
    bindListenerToDocument("click", "js-submit-timesheet", (event) => {
      this.submitTimesheet(event);
    });
    bindListenerToDocument("click", "js-phase-item", (event) => {
      this.updatePhase(event);
    });
    bindListenerToDocument("click", "js-role-item", (event) => {
      this.updateRole(event);
    });
    bindListenerToDocument("click", "js-activity-item", (event) => {
      this.updateActivity(event);
    });
  }

  load() {
    this.sumAllWeekdates();
  }

  beforeCache() {}

  // Methods

  updateHours(event) {
    this.startSave();
    const entryHourInput = event.target;
    const entry = entryHourInput.closest(".js-entry");
    const entryId = entry.dataset.id;
    this.sumHours(entryHourInput);

    util.delay(
      () => {
        const hours = {};
        Array.from(entry.querySelectorAll(".js-entry-hour-input")).forEach(
          (hourInput) => {
            hours[hourInput.dataset.weekdate] = hourInput.value;
          }
        );

        const data = {
          data: {
            hours,
          },
        };

        ajax({
          method: "PUT",
          path: `/entries/${entryId}`,
          data,
          success: (response) => {
            this.endSave();
          },
        });
      },
      800,
      `entry-${entryId}`
    );
  }

  createBlock(event) {
    const optionNode = event.target;
    const optionParent = optionNode.parentNode;
    const selectElement = optionParent.closest(".js-select");
    const timesheet_id = $(".js-timesheet-id")[0].value;

    const data = {
      timesheet_id,
    };

    let type;
    if (selectElement.querySelector(".js-select-project")) {
      type = "project";
      data.project_id = optionNode.dataset.value;
    } else if (selectElement.querySelector(".js-select-overhead")) {
      type = "overhead";
      data.overhead_id = optionNode.dataset.value;
    }

    ajax({
      method: "POST",
      path: "/entries",
      data,
      success: (response) => {
        if (type === "project") {
          $(".js-timesheet-projects")[0].appendChild(
            util.htmlToElement(response)
          );
        } else if (type === "overhead") {
          $(".js-timesheet-overheads")[0].appendChild(
            util.htmlToElement(response)
          );
        }
        optionParent.removeChild(optionNode);
        ReactRailsUJS.mountComponents();
        selectMethods.deselect(selectElement);
      },
    });
  }

  openDeleteOptions(event) {
    const timesheetProject = event.target.closest(".js-timesheet-block");
    if (timesheetProject.querySelectorAll(".js-entry").length > 1) {
      const entry = event.target.closest(".js-entry");
      entry.classList.add("is-deletable");
    } else {
      timesheetProject.classList.add("is-deletable");
    }
  }

  closeDeleteOptions(event) {
    if (event.target.classList.contains("js-cancel-entry-delete")) {
      const entry = event.target.closest(".js-entry");
      entry.classList.remove("is-deletable");
    } else if (event.target.classList.contains("js-cancel-block-delete")) {
      const timesheetProject = event.target.closest(".js-timesheet-block");
      timesheetProject.classList.remove("is-deletable");
    }
  }

  deleteEntry(event) {
    this.startSave();
    let entry;
    let entryId;
    if (event.target.classList.contains("js-delete-entry")) {
      entry = event.target.closest(".js-entry");
      entryId = entry.dataset.id;
    }

    let block;
    let blockId;
    let blockName;
    let blockType;
    if (event.target.classList.contains("js-delete-block")) {
      block = event.target.closest(".js-timesheet-block");
      blockId = block.dataset.id;
      blockName = block.querySelector(".js-timesheet-block-name").innerHTML;
      blockType = block.dataset.type;

      entry = block.querySelector(".js-entry");
      entryId = entry.dataset.id;
    }

    ajax({
      method: "DELETE",
      path: `/entries/${entryId}`,
      success: (response) => {
        if (event.target.classList.contains("js-delete-entry")) {
          util.deleteElement(entry);
        } else {
          util.deleteElement(block);
          let blockSelect;
          if (blockType === "project") {
            blockSelect = $(".js-select-project")[0].closest(".js-select");
          } else if (blockType === "overhead") {
            blockSelect = $(".js-select-overhead")[0].closest(".js-select");
          }
          const blockList = blockSelect.querySelector(".js-select-list");
          // Rewrite the whole select element
          // This is not DRY, any changes to the select element will need to be reflected here. Should think about making list elements of the select into components.
          const blockOption = util.htmlToElement(
            `<li class="select__item js-select-item js-create-block" data-value="${blockId}">${blockName}</li>`
          );
          blockList.appendChild(blockOption);
        }
        this.sumAllWeekdates();
        this.setTotal();
        this.endSave();
      },
    });
  }

  createEntry(event) {
    this.startSave();
    const timesheet_id = $(".js-timesheet-id")[0].value;

    const timesheetBlock = event.target.closest(".js-timesheet-block");
    const project_id = timesheetBlock.dataset.id;

    const data = {
      timesheet_id,
      project_id,
    };

    ajax({
      method: "POST",
      path: "/entries",
      data,
      success: (response) => {
        const entriesContainer = timesheetBlock.querySelector(
          ".js-timesheet-block-entries"
        );
        entriesContainer.appendChild(
          util.htmlToElement(response),
          entriesContainer.lastElementChild
        );
        ReactRailsUJS.mountComponents();
        this.endSave();
      },
    });
  }

  updatePhase(event) {
    this.startSave();
    const phase_id = event.target.dataset.value;
    const entryContainer = event.target.closest(".js-entry");
    const entryId = entryContainer.dataset.id;
    const data = { phase_id };

    ajax({
      method: "PUT",
      path: `/entries/${entryId}`,
      data,
      success: (response) => {
        const roles = JSON.parse(response);
        this.resetSelectList(event, roles);
        this.endSave();
      },
    });
  }

  updateRole(event) {
    this.startSave();
    const role_id = event.target.dataset.value;
    const entryContainer = event.target.closest(".js-entry");
    const entryId = entryContainer.dataset.id;
    const data = { role_id };

    ajax({
      method: "PUT",
      path: `/entries/${entryId}`,
      data,
      success: (response) => {
        this.endSave();
      },
    });
  }

  updateActivity(event) {
    this.startSave();
    const activity_id = event.target.dataset.value;
    const entryContainer = event.target.closest(".js-entry");
    const entryId = entryContainer.dataset.id;
    const data = { activity_id };

    ajax({
      method: "PUT",
      path: `/entries/${entryId}`,
      data,
      success: (response) => {
        this.endSave();
      },
    });
  }

  submitTimesheet(event) {
    const submitButton = event.target;
    submitButton.classList.add("is-submitting");

    const timesheetId = submitButton.dataset.timesheet;

    const data = {
      is_submitted: true,
      is_unlocked: false,
    };

    ajax({
      method: "PUT",
      path: `/timesheets/${timesheetId}`,
      data,
      success: (response) => {
        submitButton.classList.remove("is-submitting");
        submitButton.innerHTML = "Resubmit";

        const u = new Url();
        const newPaths = u.paths();
        newPaths.pop();
        u.paths(newPaths);
        location.href = u;
      },
    });
  }

  // Helpers

  resetSelectList(event, items) {
    const entryRow = event.target.closest(".js-entry");

    // Reset selected item
    entryRow
      .querySelector(".entry-select--role .js-select")
      .classList.remove("has-value");
    entryRow.querySelector(
      ".entry-select--role .js-select-data"
    ).innerHTML = `<div class="select__data js-select-data " data-value=""></div>`;

    // Re-render the list itself
    const inner = items
      .map(
        (item) =>
          `<li class="select__item js-select-item js-role-item" data-value="${item.id}">${item.name}</li>`
      )
      .join(" ");
    entryRow.querySelector(".entry-select--role .js-select-list").innerHTML =
      inner;
  }

  sumHours(entryHourInput) {
    // Horizontal
    const entryNode = entryHourInput.closest(".js-entry");
    this.sumEntry(entryNode);

    // Vertical
    const { weekdate } = entryHourInput.dataset;
    this.sumWeekdate(weekdate);

    // Total
    this.setTotal();
  }

  sumEntry(entryNode) {
    const totalNode = entryNode.querySelector(".js-entry-total");
    let total = Array.from(
      entryNode.querySelectorAll(".js-entry-hour-input")
    ).reduce((sum, input) => sum + util.toFloat(input.value), 0);
    total = numeral(total).format("0.[00]");
    totalNode.innerHTML = total;
  }

  sumWeekdate(weekdate) {
    const weekdateEntries = $(
      `.js-entry-hour-input[data-weekdate='${weekdate}']`
    );
    let sum = Array.from(weekdateEntries).reduce(
      (sum, input) => sum + util.toFloat(input.value),
      0
    );
    sum = numeral(sum).format("0.[00]");
    const weekDateView = $(
      `.js-timesheet-weekdate-hours[data-weekdate='${weekdate}']`
    )[0];
    if (weekDateView) weekDateView.innerHTML = sum;
  }

  sumAllWeekdates() {
    if ($(".js-entry").length > 0) {
      const hourInputs = $(".js-entry")[0].querySelectorAll(
        ".js-entry-hour-input"
      );
      const weekdates = Array.from(hourInputs).map(
        (hourInput) => hourInput.dataset.weekdate
      );
      weekdates.forEach((weekdate) => {
        this.sumWeekdate(weekdate);
      });
    }
  }

  setTotal() {
    let totalHours = Array.from($(".js-timesheet-weekdate-hours")).reduce(
      (a, b) => a + util.toFloat(b.innerHTML),
      0
    );
    totalHours = numeral(totalHours).format("0[.][00]");
    $(".js-timesheet-total-hours")[0].innerHTML = totalHours;
  }
}

const timesheetsEdit = new TimesheetsEdit();
export default timesheetsEdit;
