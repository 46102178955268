import { ComponentProps } from "react";

import { createComponent, createScaleVariant, styled } from "@plan/core";

const component = createComponent();

const color = createScaleVariant({
  scale: "colors",
  extend: { currentColor: "currentColor" },
  css: (token) => ({ color: token }),
});

export type IconProps = ComponentProps<typeof Icon>;

export const Icon = styled("svg", {
  width: "1em",
  height: "1em",
  ...component,
  variants: {
    ...component.variants,
    color,
  },
  defaultVariants: {
    ...component.defaultVariants,
    color: "currentColor",
  },
});
