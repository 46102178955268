/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconClearProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconClear = ({ label, ...props }: SvgIconClearProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        d="M17.85 16.44a.5.5 0 0 1 0 .71l-.7.7a.5.5 0 0 1-.71 0L12 13.41l-4.44 4.44a.5.5 0 0 1-.71 0l-.7-.7a.5.5 0 0 1 0-.71L10.59 12 6.15 7.56a.5.5 0 0 1 0-.71l.7-.7a.5.5 0 0 1 .71 0L12 10.59l4.44-4.44a.5.5 0 0 1 .71 0l.7.7a.5.5 0 0 1 0 .71L13.41 12l4.44 4.44Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconClear;
