import ajax from "Blocks/utils/ajax";
import React, { useState, useEffect, useMemo } from "react";
import FilterBar from "Components/blocks/FilterBar";
import WeekPicker from "Components/blocks/WeekPicker/WeekPicker";
import Loading from "Components/blocks/Loading";
import Field from "Components/blocks/forms/Field";
import Header from "Components/timesheets/timesheet/Header";
import OverheadBlock from "Components/timesheets/timesheet/overhead/OverheadBlock";
import ProjectBlock from "Components/timesheets/timesheet/project/ProjectBlock";
import ProjectSelect from "Components/timesheets/timesheet/project/ProjectSelect";
import Portal from "Components/blocks/portal/Portal";
import { eventTracker } from "Blocks/tracker";
import moment from "moment";
import Submit from "./Submit";
import LockButton from "./LockButton";
import ExportTimesheet from "./ExportTimesheet";
import styles from "./timesheet.module.css";
import {
  useGlobalState,
  resetState,
  newOverhead,
} from "./state/GlobalContext.jsx";

function Timesheet({
  activityOptions,
  profile,
  permissions,
  activityRequired,
  noteExample,
  notesRequired,
  currentProfile,
  timeOffEnabled,
  timeOffHolidayEnabled,
}) {
  const { state, dispatch } = useGlobalState();
  const [date, setDate] = useState(state.date);
  const {
    selectedProjects,
    orderedSelectedProjects,
    selectedOverheads,
    overheadOptions,
    timesheet,
    timeOffRequests,
    timeOffHolidays,
  } = state;

  const timesheetIsLocked = timesheet.isLockable && !timesheet.isUnlocked;

  const canEdit = useMemo(() => {
    if (permissions.canEditAllTimesheets) return true;
    if (timesheetIsLocked) return false;
    if (permissions.isOwnTimesheet) return true;
    return false;
  }, [timesheet]);

  useEffect(() => {
    const title = document.querySelector(".menu-page__title--status");
    if (!title) return;

    if (canEdit) {
      title.dataset.status = "Editing";
    } else {
      title.dataset.status = "Locked";
    }
  }, [canEdit]);

  const createURL = (newDate) => {
    const urlArray = location.pathname.split("/");
    urlArray[2] = state.timesheet.profileSlug;
    urlArray[3] = newDate;
    return urlArray.join("/");
  };

  const updateURL = (url) => {
    history.pushState({ turbolinks: true, url }, "", url);
  };

  const updatePageTitle = (newDate) => {
    const title = document.querySelector(".menu-page__title--status");
    if (!title) {
      return;
    }
    const formattedDate = newDate.format("MMMM DD, YYYY");
    const name = title.innerHTML.match(/\([^\)]+\)/g);
    title.innerHTML = `Week of ${formattedDate} ${name || ""}`;
  };
  const [overheadInput, setOverheadInput] = useState(null);

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref.current.offsetTop - 156, behavior: "smooth" });
  const handleOverheadScroll = () => {
    const projectRef = state.references.overhead;
    scrollToRef(projectRef);
  };

  const newOverheadHandler = (overheadData) => {
    if (state.selectedOverheads.byId[overheadData.value]) return;

    eventTracker("trackTimesheetOverheadSelect");

    const overheadId = overheadData.value;
    const overheadName = overheadData.label;
    const data = {
      timesheet_id: state.timesheet.id,
      overhead_id: overheadId,
    };
    ajax({
      method: "POST",
      path: "/entry_groups",
      data,
      success: (result) => {
        const { group, row, entries } = JSON.parse(result);
        dispatch(
          newOverhead({
            overheadName,
            overheadId,
            group,
            row,
            entries,
          })
        );
        setOverheadInput(null);
        handleOverheadScroll();
      },
    });
  };

  const [loading, setLoading] = useState("");
  const updateData = (newDate) => {
    const requestDate = newDate.format("YYYY-MM-DD");
    const newPath = createURL(requestDate);

    const url = `/timesheets/${profile.slug}/${requestDate}.json`;
    setDate(newDate);
    setLoading("fetching");

    ajax({
      method: "GET",
      path: url,
      success: (response) => {
        const newData = JSON.parse(response).data;

        // reset references
        const references = {};
        newData.selectedProjects.allIds.forEach((projectId) => {
          references[projectId] = React.createRef();
        });
        if (!references.overhead) {
          references.overhead = React.createRef();
        }
        dispatch(
          resetState({
            newData,
            date: newDate,
            references,
          })
        );
        updateURL(newPath);
        updatePageTitle(newDate, newData);
        setLoading("loaded");
      },
    });
  };
  const timeOffRequestsArray = Object.values(timeOffRequests);
  const timeOffHolidaysArray = Object.values(timeOffHolidays);

  if (loading === "fetching") return <Loading title="Loading..." />;

  const canRequestTimeOff = timeOffEnabled && canEdit;

  return (
    <>
      <div className={styles.stickyHeader}>
        <FilterBar>
          {canEdit && (
            <div className={styles.headerSearch}>
              <ProjectSelect isDisabled={loading === "empty" && true} />
            </div>
          )}
          {canEdit && (
            <div className={styles.headerSelect}>
              <Field
                as="select"
                size="small"
                placeholder="Add Overhead"
                options={overheadOptions}
                handleChange={newOverheadHandler}
                value={overheadInput}
                isDisabled={loading === "empty" && true}
              />
            </div>
          )}
          {canRequestTimeOff && (
            <div style={{ marginLeft: "1rem" }}>
              <a
                href="/time-off/me"
                style={{ color: "#5840e0", fontWeight: 600 }}
              >
                Request time off
              </a>
            </div>
          )}
          <div className={styles.headerWeekPicker}>
            <WeekPicker
              date={date}
              setDate={updateData}
              maxDate={moment(new Date()).add(1, "week").startOf("isoweek")}
            />
          </div>
        </FilterBar>
        <Header canEdit={canEdit} />
      </div>
      {loading === "empty" ? (
        <Loading title="No Timesheets for this Week" />
      ) : (
        <div className={styles.page}>
          <section className={styles.blocks}>
            {orderedSelectedProjects?.map((projectId, index) => {
              const zIndex = orderedSelectedProjects.length - index + 1; // Added 1 to ensure last project block always above Overhead block
              const project = selectedProjects.byId[projectId];
              return (
                <ProjectBlock
                  block={project}
                  activityOptions={activityOptions}
                  startOfWeek={date}
                  projectId={projectId}
                  canEdit={canEdit}
                  zIndex={zIndex}
                  key={projectId}
                  activityRequired={activityRequired}
                  noteExample={noteExample}
                  notesRequired={notesRequired}
                  permissions={permissions}
                  currentProfile={currentProfile}
                  timesheetIsLocked={timesheetIsLocked}
                />
              );
            })}
            {(selectedOverheads.allIds.length > 0 ||
              timeOffRequestsArray.length > 0 ||
              timeOffHolidaysArray.length > 0) && (
              <OverheadBlock
                key="overhead"
                startOfWeek={date}
                canEdit={canEdit}
                timeOffRequestsArray={timeOffRequestsArray}
                timeOffHolidaysArray={timeOffHolidaysArray}
                timeOffEnabled={timeOffEnabled}
                timeOffHolidayEnabled={timeOffHolidayEnabled}
              />
            )}
          </section>
        </div>
      )}
      <Portal selector=".js-header-actions">
        <Submit timesheetIsLocked={timesheetIsLocked} canEdit={canEdit} />
        <LockButton permissions={permissions} />
        <ExportTimesheet timesheet={timesheet} />
      </Portal>
    </>
  );
}

export default Timesheet;
