import React from "react";
import PropTypes from "prop-types";
import Avatar from "Components/blocks/avatar/Avatar";

class ProjectsProfiles extends React.Component {
  renderProfiles() {
    if (this.props.profileData.length > 0) {
      return (
        <>
          {this.props.profileData.map((profileDatum) => (
            <div className="profile__item" key={profileDatum.name}>
              <a
                className="profile__data"
                href={`/directory/team/${profileDatum.slug}`}
              >
                <Avatar image={profileDatum.image} />
                <div className="profile__text">
                  <span>{profileDatum.name}</span>
                  <span className="profile__roles">
                    {profileDatum.roles_sentence}
                  </span>
                </div>
              </a>
            </div>
          ))}
        </>
      );
    }
    if (this.props.canEdit) {
      return (
        <a className="button is-enabled" href={this.props.projectPlannerPath}>
          Assign team members
        </a>
      );
    }
    return "No one is assigned to this project";
  }

  render() {
    return (
      <div className="page-module">
        <h4 className="page-module__title">Team</h4>
        {this.renderProfiles()}
      </div>
    );
  }
}

ProjectsProfiles.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  projectPlannerPath: PropTypes.string.isRequired,
};

export default ProjectsProfiles;
