import React, { useMemo } from "react";
import { pickBy, reduce } from "lodash";

import {
  useProfiles,
  useAllocations,
  useOverheads,
  useTimeOff,
  useNoFilterData,
  getAllProfileEntries,
} from "../../state/Store";
import ColumnHeader from "./ColumnHeader";
import { setStatusColor } from "./setStatusColor";

function useHeaderInfo(id) {
  const profiles = useProfiles();
  const allocations = useAllocations();
  const overheads = useOverheads();
  const { timeOffRequestDays, timeOffHolidays } = useTimeOff();

  const entries = useMemo(
    () => getAllProfileEntries(allocations, id),
    [allocations, getAllProfileEntries]
  );

  const timeOffRequestHoursByProfile = useMemo(
    () =>
      reduce(
        pickBy(
          timeOffRequestDays,
          (timeOffRequestDay) =>
            timeOffRequestDay.profile_id === id && timeOffRequestDay.hours > 0
        ),
        (acc, val) => acc + Number(val.hours),
        0
      ),
    [timeOffRequestDays]
  );

  const timeOffHolidayHoursByProfile = useMemo(
    () =>
      reduce(
        pickBy(
          timeOffHolidays,
          (timeOffHoliday) =>
            timeOffHoliday.profile_id === id && timeOffHoliday.hours > 0
        ),
        (acc, val) => acc + Number(val.hours),
        0
      ),
    [timeOffHolidays]
  );

  const overheadsByProfile = useMemo(
    () =>
      reduce(
        pickBy(
          overheads,
          (overhead) => overhead.profile_id === id && overhead.hours > 0
        ),
        (acc, val) => ({
          names: [...acc.names, val.name],
          hours: acc.hours + Number(val.hours),
        }),
        { names: [], hours: 0 }
      ),
    [overheads]
  );

  const headerInfo = useMemo(
    () => ({
      name: profiles[id].name,
      avatar: profiles[id].image,
      profileSlug: profiles[id].slug,
      count: entries?.length ?? 0,
      hoursPerWeek: profiles[id].hoursPerWeek,
      overheads: overheadsByProfile,
      timeOffHours: timeOffRequestHoursByProfile + timeOffHolidayHoursByProfile,
    }),
    [profiles, entries]
  );

  return headerInfo;
}

const useAllocatedHours = (profileId) => {
  const { noFilterState } = useNoFilterData();
  const {
    entryGroups,
    timeOffRequestDays,
    overheadEntryGroups,
    timeOffHolidays,
  } = noFilterState;

  const entries = getAllProfileEntries(entryGroups.byId, profileId);
  const overheadEntries = Object.values(overheadEntryGroups.byId).filter(
    (overheadEntryGroup) => overheadEntryGroup.profile_id === profileId
  );

  const profiletimeOffRequestDays = Object.values(
    timeOffRequestDays.byId
  ).filter((timeOffRequestDay) => timeOffRequestDay.profile_id === profileId);

  const profiletimeOffHolidays = Object.values(timeOffHolidays.byId).filter(
    (timeOffRequestDay) => timeOffRequestDay.profile_id === profileId
  );

  const hoursAllocated = entries.reduce(
    (acc, val) => acc + Number(entryGroups.byId[val].allocation),
    0
  );
  const timeOffHours = profiletimeOffRequestDays.reduce(
    (acc, val) => acc + Number(val.hours),
    0
  );
  const timeOffHolidayHours = profiletimeOffHolidays.reduce(
    (acc, val) => acc + Number(val.hours),
    0
  );
  const overheadHoursAllocated = overheadEntries.reduce(
    (acc, val) => acc + Number(val.hours),
    0
  );

  const totalHours =
    hoursAllocated +
    overheadHoursAllocated +
    timeOffHours +
    timeOffHolidayHours;

  return totalHours;
};

const ProfileHeader = ({ id }) => {
  const {
    name,
    avatar,
    hoursPerWeek,
    count,
    overheads,
    profileSlug,
    timeOffHours,
  } = useHeaderInfo(id);
  const totalHours = useAllocatedHours(id);
  const badgeColor = setStatusColor(totalHours, hoursPerWeek);

  return (
    <ColumnHeader
      badgeColor={badgeColor}
      name={name}
      avatar={avatar}
      hours={totalHours}
      count={count}
      hoursPerWeek={hoursPerWeek}
      id={id}
      overheads={overheads}
      timeOffHours={timeOffHours}
      profileSlug={profileSlug}
    />
  );
};

export default React.memo(ProfileHeader);
