import React from "react";

export const RoleIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="4.75" r="1.25" fill="var(--project-color)" />
    <rect
      x="4"
      y="6"
      width="5"
      height="3.33333"
      rx="1.66667"
      fill="var(--project-color)"
    />
    <rect
      x="4"
      y="7.66675"
      width="5"
      height="1.66667"
      fill="var(--project-color)"
    />
    <path
      d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5Z"
      fill="var(--project-color)"
      fillOpacity="0.15"
    />
  </svg>
);

export const NoRoleIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="4.75" r="1.25" fill="var(--project-color)" />
    <rect
      x="4"
      y="6"
      width="5"
      height="3.33333"
      rx="1.66667"
      fill="var(--project-color)"
    />
    <rect
      x="4"
      y="7.66675"
      width="5"
      height="1.66667"
      fill="var(--project-color)"
    />
    <path
      d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5Z"
      stroke="var(--project-color)"
    />
    <line
      x1="2.64645"
      y1="10.6464"
      x2="11.1317"
      y2="2.16117"
      stroke="var(--project-color)"
    />
  </svg>
);
