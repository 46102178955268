import React, { useMemo } from "react";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import { getAllProfileEntries } from "./Store";

export const TeamOrderContext = React.createContext([]);

// When a phase is selected, we bring all the team members with related allocations
// to the front of the list
const reorderTeamOnPhaseSelect = (teamMembers, selected, entryGroups) => {
  if (!selected) return teamMembers;

  const selectedList = [];

  const unselectedList = teamMembers.filter((member) => {
    const memberAllocations = getAllProfileEntries(entryGroups, member);
    // grab the members that DO have a allocations with the selected phase
    /// and put them in another array
    if (memberAllocations.some((id) => entryGroups[id].phaseId === selected)) {
      selectedList.push(member);
      return false;
    }
    return true;
  });
  return [...selectedList, ...unselectedList];
};

// When we filter by a project we bring all the team members with related allocations
// to the front of the list
const reorderTeamOnProjectSelect = (teamMembers, selected, entryGroups) => {
  if (!selected) return teamMembers;

  const selectedList = [];

  const unselectedList = teamMembers.filter((member) => {
    const memberAllocations = getAllProfileEntries(entryGroups, member);
    // grab the members that DO have a allocations with the selected phase
    /// and put them in another array
    if (
      memberAllocations.some((id) => entryGroups[id].projectId === selected)
    ) {
      selectedList.push(member);
      return false;
    }
    return true;
  });
  return [...selectedList, ...unselectedList];
};

// When we filter by a category we bring all the team members with any allocations
// to the front of the list (the list is already filtered here)
const reorderTeamOnCategorySelect = (
  teamMembers,
  selectedCategory,
  entryGroups
) => {
  if (!selectedCategory) return teamMembers;
  // entryGroups here will already be filtered to this particular category (the backend is filtering before this point),
  // so I don't need to compare anything, I just need to find team members with any allocations and push them to the front

  const selectedList = [];
  const unselectedList = teamMembers.filter((member) => {
    const memberAllocations = getAllProfileEntries(entryGroups, member);
    // If any memberAllocations exist, that means the there's an entry for this category,
    // so we can push this user into selectedList
    if (memberAllocations.length) {
      selectedList.push(member);
      return false;
    }
    return true;
  });
  return [...selectedList, ...unselectedList];
};

export default function TeamOrderProvider({
  allIds,
  allocations,
  selectedPhase,
  filteredProject,
  filteredCategoryIds,
  filteredProfileIds,
  children,
}) {
  const teamOrder = useMemo(() => {
    if (filteredProject)
      return reorderTeamOnProjectSelect(allIds, filteredProject, allocations);
    if (filteredCategoryIds)
      return reorderTeamOnCategorySelect(
        allIds,
        filteredCategoryIds,
        allocations
      );
    return reorderTeamOnPhaseSelect(allIds, selectedPhase, allocations);
  }, [allIds, allocations, selectedPhase, filteredProject]);

  const filteredTeamOrder = useMemo(() => {
    if (_isEmpty(filteredProfileIds)) return teamOrder;

    return _filter(teamOrder, (profileId) =>
      filteredProfileIds.includes(profileId)
    );
  }, [filteredProfileIds, teamOrder]);

  return (
    <TeamOrderContext.Provider value={filteredTeamOrder}>
      {children}
    </TeamOrderContext.Provider>
  );
}
