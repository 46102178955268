import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./buttons.module.css";

export function Button({
  type,
  variant,
  textColor,
  size,
  color,
  text,
  action,
  disabled,
  classes,
  children,
  // Legacy
  buttonStyle,
  buttonType,
  // Remaining
  ...props
}) {
  return (
    <button
      type={buttonType ?? type}
      onClick={action}
      aria-disabled={disabled}
      disabled={disabled}
      className={cx(
        styles.button,
        { [styles[variant]]: variant },
        { [styles[textColor]]: textColor },
        { [styles[size]]: size },
        { [styles[color]]: color },
        { [styles[`button--${buttonStyle}`]]: buttonStyle },
        ...classes
      )}
      {...props}
    >
      {text && text}
      {children}
    </button>
  );
}

Button.propTypes = {
  variant: PropTypes.oneOf(["default", "rounded", "borderless"]),
  color: PropTypes.oneOf(["action", "cancel", "delete", "outline", "gray"]),
  textColor: PropTypes.oneOf(["red"]),
  size: PropTypes.oneOf(["extra-small", "small", "small-medium", "medium"]),
  type: PropTypes.oneOf(["button", "submit"]),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  action: PropTypes.func,
  text: PropTypes.node,
  classes: PropTypes.arrayOf(PropTypes.string),
};

Button.defaultProps = {
  variant: "default",
  color: "action",
  size: "medium",
  type: "button",
  disabled: false,
  children: null,
  action: null,
  text: "",
  classes: [],
};

export default Button;
