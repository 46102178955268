import React from "react";

export default class Segment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="segment">
        {this.props.options.map((option) => {
          return (
            <button
              key={option.value}
              className={`segment__option${
                this.props.selectedValue === option.value ? " is-selected" : ""
              }`}
              onClick={() => this.props.onSelect(option.value)}
              style={this.props.style}
            >
              {option.name}
            </button>
          );
        })}
      </div>
    );
  }
}
