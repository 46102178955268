import util from "Blocks/utils/util";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import ajax from "Blocks/utils/ajax";

function resetRoleSelectList(entryRow, items) {
  // Reset selected item
  entryRow
    .querySelector(".entry-select--role .js-select")
    .classList.remove("has-value");
  entryRow.querySelector(
    ".entry-select--role .js-select-data"
  ).innerHTML = `<div class="select__data js-select-data " data-value=""></div>`;

  // Re-render the list itself
  const inner = items
    .map(
      (item) =>
        `<li class="select__item js-select-item js-role-item" data-value="${item.id}">${item.name}</li>`
    )
    .join(" ");
  entryRow.querySelector(".entry-select--role .js-select-list").innerHTML =
    inner;
}

class TimesheetsAudit extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-open-delete-entry-options", (event) => {
      this.openDeleteOptions(event);
    });
    bindListenerToDocument("click", "js-cancel-entry-delete", (event) => {
      this.closeDeleteOptions(event);
    });
    bindListenerToDocument("click", "js-delete-entry-row", (event) => {
      this.deleteEntryRow(event);
    });
    bindListenerToDocument("click", "js-phase-item", (event) => {
      this.updatePhase(event);
    });
    bindListenerToDocument("click", "js-role-item", (event) => {
      this.updateRole(event);
    });
    bindListenerToDocument("click", "js-activity-item", (event) => {
      this.updateActivity(event);
    });
  }

  // Methods

  openDeleteOptions(event) {
    const entry = event.target.closest(".js-timesheet-block");
    entry.classList.add("is-deletable");
  }

  closeDeleteOptions(event) {
    if (event.target.classList.contains("js-cancel-entry-delete")) {
      const entry = event.target.closest(".js-timesheet-block");
      entry.classList.remove("is-deletable");
    }
  }

  deleteEntryRow(event) {
    this.startSave();
    const entryRowId = event.target.dataset.entryRow;

    ajax({
      method: "DELETE",
      path: `/entry_rows/${entryRowId}`,
      success: () => {
        const entryRow = event.target.closest(".js-entry-row-container");
        util.deleteElement(entryRow);
        const count = $(".js-entry-row-count")[0];
        if (count) {
          const newCount = parseInt(count.innerHTML) - 1;
          count.innerHTML = `${newCount} entry rows`;
        }

        this.endSave();
      },
    });
  }

  updatePhase(event) {
    this.startSave();
    const phaseId = event.target.dataset.value;
    const entryRowContainer = event.target.closest(".js-entry-row");
    const entryRowId = entryRowContainer.dataset.id;
    const data = { phase_id: phaseId };

    ajax({
      method: "PUT",
      path: `/entry_rows/${entryRowId}/audit_update_phase`,
      data,
      success: (response) => {
        const validRoles = JSON.parse(response);
        resetRoleSelectList(entryRowContainer, validRoles);
        this.endSave();
      },
    });
  }

  updateRole(event) {
    this.startSave();
    const roleId = event.target.dataset.value;
    const entryRowContainer = event.target.closest(".js-entry-row");
    const entryRowId = entryRowContainer.dataset.id;
    const data = { role_id: roleId };

    ajax({
      method: "PUT",
      path: `/entry_rows/${entryRowId}/audit_update_role`,
      data,
      success: () => {
        this.endSave();
      },
    });
  }

  updateActivity(event) {
    this.startSave();
    const activityId = event.target.dataset.value;
    const entryRowContainer = event.target.closest(".js-entry-row");
    const entryRowId = entryRowContainer.dataset.id;
    const data = { activity_id: activityId };

    ajax({
      method: "PUT",
      path: `/entry_rows/${entryRowId}/`,
      data,
      success: () => {
        this.endSave();
      },
    });
  }
}

const timesheetsAudit = new TimesheetsAudit();
export default timesheetsAudit;
