import React, { useRef } from "react";
import { motion } from "framer-motion";
import FlexWrapper from "Components/blocks/wrappers/FlexWrapper";
import LoadingGrid from "./emptyStates/LoadingGrid";
import NoProjectsOverlay from "./emptyStates/NoProjectsOverlay";
import useGridHeight from "./useGridHeight";
import useScrollSync from "./useScrollSync";
import { useTeamOrder, usePhaseOrder, useProjects } from "../state/Store";
import { COLUMN_MIN_WIDTH, COLUMN_MAX_WIDTH } from "../scheduleConfig";
import TeamColumn from "./columns/TeamColumn";
import Headers from "./headers/Headers";
import Column from "./columns/Column";
import styles from "./grid.module.css";

export const Grid = () => {
  const grid = useRef();
  const phaseOrder = usePhaseOrder();
  const teamOrder = useTeamOrder();
  const { scroll, isDragging, setDragging } = useScrollSync(grid);

  if (!phaseOrder.length && !teamOrder.length) return <LoadingGrid />;

  return (
    <FlexWrapper direction="column">
      <Headers scroll={scroll} order={teamOrder} />
      <AllocationGrid
        scroll={scroll}
        order={teamOrder}
        forwardRef={grid}
        isDragging={isDragging}
        setDragging={setDragging}
      />
    </FlexWrapper>
  );
};

const AllocationGrid = React.memo(
  ({ order, scroll, forwardRef, isDragging, setDragging }) => {
    const height = useGridHeight(forwardRef, "auto");
    const hasProjects = useProjects();

    const gridStyles = {
      height,
      overflowX: "hidden",
      overflowY: "hidden",
      userSelect: isDragging ? "none" : "auto",
      cursor: isDragging ? "grabbing" : "grab",
    };

    return (
      // Keep the team column out of the grid so we can animate
      <FlexWrapper>
        <TeamColumn />
        <div className={styles.grid} style={gridStyles}>
          <GridScroller
            scroll={scroll}
            forwardRef={forwardRef}
            length={order.length}
            isDragging={isDragging}
          >
            {order.map((id, i) => (
              <Column id={id} i={i} key={id} />
            ))}
          </GridScroller>
          {!hasProjects && <NoProjectsOverlay />}
        </div>
      </FlexWrapper>
    );
  }
);

const GridScroller = ({ scroll, forwardRef, length, isDragging, children }) => {
  const gridScrollerStyles = {
    display: "grid",
    gridTemplateColumns: `repeat(${length}, minmax(${COLUMN_MIN_WIDTH}, ${COLUMN_MAX_WIDTH}))`,
    width: "100%",
    minHeight: "100%",
    overflowX: "scroll",
    overflowY: "visible",
    background: "var(--color-legacy-gray-2)",
    userSelect: isDragging ? "none" : "auto",
    cursor: isDragging ? "grabbing" : "grab",
    position: "relative",
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
  };

  // If we're dragging or scrolling we sync the scroll value to the headers
  const handleDrag = (e, info) => {
    scroll.set(
      forwardRef.current.scrollLeft - (e.movementX + info.velocity.x / 100)
    );
  };

  const handleScroll = (e) => {
    e.preventDefault();
    if (isDragging) return;
    scroll.set(forwardRef.current.scrollLeft);
  };

  return (
    <motion.div
      ref={forwardRef}
      style={gridScrollerStyles}
      whileTap={{ cursor: "grabbing" }}
      drag="x"
      dragElastic={0}
      onDrag={handleDrag}
      onScroll={handleScroll}
      dragConstraints={forwardRef}
    >
      {children}
    </motion.div>
  );
};

export default React.memo(Grid);
