import React from "react";
import { motion } from "framer-motion";
import styles from "./field-action.module.css";

export function FieldAction({
  handleClick,
  children,
  ghosted,
  classes,
  disabled,
}) {
  return (
    <div className={`${children.length > 0 ? styles.wrap : ""} ${classes}`}>
      <motion.button
        className={`${ghosted ? styles.ghosted : styles.action}`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 1.02 }}
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </motion.button>
    </div>
  );
}

export default React.memo(FieldAction);
