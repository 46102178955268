/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import propTypes from "prop-types";
import NextIcon from "../icons/Next";
import PrevIcon from "../icons/Prev";
import CalendarDay from "./CalendarDay";
import styles from "./week-picker.module.css";
import NavButton from "./NavButton";
import "react-dates/initialize";

// Helper function, might be useful elsewhere
// returns the current 'isoweek' (M-S) of a date
function calcActiveWeek(date) {
  const mon = moment(date).clone().startOf("isoweek");
  const tue = mon.clone().add(1, "d");
  const wed = mon.clone().add(2, "d");
  const thu = mon.clone().add(3, "d");
  const fri = mon.clone().add(4, "d");
  const sat = mon.clone().add(5, "d");
  const sun = mon.clone().add(6, "d");
  return [mon, tue, wed, thu, fri, sat, sun];
}

export function WeekPicker({ date, setDate, maxDate }) {
  const [focused, setFocused] = useState(false);
  const [hoveredDay, setHoveredDay] = useState("");

  function isDateTooLate(dateToCheck) {
    return (
      maxDate && moment(dateToCheck).isAfter(moment(maxDate).startOf("isoweek"))
    );
  }

  // Handler for Week Navigation buttons
  function handleClick(e) {
    const newDate = e.startOf("isoweek");
    setDate(newDate);
  }

  function handleFocus() {
    setFocused(() => !focused);
  }

  // Handler for Calendar Picker
  function handleChange(e) {
    // if its not a valid date when typing
    if (!e) return;
    const newDate = e.startOf("isoweek");

    if (isDateTooLate(newDate)) return;

    setDate(newDate);
    handleFocus();
  }

  const lastWeek = () => moment(date).add(-1, "weeks");
  const nextWeek = () => moment(date).add(1, "weeks");

  function renderCalendarDay(day) {
    return (
      <CalendarDay
        handleClick={handleChange}
        hoveredDay={hoveredDay}
        setHoveredDay={setHoveredDay}
        key={day.key}
        date={day}
        renderCalendarDay={renderCalendarDay}
        selectedWeek={calcActiveWeek(date)}
        hoveredWeek={calcActiveWeek(hoveredDay)}
      />
    );
  }

  const canGoNextWeek = isDateTooLate(moment(date).add(1, "weeks"));

  return (
    <div className={styles.weekPicker}>
      <NavButton variant="prev" handleClick={() => handleClick(lastWeek())}>
        <PrevIcon />
      </NavButton>
      <SingleDatePicker
        onDateChange={handleChange}
        focused={focused}
        onFocusChange={handleFocus}
        date={date}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        isDayBlocked={() => false}
        isOutsideRange={() => false}
        id="single_date_picker"
        displayFormat="YYYY/MM/DD"
        renderCalendarDay={renderCalendarDay}
      />
      <NavButton
        variant="next"
        disabled={canGoNextWeek}
        handleClick={() => handleClick(nextWeek())}
      >
        <NextIcon />
      </NavButton>
    </div>
  );
}

WeekPicker.propTypes = {
  date: propTypes.string.isRequired,
  setDate: propTypes.func.isRequired,
  maxDate: propTypes.string,
};

WeekPicker.defaultProps = {
  maxDate: "",
};

export default WeekPicker;
