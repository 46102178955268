import React, { useState } from "react";
import moment from "moment";
import styles from "./header.module.css";

function HeaderEntry({ date, hours }) {
  return (
    <div className={styles.headerEntry}>
      <div className={styles.headerTitle}>
        <span className={styles.headerEntryMonth}>
          {moment(date).format("MMM")}
        </span>
        <span className={styles.headerEntryDay}>
          {moment(date).format("D")}
        </span>
      </div>
      <div className={styles.headerGroup}>
        <span className={styles.headerValue}>{hours}</span>
      </div>
    </div>
  );
}

export default HeaderEntry;
