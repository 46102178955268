import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./figure.module.css";

export const Figure = ({ label, value, variant }) => {
  return (
    <div className={cx(styles.wrapper, { [styles[variant]]: variant })}>
      <div className={styles.value}>{value}</div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

Figure.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  variant: PropTypes.oneOf(["highlighted"]),
};

export default Figure;
