import { FC } from "react";

import { styled } from "@plan/core";
import { Box, Flex } from "@plan/layout";
import { Button } from "@plan/components";

import { TimerEntryFragment } from "../../../../../../apps/web/packages/app/generated/graphql";
import { useTimerContext } from "../../contexts/TimerContext";
import { useTimerModalViewContext } from "../../contexts/TimerModalViewContext";

const TimerEntriesItemPlay = styled(Button, {
  flexShrink: 0,
  width: "$space$8",
  height: "$space$8",
  borderRadius: "50%",
  border: "1px solid $colors$-neutral10",
  textAlign: "center",
  background: "$white",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    top: "9px",
    left: "12px",
    borderTop: "6px solid transparent",
    borderBottom: "6px solid transparent",
    borderLeft: "10px solid $colors$-neutral10",
    height: "0px",
  },
});

const TimerEntriesItem = styled(Flex, {
  justifyContent: "center",
  gap: "$space$4",
  padding: "$space$1_5 $space$4",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$white",
    [`& > ${TimerEntriesItemPlay}`]: {
      backgroundColor: "$colors$-neutral10",
      cursor: "pointer",
      "&::after": {
        borderLeft: "10px solid $white",
      },
    },
  },
});

const TimerEntriesItemContent = styled(Box, {
  display: "flex",
});

const TimerEntryItemsSubtext = styled(Box, {
  fontSize: "$fontSizes$sm",
  color: "$colors$neutral",
  marginTop: "$space$1",
});

const TimerEntryItemsTime = styled(Box, {
  marginLeft: "auto",
  fontSize: "$fontSizes$md",
  fontWeight: "$semibold",
});

const TimerEntriesItemProject = styled("h2", {
  fontSize: "$fontSizes$lg",
  fontWeight: "$semibold",
});

interface EntryProps {
  entry: TimerEntryFragment;
}

export const TimerEntryListItem: FC<EntryProps> = ({ entry }) => {
  const { showEntryForm } = useTimerModalViewContext();
  const {
    actions: { selectTimerEntry },
  } = useTimerContext();

  const listItemMeta = [
    entry.phase?.name,
    entry.role?.name,
    entry.activity?.name,
  ]
    .filter((name) => !!name)
    .join(" · ");

  const handleListItemClick = (entry: TimerEntryFragment) => {
    selectTimerEntry(entry);
    showEntryForm();
  };

  return (
    <TimerEntriesItem key={entry.id} onClick={() => handleListItemClick(entry)}>
      <TimerEntriesItemPlay />
      <TimerEntriesItemContent>
        <Box>
          <TimerEntriesItemProject>
            {entry.project?.name}
          </TimerEntriesItemProject>
          <TimerEntryItemsSubtext>{listItemMeta}</TimerEntryItemsSubtext>
        </Box>
      </TimerEntriesItemContent>
      <TimerEntryItemsTime>{entry.hours.toFixed(2)}</TimerEntryItemsTime>
    </TimerEntriesItem>
  );
};
