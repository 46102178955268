import * as React from "react";

import { createComponent, styled } from "@plan/core";

import { PopoverContent, PopoverTrigger } from "./elements";

import * as PopoverPrimitive from "@radix-ui/react-popover";

const component = createComponent();

export const PopoverRoot = styled(PopoverPrimitive.Root, {
  ...component,
  backgroundColor: "$white",
});

export const PopoverArrow = styled(PopoverPrimitive.Arrow, {
  fill: "white",
});

type PopoverProps = Pick<
  React.ComponentProps<typeof PopoverContent>,
  "align"
> & {
  trigger?: React.ReactNode;
} & React.ComponentProps<typeof PopoverTrigger>;

export const Popover: React.FC<PopoverProps> = ({
  children,
  trigger,
  align = "start",
  ...rest
}) => (
  <PopoverRoot>
    <PopoverTrigger {...rest}>{trigger}</PopoverTrigger>
    <PopoverContent align={align}>
      {children}
      <PopoverArrow offset={10} />
    </PopoverContent>
  </PopoverRoot>
);

export { PopoverContent, PopoverTrigger };
