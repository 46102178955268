import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useHoverDirty } from "react-use";
import { getCurrencySymbol } from "Utils/util";
import PropTypes from "prop-types";

export const FieldContext = React.createContext({});

export const FieldProvider = React.memo((props) => {
  const {
    value,
    isFocused,
    isDisabled,
    noFloatingLabel = false,
    treatZeroAsEmpty = false,
    as,
    warnIfIncomplete,
    startDate,
    endDate,
  } = props;

  const isEmpty = useCallback(
    (val) => {
      if (treatZeroAsEmpty && parseFloat(val) === 0) {
        return true;
      }
      return val === null || val === undefined || val === "";
    },
    [treatZeroAsEmpty]
  );

  const checkForValue = useCallback(
    (val) => {
      if (noFloatingLabel) return true;
      return !isEmpty(val);
    },
    [noFloatingLabel, isEmpty]
  );

  const hoverRef = useRef(null);
  const isHovered = useHoverDirty(hoverRef);
  const [hasValue, setHasValue] = useState(checkForValue(value));
  const [isIncomplete, setIsIncomplete] = useState(!(endDate && startDate));

  const [focused, setFocused] = useState(isFocused);
  const [disabled, setDisabled] = useState(isDisabled);
  const [currencySymbol, setCurrencySymbol] = useState();

  useEffect(() => {
    setCurrencySymbol(getCurrencySymbol(localeTag, currencyCode));
  }, [localeTag, currencyCode]);

  useEffect(() => {
    setHasValue(checkForValue(value));
  }, [value]);

  useEffect(() => {
    if (as === "dates" && warnIfIncomplete) {
      setIsIncomplete(!(endDate && startDate));
    }
  }, [as, endDate, startDate, setIsIncomplete]);

  return (
    <FieldContext.Provider
      value={{
        ...props,
        hasValue,
        isIncomplete,
        setIsIncomplete,
        setHasValue,
        focused,
        setFocused,
        disabled,
        setDisabled,
        hoverRef,
        isHovered,
        currencySymbol,
      }}
    >
      {props.children}
    </FieldContext.Provider>
  );
});

FieldProvider.propTypes = {
  handleChange: PropTypes.func,
  // value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.node,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.string,
};

export const useFieldState = () => useContext(FieldContext);
