import React, { useState, useEffect } from "react";
import ajax from "Blocks/utils/ajax";
import Field from "Components/blocks/forms/Field";
import { useGlobalState, updateRow, addRow } from "../state/GlobalContext";
import ActivityButton from "./ActivityButton";
import styles from "./project.module.css";

function ActivitySelect({
  activityOptions,
  projectId,
  groupId,
  rowId,
  canEdit,
  lastRow,
}) {
  const { state, dispatch } = useGlobalState();

  const selectedActivity = state.rows.byId[rowId].activityId;
  const selectedActivityName = state.rows.byId[rowId].activityName;
  const displayText = selectedActivityName
    ? { value: rowId, label: selectedActivityName }
    : null;

  const filterActivities = () => {
    const list = activityOptions
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((activity) => {
        return { label: activity.name, value: activity.id };
      });
    return list;
  };
  const [activities, setActivities] = useState(filterActivities());

  const newRowHandler = (groupId) => {
    const data = { entry_group_id: groupId };
    ajax({
      method: "POST",
      path: "/entry_rows",
      data,
      success: (result) => {
        const { row, entries } = JSON.parse(result);
        dispatch(
          addRow({
            rowId: row.id,
            groupId,
            projectId,
            isApproved: row.isApproved,
            entries: entries,
          })
        );
      },
    });
  };

  const handleSelect = (data) => {
    const newActivityName = data.label;
    const newActivityId = data.value;
    if (newActivityId !== selectedActivity) {
      const path = `/entry_rows/${rowId}`;
      const data = {
        activity_id: newActivityId,
      };
      ajax({
        method: "PUT",
        path: path,
        data,
        success: (response) => {
          dispatch(
            updateRow({
              rowId,
              activityId: newActivityId,
              activityName: newActivityName,
            })
          );
          const newList = filterActivities(newActivityId);
          setActivities(newList);
        },
      });
    }
  };

  useEffect(() => {
    setActivities(filterActivities(selectedActivity));
  }, [state]);

  return (
    <div className={styles.activitySelect}>
      <Field
        as="select"
        size="small"
        name="activity"
        options={activities}
        value={displayText}
        placeholder="Activity"
        handleChange={handleSelect}
      />
      {canEdit && lastRow && (
        <ActivityButton
          newRowHandler={newRowHandler}
          groupId={groupId}
          key={rowId}
        />
      )}
    </div>
  );
}

export default ActivitySelect;
