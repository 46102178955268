import React from "react";

// This Field component is should not be added to new components
// instead use '/forms/Field' for anything new

// Props
// selectedValue: "value"
// onChange: fn()
// keyName: "keyName"
// toggleEdit: false
// disabled: false
// title: "Title",
// fieldName:
// type: "text"
// min: -100
// max: 100
// required: false
// requiredError: "This field is required"
// size: "large"

export default class Field extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.renderToggleEditButtons = this.renderToggleEditButtons.bind(this);

    this.state = {
      editabilityToggledOn: false,
      isFocused: false,
    };

    if (this.props.toggleEdit) {
      this.state.value = this.props.selectedValue;
    }
  }

  isDisabled() {
    if (this.props.toggleEdit) {
      return !this.state.editabilityToggledOn;
    }
    return this.props.disabled;
  }

  onChange(value) {
    if (this.props.keyName) {
      this.props.onChange({
        [this.props.keyName]: value,
      });
    } else {
      this.props.onChange(value);
    }
  }

  renderToggleEditButtons() {
    if (this.props.toggleEdit) {
      return (
        <>
          <button
            type="button"
            className="field__button field__button--save"
            onClick={() => this.onChange(this.state.value)}
          >
            Save
          </button>
          <button
            type="button"
            className="field__button field__button--toggle"
            onClick={() =>
              this.setState({
                editabilityToggledOn: !this.state.editabilityToggledOn,
              })
            }
          />
        </>
      );
    }
  }

  renderErrors() {
    if (this.props.required) {
      return (
        <div className="field__error">
          {this.props.requiredError || "This field is required"}
        </div>
      );
    }
  }

  hasValue() {
    const { selectedValue } = this.props;
    return (
      selectedValue !== null &&
      selectedValue !== undefined &&
      selectedValue !== ""
    );
  }

  // Things that I did not replace:
  // options[:toggleEdit]
  // data-type=field_options[:type]
  // js-field-title
  // js-field-input
  // options[:class]
  // field_options[:data] (key value pairs)
  // js-field-save
  // toggleEditSaveClass
  // js-field-toggle

  render() {
    return (
      <div
        className={`field${
          this.isDisabled() ? " is-disabled" : " is-editable"
        }${this.hasValue() ? " has-value" : ""}${
          this.state.isFocused ? " is-focused" : ""
        }${this.props.size ? ` field--${this.props.size}` : ""}`}
        onFocus={() => this.setState({ isFocused: true })}
        onBlur={() => this.setState({ isFocused: false })}
        data-type={this.props.type}
      >
        <div className="field__block">
          <label className="field__label">{this.props.label}</label>
          <input
            className="field__input"
            name={this.props.fieldName}
            type={this.props.type || "text"}
            value={
              this.state.editabilityToggledOn
                ? this.state.value
                : this.props.selectedValue || ""
            }
            required={this.props.required}
            tabIndex={this.isDisabled() ? -1 : 0}
            disabled={this.isDisabled() ? "disabled" : null}
            min={this.props.min || null}
            max={this.props.max || null}
            onChange={(e) =>
              this.props.toggleEdit
                ? this.setState({ value: e.target.value })
                : this.onChange(e.target.value)
            }
            onBlur={(e) =>
              this.props.onBlur ? this.props.onBlur(e.target.value) : null
            }
            placeholder={this.props.placeholder || null}
          />
          {this.renderToggleEditButtons()}
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
