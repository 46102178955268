const bindListenerToDocumentGlobal = (event, elementClass, callback) => {
  if (Array.isArray(elementClass)) {
    document.addEventListener(event, (event) => {
      if (
        elementClass.some((element) => event.target.classList.contains(element))
      ) {
        callback(event);
      }
    });
  } else {
    document.addEventListener(event, (event) => {
      if (event.target.classList.contains(elementClass)) {
        callback(event);
      }
    });
  }
};

export default bindListenerToDocumentGlobal;
