import { useState } from "react";

export function useToggle(initial = false) {
  const [state, setState] = useState(initial);

  function toggle() {
    return setState((prev) => !prev);
  }
  return [state, toggle];
}

export default useToggle;
