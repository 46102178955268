import { styled } from "@plan/core";

import { TimerEntryForm } from "./EntryForm";
import { TimerEntriesList } from "./TimerEntriesList";

const TimerMain = styled("main", {
  backgroundColor: "$colors$-neutral50",
  borderRadius: "5px",
});

export const TimerModalBody = () => {
  return (
    <TimerMain>
      <TimerEntriesList />
      <TimerEntryForm />
    </TimerMain>
  );
};
