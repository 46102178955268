/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconSearchProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconSearch = ({ label, ...props }: SvgIconSearchProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.875 15.455 4 4.005a.501.501 0 0 1-.02.691l-.7.7a.5.5 0 0 1-.71 0l-4-4.004a2.612 2.612 0 0 1-.3-.36l-.75-1.001a6.995 6.995 0 0 1-4.37 1.531A7.011 7.011 0 0 1 6.973 3.69a6.995 6.995 0 0 1 8.518 1.971 7.015 7.015 0 0 1-.046 8.753l1 .691c.156.1.3.218.43.35ZM5.025 10.01a5.003 5.003 0 0 0 5 5.006 4.997 4.997 0 0 0 3.535-1.466 5.01 5.01 0 0 0 1.465-3.54 5.003 5.003 0 0 0-5-5.006c-2.761 0-5 2.241-5 5.006Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconSearch;
