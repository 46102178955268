import React from "react";
import { Warning } from "Components/blocks/icons";
import styles from "./message.module.css";

export const MessageIcon = ({ icon }) => {
  switch (icon) {
    case "warning":
      return <Warning />;
    default:
      return <Warning />;
  }
};

export function Message({ message, children, variant }) {
  return (
    <p className={styles.message}>
      <MessageIcon icon={variant} /> {message} {children}
    </p>
  );
}

export default Message;
