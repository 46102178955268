import { FC } from "react";

import {
  TimerEntryFragment,
  UpdateEntryInput,
} from "../../../../../../../apps/web/packages/app/generated/graphql";
import { useTimerContext } from "../../../contexts/TimerContext";

import { FormInputs } from "./FormInputs";

import { Formik, FormikHelpers } from "formik";

interface UpdateEntryFormProps {
  entry: TimerEntryFragment;
}

export const UpdateEntryForm: FC<UpdateEntryFormProps> = ({ entry }) => {
  const initialStatus = { intent: "update", entryProjectId: entry.project?.id };

  const {
    actions: { updateTimerEntry },
  } = useTimerContext();

  const handleUpdateEntry = async (
    data: UpdateEntryInput,
    { resetForm, setStatus }: FormikHelpers<UpdateEntryInput>
  ) => {
    await updateTimerEntry(data);
    resetForm({ values: data });

    setStatus({
      ...initialStatus,
      saved: true,
    });

    setTimeout(() => {
      setStatus({
        ...initialStatus,
        saved: false,
      });
    }, 5000);
  };

  return (
    <Formik<UpdateEntryInput>
      initialValues={{
        entryId: entry.id,
        date: entry.date,
        phaseId: entry.phase?.id,
        roleId: entry.role?.id,
        activityId: entry.activity?.id,
        notes: entry.notes,
      }}
      initialStatus={{ ...initialStatus }}
      onSubmit={handleUpdateEntry}
    >
      <FormInputs />
    </Formik>
  );
};
