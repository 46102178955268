import { styled } from "@plan/core";
import { IconCaretRight } from "@plan/icons";

export const Expander = styled(IconCaretRight, {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "$transitions$toggle",
  variants: {
    isExpanded: {
      true: {
        transform: "rotate(90deg)",
      },
    },
  },
});
