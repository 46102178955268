import { useContext, useState } from "react";
import * as React from "react";

type HighlightedId = string | number | null;
type HighlightedSetter = React.Dispatch<React.SetStateAction<HighlightedId>>;
type HighlightTuple = [HighlightedId, HighlightedSetter];
export const HighlightedContext = React.createContext<HighlightTuple | null>(
  null
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const HighlightedProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [highlightedId, setHighlightedId] = useState<HighlightedId>(null);

  return (
    <HighlightedContext.Provider value={[highlightedId, setHighlightedId]}>
      {children}
    </HighlightedContext.Provider>
  );
};
export function useHighlightedContext() {
  const context = useContext(HighlightedContext);
  if (!context)
    throw new Error(
      "useHighlightedContext must be used within a HighlightedContext"
    );

  return context;
}

/**
 * `useIsHighlighted` checks if a given id is highlighted, either when
 * the user is hovered over the bar, or hovered over the barStack's
 * parent id, as given by the chart context `useHighlightedContext`.
 *
 * Params: `id` of the bar
 * returns
 * `isHighlighted` boolean to pass into BarSegment
 * `setHighlight` function to set on `BarSegment` on mouse events
 *
 */
export function useIsHighlighted(
  id: HighlightedId
): [boolean, HighlightedSetter] {
  const [highlightedId, setHighlightedId] = useHighlightedContext();

  const isHighlighted = !highlightedId || highlightedId === id;

  return [isHighlighted, setHighlightedId];
}
