import React from "react";

export default function StatusCompleted(classes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <circle cx="16" cy="16" r="16" fill="#F7F9FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2929 12.7157C19.6827 12.3211 20.3192 12.3191 20.7114 12.7114L21.4172 13.4171C21.8066 13.8065 21.8079 14.4373 21.4203 14.8283L15.7678 20.5294C15.3777 20.9228 14.7422 20.9242 14.3505 20.5325L10.7071 16.8891C10.3166 16.4985 10.3166 15.8654 10.7071 15.4748L11.4142 14.7677C11.8047 14.3772 12.4379 14.3772 12.8284 14.7677L14.3492 16.2885C14.7414 16.6807 15.3779 16.6788 15.7677 16.2842L19.2929 12.7157Z"
        fill="#DFE2E4"
      />
    </svg>
  );
}
