import bindListenerToDocumentGlobal from "Blocks/utils/bindListenerToDocumentGlobal";
import util from "Blocks/utils/util";

function addFadeTimerForAlert(alert) {
  setTimeout(
    (alertArgument) => {
      // https://stackoverflow.com/questions/35637770/how-to-avoid-no-param-reassign-when-setting-a-property-on-a-dom-object
      const alertButNotArgument = alertArgument;
      alertButNotArgument.style.display = "none";
    },
    6000,
    alert
  );
}

export function appendAlert(alert) {
  const alerts = $(".js-alerts")[0];
  // Need conditional logic for adding fadeTimer
  addFadeTimerForAlert(alert);
  alerts.appendChild(alert);
}

export function initAlerts() {
  bindListenerToDocumentGlobal("click", "js-alert-close", (event) => {
    const alert = event.target.closest(".js-alert");
    util.deleteElement(alert);
  });
}

export function addFadeOutForServerRenderedAlerts() {
  const alertsContainer = $(".js-alerts")[0];
  const fadeOutAlerts = Array.from(
    alertsContainer.querySelectorAll(".js-alert")
  );
  fadeOutAlerts.forEach((alert) => addFadeTimerForAlert(alert));
}

// create alert
function createAlert(status, message) {
  const alertTemplates = $(".js-alert-templates")[0];
  const alertTemplate = alertTemplates.querySelector(".js-alert");
  const newAlert = alertTemplate.cloneNode(true);
  newAlert.classList.add(`alert--${status}`);
  newAlert.querySelector(".js-alert-message").textContent = message;
  appendAlert(newAlert);
}

export function renderSuccess(message = "Success!") {
  createAlert("success", message);
}

export function renderError(
  message = "Looks like something went wrong, try refreshing the page"
) {
  createAlert("error", message);
}
