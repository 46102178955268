/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-string-refs */
import util from "Blocks/utils/util";
import React from "react";
import updateSeries from "Highcharts/updateSeries";
import Highcharts from "highcharts";

export default class Highchart extends React.Component {
  componentDidMount() {
    this.chart = Highcharts.chart(this.refs.highchart, this.props.chartData);
  }

  // I might need to trigger a rerender if the styles are changed
  componentDidUpdate(prevProps) {
    if (
      !util.deepEqual(this.props.chartData.series, prevProps.chartData.series)
    ) {
      updateSeries(this.chart, this.props.chartData.series);
    }

    if (!util.deepEqual(this.props.chartData, prevProps.chartData)) {
      this.chart.update(this.props.chartData);
    }

    // Update noData
    if (
      this.props.chartData.lang &&
      prevProps.chartData.lang &&
      this.props.chartData.lang.noData &&
      this.props.chartData.lang.noData !== prevProps.chartData.lang.noData
    ) {
      const noDataText = this.refs.highchart.querySelector(
        ".highcharts-no-data text"
      );
      if (noDataText) {
        noDataText.innerHTML = this.props.chartData.lang.noData;

        const highchartHeight = this.refs.highchart.clientHeight;
        const highchartWidth = this.refs.highchart.clientWidth;
        const textHeight = noDataText.clientHeight;
        const textWidth = noDataText.clientWidth;

        this.refs.highchart
          .querySelector(".highcharts-no-data")
          .setAttribute(
            "transform",
            `translate(${(highchartWidth - textWidth) / 2}, ${
              (highchartHeight - textHeight) / 2
            })`
          );
      }
    }
  }

  componentWillUnmount() {
    this.chart.destroy();
  }

  showLoading() {
    this.chart.showLoading();
  }

  hideLoading() {
    this.chart.hideLoading();
  }

  render() {
    return <div ref="highchart" className="" style={this.props.style} />;
  }
}
