import React, { useState } from "react";
import { DeleteConfirmation, useDeleteConfirmation } from "./DeleteChecks.jsx";
import Alert from "Components/blocks/alert/Alert";
import Message from "Components/blocks/alert/Message";
import Modal from "Components/blocks/modal/Modal";
import ActionButtons from "Components/blocks/buttons/ActionButtons";
import { makeRequest } from "Components/api/request";
import styles from "./deletemodal.module.css";

export function DeleteModal({
  name,
  isOpen,
  actions,
  handleDelete,
  timesheetId,
  projectId,
  rowId,
  type,
}) {
  const [loading, setLoading] = useState(true);

  const [onSubmit] = useDeleteConfirmation({
    deleteAction: handleDelete,
    setLoading,
    actions,
  });

  const deleteBlockWarnings = async (timesheetId) =>
    makeRequest({
      method: "get",
      url: `/timesheets/${timesheetId}/delete_block_warnings/?project_id=${projectId}`,
    });

  const deleteRowWarnings = (async) =>
    makeRequest({
      method: "get",
      url: `/entry_rows/${rowId}/delete_warnings`,
    });

  const fetchAction =
    type === "project" ? deleteBlockWarnings : deleteRowWarnings;

  const title = name || "entries";
  const phrase = name || "this";

  return (
    <Modal
      isOpen={isOpen}
      actions={actions}
      title={`Remove ${title}`}
      size="medium"
    >
      <Alert
        message={`Removing this ${type} will delete all related timesheet data and entries for this week.`}
        variant="warning"
      />
      <form onSubmit={onSubmit}>
        <DeleteConfirmation
          id={timesheetId}
          fetchAction={fetchAction}
          noDataText={`No data or entries associated with this ${type}`}
          setLoading={setLoading}
        />
        <Message
          message="Warning: this action cannot be undone"
          variant="warning"
        />
        <ActionButtons
          buttons={[
            {
              color: "cancel",
              text: "Cancel",
              action: actions.close,
            },
            {
              color: "delete",
              buttonType: "submit",
              text: "Delete",
              disabled: loading,
              action: onSubmit,
            },
          ]}
        />
      </form>
    </Modal>
  );
}

export default React.memo(DeleteModal);
