import { useState, useEffect } from "react";

export function useValidation(values) {
  const [canSubmit, setCanSubmit] = useState(true);

  // const isEmptyObject = flow(values, compact, isEmpty, some);
  const hasValues = (obj) => Object.keys(obj).every((key) => obj[key]);

  useEffect(() => {
    if (!hasValues(values)) setCanSubmit(false);
    else setCanSubmit(true);

    return () => setCanSubmit(false);
  }, [values]);

  return canSubmit;
}

export default useValidation;
