/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import CommandPalette, { filterItems, getItemIndex } from "react-cmdk";

import { useApollo } from "@hooks";
import { Badge } from "@plan";
import { Box } from "@plan/layout";
import { useDebounce } from "@plan/core";
import { IconQuickBooks } from "@plan/icons";
import { eventTracker } from "Blocks/tracker";
import Avatar from "Components/blocks/avatar/Avatar";

import _map from "lodash/map";
import { ApolloProvider, useLazyQuery } from "@apollo/client";

import SearchQuery from "./SearchQuery";

const ClientQuickbooksIcon = ({ client }) => {
  if (!client?.syncedToQbo) return null;

  return (
    <Box paddingRight="2">
      <IconQuickBooks label="Synced to Quickbooks" fontSize="14px" />
    </Box>
  );
};

const SearchFieldComponent = () => {
  const [searchString, setSearchString] = useState("");
  const debouncedQuery = useDebounce(searchString, 250);
  const [isOpen, setIsOpen] = useState(false);
  const [search, { data, error }] = useLazyQuery(SearchQuery, {
    variables: {
      searchString: debouncedQuery,
    },
  });

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (debouncedQuery) {
      search();
    }
  }, [debouncedQuery, search]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (
        (navigator?.platform?.toLowerCase().includes("mac")
          ? e.metaKey
          : e.ctrlKey) &&
        e.key === "k"
      ) {
        e.preventDefault();
        e.stopPropagation();

        setIsOpen((currentValue) => !currentValue);
      }
    }

    const el = document.querySelector("[data-search] a");
    if (!el) {
      return;
    }

    el.addEventListener("click", (e) => {
      eventTracker("trackSearchOpened", {
        withKeyboardShortcut: false,
      });
      e.stopPropagation();
      e.preventDefault();
      setIsOpen(true);
    });

    document.addEventListener("keydown", handleKeyDown);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const filteredItems = filterItems(
    [
      {
        heading: "Projects",
        id: "projects",
        items: _map(data?.searchableProjects, (project) => ({
          id: project?.id,
          children: project?.name,
          icon: () => (
            <Badge className="mg-omnisearch" size="small" color={project.color}>
              {project.number}
            </Badge>
          ),
          onClick() {
            eventTracker("trackSearchResultClicked", {
              type: "project",
              id: project.id,
            });

            window.location.href = `/projects/${project?.slug}`;
          },
        })),
      },
      {
        heading: "Clients",
        id: "clients",
        items: _map(data?.clients.nodes, (client) => ({
          id: client?.id,
          icon: () => <ClientQuickbooksIcon client={client} />,
          children: client?.displayName,
          onClick: () => {
            eventTracker("trackSearchResultClicked", {
              type: "client",
              id: client?.id,
            });

            window.location.href = `/directory/clients/${client?.slug}`;
          },
        })),
      },
      {
        heading: "Team",
        id: "team",
        items: _map(data?.profiles.nodes, (profile) => ({
          id: profile?.id,
          icon: () => (
            <Avatar
              src={profile?.image}
              className="mg-omnisearch"
              initials={profile?.initials}
            />
          ),
          children: profile?.name,
          onClick() {
            eventTracker("trackSearchResultClicked", {
              type: "profile",
              id: profile?.id,
            });

            window.location.href = `/directory/team/${profile?.slug}`;
          },
        })),
      },
    ],
    searchString
  );

  if (error) {
    return null;
  }

  return (
    <>
      <CommandPalette
        onChangeSearch={setSearchString}
        onChangeOpen={setIsOpen}
        search={searchString}
        isOpen={isOpen}
        page="root"
      >
        <CommandPalette.Page id="root">
          {filteredItems.length ? (
            filteredItems.map((list) => (
              <CommandPalette.List key={list.id} heading={list.heading}>
                {list.items.map(({ id, ...rest }) => (
                  <CommandPalette.ListItem
                    key={id}
                    index={getItemIndex(filteredItems, id)}
                    showType={false}
                    {...rest}
                  />
                ))}
              </CommandPalette.List>
            ))
          ) : (
            <CommandPalette.FreeSearchAction />
          )}
        </CommandPalette.Page>
      </CommandPalette>
    </>
  );
};

const Search = () => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <SearchFieldComponent />
    </ApolloProvider>
  );
};

export default Search;
