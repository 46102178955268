import React from "react";
import moment from "moment";

// This is deprecated, instead use date/DateFormat

const DateDisplay = ({ date, withTime, format = "MMM D, YYYY" }) => {
  if (date === null) return null;

  const dateString = moment(date).format(format);

  if (withTime) {
    const timeString = moment(date).format("LT");
    return (
      <>
        {dateString} @ {timeString}
      </>
    );
  }

  return <>{dateString}</>;
};

export default DateDisplay;
