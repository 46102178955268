import { styled } from "@plan/core";

export const TimerButton = styled("button", {
  position: "relative",
  marginRight: "$space$2",
  border: "none",
  backgroundColor: "transparent",
  width: "$sizes$lg",
  flex: "0 0 $space$8",
  height: "$sizes$lg",
  padding: "$space$1",
  cursor: "pointer",
  variants: {
    collapsed: {
      true: {
        flex: "0 0 2em",
        width: "$sizes$lg",
        height: "$sizes$lg",
        padding: "0",
        "@bpXl": {
          flex: "0 0 2.5em",
          width: "$sizes$xl",
          height: "$sizes$xl",
        },
      },
    },
    large: {
      true: {
        width: "4.5rem",
        flex: "0 0 4.5rem",
        // FIXME (BREAKING CHANGE)
        // Amend `rem` value to closest $space key (or calc value) on first refactor
        height: "4.5rem",
        padding: "0.313rem",
      },
    },
  },
});
