// stitches.config.ts
import { colors } from "./colors";

import type * as Stitches from "@stitches/react";
export type { VariantProps } from "@stitches/react";
import { textStyles } from "./typography";

import { createStitches, defaultThemeMap } from "@stitches/react";

/**
 * Notes:
 * 1. We can't use the `.` separator in Stitches.js.
 *    Until we revisit, we'll use the `_5` to indicate half measurements.
 *    See: https://stitches.dev/docs/tokens#naming-convention
 */
const stitchesConfig = createStitches({
  theme: {
    colors: {
      ...colors,
      transparent: "rgba(255,255,255,0)",
      projectColor: "var(--project-color, $cyan500)",
    },
    space: {
      "0_5": ".125rem" /* [1] */,
      1: ".25rem",
      "1_5": ".375rem" /* [1] */,
      2: ".5rem",
      "2_5": ".625rem" /* [1] */,
      3: ".75rem",
      "3_5": ".875rem" /* [1] */,
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "2.75rem",
      12: "3rem",
      14: "3.5rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      28: "7rem",
      32: "8rem",
      36: "9rem",
      40: "10rem",
      44: "11rem",
      48: "12rem",
      52: "13rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      72: "18rem",
      80: "20rem",
      96: "24rem",
    },
    fontSizes: {
      xs: "0.625rem",
      sm: "0.750rem",
      md: "0.875rem",
      lg: "1.000rem",
      xl: "1.125rem",
      "2xl": "1.250rem",
      "3xl": "1.500rem",
      "4xl": "1.875rem",
      "5xl": "2.250rem",
      "6xl": "2.500rem",
      "7xl": "3.000rem",
    },
    fonts: {
      sans: '"din-2014", "DIN 2014", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", sans-serif',
      mono: '"Andale Mono", AndaleMono, Consolas, monaco, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", monospace',
    },
    fontWeights: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700,
      black: 800,
    },
    lineHeights: {
      none: "1",
      tight: "1.2",
      base: "1.3",
      relaxed: "1.4",
      loose: "1.6",
    },
    letterSpacings: {
      allCaps: "0.025rem", // 12px and smaller bold ALL CAPS headings, like on the settings cards
    },
    sizes: {
      xs: "1rem", // 16px,
      sm: "1.25rem", // 20px
      md: "1.5rem", // 24px
      lg: "2rem", // 32px
      xl: "2.5rem", // 40px
    },
    borderWidths: {
      1: "1px",
    },
    borderStyles: {
      primary: "1px solid $colors$-neutral20",
    },
    radii: {
      default: ".25rem",
      round: "50%",
    },
    shadows: {
      // Don't love this naming...
      shadow100: "0 0.25rem 1rem rgba(1, 22, 39, 0.125)",
      focus: "0 0 0 1px $colors$brand",
    },
    zIndices: {
      basement: -1000,
      default: 1,
      masked: 100,
      mask: 200,
      sticky: 300,
      header: 400,
      toast: 500,
      dropdown: 600,
      overlay: 700,
      modal: 800,
      loader: 900,
      popup: 950,
      penthouse: 999999,
    },
    transitions: {
      // Not sure we're using this...
      cb: "cubic-bezier(0.16, 0.68, 0.43, 0.99)",
      toggle: "all 160ms ease-out",
    },
  },
  media: {
    motionSafe: "(prefers-reduced-motion: no-preference)",
    bpXs: "(min-width: 20em)",
    bpSm: "(min-width: 40em)",
    bpMd: "(min-width: 60em)",
    bpLg: "(min-width: 80em)",
    bpXl: "(min-width: 100em)",
  },
  utils: {
    ring: (value: Stitches.PropertyValue<"color">) => ({
      $$ringOffsetColor: "$colors$-neutral50",
      $$ringOffsetWidth: "1px",
      $$ringOffsetShadow: "0 0 0 $$ringOffsetWidth $$ringOffsetColor",
      $$ringColor: value,
      $$ringWidth: "2px",
      $$ringShadow: "0 0 0 calc($$ringWidth + $$ringOffsetWidth) $$ringColor",
      boxShadow: "$$ringOffsetShadow, $$ringShadow",
    }),
    textStyle: (value: keyof typeof textStyles) => textStyles[value],
    marginX: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
  prefix: "plan-",
  // https://github.com/modulz/stitches/blob/canary/packages/core/src/defaultThemeMap.js#L11-L155
  themeMap: {
    ...defaultThemeMap,
  },
});

export const { config, styled, css, globalCss, keyframes, getCssText, theme } =
  stitchesConfig;

export type TCSS = Stitches.CSS<typeof stitchesConfig>;
export interface ICSSProp {
  css?: TCSS;
}
