import React, { useState } from "react";
import styles from "./overhead.module.css";

// Overhead or Profile
function OverheadTitle({ title, status }) {
  const BadgeColor = () => {
    switch (status) {
      case "pending":
        return "#FFBE0A";
      case "approved":
        return "#0D9684";
      case "taken":
        return "#5840E0";
      default:
        break;
    }
  };
  const FontColor = () => {
    switch (status) {
      case "approved":
        return "#FFFFFF";
      case "taken":
        return "#FFFFFF";
      default:
        return "#000000";
    }
  };
  const Badge = () => {
    if (status) {
      return (
        <div
          style={{
            backgroundColor: BadgeColor(),
            color: FontColor(),
            position: "absolute",
            top: "calc(50% - 16px)",
            transformOrigin: "left",
            left: "100%",
          }}
          className={styles.overheadBadge}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>
      );
    }
    return null;
  };
  if (status) {
    return (
      <>
        <div style={{ position: "relative" }}>
          <div className={styles.overheadTitle}>{title}</div>
          <Badge />
        </div>
        <div className={styles.activitySelectPlaceholder} />
      </>
    );
  }
  return (
    <>
      <div className={styles.overheadTitle}>{title}</div>
      <div className={styles.activitySelectPlaceholder} />
    </>
  );
}

export default OverheadTitle;
