import React from "react";
import ajax from "Blocks/utils/ajax";
import Entries from "../Entries";
import RowTotals from "../RowTotals";
import DeleteButton from "../DeleteButton";
import { useGlobalState, deleteOverhead } from "../state/GlobalContext.jsx";
import styles from "./overhead.module.css";

function OverheadRow({ startOfWeek, groupName, overheadId, canEdit }) {
  const { state, dispatch } = useGlobalState();
  const overhead = state.selectedOverheads.byId[overheadId];

  const rowId = overhead.entryRowId;
  const rowIsApproved = state.rows.byId[rowId].isApproved;
  const entriesInRow = Object.values(state.entriesByRowId[rowId]);

  const expensesForEntry = (entry) => {
    const expenseIds = entry.expenses;
    return expenseIds.map((expenseId) => state.expenses.byId[expenseId]);
  };

  const expensesInRow = entriesInRow
    .map((entry) => expensesForEntry(entry))
    .flat();

  let totalExpenses = 0;
  if (expensesInRow) {
    totalExpenses = Object.values(expensesInRow).reduce(
      (total, expense) =>
        expense.amount ? total + Number(expense.amount) : total,
      0,
      0
    );
  }

  const totals = {
    hours: entriesInRow.reduce(
      (totalHours, entry) =>
        entry.hours ? totalHours + Number(entry.hours) : totalHours,
      0,
      0
    ),
    expenses: totalExpenses,
  };

  const deleteOverheadHandler = () => {
    const path = `/entry_rows/${rowId}`;
    ajax({
      method: "DELETE",
      path,
      success: (response) => {
        dispatch(deleteOverhead({ rowId, overheadId }));
      },
    });
  };

  return (
    <>
      <Entries
        startOfWeek={startOfWeek}
        blockName={groupName}
        groupId={overheadId}
        rowId={rowId}
        canEdit={canEdit}
        isApproved={rowIsApproved}
        isOverhead
      />
      <RowTotals
        expenses={totalExpenses}
        hours={Math.round(totals.hours * 100) / 100}
        showExpense
      />
      <div
        className={
          canEdit ? styles.rowDeleteColumn : styles.rowViewDeleteColumn
        }
      >
        {canEdit && (
          <DeleteButton
            type="overhead"
            groupId={overheadId}
            deleteHandler={deleteOverheadHandler}
            name={groupName}
            rowId={rowId}
          />
        )}
      </div>
    </>
  );
}

export default OverheadRow;
