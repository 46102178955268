import { MouseEventHandler, useRef } from "react";
import * as React from "react";

import { createComponent, keyframes, styled } from "@plan/core";
import { IconAdd, IconClear } from "@plan/icons";
import { AutoLayout } from "@plan/layout";

import { Button } from "../Button";
import { useField } from "../Field";
import { Label } from "../Input";
import { Spinner } from "../Spinner";

const component = createComponent();

const fadeIn = keyframes({
  "0%": { visibility: "hidden", opacity: 0 },
  "1%": { visibility: "visible", opacity: 0 },
  "100%": { opacity: 1 },
});

const fadeOut = keyframes({
  "0%": { visibility: "visible", opacity: 1 },
  "99%": { opacity: 0 },
  "100%": { visibility: "hidden", opacity: 0 },
});

const ImageUploaderContainer = styled("div", {
  ...component,
  position: "relative",
  // FIXME (BREAKING CHANGE)
  // Amend `rem` value to closest $space key on first refactor
  width: "13.5rem",
});

const ImageUploaderField = styled("div", {
  border: "1px dashed $colors$-neutral20",
  padding: "$space$3",
  width: "100%",
  // FIXME (BREAKING CHANGE)
  // Amend `rem` value to closest $space key (or calc value) on first refactor
  height: "13.5rem",
  backgroundColor: "$white",
  borderRadius: "$radii$default",
  "&:focus": {
    outline: "none",
    boxShadow: "$focus",
  },
  "&:hover": {
    borderColor: "$colors$-neutral10",
  },
  variants: {
    invalid: {
      false: {},
      true: {
        "&, &:hover": {
          borderColor: "$colors$danger",
        },
      },
    },
    disabled: {
      false: {},
      true: {
        pointerEvents: "none",
      },
    },
  },
});

const ImageUploaderFileInput = styled("input", {
  visibility: "hidden",
  width: "1px",
  height: "1px",
  position: "absolute",
});

const UploadedImageCover = styled("div", {
  position: "absolute",
  zIndex: "2",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  background: "$overlayDark",
  borderRadius: "$radii$default",
  [`${Button}`]: {
    zIndex: "$default",
  },
});

const UploadedImageWrapper = styled("div", {
  width: "100%",
  height: "100%",
  position: "relative",
  variants: {
    isLoading: {
      false: {
        "&:hover": {
          [`${UploadedImageCover}`]: {
            opacity: "1",
            animation: `${fadeIn} 200ms linear`,
          },
        },
        "&:not(:hover)": {
          [`${UploadedImageCover}`]: {
            opacity: "0",
            animation: `${fadeOut} 200ms linear`,
          },
        },
      },
    },
  },
});

const UploadedImage = styled("img", {
  position: "relative",
  zIndex: 1,
  borderRadius: "$radii$default",
  maxWidth: "100%",
  maxHeight: "100%",
});

export interface ImageUploaderProps
  extends Omit<
    React.ComponentProps<typeof ImageUploaderContainer>,
    "onChange"
  > {
  /**
   * @deprecated
   * Replaced by Field's FieldDescription.
   *
   * caption: The help text that appears next to the 'Upload Image' CTA.
   */
  caption?: string;
  /** imageUrl: The file to display, if available. */
  imageUrl?: string;
  /** isLoading: Whether or not the component is midflight (e.g., if a file is uploading) */
  isLoading?: boolean;
  /** onChange: If the input changes, fire a callback with the new value. */
  onChange?: (file?: File) => void;
}

interface UploadedProps {
  disabled?: boolean;
  imageUrl: string;
  isLoading: boolean;
  handleInputClear: () => void;
  handleInputClick: React.ComponentProps<typeof Button>["onClick"];
}

const Uploaded = ({
  disabled,
  imageUrl,
  handleInputClick,
  isLoading,
  handleInputClear,
}: UploadedProps) => (
  <UploadedImageWrapper isLoading={isLoading}>
    <UploadedImageCover>
      <AutoLayout
        resizingX="fill-container"
        resizingY="fill-container"
        alignment="center"
        spacing="2"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Button
              intent="secondary"
              disabled={disabled}
              onClick={handleInputClick}
            >
              Replace
            </Button>
            <Button
              intent="secondary"
              shape="square"
              disabled={disabled}
              onClick={handleInputClear}
            >
              <IconClear label="Remove" />
            </Button>
          </>
        )}
      </AutoLayout>
    </UploadedImageCover>

    <UploadedImage src={imageUrl} alt="" />
  </UploadedImageWrapper>
);

export const ImageUploader = ({ ...props }: ImageUploaderProps) => {
  const { getInputProps } = useField();
  const inputProps = getInputProps({ id: props?.id });

  const { onChange = () => null, caption, isLoading = false, imageUrl } = props;
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const showCta = !imageUrl;
  const showImage = !!imageUrl;

  const handleInputChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputRef?.current || !e?.target?.files?.length) return;
    onChange(e.target.files[0]);
    inputRef.current.value = "";
  };

  const handleInputClear = () => {
    onChange();
    inputRef.current.value = "";
  };

  const handleInputClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    // When the image uploader is used in a form element,
    // prevent the click event from bubbling up to the parent
    // and submitting the form unintentionally
    e?.preventDefault();
    inputRef.current.click();
  };

  return (
    <ImageUploaderContainer>
      <AutoLayout
        direction="vertical"
        spacing="2"
        resizingX="fill-container"
        resizingY="fill-container"
      >
        <ImageUploaderField
          disabled={inputProps?.disabled}
          invalid={inputProps?.invalid}
        >
          <AutoLayout
            spacing="0"
            alignment="center"
            resizingX="fill-container"
            resizingY="fill-container"
          >
            <ImageUploaderFileInput
              ref={inputRef}
              type="file"
              onChange={handleInputChange}
              {...inputProps}
            />
            {showCta && (
              <Button
                intent="secondary"
                disabled={inputProps?.disabled}
                onClick={handleInputClick}
              >
                <IconAdd label="Upload" />
                Upload
              </Button>
            )}
            {showImage && (
              <Uploaded
                handleInputClear={handleInputClear}
                handleInputClick={handleInputClick}
                imageUrl={imageUrl}
                isLoading={isLoading}
                disabled={inputProps?.disabled}
              />
            )}
          </AutoLayout>
        </ImageUploaderField>
        {caption && (
          <Label
            css={{
              whiteSpace: "normal",
              display: "block",
              position: "static",
              lineHeight: "1.2",
              marginBottom: 0, // Override `Label` defaults
              fontSize: "$fontSizes$sm",
            }}
          >
            {caption}
          </Label>
        )}
      </AutoLayout>
    </ImageUploaderContainer>
  );
};
