import React from "react";

export const Next = ({ disabled }) => {
  let stroke;
  if (disabled) {
    stroke = "#c0c5c9";
  } else {
    stroke = "#606D78";
  }

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 25L22 20L17 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Next;
