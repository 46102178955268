import { DateRange } from "react-day-picker";

import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
  subMonths,
  subWeeks,
} from "date-fns";

export const today = { Today: { from: new Date(), to: new Date() } };

// Weeks
export const weekToDate = {
  "Week to date": { from: startOfWeek(new Date()), to: new Date() },
};

export const lastSevenDays = {
  "Last 7 days": { from: sub(new Date(), { days: 7 }), to: new Date() },
};

export const thisWeek = {
  "This week": { from: startOfWeek(new Date()), to: endOfWeek(new Date()) },
};

export const lastWeek = {
  "Last week": {
    from: startOfWeek(subWeeks(new Date(), 1)),
    to: endOfWeek(subWeeks(new Date(), 1)),
  },
};

// Months
export const monthToDate = {
  "Month to date": { from: startOfMonth(new Date()), to: new Date() },
};

export const thisMonth = {
  "This month": { from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
};

export const lastMonth = {
  "Last month": {
    from: startOfMonth(subMonths(new Date(), 1)),
    to: endOfMonth(subMonths(new Date(), 1)),
  },
};

export const lastThirtyDays = {
  "Last 30 days": { from: sub(new Date(), { days: 30 }), to: new Date() },
};

// Quarters
export const lastQuarter = {
  "Last quarter": {
    from: sub(startOfQuarter(new Date()), { months: 3 }),
    to: sub(endOfQuarter(new Date()), { months: 3 }),
  },
};

export const quarterToDate = {
  "Quarter to date": { from: startOfQuarter(new Date()), to: new Date() },
};

export const lastTwoMonths = {
  "Last 2 months": { from: sub(new Date(), { months: 2 }), to: new Date() },
};

export const lastThreeMonths = {
  "Last 3 months": { from: sub(new Date(), { months: 3 }), to: new Date() },
};

export const lastSixMonths = {
  "Last 6 months": { from: sub(new Date(), { months: 6 }), to: new Date() },
};

export const thisQuarter = {
  "This quarter": {
    from: startOfQuarter(new Date()),
    to: endOfQuarter(new Date()),
  },
};

// Years
export const yearToDate = {
  "Year to date": { from: startOfYear(new Date()), to: new Date() },
};

export const thisYear = {
  "This year": { from: startOfYear(new Date()), to: endOfYear(new Date()) },
};

export const lastTwelveMonths = {
  "Last 12 months": { from: sub(new Date(), { months: 12 }), to: new Date() },
};

export const lastYear = {
  "Last year": {
    from: startOfYear(subMonths(new Date(), 12)),
    to: endOfYear(subMonths(new Date(), 12)),
  },
};

// All time
export const allTime = {
  "All time": { from: new Date(2010, 0, 1), to: new Date() },
};

// Defaults
export const DEFAULT_PRESET_OPTIONS = {
  ...today,
  ...weekToDate,
  ...thisWeek,
  ...lastSevenDays,
  ...lastWeek,
  ...monthToDate,
  ...thisMonth,
  ...lastThirtyDays,
  ...lastMonth,
  ...quarterToDate,
  ...thisQuarter,
  ...lastQuarter,
  ...lastThreeMonths,
  ...lastSixMonths,
  ...yearToDate,
  ...thisYear,
  ...lastYear,
  ...lastTwelveMonths,
  ...allTime,
};

export type DefaultPreset = keyof typeof DEFAULT_PRESET_OPTIONS;

export type DatePreset = {
  [key: string]: DateRange;
};

export type DatePresetKeys = keyof DatePreset;

export type DatePresets = DatePresetKeys[];

export type AnyDatePreset = {
  [key in DatePresets[number]]?: DateRange;
} & {
  [key: string]: DateRange;
};
