import React from "react";
import { useFieldState } from "../FieldContext";
import { Formatter } from "./FieldFormat";
import styles from "../field.module.css";

export function DisplayInput() {
  const { value } = useFieldState();

  return (
    <>
      <Formatter>
        <div className={styles.field__input}>{value}</div>
      </Formatter>
    </>
  );
}

export default React.memo(DisplayInput);
