import { appendAlert, renderError } from "Blocks/alert";
import util from "Blocks/utils/util";

// {method, path, data, success, failure}
const ajax = (options) => {
  // Move data into params if options.method is GET and there is data
  if (options.method === "GET") {
    if (typeof options.data === "object") {
      let paramData = "";
      for (const key in options.data) {
        if (paramData !== "") {
          paramData += "&";
        }
        paramData += `${key}=${encodeURIComponent(options.data[key])}`;
      }
      options.path += `?${paramData}`;
    }
  }

  const request = new XMLHttpRequest();
  request.open(options.method, options.path, true);
  const csrf_token = document.querySelector("meta[name=csrf-token]").content;
  request.setRequestHeader("X-CSRF-Token", csrf_token);
  request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
  request.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  request.onload = function () {
    if (this.status >= 200 && this.status < 400) {
      // Success!
      if (typeof options.success === "function") {
        options.success(this.response);
      }
      // if 500 error, use error handler, if available
    } else if (this.status === 500 && typeof options.error === "function") {
      options.error("500 error");
    } else {
      if (options.error) {
        options.error(this.response);
      }

      // We reached our target server, but it returned an error
      // if this.response is a error node, then render the error.
      if (typeof this.response === "string") {
        // If the response is HTML, append it as an alert
        if (
          util.htmlToElement(this.response) &&
          util.htmlToElement(this.response).tagName
        ) {
          appendAlert(util.htmlToElement(this.response));
          // Else raise the typical error
        } else if (parsedErrors(this.response)) {
          renderError(parsedErrors(this.response));
        } else {
          renderError();
        }
      }
      if (typeof options.failure === "function") {
        options.failure(this.response);
      }
    }
  };

  if (options.method !== "GET" && options.data !== undefined) {
    request.send(JSON.stringify(options.data));
  } else {
    request.send();
  }
};

const parsedErrors = (response) => {
  try {
    const parsedError = JSON.parse(response);
    return parsedError.errors || parsedError.message;
  } catch {
    return false;
  }
};

export default ajax;
