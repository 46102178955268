import React, { useEffect, useState } from "react";
import { readCookie } from "Blocks/utils/cookies";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";
import { ToastsProvider, Toasts } from "@plan/components";
import { Timer as TimerComponent, TimerProvider } from "@plan/timer";
import { ModalTrapProvider } from "@plan/core";
import LogoutLink from "../blocks/LogoutLink";

export const Timer = () => {
  const [sidebarOpen, setSidebarOpen] = useState(
    readCookie("sidebarState") !== "collapsed"
  );

  const apolloClient = useApollo({});

  useEffect(() => {
    const onMiniChange = (event) => setSidebarOpen(event.detail.isOpen());

    window.addEventListener("minibarChange", onMiniChange);

    return () => {
      window.removeEventListener("minibarChange", onMiniChange);
    };
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <ModalTrapProvider>
        <ToastsProvider>
          <TimerProvider>
            <div id="railsAppModalContainer" />
            <TimerComponent sidebarOpen={sidebarOpen} />
            <LogoutLink />
            <Toasts />
          </TimerProvider>
        </ToastsProvider>
      </ModalTrapProvider>
    </ApolloProvider>
  );
};

export default Timer;
