import React from "react";
import cx from "classnames";
import { useFieldState } from "../FieldContext";
import styles from "../field.module.css";

export function TextInput() {
  const {
    name,
    type,
    value,
    required,
    isDisabled,
    min,
    max,
    handleChange,
    handleBlur,
    placeholder,
    forwardRef,
    autoFocus,
    handleKeyDown,
    classes,
    handleFocus,
  } = useFieldState();

  return (
    <input
      autoFocus={autoFocus}
      ref={forwardRef}
      className={cx([styles.field__input, classes])}
      name={name}
      type={type}
      value={value}
      required={required}
      tabIndex={isDisabled ? -1 : 0}
      disabled={isDisabled}
      min={min}
      max={max}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
    />
  );
}

export default React.memo(TextInput);
