import React from "react";
import styles from "./hstack.module.css";

export function HStack({ children, gap }) {
  return (
    <div style={{ "--gap": gap }} className={styles.hstack}>
      {children}
    </div>
  );
}
