import cx from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { Button } from "@plan";
import styles from "./buttons.module.css";

export const Fang = ({ position }) => {
  const isRight = position === "right";

  return (
    <svg
      className={cx(styles.fang, { [styles.fangRight]: isRight })}
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.73103 8.5L11 0.687852L19.269 8.5H2.73103Z"
        fill="white"
        stroke="#DFE2E4"
      />
      <rect x="2" y="8" width="18" height="1" fill="white" />
    </svg>
  );
};

// Controls mount/unmount animation
export const FlyoutAnimation = ({ open, children, position }) => {
  const isOpen = { opacity: 1, y: 0, scale: 1, transition: { duration: 0.2 } };
  const isClosed = {
    opacity: 0,
    y: -8,
    scale: 0.98,
    transition: { duration: 0.2 },
  };

  const isRight = position === "right";

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          style={{ zIndex: 100 }}
          initial={isClosed}
          animate={isOpen}
          exit={isClosed}
          className={cx(styles.flyout, { [styles.flyoutRight]: isRight })}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const MenuIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12C20 12.5304 19.7893 13.0391 19.4142 13.4142C19.0391 13.7893 18.5304 14 18 14ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14ZM6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12C8 12.5304 7.78929 13.0391 7.41421 13.4142C7.03914 13.7893 6.53043 14 6 14Z"
      fill="var(--color--neutral-10)"
    />
  </svg>
);

export const MenuItem = ({ children }) => (
  <div className={styles.menuItem}>{children}</div>
);

export const ButtonMenu = ({
  onClick,
  isOpen = false,
  buttonText = <MenuIcon />,
  children = <>Menu</>,
  position = "left",
}) => {
  const buttonMenuRef = useRef(null);
  const [open, setOpen] = useState(isOpen);

  useClickAway(buttonMenuRef, () => setOpen(false));

  const handleClick = () => {
    if (onClick) onClick();
    setOpen((state) => !state);
  };

  useEffect(() => {
    setOpen(isOpen);
    return () => setOpen(false);
  }, [isOpen]);

  return (
    <div className={styles.buttonMenu} ref={buttonMenuRef}>
      <Button
        text={buttonText}
        onClick={handleClick}
        color="outline"
        size="small"
      />
      <FlyoutAnimation open={open} position={position}>
        <Fang position={position} />
        {children}
      </FlyoutAnimation>
    </div>
  );
};
