import React, { useEffect, useMemo, useState } from "react";
import { DateRange, Matcher } from "react-day-picker";

import { Select, SelectOptionTypeBase } from "../../Select/Select";
import {
  DateRangePicker,
  ReactDayPickerDateRange,
} from "../DateRangePicker/DateRangePicker";

import {
  AnyDatePreset,
  DatePreset,
  DatePresetKeys,
  DEFAULT_PRESET_OPTIONS as PRESET_OPTIONS,
} from "./presets";
import { RangeWrapper, Wrapper } from "./Wrapper";

import { differenceInDays, isSameDay } from "date-fns";

export * from "./presets";

export interface DatePresetOption extends DateRange, SelectOptionTypeBase {
  duration?: number;
}

const durationInDays = (dateRange: DateRange) => {
  if (!dateRange.to || !dateRange.from) return 0;
  return differenceInDays(dateRange.to, dateRange.from);
};

// Note: we sort the presets by duration in days to keep order consistent
const createOptions = (options: DatePreset) => {
  return Object.keys(options)
    .map((key) => ({
      label: key,
      value: key,
      ...options[key as keyof typeof options],
      duration: durationInDays(options[key as keyof typeof options]),
    }))
    .sort((a, b) => a.duration - b.duration);
};

type DatePresetPickerProps = {
  initialPreset?: keyof AnyDatePreset | null;
  initialDates?: DateRange;
  selectPlaceholder?: string;
  onDatesChange:
    | React.Dispatch<React.SetStateAction<DatePresetOption | null>>
    | ((dateRange: ReactDayPickerDateRange | null) => void);
  presets?: AnyDatePreset;
  isClearable?: boolean;
  topSlot?: React.ReactNode;
  bottomSlot?: React.ReactNode;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  disabledDates?: Matcher | Matcher[];
  isDisabled?: boolean;
};

function matchInitialValue(
  initialPreset: DatePresetKeys | null,
  presets: DatePresetOption[]
): DatePresetOption | null {
  if (!initialPreset) return null;
  return presets.find((preset) => preset.value === initialPreset) || presets[0];
}

export const DatePresetPicker = ({
  onDatesChange,
  initialPreset = "Last 3 months",
  presets = PRESET_OPTIONS,
  selectPlaceholder = "Select Range",
  isClearable = false,
  ...props
}: DatePresetPickerProps) => {
  const presetOptions = useMemo(
    () => createOptions(presets),
    [presets]
  ) as DatePresetOption[];
  const [dateRange, setDateRange] = useState<DatePresetOption | null>(
    matchInitialValue(initialPreset, presetOptions)
  );

  const onLocalDateChange = (dates: ReactDayPickerDateRange) => {
    if (!dates.from && !dates.to) return setDateRange(null);
    const matchingPresetOption = presetOptions.find((option) => {
      if (!dates.from || !dates.to) return false;
      if (!option.from || !option.to) return false;
      return (
        isSameDay(option.from, dates.from) && isSameDay(option.to, dates.to)
      );
    });

    if (!matchingPresetOption)
      return setDateRange({
        label: "Custom Dates",
        value: "Custom Dates",
        ...dates,
        duration: durationInDays(dates),
      });
    return setDateRange(matchingPresetOption);
  };

  const onSelect = (value: DatePresetOption | null) => {
    if (!value) return setDateRange(null);
    setDateRange(value);
  };

  useEffect(() => {
    if (!dateRange?.value && !dateRange?.from && !dateRange?.to)
      return onDatesChange(null);
    if (dateRange?.from && dateRange?.to) onDatesChange(dateRange);
  }, [dateRange, onDatesChange]);

  return (
    <Wrapper>
      <Select
        options={presetOptions}
        //@ts-ignore - react-select types are wrong
        onChange={onSelect}
        value={dateRange}
        withinField={true}
        placeholder={selectPlaceholder}
      />
      <RangeWrapper>
        <DateRangePicker
          setDateRange={onLocalDateChange}
          dateRange={dateRange ? dateRange : undefined}
          width="range"
          withinField
          {...props}
          isClearable={isClearable}
        />
      </RangeWrapper>
    </Wrapper>
  );
};
