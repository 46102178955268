import { ReactNode } from "react";

import { Formatter, FormatterProps } from "../Formatter";

import numeral from "numeral";

const formatters = {
  // .5 => 50% // .555 => 55.5%
  default: "0.[00]%",
  // .555 => 56%
  rounded: "0%",
  // .555 => 55.50%
  verbose: "0.00%",
  // .5 => 50% // .555 => 55.5% // -.555 => (55.5%)
  accounting: "(0,0.[00]%)",
};

export type PercentProps = Omit<FormatterProps, "negative"> & {
  value: string | number;
  format?: keyof typeof formatters;
  as?: ReactNode;
};

export const Percent = ({
  value,
  format = "default",
  ...props
}: PercentProps) => {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  const isNegative = numberValue < 0;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const formatPercent = (value, format) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return numeral(value).format(formatters[format]);
  };
  const amount = formatPercent(numberValue, format);
  return (
    <Formatter negative={isNegative} {...props}>
      {amount}
    </Formatter>
  );
};
