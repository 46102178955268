import { createComponent, styled } from "@plan/core";

const component = createComponent();

export const Status = styled("div", {
  ...component,
  borderRadius: "$radii$round",
  height: "$$size",
  width: "$$size",
  margin: "$space$1",
  variants: {
    ...component.variants,
    colorScheme: {
      yellow: { backgroundColor: "$warning" },
      green: { backgroundColor: "$lime400" },
      red: { backgroundColor: "$colors$danger" },
    },
    size: {
      small: {
        $$size: "$space$1",
      },
      medium: {
        $$size: "$space$1_5",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    colorScheme: "yellow",
    size: "medium",
  },
});
