import { styled } from "@plan/core";

export const TimerButtonCircle = styled("div", {
  position: "absolute",
  inset: "0 0",
  width: "100%",
  height: "100%",
  opacity: "0",
  transform: "scale(0.75)",
  transition: "opacity 320ms ease-out, transform 480ms ease-out",
  variants: {
    isActive: {
      true: {
        opacity: "1",
        transform: "scale(1)",
        transition: "opacity 350ms ease-out, transform 480ms ease-out",
      },
    },
  },
});
