/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from "react";
import Accordion from "Components/blocks/accordion/Accordion";
import Allocation from "Components/blocks/allocation/Allocation";
import Progress from "Components/blocks/progress/Progress";
import Badge from "Components/blocks/badge/Badge";
import { useIsAllowed } from "Components/blocks/permissions/Permissions";
import { motion } from "framer-motion";
import { formatWeeksAndDays } from "Utils/util";
import { Tooltip, useTooltip } from "Components/blocks";
import _sumBy from "lodash/sumBy";
import {
  useAllocations,
  useProfiles,
  useUnallocatedEntryGroups,
} from "../../../state/Store";
import { useSchedule } from "../../../state/ScheduleContext";
import Card, { useCard } from "../Card";
import BudgetRow from "../BudgetRow";
import styles from "../card.module.css";

function useTeamTotals(phaseId) {
  const allocations = useAllocations();
  const unallocatedEntryGroups = useUnallocatedEntryGroups();
  const profiles = useProfiles();

  const calcAllocationTotal = useCallback(
    (phaseId) => {
      const ids = Object.keys(allocations);
      const allocationsByPhase = ids.filter(
        (allocId) => allocations[allocId].phaseId === phaseId
      );
      const unallocatedIds = Object.keys(unallocatedEntryGroups);
      const unallocatedByPhase = unallocatedIds.filter(
        (id) => unallocatedEntryGroups[id].phaseId === phaseId
      );

      const allocationsTotal = allocationsByPhase.length
        ? allocationsByPhase
            .map((allocId) => Number(allocations[allocId].allocation))
            .reduce((alloc, acc) => acc + alloc)
        : 0;

      const consumedTotal = allocationsByPhase.length
        ? allocationsByPhase
            .map((allocId) => Number(allocations[allocId].hours))
            .reduce((alloc, acc) => acc + alloc)
        : 0;

      const allocatedHoursByTeamMember = allocationsByPhase.length
        ? allocationsByPhase.map((allocId) => {
            const allocation = allocations[allocId];
            return {
              name: profiles[allocation.profileId]?.name,
              hours: allocation.hours,
            };
          })
        : [];

      const unAllocatedHoursByTeamMember = unallocatedByPhase.length
        ? unallocatedByPhase.map((id) => {
            const unallocated = unallocatedEntryGroups[id];
            return {
              name: profiles[unallocated.profileId]?.name,
              hours: unallocated.hours,
            };
          })
        : [];

      return {
        allocationsTotal,
        consumedTotal,
        hoursByTeamMember: [
          ...allocatedHoursByTeamMember,
          ...unAllocatedHoursByTeamMember,
        ],
      };
    },
    [allocations]
  );

  const totals = useMemo(
    () => calcAllocationTotal(phaseId),
    [phaseId, calcAllocationTotal]
  );

  return totals;
}

const OverageDisplay = ({ totals }) => {
  if (!totals) {
    return null;
  }

  const totalHours = _sumBy(totals.hoursByTeamMember, (member) =>
    Number(member.hours)
  );

  return (
    <div className={styles.overage}>
      {totals.hoursByTeamMember.map((teamMemberRow, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.overageRow} key={`${teamMemberRow.name}-${idx}`}>
          <p>{teamMemberRow.name}</p>
          <p>{teamMemberRow.hours}</p>
        </div>
      ))}
      <hr />
      <div className={styles.overageRow}>
        <p>Logged hours</p>
        <p>{totalHours}</p>
      </div>
      <div className={styles.overageRow}>
        <p>Planned hours</p>
        <p>
          <strong>{totals.allocationsTotal}</strong>
        </p>
      </div>
    </div>
  );
};

export const TeamCard = ({
  number,
  name,
  abbreviation,
  id,
  height,
  hours,
  hoursConsumed,
  daysRemaining,
  budget,
  budgetConsumed,
  color,
  projectSlug,
}) => {
  const [overageTooltip, overageTooltipNode] = useTooltip();
  const totals = useTeamTotals(id);
  const { selectPhase, open } = useCard(id);
  const canViewBudget = useIsAllowed(["canViewFinancialInformation"]);
  const canViewAllProjects = useIsAllowed(["canViewAllProjects"]);
  const { viewFinancials } = useSchedule();

  const progress = Number(hours) ?? 0;
  const consumed = Number(hoursConsumed) ?? 0;

  const budgetValue = budget ?? 0;
  const budgetConsumedValue = budgetConsumed ?? 0;

  const showBudget = viewFinancials && canViewBudget;
  const overage = totals.allocationsTotal < totals.consumedTotal;

  const handleProjectLinkClick = (e) => {
    e.stopPropagation();
  };

  return (
    <motion.div
      className={styles.teamWrapper}
      animate={{ height }}
      transition={{ easing: "linear" }}
      initial={{ height }}
    >
      <Card id={id} color={color} classes={[styles.teamCard]} height={height}>
        <div className={styles.info} onClick={selectPhase}>
          <Badge
            value={number}
            size="small"
            style={{ marginBottom: ".25rem", display: "block" }}
          />
          <h3 className={styles.name}>
            {canViewAllProjects ? (
              <a
                onClick={handleProjectLinkClick}
                className={styles.projectLink}
                href={`/projects/${projectSlug}`}
                target="_blank"
                rel="noreferrer"
              >
                {name}
              </a>
            ) : (
              name
            )}
          </h3>
          <div
            className={styles.abbrev}
            style={{ display: "block" }}
            title={abbreviation}
          >
            {abbreviation}
          </div>
        </div>
        <div
          className={styles.allocation}
          ref={overageTooltipNode}
          style={{ "--project-color": overage ? "red" : "inherit" }}
        >
          <Allocation
            total={totals.allocationsTotal}
            progress={totals.consumedTotal}
            variant="fill"
            size="large"
            ediable={false}
            overage={overage}
          />
          <Tooltip
            tooltip={overage && overageTooltip}
            key="role"
            position="right"
            light
          >
            <OverageDisplay totals={totals} />
          </Tooltip>
        </div>
        <div className={styles.expanded}>
          <Accordion isOpen={open}>
            <div className={styles.role}>
              <div className={styles.roleName}>Phase Totals</div>
            </div>
            <div className={styles.roleProgress}>
              <Progress
                total={progress}
                consumed={consumed}
                allocated={totals.allocationsTotal}
              />
            </div>
            <div className={styles.roleBudget}>
              <BudgetRow
                title="Budgeted"
                showBudget={showBudget}
                budget={budgetValue}
                hours={progress}
              />
              <BudgetRow
                title="Consumed"
                showBudget={showBudget}
                budget={budgetConsumedValue}
                hours={consumed}
              />
              <BudgetRow
                title="Time Left"
                oneColumn
                value={<TimeRemaining daysRemaining={daysRemaining} />}
              />
            </div>
          </Accordion>
        </div>
      </Card>
    </motion.div>
  );
};

const TimeRemaining = ({ daysRemaining }) => {
  const daysAndWeeks = useMemo(
    () => formatWeeksAndDays(daysRemaining),
    [daysRemaining]
  );

  const pastEndDate = useMemo(
    () => daysAndWeeks.weeks < 0 || daysAndWeeks.weeks < 0,
    [daysAndWeeks]
  );

  const text = `${daysAndWeeks.weeks} Weeks, ${Math.abs(
    daysAndWeeks.days
  )} Days`;

  return (
    <span style={{ color: pastEndDate ? "var(--color-red)" : "inherit" }}>
      {text}
    </span>
  );
};

export default React.memo(TeamCard);
