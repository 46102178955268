import React from "react";
import PropTypes from "prop-types";

export const MenuTextItem = ({ body, title }) => (
  <div className="menu-page-text__item">
    <h3 className="menu-page-text__title">{title}</h3>
    <p className="menu-page-button__description">{body}</p>
  </div>
);

MenuTextItem.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MenuTextItem;
