import React from "react";
import ActionButtons from "Components/blocks/buttons/ActionButtons";
import Portal from "Components/blocks/portal/Portal";
import styles from "./empty-state.module.css";
import image from "./resource-get-started.png";

export default function NoProjectsOverlay() {
  return (
    <Portal>
      <div className={styles.noProjectsOverlay}>
        <div className={styles.noProjects}>
          <h2>We Need More Information</h2>
          <p>
            Resource gives companies the ability to easily distribute team
            members and their time, week to week, with a clear and real-time
            reflection of budget impact on projects
          </p>
          <img src={image} className={styles.expampleImg} />
          <p>
            We cannot generate a Resource dashboard for you because you do not
            have any projects with active phases and assigned roles.
          </p>
          <p>
            To start,{" "}
            <a
              href="http://support.monograph.io/en/collections/2632610"
              rel="noopener"
              target="_blank"
            >
              read about how Resource works
            </a>{" "}
            or add your first complete project:
          </p>
          <a href="/projects" className={styles.button}>
            <ActionButtons
              buttons={[
                {
                  color: "action",
                  text: "Create a Project",
                  action: () => null,
                  disabled: false,
                },
              ]}
            />
          </a>
        </div>
      </div>
    </Portal>
  );
}
