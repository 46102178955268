import React from "react";
import Url from "domurl";
import ajax from "Blocks/utils/ajax";
import util from "Blocks/utils/util";
import { Toggle } from "@plan";
import { renderSuccess, renderError } from "Blocks/alert";

export default class ProjectsEditShareableLink extends React.Component {
  constructor(props) {
    super(props);

    this.copyLink = this.copyLink.bind(this);

    this.state = {
      shareable: this.props.project.is_shareable,
    };
  }

  copyLink() {
    // https://stackoverflow.com/questions/47931843/javascript-copy-to-clipboard-not-working?rq=1
    const text = this.refs.link;
    let range;
    let selection;

    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    const copied = document.execCommand("copy");
    window.getSelection().removeAllRanges();

    if (copied) {
      renderSuccess("URL Copied to clipboard!");
    } else {
      renderError();
    }
  }

  setShareable(shareableValue) {
    if (this.state.shareable === shareableValue) return;

    util.startSave();
    this.setState({ shareable: shareableValue });
    const data = { project: { is_shareable: shareableValue } };

    ajax({
      method: "PUT",
      data,
      path: `/projects/${this.props.project.slug}`,
      success: (response) => {
        util.endSave();
      },
    });
  }

  baseUrl() {
    const u = new Url();
    // Remove paths from url
    u.paths([""]);
    return u.toString();
  }

  renderShareableLink() {
    const url = `${this.baseUrl()}shareable/${
      this.props.project.shareable_hash
    }`;
    return (
      <div
        className={`field js-field${
          this.state.shareable ? "" : " is-disabled"
        }`}
      >
        <div className="field__block">
          <a className="field__link" href={url} ref="link">
            {url}
          </a>
          <button
            className="field__button field__button--copy js-field-copy"
            onClick={this.copyLink}
          >
            Copy
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="grid">
        <div className="grid-xs-12 mb-16">{this.renderShareableLink()}</div>
        {!this.props.disableEdit && (
          <div className="grid-xs-12">
            <Toggle
              value={this.state.shareable}
              label={
                this.state.shareable
                  ? "Sharing is enabled"
                  : "Sharing is disabled"
              }
              onChange={(value) => this.setShareable(value)}
            />
          </div>
        )}
      </div>
    );
  }
}
