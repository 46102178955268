import ajax from "Blocks/utils/ajax";
import util from "Blocks/utils/util";
import ExpenseModal from "Components/timesheets/ExpenseModal";
import EntryItemPopup from "Components/timesheets/EntryItemPopup";
import classNames from "classnames";
import React from "react";
import numeral from "numeral";
import moment from "moment";

export default class EntryItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.setNoteVisibility = this.setNoteVisibility.bind(this);
    this.setNote = this.setNote.bind(this);
    this.formType = this.formType.bind(this);

    this.showExpenseModal = this.showExpenseModal.bind(this);
    this.createExpense = this.createExpense.bind(this);
    this.updateExpense = this.updateExpense.bind(this);
    this.updateExpenseState = this.updateExpenseState.bind(this);
    this.updateExpenseObject = this.updateExpenseObject.bind(this);
    this.deleteExpense = this.deleteExpense.bind(this);

    this.state = {
      isFocused: false,
      entry: this.props.data.hours[this.props.date],
      note: this.props.data.notes[this.props.date] || "",
      expenses: this.props.expenses,
      expenseModalVisible: false,
    };

    if (this.props.project) {
      this.state.expenses = this.props.expenses;
    }
  }

  componentDidMount() {
    if (this.state.entry === 0) {
      this.setState({
        entry: "",
      });
    }
  }

  handleChange(event) {
    this.setState({
      entry: event.target.value,
    });
  }

  onBlur() {
    this.setState({
      isFocused: false,
    });
  }

  onFocus() {
    this.setState({
      isFocused: true,
    });
    this.props.setChildNoteVisibility(true, this.props.accessibleKey);
  }

  setNoteVisibility(visibility) {
    this.props.setChildNoteVisibility(visibility);
  }

  setNote(note) {
    this.setState({
      note,
    });
  }

  // Expenses
  showExpenseModal() {
    this.setState({
      expenseModalVisible: true,
    });
  }

  createExpense() {
    const data = {
      entry_id: this.props.entryId,
      date: this.props.date,
    };

    ajax({
      method: "POST",
      path: `/expenses`,
      data,
      success: (response) => {
        console.log(response);
        const expense = JSON.parse(response);
        this.setState((prevState) => {
          prevState.expenses.push(expense);
          return prevState;
        });
      },
    });
  }

  updateExpense(expenseId, data) {
    this.updateExpenseState(expenseId, data);

    if (Object.keys(data).some((key) => ["note", "amount"].includes(key))) {
      util.delay(
        () => this.updateExpenseObject(expenseId, data),
        800,
        expenseId + Object.keys(data).join("")
      );
    } else {
      this.updateExpenseObject(expenseId, data);
    }
  }

  updateExpenseState(expenseId, data) {
    this.setState((prevState) => {
      const newExpenses = prevState.expenses.map((expense) => {
        if (expense.id === expenseId) {
          expense = { ...expense, ...data };
        }
        return expense;
      });
      return { expenses: newExpenses };
    });
  }

  updateExpenseObject(expenseId, data) {
    ajax({
      method: "PUT",
      path: `/expenses/${expenseId}`,
      data,
      success: (response) => {
        console.log(response);
      },
    });
  }

  deleteExpense(expenseId) {
    this.setState((prevState) => ({
      expenses: prevState.expenses.filter(
        (expense) => expense.id !== expenseId
      ),
    }));

    ajax({
      method: "DELETE",
      path: `/expenses/${expenseId}`,
      success: (response) => {
        console.log(response);
      },
    });
  }

  formType(isEditable, data, date) {
    if (isEditable) {
      return (
        <input
          className="entry-field__input js-field-input js-entry-hour-input"
          type="number"
          min="0"
          max="24"
          value={this.state.entry}
          data-weekdate={date}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.handleChange}
        />
      );
    }
    return (
      <input
        className="entry-field__input js-field-input js-entry-hour-input"
        type="number"
        min="0"
        max="24"
        value={this.state.entry ? this.state.entry : "0"}
        data-weekdate={date}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={this.handleChange}
        disabled
      />
    );
  }

  expensesTotal() {
    const sum = this.props.expenses.reduce((sum, expense) => {
      if (parseFloat(expense.amount)) {
        return sum + parseFloat(expense.amount);
      }
      return sum;
    }, 0);

    return numeral(sum).format("0.00");
  }

  renderExpenses() {
    return null;
  }

  render() {
    return (
      <div
        className="entry-item"
        data-date={moment(this.props.date).format("MMM D")}
      >
        <div
          className={classNames({
            "entry-field js-field": true,
            "has-value": this.state.entry !== "",
          })}
        >
          <label
            data-currency={currencySymbol}
            className={classNames({
              "entry-field__title js-field-title": true,
              "has-note": this.state.note !== "",
              "has-expense":
                this.state.expenses && this.state.expenses.length > 0,
            })}
          >
            {this.props.label}
          </label>
          {this.formType(this.props.editable, this.props.data, this.props.date)}
          {this.props.accessibleKey === this.props.componentWithNote ? (
            <EntryItemPopup
              entryId={this.props.entryId}
              date={this.props.date}
              note={this.state.note}
              isNoteShowing={this.props.noteListVisiblity}
              setNoteVisibility={this.setNoteVisibility}
              setNoteOnParent={this.setNote}
              expenses={this.state.expenses}
              showExpenseModal={this.showExpenseModal}
            />
          ) : null}
          <div
            className={classNames({
              "entry-field__modal js-entry-modal": true,
            })}
          />
        </div>
        {this.renderExpenses()}
      </div>
    );
  }
}
