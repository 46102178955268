import React from "react";
import styles from "./projectSelect.module.css";

const ProjectSelectGroupLabel = ({ number, label, color }) => {
  return (
    <div className={styles.header}>
      <div className={styles.number} style={{ backgroundColor: color }}>
        {number}
      </div>
      <div className={styles.project}>{label}</div>
    </div>
  );
};

export default ProjectSelectGroupLabel;
