import React, { useState } from "react";
import moment from "moment";
import DownloadButton from "Components/blocks/buttons/DownloadButton";
import { joinAsCSV, downloadAsCSV } from "Utils/util";
import { eventTracker } from "Blocks/tracker";
import FilterBar from "Components/blocks/FilterBar";
import { Field, MultiSelect, HStack, FieldGroupLabel } from "@plan";
import { useTimelogContext, useFiltersContext } from "./TimelogContext";
import { formatExportData } from "./table/useTableData";
import styles from "./timelog.module.css";

function createSelectOptionList(array, labelAccessor, valueAccessor) {
  if (!array) return [];
  return array.map((item) => ({
    label: item[labelAccessor],
    value: item[valueAccessor],
    ...item,
  }));
}

export default function Filters() {
  const { tableGroup, tableData } = useTimelogContext();
  const { activeFilters, filters, updateDates, updateFilters } =
    useFiltersContext();

  // Set Export Button Load State
  const [csvLoading, setCsvLoading] = useState(false);

  const generateCsv = () => {
    setCsvLoading(true);
    const [exportHeaders, exportData] = formatExportData(tableGroup, tableData);
    const exportCSV = joinAsCSV(exportHeaders, exportData);
    const fileName = `timelog_data_${activeFilters.begin_date}_to_${activeFilters.end_date}.csv`;
    downloadAsCSV(exportCSV, fileName);
    eventTracker("trackTimeLogExport", {
      group: tableGroup,
      filter: activeFilters,
    });
    setCsvLoading(false);
  };

  return (
    <>
      <FilterBar>
        <HStack gap="1.5rem">
          <Field
            as="dates"
            size="small"
            startDate={moment(activeFilters.begin_date, "YYYY-MM-DD")}
            startDateId="begin_date"
            endDate={moment(activeFilters.end_date, "YYYY-MM-DD")}
            endDateId="end_date"
            blockClasses={`filter ${styles.datePicker}`}
            onDateChange={updateDates}
          />
          <HStack gap=".5rem">
            <FieldGroupLabel>Filter By:</FieldGroupLabel>
            {filters.map((filter) => {
              const options = createSelectOptionList(
                filter.options,
                "name",
                "id"
              );
              return (
                <div className={styles.filter} key={filter.name}>
                  <MultiSelect
                    placeholder={filter.placeholder}
                    selectedLabel={filter.placeholder}
                    options={options}
                    handleChange={(e) => updateFilters(e, filter.name)}
                    size="small"
                    isMulti
                  />
                </div>
              );
            })}
          </HStack>
        </HStack>
        <div className={styles.downloadButton}>
          <DownloadButton
            loading={csvLoading}
            action={generateCsv}
            disabledWhileLoading
          />
        </div>
      </FilterBar>
    </>
  );
}
