import React from "react";
import PropTypes from "prop-types";
import { sumBy } from "lodash";
import Loading from "Components/blocks/Loading";
import EmptyState from "Components/blocks/EmptyState";
import Table from "Components/blocks/Table/Table";
import { useTableData, tableColumns } from "./useTableData";
import ColumnHeaders from "./ColumnHeaders";
import ExpandableRow from "./ExpandableRow";
import styles from "./table.module.css";
import { useTimelogContext } from "../TimelogContext";

export default function Phases() {
  const { canEditAllTimesheets } = useTimelogContext();
  const [data, loading, error] = useTableData("phases");

  if (error) return <EmptyState title="Error Fetching Data" />;
  if (loading === "fetching") return <Loading title="Loading..." />;

  const { data: tableData } = data;

  if (tableData.length === 0)
    return <EmptyState title="No Phases for this Date Range" />;

  return (
    <>
      <ColumnHeaders group="phases" />
      {tableData.map(({ name, data: phaseData }) => {
        const hours = sumBy(phaseData, (phase) => parseFloat(phase.hours));
        const value = sumBy(phaseData, (phase) => parseFloat(phase.value));
        const entries = phaseData.length;
        const feeType = phaseData && phaseData[0].phase_fee_type;
        return (
          <div key={name}>
            <ExpandableRow
              rowHeader={name}
              groupAttribute={feeType}
              entries={entries}
              hours={hours}
              value={value}
            >
              <div className={styles.table}>
                <Table
                  data={phaseData}
                  columns={tableColumns(canEditAllTimesheets)}
                  emptyState="No Data for Date Range"
                  hideFooter
                />
              </div>
            </ExpandableRow>
          </div>
        );
      })}
    </>
  );
}
