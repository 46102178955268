import * as React from "react";

import { IconClose } from "@plan/icons";

import { Button } from "../Button";

export interface CloseButtonProps
  extends Omit<
      React.ComponentProps<typeof Button>,
      "intent" | "shape" | "variant"
    >,
    Partial<Pick<React.ComponentProps<typeof IconClose>, "label">> {}

/**
 * ## Usage
 *
 * A helper component for consistent "close" interactions across our design
 * system and its consumers.
 *
 * The component uses `Button` under-the-hood with fixed design constraints.
 *
 * > **Note**: The `CloseButton` should be used **only** for "close" or
 * > "dismiss" actions
 * >
 * >   - ✅ e.g. closing modals, dismissing alerts etc.
 * >   - ❌ e.g. deletion, multiplication etc.
 *
 * ## Props
 *
 * - `label` – a description for differently-sighted users.
 * - `size`
 * - `width`
 */
export const CloseButton: React.FC<CloseButtonProps> = ({
  label = "Close",
  ...props
}) => (
  <Button intent="secondary" variant="ghost" shape="square" {...props}>
    <IconClose label={label} name="Close" />
  </Button>
);
