import { FC } from "react";

import { styled } from "@plan/core";
import { Box, Flex } from "@plan/layout";
import { Skeleton } from "@plan/components";

import { useTimerEntriesQuery } from "../../../../../../apps/web/packages/app/generated/graphql";
import { useTimerModalViewContext } from "../../contexts/TimerModalViewContext";

import { TimerEntryListItem } from "./TimerEntryListItem";

const TimerEntriesWrapper = styled(Box, {
  height: "320px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const CreateButtonContainer = styled(Flex, {
  gap: "$space$4",
  alignItems: "center",
  borderRadius: "5px 5px 0",
  background: "#fff",
  cursor: "pointer",
  padding: "$space$4",
  "&:hover": {
    ":first-child": {
      background: "$colors$-neutral10",
      color: "$white",
      "&::after": {
        background: "$white",
        color: "$colors$-neutral10",
      },
      "&::before": {
        background: "$white",
        color: "$colors$-neutral10",
      },
    },
  },
});

const CreateButtonCopy = styled("h2", {
  fontSize: "$fontSizes$lg",
  fontWeight: "$semibold",
});

const CreateButtonPlay = styled(Box, {
  width: "$space$8",
  height: "$space$8",
  borderRadius: "50%",
  border: "1px solid $colors$-neutral10",
  textAlign: "center",
  position: "relative",
  "&:hover": {
    background: "$colors$-neutral10",
    color: "$white",
    "&::after": {
      background: "$white",
      color: "$colors$-neutral10",
    },
    "&::before": {
      background: "$white",
      color: "$colors$-neutral10",
    },
  },
  "&::after": {
    position: "absolute",
    inset: "0 0 0 0",
    content: " ",
    height: "11px",
    width: "1px",
    margin: "auto",
    background: "$colors$-neutral10",
  },
  "&::before": {
    position: "absolute",
    inset: "0 0 0 0",
    content: " ",
    width: "11px",
    height: "1px",
    margin: "auto",
    background: "$colors$-neutral10",
  },
});

const TimerEntryCreateButton: FC = () => {
  const { showEntryForm } = useTimerModalViewContext();

  return (
    <CreateButtonContainer onClick={showEntryForm}>
      <CreateButtonPlay />
      <CreateButtonCopy>New time entry</CreateButtonCopy>
    </CreateButtonContainer>
  );
};

export const TimerEntriesList: FC = () => {
  const { timerModalView } = useTimerModalViewContext();
  const { data: entriesData, loading } = useTimerEntriesQuery();

  if (timerModalView !== "list") return <></>;

  const entries = entriesData?.timer?.entries || [];
  if (loading) return <Skeleton />;

  return (
    <TimerEntriesWrapper>
      <TimerEntryCreateButton />
      {entries.map((entry) => (
        <TimerEntryListItem entry={entry} key={entry.id} />
      ))}
    </TimerEntriesWrapper>
  );
};
