import React from "react";
import Field from "Components/blocks/forms/Field";
import { useGlobalState } from "../state/GlobalContext.jsx";

function ExpenseSelect({ handleChange, projectId }) {
  const { state } = useGlobalState();

  const expenseCategories =
    state.expenseCategoryOptionsByProjectId[projectId] ||
    state.expenseCategoryOptions;

  const formattedCategories = expenseCategories.map((expense) => ({
    label: expense.name,
    value: expense.id,
  }));

  const sortedCategories = formattedCategories.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <>
      <div>
        <Field
          as="select"
          label="New Expense"
          name="activity"
          options={sortedCategories}
          value={null}
          placeholder="Select a category"
          handleChange={handleChange}
        />
      </div>
    </>
  );
}

export default ExpenseSelect;
