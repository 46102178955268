import { useAction, useApi } from "@hooks";
import { makeRequest } from "./request";

// PROJECTS

// Create a Project
export const createProject = async (data) =>
  makeRequest({
    method: "post",
    url: `/projects`,
    data,
  });

export const useCreateProject = (config) => useAction(createProject, config);

// Update Project
export const updateProject = async (data, slug) =>
  makeRequest({
    method: "put",
    url: `/projects/${slug}`,
    data,
  });

// Search Projects / Phase / Role by profile
// {
//   query,
//     profile_id,
//     begin_date,
//     end_date
// }
export const searchProjectsByProfile = async (data) =>
  makeRequest({
    method: "get",
    url: `/projects/search`,
    data,
  });

// PROJECT TEMPLATES

// Create Project template
export const createProjectTemplate = async (data) =>
  makeRequest({
    method: "post",
    url: `/api/v1/projects/templates`,
    data,
  });

export const useCreateProjectTemplate = (config) =>
  useAction(createProjectTemplate, config);

// Delete project template
export const deleteProjectTemplate = async (slug) =>
  makeRequest({
    method: "delete",
    url: `/api/v1/projects/templates/${slug}`,
  });

export const useDeleteProjectTemplate = (config) =>
  useAction(deleteProjectTemplate, config);

// Fetch project templates and create react-select options
export function useProjectTemplates() {
  const [templates] = useApi("/api/v1/projects/templates");

  // Create select options
  const templateOptions = templates?.data
    ? templates.data.map((template) => ({
        ...template,
        label: template.name,
        value: template.id,
      }))
    : [];

  return {
    templates: templates?.data,
    templateOptions,
  };
}

export const updateProjectTemplate = async (data, slug) =>
  makeRequest({
    method: "put",
    url: `/api/v1/projects/templates/${slug}`,
    data,
  });
