import React from "react";
import { Row, Column } from "Components/blocks/icons";
import cx from "classnames";
import { eventTracker } from "Blocks/tracker";
import styles from "./grid-view.module.css";

export default function GridView({ gridView, setGridView }) {
  const setToRow = () => {
    setGridView("row");
    eventTracker("trackResourceGridViewToggleEdit", { value: "Row" });
  };
  const setToColumn = () => {
    setGridView("column");
    eventTracker("trackResourceGridViewToggleEdit", { value: "Column" });
  };

  return (
    <div className={styles.wrapper}>
      <GridButton onClick={setToColumn} active={gridView === "column"}>
        <Column />
      </GridButton>
      <GridButton onClick={setToRow} active={gridView === "row"}>
        <Row />
      </GridButton>
    </div>
  );
}

const GridButton = ({ active, children, onClick }) => {
  const classes = cx(styles.button, styles.gridView, {
    [styles.active]: active,
  });
  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};
