import React, { useState } from "react";
import moment from "moment";
import { Modal, useModal, Field, ActionButtons, HStack } from "@plan";
import ajax from "Blocks/utils/ajax";
import { loadStripe } from "@stripe/stripe-js";
import eventTracker from "Blocks/tracker.js";
import numeral from "numeral";
import style from "./duplicateProject.module.css";
import TriangleIcon from "./TriangleIcon";
import { UpgradeModal } from "../create/modals";

const formatDollars = (value) => numeral(value).format("0.00");

export default function DuplicateProject({
  slug,
  total_fee = 0,
  projectCreationDisabled,
  stripePubKey,
}) {
  const [modal, modalActions] = useModal(false);
  const [projectBeginDate, setProjectBeginDate] = useState(moment());
  const [totalFee, setTotalFee] = useState(formatDollars(total_fee));
  const [upgradeIsOpen, upgradeModalActions] = useModal(false);
  const upgradeModalTitle = "Need more projects?";
  const pathString = "/projects";

  const [loading, setLoading] = useState(false);

  const projectBundleonSubmit = async (projectAddOn) => {
    const stripeKey = stripePubKey || "";
    const stripe = await loadStripe(stripeKey);

    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Project bundle",
      buttonText: "Purchase now",
      planIntervalSetting: null,
    });

    fetch(`/stripe/checkout?add_on=${projectAddOn}`)
      .then((response) => response.json())
      .then((json) => {
        if (stripe) {
          stripe
            .redirectToCheckout({
              sessionId: json.session_id,
            })
            .then((result) => {
              console.log(result);
            });
        }
      });
  };

  const upgradePlanOnSubmit = async () => {
    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Grow",
      buttonText: "Upgrade to Grow",
      planIntervalSetting: null,
    });

    fetch("/stripe/billing.json", { method: "POST" })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = json.portal_url;
      });
  };

  const duplicateProject = () => {
    setLoading(true);

    const data = {
      duplication: {
        beginDate: moment(projectBeginDate).format("DD-MM-YYYY"),
        totalFee: formatDollars(totalFee),
      },
    };

    ajax({
      method: "POST",
      path: `/api/v1/projects/${slug}/duplications`,
      data,
      success: (res) => {
        const { slug } = JSON.parse(res);
        window.location.href = `/projects/${slug}/edit/planner`;
      },
    });
  };

  const handleDateChange = (e) => {
    if (!e) return setProjectBeginDate(null);
    setProjectBeginDate(moment(e));
  };

  const [showHover, setShowHover] = useState("none");

  const titleText = "Duplicate";
  const descriptionText =
    "Copy this project, its phases, roles, milestones, and tasks.";

  const button = () => {
    if (projectCreationDisabled) {
      return (
        <>
          <div className={style.buttonContainer}>
            <button
              type="button"
              className={style.buttonDisabled}
              onMouseEnter={() => setShowHover("block")}
              onMouseLeave={() => setShowHover("none")}
            >
              <h3 className={style.titleDisabled}>{titleText}</h3>
              <p className={style.descriptionDisabled}>{descriptionText}</p>
            </button>
            <div
              className={style.hoverCard}
              style={{ display: showHover }}
              onMouseEnter={() => setShowHover("block")}
              onMouseLeave={() => setShowHover("none")}
            >
              You’ve reached the project limit for your plan. Purchase a project
              bundle or upgrade for unlimited projects.{" "}
              <span
                className={style.link}
                onClick={() => upgradeModalActions.open()}
              >
                Learn more
              </span>
            </div>
            <div
              className={style.triangle}
              style={{ display: showHover }}
              onMouseEnter={() => setShowHover("block")}
              onMouseLeave={() => setShowHover("none")}
            >
              <TriangleIcon />
            </div>
          </div>
          <UpgradeModal
            projectBundleonSubmit={projectBundleonSubmit}
            upgradePlanOnSubmit={upgradePlanOnSubmit}
            upgradeIsOpen={upgradeIsOpen}
            upgradeModalActions={upgradeModalActions}
            upgradeModalTitle={upgradeModalTitle}
          />
        </>
      );
    }
    return (
      <button
        type="button"
        className={style.buttonEnabled}
        onClick={modalActions.open}
      >
        <h3 className={style.titleEnabled}>{titleText}</h3>
        <p className={style.descriptionEnabled}>{descriptionText}</p>
      </button>
    );
  };

  return (
    <>
      {button()}
      <Modal
        isOpen={modal}
        actions={modalActions}
        title="Duplicate Project"
        size="medium"
      >
        <p>
          Select your new project's start date below to update phase, milestone
          and task dates.
        </p>
        <HStack gap=".5rem">
          <Field
            as="date"
            date={projectBeginDate}
            name="begin_date"
            onDateChange={handleDateChange}
            showClearDates
            required
            label="Start Date"
            size="large"
          />
          <Field
            as="number"
            format="currency"
            label="Total Fee"
            value={totalFee}
            placeholder="0.00"
            name="total_fee"
            step=".01"
            handleChange={(e) => setTotalFee(e.target.value)}
            handleFocus={(e) => setTotalFee(formatDollars(e.target.value))}
            size="large"
            required
            noFloatingLabel
          />
        </HStack>
        <ActionButtons
          buttons={[
            {
              color: "cancel",
              text: "Cancel",
              action: modalActions.close,
            },
            {
              color: "action",
              buttonType: "submit",
              text: "Create",
              disabled: loading || !projectBeginDate,
              action: duplicateProject,
            },
          ]}
        />
      </Modal>
    </>
  );
}
