import React from "react";
import { useModal } from "Components/blocks/modal/useModal";
import eventTracker from "Blocks/tracker";
import UpgradeModal from "./UpgradeModal";
import styles from "./upgradeCard.module.css";

export const WorkDistributionUpgradeCard = ({
  mediaSrc,
  prepaidSeatCount,
  size,
}) => {
  const [isOpen, modalActions] = useModal();

  const buttonText = "Upgrade to Grow";
  const componentTitle = "See how much value your team has created";
  const upgradeModalTitle = "Upgrade your plan";
  const pathString = "/";

  const handleButtonClick = () => {
    eventTracker("trackTeamWorkDistributionUpgrade", {
      componentTitle,
      buttonText,
    });
    eventTracker("trackPlanModalDisplay", { upgradeModalTitle, pathString });
    modalActions.open();
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.heading6}>Unlock with the GROW plan</div>
      <div className={styles.autoLayoutCenter}>
        <div className={styles.flex}>
          <div className={styles.heading2}>{componentTitle}</div>
          <div className={styles.descriptionBox}>
            <p className={styles.text}>
              Get a visualization of hours worked and value created, broken out
              by different roles across projects.
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.button}
              onClick={() => handleButtonClick()}
            >
              {buttonText}
            </button>
          </div>
        </div>
        <div className={styles.imgContainer}>
          <img alt="" src={mediaSrc} className={styles.boxImg} />
        </div>
      </div>
      <UpgradeModal
        isOpen={isOpen}
        modalActions={modalActions}
        path="/"
        prepaidSeatCount={prepaidSeatCount}
        size={size}
      />
    </div>
  );
};

export default WorkDistributionUpgradeCard;
