// Reusable bar component that displays a bar rectangle, can be used in Bar Segment, Bar Stack or simple Bar charts
import React from "react";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// props:
// bar - Object that contains width, height, x, y, fill props.
// rx - Radius of the bar. Pass any number
// skinny - Default is false, pass this prop if want skinny bars
// onHoverStart, onHoverEnd, highlighted and hoverScale are passed if highlight feature needed.
// forwardRef- pass any ref (ex. tooltip ref)

export const BarSegment = ({
  bar,
  rx,
  skinny = false,
  onHoverStart,
  onHoverEnd,
  highlighted,
  hoverScale = 0,
  forwardRef,
}) => {
  // Measurements
  const width = skinny ? bar.width / 5 : bar.width;
  // since we want the bar to be skinnier than the default width
  // we take the default x and width and calc position based on our new, desired width
  const x = skinny ? bar.x + bar.width / 2 - width / 2 : bar.x;

  return (
    <g ref={forwardRef}>
      <motion.rect
        whileHover={{ scaleX: hoverScale }}
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
        initial={{ scaleX: 1, opacity: 0, y: bar.height / 2 }}
        animate={{
          y: 0,
          opacity: highlighted ? 1 : isNil(highlighted) ? 1 : 0.33,
        }}
        transition={{ type: "tween" }}
        style={{
          cursor: isNil(highlighted) ? "auto" : "pointer",
          transformOrigin: "bottom center",
          overflow: "hidden",
        }}
        rx={rx}
        x={x}
        y={bar.y}
        width={width}
        height={bar.height}
        fill={bar.color}
      />
      {rx && (
        <motion.rect
          x={x}
          y={bar.y + bar.height - rx}
          width={width}
          height={rx}
          fill={bar.color}
        />
      )}
    </g>
  );
};

BarSegment.propTypes = {
  bar: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }),
  rx: PropTypes.number,
  skinny: PropTypes.bool,
  onHoverStart: PropTypes.func,
  onHoverEnd: PropTypes.func,
  highlighted: PropTypes.bool,
  hoverScale: PropTypes.number,
  ref: PropTypes.object,
};
