import React, { useEffect } from "react";
import { sumBy } from "lodash";
import { Cell } from "Components/blocks/Table/Table";
import DateDisplay from "Components/blocks/DateDisplay";
import { Currency, Hours } from "@plan";
import useApi from "Components/hooks/useApi";
import { useTimelogContext, useFiltersContext } from "../TimelogContext";

function getPageTotals(array, rowAccessor, value) {
  if (array.length === 0) return null;
  return array
    .map((row) => sumBy(row[rowAccessor], (row) => parseFloat(row[value])))
    .reduce((total, currentValue) => {
      return total + currentValue;
    });
}

export function useTableData(group) {
  const { activeFilters, setFilters } = useFiltersContext();
  const { projectSlug, setTableData, setTotals } = useTimelogContext();

  const groupPath = {
    entries: `/projects/${projectSlug}/time-log-entries`,
    activities: `/projects/${projectSlug}/time-log-activities`,
    phases: `/projects/${projectSlug}/time-log-phases`,
  };

  const [data, loading, error, changeParams] = useApi(groupPath[group], {
    ...activeFilters,
  });

  // When the params change we update the param state
  // in the API hook to trigger new data fetch
  useEffect(() => {
    changeParams(activeFilters);
  }, [activeFilters]);

  useEffect(() => {
    if (data) {
      setFilters([
        {
          name: "profiles",
          options: data.filters.profiles,
          placeholder: "People",
        },
        {
          name: "phases",
          options: data.filters.phases,
          placeholder: "Phases",
        },
        {
          name: "activities",
          options: data.filters.activities,
          placeholder: "Activities",
        },
      ]);
      setTableData(data.data);

      if (group === "entries") return;

      const totals = {
        hours: getPageTotals(data.data, "data", "hours"),
        value: getPageTotals(data.data, "data", "value"),
      };
      setTotals(totals);
    }
  }, [data]);

  return [data, loading, error];
}

export function useColumnHeaders(group) {
  const groupColumnHeaders = {
    entries: [
      "Employee",
      "Date",
      "Role",
      "Rate",
      "Hours",
      "Value",
      "Activity",
      "Billable",
      "Phase",
      "Fee Type",
      "Note",
    ],
    activities: ["Activity", "Billable", "Entries", "Hours", "Amount"],
    phases: ["Phase", "Fee Type", "Entries", "Hours", "Amount"],
  };
  return groupColumnHeaders[group];
}

export function formatExportData(group, data) {
  const exportHeaders = useColumnHeaders(group);

  if (group === "entries") {
    const exportData = data.map((entry) => {
      return Object.values(entry);
    });
    return [exportHeaders, exportData];
  }
  if (group === "phases") {
    const exportData = data.map(({ name, data: phaseData }) => {
      const hours = sumBy(phaseData, (phase) => parseFloat(phase.hours));
      const value = sumBy(phaseData, (phase) => parseFloat(phase.value));
      const entries = phaseData.length;
      const feeType = phaseData && phaseData[0].phase_fee_type;
      return [name, feeType, entries, hours, value];
    });
    return [exportHeaders, exportData];
  }
  if (group === "activities") {
    const exportData = data.map(({ name, data: activityData }) => {
      const hours = sumBy(activityData, (activity) =>
        parseFloat(activity.hours)
      );
      const value = sumBy(activityData, (activity) =>
        parseFloat(activity.value)
      );
      const entries = activityData.length;
      const billable = activityData && activityData[0].activity_billable;
      return [name, billable, entries, hours, value];
    });
    return [exportHeaders, exportData];
  }
}

export function tableColumns(canEditAllTimesheets) {
  return [
    {
      Header: "Employee",
      accessor: "profile_name",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ cell }) => {
        if (canEditAllTimesheets) {
          return (
            <a
              href={`/timesheets/${cell.row.original.profile_slug}/${cell.row.original.date}`}
            >
              <DateDisplay date={cell.value} />
            </a>
          );
        }
        return <DateDisplay date={cell.value} />;
      },
    },
    {
      Header: "Note",
      headerClass: "page-table__title page-table__column--note",
      accessor: "notes",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Rate",
      accessor: "rate",
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell
          type="right"
          value={<Currency value={cell.value} format="withCents" />}
        />
      ),
    },
    {
      Header: `Hours`,
      accessor: "hours",
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell
          type="right"
          value={<Hours value={cell.value} format="twoDecimal" />}
        />
      ),
    },
    {
      Header: "Amount",
      accessor: "value",
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell
          type="right"
          value={<Currency value={cell.value} format="withCents" />}
        />
      ),
    },
  ];
}
