import React, { useRef, useState } from "react";
import makeWeek from "Blocks/utils/makeWeek";
import { Field } from "@plan";
import { useGlobalState } from "../state/GlobalContext.jsx";
import styles from "../timesheet.module.css";
import entryStyles from "../entry.module.css";

function TimeOffRequestDaysEntries({ timeOffRequest }) {
  const { state } = useGlobalState();
  const inputRef = useRef();
  const containerRef = useRef();
  const daysOfWeek = makeWeek(state.date);

  const Tooltip = ({ showTooltip, isHovered }) => {
    if (isHovered) {
      if (showTooltip) {
        return (
          <div className={entryStyles.policyToolTipContainer}>
            <div className={entryStyles.toolTipPointer}>
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5134 0.6621C8.3079 -0.2207 9.6921 -0.2207 10.4866 0.6621L18 9.0103H0L7.5134 0.6621Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={entryStyles.policyToolTip}>
              To edit your paid time off, cancel and resubmit your request using
              the{" "}
              <a href="/time-off/me" style={{ color: "#5840E0" }}>
                time off approvals process
              </a>
              .
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  };

  const Entry = ({ day }) => {
    const [isHovered, setIsHovered] = useState(false);

    const anchorId = `${timeOffRequest.timeOffRequest.id}-${day}`;
    const matchingEntry = timeOffRequest.requestDays.find(
      (requestDay) => requestDay.date === day.date
    );
    const entryValue = matchingEntry ? matchingEntry.hours : 0;

    return (
      <>
        <div className={entryStyles.anchor} id={anchorId} />
        <div ref={containerRef} className={entryStyles.entry}>
          <div
            className={entryStyles.entryField}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Field
              as="text"
              type="text"
              variant="timeInput"
              format="time"
              size="small"
              placeholder="0.00"
              value={entryValue}
              blockClasses="expenseInput"
              classes="expenseInput"
              treatZeroAsEmpty
              forwardRef={inputRef}
              isDisabled
            />
            <Tooltip isHovered={isHovered} showTooltip={entryValue !== 0} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.entries}>
        {daysOfWeek.map((day) => (
          <Entry day={day} />
        ))}
      </div>
    </>
  );
}

export default TimeOffRequestDaysEntries;
