import { styled } from "@plan/core";
import { Flex } from "@plan/layout";

export const TimerButtonContent = styled(Flex, {
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$colors$-success30",
  width: "100%",
  height: "100%",
  borderRadius: "100%",
  "&::after": {
    content: "",
    height: "0px",
    marginLeft: "1px",
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",
    borderLeft: "8px solid $white",
  },
  variants: {
    isActive: {
      true: {
        backgroundColor: "$colors$danger",
        "&::after": {
          marginLeft: "0",
          borderTop: "4px solid $white",
          borderBottom: "4px solid $white",
        },
      },
    },
    large: {
      true: {
        "&::after": {
          content: '""',
          marginLeft: "4px",
          borderTop: "15px solid transparent",
          borderBottom: "15px solid transparent",
          borderLeft: "24px solid $white",
          height: "0px",
        },
      },
    },
    collapsed: {
      true: {
        border: "0.25em solid #213342",
        transition: "border 160ms ease-out",
        "&::after": {
          "@bpXl": {
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderLeft: "10px solid $white",
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      isActive: true,
      large: true,
      css: {
        "&::after": {
          top: "20px",
          left: "20px",
          marginLeft: "0",
          borderTop: "12px solid $white",
          borderBottom: "12px solid $white",
        },
      },
    },
    {
      isActive: true,
      collapsed: true,
      css: {
        left: "$space$1",
        width: "calc(100% - $space$2)",
        height: "calc(100% - $space$2)",
        border: "none",
        transition: "border 160ms ease-in",
        "&::after": {
          "@bpXl": {
            borderTop: "5px solid $white",
            borderBottom: "5px solid $white",
          },
        },
      },
    },
  ],
});
