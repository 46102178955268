import numeral from "numeral";
import React from "react";
import Badge from "Components/blocks/badge/Badge";
import styles from "./headers.module.css";
import { useSchedule } from "../../state/ScheduleContext";

const badgeStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  justifyContent: "left",
  paddingTop: "2px",
  display: "block",
  fontWeight: 600,
};

const TimeOffBadge = () => (
  <div className={styles.overheadBadgeContainer}>
    <Badge
      style={{
        ...badgeStyle,
        maxWidth: "148px",
      }}
      intent="none"
      size="small"
    >
      Time Off
    </Badge>
  </div>
);

const TimeOffHeader = ({ hours, profileSlug }) => {
  const { hasTimeOff } = useSchedule();

  if (!hasTimeOff) return <></>;

  if (!hours && !profileSlug) {
    return <div className={styles.overheadContainer} />;
  }

  const hasHours = hours > 0;

  return (
    <div className={styles.overheadContainer}>
      {hasHours ? (
        <>
          <TimeOffBadge />
          <div className={styles.overheadHours}>
            {numeral(hours).format("0.[00]")} HRS
          </div>
        </>
      ) : (
        <>
          <div className={styles.noOverheadText}>No Time Off</div>
        </>
      )}
    </div>
  );
};

export default React.memo(TimeOffHeader);
