import { eventTracker } from "Blocks/tracker";
import Route from "Blocks/route";
import Gantt from "Blocks/gantt";
import OrganizationProjectsDistribution from "Highcharts/organization-projects-distribution";
import ProfileProjectsDistribution from "Highcharts/profile-projects-distribution";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

class ApplicationIndex extends Route {
  initialLoad() {
    bindListenerToDocument("mousedown", "account__submit", (e) => {
      if (e.target.parentElement.classList.contains("is-demo")) {
        eventTracker("trackDemoOrganizationClicked");
      } else {
        eventTracker("trackPrimaryOrganizationClicked");
      }
    });
  }

  load() {
    this.gantt = new Gantt();
    this.renderCharts();
  }

  beforeCache() {
    this.gantt.unmount();

    if (this.organizationProjectsDistribution) {
      this.organizationProjectsDistribution.destroyChart();
    }
    if (this.profileProjectsDistribution) {
      this.profileProjectsDistribution.destroyChart();
    }
  }

  // Methods

  renderCharts() {
    if ($(".js-organization-projects-distribution").length) {
      if (this.organizationProjectsDistribution) {
        this.organizationProjectsDistribution.initChart();
      } else {
        this.organizationProjectsDistribution =
          new OrganizationProjectsDistribution();
      }
    }

    if ($(".js-profile-projects-distribution").length) {
      if (this.profileProjectsDistribution) {
        this.profileProjectsDistribution.createChart();
      } else {
        this.profileProjectsDistribution = new ProfileProjectsDistribution();
      }
    }
  }
}

const applicationIndex = new ApplicationIndex();
export default applicationIndex;
