import React, { useCallback, useMemo } from "react";
import { useClickAway } from "react-use";
import cx from "classnames";
import { useFieldState } from "./FieldContext";
import styles from "./field.module.css";

export function FieldWrapper({ children }) {
  const {
    as,
    isDisabled,
    hasValue,
    isIncomplete,
    focused,
    size,
    setFocused,
    type,
    classes,
    isHovered,
    hoverRef,
    variant,
    format,
  } = useFieldState();

  // for some cases we pass a global class so that other
  // components can hook into the field.
  const classList = useMemo(
    () =>
      cx(
        { [styles[as]]: as },
        // default styles if there is no 'as' prop
        { [styles.field]: !as },
        { [styles[`field--${format}`]]: format },
        // disabled/enabled
        { [styles["is-disabled"]]: isDisabled },
        { [styles["is-editable"]]: !isDisabled },
        // Does the field have anything in the input?
        { [styles["has-value"]]: hasValue },
        { "has-value": hasValue },
        // In the case of a multiple-field input (e.g. DatePicker), is the field incomplete?
        { [styles["is-incomplete"]]: isIncomplete },
        { "is-incomplete": isIncomplete },
        // Focused
        { [styles["is-focused"]]: focused },
        { "is-focused": focused },
        // Hover
        { [styles["is-hovered"]]: isHovered },
        { "is-hovered": isHovered },
        // Size
        { [styles[`field--${size}`]]: size },
        // Variants
        { [styles[`field--${variant}`]]: variant },
        // Custom classes from the component
        { [classes]: classes }
      ),
    [
      as,
      styles,
      format,
      isDisabled,
      hasValue,
      focused,
      isHovered,
      size,
      variant,
      classes,
    ]
  );

  useClickAway(hoverRef, () => setFocused(false));

  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);

  return (
    <div
      className={classList}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={hoverRef}
      data-type={type}
    >
      {children}
    </div>
  );
}

export default React.memo(FieldWrapper);
