import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import $ from "Blocks/utils/jQuerySelector";

class ProjectsWorkload extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-sort", (event) => {
      this.sort();
    });
    bindListenerToDocument(
      "click",
      ["js-filter", "js-select-reset"],
      (event) => {
        this.filter();
      }
    );
  }

  load() {
    this.setProjectData();
  }

  // beforeCache() {}

  // Methods
  setProjectData() {
    this.projectData = [];
    this.filteredProjectData = [];
    Array.from($(".js-project")).forEach((projectNode) => {
      let name, number, active, budget, categories;

      const node = projectNode;
      if (projectNode.querySelector(".js-project-name")) {
        name = projectNode.querySelector(".js-project-name").innerHTML;
      } else {
        name = "";
      }
      if (projectNode.querySelector(".js-project-number")) {
        number = projectNode.querySelector(".js-project-number").innerHTML;
      } else {
        number = "";
      }

      active = projectNode.dataset.isActive === "true";

      categories = JSON.parse(projectNode.dataset.categories);
      const project = { node, name, number, active, budget, categories };
      this.projectData.push(project);
    });
  }

  sort() {
    const selectNode = $(".js-sort-select")[0];
    const query = selectNode.dataset.value;
    this.projectData.sort((a, b) => {
      if (a[query] < b[query]) {
        return -1;
      } else if (a[query] > b[query]) {
        return 1;
      } else {
        return 0;
      }
    });

    this.updateDom();
  }

  filter() {
    this.resetFilters();
    this.filterByCategory();
    this.sort();
  }

  resetFilters() {
    this.projectData = this.projectData.concat(this.filteredProjectData);
    this.filteredProjectData = [];
  }

  filterByCategory() {
    const categorySelectNode = $(".js-category-select")[0];
    let filterValue = categorySelectNode.dataset.value;
    if (filterValue) {
      filterValue = parseInt(filterValue);
      this.projectData = this.projectData.filter((project) => {
        if (!project.categories.includes(filterValue)) {
          this.filteredProjectData.push(project);
        }
        return project.categories.includes(filterValue);
      });
    }
  }

  updateDom() {
    const projectsContainer = $(".js-projects")[0];
    projectsContainer.innerHTML = "";
    this.projectData.forEach((project) => {
      projectsContainer.appendChild(project.node);
    });
  }

  // Helpers
}

const projectsWorkload = new ProjectsWorkload();
export default projectsWorkload;
