import React, { useState } from "react"; // Sets base state for handling filters on reports pages

const transformValue = (e) => {
  if (e === null || e.value === null) return null;
  // handle multi-select values
  if (Array.isArray(e)) return e.map((item) => item.value);
  // otherwise just set it to the single selected value
  return e.value;
};

// https://www.notion.so/monographhq/useFilters-Hook-dbf9b9f710834024bf41fd1322076a02
export function useFilters({ initialActiveFilters = {}, initialFilters = [] }) {
  const [activeFilters, setActiveFilters] = useState({
    ...initialActiveFilters,
  });
  const [filters, setFilters] = useState([...initialFilters]);

  const updateFilters = (e, key) => {
    setActiveFilters({
      ...activeFilters,
      [key]: transformValue(e),
    });
  };

  const updateDates = (e) => {
    const dates = {};
    if (e.startDate) dates.begin_date = e.startDate.format("YYYY-MM-DD");
    if (e.endDate) dates.end_date = e.endDate.format("YYYY-MM-DD");
    setActiveFilters({
      ...activeFilters,
      ...dates,
    });
  };

  return {
    filters,
    activeFilters,
    setActiveFilters,
    updateDates,
    updateFilters,
    setFilters,
  };
}

export default useFilters;
