import React from "react";
import styles from "./colorSelect.module.css";

const ColorSelectOption = ({ label, value }) => {
  return (
    <div className={styles.colorOption} style={{ background: value }}>
      {label}
    </div>
  );
};

export default ColorSelectOption;
