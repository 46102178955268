import EntryItem from "Components/timesheets/EntryItem";
import timesheetsEdit from "Custom/timesheets-edit";
import React from "react";
import moment from "moment";

export default class EntryList extends React.Component {
  constructor(props) {
    super(props);

    this.setChildNoteVisibility = this.setChildNoteVisibility.bind(this);

    this.state = {
      isChildNoteVisibile: false,
      keyForCellWithNote: "",
    };
  }

  componentDidMount() {
    timesheetsEdit.sumAllWeekdates();
  }

  setChildNoteVisibility(visibility, key) {
    this.setState({
      isChildNoteVisibile: visibility,
      keyForCellWithNote: key,
    });
  }

  getExpenses(date) {
    return this.props.expenses.filter((expense) => expense.date === date);
  }

  renderEntryItem(date) {
    if (this.props.project) {
      return (
        <EntryItem
          entryId={this.props.entry.id}
          label={moment(date).format("ddd")}
          date={date}
          editable={this.props.editable}
          data={this.props.entry.data}
          key={this.props.entry.id + moment(date).format("x")}
          accessibleKey={this.props.entry.id + moment(date).format("x")}
          componentWithNote={this.state.keyForCellWithNote}
          noteListVisiblity={this.state.isChildNoteVisibile}
          setChildNoteVisibility={this.setChildNoteVisibility}
          project
          projectName={this.props.projectName}
          expenses={this.getExpenses(date)}
          expenseCategories={this.props.expenseCategories}
        />
      );
    }
    return (
      <EntryItem
        entryId={this.props.entry.id}
        label={moment(date).format("ddd")}
        date={date}
        editable={this.props.editable}
        data={this.props.entry.data}
        key={this.props.entry.id + moment(date).format("x")}
        accessibleKey={this.props.entry.id + moment(date).format("x")}
        componentWithNote={this.state.keyForCellWithNote}
        noteListVisiblity={this.state.isChildNoteVisibile}
        setChildNoteVisibility={this.setChildNoteVisibility}
        project={false}
      />
    );
  }

  render() {
    return (
      <>
        {this.props.dates.map((date) => this.renderEntryItem(date))}
        <div className="entry-total js-entry-total">
          {this.props.totalHours}
        </div>
      </>
    );
  }
}
