import bindListenerToDocumentGlobal from "Blocks/utils/bindListenerToDocumentGlobal";

// Add .is-focused class on focusin
bindListenerToDocumentGlobal("focusin", "js-field-input", (event) => {
  const fieldParent = event.target.closest(".js-field");
  fieldParent.classList.add("is-focused");
});

// Remove .is-focus class on focusout
bindListenerToDocumentGlobal("focusout", "js-field-input", (event) => {
  const fieldParent = event.target.closest(".js-field");
  fieldParent.classList.remove("is-focused");
});

// On change of field, add "has-value" if value is not ""
bindListenerToDocumentGlobal("change", "js-field-input", (event) => {
  addHasValue(event.target);
});

// Toggle editability of field
// We are using the value attribute of the field element to store
// the old value when toggling back to an uneditable state.
// fieldInput.value is the property
// fieldInput.getAttribute("value") is the value
bindListenerToDocumentGlobal("click", "js-field-toggle", (event) => {
  const field = event.target.closest(".js-field");
  const fieldInput = field.querySelector(".js-field-input");
  if (field.classList.contains("is-editable")) {
    fieldInput.disabled = true;
    field.classList.add("is-disabled");
    field.classList.remove("is-editable");
    fieldInput.value = fieldInput.getAttribute("value");
    // We need this or else it looks like it has no value even after it's value
    // is set above
    addHasValue(field);
  } else {
    fieldInput.disabled = false;
    field.classList.remove("is-disabled");
    field.classList.add("is-editable");
  }
});

bindListenerToDocumentGlobal("click", "js-field-save", (event) => {
  const field = event.target.closest(".js-field");
  const fieldInput = field.querySelector(".js-field-input");
  const value = fieldInput.value;
  fieldInput.setAttribute("value", value);
  // Do we want to toggle the view?
  // fieldInput.disabled = true;
  // field.classList.add("is-disabled");
  // field.classList.remove("is-editable");
});

export const addHasValue = (field) => {
  let fieldContainer = field.closest(".js-field");
  if (field.value !== "") {
    fieldContainer.classList.add("has-value");
  } else {
    fieldContainer.classList.remove("has-value");
  }
};

export const addHasValueOnLoad = () => {
  Array.from($(".js-field-input")).forEach((field) => {
    addHasValue(field);
  });
};
