import React, { useMemo } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import cx from "classnames";
import styles from "./hours.module.css";

const formatters = {
  default: "0,0.[00]",
  noDecimal: "0",
  twoDecimal: "0,0.00",
};

function formatHours(value, format) {
  return numeral(value).format(formatters[format]);
}

export function Hours({ value, format, label, labelText }) {
  const isNegative = value < 0;
  const amount = useMemo(() => formatHours(value, format), [format, value]);
  return (
    <span className={cx({ [styles.negative]: isNegative })}>
      {amount}
      {label && <span className={styles.hoursLabel}>{labelText}</span>}
    </span>
  );
}

Hours.propTypes = {
  format: PropTypes.oneOf(["default", "noDecimal", "twoDecimal"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Hours.defaultProps = {
  format: "default",
  value: 0,
  label: false,
  labelText: "HRS",
};

export default Hours;
