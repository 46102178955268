
const digest = 'b526509014ac903926a118e2b57ba90e791cc4ee0f151fc8772757da8e795e98';
const css = `._header_9vxqq_1 {
  display: flex;
  padding-top: 8px;
  padding-bottom: 4px;
}

._number_9vxqq_7 {
  background: var(--color-neutral-40);
  display: flex;
  align-items: center;
  border-radius: 0.1875rem;
  color: var(--color-white);
  font-size: 0.625rem;
  line-height: 1;
  margin-right: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0.25rem;
}

._project_9vxqq_21 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
  text-transform: none;
  color: var(--color-neutral-40);
}

._phase_9vxqq_29 {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"header":"_header_9vxqq_1","number":"_number_9vxqq_7","project":"_project_9vxqq_21","phase":"_phase_9vxqq_29"};
export { css, digest };
  