import { useToasts } from "@plan/components";

import {
  TimerEntryFragment,
  UpdateEntryInput,
  useUpdateTimerEntryMutation,
} from "../../../../../apps/web/packages/app/generated/graphql";

import { ApolloError } from "@apollo/client";

interface useUpdateTimerEntryProps {
  successCallback: (newEntry: TimerEntryFragment) => void;
  errorCallback: () => void;
}

const TIMER_ENTRY_NO_LONGER_EXISTS = "TIMER_ENTRY_NO_LONGER_EXISTS";

const hasMissingEntry = (error: ApolloError) => {
  return error.graphQLErrors.find(
    ({ extensions }) => extensions?.code === TIMER_ENTRY_NO_LONGER_EXISTS
  );
};

export const useUpdateTimerEntry = ({
  successCallback,
  errorCallback,
}: useUpdateTimerEntryProps) => {
  const { addToast } = useToasts();

  const [updateEntryMutation] = useUpdateTimerEntryMutation({
    onCompleted(data) {
      if (!data.updateEntry?.entry) return;
      const updatedEntry = data.updateEntry?.entry;

      addToast({
        message: `Successfully saved timesheet entry.`,
        intent: "success",
      });

      successCallback(updatedEntry);
    },
  });

  const useUpdateTimerEntry = async (entry: UpdateEntryInput) => {
    await updateEntryMutation({
      variables: {
        input: {
          ...entry,
        },
      },
      onError: (error) => {
        let errorMessage = "Error saving timesheet entry.";

        if (hasMissingEntry(error)) {
          errorMessage =
            "The timesheet entry you were tracking no longer exists.";
        }

        if (entry.hours) {
          errorMessage += ` We've removed ${entry.hours.toFixed(
            2
          )} hours and reset the timer.`;
        }

        addToast({
          message: errorMessage,
          intent: "danger",
        });

        errorCallback();
      },
    });

    return true;
  };

  return useUpdateTimerEntry;
};
