import React, { useEffect, useState } from "react";

export function useSetUrlParams(initialParams) {
  const [parameters, setParameters] = useState(initialParams);

  useEffect(() => {
    // create the new query string
    const url = new URLSearchParams();

    // pull the parameter names
    const newParameterNames = Object.keys(parameters);

    // update the query string
    newParameterNames.forEach((newParameterName) => {
      if (parameters[newParameterName])
        return url.set(newParameterName, parameters[newParameterName]);
    });

    // create the new path
    const path = `${window.location.pathname}?${url.toString()}`;

    // update the browser URL
    history.replaceState(null, "", path);
  }, [parameters]);

  return [new URLSearchParams(window.location.search), setParameters];
}

export default useSetUrlParams;
