import React, { useContext, useState } from "react";

export const ScheduleContext = React.createContext({});

export function ScheduleProvider({
  children,
  scheduleId,
  populatedBy,
  hasTimeOff,
  hasAllocations,
  date,
}) {
  const [viewFinancials, setViewFinancials] = useState(true);

  return (
    <ScheduleContext.Provider
      value={{
        scheduleId,
        date,
        populatedBy,
        hasTimeOff,
        hasAllocations,
        viewFinancials,
        setViewFinancials,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
}

// Data
export const useSchedule = () => useContext(ScheduleContext);
