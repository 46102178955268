import { ComponentType } from "react";

import { createComponent, styled } from "@plan/core";
import { Icon } from "@plan/icons";

const component = createComponent();

export type LinkProps = ComponentType<typeof Link>;

export const Link = styled("a", {
  ...component,
  appearance: "none",
  backgroundColor: "$colors$transparent",
  border: 0,
  borderRadius: "$radii$default",
  color: "$colors$brand",
  cursor: "pointer",
  display: "inline",
  fontSize: "1em",
  fontWeight: "$fontWeights$semibold",
  lineHeight: "inherit",
  padding: 0,
  textAlign: "inherit",
  textDecoration: "none",
  "> * + *": {
    marginLeft: "0.25em", // ~$space$2
  },
  [`${Icon}`]: {
    fontSize: "inherit",
    lineHeight: "inherit",
    // arbitrary offset from text-bottom to the visual center
    transform: "translateY(-0.15em)",
    verticalAlign: "text-bottom",
  },
  "@motionSafe": {
    transition: "0.2s ease",
    transitionProperty: "background-color, border-color, color",
  },
  variants: {
    ...component.variants,
    disabled: {
      false: {
        "&:hover": {
          textDecoration: "underline",
        },
        "&:focus": {
          ring: "$colors$-brand30",
        },
      },
      true: {
        opacity: 0.4,
        pointerEvents: "none",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    disabled: false,
  },
});
