import React, { useEffect, useRef } from "react";
import { useClickAway, useWindowSize } from "react-use";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import { ModalProvider } from "./ModalContext";
import styles from "./modal.module.css";

export default function ModalFrame(props) {
  const { isOpen, actions, size, children } = props;
  const { height: windowHeight } = useWindowSize();
  const [modalHeight, setModalHeight] = React.useState(0);
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      setModalHeight(modalRef.current.clientHeight);
    }
  }, [modalRef]);

  useClickAway(modalRef, () => {
    actions.close();
  });

  const theModalIsTooDangTall = modalHeight > windowHeight;

  return (
    <ModalProvider {...props} isOpen={isOpen}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={cx(styles.overlay, {
              [styles.tall]: theModalIsTooDangTall,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            clas
          >
            <motion.div
              ref={modalRef}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              className={cx(styles.modal, styles[size])}
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalProvider>
  );
}
