export const CREATE_ALLOCATION = "CREATE_ALLOCATION";
export const UPDATE_ALLOCATION = "UPDATE_ALLOCATION";
export const REMOVE_ALLOCATION = "REMOVE_ALLOCATION";
export const RESET_DATA = "RESET_DATA";

export default function reducer(state, action) {
  if (action.type === CREATE_ALLOCATION) {
    const { entryGroup, phase, project, role } = action.payload;
    // Add the EntryGroup
    state.entryGroups.byId[entryGroup.id] = entryGroup;
    state.entryGroups.byId[entryGroup.id].added = true;
    state.entryGroups.allIds.push(entryGroup.id);
    // Set the project
    state.projects.byId[project.id] = project;
    if (!state.projects.allIds.includes(project.id))
      state.projects.allIds.push(project.id);
    // Set the phase
    if (phase?.id) state.phases.byId[phase.id] = phase;
    if (phase?.id && !state.phases.allIds.includes(phase.id))
      state.phases.allIds.push(phase.id);
    // Set the rol
    if (role?.id) state.roles.byId[role.id] = role;
    if (role?.id && !state.roles.allIds.includes(role.id))
      state.roles.allIds.push(role.id);
    return;
  }

  if (action.type === UPDATE_ALLOCATION) {
    const { value, id } = action.payload;
    // Hack due to decimals in the data 1.0 vs 1
    if (
      state.entryGroups.byId[id] &&
      value !== parseFloat(state.entryGroups.byId[id].allocation)
    ) {
      state.entryGroups.byId[id].allocation = value;
      state.entryGroups.byId[id].allocatedByUser = true;
    }
    return;
  }

  if (action.type === REMOVE_ALLOCATION) {
    const { id } = action.payload;
    state.entryGroups.allIds = state.entryGroups.allIds.filter(
      (entry) => entry !== id
    );
    delete state.entryGroups.byId[id];
    return;
  }

  if (action.type === RESET_DATA) {
    return action.payload.data;
  }

  return state;
}
