import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ selector, children }) => {
  const [el, setEl] = useState();

  useEffect(() => {
    setEl(document.querySelector(selector));
  }, []);

  if (el === undefined) return null;
  return createPortal(children, el);
};

Portal.defaultProps = {
  selector: "body",
};

export default Portal;
