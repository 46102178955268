import { FC } from "react";

import { styled } from "@plan/core";
import { Flex } from "@plan/layout";
import { Button, Text } from "@plan/components";

import { useTimerContext } from "../../contexts/TimerContext";

const TimerConfirm = styled(Flex, {
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "$white",
  borderRadius: "$normal",
  zIndex: "$masked",
  padding: "$space$4 $space$12 $space$12",
});

const TimerButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  marginTop: "$space$4",
  width: "75%",
});

interface ConfirmTimerResetProps {
  hideResetConfirmation: () => void;
}

export const ConfirmTimerReset: FC<ConfirmTimerResetProps> = ({
  hideResetConfirmation,
}) => {
  const {
    actions: { resetTimerTime },
  } = useTimerContext();

  const handleButtonClick = () => {
    resetTimerTime();
    hideResetConfirmation();
  };

  return (
    <TimerConfirm>
      <Text
        textStyle="extraLarge"
        css={{
          width: "75%",
          padding: "$space$2",
        }}
      >
        Are you sure you want to reset time on this entry?
      </Text>
      <TimerButtonContainer>
        <Button
          size="md"
          intent="primary"
          type="button"
          onClick={handleButtonClick}
        >
          Reset Entry
        </Button>
        <Button
          size="md"
          type="button"
          intent="secondary"
          onClick={hideResetConfirmation}
        >
          Cancel
        </Button>
      </TimerButtonContainer>
    </TimerConfirm>
  );
};
