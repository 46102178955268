import React from "react";
import styles from "./upgradePage.module.css";
import { PLAN_DETAILS, PLAN_TRACK, PLAN_GROW } from "./constants";

const getAnnualPricePerMonth = (annualPrice) => annualPrice / 12;

const getAnnualDiscountAmount = (monthlyPrice, annualPrice) =>
  Math.round((monthlyPrice - getAnnualPricePerMonth(annualPrice)) * 12);

export const TrackCard = ({ showAnnual, prepaidSeatCount, size }) => {
  const { monthlyPrice, annualPrice, annualMonthlyPrice } =
    PLAN_DETAILS[PLAN_TRACK];
  const paidSize =
    prepaidSeatCount && size && prepaidSeatCount > size
      ? prepaidSeatCount
      : size;

  const discount =
    paidSize && getAnnualDiscountAmount(monthlyPrice, annualPrice) * paidSize;

  return (
    <div className={styles.modalFlex}>
      <div className={styles.card} style={{ height: "100%" }}>
        <h2 className={styles.heading5}>Track</h2>
        <p className={styles.detailText}>Track project progress</p>
        <ul style={{ paddingTop: "12px", paddingBottom: "8px" }}>
          <p className={styles.listText}>
            <li className={styles.listItem}>Track time and budgets</li>
          </p>
          <p className={styles.listText}>
            <li className={styles.listItem}>Efficient invoicing</li>
          </p>
          <p className={styles.listText}>
            <li className={styles.listItem}>Online help center</li>
          </p>
        </ul>
        <div className={styles.cardWrap}>
          <div className={styles.cardBoxTrack}>
            <a
              className={styles.compareLink}
              href="https://monograph.com/pricing"
              target="_blank"
              rel="noreferrer"
            >
              Compare features
            </a>
          </div>
          <div className={styles.cardFlex}>
            <div className={styles.cardAutoLayout}>
              <h2 className={styles.heading5}>
                {showAnnual ? `$${annualMonthlyPrice}` : `$${monthlyPrice}`}
                &nbsp;
              </h2>
              <p className={styles.perMember}>per member/month</p>
            </div>
            {showAnnual ? (
              <div className={styles.cardAutoLayout}>
                <div className={styles.saveAmountText}>
                  {`$${annualPrice} annually (save $${discount})`}
                </div>
              </div>
            ) : null}
            <p style={{ marginTop: "1.1rem" }} className={styles.seatMinimum}>
              100 projects included
            </p>
          </div>
          <div className={styles.badgeContainer}>
            <div className={styles.badge}>Current plan</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GrowCard = ({ showAnnual, onSubmit, prepaidSeatCount, size }) => {
  const { monthlyPrice, annualPrice, annualMonthlyPrice } =
    PLAN_DETAILS[PLAN_GROW];
  const paidSize =
    prepaidSeatCount && size && prepaidSeatCount > size
      ? prepaidSeatCount
      : size;
  const discount =
    paidSize && getAnnualDiscountAmount(monthlyPrice, annualPrice) * paidSize;

  return (
    <div className={styles.modalFlex}>
      <div className={styles.card}>
        <h2 className={styles.heading5}>Grow</h2>
        <p className={styles.detailText}>Forecast project performance</p>
        <ul style={{ paddingTop: "12px", paddingBottom: "8px" }}>
          <p className={styles.listText}>
            <li className={styles.listItem}>Everything in Track</li>
          </p>
          <p className={styles.listText}>
            <li className={styles.listItem}>Forecast staff workload</li>
          </p>
          <p className={styles.listText}>
            <li className={styles.listItem}>Gain insights on profitability</li>
          </p>
          <p className={styles.listText}>
            <li className={styles.listItem}>
              Guided onboarding + support for US based firms
            </li>
          </p>
          <div className={styles.cardBox}>
            <a
              className={styles.compareLink}
              href="https://monograph.com/pricing"
              target="_blank"
              rel="noreferrer"
            >
              Compare features
            </a>
          </div>
        </ul>
        <div className={styles.cardFlex}>
          <div className={styles.cardAutoLayout}>
            <h2 className={styles.heading5}>
              {showAnnual ? `$${annualMonthlyPrice}` : `$${monthlyPrice}`}&nbsp;
            </h2>
            <p className={styles.perMember}>per member/month</p>
          </div>
          {showAnnual ? (
            <div className={styles.cardAutoLayout}>
              <div className={styles.saveAmountText}>
                {`$${annualPrice} annually (save $${discount})`}
              </div>
            </div>
          ) : null}
          <p className={styles.seatMinimum}>Includes 5 seats</p>
          <p style={{ marginTop: "0.25rem" }} className={styles.seatMinimum}>
            Unlimited projects
          </p>
        </div>
        <div className={styles.badgeContainer}>
          <button
            type="submit"
            className={styles.upgradeButton}
            onClick={onSubmit}
          >
            Upgrade to Grow
          </button>
        </div>
      </div>
    </div>
  );
};
