/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { PasswordComplexityMeter } from "@plan/components";

import propTypes from "prop-types";
import { makeRequest } from "../api/request";
import { Field } from "./forms/Field";
import { FlexWrapper } from "./wrappers/FlexWrapper";

export const PasswordInput = ({ withComplexity, ...fieldProps }) => {
  const [password, setPassword] = useState("");

  const checkPassword = async (value) => {
    const response = await makeRequest({
      url: "/pw-entropy-check",
      method: "post",
      data: { value },
    });

    const { entropy, is_strong: isStrong } = response.data;

    return {
      entropy,
      isStrong,
    };
  };

  return (
    <FlexWrapper direction="column">
      <Field
        type="password"
        value={password}
        handleChange={(val) => {
          setPassword(val.target.value);
        }}
        {...fieldProps}
      />
      {withComplexity && (
        <div style={{ margin: "1rem 0" }}>
          <PasswordComplexityMeter
            password={password}
            evaluator={checkPassword}
          />
        </div>
      )}
    </FlexWrapper>
  );
};

PasswordInput.propTypes = {
  withComplexity: propTypes.bool,
};

PasswordInput.defaultProps = {
  withComplexity: false,
};

export default PasswordInput;
