import { eventTracker } from "Blocks/tracker";
import { setCookie, readCookie } from "Blocks/utils/cookies";

/* global document */

const CannyChangelogSeenKey = "_monograph:canny:changelog:seen";

const sidebar = {
  minibar: readCookie("sidebarState") === "collapsed",
  minibarChangeEvent: new CustomEvent("minibarChange", {
    detail: { isOpen: () => !sidebar.isMini() },
  }),

  setMinibar: () => {
    const html = document.documentElement;

    if (sidebar.minibar) {
      html.classList.add("minibar");
    } else {
      html.classList.remove("minibar");
    }

    window.dispatchEvent(sidebar.minibarChangeEvent);
  },

  toggle: () => {
    // change state
    sidebar.minibar = !sidebar.minibar;
    // Set cookie
    const newSidebarState = sidebar.minibar ? "collapsed" : "open";
    setCookie("sidebarState", newSidebarState);

    sidebar.setMinibar(sidebar.minibar);
  },

  init: () => {
    sidebar.setMinibar(sidebar.minibar);
    sidebar.setEventListeners();
    sidebar.initializeCanny();
  },

  isMini: () => sidebar.minibar,

  initializeCanny: () => {
    // Await async Canny SDK initialization. There's no `onLoad` method to denote initialization
    if (typeof Canny === "undefined") {
      setTimeout(sidebar.initializeCanny, 250);
      return;
    }

    // eslint-disable-next-line no-undef
    Canny("initChangelog", {
      appID: "5de5ed7176d66329d87bdcbc",
      position: "right",
      align: "bottom",
    });

    // Prevent clicks on Canny button from trigger navigation events
    const el = document.querySelector("[data-canny-changelog]");
    el.addEventListener("click", (event) => {
      const hadNotification = !!document.querySelector(".Canny_Badge");
      eventTracker("trackWhatsNewClicked", { hadNotification });
      event.preventDefault();
      event.stopPropagation();
    });

    // we need to wait a few seconds until the badge is loaded and visible before we can click it
    setTimeout(() => {
      // If the badge is showing, and the user doesn't have `_monograph:canny:changelog:seen` set in local storage
      // (which means this is their first time seeing the badge), we set it both on local and session storage.
      if (document.querySelector(".Canny_BadgeContainer")) {
        if (!localStorage.getItem(CannyChangelogSeenKey)) {
          localStorage.setItem(CannyChangelogSeenKey, "true");
          sessionStorage.setItem(CannyChangelogSeenKey, "true");
        }
        // If the user has `_monograph:canny:changelog:seen` set in localStorage, this means the user has seen
        // the badge before, so we need to check if they have it set in sessionStorage
        // (which would mean they've seen the badge during the current session). If they haven't, we trigger the changelog open an clear `_monograph:canny:changelog:seen` from both local storage.
        else if (!sessionStorage.getItem(CannyChangelogSeenKey)) {
          el.click();
          localStorage.removeItem(CannyChangelogSeenKey);
        }
      }
    }, 1500);
  },

  toggleElement: (element) => {
    if (element.style.display === "none") {
      element.style.display = "flex";
    } else {
      element.style.display = "none";
    }
  },

  setEventListeners: () => {
    document.addEventListener("click", (event) => {
      if (
        event.target &&
        event.target.classList &&
        event.target.classList.contains("js-sidebar-toggle")
      ) {
        sidebar.toggle();
      } else if (
        event.target.classList.contains("js-track-help-link-clicked")
      ) {
        eventTracker("trackHelpLinkClicked");
      } else if (
        event.target.classList.contains("js-track-reports-link-clicked")
      ) {
        eventTracker("trackReportsLinkClicked");
      } else if (
        event.target.classList.contains("js-track-checklist-link-clicked")
      ) {
        eventTracker("trackOnboardingChecklistButtonClicked");
      }
    });
  },
};

export default sidebar;
