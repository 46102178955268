import React, { useState } from "react";
import { Card, Field, useModal, Tooltip, useTooltip } from "@plan";
import { useDebouncedSave } from "@hooks";
import { renderError } from "Blocks/alert";
import { useMutation } from "@apollo/client";
import { Delete } from "./Delete";
import styles from "../consultants.module.css";
import { UPDATE_BUDGET } from "../queries";
import { useSessionStorage } from "react-use";
import InvoiceWarningModal from "./InvoiceWarningModal";

const Consultant = ({ consultant }) => {
  const { budget: initialBudget, budgetIsFrozen, name, id } = consultant;
  const [budget, setBudget] = useState(initialBudget);
  const [updateBudget] = useMutation(UPDATE_BUDGET, {
    onError: () => renderError(),
  });

  const [deleteTooltip, deleteNode] = useTooltip();

  const save = () =>
    updateBudget({
      variables: { input: { id, budget } },
      optimisticResponse: {
        updateProjectConsultantBudget: {
          id,
          budget,
        },
      },
    });

  const handleUpdate = (e) => setBudget(Number(e.target.value));

  useDebouncedSave(budget, save, { delay: 500, utilSave: true });

  const [modal, modalActions] = useModal(false);

  const [value, setValue] = useSessionStorage(
    `hasVisitedConsultant-${id}`,
    false
  );

  const handleBudgetChange = (e) => {
    if (!value && budgetIsFrozen) {
      modalActions.open();
    } else {
      handleUpdate(e);
    }
  };

  return (
    <Card key={id} classes={[styles.cardGrid]}>
      <div data-consultant-id={id} className={styles.cardTitle}>
        {name}
      </div>
      <Field
        as="number"
        label="Budget"
        noFloatingLabel
        format="currency"
        placeholder="0.00"
        value={budget}
        handleChange={handleBudgetChange}
      />
      <InvoiceWarningModal
        actions={modalActions}
        isOpen={modal}
        setValue={setValue}
      />
      {budgetIsFrozen && (
        <Tooltip
          text="There are invoices tied to this Consultant so it can't be deleted."
          tooltip={deleteTooltip}
          light
          position="left"
        />
      )}
      <div ref={deleteNode} style={{ display: "flex", alignItems: "center" }}>
        <Delete name={name} id={id} disabled={budgetIsFrozen} />
      </div>
    </Card>
  );
};

export default Consultant;
