import React from "react";

export const Invoices = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 5a1 1 0 011-1h6a1 1 0 110 2H5a1 1 0 01-1-1zM4 8a1 1 0 011-1h4a1 1 0 010 2H5a1 1 0 01-1-1z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.625 15.78A1 1 0 010 15V3a3 3 0 013-3h10a3 3 0 013 3v12a1 1 0 01-1.625.78l-1.841-1.472-1.62 1.44a1 1 0 01-1.328 0L8 14.337l-1.586 1.41a1 1 0 01-1.328 0l-1.62-1.44-1.841 1.473zM2 3v9.92l.875-.7a1 1 0 011.29.033l1.585 1.409 1.586-1.41a1 1 0 011.328 0l1.586 1.41 1.586-1.41a1 1 0 011.289-.033l.875.7V3a1 1 0 00-1-1H3a1 1 0 00-1 1z"
      />
    </svg>
  );
};

export default React.memo(Invoices);
