import * as React from "react";

import { styled } from "@plan/core";

const StyledCell = styled("div", {
  display: "inline-block",
  lineHeight: "$tight",
  // FIXME (BREAKING CHANGE)
  // Amend `rem` value to closest $space key (or calc value) on first refactor
  maxWidth: "30rem",
  variants: {
    fixed: {
      true: {
        height: "100%",
        width: "100%",
      },
    },
    bold: { true: { fontWeight: "$bold" } },
    colorScheme: {
      brand: { color: "$colors$brand" },
      success: { color: "$colors$success" },
      danger: { color: "$colors$danger" },
      muted: { color: "$colors$neutral10" },
    },
  },
});

export type CellProps = React.ComponentProps<typeof StyledCell> & {
  value?: React.ReactNode;
};

export const Cell: React.FC<CellProps> = ({
  fixed,
  bold = false,
  colorScheme,
  value = undefined,
  ...props
}) => {
  if (!value) return <>--</>;
  return (
    <StyledCell fixed={fixed} bold={bold} colorScheme={colorScheme} {...props}>
      {value}
    </StyledCell>
  );
};
