import { useEffect, useState } from "react";
import { useMotionValue } from "framer-motion";
import { useSelectedPhase } from "../state/Store";

export default function useScrollSync(ref) {
  const scroll = useMotionValue(0);
  const [isDragging, setDragging] = useState(false);
  const [selectedPhase] = useSelectedPhase();

  useEffect(() => {
    const unsubscribeScroll = scroll.onChange((value) => {
      ref.current.scrollLeft = value;
    });
    return unsubscribeScroll;
  }, []);

  useEffect(() => {
    if (selectedPhase) scroll.set(0);
  }, [selectedPhase]);

  return { isDragging, setDragging, scroll };
}
