import { useMemo } from "react";
import { sample } from "lodash";

// Take a set of colors and return select options + a random selection
export function useColorOptions(colors) {
  // Create select options
  const colorOptions = useMemo(
    () =>
      colors.map((color) => ({
        ...color,
        label: color.number,
        value: color.hex,
      })),
    [colors]
  );

  // Randomly select a color from the list
  const randomColor = useMemo(() => sample(colorOptions), [colorOptions]);

  return { colorOptions, randomColor };
}

export default useColorOptions;
