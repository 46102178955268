import Route from "Blocks/route";
import Gantt from "Blocks/gantt";

class ShareableShow extends Route {
  initialLoad() {}

  load() {
    this.gantt = new Gantt();
  }

  beforeCache() {
    this.gantt.unmount();
  }

  // Methods
}

const shareableShow = new ShareableShow();
export default shareableShow;
