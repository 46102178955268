import React, { useCallback } from "react";
import {
  updateAllocation as updateAllocationApi,
  deleteAllocation as deleteAllocationApi,
} from "Components/api/index";
import { useAction, useDebouncedSave } from "Hooks";
import { eventTracker } from "Blocks/tracker";
import {
  useDispatch,
  useNoFilterData,
  updateAllocation,
  removeAllocation,
  useAllocations,
} from "../../../state/Store";

export default function useUpdateAllocations(id) {
  const allocations = useAllocations();
  const dispatch = useDispatch();
  const { noFilterDispatch } = useNoFilterData();
  const [_, allocateToApi] = useAction(updateAllocationApi, {
    renderErrors: true,
  });

  useDebouncedSave(
    Number(allocations[id].allocation),
    async () => {
      await allocateToApi({
        allocation: allocations[id].allocation,
        id,
      });
    },
    { delay: 500, utilSave: true }
  );

  const handleChange = useCallback(
    async (value) => {
      noFilterDispatch(updateAllocation({ value, id }));
      dispatch(updateAllocation({ value, id }));
    },
    [id]
  );

  const handleRemove = useCallback(async () => {
    try {
      await deleteAllocationApi({ allocation: 0, id });
    } catch {
      return;
    } finally {
      eventTracker("trackResourceAllocationDelete", {
        projectId: allocations[id].projectId,
      });
      dispatch(removeAllocation({ id }));
      noFilterDispatch(removeAllocation({ id }));
    }
  }, [id]);

  return {
    handleChange,
    handleRemove,
  };
}
