
const digest = 'd2909f0f88c3a10d9d9d3f989303fb9d54f030f6821f7ada18402469e0edb2f1';
const css = `._negative_1u5vo_1 {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_1u5vo_1"};
export { css, digest };
  