import axios from "axios";

// Setup default headers for requests
export function createHeaders() {
  const authToken = document.querySelector("meta[name=csrf-token]").content;
  const headers = {
    "X-CSRF-Token": authToken,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
  };
  return headers;
}

// General request structure
export const makeRequest = async (request) => {
  const headers = createHeaders();
  const response = await axios({
    headers,
    ...request,
  });
  if (!response.status === 200) {
    throw new Error(response);
  }
  return response;
};
