import React from "react";

export const Plus = () => {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="3" width="7" height="1" />
      <rect x="3" width="1" height="7" />
    </svg>
  );
};

export default Plus;
