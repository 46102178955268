import React from "react";
import styles from "./project.module.css";

function ActivityButton({ newRowHandler, groupId }) {
  return (
    <div
      className={styles.activityAddTarget}
      onClick={() => newRowHandler(groupId)}
    >
      <button className={styles.activityAddButton}>
        <div className={styles.activityAddButtonTextWrapper}>
          <span className={styles.activityAddButtonText}>Activity</span>
        </div>
      </button>
    </div>
  );
}

export default ActivityButton;
