import React, { useMemo } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import cx from "classnames";
import styles from "./percent.module.css";

const formatters = {
  default: "0.[00]%",
  noDecimal: "0%",
  twoDecimal: "0.00%",
};

// Takes a decimal and displays it as a percent
// .9 -> 90%

function formatPercent(value, format) {
  return numeral(value).format(formatters[format]);
}

export function Percent({ value, format }) {
  const isNegative = value < 0;
  const amount = useMemo(() => formatPercent(value, format), [format, value]);
  return (
    <span className={cx({ [styles.negative]: isNegative })}>{amount}</span>
  );
}

Percent.propTypes = {
  format: PropTypes.oneOf(["default", "noDecimal", "twoDecimal"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Percent.defaultProps = {
  format: "default",
  value: 0,
};

export default Percent;
