import { CSSProperties } from "react";
import * as React from "react";

import { createComponent, styled, theme } from "@plan/core";
import { getAccessibleTextColor } from "@plan/core/src/hooks/useAccessibleTextColor/getAccessibleTextColor";

const component = createComponent();

const StyledBadge = styled("div", {
  ...component,
  $$radius: "0.1875rem",
  $$minSize: "$space$3",
  fontWeight: "$bold",
  minHeight: "$$minSize",
  minWidth: "$$minSize",
  borderRadius: "$$radius",
  display: "inline-flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "max-content",
  color: "$white",
  backgroundColor: "$$colorScheme",
  transition: "background 0.2s $cb",
  variants: {
    ...component.variants,
    intent: {
      info: {
        backgroundColor: "$colors$-neutral30",
        color: "$black",
      },
      active: {
        backgroundColor: "$colors$success",
      },
      inactive: {
        backgroundColor: "$-warning10",
        color: "$black",
      },
      danger: {
        backgroundColor: "$colors$danger10",
      },
      highlight: {
        background: "linear-gradient(89deg, #7F41EA 21.72%, #B77E10 93.86%)",
        color: "$white",
      },
      announcement: {
        backgroundColor: "$brand",
        color: "$white",
      },
      primary: {
        backgroundColor: "$colors$neutral10",
        color: "$white",
      },
      warning: {
        backgroundColor: "$warning",
        color: "$black",
      },
    },
    size: {
      sm: {
        $$radius: "$space$0_5",
        fontSize: "$fontSizes$sm",
        padding: "1px 3px",
        $$minSize: "$space$4",
      },
      md: {
        fontSize: "$fontSizes$md",
        lineHeight: "1.1",
        padding: "$space$0_5 $space$1",
        $$minSize: "$sizes$sm",
      },
      lg: {
        fontSize: "$fontSizes$lg",
        padding: "$space$2 $space$2 0.4rem",
        $$minSize: "$sizes$md",
      },
    },
    outline: {
      true: {
        background: "transparent",
        border: "1px solid $$colorScheme",
        color: "$$colorScheme",
      },
    },
    disabled: {
      true: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
    },
  },
});

export type BadgeProps = React.ComponentProps<typeof StyledBadge> & {
  colorScheme?: keyof typeof theme.colors;
  projectColor?: string;
};

const Core = ({
  colorScheme = "projectColor",
  projectColor,
  size = "sm",
  css,
  ...props
}: BadgeProps) => {
  const textColor = React.useMemo(
    () => getAccessibleTextColor(projectColor || ""),
    [projectColor]
  );

  let colorSchemeCSS = {
    $$colorScheme: `$colors$${colorScheme}`,
  } as CSSProperties;

  if (projectColor) {
    colorSchemeCSS = {
      backgroundColor: projectColor,
      color: textColor,
    } as CSSProperties;
  }

  return (
    <StyledBadge size={size} css={{ ...css, ...colorSchemeCSS }} {...props} />
  );
};

export const Badge = ({ ...props }: BadgeProps) => {
  return <Core {...props} />;
};
