import React from "react";
import Currency from "Components/blocks/currency/Currency";
import styles from "./timesheet.module.css";

function RowTotals({ hours, expenses, showExpense }) {
  return (
    <div className={styles.rowTotals}>
      <div className={styles.rowTotalsHours}>
        <span className={styles.rowTotalsValue}>{hours}</span>
      </div>
      <div className={styles.rowTotalsExpenses}>
        {showExpense && (
          <>
            <span className={styles.rowTotalsLabel}>{currencySymbol}</span>
            <span className={styles.rowTotalsValue}>
              <Currency value={expenses} format="noSymbol" />
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default RowTotals;
