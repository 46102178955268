import React from "react";
import { ApolloProvider, gql, useQuery } from "@apollo/client";
import { Badge } from "@plan";
import { useApollo } from "@hooks/useApollo";
import classNames from "classnames/bind";
import styles from "./TeamCompensation.module.css";

const cx = classNames.bind(styles);

const WarningIndicator = ({ isBadge }) => {
  const GET_COMPENSATIONS = gql`
    query currentProfile {
      currentProfile {
        organization {
          profilesWithIncompleteOnboardingCount
          unprocessedQboCustomers
        }
      }
    }
  `;

  const { data } = useQuery(GET_COMPENSATIONS, {
    fetchPolicy: "network-only", // Attempted fix for BUG-39 - this will probably be unneeded when we launch new Team Overview page though
  });

  const profilesWithIncompleteOnboardingCount =
    data?.currentProfile?.organization?.profilesWithIncompleteOnboardingCount ||
    0;

  const unprocessedQboCustomers =
    data?.currentProfile?.organization?.unprocessedQboCustomers || 0;

  if (
    profilesWithIncompleteOnboardingCount === 0 &&
    unprocessedQboCustomers === 0
  )
    return null;

  return isBadge ? (
    <Badge
      size="small"
      style={{ marginLeft: "0.5rem" }}
      color="var(--color-brand)"
    >
      {profilesWithIncompleteOnboardingCount + unprocessedQboCustomers}
    </Badge>
  ) : (
    <span className={cx("dot-highlight")} />
  );
};

export const TeamCompensationWarning = ({ isBadge }) => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <WarningIndicator isBadge={isBadge} />
    </ApolloProvider>
  );
};

export default React.memo(TeamCompensationWarning);
