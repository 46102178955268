import React, { useState } from "react";

import { Toggle } from "Components/blocks";
import Modal from "Components/blocks/modal/Modal";
import eventTracker from "Blocks/tracker";
import { TrackCard, GrowCard } from "./ModalPlanCards";
import styles from "./upgradePage.module.css";

const UpgradeModal = ({
  isOpen,
  modalActions,
  path,
  prepaidSeatCount,
  size,
}) => {
  const [showAnnual, setShowAnnual] = useState(true);
  const modalTitle = "Upgrade your plan";

  const onSubmit = async () => {
    eventTracker("trackPlanModalClick", {
      upgradeModalTitle: modalTitle,
      pathString: path,
      columnTitle: "Grow",
      buttonText: "Upgrade to Grow",
      planIntervalSetting: showAnnual ? "annual" : "monthly",
    });
    eventTracker("trackModalGrowUpgradeClick", { pathString: path });
    fetch("/stripe/billing.json", { method: "POST" })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = json.portal_url;
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="medium"
      actions={modalActions}
      title={modalTitle}
      subHeading="Get more insights to grow with confidence. Forecast resources and reduce budgeting risks with our Grow plan."
    >
      <Toggle
        label="Pay annually and save"
        value={showAnnual}
        onChange={() => setShowAnnual(!showAnnual)}
      />
      <div className={styles.cardContainer}>
        <TrackCard
          showAnnual={showAnnual}
          prepaidSeatCount={prepaidSeatCount}
          size={size}
        />
        <GrowCard
          showAnnual={showAnnual}
          prepaidSeatCount={prepaidSeatCount}
          onSubmit={onSubmit}
          size={size}
        />
      </div>
    </Modal>
  );
};

export default UpgradeModal;
