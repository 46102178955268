import React from "react";
import { motion } from "framer-motion";

export const Lock = ({ locked, classes }) => {
  return (
    <motion.svg
      width="8"
      height="11"
      className={classes}
      viewBox="0 0 8 11"
      fill="none"
      style={{ perspective: "1000px", overflow: "visible" }}
      animate={locked ? "locked" : "unlocked"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H0V11H8V4ZM7 5H1V10H7V5Z"
        fill="#A0A8AE"
      />
      <motion.path
        initial={{
          rotateY: "0deg",
        }}
        style={{
          transformOrigin: "center right",
          transition: { duration: 0.1 },
        }}
        variants={{
          locked: {
            y: [-1, -1, 0],
            x: ["50%", "0%", "0%"],
            rotateY: "0deg",
          },
          unlocked: {
            y: [0, -1, -1],
            x: ["0%", "0%", "50%"],
            rotateY: ["0deg", "0deg", "180deg"],
          },
        }}
        d="M1 4C1 4 1 0 4 0C7 0 7 4 7 4V5H6V4C6 4 6 1 4 1C2 1 2 4 2 4H1Z"
        fill="#A0A8AE"
      />
      <path
        d="M4.6 6.88889C4.6 7.08633 4.52275 7.26373 4.4 7.3858V8.44445C4.4 8.68991 4.22091 8.88889 4 8.88889C3.77909 8.88889 3.6 8.68991 3.6 8.44445V7.3858C3.47725 7.26373 3.4 7.08633 3.4 6.88889C3.4 6.5207 3.66863 6.22222 4 6.22222C4.33137 6.22222 4.6 6.5207 4.6 6.88889Z"
        fill="#A0A8AE"
      />
    </motion.svg>
  );
};
export default React.memo(Lock);
