import { styled } from "@plan/core";
import { Flex } from "@plan/layout";
import { Button, Modal, ModalContent, Text } from "@plan/components";

import { useTimerContext } from "../contexts/TimerContext";
import { millisecondsToFixedHours } from "../helpers/timeHelpers";

// If the user left the site while recording, then comes back,
// and their time away was longer than this threshold (5 minutes),
// we'll show a modal asking them what to do with that lost time
const LOST_TIME_THRESHOLD_MS = 300000;

const LostTimeCopy = styled(Text, {
  color: "$colors$neutral10",
  fontSize: "$fontSizeslg!important",
});

const HiddenCloseButtonModal = styled(ModalContent, {
  "& > button": {
    display: "none",
  },
});

export const TimerLostTimeWarning = () => {
  const {
    state: { lostTimeTotal, entry },
    actions: { acceptLostTime, rejectLostTime },
  } = useTimerContext();

  const isLostTimeWarningVisible = !!(
    lostTimeTotal && lostTimeTotal > LOST_TIME_THRESHOLD_MS
  );

  const hourTotal = millisecondsToFixedHours(lostTimeTotal as number);
  const projectName = entry?.project?.name as string;
  const phaseName = entry?.phase?.name as string;

  return (
    <Modal open={isLostTimeWarningVisible && !!entry}>
      <HiddenCloseButtonModal size="sm" title="Save recorded time">
        <Flex css={{ flexDirection: "column", gap: "$space$4" }}>
          <LostTimeCopy>
            While you were away, the timer continued to record your time for the{" "}
            {phaseName} phase on the {projectName} project.
          </LostTimeCopy>
          <LostTimeCopy>
            Do you want to save the {hourTotal} hours recorded while you were
            away?
          </LostTimeCopy>
          <Flex css={{ justifyContent: "flex-end", gap: "$space$2" }}>
            <Button intent="secondary" onClick={rejectLostTime}>
              No thanks
            </Button>
            <Button intent="primary" onClick={acceptLostTime}>
              Yes, save time
            </Button>
          </Flex>
        </Flex>
      </HiddenCloseButtonModal>
    </Modal>
  );
};
