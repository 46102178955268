import { ReactNode } from "react";

import { IconPlayCircle } from "@plan/icons";
import { AutoLayout, Box } from "@plan/layout";

import { Card, CardProps } from "../Card";

import { capitalize, isEmpty, join } from "lodash";

const DefaultIcon = () => (
  <IconPlayCircle color="success" label="play circle icon" />
);

type ActiveFilterProps = {
  filterName: string;
  activeFilters: string[];
};

const ActiveFilter = ({ filterName, activeFilters }: ActiveFilterProps) => {
  const activeFilterList = join(
    activeFilters.map((filter) => capitalize(filter)),
    ", "
  );

  return (
    <>
      {activeFilterList && (
        <AutoLayout as="span" spacing="2">
          <Box as="span">{filterName}:</Box>
          <Box as="span" css={{ fontWeight: "bold" }}>
            {activeFilterList}
          </Box>
        </AutoLayout>
      )}
    </>
  );
};

export interface ActiveFiltersBarProps extends CardProps {
  filters: ActiveFilterProps[];
  icon?: ReactNode;
}

export const ActiveFiltersBar = ({
  filters,
  icon = <DefaultIcon />,
  ...props
}: ActiveFiltersBarProps) => (
  <Card paddingX="4" paddingY="2" {...props}>
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      alignment="left"
      spacing="6"
    >
      <Box as="span">Active Filters</Box>
      <AutoLayout as="span" spacing="2" alignment="left">
        {icon}
        {isEmpty(filters) ? (
          <Box as="span">None</Box>
        ) : (
          <AutoLayout as="span" spacing="8">
            {filters?.map((filter, i) => (
              <ActiveFilter
                key={i}
                filterName={filter.filterName}
                activeFilters={filter.activeFilters}
              />
            ))}
          </AutoLayout>
        )}
      </AutoLayout>
    </AutoLayout>
  </Card>
);
