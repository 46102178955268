import { FC, ReactNode } from "react";

import { styled } from "@plan/core";

import { Skeleton } from "./Skeleton";

type Props = {
  children: ReactNode;
  stretched?: boolean;
  loading?: boolean;
  className?: string;
};

const Wrapper = styled("div", {
  position: "relative",

  variants: {
    stretched: {
      true: {
        flex: "1 0 auto",
      },
    },
  },
});

export const SkeletonWrapper: FC<Props> = ({
  children,
  loading,
  stretched,
  className,
}) => (
  <Wrapper stretched={stretched} className={className}>
    {loading && (
      <Skeleton
        data-testid="sw-skeleton"
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />
    )}
    {children}
  </Wrapper>
);
