import { createComponent, styled } from "@plan/core";
const component = createComponent();

import * as TabsPrimitive from "@radix-ui/react-tabs";

export const Tabs = styled(TabsPrimitive.Root, {
  ...component,
  display: "flex",
  flexDirection: "column",
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "fill-container",
  },
});

export const TabsList = styled(TabsPrimitive.List, {
  display: "flex",
});

export const TabsContent = styled(TabsPrimitive.Content, {
  padding: "$space$4 $space$5",
  outline: "none",
});

export const TabsTrigger = styled(TabsPrimitive.Trigger, {
  $$tabsTriggerBackground: "$colors$-neutral50",
  $$tabsTriggerBorderWidth: "$borderWidths$1",
  $$tabsTriggerBorderColor: "$colors$-neutral20",
  appearance: "none",
  flex: 1,
  border: 0,
  textAlign: "left",
  padding: "$space$4 $space$10 $space$4 $space$8",
  position: "relative",
  '&[data-state="active"]': {
    $$tabsTriggerBackground: "$colors$white",
  },
  "&:focus": {
    zIndex: "$mask",
  },
  "&:focus-visible": {
    outline: "none",
  },
  '&:hover:not([data-state="active"])': {
    $$tabsTriggerBackground: "$colors$-neutral40",
  },
  cursor: "pointer",
  variants: {
    style: {
      minimal: {
        fontWeight: "$semibold",
        padding: "unset",
        paddingY: "$space$2",
        width: "fit-content",
        flex: "unset",
        backgroundColor: "transparent",
        border: "none",
        color: "$colors$neutral10",
        '&[data-state="active"]': {
          boxShadow: "0px -3px 0px 0px #011627 inset",
          color: "unset",
        },
      },
      simple: {
        backgroundColor: "$$tabsTriggerBackground",
        border: "$$tabsTriggerBorderWidth solid $$tabsTriggerBorderColor",
        "&:not(:first-child)": {
          borderLeft: "none",
        },
        "&:focus": {
          boxShadow: "$shadows$focus",
        },
      },
      progression: {
        clipPath: "polygon(98% 0%, 100% 50%, 98% 100%, 0% 100%, 2% 50%, 0% 0%)",
        backgroundColor: "$$tabsTriggerBorderColor",
        "&:not(:first-child)": {
          marginLeft: "-0.7%",
        },
        "&:focus-visible": {
          backgroundColor: "$colors$-brand40",
          "&::before": {
            margin: "0.15rem",
          },
        },
        "&::before": {
          content: "",
          position: "absolute",
          inset: "0",
          margin: "$$tabsTriggerBorderWidth",
          clipPath:
            "polygon(98% 0%, 100% 50%, 98% 100%, 0% 100%, 2% 50%, 0% 0%)",
          backgroundColor: "$$tabsTriggerBackground",
          zIndex: "$basement",
        },
        "&:first-child, &:first-child:before": {
          clipPath: "polygon(0% 0%, 98% 0%, 100% 50%, 98% 100%, 0% 100%)",
        },
        "&:last-child, &:last-child:before": {
          clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%, 2% 49%);",
        },
      },
    },
  },
  defaultVariants: {
    style: "progression",
  },
});
