import React from "react";
import moment from "moment";
import cx from "classnames";
import PropTypes, { oneOf } from "prop-types";
import styles from "../chart.module.css";

// Props:
// value - date value, e.x "2020-01-01"
// x - x position of the label
// y - y position of the label
// dateIncrement - date increment for chart, can be "hour", "day", "month", "quarter"
// align - Text align for the label. Default is "start", pass in "middle" or "end"

function useDateInfo(value) {
  const date = moment(value);
  const dayOfWeek = date.format("dd");
  const dayNumber = date.format("D");
  const month = date.format("MMM");
  const quarter = moment(value).utc().quarter();
  const year = date.format("YYYY");

  const isFirstOfMonth = moment(value).startOf("month").isSame(value);

  const isFirstOfQuarter = moment(value).startOf("quarter").isSame(value);

  const isFirstOfYear = moment(value).startOf("year").isSame(value);

  return {
    date,
    dayOfWeek: dayOfWeek[0],
    dayNumber,
    month,
    quarter,
    year,
    isFirstOfMonth,
    isFirstOfYear,
    isFirstOfQuarter,
  };
}

const HourLabel = ({ x, y, hour, align }) => (
  <>
    <text x={x} y={y}>
      <tspan className={cx(styles.chartLabel, { [styles[align]]: align })}>
        {hour} h
      </tspan>
    </text>
  </>
);

const DayLabel = ({
  x,
  y,
  dayOfWeek,
  isFirstOfMonth,
  month,
  dayNumber,
  align,
}) => (
  <>
    <text
      x={x}
      y={y}
      className={cx(styles.chartLabel, { [styles[align]]: align })}
    >
      {dayOfWeek}
      <tspan style={{ fill: "var(--color-neutral-10" }}> {dayNumber}</tspan>
    </text>
    {isFirstOfMonth && (
      <text
        x={x}
        y={y + 20}
        className={cx(styles.chartLabel, styles.monthLabel, {
          [styles[align]]: align,
        })}
      >
        <tspan>{month}</tspan>
      </text>
    )}
  </>
);

const MonthLabel = ({ x, y, month, year, isFirstOfMonth, align }) => (
  <>
    <text
      x={x}
      y={y}
      className={cx(styles.chartLabel, styles.monthLabel, {
        [styles[align]]: align,
      })}
    >
      <tspan>
        {`${month} `}
        {!isFirstOfMonth && <tspan className={styles.partial}>(part)</tspan>}
      </tspan>
    </text>
    {month === "Jan" && (
      <text
        x={x}
        y={y + 20}
        className={cx(styles.chartLabel, { [styles[align]]: align })}
      >
        <tspan>{year}</tspan>
      </text>
    )}
  </>
);

const QuarterLabel = ({ x, y, quarter, year, isFirstOfQuarter, align }) => (
  <>
    <text
      x={x}
      y={y}
      className={cx(styles.chartLabel, { [styles[align]]: align })}
    >
      <tspan>
        Q{quarter}{" "}
        {!isFirstOfQuarter && <tspan className={styles.partial}>(part)</tspan>}
      </tspan>
    </text>
    {quarter === 1 && (
      <text
        x={x}
        y={y + 20}
        className={cx(styles.chartLabel, { [styles[align]]: align })}
      >
        <tspan>{year}</tspan>
      </text>
    )}
  </>
);

export const DateLabel = ({ value, x, y, dateIncrement, align }) => {
  const {
    date,
    dayOfWeek,
    dayNumber,
    month,
    quarter,
    year,
    isFirstOfMonth,
    isFirstOfQuarter,
  } = useDateInfo(value);

  return (
    <g style={{ cursor: "pointer" }}>
      {dateIncrement === "hour" && (
        <HourLabel x={x} y={y} align={align} hour={value} />
      )}
      {(dateIncrement === "day" || !dateIncrement) && (
        <DayLabel
          dayOfWeek={dayOfWeek}
          isFirstOfMonth={isFirstOfMonth}
          month={month}
          dayNumber={dayNumber}
          x={x}
          y={y}
          align={align}
        />
      )}
      {dateIncrement === "month" && (
        <MonthLabel
          year={year}
          month={month}
          isFirstOfMonth={isFirstOfMonth}
          x={x}
          y={y}
          align={align}
        />
      )}
      {dateIncrement === "quarter" && (
        <QuarterLabel
          quarter={quarter}
          year={year}
          isFirstOfQuarter={isFirstOfQuarter}
          x={x}
          y={y}
          align={align}
        />
      )}
    </g>
  );
};

DateLabel.propTypes = {
  value: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  dateIncrement: oneOf(["hour", "day", "month", "quarter"]),
  align: PropTypes.oneOf(["middle", "end"]),
};

export default React.memo(DateLabel);
