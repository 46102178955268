import React from "react";
import ajax from "Blocks/utils/ajax";
import { useApollo } from "@hooks/useApollo";
import { ApolloProvider } from "@apollo/client";
import ProjectNumber from "./ProjectNumber";
import ProjectGroup from "./ProjectGroup";
import DeleteButton from "../DeleteButton";
import { useGlobalState, deleteBlock } from "../state/GlobalContext";
import { hexToRgb } from "../util";
import styles from "./project.module.css";

function ProjectBlock({
  canEdit,
  block,
  projectId,
  startOfWeek,
  activityOptions,
  zIndex,
  activityRequired,
  noteExample,
  notesRequired,
  permissions,
  currentProfile,
  timesheetIsLocked,
}) {
  const { state, dispatch } = useGlobalState();
  const apolloClient = useApollo();

  const timesheetId = state.timesheet.id;
  const groupIds = state.selectedProjects.byId[projectId].entryGroups;
  const orderedGroupIds = state.orderedGroupsByProjectId[projectId];

  if (
    !groupIds.some((id) => state.projectGroups.byId[id].manuallyAdded) &&
    Number(block.totalAllocations) === 0 &&
    !block.hasTime &&
    !block.hasExpenses
  )
    return null;

  const deleteBlockHandler = () => {
    const path = "/entry_groups/delete_entry_groups";
    const data = {
      timesheet_id: timesheetId,
      entry_group_ids: groupIds,
    };

    ajax({
      method: "DELETE",
      path,
      data,
      success: (response) => {
        dispatch(deleteBlock({ projectId }));
      },
    });
  };

  return (
    <ApolloProvider client={apolloClient}>
      <div
        className={styles.block}
        style={{
          "--project-color": block.color,
          "--project-color-rgb": `${hexToRgb(block.color)}, 0.25`,
          zIndex,
        }}
        id={projectId}
        ref={state.references[projectId]}
      >
        <div className={styles.blockHeader}>
          <ProjectNumber number={block.number} color={block.color} />
          <a className={styles.blockTitle} href={`/projects/${block.slug}`}>
            {block.name}
          </a>
          <div className={styles.blockDeleteButton}>
            {canEdit && (
              <DeleteButton
                type="project"
                name={block.name}
                deleteHandler={deleteBlockHandler}
                timesheetId={timesheetId}
                projectId={projectId}
              />
            )}
          </div>
        </div>
        <div className={styles.groups}>
          {orderedGroupIds.map((key, index) => {
            const zIndex = orderedGroupIds.length - index;
            return (
              <div style={{ zIndex, position: "relative" }} key={key}>
                <ProjectGroup
                  startOfWeek={startOfWeek}
                  blockName={block.name}
                  groupId={key}
                  activityOptions={activityOptions}
                  projectId={projectId}
                  color={block.color}
                  canEdit={canEdit}
                  activityRequired={activityRequired}
                  noteExample={noteExample}
                  notesRequired={notesRequired}
                  permissions={permissions}
                  currentProfile={currentProfile}
                  timesheetIsLocked={timesheetIsLocked}
                />
              </div>
            );
          })}
        </div>
      </div>
    </ApolloProvider>
  );
}

export default ProjectBlock;
