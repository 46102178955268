import ajax from "Blocks/utils/ajax";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

class ProjectsInvoices extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-toggle-paid", (event) => {
      this.toggleInvoiceIsPaid(event);
    });
  }

  load() {}

  beforeCache() {}

  toggleInvoiceIsPaid(event) {
    console.log("markInvoiceAsPaid");
    const button = event.target;
    const row = button.closest(".js-invoice");
    const { invoiceSlug } = row.dataset;
    const invoiceIsPaid = button.dataset.isPaid === "true";

    const data = {
      invoice: { is_paid: !invoiceIsPaid },
    };

    ajax({
      method: "PUT",
      path: `/invoices/${invoiceSlug}`,
      data,
      success: (response) => {
        location.reload(true);
      },
    });
  }
}

const projectsInvoices = new ProjectsInvoices();
export default projectsInvoices;
