import React from "react";
import cx from "classnames";
import { useFieldState } from "../FieldContext";
import styles from "./formats.module.css";

export const Format = React.memo(({ children, format }) => {
  const { size, negative } = useFieldState();
  return (
    <div
      className={cx(
        { [styles.isOverBudget]: negative },
        { [styles[`format-${format}`]]: format },
        { [styles[size]]: size }
      )}
    >
      {children}
    </div>
  );
});

export function Formatter({ children }) {
  const { format, currencySymbol, size } = useFieldState();
  switch (format) {
    case "time":
      return <>{children}</>;
    case "currency":
      return (
        <>
          <Format format={format}>{currencySymbol}</Format>
          {children}
        </>
      );
    case "percent":
      return (
        <>
          {children}
          <Format format={format}>%</Format>
        </>
      );
    case "perHour":
      return (
        <>
          <Format format="currency">{currencySymbol}</Format>
          {children}
          <Format format={format}>
            <span className={`${styles.perHourSeparater} ${styles[size]}`}>
              /
            </span>
            HR
          </Format>
        </>
      );
    case "hours":
      return (
        <>
          {children}
          <Format format={format}>HRS</Format>
        </>
      );

    default:
      return <>{children}</>;
      break;
  }
}

export default React.memo(Formatter);
