import React from "react";
import { useFieldState } from "./FieldContext";
import styles from "./field.module.css";

export function Label({ children }) {
  const { label } = useFieldState();
  if (!label && !children) return null;
  return (
    <label className={styles.field__label}>
      {label}
      {children}
    </label>
  );
}

export default React.memo(Label);
