import React from "react";
import EmptyState from "Components/blocks/EmptyState";
import CreateProject from "./create/CreateProject";
import ProjectSample from "./ProjectSample";
import styles from "./projects.module.css";

const ProjectsEmptyState = ({ canCreateProjects, colors, categories }) => (
  <>
    <EmptyState
      title="Welcome to Monograph!"
      text={`Looks a little empty around here, let's create your first project.`}
    >
      {canCreateProjects && (
        <div className={styles.emptyStateButtons}>
          <CreateProject newAccount colors={colors} categories={categories} />
          <div className={styles.emptyStateSample}>
            <ProjectSample />
          </div>
        </div>
      )}
    </EmptyState>
  </>
);

export default ProjectsEmptyState;
