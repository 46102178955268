import { FC } from "react";

import { styled } from "@plan/core";
import { Box } from "@plan/layout";

import { useTimerContext } from "../../../contexts/TimerContext";
import { useTimerModalViewContext } from "../../../contexts/TimerModalViewContext";

import { CreateEntryForm } from "./CreateEntryForm";
import { UpdateEntryForm } from "./UpdateEntryForm";

const TimerEntryFormContainer = styled(Box, {
  background: "$white",
  borderBottomRightRadius: "3px",
  borderBottomLeftRadius: "3px",
});

export const TimerEntryForm: FC = () => {
  const {
    state: { entry },
  } = useTimerContext();

  const { timerModalView } = useTimerModalViewContext();
  if (timerModalView !== "form") return <></>;

  return (
    <TimerEntryFormContainer>
      {entry && <UpdateEntryForm entry={entry} />}
      {!entry && <CreateEntryForm />}
    </TimerEntryFormContainer>
  );
};
