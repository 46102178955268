import React from "react";
import styles from "./buttons.module.css";

export function TableButton({ text, action }) {
  return (
    <button
      className={`${styles.button} ${styles[`button--table`]}`}
      onClick={action}
    >
      {text}
    </button>
  );
}

export default TableButton;
