import React, { useMemo, useContext } from "react";
import { useApi } from "Hooks";

export const PermissionsContext = React.createContext({});

export function PermissionsProvider({ children }) {
  const [data] = useApi("/policies/current_policy.json");

  const name = useMemo(() => data?.name ?? "", [data]);
  const isAdmin = useMemo(() => data?.isAdmin ?? false, [data]);
  const permissions = useMemo(() => data?.permissions ?? {}, [data]);

  return (
    <PermissionsContext.Provider value={{ permissions, name, isAdmin }}>
      {children}
    </PermissionsContext.Provider>
  );
}

export const usePermissions = () => useContext(PermissionsContext);
