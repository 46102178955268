import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";
import { motion } from "framer-motion";
import EmptyState from "../EmptyState";
import styles from "./tables.module.css";

export const SortArrows = ({ isSorted, desc }) => (
  <span className="sort__arrows">
    <span
      className={`sort__arrow sort__arrow--top ${
        isSorted && !desc ? "isActive" : ""
      }`}
    />
    <span
      className={`sort__arrow sort__arrow--bottom ${
        isSorted && desc ? "isActive" : ""
      }`}
    />
  </span>
);

SortArrows.propTypes = {
  isSorted: PropTypes.bool,
  desc: PropTypes.bool,
};

SortArrows.defaultProps = {
  isSorted: false,
  desc: false,
};

export const DataTable = ({
  data,
  columns,
  emptyState,
  hideFooter,
  loading,
  forwardRef,
  sticky,
  size,
  variant,
  highlightTrigger,
  initialState,
  rowProps = () => ({}),
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const tableClasses = sticky ? "page-table page-table--sticky" : "page-table";

  return (
    <>
      <motion.table
        {...getTableProps()}
        ref={forwardRef}
        className={cx(tableClasses, { [styles[variant]]: variant })}
        animate={loading}
        style={{
          minWidth: "100%",
        }}
        {...props}
      >
        <thead className="page-table__header">
          {headerGroups.map((headerGroup) => (
            <tr
              className={cx("page-table__row", "page-table__row--header", {
                [styles[size]]: size,
              })}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => {
                const headerProps = {
                  ...column.getHeaderProps([
                    { className: column.headerClass },
                    column.getSortByToggleProps(),
                  ]),
                  style: {
                    minWidth: column.width,
                    maxWidth: column.width,
                  },
                };

                return (
                  // Add the sorting props to control sorting.
                  // we can add them into the header props
                  <th
                    className="page-table__title"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...headerProps}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {
                      <SortArrows
                        isSorted={column.isSorted}
                        desc={column.isSortedDesc}
                      />
                    }
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <motion.tbody
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getTableBodyProps()}
          variants={{
            reloading: { opacity: 0.5 },
            loaded: { opacity: 1 },
          }}
          className="page-table__content"
        >
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                className={cx("page-table__row", { [styles[size]]: size })}
                {...row.getRowProps(rowProps(row))}
              >
                {row.cells.map((cell) => {
                  const columnProps = {
                    ...cell.getCellProps([{ className: cell.column.classes }]),
                    style: {
                      minWidth: cell.column.width,
                      maxWidth: cell.column.width,
                    },
                  };

                  return (
                    <td
                      className={cx("page-table__cell", {
                        [styles.highlighted]: row.original[highlightTrigger],
                      })}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...columnProps}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </motion.tbody>
        {!hideFooter && data.length !== 0 && (
          <tfoot className="page-table__content">
            {footerGroups.map((group) => (
              <tr
                {...group.getFooterGroupProps()}
                className="page-table__row page-table__row--footer"
              >
                {group.headers.map((column) => (
                  <td
                    className="page-table__title-footer"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...column.getFooterProps([
                      {
                        className: column.footerClass,
                      },
                    ])}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </motion.table>
      {data.length === 0 && <EmptyState title={emptyState} />}
    </>
  );
};

DataTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.elementType,
      ]).isRequired,
      hideSort: PropTypes.bool,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      width: PropTypes.number,
    })
  ),
  emptyState: PropTypes.string,
  variant: PropTypes.string,
  hideFooter: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  highlightTrigger: PropTypes.string,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  emptyState: "No Data",
  hideFooter: false,
  size: "medium",
  loading: false,
  forwardRef: null,
};

export default DataTable;
