import React from "react";
import { Modal, ActionButtons } from "@plan";

const InvoiceWarningModal = ({ isOpen, actions, setValue }) => {
  const handleConfirm = () => {
    setValue(true);
    actions.close();
  };

  return (
    <Modal
      title="Invoice Inconsistency"
      isOpen={isOpen}
      actions={actions}
      size="medium"
    >
      <p>
        There are invoices referencing this consultant that include “Planned
        Budget” and “Percentage Complete”. If you update the planned budget for
        this consultant, then those past invoices may be considered
        inconsistent.
      </p>
      <p>Are you sure you want to proceed?</p>
      <ActionButtons
        buttons={[
          { buttonStyle: "cancel", text: "Cancel", action: actions.close },
          {
            buttonStyle: "action",
            buttonType: "submit",
            text: "Yes",
            action: handleConfirm,
          },
        ]}
      />
    </Modal>
  );
};

export default InvoiceWarningModal;
