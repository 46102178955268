import * as React from "react";

import { createLayout, createScaleVariant, styled } from "@plan/core";

const layout = createLayout();

const spacing = createScaleVariant({
  scale: "space",
  extend: {
    0: 0,
    inherit: "inherit",
  },
  css: (token) => ({
    "> *": {
      $$spacing: token,
    },
  }),
});

export type AutoLayoutProps = React.ComponentProps<typeof AutoLayout>;

export const AutoLayout = styled("div", {
  ...layout,
  display: "flex",
  listStyleType: "none",
  "> *": {
    flex: "none",
  },
  variants: {
    ...layout.variants,
    spacing,
    alignment: {
      // All directions with `packed`
      "top-left": {},
      "top-center": {},
      "top-right": {},
      "bottom-left": {},
      "bottom-center": {},
      "bottom-right": {},
      // All directions with `packed` **or** `vertical` with `space-between`
      left: {},
      center: {},
      right: {},
      // `horizontal` with `space-between`
      top: {},
      bottom: {},
    },
    direction: {
      horizontal: {
        flexDirection: "row",
        "> * + *": {
          $$spacingHorizontal: "$$spacing",
          $$spacingVertical: "0",
        },
      },
      vertical: {
        flexDirection: "column",
        "> * + *": {
          $$spacingHorizontal: "0",
          $$spacingVertical: "$$spacing",
        },
      },
    },
    distribution: {
      packed: {
        "> * + *": {
          marginLeft: "$$spacingHorizontal",
          marginTop: "$$spacingVertical",
        },
      },
      "space-between": {
        justifyContent: "space-between",
      },
    },
  },
  compoundVariants: [
    ...layout.compoundVariants,
    /* Horizontal: Packed
      ---------------------------------- */
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "top-left",
      css: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "top-center",
      css: {
        alignItems: "flex-start",
        justifyContent: "center",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "top-right",
      css: {
        alignItems: "flex-start",
        justifyContent: "flex-end",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "left",
      css: {
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "center",
      css: {
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "right",
      css: {
        alignItems: "center",
        justifyContent: "flex-end",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "bottom-left",
      css: {
        alignItems: "flex-end",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "bottom-center",
      css: {
        alignItems: "flex-end",
        justifyContent: "center",
      },
    },
    {
      direction: "horizontal",
      distribution: "packed",
      alignment: "bottom-right",
      css: {
        alignItems: "flex-end",
        justifyContent: "flex-end",
      },
    },
    /* Horizontal: Space-Between
      ---------------------------------- */
    {
      direction: "horizontal",
      distribution: "space-between",
      alignment: "top",
      css: {
        alignItems: "flex-start",
      },
    },
    {
      direction: "horizontal",
      distribution: "space-between",
      alignment: "center",
      css: {
        alignItems: "center",
      },
    },
    {
      direction: "horizontal",
      distribution: "space-between",
      alignment: "bottom",
      css: {
        alignItems: "flex-end",
      },
    },
    /* Vertical: Packed
      ---------------------------------- */
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "top-left",
      css: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "top-center",
      css: {
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "top-right",
      css: {
        alignItems: "flex-end",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "left",
      css: {
        alignItems: "flex-start",
        justifyContent: "center",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "center",
      css: {
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "right",
      css: {
        alignItems: "flex-end",
        justifyContent: "center",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "bottom-left",
      css: {
        alignItems: "flex-start",
        justifyContent: "flex-end",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "bottom-center",
      css: {
        alignItems: "center",
        justifyContent: "flex-end",
      },
    },
    {
      direction: "vertical",
      distribution: "packed",
      alignment: "bottom-right",
      css: {
        alignItems: "flex-end",
        justifyContent: "flex-end",
      },
    },
    /* Vertical: Space-Between
      ---------------------------------- */
    {
      direction: "vertical",
      distribution: "space-between",
      alignment: "left",
      css: {
        alignItems: "flex-start",
      },
    },
    {
      direction: "vertical",
      distribution: "space-between",
      alignment: "center",
      css: {
        alignItems: "center",
      },
    },
    {
      direction: "vertical",
      distribution: "space-between",
      alignment: "right",
      css: {
        alignItems: "flex-end",
      },
    },
  ],
  defaultVariants: {
    ...layout.defaultVariants,
    alignment: "top-left",
    direction: "horizontal",
    distribution: "packed",
    spacing: "inherit",
  },
});
