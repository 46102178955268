import React, { useState } from "react";
import styles from "./project.module.css";

function ProjectNumber({ number, color }) {
  return (
    <div className={styles.blockNumber} style={{ backgroundColor: color }}>
      {number}
    </div>
  );
}

export default ProjectNumber;
