import * as React from "react";
import { CSSProperties } from "react";

import { createComponent, keyframes, styled, theme } from "@plan/core";

const component = createComponent();

export const growWidth = keyframes({
  "0%": { width: "0" },
  "100%": { width: "100%" },
});

const StyledProgressBar = styled("div", {
  ...component,
  borderRadius: "100px",
  height: "10px",
});

const StyledProgressFill = styled("div", {
  height: "100%",
  borderRadius: "100px",
  width: "0",
});

const StyledProgressAnimatedFill = styled("div", {
  height: "100%",
  borderRadius: "100px",
  width: "0",
  animation: `${growWidth} 250ms ease-out forwards`,
});

export type ProgressBarProps = React.ComponentProps<
  typeof StyledProgressBar
> & {
  color?: keyof typeof theme.colors;
  secondaryColor?: keyof typeof theme.colors;
  projectColor?: string;
  percent?: number;
};

const getWidth = (percent: number) => {
  if (percent < 0) return 0;
  if (percent > 100) return 100;
  return percent;
};

export const ProgressBar = ({
  color = "projectColor",
  secondaryColor = "-neutral40",
  projectColor,
  percent = 0,
  ...props
}: ProgressBarProps) => {
  const css = {
    backgroundColor: projectColor || `$colors$${color}`,
    width: `${getWidth(percent)}%`,
  } as CSSProperties;

  return (
    <StyledProgressBar
      {...props}
      css={{
        backgroundColor: `$colors$${secondaryColor}`,
        ...(props.css || {}),
      }}
    >
      <StyledProgressFill css={{ width: css.width }}>
        <StyledProgressAnimatedFill
          css={{ backgroundColor: css.backgroundColor }}
        />
      </StyledProgressFill>
    </StyledProgressBar>
  );
};
