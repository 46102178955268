import React from "react";

// This is deprecated, use the 'table/Table' components instead
export default class Table extends React.Component {
  constructor(props) {
    super(props);

    this.renderCell = this.renderCell.bind(this);
  }

  renderCell(column, row) {
    if (column.render) {
      return column.render(row);
    }
    if (column.keyName) {
      return row[column.keyName];
    }
    return row[column.name.toLowerCase()];
  }

  render() {
    return (
      <table className="page-table">
        <thead className="page-table__header">
          <tr className="page-table__row page-table__row--header">
            {this.props.columns.map((column) => {
              return (
                <th key={column.name} className="page-table__title">
                  {column.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="page-table__content">
          {this.props.rows.map((row) => {
            return (
              <tr key={`${row.id}-${row.date}`} className="page-table__row">
                {this.props.columns.map((column) => {
                  return (
                    <td
                      key={`${row.id}-${row.date}-${column.name}`}
                      className="page-table__cell"
                    >
                      {this.renderCell(column, row)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
