import React from "react";

export default function StatusCanceled(classes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <circle cx="16" cy="16" r="16" fill="#F7F9FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16ZM20 16C20 18.2091 18.2091 20 16 20C15.3858 20 14.8039 19.8616 14.2839 19.6142L19.6142 14.2839C19.8616 14.8039 20 15.3858 20 16ZM12.3858 17.7161L17.7161 12.3858C17.1961 12.1384 16.6142 12 16 12C13.7909 12 12 13.7909 12 16C12 16.6142 12.1384 17.1961 12.3858 17.7161Z"
        fill="#DFE2E4"
      />
    </svg>
  );
}
