import React, { useState } from "react";
import Field from "Components/blocks/forms/Field";
import ajax from "Blocks/utils/ajax";
import { createAllocation as createAllocationApi } from "Components/api/entryGroups";
import { useAction } from "Hooks";
import {
  useNoFilterData,
  useDispatch,
  createAllocation as createAlloc,
} from "../../../state/Store";
import GroupLabel from "./GroupLabel";
import Option from "./Option";

const ProjectSearch = ({
  requestData,
  handleChange,
  filterResults,
  placeholder,
  date,
  size,
  setSearch,
  ...props
}) => {
  const [input, setInput] = useState(null);
  const dispatch = useDispatch();
  const { noFilterDispatch } = useNoFilterData();
  const [state, createAllocation] = useAction(createAllocationApi, {
    renderErrors: true,
  });
  // check if phase/role combo already has an allocation

  const loadOptions = (value, callback) => {
    ajax({
      method: "GET",
      path: "/projects/search",
      data: { query: value, ...requestData },
      success: (result) => {
        const projects = JSON.parse(result);
        if (filterResults) return callback(filterResults(projects));
        return callback(projects);
      },
    });
  };

  const handleSelect = async (option) => {
    if (!option) return;
    const res = await createAllocation({
      profile_id: requestData.profile_id,
      project_id: option.projectId,
      phase_id: option.phaseId,
      role_id: option.roleId,
      date,
    });
    setSearch(false);
    if (res?.entryGroup) {
      noFilterDispatch(createAlloc(res));
      dispatch(createAlloc(res));
    }
  };

  return (
    <Field
      as="search"
      size="small"
      placeholder={placeholder}
      loadOptions={loadOptions}
      formatGroupLabel={GroupLabel}
      formatOptionLabel={Option}
      value={input}
      handleChange={handleSelect}
      // onBlur={handleOnBlur}
      // isDisabled={isDisabled}
      openMenuOnClick={false}
      isClearable
      noSeparator
      // focused={isFocused}
      {...props}
    />
  );
};

export default React.memo(ProjectSearch);
