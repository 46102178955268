import React, { useMemo } from "react";
import { motion } from "framer-motion";
import cx from "classnames";
import styles from "./card.module.css";
import { useCard } from "./Card";

export const EmptyCard = ({ height, id }) => {
  const { gridView, ghosted, open } = useCard(id);

  const classes = useMemo(
    () =>
      cx(
        styles.emptyCard,
        { [styles.ghosted]: ghosted },
        { [styles.gridRow]: gridView === "row" },
        { [styles.open]: open }
      ),
    [ghosted, gridView, open]
  );

  return (
    <motion.div
      className={classes}
      animate={{ height }}
      transition={{ easing: "linear", duration: 0.0125 }}
      initial={false}
      data-card={id}
    />
  );
};

export default React.memo(EmptyCard);
