import util from "Blocks/utils/util";
import React, { useState, useCallback } from "react";
import Field from "Components/blocks/Field";
import { debounce } from "debounce";
import ajax from "Blocks/utils/ajax";

function AccountingPhase(props) {
  console.log(props.phase);
  const [phase, setPhase] = useState({
    external_accounting_id: props.phase["external_accounting_id"]
      ? props.phase["external_accounting_id"]
      : null,
  });

  const debounceInput = useCallback(
    debounce((object) => putPhase(object), 500),
    []
  );

  const putPhase = (object) => {
    ajax({
      method: "PUT",
      path: `/phases/${props.phase.id}`,
      data: { phase: { ...object } },
      success: (response) => {
        util.endSave();
      },
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        title={props.phase.name}
        style={{ flex: "0 1 100%", marginBottom: "8px" }}
      >
        <Field
          selectedValue={props.phase.name}
          label="Phase"
          keyName="name"
          onChange={(object) => {}}
          disabled="true"
        />
      </div>
      <div style={{ marginLeft: "8px", flex: "0 0 140px" }}>
        <Field
          selectedValue={phase.external_accounting_id}
          label="External ID"
          keyName="external_accounting_id"
          onChange={(object) => {
            util.startSave();
            setPhase(object);
            debounceInput(object);
          }}
        />
      </div>
    </div>
  );
}

export default AccountingPhase;
