import { makeRequest } from "./request";

// ROLES

// Create Role
// data {
//   role_template_id: ID,
//   phase_id: ID,
// }
export const createRole = async (data) =>
  makeRequest({
    method: "post",
    url: "/roles",
    data,
  });

// Update Role
export const updateRole = async (id, data) =>
  makeRequest({
    method: "put",
    url: `/roles/${id}`,
    data,
  });

// Update Role Order
export const updateRoleOrder = async (id, data) =>
  makeRequest({
    method: "put",
    url: `/roles/${id}/update_position`,
    data,
  });

// Delete Warnings
// /roles/:id/delete_warnings

export const deleteRoleWarnings = async (id) =>
  makeRequest({
    method: "get",
    url: `/roles/${id}/delete_warnings`,
  });

// Delete Role
// No data, instead pass an ID in the URL
export const deleteRole = async (id) =>
  makeRequest({
    method: "delete",
    url: `/roles/${id}`,
  });

// Create Role Template
// data {
//    name,
//    rate
// }
export const createRoleTemplate = async (data) =>
  makeRequest({
    method: "post",
    url: "/role_templates",
    data,
    // We use this route in legacy JS in settings to return a partial
    // eventually we will no longer need this
    params: { partial: false },
  });

// Create Role and Role Template
// data {
//    phase_id,
//    name,
//    rate
// }
export const createRoleTemplateWithRole = async (data) =>
  makeRequest({
    method: "post",
    url: "/role_templates/create_with_role",
    data,
  });
