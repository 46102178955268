/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useApi, useSetUrlParams } from "Hooks";
import { PermissionsProvider } from "Components/blocks/permissions/PermissionsContext";
import Portal from "Components/blocks/portal/Portal";
import Badge from "Components/blocks/badge/Badge";
import Alert from "Components/blocks/alert/Alert";
import { isEmpty, isEqual } from "lodash";
import { useLocalStorage } from "react-use";
import Filters from "./filters/Filters";
import Grid from "./grid/Grid";
import LoadingGrid from "./grid/emptyStates/LoadingGrid";
import { Store } from "./state/Store";
import { ScheduleProvider } from "./state/ScheduleContext";

// don't remove – includes removal of body padding
import styles from "./dashboard.module.css";

export const formatDate = (date) =>
  moment(date).startOf("isoweek").format("YYYY-MM-DD");

const defaultFilterableData = { allIds: [], byId: {} };

export const defaultFilters = {
  date: formatDate(),
  gridView: "column",
  selectedPhaseId: undefined,
  projectSort: "name",
  projectId: undefined,
  categoryIds: [],
  profileIds: undefined,
};

const VALID_GRIDVIEWS = ["column", "row"];
const VALID_PROJECT_SORTS = ["name", "number"];

const queryStringFilters = (queryParams = {}) => ({
  ...queryParams,
  ...(queryParams.date && { date: formatDate(queryParams.date) }),
});

const validateFilters = (filters) => {
  const validatedFilters = { ...filters };
  if (!filters.gridView || !VALID_GRIDVIEWS.includes(filters.gridView)) {
    validatedFilters.gridView = defaultFilters.gridView;
  }

  if (
    !filters.projectSort ||
    !VALID_PROJECT_SORTS.includes(filters.projectSort)
  ) {
    validatedFilters.projectSort = defaultFilters.projectSort;
  }

  return validatedFilters;
};

const formatUrlFilters = (filters) => {
  const formattedFilters = { ...filters };

  // Delete any URL params that are equal to their defaults, also make the date look normal if applicable
  Object.keys(formattedFilters).forEach((key) => {
    if (isEqual(defaultFilters[key], formattedFilters[key])) {
      delete formattedFilters[key];
    } else if (key === "date") {
      formattedFilters[key] = formatDate(formattedFilters[key]);
    }
  });

  return formattedFilters;
};

const useLocalStorageFilters = ({ initialQueryParams, localStorageKey }) => {
  const [localStorageFilters, setLocalStorageFilters] = useLocalStorage(
    localStorageKey,
    {}
  );
  const initialQueryStringFilters = queryStringFilters(initialQueryParams);

  const initialFilters = validateFilters({
    ...defaultFilters,
    ...localStorageFilters,
    ...initialQueryStringFilters,
  });

  const [currentFilters, setCurrentFilters] = useState({ ...initialFilters });
  const [_, setUrlParams] = useSetUrlParams(
    formatUrlFilters({ ...currentFilters })
  );

  const updateFilters = (newFilters) => {
    setCurrentFilters({
      ...currentFilters,
      ...newFilters,
    });
  };

  const clearFilter = (filterName) => {
    if (!(filterName in defaultFilters)) return;
    updateFilters({ [filterName]: defaultFilters[filterName] });
  };

  // Data with filters applied -- used in 99% of places
  const [
    filteredData,
    filteredLoading,
    filteredError,
    setFilters,
    a,
    refetchData,
  ] = useApi("/staffing/current-week.json", { ...currentFilters });

  // Data without filters -- just used so profiles' total allocated hours don't change on filter
  const [
    unfilteredData,
    unfilteredLoading,
    unfilteredError,
    setUnfilteredDate,
    b,
    refetchNoFilterData,
  ] = useApi("/staffing/current-week.json", {
    date: currentFilters.date,
  });

  useEffect(() => {
    setUrlParams(formatUrlFilters({ ...currentFilters }));
    setFilters({ ...currentFilters });
    setLocalStorageFilters({ ...currentFilters });
  }, [currentFilters]);

  useEffect(() => {
    setUnfilteredDate({ date: currentFilters.date });
  }, [currentFilters.date]);

  const filterData = {
    unfilteredData,
    filteredData,
  };

  const refetchAllData = () => {
    refetchData();
    refetchNoFilterData();
  };

  const filtersLoading =
    filteredLoading === "fetching" ||
    filteredLoading === "reloading" ||
    unfilteredLoading === "fetching";

  return {
    refetchAllData,
    updateFilters,
    filterError: !!unfilteredError || !!filteredError,
    filtersLoading,
    currentFilters,
    clearFilter,
    filterData,
    hasRequiredData: !isEmpty(filterData) && !isEmpty(unfilteredData),
  };
};

const TimeframeBadge = ({ date }) => {
  const [timeframe, setTimeframe] = useState("current");
  const thisWeek = moment(new Date()).startOf("isoweek");

  useEffect(() => {
    if (moment(date).isSame(thisWeek, "isoWeek"))
      return setTimeframe("current");
    if (moment(date).isBefore(thisWeek, "isoWeek")) return setTimeframe("past");
    setTimeframe("future");
  }, [date]);

  const variants = {
    past: { color: "var(--color-purple)", value: "Past" },
    current: { color: "var(--color-blue)", value: "Current" },
    future: { color: "var(--color-brand)", value: "Future" },
  };

  return (
    <Portal selector=".header-text__title">
      <Badge
        color={variants[timeframe].color}
        value={variants[timeframe].value}
        size="medium"
        style={{
          marginLeft: ".75rem",
          textTransform: "uppercase",
        }}
      />
    </Portal>
  );
};

export function Schedule({ queryParams: initialQueryParams, organizationId }) {
  const {
    updateFilters,
    currentFilters,
    filtersLoading,
    filterError,
    refetchAllData,
    hasRequiredData,
    clearFilter,
    filterData: { unfilteredData, filteredData },
  } = useLocalStorageFilters({
    initialQueryParams,
    localStorageKey: `weekly-staffing:${organizationId}`,
  });

  const scheduleId = filteredData?.schedule?.id ?? "";
  const populatedBy = filteredData?.schedule?.populatedBy ?? "";
  const hasAllocations = filteredData?.schedule?.hasAllocations ?? false;
  const hasTimeOff = filteredData?.schedule?.hasTimeOff ?? false;

  const filterableData = {
    projects: filteredData?.projects ?? { ...defaultFilterableData },
    categories: filteredData?.categories ?? { ...defaultFilterableData },
    profiles: filteredData?.profiles ?? { ...defaultFilterableData },
  };

  return (
    <PermissionsProvider>
      <ScheduleProvider
        scheduleId={scheduleId}
        hasTimeOff={hasTimeOff}
        populatedBy={populatedBy}
        date={currentFilters.date}
        hasAllocations={hasAllocations}
      >
        <TimeframeBadge date={moment(currentFilters.date)} />
        <Filters
          updateFilters={updateFilters}
          refetchData={refetchAllData}
          currentFilters={currentFilters}
          loading={filtersLoading}
          filterableData={filterableData}
          scheduleId={scheduleId}
          clearFilter={clearFilter}
        />
        {filterError && (
          <Alert style={{ borderRadius: 0 }}>
            There was an error fetching your data. Please refresh the page.
          </Alert>
        )}
        {filtersLoading && <LoadingGrid />}
        {!filtersLoading && hasRequiredData && (
          <Store
            initial={filteredData}
            unfilteredData={unfilteredData}
            gridView={currentFilters.gridView}
            setSelectedPhaseId={(newPhaseId) =>
              updateFilters({ selectedPhaseId: newPhaseId })
            }
            selectedPhaseId={currentFilters.selectedPhaseId}
            projectSort={currentFilters.projectSort}
            filteredProjectId={currentFilters.projectId}
            filteredCategoryIds={currentFilters.categoryIds}
            filteredProfileIds={currentFilters.profileIds}
          >
            <Grid />
          </Store>
        )}
      </ScheduleProvider>
    </PermissionsProvider>
  );
}

export default Schedule;
