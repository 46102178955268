import React from "react";
import {
  ApolloProvider,
  makeVar,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useApollo } from "@hooks/useApollo";
import { renderError } from "Blocks/alert";
import styles from "./consultants.module.css";
import { SideBar } from "./elements/SideBar";
import Consultant from "./elements/Consultant";
import { Budget } from "./elements/Budget";
import { EmptyState } from "./elements/EmptyState";
import {
  GET_PROJECT_CONSULTANTS,
  GET_PROJECT_TEMPLATE_CONSULTANTS,
} from "./queries";

export const pageStore = makeVar({});
export const usePageStore = () => useReactiveVar(pageStore);

// <Page> exists because the useQuery() call has to be made by a component that is wrapped in ApolloProvider
const Page = () => {
  const { projectSlug: slug, isTemplate } = usePageStore();

  // Use query for ProjectTemplate if we're editing a template
  const queryToUse = isTemplate
    ? GET_PROJECT_TEMPLATE_CONSULTANTS
    : GET_PROJECT_CONSULTANTS;

  const { data } = useQuery(queryToUse, {
    variables: { slug },
    fetchPolicy: "cache-and-network",
    onError: () => renderError(),
  });

  const projectOrTemplate = isTemplate ? data?.projectTemplate : data?.project;

  const alphabetical = (a, b) => a.name.localeCompare(b.name);

  const consultants = projectOrTemplate?.projectConsultants
    ?.map((consultant) => ({
      name: consultant.consultantTemplate.name,
      budget: consultant.budget,
      budgetIsFrozen: consultant.budgetIsFrozen,
      id: consultant.id,
    }))
    .sort(alphabetical);

  return (
    <div className={styles.wrapper}>
      <SideBar consultants={consultants} />
      <div>
        <Budget project={projectOrTemplate} consultants={consultants} />
        {!consultants?.length && <EmptyState />}
        {consultants?.map((consultant) => (
          <Consultant consultant={consultant} key={consultant.id} />
        ))}
      </div>
    </div>
  );
};

export function Consultants({ organizationId, isTemplate, projectSlug }) {
  const apolloClient = useApollo();
  pageStore({
    organizationId,
    isTemplate: !!isTemplate,
    projectSlug,
  });

  return (
    <ApolloProvider client={apolloClient}>
      <Page />
    </ApolloProvider>
  );
}

export default Consultants;
