import React from "react";
import util, { getSum, formatDecimal } from "../../blocks/utils/util";

// This should eventually be replaced by individual format components

export default function Total({ data, val, format }) {
  const total = getSum(data, val);

  switch (format) {
    case "number":
      return <>{total}</>;
    case "currency":
      return <>{util.toCurrencyWithCents(total)}</>;
    case "hours":
      return <>{formatDecimal(total)}</>;
    default:
      return <>{total}</>;
  }
}
