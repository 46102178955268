import Url from "domurl";
import { eventTracker } from "Blocks/tracker";
import ajax from "Blocks/utils/ajax";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

// This is a duplicate of InvoicesShow
// The reason why I'm duplicating these is because when you visit edit and then
// show without refreshing, turbolinks only loads the event listener once
// and the event is bound to the first page, but it isn't bound to the second page.
// There are other ways to solve this, but for the moment (lol), this separate js page
// will work.
class InvoicesEdit extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-delete-invoice", (event) => {
      this.deleteInvoice(event);
    });
    bindListenerToDocument("click", "js-track-invoice-duplicate", () => {
      eventTracker("trackInvoiceDuplicate");
    });
    bindListenerToDocument("click", "js-track-invoice-delete", () => {
      eventTracker("trackInvoiceDelete");
    });
  }

  load() {}

  beforeCache() {}

  deleteInvoice(event) {
    const u = new Url();
    const invoiceSlug = u.paths()[1];

    ajax({
      method: "DELETE",
      path: `/invoices/${invoiceSlug}`,
      success: (response) => {
        const u = new Url();
        u.paths(["invoices"]);
        location.href = u;
      },
    });
  }
}

const invoicesEdit = new InvoicesEdit();
export default invoicesEdit;
