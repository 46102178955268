import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Field } from "@plan";
import { renderError } from "Blocks/alert";
import { usePageStore } from "../Consultants";
import { GET_CONSULTANT_TEMPLATES, CREATE_CONSULTANT } from "../queries";

export const AddConsultant = () => {
  const { organizationId, projectSlug: slug, isTemplate } = usePageStore();
  const { data } = useQuery(GET_CONSULTANT_TEMPLATES, {
    variables: { organizationId },
  });

  const alphabetical = (a, b) => a.label.localeCompare(b.label);
  const templates =
    data?.consultantTemplates
      ?.map((template) => ({
        label: template.name,
        value: template.id,
        ...template,
      }))
      .sort(alphabetical) ?? [];

  // If this is a template, we need to refetch the projectTemplateConsultants query; otherwise it's the projectConsultants query
  const refetchQueries = isTemplate
    ? ["projectTemplateConsultants"]
    : ["projectConsultants"];

  const [addConsultant] = useMutation(CREATE_CONSULTANT, {
    refetchQueries,
    onError: () => renderError(),
  });

  const handleCreate = (e) => {
    const { id } = e;
    addConsultant({
      variables: {
        input: {
          templateId: id,
          slug,
          type: isTemplate ? "PROJECT_TEMPLATE" : "PROJECT",
        },
      },
    });
  };

  return (
    <Field
      as="select"
      size="small"
      placeholder="+ Add a Consultant"
      options={templates}
      value={null}
      handleChange={handleCreate}
    />
  );
};
