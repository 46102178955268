import React, { useState } from "react";
import Field from "Components/blocks/forms/Field";
import util from "Blocks/utils/util";
import AWS from "Blocks/aws";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";

const ExpenseItem = ({
  expense,
  entryId,
  date,
  category,
  editable,
  hasLineItem,
  expenseCategories,
  updateExpense,
  deleteExpense,
  isOverhead = false,
}) => {
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  const [amount, setAmount] = useState(expense.amount);
  const [note, setNote] = useState(expense.note);
  const [tooltip, node] = useTooltip();

  const isEditable = editable && !hasLineItem;

  const onChange = (event, setState, rescueValue = null) => {
    const { name, value } = event.target;

    setState(value);

    util.delay(
      () => performUpdateExpense({ [name]: value || rescueValue }),
      600,
      entryId + date
    );
  };

  const performUpdateExpense = (data) =>
    updateExpense({ expenseId: expense.id, updates: data });

  const toggleDeletable = () => setIsDeletable(!isDeletable);

  const uploadAttachment = (event) => {
    event.preventDefault();
    setLoadingAttachment(true);
    const file = event.target.files[0];
    AWS.getSignedUrl(file, (attachment) => {
      performUpdateExpense({ attachment });
      setLoadingAttachment(false);
    });
  };

  const deleteAttachment = () => {
    setLoadingAttachment(false);
    performUpdateExpense({ attachment: "" });
  };

  const renderBillableCheckbox = () => {
    if (isEditable) {
      return (
        <>
          <label className="expense-type__label">
            <input
              type="checkbox"
              className="expense-type__input"
              defaultChecked={expense.isBillable && !isOverhead}
              disabled={isOverhead}
              onChange={(e) =>
                updateExpense({
                  expenseId: expense.id,
                  updates: { is_billable: e.target.checked },
                })
              }
            />
            Is it billable?
          </label>
        </>
      );
    }
    if (expense.isBillable) {
      return <label className="expense-type__label">Billable</label>;
    }
    return <label className="expense-type__label">Non-billable</label>;
  };

  const renderReimbursableCheckbox = () => {
    if (isEditable) {
      return (
        <>
          <label className="expense-type__label">
            <input
              type="checkbox"
              className="expense-type__input"
              defaultChecked={expense.isReimbursable}
              disabled={!!expense.reimbursePaidDate}
              onChange={(e) =>
                updateExpense({
                  expenseId: expense.id,
                  updates: { is_reimbursable: e.target.checked },
                })
              }
            />
            Is it reimbursable?
          </label>
        </>
      );
    }
    if (expense.isReimbursable) {
      return <label className="expense-type__label">Reimbursable</label>;
    }
    return <label className="expense-type__label">Non-reimbursable</label>;
  };

  const renderUpload = () => {
    if (isEditable) {
      if (expense.attachment) {
        return (
          <div className="expense-attachment has-attachment">
            <button
              className="expense-attachment__button"
              onClick={deleteAttachment}
            />
            <label className="expense-attachment__label">
              {util.massageFileName(expense.attachment)}
            </label>
          </div>
        );
      }
      if (loadingAttachment) {
        return (
          <div className="expense-attachment">
            <label className="expense-attachment__label">
              Loading Attachment
            </label>
          </div>
        );
      }
      return (
        <div className="expense-attachment">
          <label
            className="expense-attachment__button_label"
            htmlFor={`upload-${expense.id}`}
          >
            Upload Attachment
          </label>
          <input
            name="image"
            type="file"
            className="expense-attachment__input"
            id={`upload-${expense.id}`}
            onChange={uploadAttachment}
          />
        </div>
      );
    }
    if (expense.attachment) {
      return (
        <div className="expense-attachment has-attachment">
          <label className="expense-attachment__label">
            {util.massageFileName(expense.attachment)}
          </label>
        </div>
      );
    }
  };

  return (
    <li
      className={`expense-item expense-item${
        isDeletable ? " is-deletable" : ""
      }`}
    >
      {hasLineItem && (
        <Tooltip
          text="There are invoices related to this expense so the expense can't be changed."
          tooltip={tooltip}
        />
      )}
      <div ref={node} className="expense-inputs">
        <div className="expense-inputs__group">
          <div className="expense-inputs__group-top">
            <div className="expense-inputs__select">
              <Field
                as="select"
                label="Category"
                name="expense"
                options={expenseCategories}
                value={category}
                placeholder="Select a category"
                handleChange={(cat) =>
                  performUpdateExpense({ expense_category_id: cat.value })
                }
                isDisabled={!isEditable}
              />
            </div>
            <div className="expense-inputs__field expense-inputs__field--amount">
              <Field
                as="number"
                label="Amount"
                format="currency"
                name="amount"
                placeholder=""
                value={amount}
                handleChange={(event) => onChange(event, setAmount, "0.0")}
                isDisabled={!isEditable}
                noFloatingLabel
              />
            </div>
          </div>
          <div className="expense-inputs__field expense-inputs__field--note">
            <Field
              as="text"
              label="Note"
              name="note"
              placeholder=""
              value={note || ""}
              handleChange={(event) => onChange(event, setNote)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        {isEditable ? (
          <button className="expense-inputs__button" onClick={toggleDeletable}>
            &times;
          </button>
        ) : null}
      </div>
      <div className="expense-options">
        {renderUpload()}

        <div className="expense-types">
          <div className="expense-type">{renderBillableCheckbox()}</div>
          <div className="expense-type">{renderReimbursableCheckbox()}</div>
        </div>
      </div>
      <div className="expense-actions">
        <div className="expense-actions__delete">
          <div className="expense-actions__message">
            Are you sure you want to remove this expense?
          </div>
          <button
            className="expense-actions__button expense-actions__button--delete"
            onClick={() => deleteExpense(expense.id)}
          >
            Delete
          </button>
          <button
            className="expense-actions__button expense-actions__button--cancel"
            onClick={toggleDeletable}
          >
            Cancel
          </button>
        </div>
      </div>
    </li>
  );
};

export default React.memo(ExpenseItem);
