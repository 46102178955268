/* global window, document */
import { downloadAsCSV } from "Blocks/utils/util";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import ajax from "Blocks/utils/ajax";
import { renderSuccess } from "Blocks/alert";

/* globals modal */
function csvBeginDate() {
  return $(".js-csv-begin-date")[0].value;
}
function csvEndDate() {
  return $(".js-csv-end-date")[0].value;
}

function csvProfileId() {
  return document.querySelector(".js-csv-profile-id").dataset.profileId;
}

function csvFormat() {
  const [segmentBlock] = $(".js-segment");
  const selectedButton = segmentBlock.querySelector(".is-selected");
  return selectedButton.dataset.value;
}

function csvNotes() {
  return $(".js-include-notes")[0].checked;
}

function deselectSegmentOptions(segment) {
  Array.from(segment.querySelectorAll(".js-segment-option")).forEach(
    (segmentOption) => segmentOption.classList.remove("is-selected")
  );
}

function selectSegementOption(event) {
  const button = event.target;
  const segment = button.closest(".js-segment");
  deselectSegmentOptions(segment);
  button.classList.add("is-selected");
}

function toggleButtonState(button, { text, disabled }) {
  button.innerHTML = text;
  button.disabled = disabled;
  button.classList.toggle("is-saving");
}

// Nearly identical to exportTimesheet in timesheets-show.js
function exportTimesheet(event) {
  const timesheetId = event.target.dataset.id;

  const exportTimesheetButton = event.target;
  exportTimesheetButton.classList.add("is-saving");

  ajax({
    method: "POST",
    path: `/timesheets/export/${timesheetId}`,
    success: (response) => {
      const { beginDate, profileFname, profileLname } =
        exportTimesheetButton.dataset;
      const fileName = `Timesheet ${beginDate} ${profileLname}, ${profileFname}.csv`;
      downloadAsCSV(response, fileName);

      exportTimesheetButton.classList.remove("is-saving");
      renderSuccess("Created CSV File");
      modal.close();
    },
  });
}

// Export range of timesheets for given profile
function exportProfileTimesheets(event) {
  const exportTimesheetButton = event.target;
  const initialInnerHTML = exportTimesheetButton.innerHTML;

  toggleButtonState(exportTimesheetButton, {
    text: "Exporting...",
    disabled: true,
  });

  exportTimesheetButton.classList.add("is-saving");
  const beginDate = csvBeginDate();
  const endDate = csvEndDate();
  const profileName = window.location.href.split("/").pop();

  const data = {
    begin_date: beginDate,
    csv_format: csvFormat(),
    end_date: endDate,
    include_notes: csvNotes(),
    profile_id: csvProfileId(),
  };

  const resetModalandClose = () => {
    modal.close();
    toggleButtonState(exportTimesheetButton, {
      text: initialInnerHTML,
      disabled: false,
    });
  };

  ajax({
    method: "POST",
    path: `/timesheets/export/profile`,
    data,
    success: (response) => {
      const fileName = `Timesheets ${beginDate}-${endDate}--${profileName}.csv`;
      downloadAsCSV(response, fileName);
      resetModalandClose();
    },
    failure: resetModalandClose,
  });
}

function toggleLock(event) {
  const lockButton = event.target;
  const lockedState = lockButton.dataset.locked === "true";
  const timesheetId = lockButton.dataset.id;

  const data = {
    is_unlocked: lockedState,
  };

  ajax({
    method: "PUT",
    path: `/timesheets/${timesheetId}`,
    data,
    success: () => {
      if (lockedState) {
        lockButton.dataset.locked = "false";
        lockButton.innerHTML = "Lock";
      } else {
        lockButton.dataset.locked = "true";
        lockButton.innerHTML = "Unlock";
      }
    },
  });
}

class TimesheetsIndex extends Route {
  // eslint-disable-next-line class-methods-use-this
  initialLoad() {
    bindListenerToDocument("click", "js-toggle-lock-timesheet", (event) => {
      toggleLock(event);
    });
    bindListenerToDocument("click", "js-export-timesheet", (event) => {
      exportTimesheet(event);
    });
    bindListenerToDocument("click", "js-segment-option", (event) => {
      selectSegementOption(event);
    });
    // Export multiple timesheets from index page
    // `/timesheets/:profile`
    bindListenerToDocument("click", "js-export-timesheets", (event) => {
      exportProfileTimesheets(event);
    });
  }
}

const timesheetsIndex = new TimesheetsIndex();
export default timesheetsIndex;
