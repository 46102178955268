import React from "react";

export const LoadingRing = ({ color = "var(--color-neutral-10)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    width="96"
    height="96"
    style={{ opacity: 0.9 }}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={color}
      strokeWidth="4"
      r="16"
      strokeDasharray="75.39822368615503 27.132741228718345"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.2048192771084336s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export default LoadingRing;
