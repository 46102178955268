// @ts-nocheck | don't check this for now. unsure how to get past the `noImplicitAny` error
// @todo: UNCOMMENT THE ABOVE LINE AND FIX THE ERRORS
import * as React from "react";

import { useId } from "@radix-ui/react-id";

interface FieldContextValue {
  id?: string;
  isInitiated: boolean;
  errorMessageId?: string;
  descriptionId?: string;
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
  control?: "single" | "fieldset";
  setControl: React.Dispatch<
    React.SetStateAction<FieldContextValue["control"]>
  >;
  hasFieldDescription?: boolean;
  setHasFieldDescription: React.Dispatch<
    React.SetStateAction<FieldContextValue["hasFieldDescription"]>
  >;
  hasFieldError?: boolean;
  setHasFieldError: React.Dispatch<
    React.SetStateAction<FieldContextValue["hasFieldError"]>
  >;
}

const FieldContext = React.createContext<FieldContextValue>({
  isInitiated: false,
  setControl: () => null,
  setHasFieldError: () => null,
  setHasFieldDescription: () => null,
});

export const useField = (control?: FieldContextValue["control"]) => {
  const {
    descriptionId,
    disabled,
    errorMessageId,
    id,
    invalid,
    isInitiated,
    required,
    setHasFieldDescription,
    setHasFieldError,
    ...context
  } = React.useContext(FieldContext);

  React.useEffect(() => {
    if (control) context.setControl(control);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);

  const getFieldElement = React.useCallback(
    () => (context?.control === "fieldset" ? "fieldset" : "div"),
    [context?.control]
  );

  const getInputProps = React.useCallback(
    (props) => {
      const ariaDescribedBy = [
        invalid && context?.hasFieldError && errorMessageId,
        context?.hasFieldDescription && descriptionId,
      ]
        .filter(Boolean)
        .join(" ");

      return isInitiated
        ? {
            ...props,
            disabled,
            invalid,
            required,
            ...(context?.control !== "fieldset"
              ? {
                  id,
                  "aria-describedby":
                    ariaDescribedBy.length > 0 ? ariaDescribedBy : undefined,
                }
              : {}),
          }
        : props;
    },
    [
      context,
      descriptionId,
      disabled,
      errorMessageId,
      id,
      isInitiated,
      invalid,
      required,
    ]
  );

  const getFieldLabelElement = React.useCallback(
    () => (context?.control === "fieldset" ? "legend" : "label"),
    [context?.control]
  );

  const getFieldLabelProps = React.useCallback(
    (props) =>
      isInitiated
        ? {
            ...props,
            disabled,
            required,
            ...(context?.control !== "fieldset" ? { htmlFor: id } : {}),
          }
        : props,
    [isInitiated, disabled, required, context?.control, id]
  );

  const getFieldDescriptionProps = React.useCallback(
    (props) =>
      isInitiated
        ? {
            ...props,
            id: descriptionId,
            disabled,
          }
        : props,
    [descriptionId, disabled, isInitiated]
  );

  const getFieldErrorProps = React.useCallback(
    (props) =>
      isInitiated
        ? {
            ...props,
            "aria-live": "polite",
            disabled,
            id: errorMessageId,
            invalid,
            hidden: !invalid || false,
          }
        : props,
    [disabled, errorMessageId, invalid, isInitiated]
  );

  return {
    getInputProps,
    getFieldElement,
    getFieldLabelElement,
    getFieldLabelProps,
    getFieldDescriptionProps,
    getFieldErrorProps,
    setHasFieldDescription,
    setHasFieldError,
  };
};

type UseFieldProviderProps = Pick<FieldContextValue, "id">;
const useFieldProvider = ({ id: deterministicId }: UseFieldProviderProps) => {
  const [isInitiated, setIsInitiated] =
    React.useState<FieldContextValue["isInitiated"]>(false);

  const [control, setControl] =
    React.useState<FieldContextValue["control"]>(undefined);

  const [hasFieldDescription, setHasFieldDescription] =
    React.useState<FieldContextValue["hasFieldDescription"]>(false);
  const [hasFieldError, setHasFieldError] =
    React.useState<FieldContextValue["hasFieldError"]>(false);

  const id = useId(deterministicId);
  const descriptionId = useId();
  const errorMessageId = useId();

  React.useEffect(() => {
    setIsInitiated(true);
  }, []);

  return {
    isInitiated,
    setIsInitiated,
    control,
    setControl,
    hasFieldDescription,
    setHasFieldDescription,
    hasFieldError,
    setHasFieldError,
    id,
    descriptionId,
    errorMessageId,
  };
};

export type FieldProviderProps = Pick<
  FieldContextValue,
  "id" | "disabled" | "invalid" | "required"
>;
export const FieldProvider: React.FC<
  React.PropsWithChildren<FieldProviderProps>
> = ({ id, children, ...props }) => {
  const context = useFieldProvider({ id });

  return (
    <FieldContext.Provider
      value={{
        ...props,
        ...context,
      }}
    >
      {children}
    </FieldContext.Provider>
  );
};
