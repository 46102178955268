import React from "react";
import { eventTracker } from "Blocks/tracker";
import ajax from "Blocks/utils/ajax";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import ActivitySelect from "./ActivitySelect";
import Entries from "../Entries";
import RowTotals from "../RowTotals";
import DeleteButton from "../DeleteButton";
import {
  useGlobalState,
  deleteBlock,
  deleteGroup,
  deleteRow,
} from "../state/GlobalContext";
import styles from "./project.module.css";

function ProjectRow({
  startOfWeek,
  projectId,
  groupId,
  rowId,
  activityOptions,
  color,
  blockName,
  canEdit,
  lastRow,
  activityRequired,
  noteExample,
  notesRequired,
  hasOpenInvoice,
}) {
  const { state, dispatch } = useGlobalState();

  const row = state.rows.byId[rowId];
  const entriesInRow = state.entriesByRowId[rowId];
  const totalHours = Object.values(entriesInRow).reduce(
    (totalHours, entry) => totalHours + Number(entry.hours),
    0
  );

  function getExpenseInRow(entries) {
    const expenses = [];
    Object.keys(entries).forEach((entry) => {
      const expenseArray = state.entriesByRowId[rowId][entry].expenses;
      if (expenseArray.length) {
        expenseArray.map((expense) => expenses.push(expense));
      }
    });
    return expenses;
  }
  const expenseArray = getExpenseInRow(entriesInRow);

  const totalExpenses = expenseArray.reduce(
    (acc, expense) => acc + Number(state.expenses.byId[expense].amount),
    0
  );

  const deleteRowHandler = ({ onError = () => {} }) => {
    const path = `/entry_rows/${rowId}`;
    const noOtherGroupsInBlock =
      state.selectedProjects.byId[projectId].entryGroups.length === 1;
    const noOtherRowsInGroup =
      state.projectGroups.byId[groupId].entryRows.length === 1;

    eventTracker("trackTimesheetEntryRowDeleted", {
      rowId,
    });

    ajax({
      method: "DELETE",
      path,
      success: (response) => {
        if (noOtherRowsInGroup) {
          if (noOtherGroupsInBlock) {
            dispatch(deleteBlock({ projectId }));
          } else {
            dispatch(deleteGroup({ projectId, groupId }));
          }
        } else {
          dispatch(deleteRow({ projectId, groupId, rowId }));
        }
      },
      error: (response) => {
        onError(response);
      },
    });
  };
  const group = state.projectGroups.byId[groupId];

  const projectAndPhaseAndActivityId = `${projectId}-${group.phaseId}-${row.activityId}`;

  const [tooltip, tooltipNode] = useTooltip();
  const toolTipText =
    "There are open invoices with expenses from this timesheet entry, so the phase and role cannot be changed.";

  return (
    <>
      {canEdit ? (
        <ActivitySelect
          selectedValue={row.activityName}
          activityOptions={activityOptions}
          groupId={groupId}
          rowId={rowId}
          projectId={projectId}
          canEdit={canEdit}
          lastRow={lastRow}
        />
      ) : (
        <div className={styles.rowViewName}>
          {row.activityName ? row.activityName : "No Activity"}
        </div>
      )}
      <Entries
        startOfWeek={startOfWeek}
        blockName={blockName}
        groupId={groupId}
        rowId={rowId}
        isApproved={row.isApproved}
        color={color}
        canEdit={canEdit}
        projectAndPhaseAndActivityId={projectAndPhaseAndActivityId}
        hasActivity={row.activityName !== null}
        activityRequired={activityRequired}
        noteExample={noteExample}
        notesRequired={notesRequired}
        projectId={projectId}
      />
      <RowTotals
        hours={Math.round(totalHours * 100) / 100}
        expenses={Math.round(totalExpenses * 100) / 100}
        showExpense
      />
      {canEdit ? (
        <div className={styles.rowDeleteColumn} ref={tooltipNode}>
          <DeleteButton
            deleteHandler={deleteRowHandler}
            type="activity"
            name={row.name}
            rowId={rowId}
            disabled={hasOpenInvoice}
          />
          {hasOpenInvoice && (
            <Tooltip
              text={toolTipText}
              tooltip={tooltip}
              position="left"
              style={{
                width: "210px",
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.rowViewDeleteColumn} />
      )}
    </>
  );
}

export default ProjectRow;
