import { Tooltip, useTooltip } from "@plan";
import classNames from "classnames/bind";
import React from "react";
import styles from "./InvoiceAssignColumn.module.css";

const cx = classNames.bind(styles);

function InvoiceAssignColumn() {
  const [tooltip, ref] = useTooltip();
  const text = (
    <div className={cx("column")}>
      Assigning the invoice line items below will allow Monograph to more
      accurately report on invoiced amounts. This will not affect time sheet
      data.
    </div>
  );
  return (
    <>
      <div className="invoice-list__column invoice-list__column--phase">
        Assign{" "}
        <span className={cx("iconWrapper")}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={cx("icon")}
            ref={ref}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM13 12.5C13 12.2239 12.7761 12 12.5 12H11.5C11.2239 12 11 12.2239 11 12.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V12.5ZM12.5 8C12.7761 8 13 8.22386 13 8.5V9.5C13 9.77614 12.7761 10 12.5 10H11.5C11.2239 10 11 9.77614 11 9.5V8.5C11 8.22386 11.2239 8 11.5 8H12.5Z"
            />
          </svg>
        </span>
      </div>
      <Tooltip tooltip={tooltip} text={text} position="top" />
    </>
  );
}

export default InvoiceAssignColumn;
