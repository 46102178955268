export const PLAN_TRACK = "track";
export const PLAN_GROW = "grow";

export const PROJECT_ADD_ON = {
  price: 100,
  quantity: 20,
};

export const PLAN_DETAILS = {
  [PLAN_TRACK]: {
    id: PLAN_TRACK,
    displayName: "Track",
    monthlyPrice: 30,
    annualPrice: 300,
    annualMonthlyPrice: 25,
    seatMinimum: null,
    projectLimit: 20,
  },
  [PLAN_GROW]: {
    id: PLAN_GROW,
    displayName: "Grow",
    monthlyPrice: 60,
    annualPrice: 594,
    annualMonthlyPrice: 49.5,
    seatMinimum: 5,
    projectLimit: null,
  },
};

export const UPGRADE_LANDER_DETAILS = {
  resource: {
    path: "/staffing",
    title: "Budget and forecast staff time",
    description:
      "Allocate and forecast the impact of your team’s budgeted time. No more spreadsheets needed.",
    learnMoreLink: "https://support.monograph.com/en/articles/4615842",
    textWidth: 350,
    gapWidth: 133,
  },
  team_breakdown_report: {
    path: "/team/workload/projects",
    title: "Keep track of your project teams",
    description:
      "Instantly track all active projects and their assigned team members.",
  },
  staff_assignment_report: {
    path: "/team/workload/team",
    title: "Keep track of your team members",
    description:
      "Know what projects team members are working on at any moment. Instantly track all assigned team members and their active projects.",
    textWidth: 363,
  },
  team_workload_forecast: {
    path: "/reports/team",
    title: "Track utilization rates and future staff allocation",
    description:
      "Make better staffing decisions with team activity and forecast reports.",
    textWidth: 390,
    learnMoreLink: "https://support.monograph.com/en/articles/3497263",
    mediaWidth: 520,
  },
  payroll_report: {
    path: "/reports/payroll",
    title: "Track and export your team’s time for faster payroll",
    description:
      "Increase the accuracy and timeliness of your payroll. Export payroll reports with ease.",
    textWidth: 390,
  },
  expense_report: {
    path: "/reports/expenses",
    title: "Track and export your team’s expenses",
    description:
      "Track your firm’s expenses across projects, people and categories to reimburse billable and non-billable expenses.",
    textWidth: 395,
  },
  project_templates: {
    path: "/projects/templates",
    title: "Build new project plans faster",
    description:
      "Save time building your future projects. Use any project as a template to standardize better budgeting decisions.",
    textWidth: 333,
    gapWidth: 156,
    mediaWidth: 439,
  },
};

export default UPGRADE_LANDER_DETAILS;
