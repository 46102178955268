import React from "react";
import { Close, Carot } from "../icons";
import styles from "./buttons.module.css";

// This may not belong as a shared component and could be
// better placed in the project planner folder

const Icon = ({ variant, isOpen }) => {
  switch (variant) {
    case "close":
      return <Close />;
    case "carot":
      return <Carot toggle={isOpen} />;
    default:
      return <Close />;
  }
};

export const PositionedAction = ({
  children,
  handleClick,
  variant,
  disabled = false,
  forwardedRef,
  ...props
}) => (
  <div ref={forwardedRef} className={styles.positionedWrapper}>
    <button
      disabled={disabled}
      onClick={handleClick}
      className={styles.positioned}
    >
      <Icon variant={variant} {...props} />
      {children}
    </button>
  </div>
);

export default React.memo(PositionedAction);
