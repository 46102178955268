import React from "react";

export function Exclamation({ fill = "#FFBE0B" }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="7" cy="7.03557" rx="7" ry="6.96429" fill={fill} />
      <path
        d="M6.14729 2.34H8.19129V9.354H6.14729V2.34ZM8.26129 12H6.07729V9.816H8.26129V12Z"
        fill="white"
      />
    </svg>
  );
}

export default React.memo(Exclamation);
