import React, { useCallback, useEffect, useState } from "react";

import { createComponent, keyframes, styled, useDebounce } from "@plan/core";
import { Flex } from "@plan/layout";

import { Text } from "../Text/Text";

import { ComplexityItem, PasswordStrengthStatus } from "./ComplexityItem";

const component = createComponent();

const StyledPasswordComplexity = styled(Flex, {
  ...component,
});

const fadeSlideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const SlideDown = styled(Flex, {
  "": {
    animationName: fadeSlideDown,
  },
});

const ComplexitySections = styled(Flex, {
  gap: "4px",
  width: "100%",
});

const ListItem = styled("li", {
  listStyle: "none",
  display: "flex",
  "&:before": {
    content: "· ",
    fontSize: "24px",
    verticalAlign: "middle",
    lineHeight: "1.2rem",
    margin: "0 8px",
  },
});

type EvaluatorFn = (
  password: string
) => Promise<{ entropy: number; isStrong: boolean }>;

export type PasswordComplexityProps = React.ComponentProps<
  typeof StyledPasswordComplexity
> & {
  password?: string;
  evaluator: EvaluatorFn;
};

const STATUS_MAP: Record<PasswordStrengthStatus, string> = {
  blank: "Password is blank",
  weak: "Too weak",
  medium: "Could be stronger",
  strong: "Strong password",
};

const REQUIREMENTS = [
  // uppercase
  {
    name: "uppercase",
    warning: "Upper & lower case letters",
    validate: (password: string): boolean => {
      return /[A-Z]/.test(password);
    },
  },
  // number
  {
    name: "number",
    warning: "A number",
    validate: (password: string): boolean => {
      return /[0-9]/.test(password);
    },
  },
  // symbol
  {
    name: "symbol",
    warning: "A symbol (#$&)",
    validate: (password: string): boolean => {
      return /[^a-zA-Z0-9 ]/.test(password);
    },
  },
  // common words
  {
    name: "common_words",
    warning: "No common words",
    validate: (password: string): boolean => {
      return !!password;
    },
  },
];

/**
 * Component to show real-time strength of entered password
 * @param {object} props
 * @param {string} props.password - The password to check
 * @param {EvaluatorFn} props.evaluator - async request to check password strength  - should use api call to `/pw-entropy-check` instead if at all possible
 *
 */
export const PasswordComplexityMeter = ({
  password,
  evaluator,
}: PasswordComplexityProps) => {
  const [status, setStatus] = useState<PasswordStrengthStatus>("blank");
  const debouncedPassword = useDebounce(password, 250);

  useEffect(() => {
    if (debouncedPassword) {
      checkPassword(debouncedPassword as string);
    } else {
      setStatus("blank");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPassword]);

  const checkPassword = async (password: string) => {
    const { entropy, isStrong } = await evaluator(password);

    if (entropy === 0 || !password) {
      setStatus("blank");
    } else if (entropy <= 12) {
      setStatus("weak");
    } else if (entropy <= 18 || !isStrong) {
      setStatus("medium");
    } else {
      setStatus("strong");
    }
  };

  /**
   * determines strength level (aka color) to display based on
   * password strength status and step's index
   */
  const getSectionProps = useCallback(
    (i: number): { strength: PasswordStrengthStatus } => {
      switch (status) {
        case "weak":
          return { strength: i < 1 ? "weak" : "blank" };
        case "medium":
          return { strength: i < 2 ? "medium" : "blank" };
        case "strong":
          return { strength: "strong" };
        case "blank": // fall through
        default:
          return { strength: "blank" };
      }
    },
    [status]
  );

  return (
    <StyledPasswordComplexity>
      <Flex css={{ flexDirection: "column", gap: "14px" }}>
        <Flex css={{ alignItems: "center", gap: "9px" }}>
          <ComplexitySections>
            <ComplexityItem {...getSectionProps(0)} />
            <ComplexityItem {...getSectionProps(1)} />
            <ComplexityItem {...getSectionProps(2)} />
          </ComplexitySections>

          <Text css={{ whiteSpace: "nowrap" }}>{STATUS_MAP[status]}</Text>
        </Flex>
        {password && status !== "strong" && (
          <SlideDown
            css={{
              animationDuration: `.5s`,
              animationTimingFunction: "ease-out",
              animationFillMode: "forwards",
              flexDirection: "column",
            }}
          >
            <Text>It&apos;s best to have:</Text>
            <ul>
              {REQUIREMENTS.map((req) => (
                <ListItem key={req.name}>
                  <Text>{req.warning}</Text>
                </ListItem>
              ))}
            </ul>
          </SlideDown>
        )}
      </Flex>
    </StyledPasswordComplexity>
  );
};
