import { useAction } from "@hooks";
import { makeRequest } from "./request";

export const createEntry = async (data) =>
  makeRequest({
    method: "post",
    url: `/entries`,
    data,
  });

export const useCreateEntry = (config) => useAction(createEntry, config);

export const updateEntry = async (data) =>
  makeRequest({
    method: "put",
    url: `/entries`,
    data,
  });

export const useUpdateEntry = (config) => useAction(updateEntry, config);
