import React from "react";
import cx from "classnames";
import Currency from "Components/blocks/currency/Currency";
import Hours from "Components/blocks/hours/Hours";
import styles from "./card.module.css";

const BudgetRow = ({ title, budget, showBudget, hours, value, oneColumn }) => {
  const overBudgetStyles = (value) => {
    if (value < 0) return { color: "var(--color-red)" };
    return {};
  };

  const budgetStyle = overBudgetStyles(budget);
  const hoursStyle = overBudgetStyles(hours);
  const classes = cx(styles.row, { [styles.oneColumn]: oneColumn });

  return (
    <div className={classes}>
      <div className={styles.title}>{title}</div>
      {budget !== undefined && (
        <div className={styles.budget} style={budgetStyle}>
          {showBudget && <Currency value={budget} format="noCents" />}
        </div>
      )}
      {hours !== undefined && (
        <div className={styles.hours} style={hoursStyle}>
          <Hours value={hours} label />
        </div>
      )}
      {value && <div className={styles.value}>{value}</div>}
    </div>
  );
};

export default React.memo(BudgetRow);
