import * as React from "react";

import { createComponent, styled } from "@plan/core";
import { Icon, IconDone } from "@plan/icons";
import { AutoLayout, AutoLayoutProps } from "@plan/layout";

import { useField } from "../Field";

import * as RadixCheckbox from "@radix-ui/react-checkbox";

enum vars {
  color = "$$checkboxColor",
  borderColor = "$$checkboxBorderColor",
  backgroundColor = "$$checkboxBackgroundColor",
  fontSize = "$$checkboxFontSize",
  lineHeight = "$$checkboxLineHeight",
  size = "$$checkboxSize",
}

const component = createComponent();

const LabelText = styled("span", {
  ...component,
  color: vars.color,
  lineHeight: vars.lineHeight,
  fontSize: vars.fontSize,
  fontWeight: "$normal",
  userSelect: "none",
});

const Root = styled(RadixCheckbox.Root, {
  ...component,
  [`&, & + ${LabelText}`]: {
    [vars.backgroundColor]: "$colors$white",
    [vars.borderColor]: "$colors$neutral",
    [vars.color]: "$colors$neutral40",
    [vars.fontSize]: "$fontSizes$lg",
    [vars.lineHeight]: "$lineHeights$tight",
    [vars.size]: "$sizes$xs",
  },
  appearance: "none",
  backgroundColor: vars.backgroundColor,
  border: "$borderWidths$1 solid",
  borderColor: vars.borderColor,
  borderRadius: "$radii$default",
  color: "$colors$white",
  display: "inline-block",
  fontSize: "$fontSizes$sm",
  height: vars.size,
  lineHeight: 1,
  marginTop: `calc((${vars.lineHeight} * ${vars.fontSize} - ${vars.size}) / 2)`,
  overflow: "hidden",
  padding: 0,
  width: vars.size,
  "> span": {
    [`&, > ${Icon}`]: {
      display: "inline-block",
      height: "100%",
      width: "100%",
    },
  },
  "&:not([data-disabled])": {
    "&:hover": {
      [vars.backgroundColor]: "$colors$-brand30",
      [vars.borderColor]: "$colors$-brand30",
    },
    "&:focus": {
      ring: "$colors$-brand30",
    },
    '&[data-state="checked"]': {
      [vars.backgroundColor]: "$colors$brand",
      [vars.borderColor]: "$colors$brand",
    },
  },
  "&[data-disabled]": {
    [`&, & ~ ${LabelText}`]: {
      cursor: "not-allowed",
      [vars.backgroundColor]: "$colors$-neutral30",
      [vars.borderColor]: "$colors$-neutral30",
      [vars.color]: "$colors$-neutral10",
    },
  },
  variants: {
    ...component.variants,
    invalid: {
      false: {},
      true: {
        "&:not([data-disabled])": {
          "&&": {
            [vars.borderColor]: "$colors$danger",
          },
        },
      },
    },
  },
  defaultVariants: {
    invalid: false,
  },
});

export type CheckboxRef = HTMLButtonElement;
export type CheckboxProps = Omit<
  React.ComponentProps<typeof Root>,
  "asChild"
> & {
  autoLayoutProps?: Pick<AutoLayoutProps, "spacing" | "alignment">;
};

export const Checkbox = React.forwardRef<CheckboxRef, CheckboxProps>(
  ({ disabled, required, children, autoLayoutProps = {}, ...props }, ref) => {
    const { getInputProps } = useField("fieldset");
    const inputProps = getInputProps({ disabled, required });
    const { spacing = "2", alignment = "top-left" } = autoLayoutProps;

    return (
      <AutoLayout as="label" spacing={spacing} alignment={alignment}>
        <Root ref={ref} {...inputProps} {...props}>
          <RadixCheckbox.Indicator>
            <IconDone label="Checked" />
          </RadixCheckbox.Indicator>
        </Root>
        <LabelText resizingX="fill-container" resizingY="fill-container">
          {children}
        </LabelText>
      </AutoLayout>
    );
  }
);

export type CheckboxGroupProps = Omit<
  AutoLayoutProps,
  "direction" | "spacing" | "resizingX" | "resizingY"
>;
export const CheckboxGroup = React.forwardRef<
  HTMLDivElement,
  CheckboxGroupProps
>((props, ref) => (
  <AutoLayout
    ref={ref}
    role="group"
    direction="vertical"
    spacing="2"
    {...props}
  />
));
