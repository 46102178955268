import Url from "domurl";
import { decodeHTML } from "Utils/util";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import Gantt from "Blocks/gantt";
import ajax from "Blocks/utils/ajax";
import { selectMethods } from "Blocks/select";

function checkIfConfirmed(event) {
  const inputValue = event.target.value;
  const projectName = $(".js-project-name")[0].textContent;
  const decodedProjectName = decodeHTML(projectName);
  const deleteButton = $(".js-delete-project")[0];
  if (inputValue === decodedProjectName) {
    deleteButton.disabled = false;
  } else {
    deleteButton.disabled = true;
  }
}

function deleteProject(event) {
  if (event.target.disabled === false) {
    const u = new Url();
    const projectSlug = u.paths()[1];
    ajax({
      method: "DELETE",
      path: `/projects/${projectSlug}`,
      success: () => {
        location.href = "/projects/overview";
      },
    });
  }
}

function selectProgressPhase(event) {
  const phaseId = event.target.dataset.value;
  const progressPhaseNodes = Array.from($(".js-progress-phase"));
  progressPhaseNodes.forEach((node) => node.classList.remove("is-visible"));
  const activeProgressPhaseNode = document.querySelector(
    `.js-progress-phase[data-id="${phaseId}"]`
  );
  activeProgressPhaseNode.classList.add("is-visible");

  // Right now there are a lot of select elements, because there is one for
  // each .js-progress-phase. This changes all of the select elements.
  const phaseSelects = $(".js-phase-select");
  Array.from(phaseSelects).forEach((select) => {
    const selectElement = select.closest(".js-select");
    selectMethods.selectItemByValue(selectElement, phaseId);
  });
}

function toggleArchive(event) {
  const archived = event.target.dataset.archive === "true";
  const data = {
    is_archived: archived,
  };

  const u = new Url();
  const projectSlug = u.paths()[1];

  ajax({
    method: "PUT",
    path: `/projects/${projectSlug}`,
    data,
    success: () => {
      location.href = u;
    },
  });
}

class ProjectsShow extends Route {
  // eslint-disable-next-line class-methods-use-this
  initialLoad() {
    bindListenerToDocument("input", "js-confirm-delete", (event) => {
      checkIfConfirmed(event);
    });
    bindListenerToDocument("click", "js-delete-project", (event) => {
      deleteProject(event);
    });
    bindListenerToDocument("click", "js-select-progress-phase", (event) => {
      selectProgressPhase(event);
    });
    bindListenerToDocument("click", "js-toggle-archive", (event) => {
      toggleArchive(event);
    });
  }

  load() {
    this.gantt = new Gantt();

    const shadeColor2 = function (color, percent) {
      const f = parseInt(color.slice(1), 16);
      const t = percent < 0 ? 0 : 255;
      const p = percent < 0 ? percent * -1 : percent;
      const R = f >> 16;
      const G = (f >> 8) & 0x00ff;
      const B = f & 0x0000ff;
      return `#${(
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)}`;
    };

    function rgb2hex(rgb) {
      rgb = rgb.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
      );
      return rgb && rgb.length === 4
        ? `#${`0${parseInt(rgb[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
            rgb[2],
            10
          ).toString(16)}`.slice(-2)}${`0${parseInt(rgb[3], 10).toString(
            16
          )}`.slice(-2)}`
        : "";
    }

    const baseColor = "#FFBE0B";

    // Get all the pills
    const pills = Array.from($(".projects-gantt__phase-time"));
    const labels = $(".projects-gantt__number");
    pills.forEach((pill, i) => {
      let color = pill.style.backgroundColor
        ? pill.style.backgroundColor
        : baseColor;
      color = rgb2hex(color);
      const newColor = shadeColor2(color, -0.04 * i);
      pill.style.backgroundColor = newColor;
      labels[i].style.backgroundColor = newColor;
    });

    // TODO: Remove
    if ($(".projects-gantt__list")[1]) {
      const realWidth = $(".projects-gantt__list")[1].scrollWidth;
      $(".projects-gantt__display-header")[0].style.width = `${realWidth}px`;
    }
  }

  beforeCache() {
    this.gantt.unmount();
  }
}

const projectsShow = new ProjectsShow();
export default projectsShow;
