import React from "react";

export const Cell = ({ type, value }) => {
  function getClasses(variation) {
    switch (variation) {
      case "right":
        return "page-table__cell--align-right";
      case "footer":
        return "page-table__cell--align-right page-table__cell--total";
      default:
        return "page-table__cell";
    }
  }

  const classes = getClasses(type);

  return <div className={classes}>{value}</div>;
};

export const Footer = ({ label = "Total", total }) => {
  return (
    <Cell
      type="footer"
      value={
        <>
          <span>{label}</span> {total}
        </>
      }
    />
  );
};

export function createColumns({ columns, type, options }) {
  return columns.map((column) => {
    return {
      Header: column.name,
      // handle numbers and '.' in the name (1.2 Paid Time Off)
      accessor: (col) => col[column.name],
      type,
      ...options,
    };
  });
}

export const FixedCell = ({ children }) => (
  <div className="page-table__fixed-cell">{children}</div>
);
