import React from "react";
import { Currency, Hours } from "@plan";
import Entries from "./Entries";
import Activities from "./Activities";
import Phases from "./Phases";
import TableBar from "./TableBar";
import { useTimelogContext } from "../TimelogContext";
import styles from "./table.module.css";

function GroupTable({ group }) {
  switch (group) {
    case "entries":
      return <Entries />;
    case "activities":
      return <Activities />;
    case "phases":
      return <Phases />;
    default:
      return <Entries />;
  }
}

export default function Table() {
  const { tableGroup, setTableGroup, totals } = useTimelogContext();

  const groupByOptions = [
    { value: "entries", label: "None" },
    { value: "activities", label: "Activities" },
    { value: "phases", label: "Phases" },
  ];

  const handleSelect = (data) => {
    const group = data.value;
    setTableGroup(group);
  };

  const shouldShowTotals = () => {
    if (tableGroup === "entries") return false;
    if (totals.value && totals.hours) return true;
  };
  const showTotals = shouldShowTotals();

  return (
    <>
      <div className={styles.tableWrapper}>
        <TableBar groupByOptions={groupByOptions} handleSelect={handleSelect} />
        <GroupTable group={tableGroup} />
      </div>
      {showTotals && (
        <div className={styles.totalGrouped}>
          <div className={styles.totalHours}>
            <Hours value={totals.hours} format="twoDecimal" />
            <span className={`${styles.totalLabel} sum__total`}>Hours</span>
          </div>
          <div className={styles.totalAmount}>
            <Currency value={totals.value} format="withCents" />
            <span className={`${styles.totalLabel} sum__total`}>Total</span>
          </div>
        </div>
      )}
    </>
  );
}
