// Entry point for the build script in your package.json
import "core-js/stable";
import "regenerator-runtime/runtime";

// These are from rails create react app
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import Highcharts from "highcharts";
import VanillaModal from "vanilla-modal";
import $ from "./blocks/utils/jQuerySelector";
import sidebar from "./blocks/sidebar";
import initPolyfills from "./blocks/polyfill";

import { selectMethods, initSelects } from "./blocks/select";
import controller from "./blocks/controller";
import { addHasValueOnLoad } from "./blocks/field";
import { initAlerts, addFadeOutForServerRenderedAlerts } from "./blocks/alert";
import "./components";

window.$ = $;
Turbolinks.start();

window.Turbolinks = Turbolinks;
window.ReactRailsUJS.detectEvents();

Rails.start();

// Init sidebar
sidebar.init();

// Support component names relative to this directory:
// Required for Rails React
// let componentRequireContext = require.context("components", true);

// let ReactRailsUJS = require("react_ujs");

// ReactRailsUJS.useContext(componentRequireContext);

// Init polyfills
initPolyfills();

// Add highcharts with extra modules and settings
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// Change k to K to represent thousands
Highcharts.setOptions({
  lang: {
    // default ['k', 'M', 'G', 'T', 'P', 'E']
    numericSymbols: ["K", "M", "G", "T", "P", "E"],
  },
});

// Set up listeners for selects
initSelects();
initAlerts();

// Turbolinks
// Fires immediately after a visit starts.
// document.addEventListener("turbolinks:visit", function () {
//   console.log("visit");
// });

// Before leaving the page
document.addEventListener("turbolinks:before-cache", () => {
  controller.beforeCache();

  // Closes modal and repopulates the original div with its content if modal is open
  if (typeof modal !== "undefined") {
    if (modal.isOpen) {
      // Added rush = true to allow the modal to close without waiting for transitions
      modal.close(null, true);
    }
    modal.destroy();
  }

  ReactRailsUJS.unmountComponents();
});

function renderSearchMenuHelper() {
  function isAppleDevice() {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  }

  const el = document.querySelector(".sidebar-item__search-helper");
  if (el && isAppleDevice) {
    el.innerText = "⌘ + k";
  }
}

// Fires once after the initial page load, and again after every Turbolinks visit.
document.addEventListener("turbolinks:load", (event) => {
  // Reinstantiating the modal every time prevents it from breaking after subsequent page loads
  // Define it with window so that other modules have access
  window.modal = new VanillaModal({
    modal: ".modal",
    modalInner: ".modal-overlay",
    modalContent: ".modal-content",
    loadClass: "modal-is-active",
    class: "modal-is-visible",
  });

  selectMethods.initializeSelects();
  controller.load();
  addFadeOutForServerRenderedAlerts();
  addHasValueOnLoad();
  renderSearchMenuHelper();
});
