import Route from "Blocks/route";
import ajax from "Blocks/utils/ajax";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import Url from "domurl";

class InvoicesShow extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-delete-invoice", (event) => {
      this.deleteInvoice(event);
    });
  }

  load() {}

  beforeCache() {}

  deleteInvoice(event) {
    const u = new Url();
    const invoiceSlug = u.paths()[1];

    ajax({
      method: "DELETE",
      path: `/invoices/${invoiceSlug}`,
      success: (response) => {
        const u = new Url();
        u.paths(["invoices"]);
        location.href = u;
      },
    });
  }
}

const invoicesShow = new InvoicesShow();
export default invoicesShow;
