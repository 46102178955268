import numeral from "numeral";
import isNumber from "lodash/isNumber";
import moment from "moment";

/* globals currencyFormat, roundedCurrencyFormat, window, document, Blob */

const util = {
  htmlToElement(html) {
    const template = document.createElement("div");
    template.innerHTML = html.trim();
    return template.firstChild;
  },

  htmlToElements(html) {
    const template = document.createElement("div");
    template.innerHTML = html.trim();
    return template.childNodes;
  },

  capitalize(str) {
    if (typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  deleteElement(el) {
    el.parentNode.removeChild(el);
  },

  delay: (callback, ms, stringUniqToInput) => {
    clearTimeout(util.timeouts[stringUniqToInput]);
    util.timeouts[stringUniqToInput] = setTimeout(callback, ms);
  },

  // This saves the timeout variables for each input
  timeouts: {},

  // I still need to use this because Numeral doesn't treat null/empty values as 0,
  // which is the desired functionality of this
  toFloat(str) {
    const value = numeral(str).value();
    if (Number.isNaN(parseFloat(value))) {
      return 0.0;
    }
    return parseFloat(value);
  },

  // If the rounded float has no cents, don't render any, otherwise show the
  // typical amount of cents.
  // 1 => $1
  // 1.60 => $1.60
  toCurrency(float) {
    if (Number(float).toFixed(2).slice(-2) === "00") {
      return roundedCurrencyFormat.format(float);
    }
    return currencyFormat.format(float);
  },

  // 1 => $1
  // 1.60 => $2
  toCurrencyWithoutCents(float) {
    return roundedCurrencyFormat.format(float);
  },

  // 1 => $1.00
  // 1.60 => $1.60
  toCurrencyWithCents(float) {
    return currencyFormat.format(float);
  },

  slugify(text) {
    // Use hash map for special characters
    const specialChars = {
      à: "a",
      ä: "a",
      á: "a",
      â: "a",
      æ: "a",
      å: "a",
      ë: "e",
      è: "e",
      é: "e",
      ê: "e",
      î: "i",
      ï: "i",
      ì: "i",
      í: "i",
      ò: "o",
      ó: "o",
      ö: "o",
      ô: "o",
      ø: "o",
      ù: "o",
      ú: "u",
      ü: "u",
      û: "u",
      ñ: "n",
      ç: "c",
      ß: "s",
      ÿ: "y",
      œ: "o",
      ŕ: "r",
      ś: "s",
      ń: "n",
      ṕ: "p",
      ẃ: "w",
      ǵ: "g",
      ǹ: "n",
      ḿ: "m",
      ǘ: "u",
      ẍ: "x",
      ź: "z",
      ḧ: "h",
      "·": "-",
      "/": "-",
      _: "-",
      ",": "-",
      ":": "-",
      ";": "-",
    };

    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/./g, (target) => specialChars[target] || target) // Replace special characters using the hash map
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  },

  spaceship(val1, val2) {
    if (val1 === null || val2 === null || typeof val1 !== typeof val2) {
      return null;
    }
    if (typeof val1 === "string") {
      return val1.localeCompare(val2);
    }
    if (val1 > val2) {
      return 1;
    }
    if (val1 < val2) {
      return -1;
    }
    return 0;
  },

  // Added startSave and endSave to util so React components could also use them.
  startSave() {
    const saveButton = $(".js-save")[0];
    if (saveButton) {
      saveButton.classList.add("is-saving");
      saveButton.innerHTML = "Saving...";
    }
  },

  endSave() {
    const saveButton = $(".js-save")[0];
    if (saveButton) {
      setTimeout(() => {
        saveButton.classList.remove("is-saving");
        saveButton.innerHTML = "Changes Saved!";
      }, 1000);
    }
  },

  getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  },

  deepEqual(obj1, obj2) {
    // it's just the same object. No need to compare.
    if (obj1 === obj2) {
      return true;
    }
    // compare primitives
    if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
      return obj1 === obj2;
    }
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    // compare objects with same number of keys
    for (const key in obj1) {
      // other object doesn't have this prop
      if (!(key in obj2)) return false;
      if (!util.deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  },

  // https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
  serialize(obj) {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    return str.join("&");
  },

  massageFileName(awsLink) {
    let fullFileName = awsLink.substr(awsLink.lastIndexOf("/") + 1);
    fullFileName = fullFileName.split(".");
    const extension = fullFileName.pop();
    const fileName = fullFileName.join(".");

    // Shorten the file name if more than 24 characters
    if (fileName.length > 24) {
      return `${fileName.substr(0, 24)}...${extension}`;
    }
    return `${fileName}.${extension}`;
  },
};

// Check for commas or newlines in all data and wrap the string in "" if needed
function sanitizeForCsv(data) {
  if (!data) {
    return "";
  }

  const cell = data.toString(); // Coerce to string

  if (cell?.includes(",") || cell?.includes("\n")) {
    return `"${cell}"`; // wrap in double quotes
  }
  return cell;
}

export const joinAsCSV = (headers, body) => {
  const rows = [headers, ...body];
  return rows.map((row) => row.map((item) => sanitizeForCsv(item))).join("\n");
};

export function downloadAsCSV(data, fileName) {
  const blob = new Blob([data], { type: "csv" });
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export const getSum = (arr, key) =>
  arr.reduce((sum, item) => sum + Number(item[key]), 0);

export const formatDecimal = (val, decimalPlaces = 2) =>
  Number.parseFloat(val).toFixed(decimalPlaces);

// 0.25 --> 25.00%
export function formatPercent(value) {
  if (value === Infinity) return "--";
  if (value === null || isNaN(value)) return 0;
  if (value === 1) return 100;
  return parseFloat(value * 100).toFixed(2);
}

// mimics Rails simple_format method
export function simpleFormat(string) {
  let formattedString = string.replace(/\r\n?/, "\n");
  formattedString = formattedString.trim();
  if (formattedString.length > 0) {
    formattedString = formattedString.replace(/\n\n+/g, "</p><p>");
    formattedString = formattedString.replace(/\n/g, "<br />");
    formattedString = `<p>${formattedString}</p>`;
  }
  return formattedString;
}

// Doens't include currency symbol (localeTag is available in rails view)
const localeTag = localeTag || {};
export const numberFormat = new Intl.NumberFormat(localeTag, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

// 1000 --> 1,000
export const formatCurrencyNoSymbol = (value) => {
  if (!value) return numberFormat.format(0);
  return numberFormat.format(value);
};

// Always returns a number unless infinity
export function expectNumber(value) {
  if (value === Infinity) return "--";
  if (isNumber(value)) return parseFloat(value);
  if (
    !value ||
    isNaN(value) ||
    value === undefined ||
    value === null ||
    value === ""
  )
    return 0;
  return parseFloat(value);
}

export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

export const getCurrentMonth = () => {
  const begin_date = moment().startOf("month").format("YYYY-MM-DD");
  const end_date = moment().endOf("month").format("YYYY-MM-DD");
  return { begin_date, end_date };
};

export const getCurrentYear = () => {
  const begin_date = moment().startOf("year").format("YYYY-MM-DD");
  const end_date = moment().endOf("year").format("YYYY-MM-DD");
  return { begin_date, end_date };
};

export const getOneYearFromCurrentMonth = () => {
  const begin_date = moment().startOf("month").format("YYYY-MM-DD");
  // Get one year out and go back one day to not include the beginning of
  // the month next year eg. 12/1/2000 - 12/1/2001 vs 12/1/2000 - 11/30/2001
  const end_date = moment(begin_date)
    .add(1, "year")
    .subtract(1, "day")
    .format("YYYY-MM-DD");
  return { begin_date, end_date };
};

export const decodeHTML = (html) => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = html;
  return textArea.value;
};

export const formatWeeksAndDays = (totalDays) => ({
  days: totalDays % 7,
  weeks: Math.floor(totalDays / 7),
});

export default util;
