import * as React from "react";

import type { AsChildProp } from "@plan/core";
import { styled } from "@plan/core";

import { FORM_SIZES } from "../constants";

import { StyledFieldDescription } from "./FieldDescription";
import { FieldProvider, FieldProviderProps, useField } from "./useField";

import { Slot } from "@radix-ui/react-slot";

const FORM_CONTROL_ELEMENT = "div";

const StyledFieldRoot = styled(FORM_CONTROL_ELEMENT, {
  display: "flex",
  flexDirection: "column",
  "> *": {
    "& + *": {
      marginTop: "$1_5",
    },
    [`& + ${StyledFieldDescription}`]: {
      marginTop: "$0_5",
    },
  },
  variants: {
    size: FORM_SIZES,
  },
});

interface FieldRootProps
  extends AsChildProp,
    React.ComponentProps<typeof StyledFieldRoot> {}

const FieldRoot = React.forwardRef<HTMLElement, FieldRootProps>(
  ({ asChild, ...props }, ref) => {
    const { getFieldElement } = useField();

    const DEFAULT_ELEMENT = getFieldElement();

    const Comp = asChild ? Slot : DEFAULT_ELEMENT;

    return <StyledFieldRoot ref={ref} as={Comp} {...props} />;
  }
);

export interface FieldProps extends FieldRootProps, FieldProviderProps {}

export const Field = React.forwardRef<HTMLElement, FieldProps>(
  ({ asChild, children, size, ...props }, ref) => (
    <FieldProvider {...props}>
      <FieldRoot ref={ref} size={size} asChild={asChild}>
        {children}
      </FieldRoot>
    </FieldProvider>
  )
);
