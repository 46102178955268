import React, { useMemo, useRef, useEffect, useCallback } from "react";
import cx from "classnames";
import { formatCurrencyNoSymbol, expectNumber } from "Utils/util";
import { useFieldState } from "../FieldContext";
import Formatter from "./FieldFormat";
import styles from "./formats.module.css";
import fieldStyles from "../field.module.css";

const FormattedTextInput = React.memo(({ value, classes }) => {
  const { name, isDisabled, placeholder } = useFieldState();
  return (
    <Formatter>
      <input
        className={classes}
        tabIndex={isDisabled ? -1 : 0}
        disabled={isDisabled}
        name={name}
        type="text"
        readOnly
        value={value}
        placeholder={placeholder}
      />
    </Formatter>
  );
});

export function NumberInput() {
  const {
    name,
    value,
    required,
    isDisabled,
    isHovered,
    isFocused,
    focused,
    size,
    min,
    max,
    step,
    handleChange,
    handleBlur,
    handleFocus,
    handleKeyDown,
    placeholder,
    handleWheel,
    handleScroll,
    format,
    negative,
    inputClasses,
    isSelected,
  } = useFieldState();

  const ref = useRef(null);

  const displayVal = (val) => {
    if (focused && (val === null || val === 0)) return "";
    if (
      !focused &&
      (format === "currency" || format === "perHour" || format === "percent")
    ) {
      if (!isFinite(value)) return value;
      return expectNumber(value).toFixed(2);
    }
    if (!focused && format === "time") {
      return value;
    }
    if (value === "") return null;
    return value;
  };

  const formatCheck = useMemo(
    () => (!focused && !isHovered) || isDisabled,
    [focused, isHovered, isDisabled]
  );

  const classes = cx(
    styles.number,
    { [inputClasses]: inputClasses },
    { [fieldStyles.field__input]: !size || !format },
    { [styles[`input-${size}`]]: size === "small" },
    { [styles[`input-${format}`]]: format },
    { [styles.isOverBudget]: negative }
  );
  // Prevent the onWheel increment and decrement of number fields
  // React treats 'onWheel' as a passive event so we add a vanilla
  // event listener and cancel it when in focus
  useEffect(() => {
    const onWheel = (e) => {
      // If we've passed a custom handleWheel event use that
      if (handleWheel) return handleWheel(e);
      // Otherwise cancel out the onWheel event if the element is focused
      // This lets us still scroll over non-focused inputs but cancel out the
      // undesired inc/dec on wheel scroll when active
      if (ref.current === document.activeElement) e.preventDefault();
    };
    if (ref.current) ref.current.addEventListener("wheel", onWheel);
    return () => {
      if (ref.current) return ref.current.removeEventListener("wheel", onWheel);
    };
  }, [ref]);

  // We swap out the number field for a text input in order to format the appearance
  if ((format === "currency" || format === "perHour") && formatCheck)
    return (
      <FormattedTextInput
        value={formatCurrencyNoSymbol(value)}
        classes={classes}
      />
    );

  if (format === "percent" && formatCheck)
    return (
      <FormattedTextInput
        value={expectNumber(value).toFixed(2)}
        classes={classes}
      />
    );

  if (format === "hours" && formatCheck)
    return (
      // remove leading zeros
      <FormattedTextInput value={+value} classes={classes} />
    );

  return (
    <Formatter>
      <input
        className={classes}
        inputMode="decimal"
        pattern="\d*"
        name={name}
        type="number"
        step={step || 1}
        value={displayVal(value)}
        required={required}
        tabIndex={isDisabled ? -1 : 0}
        disabled={isDisabled}
        min={min}
        max={max}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        ref={ref}
      />
    </Formatter>
  );
}

export default React.memo(NumberInput);
