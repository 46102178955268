import React, { useState } from "react";
import ajax from "Blocks/utils/ajax";
import Url from "domurl";
import PropTypes from "prop-types";
import { useGlobalState } from "./state/GlobalContext";
import { eventTracker } from "Blocks/tracker";

export default function Submit({ canEdit, timesheetIsLocked }) {
  const { state } = useGlobalState();
  const { timesheet } = state;
  const { isSubmitted } = timesheet;
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!canEdit || timesheetIsLocked) return null;
  // if (viewLockedState || !isUnlocked) return null;

  const navigateToTimesheets = () => {
    const u = new Url();
    const newPaths = u.paths();
    newPaths.pop();
    u.paths(newPaths);
    location.href = u;
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    eventTracker("trackTimesheetSubmit");
    const data = {
      is_submitted: true,
      is_unlocked: false,
    };

    ajax({
      method: "PUT",
      path: `/timesheets/${timesheet.id}`,
      data,
      success: () => {
        navigateToTimesheets();
      },
    });
  };

  const buttonText = () => {
    if (isSubmitting) return "Submitting...";
    if (isSubmitted) return "Resubmit";
    return "Submit";
  };

  return (
    <button
      type="button"
      className="header-actions__button header-actions__button--primary"
      onClick={handleSubmit}
    >
      {buttonText()}
    </button>
  );
}

Submit.propTypes = {
  viewLockedState: PropTypes.bool,
};

Submit.defaultProps = {
  viewLockedState: false,
};
