import React from "react";
import styles from "./field-group.module.css";

export function FieldGroup({ children, classes }) {
  return (
    <div className={`${styles.fieldGroup} ${classes && classes}`}>
      {children}
    </div>
  );
}

export default React.memo(FieldGroup);
