// https://stackoverflow.com/questions/6604291/proper-way-to-remove-all-series-data-from-a-highcharts-chart
export default function updateSeries(chart, newSeries) {
  while (chart.series.length > 0) {
    chart.series[0].remove(false);
  }

  newSeries.forEach((series) => {
    chart.addSeries(series, false);
  });
}
