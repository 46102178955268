import React from "react";

export const CreditCards = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.75016 1.75H9.91683C10.5612 1.75 11.0835 2.27233 11.0835 2.91667V9.33333C11.0835 9.97766 10.5612 10.5 9.91683 10.5H1.75016C1.10583 10.5 0.583496 9.97766 0.583496 9.33333V2.91667C0.583496 2.27233 1.10583 1.75 1.75016 1.75ZM9.91683 4.66667V2.91667H1.75016V4.66667H9.91683ZM1.75016 9.33333V6.41667H9.91683V9.33333H1.75016ZM12.2502 10.5V4.08333C12.8945 4.08333 13.4168 4.60567 13.4168 5.25V10.5C13.4168 11.7887 12.3722 12.8333 11.0835 12.8333H4.0835C3.43916 12.8333 2.91683 12.311 2.91683 11.6667H11.0835C11.7278 11.6667 12.2502 11.1443 12.2502 10.5Z"
      />
    </svg>
  );
};

export default CreditCards;
