/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
import { downloadAsCSV } from "Utils/util";
import ajax from "Blocks/utils/ajax";
import { eventTracker } from "Blocks/tracker";
import { renderSuccess } from "Blocks/alert";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

/* globals modal */

function csvBeginDate(isAjera) {
  if (isAjera) {
    return $(".js-csv-begin-date-ajera")[0].value;
  }
  return $(".js-csv-begin-date")[0].value;
}
function csvEndDate(isAjera) {
  if (isAjera) {
    return $(".js-csv-end-date-ajera")[0].value;
  }
  return $(".js-csv-end-date")[0].value;
}

function csvFormat() {
  const [segmentBlock] = $(".js-segment");
  const selectedButton = segmentBlock.querySelector(".is-selected");
  return selectedButton.dataset.value;
}

function csvNotes() {
  return $(".js-include-notes")[0].checked;
}

function deselectSegmentOptions(segment) {
  Array.from(segment.querySelectorAll(".js-segment-option")).forEach(
    (segmentOption) => segmentOption.classList.remove("is-selected")
  );
}

function selectSegementOption(event) {
  const button = event.target;
  const segment = button.closest(".js-segment");
  deselectSegmentOptions(segment);
  button.classList.add("is-selected");
}

function toggleButtonState(button, { text, disabled }) {
  button.innerHTML = text;
  button.disabled = disabled;
  button.classList.toggle("is-saving");
}

function exportTimesheets(event, isAjera) {
  const button = event.target;

  const initialInnerHTML = button.innerHTML;

  toggleButtonState(button, {
    text: "Exporting...",
    disabled: true,
  });

  const beginDate = csvBeginDate(isAjera);
  const endDate = csvEndDate(isAjera);

  const data = {
    begin_date: beginDate,
    end_date: endDate,
    csv_format: csvFormat(),
    include_notes: csvNotes(),
  };

  const resetModalandClose = () => {
    modal.close();
    toggleButtonState(button, {
      text: initialInnerHTML,
      disabled: false,
    });
  };

  const exportUrl = isAjera
    ? "/timesheets/export/ajera"
    : "/timesheets/export/organization";

  ajax({
    method: "POST",
    path: exportUrl,
    data,
    success: (res) => {
      if (isAjera) {
        eventTracker("trackTimesheetExportedForAjera");
        downloadAsCSV(res, "ajera-export.csv");
      } else {
        renderSuccess(
          "Export generated, you should receive a download email shortly"
        );
      }

      resetModalandClose();
    },
    failure: resetModalandClose,
  });
}

function toggleLock(event) {
  const lockButton = event.target;
  const lockedState = lockButton.dataset.locked === "true";
  const timesheetId = lockButton.dataset.id;

  const data = {
    is_unlocked: lockedState,
  };

  ajax({
    method: "PUT",
    path: `/timesheets/${timesheetId}`,
    data,
    success: () => {
      if (lockedState) {
        lockButton.dataset.locked = "false";
        lockButton.innerHTML = "Lock";
      } else {
        lockButton.dataset.locked = "true";
        lockButton.innerHTML = "Unlock";
      }
    },
  });
}

class TimesheetsOrganizationTimesheets extends Route {
  // eslint-disable-next-line class-methods-use-this
  initialLoad() {
    bindListenerToDocument("click", "js-toggle-lock-timesheet", (event) => {
      toggleLock(event);
    });
    bindListenerToDocument("click", "js-export-timesheets", (event) => {
      exportTimesheets(event);
    });
    bindListenerToDocument("click", "js-export-timesheets-ajera", (event) => {
      exportTimesheets(event, true);
    });
    bindListenerToDocument("click", "js-segment-option", (event) => {
      selectSegementOption(event);
    });
  }
}

const timesheetsOrganizationTimesheets = new TimesheetsOrganizationTimesheets();
export default timesheetsOrganizationTimesheets;
