import React from "react";
import cx from "classnames";
import styles from "./card.module.css";

export function Card({ children, classes, withPadding, forwardRef, style }) {
  return (
    <section
      className={cx(
        styles.card,
        { [classes]: classes },
        { [styles.withPadding]: withPadding }
      )}
      style={style}
      ref={forwardRef}
    >
      {children}
    </section>
  );
}

export default Card;
