import React from "react";
import AsyncSelect from "react-select/async";
import ReactSelect, { components } from "react-select";
import CreateSelect from "react-select/creatable";

export const Option = (props) => (
  <span>
    <components.Option {...props} />
  </span>
);

function CreateSearchStyles(props) {
  const { withPadding, size, noSeparator } = props;
  return {
    container: (provided, state) => ({
      width: "100%",
      height: "100%",
      position: "relative",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: 0,
      color: "var(--color-neutral-10)",
    }),
    group: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid var(--color--neutral-20)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow: "visible",
      fontSize: "1rem",
      lineHeight: "1",
      color: "#011627",
      margin: 0,
    }),
    control: (provided, state) => ({
      background: "transparent",
      fontSize: props.small ? ".875rem" : "1rem",
      height: "100%",
      lineHeight: props.small ? "1" : "2rem",
      outline: "none",
      padding: "0.875rem 0 0 0.75em",
      paddingTop: size === "small" ? 0 : null,
      paddingLeft: size === "small" ? 0 : null,
      display: "flex",
      flexDirection: "row-reverse",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
    }),
    indicatorSeparator: (provided, state) => ({
      display: noSeparator ? "none" : "block",
      padding: withPadding ? provided.padding : 0,
      backgroundColor: "var(--color-legacy-gray-3)",
      width: size === "small" ? "1px" : "",
      alignSelf: "stretch",
      margin: ".5rem",
    }),
    loadingIndicator: (provided, state) => ({
      position: "absolute",
      top: "50%",
      right: "1rem",
      opacity: 0.5,
      // sets the size of the loading dots
      fontSize: "6px",
      transform: "translateY(-3px)",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      width: "auto",
      color: "var(--color-legacy-gray-4)",
      marginLeft: "0.5rem",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      paddingLeft: size === "small" ? ".75rem" : 0,
      // fix some obscure alignment issue
      marginTop: "1px",
      marginLeft: "-0.25rem",
    }),
    input: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      width: "100%",
      marginLeft: 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      lineHeight: 1.5,
      width: "auto",
      whiteSpace: "noWrap",
      maxWidth: "max-content",
      minWidth: "100%",
      zIndex: 100,
    }),
    option: (provided, state) => {
      const isAddButton = state.data.value === "--add-button";
      return {
        ...provided,
        padding: "4px 16px",
        background: state.isFocused
          ? "var(--color-brand)"
          : state.isSelected
          ? "var(--color-brand)"
          : state.isActive
          ? "var(--color-brand-10)"
          : "#fff",
        color:
          state.isFocused || state.isSelected
            ? "white"
            : isAddButton
            ? "var(--color-brand)"
            : state.isFocused && isAddButton
            ? "var(--color-white)"
            : "black",
        cursor: "pointer",
        ":active": {
          ...provided[":active"],
          backgroundColor: "var(--color-brand-10)",
        },
      };
    },
  };
}

const CaretDownIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="3.5" stroke="#606D78" />
    <path d="M6.5 6.5L9.5 9.5" stroke="#606D78" />
  </svg>
);

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <CaretDownIcon />
  </components.DropdownIndicator>
);

export function Search(props) {
  const { handleChange, options } = props;

  const styles = CreateSearchStyles(props);

  return (
    <AsyncSelect
      {...props}
      styles={styles}
      options={options}
      components={{ DropdownIndicator }}
      onChange={handleChange}
    />
  );
}

export default React.memo(Search);
