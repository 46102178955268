import React from "react";
import { useModal } from "Components/blocks/modal/useModal";
import { TemplateModal } from "./ProjectTemplateModal";

export default function CreateProjectTemplateButton({
  organizationId,
  colors,
  planName,
  planTrack,
}) {
  const [modal, modalActions] = useModal(false);
  const button = (
    <button
      className="header-actions__button header-actions__button--primary"
      onClick={modalActions.open}
    >
      New Template
    </button>
  );

  return (
    <>
      {planName === planTrack ? null : button}
      <TemplateModal
        isOpen={modal}
        actions={modalActions}
        organizationId={organizationId}
        colors={colors}
      />
    </>
  );
}
