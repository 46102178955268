import React from "react";

// This is still in use as of (12/8/2020), however, we can begin to port to the
// 'Panel' accordion component for greater flexibility
export default class ExpandableItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  render() {
    const { content, name, summary } = this.props;
    const { expanded } = this.state;

    return (
      <li className={`expandable-item${expanded ? " is-expanded" : ""}`}>
        <div
          className="expandable-item__header"
          onClick={() =>
            this.setState((prevState) => ({ expanded: !prevState.expanded }))
          }
        >
          <h3 className="expandable-item__name">{name}</h3>
          <div className="expandable-item__summary">{summary}</div>
          <span className="expandable-item__toggle" />
        </div>
        <div className="expandable-item__content">{content}</div>
      </li>
    );
  }
}
