import { makeRequest } from "./request";

// Allocations

// Create EntryGroup
// data {
//    alloction: Number,
//  }
export const createAllocation = async (data) =>
  makeRequest({
    method: "post",
    url: `/entry_groups/create_allocation`,
    data,
  });

// Update EntryGroup Allocation
// data {
//  entry_group_id: ID,
//  alloction: Number,
//  }
export const updateAllocation = async (data) =>
  makeRequest({
    method: "put",
    url: `/entry_groups/update_allocation`,
    data,
  });

export const deleteAllocation = async (data) =>
  makeRequest({
    method: "put",
    url: `/entry_groups/remove_allocation`,
    data,
  });
