import React from "react";
import ViewSampleButton from "Components/blocks/buttons/ViewSampleButton";
import sample from "../../../assets/images/samples/project-planner.jpg";

export default function ProjectSample() {
  const content = {
    image: sample,
    link: {
      url: "http://support.monograph.io/en/articles/1374411-how-to-create-a-project",
      text: "See our tutorial for creating a project.",
    },
  };

  return (
    <ViewSampleButton modalTitle="Sample Project Planner" content={content} />
  );
}
