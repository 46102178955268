import React, { useRef } from "react";
import makeWeek from "Blocks/utils/makeWeek";
import { Field } from "@plan";
import { useGlobalState } from "../state/GlobalContext.jsx";
import styles from "../timesheet.module.css";
import entryStyles from "../entry.module.css";

function TimeOffHolidayEntries({ holidays }) {
  const { state } = useGlobalState();
  const inputRef = useRef();
  const containerRef = useRef();
  const daysOfWeek = makeWeek(state.date);

  const Entry = ({ day }) => {
    const anchorId = `${holidays.id}-${day}`;
    const matchingEntry = holidays.find((holiday) => holiday.date === day.date);
    const entryValue = matchingEntry ? matchingEntry.hours : 0;

    return (
      <>
        <div className={entryStyles.anchor} id={anchorId} />
        <div ref={containerRef} className={entryStyles.entry}>
          <div className={entryStyles.entryField}>
            <Field
              as="text"
              type="text"
              variant="timeInput"
              format="time"
              size="small"
              placeholder="0.00"
              value={entryValue}
              blockClasses="expenseInput"
              classes="expenseInput"
              forwardRef={inputRef}
              isDisabled
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.entries}>
        {daysOfWeek.map((day) => (
          <Entry day={day} />
        ))}
      </div>
    </>
  );
}

export default TimeOffHolidayEntries;
