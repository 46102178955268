import * as React from "react";

import { createComponent, keyframes, styled } from "@plan/core";
import { IconChevronRight } from "@plan/icons";

import * as AccordionBase from "@radix-ui/react-accordion";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

const slideDown = keyframes({
  from: { height: 0, opacity: 0 },
  to: { height: "var(--radix-accordion-content-height)", opacity: 1 },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)", opacity: 1 },
  to: { height: 0, opacity: 0 },
});

const component = createComponent();

const accordionChild = {
  ...component,
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "fill-container",
    resizingY: "fill-container",
  },
};

export const AccordionContent = styled(AccordionBase.Content, {
  ...accordionChild,
  '&[data-state="open"]': {
    animation: `${slideDown} 150ms ease-in`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 150ms ease-out`,
  },
});

export const AccordionHeader = styled(AccordionBase.Header, {
  ...accordionChild,
  display: "flex",
});

export const AccordionButton = styled(AccordionBase.Trigger, {
  ...accordionChild,
  padding: 0,
  width: "100%",
  height: "100%",
  border: "none",
  textDecoration: "none",
  cursor: "pointer",
  backgroundColor: "transparent",
});

type AccordionTriggerProps = React.ComponentProps<typeof AccordionButton> &
  Polymorphic.OwnProps<typeof AccordionBase.Trigger>;

const AccordionTriggerBase = ({
  children,
  ...props
}: AccordionTriggerProps) => (
  <AccordionHeader>
    <AccordionButton {...props}>{children}</AccordionButton>
  </AccordionHeader>
);

export const AccordionTriggerArrow = styled(IconChevronRight, {
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
});

export const AccordionTrigger = styled(AccordionTriggerBase, {
  padding: "$4",
  height: "$4",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "$md",
  lineHeight: "$base",
  fontWeight: "$semibold",
  '&[data-state="open"]': {
    [`${AccordionTriggerArrow}`]: {
      transform: "rotate(90deg)",
    },
  },
  "&:focus": {
    boxShadow: "none",
  },
});

export const AccordionItem = styled(AccordionBase.Item, {
  ...accordionChild,
  borderBottom: "1px solid $-neutral20",
  "&:last-of-type": {
    borderBottom: "none",
  },
});

export type AccordionProps = React.ComponentProps<typeof Accordion> &
  Polymorphic.OwnProps<typeof AccordionBase.Root>;

export const Accordion = styled(AccordionBase.Root, component);
