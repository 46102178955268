// Pretty sure we used to have the babel polyfill here https://babeljs.io/docs/en/babel-polyfill
// We include it in other parts of the app (can be found with search for "@babel/present-env")

export default function initPolyfills() {
  var ElementPrototype = window.Element.prototype;

  // Polyfill for closest for IE9+ (it needs matches too)
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this;

      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}
