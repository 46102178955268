import React, { useState } from "react";
import ajax from "Blocks/utils/ajax";
import TableButton from "Components/blocks/buttons/TableButton";

export default function ToggleLock({ timesheet }) {
  const [isUnlocked, setIsUnlocked] = useState(timesheet.is_unlocked);
  const timesheetId = timesheet.id;

  const updateTimesheet = () => {
    const data = {
      profile_id: timesheet.profile.id,
      date: timesheet.slug,
      is_unlocked: !isUnlocked,
    };

    ajax({
      method: "PUT",
      path: `/timesheets/${timesheetId}`,
      data,
      success: () => {
        setIsUnlocked(!isUnlocked);
      },
    });
  };

  if (timesheet.is_lockable) {
    return (
      <TableButton
        text={isUnlocked ? "Lock" : "Unlock"}
        action={updateTimesheet}
      />
    );
  }
  return null;
}
