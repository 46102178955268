import React, { useState } from "react";
import ajax from "Blocks/utils/ajax";
import Field from "Components/blocks/forms/Field";
import ProjectSelectGroupLabel from "./ProjectSelectGroupLabel";
import ProjectSelectOption from "./ProjectSelectOption";
import { useGlobalState, addProject } from "../state/GlobalContext.jsx";
import { shouldShowRoleName } from "../util.js";

const ProjectSelector = ({ isDisabled }) => {
  const [projectInput, setProjectInput] = useState(null);
  const { state, dispatch } = useGlobalState();

  // check if project phase is already in state, if so then don't add group to state
  const filteredProjectItems = (projects) =>
    projects.map((project) => {
      // const projectId = project.id;
      const filteredOptions = project.options.filter((group) => {
        const optionPhaseId = group.phaseId;
        const optionRoleId = group.roleId;

        const projectGroups = Object.values(state.projectGroups.byId);

        if (
          projectGroups
            .filter((pg) => pg.phaseId === optionPhaseId)
            .some((g) => Number(g.allocation) === 0 && !g.hasTime)
        ) {
          return true;
        }

        if (
          projectGroups.some(
            (g) => g.phaseId === optionPhaseId && g.roleId == optionRoleId
          )
        ) {
          return false;
        }

        return true;
      });

      const filteredOptionsWithRoleName = filteredOptions.map((option) => {
        const { phaseId } = option;
        const usedProjectGroups = Object.values(state.projectGroups.byId);
        const optionsAndProjectGroups = [
          ...filteredOptions,
          ...usedProjectGroups,
        ];
        return {
          ...option,
          showRoleName:
            shouldShowRoleName(phaseId, optionsAndProjectGroups) &&
            option.roleName,
        };
      });

      const projectItem = {
        ...project,
        options: filteredOptionsWithRoleName,
      };

      return projectItem;
    });

  const loadOptions = (inputValue, callback) => {
    ajax({
      method: "GET",
      path: "/projects/search",
      data: { query: inputValue, timesheet_id: state.timesheet.id },
      success: (result) => {
        const projects = JSON.parse(result);
        const items = filteredProjectItems(projects);
        callback(items);
      },
    });
  };

  const newProjectHandler = (projectData) => {
    // Prevent backspace from firing newProjectHandler
    if (projectData === null) {
      return false;
    }
    setIsFocused(true);
    const { phaseId, projectId, roleId } = projectData;

    const data = {
      timesheet_id: state.timesheet.id,
      project_id: projectId,
      phase_id: Number(phaseId),
      role_id: roleId,
    };
    const reference = state.references[projectId] || React.createRef();
    ajax({
      method: "POST",
      path: "/entry_groups",
      data,
      success: (result) => {
        const { entries, row, group, project } = JSON.parse(result);
        dispatch(
          addProject({
            project,
            group: { ...group, manuallyAdded: true },
            row,
            entries,
            projectId,
            reference,
          })
        );
        handleScroll(projectId, reference);
      },
    });
  };

  const [isFocused, setIsFocused] = useState(false);
  const handleOnBlur = () => {
    setProjectInput(null);
    setIsFocused(false);
    document.activeElement.blur();
  };

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref.current.offsetTop - 156, behavior: "smooth" });
  const handleScroll = (id, reference) => {
    const projectRef = state.references[id] || reference;
    scrollToRef(projectRef);
  };

  return (
    <>
      <Field
        as="search"
        size="small"
        placeholder="Search for Project and Add Phase"
        loadOptions={loadOptions}
        formatGroupLabel={ProjectSelectGroupLabel}
        formatOptionLabel={ProjectSelectOption}
        value={projectInput}
        handleChange={newProjectHandler}
        onBlur={handleOnBlur}
        isDisabled={isDisabled}
        openMenuOnClick={false}
        isClearable
        noSeparator
        focused={isFocused}
      />
    </>
  );
};

export default React.memo(ProjectSelector);
