import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./status.module.css";

export function Status({ color, size }) {
  return (
    <div
      className={cx(
        styles.status,
        { [styles[color]]: color },
        { [styles[size]]: size }
      )}
    />
  );
}

Status.propTypes = {
  color: PropTypes.oneOf(["yellow", "green", "red"]),
  size: PropTypes.oneOf(["small", "medium"]),
};

Status.defaultProps = {
  color: "green",
  size: "medium",
};

export default Status;
