import React from "react";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import { Info } from "Components/blocks/icons";

export function DistributionHeader() {
  const [deleteTooltip, deleteNode] = useTooltip();

  return (
    <h4 className="projects-distribution__title">
      Team Work Distribution
      <span
        ref={deleteNode}
        style={{
          color: "#a0a8ae",
          display: "inline-block",
          marginLeft: ".25rem",
          top: "0.125rem",
          position: "relative",
          height: "16px",
          width: "16px",
        }}
      >
        <Info />
        <Tooltip
          style={{
            maxWidth: "16rem",
          }}
          text="This report displays the time logged by role for active projects with the option to view logged time by hours or by value (hours &times; billable rate)"
          tooltip={deleteTooltip}
        />
      </span>
    </h4>
  );
}

export default DistributionHeader;
