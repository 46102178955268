import React, { SVGProps } from "react";

import { styled } from "@plan/core";

export type PointProps = Omit<
  React.ComponentProps<typeof Square> &
    React.ComponentProps<typeof Circle> & {
      r: number;
      fill: string;
      strokeWidth: number;
      stroke: string;
      x?: number;
      y?: number;
      key?: string | number;
      isHighlighted?: boolean;
      symbolShape?: "circle" | "square" | "rhombus" | "triangle";
    },
  "width" | "height" | "cx" | "cy"
>;

const Styles = {
  cursor: "pointer",
  overflow: "hidden",
  "&:hover": {
    opacity: 1,
    filter: "drop-shadow(0 2px 8px rgba(0,0,0,.075))",
  },
  variants: {
    isHighlighted: {
      true: {
        opacity: 1,
      },
    },
  },
};

const Circle = styled("circle", Styles);
const Square = styled("rect", Styles);
const SVG = styled("svg", Styles);

/**
 * ## Description
 * Point is used to compose Line charts or populate dots. Use Point to display connections on Line chart.
 *
 */
export const Point = React.forwardRef<SVGRectElement, PointProps>(
  ({ key, symbolShape = "circle", x, y, r, ...props }, forwardRef) => {
    let shapeElement;

    switch (symbolShape) {
      case "circle":
        shapeElement = <Circle key={key} cx={x} cy={y} r={r} {...props} />;
        break;
      case "square":
        shapeElement = (
          <Square
            key={key}
            width={r * 2} // simulates the growth of a radius in all directions.
            height={r * 2} // simulates the growth of a radius in all directions.
            x={x}
            y={y}
            style={{
              transform: `translate(${-r}px, ${-r}px)`,
            }}
            {...props}
          />
        );
        break;
      case "rhombus":
        shapeElement = (
          <Square
            key={key}
            width={r * 2}
            height={r * 2}
            x={x}
            y={y}
            style={{
              transformBox: "fill-box",
              transform: `rotate(45deg) translate(${-r}px, ${-r}px)`,
            }}
            {...props}
          />
        );
        break;
      case "triangle":
        shapeElement = x && y && (
          <SVG height={y + r * 2} width={x + r * 2}>
            {/* ts-ignore */}
            <polygon
              key={key}
              points={`${x - r},${y - r} ${x - r},${y + r} ${x + r},${y}`}
              style={{
                fill: props.fill,
              }}
              {...(props as SVGProps<SVGPolygonElement>)}
            />
          </SVG>
        );
        break;
    }

    return <g ref={forwardRef}>{shapeElement}</g>;
  }
);
