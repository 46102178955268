import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";
import { OrgSearch } from "./organizations/Search";
import { ActiveOrganizations } from "./organizations/Table";

export function Organizations() {
  const apolloClient = useApollo({});

  return (
    <ApolloProvider client={apolloClient}>
      <OrgSearch />
      <ActiveOrganizations />
    </ApolloProvider>
  );
}

export default Organizations;
