/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconQuickBooksProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconQuickBooks = ({ label, ...props }: SvgIconQuickBooksProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill="#2CA01C"
      />,
      <path
        d="M3.393 11.928a4.615 4.615 0 0 0 4.616 4.614h.66v-1.713h-.66a2.904 2.904 0 0 1-2.901-2.901c0-1.6 1.302-2.903 2.9-2.903h1.585v8.967c0 .948.768 1.715 1.714 1.715V7.312H8.01a4.616 4.616 0 0 0-4.615 4.616h-.002Zm12.53-4.616h-.66v1.715h.66c1.6 0 2.9 1.302 2.9 2.901a2.904 2.904 0 0 1-2.9 2.901H14.34V5.862c0-.947-.768-1.715-1.714-1.715v12.396h3.3a4.616 4.616 0 0 0 0-9.23h-.004Z"
        fill="#fff"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconQuickBooks;
