import React from "react";

// This is a deprecated component and references should be removed at some point
// Reference permissions/Permissions and the related hook

export default function PermissionCheck({
  children,
  permission,
  value = null,
}) {
  if (permission) {
    return <>{children}</>;
  }
  return <>{value}</>;
}
