import React from "react";
import { motion } from "framer-motion";

export const Download = React.memo(({ loading }) => {
  const variants = {
    default: { y: 0 },
    loading: { y: [0, -4], transition: { yoyo: Infinity, duration: 0.5 } },
  };
  const animate = loading ? "loading" : "default";

  return (
    <motion.svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      style={{ overflow: "visible" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M8.5 0V9M8.5 9L5 5.625M8.5 9L12 5.625"
        stroke="#6b7280"
        animate={animate}
        variants={variants}
      />
      <path d="M1 10L1 14L16 14L16 10" stroke="#4B5563" />
    </motion.svg>
  );
});

export default React.memo(Download);
