import React, { useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import max from "lodash/max";
import { useAllocations, useTeamOrder, useGridView } from "../../state/Store";
import ProjectCard from "./project/ProjectCard";
import EmptyCard from "./EmptyCard";

function useCardSwitch({ phase, allEntries, selectedPhase }) {
  const allocations = useAllocations();
  const gridView = useGridView();
  const teamOrder = useTeamOrder();

  const entries = useMemo(() =>
    allEntries.filter((entry) => allocations[entry].phaseId === phase)
  );

  const calcMaxNumberOfAllocations = (phase) => {
    const allocationsForPhase = Object.keys(allocations).filter(
      (id) => allocations[id].phaseId === phase
    );
    const numberOfAllocationsByProfile = teamOrder.map((id) =>
      allocationsForPhase.reduce((acc, alloc) => {
        if (allocations[alloc].profileId === id) return acc + 1;
        return acc;
      }, 0)
    );
    return max(numberOfAllocationsByProfile);
  };

  const maxNumberOfAllocations = useMemo(
    () => calcMaxNumberOfAllocations(phase),
    [calcMaxNumberOfAllocations]
  );

  const calcRowHeight = (phase, selected) => {
    // Would be great to dynamically grab these heights at some point
    if (gridView === "row" && selected !== phase)
      return 93 * maxNumberOfAllocations;
    if (selected === phase) return 240 * maxNumberOfAllocations;
    return 0;
  };

  const numberOfAllocationsForProfile = (id, phaseId) => {
    const allocationsForPhase = Object.keys(allocations).filter(
      (id) => allocations[id].phaseId === phaseId
    );
    return allocationsForPhase.reduce((acc, alloc) => {
      if (allocations[alloc].profileId === id) return acc + 1;
      return acc;
    }, 0);
  };

  const rowHeight = useMemo(
    () => calcRowHeight(phase, selectedPhase),
    [
      phase,
      allocations,
      selectedPhase,
      calcRowHeight,
      teamOrder,
      numberOfAllocationsForProfile,
    ]
  );

  return { entries, rowHeight, numberOfAllocationsForProfile };
}

const CardSwitch = React.memo(
  ({ allEntries, phase, allocations, phases, roles, selectedPhase }) => {
    const grid = useGridView();
    const { entries, rowHeight, numberOfAllocationsForProfile } = useCardSwitch(
      {
        phase,
        allEntries,
        allocations,
        selectedPhase,
      }
    );

    if (grid === "row" && rowHeight === 0) return <></>;

    if (!entries.length)
      return <EmptyCard id={phase} key={phase} height={rowHeight} />;

    return entries.map((entry) => {
      const { phaseId, roleId, profileId, added, allocatedByUser, allocation } =
        allocations[entry];
      const { name, abbreviation, color, number, projectSlug } =
        phases[phaseId];

      const height =
        rowHeight / numberOfAllocationsForProfile(profileId, phaseId);

      return (
        <AnimatePresence key={entry}>
          {entry && (
            <ProjectCard
              key={entry}
              id={entry}
              phaseId={phaseId}
              projectSlug={projectSlug}
              number={number}
              name={name}
              abbrev={abbreviation}
              color={color}
              role={roles[roleId]}
              allocation={allocation}
              allocatedByUser={allocatedByUser}
              consumed={Number(allocations[entry].hours)}
              added={added}
              height={height}
            />
          )}
        </AnimatePresence>
      );
    });
  }
);

export default CardSwitch;
