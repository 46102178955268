import * as React from "react";

import { styled } from "@plan/core";

const StyledFooter = styled("div", {
  display: "inline-block",
  lineHeight: "$tight",
  // FIXME (BREAKING CHANGE)
  // Amend `rem` value to closest $space key (or calc value) on first refactor
  maxWidth: "30rem",
  variants: {
    bold: { true: { fontWeight: "$bold" } },
    semibold: { true: { fontWeight: "$semibold" } },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    colorScheme: {
      brand: { color: "$colors$brand" },
      success: { color: "$colors$success" },
      danger: { color: "$colors$danger" },
      muted: { color: "$colors$neutral10" },
    },
  },
  defaultVariants: {
    align: "left",
    bold: "true",
  },
});

export type FooterProps = React.ComponentProps<typeof StyledFooter> & {
  value: React.ReactNode;
  // FIXME: drop on first refactor
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  css?: any;
};

export const Footer: React.FC<FooterProps> = ({
  value = undefined,
  bold = true,
  align = "left",
  colorScheme,
  ...props
}) => {
  return (
    <StyledFooter
      align={align}
      bold={bold}
      colorScheme={colorScheme}
      {...props}
    >
      {value}
    </StyledFooter>
  );
};
