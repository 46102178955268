import { ComponentProps, FC } from "react";

import { styled } from "@plan/core";
import { Tooltip, TooltipContent, TooltipTrigger } from "@plan/components";

import { asDate, DateDisplay, DateDisplayFormatters } from "../DateDisplay";

import { differenceInDays, isBefore } from "date-fns";

const Span = styled("span", {
  variants: {
    empty: { true: { color: "$colors$-neutral10" } },
    pastDue: { true: { color: "$colors$danger" } },
  },
});

export type DueDateProps = {
  date?: Date | number | string;
  dateDisplayProps?: ComponentProps<typeof DateDisplay>;
  compareTo?: Date;
  format?: DateDisplayFormatters;
  tooltipContentProps?: ComponentProps<typeof TooltipContent>;
  isPaid?: boolean;
};

export const DueDate: FC<DueDateProps> = ({
  date,
  dateDisplayProps,
  format = "calendarDate",
  compareTo = new Date(),
  tooltipContentProps,
  isPaid,
}) => {
  if (!date) return <Span empty>--</Span>;

  const dateInstance = asDate(date);

  const isPastDue = isBefore(dateInstance, compareTo),
    difference = differenceInDays(compareTo, dateInstance);

  const dateFragment = (
    <DateDisplay value={dateInstance} format={format} {...dateDisplayProps} />
  );

  return isPastDue && !isPaid ? (
    <Tooltip>
      <TooltipTrigger asChild>
        <Span pastDue>
          {difference} {pluralize("day", difference)} past due
        </Span>
      </TooltipTrigger>
      <TooltipContent {...tooltipContentProps}>{dateFragment}</TooltipContent>
    </Tooltip>
  ) : (
    dateFragment
  );
};

const pluralize = (word: string, count: number): string =>
  count === 1 ? word : `${word}s`;
