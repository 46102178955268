import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Hook to manage the state for tabs
export function useTabs(initialTab) {
  const [currentTab, setCurrentTab] = useState(initialTab);
  return [currentTab, setCurrentTab];
}

// Checks the current tab state and returns the panel
export const TabPanel = ({ name, children, currentTab, animated }) => {
  if (currentTab !== name) return null;
  if (animated)
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={name}
          style={{
            transformOrigin: "top center",
            visibility: currentTab !== name ? "hidden" : "visible",
          }}
          initial={{ opacity: 0.75 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.75 }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    );

  return <>{children}</>;
};

// Collection of Tabs
export const TabBar = ({ children }) => {
  return <div className="segment filter">{children}</div>;
};

// Provides navigation between tabs / tab state
export const Tab = ({ name, currentTab, onTabSelect, children, isSegment }) => {
  const isCurrentTab = currentTab === name;
  const handleTabSelect = () => onTabSelect(name);

  return (
    <button
      className={`${isSegment ? "segment__option" : ""}${
        isCurrentTab ? " is-selected" : ""
      }`}
      onClick={handleTabSelect}
      onKeyDown={handleTabSelect}
      role="tab"
      type="button"
      aria-selected={isCurrentTab}
      aria-controls={name}
    >
      {children}
    </button>
  );
};
