import * as React from "react";

import { createComponent, styled } from "@plan/core";
import { AutoLayout, AutoLayoutProps } from "@plan/layout";

import { useField } from "../Field";

import { useId } from "@radix-ui/react-id";
import * as SwitchBase from "@radix-ui/react-switch";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

enum vars {
  backgroundColor = "$$switchBackgroundColor",
  borderColor = "$$switchBorderColor",
  color = "$$switchColor",
  fontSize = "$$switchFontSize",
  lineHeight = "$$switchLineHeight",
  thumbBackgroundColor = "$$switchThumbBackgroundColor",
}

const component = createComponent();

const LabelText = styled("span", {
  ...component,
  color: vars.color,
  lineHeight: vars.lineHeight,
  fontSize: vars.fontSize,
  fontWeight: "$normal",
  userSelect: "none",
});

const Container = styled(SwitchBase.Root, {
  ...component,
  [`&, & ~ *`]: {
    [vars.backgroundColor]: "$colors$-neutral40",
    [vars.borderColor]: "$colors$transparent",
    [vars.color]: "$colors$text",
    [vars.fontSize]: "$fontSizes$lg",
    [vars.lineHeight]: "$lineHeights$tight",
    [vars.thumbBackgroundColor]: "$colors$white",
  },
  $$width: "$space$11",
  $$height: "$sizes$md",
  appearance: "none",
  padding: 0,
  width: "$$width",
  height: "$$height",
  backgroundColor: vars.backgroundColor,
  border: "1px solid",
  borderColor: vars.borderColor,
  borderRadius: "$$height",
  position: "relative",
  cursor: "pointer",
  "&:not([disabled])": {
    '&:not([data-state="checked"]):hover': {
      [vars.backgroundColor]: "$colors$-brand30",
    },
    "&:focus": {
      ring: "$colors$-brand30",
    },
  },
  "&[disabled]": {
    [`&, & ~ *`]: {
      [vars.color]: "$colors$-neutral10",
      [vars.thumbBackgroundColor]: "$colors$-neutral30",
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  },
  '&[data-state="checked"]': {
    backgroundColor: "$colors$brand",
    "&[disabled]": {
      backgroundColor: "$colors$-brand30",
    },
  },
  variants: {
    ...component.variants,
    invalid: {
      false: {},
      true: {
        [`&, & ~ *`]: {
          [vars.borderColor]: "$colors$danger",
        },
      },
    },
    dark: {
      false: {},
      true: {
        [`&, & ~ *`]: {
          [vars.backgroundColor]: "$colors$neutral",
        },
      },
    },
  },
});

const Thumb = styled(SwitchBase.Thumb, {
  ...component,
  display: "block",
  width: "$sizes$sm",
  height: "$sizes$sm",
  backgroundColor: vars.thumbBackgroundColor,
  borderRadius: "$radii$round",
  transition: "transform 200ms", // Move to theme?
  transform: "translateX(1px)",
  willChange: "transform",
  '&[data-state="checked"]': {
    transform: "translateX(calc($$width / 2))",
  },
});

export interface SwitchProps
  extends Pick<
      AutoLayoutProps,
      "css" | "className" | "resizingX" | "resizingY"
    >,
    React.ComponentProps<typeof Container> {
  label: React.ReactNode;
  showLabel?: boolean;
  invalid?: boolean;
}

export const Switch = ({
  showLabel = true,
  label,
  css,
  className,
  disabled,
  id: deterministicId,
  invalid,
  resizingX,
  resizingY,
  ...props
}: SwitchProps) => {
  const id = useId(deterministicId);

  const { getInputProps } = useField("fieldset");
  const inputProps = getInputProps({ disabled, invalid });

  return (
    <AutoLayout
      as="label"
      direction="horizontal"
      spacing="2_5"
      alignment="left"
      htmlFor={id}
      css={css}
      className={className}
      resizingX={resizingX}
      resizingY={resizingY}
    >
      <Container id={id} {...inputProps} {...props}>
        <Thumb />
      </Container>
      {showLabel ? (
        <LabelText>{label}</LabelText>
      ) : (
        <VisuallyHidden.Root>{label}</VisuallyHidden.Root>
      )}
    </AutoLayout>
  );
};

export type SwitchGroupProps = Omit<
  AutoLayoutProps,
  "direction" | "spacing" | "resizingX" | "resizingY"
>;
export const SwitchGroup = React.forwardRef<HTMLDivElement, SwitchGroupProps>(
  (props, ref) => (
    <AutoLayout
      ref={ref}
      role="group"
      direction="vertical"
      spacing="2"
      {...props}
    />
  )
);
