import { ComponentType } from "react";

import { createComponent, styled } from "@plan/core";
import { Icon } from "@plan/icons";

import { SpinnerCircle, SpinnerSvg } from "../Spinner/spinner.css";

const component = createComponent();

export type ButtonProps = ComponentType<typeof Button>;

export const Button = styled("button", {
  ...component,
  $$buttonBorderWidth: "1px",
  $$buttonIconColor: "currentColor",
  $$buttonPaddingY: "calc(calc($$buttonSize - $$buttonFontSize) * 0.5)",
  appearance: "none",
  alignItems: "center",
  backgroundColor: "$$buttonBackgroundColor",
  border: "$$buttonBorderWidth solid $$buttonBorderColor",
  borderRadius: "$radii$default",
  color: "$$buttonColor",
  cursor: "pointer",
  display: "inline-grid",
  fontSize: "$$buttonFontSize",
  fontWeight: "$semibold",
  gap: "$$buttonGap",
  gridAutoFlow: "column",
  justifyContent: "center",
  justifyItems: "center",
  lineHeight: 1,
  padding:
    "calc($$buttonPaddingY - $$buttonBorderWidth) calc($$buttonPaddingX - $$buttonBorderWidth)",
  textDecoration: "none",
  "&:not([disabled])": {
    "&:hover": {
      backgroundColor: "$$buttonBackgroundColorHover",
    },
    "&:active": {
      backgroundColor: "$$buttonBackgroundColorActive",
    },
    "&:focus": {
      ring: "$$buttonRingColor",
    },
  },
  "&[disabled]": {
    pointerEvents: "none",
    opacity: 0.4,
  },
  // for icons not from @plan/icons, and <Spinner />
  [`> svg:not(${Icon}), ${SpinnerSvg}`]: {
    height: "$$buttonIconSize",
    width: "$$buttonIconSize",
    path: {
      fill: "$$buttonIconColor",
    },
    [`${SpinnerCircle}`]: {
      stroke: "$$buttonIconColor",
    },
  },
  // for icons from @plan/icons
  [`${Icon}`]: {
    color: "$$buttonIconColor",
    fontSize: "$$buttonIconSize",
  },
  "@motionSafe": {
    transition: "0.2s ease",
    transitionProperty: "background-color, border-color, color",
  },
  variants: {
    ...component.variants,
    intent: {
      primary: {
        $$buttonRingColor: "$colors$-brand30",
      },
      secondary: {
        $$buttonIconColor: "$colors$neutral10",
        $$buttonRingColor: "$colors$-neutral30",
        "&:hover": {
          border: "1px solid $colors$neutral40",
          color: "$colors$neutral40",
        },
      },
      success: {
        $$buttonRingColor: "$colors$-success30",
      },
      warning: {
        $$buttonRingColor: "$colors$-warning30",
      },
      danger: {
        $$buttonRingColor: "$colors$-danger30",
      },
    },
    size: {
      xs: {
        $$buttonFontSize: "$fontSizes$xs",
        $$buttonGap: "$space$1",
        $$buttonIconSize: "$space$2",
        $$buttonPaddingX: "$space$1",
        $$buttonSize: "$sizes$xs",
      },
      sm: {
        $$buttonFontSize: "$fontSizes$sm",
        $$buttonGap: "$space$1",
        $$buttonIconSize: "$space$2_5",
        $$buttonPaddingX: "$space$1_5",
        $$buttonSize: "$sizes$sm",
      },
      md: {
        $$buttonFontSize: "$fontSizes$md",
        $$buttonGap: "$space$1_5",
        $$buttonIconSize: "$space$3_5",
        $$buttonPaddingX: "$space$2",
        $$buttonSize: "$sizes$md",
      },
      lg: {
        $$buttonFontSize: "$fontSizes$md",
        $$buttonGap: "$space$2",
        $$buttonIconSize: "$space$3_5",
        $$buttonPaddingX: "$space$2_5",
        $$buttonSize: "$sizes$lg",
      },
      xl: {
        $$buttonFontSize: "$fontSizes$lg",
        $$buttonGap: "$space$2",
        $$buttonIconSize: "$space$4",
        $$buttonPaddingX: "$space$3",
        $$buttonSize: "$sizes$xl",
      },
    },
    variant: {
      solid: {},
      ghost: {
        $$buttonBackgroundColor: "$colors$transparent",
        $$buttonBorderColor: "$colors$transparent",
      },
    },
    shape: {
      rectangle: {},
      square: {
        height: "$$buttonSize",
        justifyItems: "center",
        padding: 0,
        width: "$$buttonSize",
      },
    },
    width: {
      full: {
        width: "100%",
      },
    },
  },
  compoundVariants: [
    ...component.compoundVariants,
    /**
     * Solid × Intent
     */
    {
      intent: "primary",
      variant: "solid",
      css: {
        $$buttonBackgroundColor: "$colors$brand",
        $$buttonBackgroundColorActive: "$colors$brand20",
        $$buttonBackgroundColorHover: "$colors$brand10",
        $$buttonBorderColor: "$colors$transparent",
        $$buttonColor: "$colors$white",
      },
    },
    {
      intent: "secondary",
      variant: "solid",
      css: {
        $$buttonBackgroundColor: "$colors$white",
        $$buttonBackgroundColorActive: "$colors$-neutral40",
        $$buttonBackgroundColorHover: "$colors$-neutral50",
        $$buttonBorderColor: "$colors$-neutral10",
        $$buttonColor: "$colors$neutral10",
      },
    },
    {
      intent: "success",
      variant: "solid",
      css: {
        $$buttonBackgroundColor: "$colors$success",
        $$buttonBackgroundColorActive: "$colors$success20",
        $$buttonBackgroundColorHover: "$colors$success10",
        $$buttonBorderColor: "$colors$transparent",
        $$buttonColor: "$colors$white",
      },
    },
    {
      intent: "warning",
      variant: "solid",
      css: {
        $$buttonBackgroundColor: "$colors$warning",
        $$buttonBackgroundColorActive: "$colors$warning20",
        $$buttonBackgroundColorHover: "$colors$warning10",
        $$buttonBorderColor: "$colors$transparent",
        $$buttonColor: "$colors$black",
      },
    },
    {
      intent: "danger",
      variant: "solid",
      css: {
        $$buttonBackgroundColor: "$colors$danger",
        $$buttonBackgroundColorActive: "$colors$danger20",
        $$buttonBackgroundColorHover: "$colors$danger10",
        $$buttonBorderColor: "$colors$transparent",
        $$buttonColor: "$colors$white",
      },
    },
    /**
     * Ghost × Intent
     */
    {
      intent: "primary",
      variant: "ghost",
      css: {
        $$buttonBackgroundColorActive: "$colors$-brand30",
        $$buttonBackgroundColorHover: "$colors$-brand40",
        $$buttonColor: "$colors$brand",
      },
    },
    {
      intent: "secondary",
      variant: "ghost",
      css: {
        $$buttonBackgroundColorActive: "$colors$-neutral30",
        $$buttonBackgroundColorHover: "$colors$-neutral40",
        $$buttonColor: "$colors$black",
      },
    },
    {
      intent: "success",
      variant: "ghost",
      css: {
        $$buttonBackgroundColorActive: "$colors$-success30",
        $$buttonBackgroundColorHover: "$colors$-success40",
        $$buttonColor: "$colors$success",
      },
    },
    {
      intent: "warning",
      variant: "ghost",
      css: {
        $$buttonBackgroundColorActive: "$colors$-warning40",
        $$buttonBackgroundColorHover: "$colors$-warning50",
        $$buttonColor: "$colors$warning10",
      },
    },
    {
      intent: "danger",
      variant: "ghost",
      css: {
        $$buttonBackgroundColorActive: "$colors$-danger30",
        $$buttonBackgroundColorHover: "$colors$-danger40",
        $$buttonColor: "$colors$danger",
      },
    },
  ],
  defaultVariants: {
    ...component.defaultVariants,
    shape: "rectangle",
    size: "xl",
    intent: "primary",
    variant: "solid",
  },
});
