import React from "react";
import { ApolloProvider, gql, useQuery } from "@apollo/client";
import { Badge } from "@plan";
import { useApollo } from "@hooks/useApollo";
import classNames from "classnames/bind";
import styles from "./TeamCompensation.module.css";

const cx = classNames.bind(styles);

const WarningIndicator = ({ isBadge }) => {
  const GET_ONBOARD_STATS = gql`
    query payrollOnboardStats {
      payrollOnboardStats {
        organization {
          remainingSteps
        }
        worker {
          remainingSteps
        }
      }
    }
  `;

  const { data } = useQuery(GET_ONBOARD_STATS);

  const workerRemainingSteps =
    data?.payrollOnboardStats?.worker?.remainingSteps || 0;
  const organizationRemainingSteps =
    data?.payrollOnboardStats?.organization?.remainingSteps || 0;

  if (organizationRemainingSteps === 0 && workerRemainingSteps === 0)
    return null;

  return isBadge ? (
    <Badge
      size="small"
      style={{ marginLeft: "0.5rem" }}
      color="var(--color-brand)"
    >
      {workerRemainingSteps + organizationRemainingSteps}
    </Badge>
  ) : (
    <span className={cx("dot-highlight")} />
  );
};

export const PayrollOnboardWarning = ({ isBadge }) => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <WarningIndicator isBadge={isBadge} />
    </ApolloProvider>
  );
};

export default React.memo(PayrollOnboardWarning);
