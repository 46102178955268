import React, { useEffect } from "react";
import { useAction } from "Hooks";
import styles from "./deletemodal.module.css";

// Display the warning Checkboxes
export function DeleteWarnings({ data, noDataText }) {
  const isData = data.length;
  return (
    <div className={styles.deleteWarnings}>
      {!isData && <span className={styles.deleteNoWarnings}>{noDataText}</span>}
      {!!isData &&
        data.map((item, i) => (
          <div key={i} className={styles.deleteWarningsItem}>
            <div className={styles.deleteItemBullet} />
            {item}
          </div>
        ))}
    </div>
  );
}

// Require the user to check all warnings and type to match a phrase
export const DeleteConfirmation = ({
  id,
  fetchAction,
  noDataText,
  setLoading,
}) => {
  const [{ loading, data, error }, deleteWarnings] = useAction(fetchAction);

  // Grab the delete warning data on mount
  useEffect(() => {
    deleteWarnings(id);
  }, []);

  useEffect(() => {
    // set loading to false
    setLoading(false);
  }, [data]);

  if (loading || !data) return <p>Loading...</p>;
  if (error) return <p>There was an error pulling related data</p>;

  return (
    <>
      <DeleteWarnings data={data} noDataText={noDataText} />
    </>
  );
};

// Associated hook for the logic this should be used in the parent component
// and the confirmations object should be passed to the DeleteWarnings component
export function useDeleteConfirmation({ actions, deleteAction, setLoading }) {
  const onSubmit = async (e) => {
    e.preventDefault();
    // set loading to true to prevent multiple requests
    setLoading(true);

    const onError = () => {
      setLoading(false);
      actions.close();
    };

    return deleteAction({ onError });
  };

  return [onSubmit];
}

export default DeleteWarnings;
