import React from "react";
import Modal from "Components/blocks/Modal";
import Address from "Components/blocks/Address";
import Field from "Components/blocks/Field";

export default class ClientModal extends Modal {
  constructor(props) {
    super(props);

    this.updateClientState = this.updateClientState.bind(this);
    this.createClient = this.createClient.bind(this);
    this.updateClient = this.updateClient.bind(this);

    this.state = {
      address: this.props.clientAddress,
      client: this.props.client || {
        fname: "",
        lname: "",
        organization_name: "",
        department: "",
      },
      editable: false,
    };
  }

  // This updates the data in the modal if the client is swapped
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.action === "edit") {
      if (
        this.props.clientAddress !== prevProps.clientAddress ||
        this.props.client !== prevProps.client
      ) {
        this.setState({
          client: this.props.client,
          address: this.props.clientAddress,
        });
      }
    }
  }

  updateClientState(data) {
    this.setState((prevState) => ({
      client: {
        ...prevState.client,
        ...data,
      },
    }));
  }

  createClient() {
    const data = {
      ...this.state,
      address: this.refs.addressComponent.state.address,
    };

    this.props.createClient(data);

    // Reset create client modal
    this.setState({
      address: {},
      client: {},
    });
  }

  updateClient() {
    const data = {
      ...this.state,
      address: this.refs.addressComponent.state.address,
    };

    this.props.updateClient(data);
  }

  renderButtons() {
    if (this.state.editable) {
      return (
        <>
          <button
            className="modal-button modal-button--red-fill"
            onClick={this.props.deleteClient}
          >
            Delete
          </button>
          <button
            className="modal-button modal-button--gray-outline"
            onClick={() => this.setState({ editable: false })}
          >
            Cancel
          </button>
        </>
      );
    }
    if (this.props.action === "create") {
      return (
        <button
          className="modal-button modal-button--primary-fill"
          onClick={this.createClient}
        >
          Save
        </button>
      );
    }
    if (this.props.action === "edit") {
      return (
        <>
          <button
            className="modal-button modal-button--primary-fill"
            onClick={this.updateClient}
          >
            Save
          </button>
          <button
            className="modal-button modal-button--red-fill"
            onClick={() => this.setState({ editable: true })}
          >
            Delete
          </button>
        </>
      );
    }
  }

  renderContent() {
    return (
      <>
        <div className="grid">
          <div className="grid-xs-12 grid-sm-5 mb-16">
            <Field
              label="First Name"
              keyName="fname"
              selectedValue={this.state.client.fname}
              onChange={this.updateClientState}
            />
          </div>
          <div className="grid-xs-12 grid-sm-7 mb-16">
            <Field
              label="Last Name"
              keyName="lname"
              selectedValue={this.state.client.lname}
              onChange={this.updateClientState}
            />
          </div>
          <div className="grid-xs-12 mb-16">
            <Field
              label="Organization Name"
              keyName="organization_name"
              selectedValue={this.state.client.organization_name}
              onChange={this.updateClientState}
            />
          </div>
          <div className="grid-xs-12 mb-16">
            <Field
              label="Department Name"
              keyName="department"
              selectedValue={this.state.client.department}
              onChange={this.updateClientState}
            />
          </div>
        </div>

        <Address
          manualUpdate
          ref="addressComponent"
          address={this.props.clientAddress}
        />

        {this.state.editable ? (
          <div className="modal__delete-warning">
            Are you sure you want to delete this client?
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
