// hooks.js
import { useState } from "react";
import { renderError } from "Blocks/alert";

export const useAction = (action, config = { renderErrors: true }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // The incoming "action" argument to the hook is NOT performed.
  // It is only stored in the function scope; so that, we can use it when
  // performing the action using the following function
  // This function is returned as the second element in the returned array
  const performAction = async (...args) => {
    try {
      setLoading(true);
      setData(null);
      setError(null);
      const res = await action(...args);
      setData(res.data);
      return res.data;
    } catch (err) {
      setError(err);
      // Shows the 'something went wrong, refresh the page' banner
      if (config?.renderErrors) renderError();
      throw new Error(err);
    } finally {
      setLoading(false);
    }
  };

  const resetError = () => setError(null);

  return [{ loading, data, error, resetError }, performAction];
};

export default useAction;
