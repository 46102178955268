import React from "react";
import cx from "classnames";
import styles from "./skeleton.module.css";

// Add circle variant
export const Skeleton = ({
  width = "100%",
  height = "100%",
  marginBottom = ".5rem",
  background = "var(--color-legacy-gray-1)",
  style,
  animated = true,
}) => (
  <div
    className={cx(styles.loadingSkeleton, { [styles.animated]: animated })}
    style={{
      width,
      height,
      marginBottom,
      "--background": background,
      ...style,
    }}
  />
);

export default Skeleton;
