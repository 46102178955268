import { keyframes, styled } from "@plan/core";

const timerRotating = keyframes({
  from: { transform: "rotate(0deg)" },
  to: { transform: "rotate(360deg)" },
});

export const TimerButtonCircleGraphic = styled("div", {
  border: "2px solid $colors$danger",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  borderRadius: "$space$10",
  "&::after": {
    content: '""',
    position: "absolute",
    top: "-3px",
    left: "-3px",
    width: "calc(50% + 3px)",
    height: "50%",
    backgroundColor: "$colors$neutral40",
  },
  variants: {
    isActive: {
      true: {
        animation: `${timerRotating} 9s linear infinite`,
      },
    },
    large: {
      true: {
        border: "3px solid $colors$danger",
        "&::after": {
          backgroundColor: "$white",
        },
      },
    },
  },
});
