import { FC, PropsWithChildren, useReducer, useState } from "react";

import { TimerEntryFragment } from "../../../../../apps/web/packages/app/generated/graphql";
import { eventTracker } from "../../../../../apps/web/packages/app/lib/analytics/tracker.js";
import {
  TimerContext,
  TimerContextActions,
  TimerContextState,
} from "../contexts/TimerContext";
import {
  getLostTimeEntryHours,
  getRecordingDisplayTime,
} from "../helpers/timeHelpers";
import { useCreateTimerEntry } from "../hooks/useCreateTimerEntry";
import { useIsSuperAdmin } from "../hooks/useIsSuperAdmin";
import { useSyncTimerToLocalStorage } from "../hooks/useSyncTimerToLocalStorage";
import { useTimerTickUpdate } from "../hooks/useTimerTickUpdate";
import { useUpdateTimerEntry } from "../hooks/useUpdateTimerEntry";
import {
  timerReducer,
  useTimerReducerInitialState,
} from "../reducers/TimerReducer";

export const TimerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [timerReducerState, timerDispatch] = useReducer(
    timerReducer,
    useTimerReducerInitialState()
  );

  useSyncTimerToLocalStorage(timerReducerState);

  const { recordingStartTime, entry, isTimerModalVisible, lostTimeMs } =
    timerReducerState;
  const isRecording = !!recordingStartTime;

  const [recordingDisplayTime, setRecordingDisplayTime] = useState(
    getRecordingDisplayTime(entry, recordingStartTime)
  );

  // This callback will fire if someone is all three of the following:
  // 1) Logged in as someone else
  // 2) Using a non-demo profile
  // 3) On production
  // In other words, it prevents someone who works here from accidentally logging time to an actual org
  useIsSuperAdmin(() => {
    resetTimer();
  });

  // This callback will fire every hundredth of a minute/17000ms
  useTimerTickUpdate(() => {
    setRecordingDisplayTime(getRecordingDisplayTime(entry, recordingStartTime));
    if (recordingStartTime) {
      timerDispatch({ type: "UPDATE_LAST_RECORDING_AT" });
    }
  });

  const createTimerEntry = useCreateTimerEntry({
    successCallback: (newEntry) => {
      timerDispatch({ type: "SELECT_ENTRY_AND_START", payload: newEntry });
      setRecordingDisplayTime(getRecordingDisplayTime(newEntry, Date.now()));
      eventTracker("trackTimerStarted");
      toggleTimerModal();
    },
  });

  const updateTimerEntry = useUpdateTimerEntry({
    successCallback: (newEntry) => {
      selectTimerEntry(newEntry);
    },
    errorCallback: () => {
      clearTimerEntry();
    },
  });

  const acceptLostTime = () => {
    timerDispatch({ type: "HIDE_LOST_TIME_WARNING" });
  };

  const rejectLostTime = async () => {
    timerDispatch({ type: "HIDE_LOST_TIME_WARNING" });
    if (!entry || !lostTimeMs || !recordingStartTime) return;

    await updateTimerEntry({
      entryId: entry.id,
      hours: getLostTimeEntryHours(entry, lostTimeMs, recordingStartTime),
    });
    resetTimer();
  };

  const startTimer = () => {
    if (!entry) return;
    timerDispatch({ type: "START" });
    eventTracker("trackTimerStarted");
  };

  const stopTimer = async () => {
    timerDispatch({ type: "STOP" });
    if (!entry) return false;

    await updateTimerEntry({
      entryId: entry.id,
      hours: parseFloat(recordingDisplayTime),
    });
    eventTracker("trackTimerStopped");
    return true;
  };

  const resetTimerTime = () => {
    if (!entry) return;
    timerDispatch({ type: "RESET_TIME" });
    updateTimerEntry({ entryId: entry.id, hours: 0 });
  };

  const selectTimerEntry = (entry: TimerEntryFragment) => {
    timerDispatch({ type: "SELECT_ENTRY", payload: entry });
    setRecordingDisplayTime(getRecordingDisplayTime(entry, recordingStartTime));
  };

  const clearTimerEntry = () => timerDispatch({ type: "CLEAR_ENTRY" });
  const resetTimer = () => timerDispatch({ type: "RESET_EVERYTHING" });
  const showTimerModal = () => timerDispatch({ type: "SHOW_MODAL" });
  const hideTimerModal = () => timerDispatch({ type: "HIDE_MODAL" });

  const toggleTimerModal = () =>
    isTimerModalVisible ? hideTimerModal() : showTimerModal();
  const toggleTimerRecording = () => (isRecording ? stopTimer() : startTimer());

  const timerState: TimerContextState = {
    isRecording,
    recordingDisplayTime,
    entry,
    isTimerModalVisible,
    lostTimeTotal: lostTimeMs,
  };

  const timerActions: TimerContextActions = {
    startTimer,
    stopTimer,
    clearTimerEntry,
    selectTimerEntry,
    resetTimerTime,
    resetTimer,
    toggleTimerModal,
    hideTimerModal,
    acceptLostTime,
    rejectLostTime,
    showTimerModal,
    toggleTimerRecording,
    createTimerEntry,
    updateTimerEntry,
  };

  return (
    <TimerContext.Provider value={{ state: timerState, actions: timerActions }}>
      {children}
    </TimerContext.Provider>
  );
};
