import { useRef, useLayoutEffect, useState } from "react";

export const useBoundingBox = () => {
  const ref = useRef();
  const [bbox, setBbox] = useState({});
  const [client, setClient] = useState({});

  const set = () => {
    if (ref && ref.current) setBbox(ref.current.getBoundingClientRect());
    setClient({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useLayoutEffect(() => {
    if (window) set();
    window.addEventListener("resize", set);
    return () => window.removeEventListener("resize", set);
  }, []);

  useLayoutEffect(() => {
    if (ref && ref.current) setBbox(ref.current.getBoundingClientRect());
  }, [ref, ref.current]);

  return [ref, bbox, client];
};

export default useBoundingBox;
