/**
 * INTERNAL CONSTANTS, NOT TO BE EXPORTED
 */
export const FORM_SIZES = {
  sm: {
    maxWidth: "11.25rem", // 180px
  },
  md: {
    maxWidth: "26.25rem", // 420px
  },
  lg: {
    maxWidth: "46.75rem", // 748px
  },
} as const;
