class Route {
  constructor() {
    this.firstLoad = true;
  }
  initialLoad() {}
  load() {}
  beforeCache() {}

  _load() {
    if (this.firstLoad) {
      this.initialLoad();
    }
    this.firstLoad = false;
    this.load();
  }

  startSave() {
    const saveButton = $(".js-save")[0];
    if (saveButton) {
      saveButton.classList.add("is-saving");
      saveButton.innerHTML = "Saving...";
    }
  }

  endSave() {
    const saveButton = $(".js-save")[0];
    if (saveButton) {
      setTimeout(() => {
        saveButton.classList.remove("is-saving");
        saveButton.innerHTML = "Changes Saved!";
      }, 1000);
    }
  }
}

export default Route;
