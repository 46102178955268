import React from "react";
import TimeOffHolidayEntries from "./TimeOffHolidayEntries";
import RowTotals from "../RowTotals";
import styles from "../overhead/overhead.module.css";

function TimeOffHolidayRow({ holidays }) {
  const totalHours = holidays.reduce(
    (sum, { hours }) => sum + parseFloat(hours),
    0
  );

  return (
    <>
      <TimeOffHolidayEntries holidays={holidays} />
      <RowTotals hours={Math.round(totalHours)} showExpense={false} />
      <div className={styles.rowViewDeleteColumn} />
    </>
  );
}

export default TimeOffHolidayRow;
