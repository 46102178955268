import React from "react";
import { Card } from "@plan";
import { AddConsultant } from "./AddConsultant";
import styles from "../consultants.module.css";

const ConsultantsList = ({ consultants = [] }) => {
  // Smooth scroll to the related consultant in the list
  const handleClick = (id) => {
    const elementPosition = document
      .querySelector(`[data-consultant-id='${id}']`)
      .getBoundingClientRect().top;
    const offsetPosition = elementPosition;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return consultants.map((consultant) => (
    <div className={styles.miniMapItem} key={consultant.id}>
      <div onClick={() => handleClick(consultant.id)}>{consultant.name}</div>
    </div>
  ));
};

const NoConsultants = () => (
  <p className={styles.instructions}>
    Add a consultant using the input above to start planning consultant work on
    this project.
  </p>
);

export function SideBar({ consultants = [] }) {
  return (
    <Card classes={[styles.sideBar]}>
      <AddConsultant />
      <div className={styles.miniMap}>
        {!consultants.length ? (
          <NoConsultants />
        ) : (
          <ConsultantsList consultants={consultants} />
        )}
      </div>
    </Card>
  );
}
