import React from "react";

export const Minus = () => {
  return (
    <svg
      width="7"
      height="1"
      viewBox="0 0 7 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7" height="1" />
    </svg>
  );
};

export default Minus;
