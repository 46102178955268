import util from "Blocks/utils/util";
import React, { useState, useCallback } from "react";
import Field from "Components/blocks/Field";
import numeral from "numeral";
import { debounce } from "debounce";
import ajax from "Blocks/utils/ajax";

function AccountingRoles(props) {
  const [parent, setParent] = useState({
    external_accounting_role_id: props.project["external_accounting_role_id"]
      ? props.project["external_accounting_role_id"]
      : null,
  });

  const debounceInput = useCallback(
    debounce((object) => putProject(object), 500),
    []
  );

  const putProject = (object) => {
    ajax({
      method: "PUT",
      path: `/projects/${props.project.slug}`,
      data: { project: { ...object } },
      success: (response) => {
        util.endSave();
      },
    });
  };

  return (
    <div>
      <Field
        selectedValue={parent.external_accounting_role_id}
        label="Parent Name"
        keyName="external_accounting_role_id"
        onChange={(object) => {
          util.startSave();
          setParent(object);
          debounceInput(object);
        }}
      />
      <div style={{ borderTop: "1px solid #eee", margin: "1rem 0 0" }}></div>
      <h5 className="page-module__subtitle" style={{ paddingBottom: "0" }}>
        SERVICE:ITEMS
      </h5>

      <div className="settings">
        <ul className="setting__list" style={{ marginTop: "1em" }}>
          {props.roles.map((role) => {
            return (
              <li key={role.id} className="setting__item">
                <div className="setting__item-display">
                  <div className="setting__item-name">
                    {parent.external_accounting_role_id
                      ? `${parent.external_accounting_role_id}:`
                      : null}
                    {role.name}
                  </div>{" "}
                  <div className="setting__item-name setting__item-name--rate">
                    {currencySymbol}
                    {numeral(role.rate).format("0[.][00]")}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default AccountingRoles;
