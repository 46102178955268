
const digest = '1e12c45e366ed0b1a63e68de45d68dcc95db63813050c029e881df88b4926f2d';
const css = `._fieldGroupLabel_1y59t_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-10);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fieldGroupLabel":"_fieldGroupLabel_1y59t_1"};
export { css, digest };
  