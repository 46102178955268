import { FC } from "react";
import * as React from "react";

import {
  SpinnerCircle,
  SpinnerContainer,
  spinnerSize,
  SpinnerSvg,
  spinnerThickness,
} from "./spinner.css";

export interface SpinnerProps
  extends React.ComponentProps<typeof SpinnerContainer> {
  label?: string;
}

export const Spinner: FC<SpinnerProps> = ({ label = "Loading…", ...props }) => (
  <SpinnerContainer aria-label={label} role="progressbar" {...props}>
    <SpinnerSvg
      viewBox={`${spinnerSize / 2} ${
        spinnerSize / 2
      } ${spinnerSize} ${spinnerSize}`}
    >
      <SpinnerCircle
        cx={spinnerSize}
        cy={spinnerSize}
        r={(spinnerSize - spinnerThickness) / 2}
        fill="none"
        strokeWidth={spinnerThickness}
      />
    </SpinnerSvg>
  </SpinnerContainer>
);

export default Spinner;
