import { gql } from "@apollo/client";

const SearchQuery = gql`
  fragment QuickSearchProject on Project {
    color
    id
    name
    number
    slug
  }

  fragment QuickSearchProfile on Profile {
    id
    image
    initials
    name
    slug
  }

  fragment QuickSearchClient on Client {
    syncedToQbo
    id
    name
    displayName
    slug
  }

  query QuickSearch($searchString: String, $limit: Int = 4) {
    searchableProjects(
      parameters: { searchString: $searchString, limit: $limit }
    ) {
      ...QuickSearchProject
    }
    profiles(filters: { searchString: $searchString }, first: $limit) {
      nodes {
        ...QuickSearchProfile
      }
    }
    clients(
      filters: { searchString: $searchString, statuses: ACTIVE }
      first: $limit
    ) {
      nodes {
        ...QuickSearchClient
      }
    }
  }
`;

export default SearchQuery;
