
const digest = 'afd6a2bcdad8b05d665796cde4682f2a4a844a513ffb8e02a01f42ed0490e550';
const css = `._wrap_1rlna_1 {
  border-left: 1px solid var(--color--neutral-20);
  border-right: 1px solid var(--color--neutral-20);
  display: flex;
  flex-direction: row;
  min-height: 100%;
  overflow-y: hidden;
  width: 100%;
}

._grid_1rlna_11 {
  background: var(--color-legacy-gray-1);
  cursor: grab;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: visible;
}

._grid_1rlna_11:hover {
  cursor: grab;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"wrap":"_wrap_1rlna_1","grid":"_grid_1rlna_11"};
export { css, digest };
  