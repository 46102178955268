import React from "react";

export const FlexWrapper = ({
  children,
  direction = "row",
  center,
  style,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: direction,
        alignItems: center ? "center" : "auto",
        justifyContent: center ? "center" : "auto",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default FlexWrapper;
