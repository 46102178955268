import React from "react";

import { keyframes, styled } from "@plan/core";
import { Box } from "@plan/layout";

const ANIMATION_DURATION = 0.5;

const grow = keyframes({
  "0%": { width: "0px" },
  "100%": { width: "100%" },
});

const ComplexitySectionWrapper = styled(Box, {
  borderRadius: "100px",
  minWidth: "64px",
  flex: 1,
  height: "6px",
  backgroundColor: "#D9D9D9",
  "> div": {
    borderRadius: "100px",
    height: "100%",
    animationDuration: `${ANIMATION_DURATION}s`,
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
    width: "0px",
  },
  variants: {
    strength: {
      blank: {},
      weak: {
        "> div": {
          backgroundColor: "$colors$-danger10",
          animationName: grow,
        },
      },
      medium: {
        "> div": {
          backgroundColor: "$colors$warning",
          animationName: grow,
        },
      },
      strong: {
        "> div": {
          backgroundColor: "$colors$-success10",
          animationName: grow,
        },
      },
    },
  },
});

export type PasswordStrengthStatus = "blank" | "weak" | "medium" | "strong";

type ComplexityItemProps = { strength: PasswordStrengthStatus };
export const ComplexityItem = (props: ComplexityItemProps) => (
  <ComplexitySectionWrapper {...props}>
    <Box />
  </ComplexitySectionWrapper>
);
