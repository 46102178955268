import React from "react";
import { components } from "react-select";
import Field from "Components/blocks/forms/Field";
import styles from "./table.module.css";

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div className={styles.groupByHolder}>Group By</div>
      <span>{children}</span>
    </components.ValueContainer>
  );
};

export default function TableBar({ groupByOptions, handleSelect }) {
  return (
    <>
      <div className={styles.tableBar}>
        <div className={styles.groupBy}>
          <Field
            as="select"
            size="small"
            placeholder="None"
            options={groupByOptions}
            hideSelectedOptions={false}
            blockClasses={`${styles.filter} timelog__table-filter`}
            handleChange={handleSelect}
            components={{ ValueContainer }}
          />
        </div>
      </div>
    </>
  );
}
