import React, { useMemo } from "react";
import OverheadTitle from "./OverheadTitle";
import OverheadRow from "./OverheadRow";
import TimeOffRequestRow from "../timeOffRequest/TimeOffRequestRow";
import TimeOffHolidayRow from "../timeOffHoliday/TimeOffHolidayRow";
import { useGlobalState } from "../state/GlobalContext.jsx";
import styles from "./overhead.module.css";

function OverheadBlock({
  canEdit,
  startOfWeek,
  timeOffRequestsArray,
  timeOffHolidaysArray,
  timeOffEnabled,
  timeOffHolidayEnabled,
}) {
  const { state } = useGlobalState();

  const sortedOverheads = useMemo(
    () =>
      state.selectedOverheads.allIds.sort((a, b) =>
        state.selectedOverheads.byId[a].name.localeCompare(
          state.selectedOverheads.byId[b].name
        )
      ),
    [state]
  );

  const overheadHex = "#A0A8AE";
  const overheadRgb = "160, 168, 174";

  return (
    <>
      <div
        className={styles.block}
        style={{
          "--project-color": overheadHex,
          "--project-color-rgb": `${overheadRgb}, 0.25`,
        }}
        id="overhead"
        ref={state.references.overhead}
      >
        <div className={styles.blockHeader}>
          <div className={styles.blockTitle}>Overhead</div>
        </div>
        <div className={styles.groups}>
          {timeOffEnabled
            ? timeOffRequestsArray.map((timeOffRequest) => (
                <div
                  className={styles.groupSingleRow}
                  key={timeOffRequest.timeOffRequest.id}
                >
                  <OverheadTitle
                    title={timeOffRequest.timeOffRequest.timeOffPolicyName}
                    status={timeOffRequest.timeOffRequest.status}
                  />
                  <TimeOffRequestRow
                    timeOffRequest={timeOffRequest}
                    startOfWeek={startOfWeek}
                  />
                </div>
              ))
            : null}
          {sortedOverheads.map((overheadId) => {
            const overheadName = state.selectedOverheads.byId[overheadId].name;
            return (
              <div className={styles.groupSingleRow} key={overheadId}>
                <OverheadTitle title={overheadName} />
                <OverheadRow
                  startOfWeek={startOfWeek}
                  groupName={overheadName}
                  overheadId={overheadId}
                  canEdit={canEdit}
                />
              </div>
            );
          })}
          {timeOffHolidayEnabled
            ? timeOffHolidaysArray.map((timeOffHoliday) => (
                <div className={styles.groupSingleRow} key={timeOffHoliday.id}>
                  <OverheadTitle title={timeOffHoliday.name} />
                  <TimeOffHolidayRow
                    holidays={timeOffHoliday.holidays}
                    startOfWeek={startOfWeek}
                    canEdit={false}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
}

export default OverheadBlock;
