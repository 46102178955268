import { gql, useQuery, ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";

const getKillInvoicesV1Flag = gql`
  query featureFlags {
    featureFlags {
      killInvoicesV1
    }
  }
`;

const ExportCSV = ({ slug }) => {
  const { data } = useQuery(getKillInvoicesV1Flag);

  const orgFlagOn = Boolean(data?.featureFlags["killInvoicesV1"]);

  if (!data) return null;

  return (
    <>
      {!orgFlagOn && (
        <>
          <h5 className="invoice-controls__subtitle">Export CSV</h5>
          <div className="invoice-controls__actions">
            <a
              href={`/invoices/${slug}/qbo.csv`}
              className="invoice-controls__button invoice-controls__button--export"
            >
              QuickBooks Online
            </a>
            <a
              href={`/invoices/${slug}/xero.csv`}
              className="invoice-controls__button invoice-controls__button--export"
            >
              Xero
            </a>
          </div>
        </>
      )}
    </>
  );
};

export const ExportCSVWrapper = ({ slug }) => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <ExportCSV slug={slug} />
    </ApolloProvider>
  );
};
