import numeral from "numeral";
import React from "react";
import Badge from "Components/blocks/badge/Badge";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import Edit from "Components/blocks/icons/Edit";
import { useSchedule } from "../../state/ScheduleContext";
import styles from "./headers.module.css";

const badgeStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  justifyContent: "left",
  paddingTop: "2px",
  display: "block",
  fontWeight: 600,
};

const OverheadBadge = ({ overhead, singleBadge }) => {
  const [overheadNameTooltip, overheadNameNode] = useTooltip();

  return (
    <div
      ref={overheadNameNode}
      key={overhead}
      className={styles.overheadBadgeContainer}
    >
      <Tooltip
        position="top"
        text={overhead}
        tooltip={overheadNameTooltip}
        style={{ minWidth: 0 }}
      />
      <Badge
        style={{
          ...badgeStyle,
          maxWidth: singleBadge ? "148px" : "127px",
        }}
        intent="none"
        size="small"
        key={overhead}
      >
        {overhead}
      </Badge>
    </div>
  );
};

const OverheadBadges = ({ names }) => {
  const [overflowTooltip, overflowNode] = useTooltip();

  const shownOverheads = names.slice(0, 1);
  const hiddenOverheads = names.slice(1);

  if (names.length == 1) {
    return <OverheadBadge singleBadge overhead={names[0]} />;
  }

  return (
    <>
      {shownOverheads.map((name) => (
        <OverheadBadge key={name} overhead={name} />
      ))}
      {hiddenOverheads.length > 0 && (
        <div className={styles.overheadBadgeContainer} ref={overflowNode}>
          <Tooltip
            text={hiddenOverheads.map((overhead) => (
              <div>{overhead}</div>
            ))}
            position="top"
            tooltip={overflowTooltip}
            style={{ minWidth: 0 }}
          />
          <Badge style={{ fontWeight: 600 }} intent="none" size="small">
            +{hiddenOverheads.length}
          </Badge>
        </div>
      )}
    </>
  );
};

const EditLink = ({ href }) => (
  <a className={styles.editLink} href={href}>
    <Edit className={styles.editIcon} />
  </a>
);

const OverheadHeader = ({ overheads, profileSlug }) => {
  const [noOverheadTooltip, noOverheadNode] = useTooltip();
  const { date } = useSchedule();

  if (!overheads && !profileSlug) {
    return <div className={styles.overheadContainer} />;
  }

  const hasOverhead = overheads?.names && overheads.names.length > 0;

  return (
    <div className={styles.overheadContainer}>
      {hasOverhead ? (
        <>
          <OverheadBadges names={overheads.names} />
          <EditLink href={`/timesheets/${profileSlug}/${date}`} />
          <div className={styles.overheadHours}>
            {numeral(overheads.hours).format("0.[00]")} HRS
          </div>
        </>
      ) : (
        <>
          <Tooltip
            text="Time entered as an Overhead category in the user’s timesheet will appear here. To add time to an Overhead for this time period, go to the individual’s timesheet."
            position="top"
            tooltip={noOverheadTooltip}
            style={{ maxWidth: "320px" }}
          />
          <div ref={noOverheadNode} className={styles.noOverheadText}>
            No Overhead
          </div>
          <EditLink href={`/timesheets/${profileSlug}/${date}`} />
        </>
      )}
    </div>
  );
};

export default React.memo(OverheadHeader);
