import React, { useState, useCallback } from "react";
import "react-dates/initialize";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import defaultPhrases from "react-dates/lib/defaultPhrases";
import { useFieldState } from "../FieldContext";

const CalendarIcon = () => (
  <svg
    className="dates__calendarIcon"
    width="12"
    height="12"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="2.5" width="11" height="10" rx="1.5" stroke="#606D78" />
    <rect
      x="2.5"
      y="1"
      width="2"
      height="3"
      rx="1"
      fill="#606D78"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="7.5"
      y="1"
      width="2"
      height="3"
      rx="1"
      fill="#606D78"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="3" cy="6" r="0.5" fill="#606D78" />
    <circle cx="5" cy="6" r="0.5" fill="#606D78" />
    <circle cx="7" cy="6" r="0.5" fill="#606D78" />
    <circle cx="9" cy="6" r="0.5" fill="#606D78" />
    <circle cx="3" cy="8" r="0.5" fill="#606D78" />
    <circle cx="5" cy="8" r="0.5" fill="#606D78" />
    <circle cx="7" cy="8" r="0.5" fill="#606D78" />
    <circle cx="9" cy="8" r="0.5" fill="#606D78" />
    <circle cx="3" cy="10" r="0.5" fill="#606D78" />
    <circle cx="5" cy="10" r="0.5" fill="#606D78" />
    <circle cx="7" cy="10" r="0.5" fill="#606D78" />
  </svg>
);

// To override aria-label on the customInputIcon button
const phraseOverrides = {
  ...defaultPhrases,
  focusStartDate: "Choose a date range",
};

export function DateInput() {
  const [dateFocus, setDateFocus] = useState(null);
  const { onDateChange, startDate, endDate, startDateId, endDateId } =
    useFieldState();

  const falseFunc = useCallback(() => false, []);
  const handleFocus = useCallback(
    (focusedInput) => setDateFocus(focusedInput),
    [setDateFocus]
  );

  return (
    <DateRangePicker
      customInputIcon={<CalendarIcon />}
      phrases={phraseOverrides}
      startDate={startDate}
      startDateId={startDateId}
      endDate={endDate}
      endDateId={endDateId}
      keepOpenOnDateSelect
      isOutsideRange={falseFunc}
      onDatesChange={onDateChange}
      focusedInput={dateFocus}
      onFocusChange={handleFocus}
      hideKeyboardShortcutsPanel
      transitionDuration={0}
      displayFormat="YYYY/MM/DD"
      noBorder
    />
  );
}

export function SingleDateInput() {
  const [dateFocus, setDateFocus] = useState(null);
  const { onDateChange, date, withPortal, isOutsideRange, ...props } =
    useFieldState(false);
  const falseFunc = useCallback(() => false, []);
  const handleFocus = useCallback(
    (focusedInput) => setDateFocus(focusedInput.focused),
    [setDateFocus, dateFocus]
  );

  return (
    <SingleDatePicker
      customInputIcon={<CalendarIcon />}
      phrases={phraseOverrides}
      date={date}
      isOutsideRange={isOutsideRange || falseFunc}
      onDateChange={onDateChange}
      focused={dateFocus}
      onFocusChange={handleFocus}
      hideKeyboardShortcutsPanel
      transitionDuration={0}
      numberOfMonths={1}
      displayFormat="YYYY/MM/DD"
      withPortal={withPortal}
      noBorder
    />
  );
}

export default React.memo(DateInput);
