import { useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";

import { TimerEntryFragment } from "../../../../../apps/web/packages/app/generated/graphql";
import { TimerReducerState } from "../reducers/TimerReducer";

export interface LocalStorageTimer {
  entry?: TimerEntryFragment;
  start?: number;
  lastRecordingAt?: number;
}

export const useSyncTimerToLocalStorage = ({
  recordingStartTime,
  lastRecordingAt,
  entry,
}: TimerReducerState) => {
  const [storedLocalStorageTimer, setLocalStorageTimer] =
    useLocalStorage<LocalStorageTimer>("timer", {});

  const localStorageTimer = useMemo(() => {
    return {
      ...storedLocalStorageTimer,
    };
  }, [storedLocalStorageTimer]);

  // Ensure start time is up to date
  useEffect(() => {
    if (recordingStartTime === localStorageTimer.start) return;

    setLocalStorageTimer({
      ...localStorageTimer,
      start: recordingStartTime,
    });
  }, [recordingStartTime, setLocalStorageTimer, localStorageTimer]);

  // Ensure lastRecordingAt is up to date
  useEffect(() => {
    if (lastRecordingAt === localStorageTimer.lastRecordingAt) return;

    setLocalStorageTimer({
      ...localStorageTimer,
      lastRecordingAt,
    });
  }, [lastRecordingAt, setLocalStorageTimer, localStorageTimer]);

  useEffect(() => {
    if (
      entry?.id === localStorageTimer.entry?.id &&
      entry?.hours === localStorageTimer.entry?.hours
    )
      return;

    setLocalStorageTimer({
      ...localStorageTimer,
      entry,
    });
  }, [entry, setLocalStorageTimer, localStorageTimer]);
};
