import Url from "domurl";
import { decodeHTML } from "Utils/util";
import { eventTracker } from "Blocks/tracker";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import Gantt from "Blocks/gantt";
import ajax from "Blocks/utils/ajax";
import { selectMethods } from "Blocks/select";

function checkIfConfirmed(event) {
  const inputValue = event.target.value;
  const projectName = $(".js-project-name")[0].textContent;
  const decodedProjectName = decodeHTML(projectName);
  const deleteButton = $(".js-delete-project")[0];

  if (inputValue === decodedProjectName) {
    deleteButton.disabled = false;
  } else {
    deleteButton.disabled = true;
  }
}

function deleteProjectTemplate(event) {
  if (event.target.disabled === false) {
    const u = new Url();
    const templateSlug = u.paths()[2];

    eventTracker("trackProjectTemplateDelete");

    ajax({
      method: "DELETE",
      path: `/api/v1/projects/templates/${templateSlug}`,
      success: () => {
        const newPaths = u.paths();
        newPaths.pop();
        u.paths(newPaths);
        location.href = u;
      },
    });
  }
}

class ProjectTemplatesShow extends Route {
  // eslint-disable-next-line class-methods-use-this
  initialLoad() {
    bindListenerToDocument("input", "js-confirm-delete", (event) => {
      checkIfConfirmed(event);
    });
    bindListenerToDocument("click", "js-delete-project", (event) => {
      deleteProjectTemplate(event);
    });
  }

  load() {
    this.gantt = new Gantt();

    const shadeColor2 = function (color, percent) {
      const f = parseInt(color.slice(1), 16);
      const t = percent < 0 ? 0 : 255;
      const p = percent < 0 ? percent * -1 : percent;
      const R = f >> 16;
      const G = (f >> 8) & 0x00ff;
      const B = f & 0x0000ff;
      return `#${(
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)}`;
    };

    function rgb2hex(rgb) {
      rgb = rgb.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
      );
      return rgb && rgb.length === 4
        ? `#${`0${parseInt(rgb[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
            rgb[2],
            10
          ).toString(16)}`.slice(-2)}${`0${parseInt(rgb[3], 10).toString(
            16
          )}`.slice(-2)}`
        : "";
    }

    const baseColor = "#FFBE0B";

    // Get all the pills
    const pills = Array.from($(".projects-gantt__phase-time"));
    const labels = $(".projects-gantt__number");
    pills.forEach((pill, i) => {
      let color = pill.style.backgroundColor
        ? pill.style.backgroundColor
        : baseColor;
      color = rgb2hex(color);
      const newColor = shadeColor2(color, -0.04 * i);
      pill.style.backgroundColor = newColor;
      labels[i].style.backgroundColor = newColor;
    });
  }

  beforeCache() {
    this.gantt.unmount();
  }
}

const projectTemplatesShow = new ProjectTemplatesShow();
export default projectTemplatesShow;
