import React, { useState } from "react";
import Segment from "Components/blocks/Segment";
import { Field, Modal, ActionButtons } from "@plan";
import { useColorOptions } from "@hooks";
import { useCreateProjectTemplate } from "Components/api";
import { eventTracker } from "Blocks/tracker";
import ColorSelectOption from "../create/ColorSelectOption";

export const TemplateModal = ({
  isOpen,
  actions,
  project = { id: null },
  organizationId,
  colors,
}) => {
  const { colorOptions, randomColor } = useColorOptions(colors);
  const [res, createTemplate] = useCreateProjectTemplate();

  const initialForm = {
    name: "",
    number: "",
    billable: true,
    color: randomColor,
  };

  const [form, setForm] = useState(initialForm);

  const updateFormValue = (key, value) =>
    setForm({
      ...form,
      [key]: value,
    });

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const project_template = {
      organization_id: organizationId,
      name: form.name,
      is_billable: form.billable,
      number: form.number,
      color: form?.color?.value,
    };

    const data = {
      project_template,
      project_id: project?.id ?? null,
    };

    try {
      setLoading(true);
      const { slug } = await createTemplate(data);
      eventTracker("trackProjectTemplateCreate");
      window.location.href = `/projects/templates/${slug}/edit/planner`;
    } catch (err) {
      setLoading(false);
      throw new Error("Issue creating template");
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        actions={actions}
        title="Create a New Template"
        size="medium"
      >
        <div style={{ marginBottom: "1.5em" }}>
          Save this project, its phases, roles, milestones, and tasks as a
          template.
        </div>
        <Field
          as="text"
          label="Name"
          name="name"
          placeholder=""
          value={form.name}
          handleChange={(e) => updateFormValue("name", e.target.value)}
        />
        <div style={{ display: "flex", margin: "1.5em 0" }}>
          <div style={{ width: "30%" }}>
            <Field
              label="Number"
              value={form.number}
              handleChange={(e) => updateFormValue("number", e.target.value)}
            />
          </div>
          <div style={{ width: "70%", marginLeft: "1.5em" }}>
            <Field
              as="select"
              label="Color"
              options={colorOptions}
              formatOptionLabel={ColorSelectOption}
              value={form.color}
              handleChange={(e) => updateFormValue("color", e)}
            />
          </div>
        </div>
        <Segment
          options={[
            { name: "Billable", value: true },
            { name: "Non-Billable", value: false },
          ]}
          selectedValue={form.billable}
          onSelect={(e) => updateFormValue("billable", e)}
        />
        <ActionButtons
          buttons={[
            {
              color: "cancel",
              text: "Cancel",
              action: actions.close,
            },
            {
              color: "action",
              buttonType: "submit",
              text: "Create",
              disabled: loading || form?.name?.length < 1,
              action: onSubmit,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default TemplateModal;
