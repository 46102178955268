import React from "react";

import { styled, type TCSS, type VariantProps } from "@plan/core";
import { Flex } from "@plan/layout";

import { clamp, round } from "lodash";

export enum circularProgressVars {
  backgroundColor = "$$fillColor",
  backgroundTransition = "$$fillTransition",
  color = "$$color",
  size = "$$size",
  sizeInner = "$$sizeInner",
  trackColor = "$$trackColor",
  trackWidth = "$$trackWidth",
  value = "$$value",
}

const Percent = styled("div", {
  color: "$colors$neutral",
  lineHeight: 1,
  fontSize: ".875rem",
  textAlign: "center",
  position: "relative",
  variants: {
    full: {
      true: {
        textAlign: "left",
      },
    },
  },
});

const PercentSymbol = styled(Flex, {
  fontSize: ".575rem",
});

export const PercentContentComponent = ({
  inverse,
  value,
  min = -999,
  max = 999,
}: {
  inverse?: boolean;
  min?: number;
  max?: number;
  value: number;
}) => {
  const renderedValue = inverse ? 100 - value : value;

  const roundedProgressPercent = isNaN(renderedValue)
    ? 0
    : round(clamp(renderedValue, min, max));

  if (roundedProgressPercent === Infinity) {
    return <>--</>;
  }

  return (
    <Flex>
      {roundedProgressPercent}
      <PercentSymbol>%</PercentSymbol>
    </Flex>
  );
};

const CircularProgressInner = styled("div", {
  backgroundColor: circularProgressVars.backgroundColor,
  transition: circularProgressVars.backgroundTransition,
  width: circularProgressVars.sizeInner,
  height: circularProgressVars.sizeInner,
  textStyle: "large",
  fontWeight: "$semibold",
  color: "$colors$neutral",
});

type CircularProgressRootElement = HTMLDivElement;
type CircularProgressRootProps = VariantProps<typeof CircularProgressRoot>;

const CircularProgressRoot = styled("div", {
  [circularProgressVars.backgroundColor]: "$colors$white",
  [circularProgressVars.backgroundTransition]: "none",
  [circularProgressVars.color]: "$colors$brand",
  [circularProgressVars.size]: "2.5rem",
  [circularProgressVars.sizeInner]: `calc(${circularProgressVars.size} - (${circularProgressVars.trackWidth} * 2))`,
  [circularProgressVars.trackColor]: "$colors$-neutral30",
  [circularProgressVars.trackWidth]: "2px",
  [circularProgressVars.value]: "0deg",
  backgroundImage: `conic-gradient(${circularProgressVars.color} 0deg ${circularProgressVars.value}, ${circularProgressVars.trackColor} ${circularProgressVars.value})`,
  width: circularProgressVars.size,
  height: circularProgressVars.size,
  [`&, > ${CircularProgressInner}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "$round",
  },
});

export type CircularProgressElement = CircularProgressRootElement;

export interface CircularProgressProps
  extends CircularProgressRootProps,
    React.HTMLAttributes<CircularProgressRootElement> {
  /**
   * Percentage value between 0 and 100
   */
  value?: number;
  /**
   * The "indicator" color
   *
   * Note: Color tokens must be prefixed with `$color$<your-token>`
   */
  color?: string;
  /**
   * The ring color
   *
   * Note: Color tokens must be prefixed with `$color$<your-token>`
   */
  trackColor?: string;
  /**
   * The color within the ring
   *
   * Note: Color tokens must be prefixed with `$color$<your-token>`
   */
  backgroundColor?: string;
  css?: TCSS;
  withPercentage?: boolean;
  showRedCircle?: boolean;
}

/**
 * # CircularProgress
 *
 * ## Implementation Details
 *
 * Before extracting into `@plan/components` we may want to consider "clipping"
 * the inside of the circle, rather than asking the consumer to specify a
 * background color.
 */
export const CircularProgress = React.forwardRef<
  CircularProgressElement,
  CircularProgressProps
>(
  (
    {
      children,
      value,
      color,
      trackColor,
      backgroundColor,
      css,
      withPercentage,
      showRedCircle,
      ...props
    },
    ref
  ) => {
    const overage = Number(value) > 100;
    const fillPercent = `${(360 / 100) * clamp(Number(value), 0, 100)}deg`;
    const fillColor = overage || showRedCircle ? "$colors$danger" : color;
    return (
      <CircularProgressRoot
        ref={ref}
        role="progressbar"
        aria-valuemin={0}
        aria-valuenow={value}
        css={Object.assign(
          {},
          (color || overage) && { [circularProgressVars.color]: fillColor },
          backgroundColor && {
            [circularProgressVars.backgroundColor]: backgroundColor,
          },
          trackColor && { [circularProgressVars.trackColor]: trackColor },
          value && { [circularProgressVars.value]: fillPercent },
          css
        )}
        {...props}
      >
        <CircularProgressInner>
          {withPercentage && (value || value === 0) ? (
            <Percent
              full={
                (!!value || value === 0) && value !== Infinity && value >= 100
              }
            >
              {value === 0 ? "--" : <PercentContentComponent value={value} />}
            </Percent>
          ) : (
            children
          )}
        </CircularProgressInner>
      </CircularProgressRoot>
    );
  }
);
