import React, { useState, useEffect } from "react";
import keyBy from "lodash/keyBy";
import { useAction } from "Hooks";
import CheckBox from "Components/blocks/forms/CheckBox";
import Field from "Components/blocks/forms/Field";

// Display the warning Checkboxes
export function DeleteWarnings({ data, checks, setCheck, noDataText }) {
  const isData = data.length;
  return (
    <>
      {!isData && <p>{noDataText}</p>}
      {!!isData && <p>To confirm, please check all the below:</p>}
      {!!isData &&
        data.map((item, i) => (
          <CheckBox
            key={item}
            name={i.toString()}
            label={item}
            isChecked={checks[i.toString()]}
            handleToggle={setCheck}
          />
        ))}
    </>
  );
}

// Require the user to check all warnings and type to match a phrase
export const DeleteConfirmation = ({
  id,
  fetchAction,
  confirmation,
  noDataText,
}) => {
  const [{ loading, data, error }, deleteWarnings] = useAction(fetchAction);
  const { setAllChecked, setText, text, phrase } = confirmation;
  const [checks, setChecks] = useState();

  // Grab the delete warning data on mount
  useEffect(() => {
    deleteWarnings(id);
    // Clear the text input on close
    return () => setText("");
  }, []);

  const handleCheck = (e) => {
    const { name } = e.target;
    setChecks({ ...checks, [name]: !checks[name] });
  };

  useEffect(() => {
    // Create the checkbox state
    if (!checks && data) {
      let initialState;
      data.map(
        (item, i) => (initialState = { ...initialState, [i.toString()]: false })
      );
      setChecks({ ...initialState });
    }
    // If there are no warnings, set all validations to true
    if (data && !(data.length > 0)) {
      setText(phrase);
      setAllChecked(true);
    }
  }, [data]);

  // Listen to see if all checkboxes are checked
  useEffect(() => {
    if (checks && data) {
      const checkIds = Object.values(checks);
      const isVerified = checkIds.every((val) => val === true);
      setAllChecked(isVerified);
    }
  }, [checks]);

  if (loading || !data) return <p>Loading...</p>;
  if (error) return <p>There was an error pulling related data</p>;

  // If we don't have any warnings, we don't show checkboxes
  // or the text field
  const hasWarnings = data.length > 0;

  return (
    <>
      <DeleteWarnings
        data={data}
        checks={checks}
        setCheck={handleCheck}
        noDataText={noDataText}
      />
      {hasWarnings && (
        <>
          <p>
            Please type <strong style={{ whiteSpace: "pre" }}>{phrase}</strong>{" "}
            to confirm
          </p>
          <Field
            autoFocus
            type="text"
            as="field"
            size="small"
            value={text}
            handleChange={(e) => setText(e.target.value)}
          />
        </>
      )}
    </>
  );
};

// Associated hook for the logic this should be used in the parent component
// and the confirmations object should be passed to the DeleteWarnings component
export function useDeleteConfirmation({ matchPhrase, deleteAction }) {
  const [text, setText] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!canDelete) return;
    return deleteAction();
  };

  const confirmation = {
    text,
    setText,
    setAllChecked,
    phrase: matchPhrase,
  };

  useEffect(() => {
    if (text === matchPhrase && allChecked) setCanDelete(true);
    if (text !== matchPhrase || !allChecked) setCanDelete(false);
  }, [matchPhrase, text, allChecked]);

  return [canDelete, confirmation, onSubmit];
}

export default DeleteWarnings;
