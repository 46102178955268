import React, { useState, useContext } from "react";
import { getCurrentMonth } from "Utils/util";
import { useFilters, useApi, useApiCached } from "@hooks";

export const TimelogContext = React.createContext({});
export const FiltersContext = React.createContext({});

export function TimelogProvider({
  children,
  queryParams,
  projectId,
  projectSlug,
  canEditAllTimesheets,
}) {
  const thisMonth = getCurrentMonth();
  const { filters, activeFilters, updateDates, updateFilters, setFilters } =
    useFilters({
      initialActiveFilters: {
        ...thisMonth,
        ...queryParams,
      },
      initialFilters: [
        { name: "profiles", options: [], placeholder: "People" },
        { name: "phases", options: [], placeholder: "Phases" },
        { name: "activities", options: [], placeholder: "Activities" },
      ],
    });

  const [tableData, setTableData] = useState([]);
  const [tableGroup, setTableGroup] = useState("entries");
  const [totals, setTotals] = useState({ hours: 0, value: 0 });

  return (
    <TimelogContext.Provider
      value={{
        projectId,
        projectSlug,
        tableData,
        setTableData,
        tableGroup,
        setTableGroup,
        totals,
        setTotals,
        canEditAllTimesheets,
      }}
    >
      <FiltersContext.Provider
        value={{
          filters,
          updateDates,
          activeFilters,
          updateFilters,
          setFilters,
        }}
      >
        {children}
      </FiltersContext.Provider>
    </TimelogContext.Provider>
  );
}

export const useTimelogContext = () => useContext(TimelogContext);
export const useFiltersContext = () => useContext(FiltersContext);
