import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import cx from "classnames";
import styles from "./week-picker.module.css";

// Helper function, might be useful elsewhere
// Determines if a date falls within a list of dates
function checkDateMatch(dates, day) {
  let response = false;
  dates.forEach((date) => {
    const isDayOfMonthMatch = date.date() === day.date();
    const isMonthMatch = date.month() === day.month();
    const isYearMatch = date.year() === day.year();
    if (isDayOfMonthMatch && isMonthMatch && isYearMatch) response = true;
  });
  return response;
}

function CalendarDay({
  date,
  handleClick,
  hoveredDay,
  setHoveredDay,
  hoveredWeek,
  selectedWeek,
}) {
  const day = useRef(moment(date.day).date());

  // Set the day value into state so that
  // when we click to change dates, it doesn't update
  useEffect(() => {
    if (date.day) day.current = moment(date.day).date();
  }, []);

  // Not every cell is a date,
  // so we check to make sure there is a day
  if (date.day) {
    const classes = cx(
      "CalendarDay",
      "CalendarDay__default",
      "CalendarDay_1",
      "CalendarDay__default_2",
      styles.CalendarDay,
      { [styles.isHighlighted]: checkDateMatch(hoveredWeek, date.day) },
      { [styles.isHovered]: date.day === hoveredDay },
      { [styles.isSelected]: checkDateMatch(selectedWeek, date.day) }
    );
    return (
      <td
        className={classes}
        role="button"
        onClick={() => handleClick(date.day)}
        onMouseEnter={() => setHoveredDay(date.day)}
        key={date.key}
      >
        {day.current}
      </td>
    );
  }

  // If there is no date, we render an empty cell
  const emptyClasses = cx(
    "CalendarDay",
    "CalendarDay__default",
    "CalendarDay_1",
    "CalendarDay__default_2"
  );
  return <td className={emptyClasses} key={date.key} />;
}

export default CalendarDay;
