import React from "react";
import styles from "./projectSelect.module.css";
// import { useGlobalState } from "./state/GlobalContext.jsx";

const ProjectSelectOption = ({
  phaseName,
  roleName,
  abbreviation,
  showRoleName,
}) => {
  return (
    <div>
      <div className={styles.phase}>
        {abbreviation} • {phaseName} {showRoleName && `• ${roleName}`}
      </div>
    </div>
  );
};

export default ProjectSelectOption;
