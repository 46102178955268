import * as React from "react";

import { createComponent, styled } from "@plan/core";
import { IconInfoOutlined, IconWarningOutlined } from "@plan/icons";
import { AutoLayout, Box } from "@plan/layout";

import { Link } from "../Link";

const component = createComponent();

export const StyledAlert = styled("div", {
  ...component,
  color: "$colors$neutral30",
  fontSize: "$fontSizes$lg",
  borderRadius: "$radii$default",
  marginBottom: "$space$2",
  [`${Link}`]: {
    color: "inherit",
    fontWeight: "$normal",
    textDecoration: "underline",
  },
  variants: {
    ...component.variants,
    intent: {
      announcement: {
        backgroundColor: "$colors$brand",
        border: "1px solid $colors$-brand20",
        color: "$white",
      },
      warning: {
        backgroundColor: "$colors$-warning40",
        border: "1px solid $colors$-warning20",
      },
      info: {
        backgroundColor: "$colors$-brand50",
        border: "1px solid $brand",
      },
      danger: {
        backgroundColor: "$colors$-danger40",
        border: "1px solid $colors$-danger20",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    intent: "info",
  },
});

export interface AlertProps extends React.ComponentProps<typeof StyledAlert> {
  /** icon: What to display as the icon for the Alert. You can pass a React component, or `false` to hide the icon */
  icon?: React.ReactNode | false;
}

const AlertIcon: React.FC<AlertProps> = ({ icon, intent }) => {
  const defaultIcons: Record<
    Extract<AlertProps["intent"], string>,
    React.ReactElement
  > = {
    info: <IconInfoOutlined label="Info" color="brand" />,
    warning: <IconInfoOutlined label="Warning" color="warning" />,
    danger: <IconWarningOutlined label="Danger" color="danger" />,
    announcement: <></>,
  };

  if (
    typeof intent === "undefined" ||
    typeof intent !== "string" ||
    icon === false
  ) {
    return null;
  }

  if (React.isValidElement(icon)) return icon;

  return defaultIcons[intent] || null;
};

export const Alert = ({
  intent = "info",
  icon,
  children,
  ...props
}: AlertProps) => (
  <StyledAlert intent={intent} {...props}>
    <AutoLayout as="span" alignment="center" padding="2_5" spacing="2">
      <AlertIcon icon={icon} intent={intent} />
      <Box as="span" resizingY="fill-container" resizingX="fill-container">
        {children}
      </Box>
    </AutoLayout>
  </StyledAlert>
);
