import util from "Blocks/utils/util";
import React, { useState, useCallback } from "react";
import Field from "Components/blocks/Field";
import { debounce } from "debounce";
import ajax from "Blocks/utils/ajax";

function AccountingProject(props) {
  const [customer, setCustomer] = useState({
    external_accounting_id: props.project["external_accounting_id"]
      ? props.project["external_accounting_id"]
      : null,
  });

  const debounceInput = useCallback(
    debounce((object) => putProject(object), 500),
    []
  );

  const putProject = (object) => {
    ajax({
      method: "PUT",
      path: `/projects/${props.project.slug}`,
      data: { project: { ...object } },
      success: (response) => {
        util.endSave();
      },
    });
  };

  return (
    <div>
      <Field
        selectedValue={customer.external_accounting_id}
        label="Customer Name"
        keyName="external_accounting_id"
        onChange={(object) => {
          util.startSave();
          setCustomer(object);
          debounceInput(object);
        }}
      />
      <div style={{ borderTop: "1px solid #eee", margin: "1rem 0 0" }}></div>
      <h5 className="page-module__subtitle" style={{ paddingBottom: "0" }}>
        CUSTOMER:JOB
      </h5>
      <p>
        {customer.external_accounting_id
          ? `${customer.external_accounting_id}:`
          : ``}
        {props.project.name}
      </p>
    </div>
  );
}

export default AccountingProject;
