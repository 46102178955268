import React, { useState, useEffect } from "react";
import { useMeasure } from "react-use";

export function useOverflow() {
  const [wrapperRef, { width: wrapperWidth }] = useMeasure();
  const [innerRef, { width: innerWidth }] = useMeasure();
  const [overflow, setOverflow] = useState(false);

  function compareWidth(inner, outer) {
    if (inner > outer) return true;
    return false;
  }

  useEffect(() => {
    setOverflow(compareWidth(innerWidth, wrapperWidth));
  }, [innerWidth, wrapperWidth]);

  return { wrapperRef, innerRef, innerWidth, wrapperWidth, overflow };
}

export default useOverflow;
