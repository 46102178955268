import React from "react";
import ActiveStatusIcon from "Components/blocks/Status/ActiveStatusIcon";

export default function TimesheetStatus({ isSubmitted }) {
  const status = isSubmitted ? "Submitted" : "Open";
  return (
    <span>
      <ActiveStatusIcon status={status} />
      {status}
    </span>
  );
}
