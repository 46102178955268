import React from "react";
import PropTypes from "prop-types";
import StickyTable from "./StickyTable";
import DataTable from "./DataTable";
// Export the elements from here to simplify imports elsewhere
export * from "./TableElements";

export const Table = (props) => {
  const { as } = props;
  switch (as) {
    case "sticky":
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <StickyTable {...props} />;
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <DataTable {...props} />;
  }
};

Table.propTypes = {
  as: PropTypes.string,
};

Table.defaultProps = {
  as: "",
};

export default Table;
