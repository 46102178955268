import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHover } from "Hooks";
import { motion, AnimatePresence } from "framer-motion";
import Portal from "Components/blocks/portal/Portal";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./tooltip.module.css";

const ToolTipArrow = React.memo(() => {
  return (
    <svg
      width="8"
      height="3"
      viewBox="0 0 8 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9641 0L0.464096 3L7.4641 3L3.9641 0Z"
        fill="var(--background)"
      />
    </svg>
  );
});

const ToolTipContent = React.memo(
  ({ children, tooltip, position = "bottom", heading, text, light, style }) => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.125 } }}
        exit={{ opacity: 0, transition: { duration: 0.125 } }}
        key={heading}
        ref={tooltip.tooltipRef}
        style={{
          position: "fixed",
          minWidth: "7rem",
          zIndex: 5000,
          "--offsetX": `${tooltip.offset.x}px`,
          "--offsetY": `${tooltip.offset.y}px`,
          "--tether-top": `${tooltip.position.y}px`,
          "--tether-bottom": `${tooltip.position.bottom}px`,
          "--tether-left": `${tooltip.position.x}px`,
          "--tether-right": `${tooltip.position.right}px`,
          "--tether-vert-center": `${
            (tooltip.position.y + tooltip.position.bottom) / 2
          }px`,
          "--tether-hor-center": `${
            (tooltip.position.x + tooltip.position.right) / 2
          }px`,
          ...style,
          // ...positionStyles(tooltip.position, position)
        }}
        className={cx([styles[position]])}
      >
        <div className={cx(styles.tooltip, { [styles.light]: light })}>
          <div className={styles.arrow}>
            <ToolTipArrow />
          </div>
          {heading && <div className={styles.heading}>{heading}</div>}
          {text && (
            <div className={cx(styles.text, { [styles.light]: light })}>
              {text}
            </div>
          )}
          {children}
        </div>
      </motion.div>
    );
  }
);

export function useTooltip() {
  const [position, setPosition] = useState({});
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const [node, shown] = useHover();
  const tether = useRef();
  const tooltipRef = useRef();

  const setOffsetPosition = useCallback(() => {
    if (tooltipRef.current) {
      const { x, y } = tooltipRef.current.getBoundingClientRect();
      const offsetX = x < 0 ? -x : 0;
      const offsetY = y < 0 ? -y : 0;
      setOffset({
        x: offsetX,
        y: offsetY,
      });
    }
  }, [tooltipRef]);

  const setTooltipPosition = useCallback(() => {
    if (tether.current) setPosition(tether.current.getBoundingClientRect());
    if (!tether.current && node.current)
      setPosition(node.current.getBoundingClientRect());
  }, [tether, node]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setTooltipPosition();
      setOffsetPosition();
    });
    return () =>
      window.removeEventListener("scroll", () => {
        setTooltipPosition();
        setOffsetPosition();
      });
  }, []);

  useEffect(() => {
    setTooltipPosition();
    // Add shown to the depency to ensure that the tooltip gets
    // properly positioned if the tether element has moved
  }, [tether, node, shown]);

  useEffect(() => {
    setOffsetPosition();
  }, [shown, tooltipRef]);

  return [{ shown, position, offset, tooltipRef }, node, tether];
}

export function Tooltip({
  children,
  tooltip,
  position,
  heading,
  text,
  light,
  style,
}) {
  return (
    <Portal selector="body">
      <AnimatePresence exitBeforeEnter>
        {tooltip.shown && (
          <ToolTipContent
            tooltip={tooltip}
            position={position}
            heading={heading}
            text={text}
            light={light}
            style={style || {}}
          >
            {children}
          </ToolTipContent>
        )}
      </AnimatePresence>
    </Portal>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  light: PropTypes.bool,
  position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  style: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.object.isRequired,
};

export default React.memo(Tooltip);
