import React from "react";

export default function StatusPaused(classes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <circle cx="16" cy="16" r="16" fill="#F7F9FA" />
      <path
        d="M13 11C12.4477 11 12 11.4477 12 12V20C12 20.5523 12.4477 21 13 21H14C14.5523 21 15 20.5523 15 20V12C15 11.4477 14.5523 11 14 11H13Z"
        fill="#DFE2E4"
      />
      <path
        d="M18 11C17.4477 11 17 11.4477 17 12V20C17 20.5523 17.4477 21 18 21H19C19.5523 21 20 20.5523 20 20V12C20 11.4477 19.5523 11 19 11H18Z"
        fill="#DFE2E4"
      />
    </svg>
  );
}
