import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal, { useModal } from "Components/blocks/modal/Modal";
import ActionButtons from "Components/blocks/buttons/ActionButtons";
import ajax from "Utils/ajax";
import styles from "./MenuPageStatus.module.css";
import ActiveIcon from "./icons/Active";
import CanceledIcon from "./icons/Canceled";
import CompletedIcon from "./icons/Completed";
import PausedIcon from "./icons/Paused";
import ProposedIcon from "./icons/Proposed";

export default function MenuPageStatus({
  name,
  requestPath,
  validStatuses,
  currentStatus,
}) {
  const [isOpen, actions] = useModal(false);

  const [selectedStatus, setSelectedStatus] = useState(currentStatus.value);

  const statusDisplayData = [
    {
      value: "proposed",
      title: "Proposed",
      description: "Disable time tracking unless user has staffed hours",
      icon: ProposedIcon,
    },
    {
      value: "active",
      title: "Active",
      description: "Your team can track time against the project",
      icon: ActiveIcon,
    },
    {
      value: "paused",
      title: "Paused",
      description:
        "Freeze the schedule and prevent entering time in the future",
      icon: PausedIcon,
    },
    {
      value: "canceled",
      title: "Canceled",
      description: "Disable time tracking in the future and remove from Gantt",
      icon: CanceledIcon,
    },
    {
      value: "completed",
      title: "Completed",
      description: "Disable any further time tracking in the future",
      icon: CompletedIcon,
    },
  ];

  const validStatusDisplayData = statusDisplayData.filter((so) =>
    validStatuses.includes(so.value)
  );

  function statusOption(statusObject) {
    const { title, description } = statusObject;
    const active = selectedStatus === statusObject.value;
    const iconStyle = active ? styles.active : "";

    return (
      <li
        className={`${styles.status} ${active ? styles.active : ""}`}
        onClick={() => setSelectedStatus(statusObject.value)}
        key={statusObject.value}
      >
        {statusObject.icon(iconStyle)}
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </li>
    );
  }

  function updateStatus() {
    ajax({
      method: "POST",
      path: requestPath,
      data: {
        status: selectedStatus,
      },
      success: () => {
        location.reload();
      },
    });
  }

  const currentIcon = statusDisplayData.find(
    (status) => status.value === currentStatus.value
  ).icon;
  const currentLabel = currentStatus.label;

  return (
    <>
      <button
        className={styles.statusButton}
        type="button"
        onClick={actions.open}
      >
        {currentIcon(styles.statusButtonIcon)}
        <span>{currentLabel}</span>
      </button>
      <Modal
        title="Edit Status"
        subHeading={name}
        size="small"
        actions={actions}
        // toggleOpen={toggleModal}
        isOpen={isOpen}
      >
        <ul>{validStatusDisplayData.map((so) => statusOption(so))}</ul>
        <ActionButtons
          buttons={[
            {
              buttonStyle: "cancel",
              text: "Cancel",
              action: actions.close,
            },
            {
              buttonStyle: "action",
              buttonType: "submit",
              text: "Update",
              action: updateStatus,
            },
          ]}
        />
      </Modal>
    </>
  );
}

MenuPageStatus.propTypes = {
  name: PropTypes.string.isRequired,
  requestPath: PropTypes.string.isRequired,
  validStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStatus: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};
