import React from "react";
import Button from "./Button.jsx";
import { useModal } from "Components/blocks/modal/useModal";
import Modal from "Components/blocks/modal/Modal";

export default function ViewSampleButton({ modalTitle, content }) {
  const [modal, modalActions] = useModal(false);

  return (
    <>
      <Button
        variant="default"
        color="outline"
        action={modalActions.open}
        text="View Sample"
      />
      <Modal
        isOpen={modal}
        actions={modalActions}
        title={modalTitle}
        size="sample"
      >
        <img src={content.image} width="100%" height="auto" />
        <br />
        <a target="_blank" href={content.link.url}>
          {content.link.text}
        </a>
      </Modal>
    </>
  );
}
