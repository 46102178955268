
const digest = '3d66001644d85432c2f99aefa8f02b51a7acb7cd760e18362311a6bf92cf6e2d';
const css = `._hstack_7ic1z_1 {
  display: flex;
  flex-direction: row;
  /* * Note: When gap is better supported on Safari
  * we can implement spacing with gap: */
  /* gap: var(--gap, 0.5rem); */
  min-width: max-content;
  align-items: stretch;
}

._hstack_7ic1z_1 > *:not(:last-child) {
  margin-right: var(--gap, 0.5rem);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"hstack":"_hstack_7ic1z_1"};
export { css, digest };
  