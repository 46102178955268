import * as React from "react";

import type * as Polymorphic from "@radix-ui/react-polymorphic";
import * as PortalBase from "@radix-ui/react-portal";

type PortalProps = Polymorphic.OwnProps<typeof PortalBase.Root> & {
  children?: React.ReactNode;
};

export const Portal = ({ children }: PortalProps) => (
  <PortalBase.Root>{children}</PortalBase.Root>
);
