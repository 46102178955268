import React from "react";

// This is used for card style layouts within pages (for instance a table)
// We should consider refactoring this into the standard /card/Card component

const Module = ({ options, children }) => {
  switch (options) {
    case "inGrid":
      return (
        <div className="grid-xs-12">
          <div className="page-module">{children}</div>
        </div>
      );
    case "table":
      return (
        <div className="grid-xs-12">
          <div className="page-module__scrollable">{children}</div>
        </div>
      );
    default:
      return <div className="page-module">{children}</div>;
  }
};

export default Module;
