import React from "react";
import Select from "react-select";

// At some point we should combine this with the 'forms/Field' component

// Styling through emotion styling object
// https://react-select.com/styles#style-object

export default function Filter({
  name,
  options,
  placeholder,
  handleChange,
  size,
}) {
  const filterStyles = {
    container: (provided, state) => ({
      ...provided,
      border: "1px solid #6B7280",
      borderColor: state.isSelected && "#6B7280",
      fontColor: "#4B5563",
      borderRadius: ".25em",
      boxShadow: state.isFocused ? "none" : "none",
      height: "2.5rem",
      width: size || "13rem",
      transition: "all .1s ease-in-out",
      outline: state.isFocused || state.isSelected ? "none" : "",
      margin: "0 0.25rem",
      "&:hover": {
        borderColor: "#111827",
        cursor: "pointer",
        fontColor: "#111827",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      outline: state.isFocused || state.isSelected ? "none" : "",
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "none" : "none",
      borderRadius: ".25em",
      boxShadow: "none",
      height: "100%",
      outline: state.isFocused || state.isSelected ? "none" : "",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused
        ? "rgba(255, 190,	11, .75)"
        : state.isSelected
        ? "rgba(255, 190,	11, 1)"
        : state.isActive
        ? "rgba(255, 190,	11, .85)"
        : "#fff",
      color: state.isFocused || state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };

  return (
    <Select
      styles={filterStyles}
      isClearable
      name={name}
      placeholder={placeholder}
      options={options}
      onChange={(e) => handleChange(e, name)}
    />
  );
}
