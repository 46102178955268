import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./panel.module.css";

export const AccordionPanelWrapper = ({ children, expanded }) => (
  <li className={cx(styles.item, { [styles.isExpanded]: expanded })}>
    {children}
  </li>
);

export const AccordionHeader = ({ children, onClick }) => (
  <div className={styles.header} onClick={onClick}>
    {children}
  </div>
);
export const AccordionTitle = ({ title }) => (
  <h3 className={styles.name}>{title}</h3>
);

export const AccordionSummary = ({ summary }) => (
  <div className={styles.summary}>{summary}</div>
);
export const AccordionToggle = () => <span className={styles.toggle} />;

export const AccordionContent = ({ children }) => (
  <div className={styles.content}>{children}</div>
);

export const PanelList = ({ children }) => (
  <ul className={styles.list}>{children}</ul>
);

export const AccordionPanel = ({ title, summary, children }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleOpen = () => setExpanded((state) => !state);
  return (
    <AccordionPanelWrapper expanded={expanded}>
      <AccordionHeader onClick={toggleOpen}>
        <AccordionTitle title={title} />
        <AccordionSummary summary={summary} />
        <AccordionToggle />
      </AccordionHeader>
      <AccordionContent>{children}</AccordionContent>
    </AccordionPanelWrapper>
  );
};

export default AccordionPanel;
