import React from "react";

export const Close = React.memo(({ fill = "#A0A8AE", forwardRef }) => {
  return (
    <svg
      ref={forwardRef}
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.940782 0.970477C0.748857 0.765984 0.411408 0.736643 0.187068 0.904941C-0.0372711 1.07324 -0.0635483 1.37545 0.128377 1.57994L3.32073 4.98135L0.482529 8.00541C0.290604 8.20991 0.316881 8.51211 0.541221 8.68041C0.76556 8.84871 1.10301 8.81937 1.29493 8.61488L4.01294 5.71888L6.70471 8.58693C6.89663 8.79142 7.23408 8.82077 7.45842 8.65247C7.68276 8.48417 7.70904 8.18196 7.51711 7.97747L4.70514 4.98135L7.87126 1.60789C8.06319 1.4034 8.03691 1.10119 7.81257 0.93289C7.58823 0.764591 7.25078 0.793933 7.05886 0.998426L4.01294 4.24381L0.940782 0.970477Z"
        fill={fill}
      />
    </svg>
  );
});

export default React.memo(Close);
