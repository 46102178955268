import { ComponentProps } from "react";

import { createComponent, styled } from "@plan/core";

import * as Separator from "@radix-ui/react-separator";

const component = createComponent();

export type DividerProps = Omit<ComponentProps<typeof Divider>, "asChild">;

export const Divider = styled(Separator.Root, {
  ...component,
  $$dividerBorderWidth: "$borderWidths$1",
  backgroundColor: "$colors$-neutral20",
  border: 0,
  variants: {
    ...component.variants,
    direction: {
      horizontal: {
        height: "$$dividerBorderWidth",
        width: "100%",
      },
      vertical: {
        height: "100%",
        width: "$$dividerBorderWidth",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    direction: "horizontal",
  },
});
