import Highcharts from "highcharts";
import moment from "moment";
import util from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

// Determines if dates are valid
function validDates(beginDate, endDate) {
  return (
    beginDate <= endDate &&
    moment(beginDate).isValid() &&
    moment(endDate).isValid()
  );
}

// The order of the keys for role templates is reversed somewhere and I don't know why

export default class OrganizationProjectsDistribution {
  constructor() {
    bindListenerToDocument("click", "js-filter-organization", (event) => {
      this.prepareAndRenderChart(event);
    });
    bindListenerToDocument("input", "js-organization-time", (event) => {
      this.delayedUpdateProjectData(event);
    });

    this.initChart();
  }

  // Methods

  // Render chart without data before making an ajax request to update it with the new data,
  // this way the update and create share the same method.
  initChart() {
    // Render chart without project data
    this.createChart();
    // Update chart with project data
    this.updateData();
  }

  updateData() {
    this.chart.showLoading();

    let beginDate = $(".js-organization-time")[0].value;
    let endDate = $(".js-organization-time")[1].value;

    // Show 'No data' if dates aren't valid
    if (!validDates(beginDate, endDate)) {
      this.chart.destroy();
      this.createChart();
      return;
    }

    // Include these so inputs (on Safari) such as "2019" will work.
    beginDate = moment(beginDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");

    const query = `
      query HoursAndDollarsSpentPerProject($dateRange: DateRangeInput!) {
        highcharts {
          hoursAndDollarsSpentPerProject(dateRange: $dateRange) {
            projects {
              name
              slug
            }
            roles {
              name
              color
              budgetConsumed
              hoursConsumed
            }
          }
        }
      }
    `;

    const data = {
      query,
      variables: {
        dateRange: {
          beginDate,
          endDate,
        },
      },
    };

    ajax({
      method: "POST",
      path: `/graphql`,
      data,
      success: (response) => {
        // Ensure that the chart hasn't been destroyed
        this.chart.hideLoading();
        if (this.chart.series) {
          const responseData = JSON.parse(response);
          this.projectData =
            responseData.data.highcharts.hoursAndDollarsSpentPerProject.projects;
          this.roleData =
            responseData.data.highcharts.hoursAndDollarsSpentPerProject.roles;
          this.prepareAndRenderChart();
        }
      },
    });
  }

  delayedUpdateProjectData() {
    util.delay(
      () => {
        this.updateData();
      },
      800,
      "updateData"
    );
  }

  createChart() {
    this.chart = Highcharts.chart("organization-projects-distribution", {
      chart: {
        type: "bar",
        height: 400,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
        labels: {
          formatter() {
            // Do I really need to double slug this?
            return `<a style="color: #666" href="/projects/${util.slugify(
              this.value.slug
            )}">${this.value.name}</a>`;
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [],
      // Keep tooltip so it can be edited
      tooltip: {},
      credits: {
        enabled: false,
      },
      legend: {
        reversed: true,
        itemMarginTop: 6,
      },
    });
  }

  updateProjectsHoursData() {
    this.chart.update({
      chart: {
        height: this.chartHeight(),
      },
      yAxis: {
        title: {
          text: "Hours Worked",
        },
      },
      xAxis: {
        categories: this.projectData.map((project) => ({
          name: project.name,
          slug: project.slug,
        })),
      },
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>${this.y} hours</b>`;
        },
        headerFormat:
          '<span style="font-size: 10px">{point.key.name}</span><br/>',
      },
      series: this.hoursConsumedSeries(),
    });
  }

  updateProjectsDollarsData() {
    this.chart.update({
      chart: {
        height: this.chartHeight(),
      },
      yAxis: {
        title: {
          text: "Value Created",
        },
      },
      xAxis: {
        categories: this.projectData.map((project) => ({
          name: project.name,
          slug: project.slug,
        })),
      },
      // This number is not formatted correctly
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>${currencySymbol}${this.y}</b>`;
        },
        headerFormat:
          '<span style="font-size: 10px">{point.key.name}</span><br/>',
      },
      series: this.budgetConsumedSeries(),
    });
  }

  prepareAndRenderChart() {
    // this.setRoleData();
    this.changeSeries();
    this.renderChart();
  }

  // Every time the chart is rendered, the series are all removed and appended
  // This should use the shared updateSeries method
  changeSeries() {
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove();
    }
    let newSeriesData;
    if ($(".js-organization-filter")[0].dataset.value === "hours_consumed") {
      newSeriesData = this.hoursConsumedSeries();
    } else if (
      $(".js-organization-filter")[0].dataset.value === "budget_consumed"
    ) {
      newSeriesData = this.budgetConsumedSeries();
    }
    newSeriesData.forEach((series) => {
      this.chart.addSeries(series, false);
    });
  }

  // Determines which chart to render depending on selected value
  renderChart() {
    if ($(".js-organization-filter")[0].dataset.value === "hours_consumed") {
      this.updateProjectsHoursData();
    } else if (
      $(".js-organization-filter")[0].dataset.value === "budget_consumed"
    ) {
      this.updateProjectsDollarsData();
    }
  }

  // Change roleData into objects for HighCharts
  hoursConsumedSeries() {
    const roleHoursData = this.roleData.map((roleDatum) => ({
      name: roleDatum.name,
      data: roleDatum.hoursConsumed,
      color: roleDatum.color,
    }));
    return roleHoursData;
  }

  budgetConsumedSeries() {
    const roleDollarsData = this.roleData.map((roleDatum) => ({
      name: roleDatum.name,
      data: roleDatum.budgetConsumed,
      color: roleDatum.color,
    }));
    return roleDollarsData;
  }

  chartHeight() {
    let chartHeight = 400;
    if (this.projectData.length > 12) {
      chartHeight += (this.projectData.length - 12) * 16;
    }
    return chartHeight;
  }

  // Destroy
  destroyChart() {
    this.chart.destroy();
  }
}
