import { createScaleVariant } from "./variants";

/* Scale Variants
  ============================================ */

const boxModelValues = {
  0: 0,
  inherit: "inherit",
  initial: "initial",
  unset: "unset",
  revert: "revert",
};

const padding = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({ padding: token }),
});
const paddingTop = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({ paddingTop: token }),
});
const paddingRight = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({
    paddingRight: token,
  }),
});
const paddingBottom = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({
    paddingBottom: token,
  }),
});
const paddingLeft = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({
    paddingLeft: token,
  }),
});
const paddingX = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({ paddingX: token }),
});
const paddingY = createScaleVariant({
  scale: "space",
  extend: boxModelValues,
  css: (token) => ({ paddingY: token }),
});

/* Box Component
  ============================================ */

/* Without Padding
  ---------------------------------- */

/**
 * A `Box` component with limited customizability
 *
 * e.g. we don't want consumers changing `Button` `padding`, but we want them
 *      to have access to `resizing` props
 */
const boxWithoutPadding = {
  variants: {
    resizingX: {
      "hug-contents": {},
      "fill-container": {
        flexBasis: 0,
      },
    },
    resizingY: {
      "hug-contents": {},
      "fill-container": {
        flexBasis: 0,
      },
    },
  },
  compoundVariants: [
    {
      resizingX: "hug-contents",
      resizingY: "hug-contents",
      css: {
        alignSelf: "initial",
        flexGrow: 0,
      },
    },
    {
      resizingX: "fill-container",
      resizingY: "fill-container",
      css: {
        width: "100%",
        height: "100%",
        alignSelf: "stretch",
        flexBasis: 0,
        flexGrow: 1,
      },
    },
    {
      resizingX: "fill-container",
      resizingY: "hug-contents",
      css: {
        $$flexGrowFillContainerXHugContentsY: "0",
        width: "100%",
        alignSelf: "stretch",
        flexBasis: 0,
        flexGrow: "$$flexGrowFillContainerXHugContentsY",
        // When this compound variant is nested inside itself, the flexGrow
        // value must be adjusted (via custom properties)
        "& > *": {
          // Double-up specificity by 1 to override the default selector
          "&&": {
            $$flexGrowFillContainerXHugContentsY: 1,
          },
        },
      },
    },
    {
      resizingX: "hug-contents",
      resizingY: "fill-container",
      css: {
        height: "100%",
        alignSelf: "initial",
        flexBasis: 0,
        flexGrow: "0",
      },
    },
  ],
  defaultVariants: {
    resizingX: "hug-contents",
    resizingY: "hug-contents",
  },
};

/* With Padding
  ---------------------------------- */

/**
 * A `Box` component with full customizability
 */
const box = {
  ...boxWithoutPadding,
  variants: {
    ...boxWithoutPadding.variants,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingX,
    paddingY,
  },
};

/* Creators
  ============================================ */

export const createLayout = () => box;
export const createComponent = () => boxWithoutPadding;
