import util from "Blocks/utils/util";
import { eventTracker } from "Blocks/tracker";
import ajax from "Blocks/utils/ajax";
import { renderError } from "Blocks/alert";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

// This is an example URL query for reference:
// /signup?email=email&company=NorthoftheWall

const map = {
  email: "user_email",
  referrer: "user_organization_referrer",
  company: "user_organization_name",
};

class RegistrationsNew extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-track-signup-submit", () => {
      eventTracker("trackSignupSubmit");
    });
    const url = new URLSearchParams(window.location.search);
    Object.keys(map).forEach((key) => {
      if (url.get(key)) {
        const field = document.getElementById(map[key]);
        field.value = url.get(key);
      }
    });
  }

  load() {}

  beforeCache() {}
}

const registrationsNew = new RegistrationsNew();
export default registrationsNew;
