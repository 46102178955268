import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import moment from "moment";
import { Field } from "@plan";
import { useDebouncedSave } from "@hooks";
import { useUpdateEntry } from "Components/api/index";
import cx from "classnames";
import { renderError } from "Blocks/alert";
import EntryItemPopup from "../EntryItemPopup";
import ExpenseModal from "../ExpenseModal";
import {
  useGlobalState,
  updateEntry,
  updateSelectedEntry,
} from "./state/GlobalContext";
import styles from "./entry.module.css";

const useKeyNav = (rowId, date, setIsOpen) => {
  const { state, dispatch } = useGlobalState();

  const nextEntryInColumn = (rowId, dir) => {
    const currIndex = state.orderedRows.indexOf(rowId);
    return state.orderedRows[currIndex + dir];
  };

  const checkIsSelected = (newRowId) => {
    if (state.selectedEntry.rowId === newRowId) {
      return state.selectedEntry.date == date;
    }
  };

  const noteKeyHandler = (e) => {
    const TABKEY = 9;
    if (e.shiftKey && e.keyCode === TABKEY) {
      // default will focus back into entry cell
      return;
    }
    if (e.keyCode === TABKEY) {
      e.preventDefault();
      // focus input into next entry cell
      setIsOpen(false);
      const d = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
      dispatch(updateSelectedEntry({ rowId, date: d }));
    }
  };

  const keyHandler = (e) => {
    const TABKEY = 9;
    const UPKEY = 38;
    const DOWNKEY = 40;
    const LEFTKEY = 37;
    const RIGHTKEY = 39;

    const input = e.currentTarget;
    const cursorPosition = input.selectionStart;
    const valueLength = input.value.length;

    switch (e.keyCode) {
      case TABKEY: {
        if (e.shiftKey) {
          setIsOpen(false);
        }
        break;
      }
      case UPKEY: {
        e.preventDefault();
        setIsOpen(false);
        const newRowId = nextEntryInColumn(rowId, -1);
        dispatch(updateSelectedEntry({ rowId: newRowId, date }));
        break;
      }
      case DOWNKEY: {
        e.preventDefault();
        setIsOpen(false);
        const newRowId = nextEntryInColumn(rowId, 1);
        dispatch(updateSelectedEntry({ rowId: newRowId, date }));
        break;
      }
      case LEFTKEY: {
        if (cursorPosition === 0) {
          e.preventDefault();
          setIsOpen(false);
          const d = moment(date, "YYYY-MM-DD")
            .add(-1, "days")
            .format("YYYY-MM-DD");
          dispatch(updateSelectedEntry({ rowId, date: d }));
        }
        break;
      }
      case RIGHTKEY: {
        if (cursorPosition === valueLength) {
          e.preventDefault();
          setIsOpen(false);
          const d = moment(date, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD");
          dispatch(updateSelectedEntry({ rowId, date: d }));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const isSelected = checkIsSelected(rowId);

  return { isSelected, keyHandler, noteKeyHandler };
};

function Entry({
  rowId,
  date,
  blockName,
  canEdit,
  projectAndPhaseAndActivityId,
  hasActivity,
  activityRequired,
  noteExample,
  notesRequired,
  projectId,
  isOverhead = false,
}) {
  const { state, dispatch } = useGlobalState();
  const containerRef = useRef();
  const inputRef = useRef();
  const entry = state.entriesByRowId[rowId][date];
  const { hours, notes } = entry;
  const [_, putEntry] = useUpdateEntry({ renderErrors: true });

  const saveEntry = useCallback(() => {
    const isValidHoursValue = (value) =>
      value === "" || value === "." || !Number.isNaN(parseFloat(value));

    if (isValidHoursValue(hours)) {
      const data = {
        entry_row_id: rowId,
        date,
        hours: hours === "" || hours === "." ? 0 : parseFloat(hours),
        notes,
      };
      putEntry(data);
    } else {
      renderError("All entries must have numeric values");
    }
  }, [rowId, date, hours, notes]);

  const hoursNotes = useMemo(() => ({ hours, notes }), [hours, notes]);
  useDebouncedSave(hoursNotes, saveEntry, { delay: 1000, utilSave: true });

  const [isOpen, setIsOpen] = useState(false);
  const { isSelected, keyHandler, noteKeyHandler } = useKeyNav(
    rowId,
    date,
    setIsOpen
  );

  const [displayValue, setDisplayValue] = useState(
    hours > 0 ? hours.toString() : ""
  );

  useEffect(() => {
    setDisplayValue(hours > 0 ? hours.toString() : "");
  }, [hours]);

  const expenses = entry.expenses.map(
    (expenseId) => state.expenses.byId[expenseId]
  );
  const entryNotes = entry.notes;
  const [isExpenseOpen, setIsExpenseOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleFocus = (show, e) => {
    if (canEdit && (entryNotes || expenses)) {
      // if the focus is still in the input area, we want to make sure
      // the popover stays open, we only pass `e` on blur so that is
      // needed in this check
      if (e && containerRef && containerRef.current.contains(e.target)) {
        // eslint-disable-next-line no-useless-return
        // eslint-disable-next-line no-else-return
      } else {
        setIsOpen(show);
      }
    }
  };

  const anchorId = `${projectAndPhaseAndActivityId}-${date}`;
  const currentRoute = window.location.href;

  useEffect(() => {
    if (currentRoute.includes(anchorId)) {
      // leverage the event loop to wait for the component to render before calling updateSelectedEntry
      // https://www.youtube.com/watch?v=8aGhZQkoFbQ&t=895s
      setTimeout(() => dispatch(updateSelectedEntry({ rowId, date })), 0);
      handleFocus(true);
    }
  }, [anchorId]);

  useEffect(() => {
    if (isSelected) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [isSelected]);

  const handlePopupFocus = (show) => {
    setIsOpen(show);
  };

  const updateNotesHandler = (text) => {
    dispatch(
      updateEntry({
        rowId,
        date,
        hours: entry.hours,
        notes: text,
      })
    );
  };

  // Treat these as display only values in the input
  const zeroValues = ["0", "0.", "0.0", "0.00", "."];

  const updateHandler = (e) => {
    const inputValue = e.target.value;

    // Disallow negative numbers here
    if (parseInt(e.target.value) < 0) return;

    // if the input is empty, allow decimals but don't set the global value to be saved
    if (zeroValues.includes(inputValue)) return setDisplayValue(inputValue);
    setDisplayValue(inputValue);
    return dispatch(
      updateEntry({ rowId, date, hours: inputValue, notes: entryNotes })
    );
  };

  // eslint-disable-next-line consistent-return
  const handleBlur = (e) => {
    // set the value to 0 if the input is empty
    // in cases like "."
    if (zeroValues.includes(e.target.value)) {
      setDisplayValue("");
      return dispatch(
        updateEntry({ rowId, date, hours: 0, notes: entryNotes })
      );
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const Tooltip = isHovered ? (
    <div className={styles.toolTipContainer}>
      <div className={styles.toolTipPointer}>
        <svg
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5134 0.6621C8.3079 -0.2207 9.6921 -0.2207 10.4866 0.6621L18 9.0103H0L7.5134 0.6621Z"
            fill="#1F2937"
          />
        </svg>
      </div>
      <div className={styles.toolTip}>
        Select an activity to record your hours.
      </div>
    </div>
  ) : null;

  const showNotesWarning = entryNotes === "" && notesRequired && !!displayValue;

  const disabledEntry = (
    <div
      className={styles.entryField}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Field
        as="text"
        type="text"
        variant="timeInput"
        format="time"
        size="small"
        placeholder="0.00"
        value={displayValue}
        blockClasses="expenseInput"
        classes="expenseInput"
        treatZeroAsEmpty
        forwardRef={inputRef}
        isDisabled
      />
      {Tooltip}
    </div>
  );

  const enabledEntry = (
    <Field
      as="text"
      type="text"
      variant="timeInput"
      format="time"
      size="small"
      placeholder="0.00"
      value={displayValue}
      handleChange={updateHandler}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
      blockClasses="expenseInput"
      classes="expenseInput"
      treatZeroAsEmpty
      handleKeyDown={keyHandler}
      forwardRef={inputRef}
      warning={showNotesWarning}
    />
  );

  const cantEditEntry = (
    <div className={styles.entryView}>
      {displayValue || <div className={styles.entryViewZero}>0.00</div>}
    </div>
  );

  const entryField = () => {
    if (!canEdit) return cantEditEntry;
    if (!activityRequired) return enabledEntry;

    return hasActivity ? enabledEntry : disabledEntry;
  };

  return (
    <>
      <div className={styles.anchor} id={anchorId} />
      <div
        ref={containerRef}
        className={styles.entry}
        onFocus={() => handleFocus(true)}
        onBlur={(e) => handleFocus(false, e)}
      >
        <div className={styles.entryField}>
          {entryField()}
          {expenses.length > 0 && <ExpenseIcon canEdit={canEdit} />}
          {entryNotes ? (
            <NotesIcon canEdit={canEdit} />
          ) : (
            <WarningIcon showNotesWarning={showNotesWarning} />
          )}
        </div>
        {isOpen && (
          <div className={styles.entryPopup}>
            <EntryItemPopup
              date={date}
              notes={entryNotes}
              expenses={expenses}
              setNotesVisibility={handlePopupFocus}
              setNotesOnParent={updateNotesHandler}
              keyHandler={noteKeyHandler}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              showExpenseModal={() => setIsExpenseOpen(true)}
              canEdit={canEdit}
              noteExample={noteExample}
              notesRequired={notesRequired}
              showNotesWarning={showNotesWarning}
            />
          </div>
        )}
      </div>
      <ExpenseModal
        show={isExpenseOpen}
        closeModal={() => setIsExpenseOpen(false)}
        editable
        date={date}
        entryRowId={rowId}
        projectName={blockName}
        projectId={projectId}
        isOverhead={isOverhead}
      />
    </>
  );
}

const NotesIcon = ({ canEdit }) => (
  <div
    className={cx(
      { [styles.entryNotesIcon]: canEdit },
      { [styles.entryViewNotesIcon]: !canEdit }
    )}
  >
    <svg
      width="4"
      height="5"
      viewBox="0 0 4 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="-0.5" x2="4" y2="-0.5" transform="matrix(-1 0 0 1 4 1)" />
      <line y1="-0.5" x2="4" y2="-0.5" transform="matrix(-1 0 0 1 4 3)" />
      <line y1="-0.5" x2="3" y2="-0.5" transform="matrix(-1 0 0 1 4 5)" />
    </svg>
  </div>
);

const WarningIcon = ({ showNotesWarning }) =>
  showNotesWarning ? (
    <div className={styles.entryWarningIcon}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.1665 6.99996C1.1665 3.7783 3.77818 1.16663 6.99984 1.16663C8.54693 1.16663 10.0307 1.78121 11.1246 2.87517C12.2186 3.96913 12.8332 5.45286 12.8332 6.99996C12.8332 10.2216 10.2215 12.8333 6.99984 12.8333C3.77818 12.8333 1.1665 10.2216 1.1665 6.99996ZM2.33317 6.99996C2.33317 9.57729 4.42251 11.6666 6.99984 11.6666C8.23751 11.6666 9.4245 11.175 10.2997 10.2998C11.1748 9.42462 11.6665 8.23764 11.6665 6.99996C11.6665 4.42263 9.57717 2.33329 6.99984 2.33329C4.42251 2.33329 2.33317 4.42263 2.33317 6.99996ZM6.70817 8.16663C6.54709 8.16663 6.4165 8.29721 6.4165 8.45829V9.04163C6.4165 9.20271 6.54709 9.33329 6.70817 9.33329H7.2915C7.45259 9.33329 7.58317 9.20271 7.58317 9.04163V8.45829C7.58317 8.29721 7.45259 8.16663 7.2915 8.16663H6.70817ZM6.60317 4.66663H7.3965C7.48032 4.66602 7.56035 4.70151 7.61618 4.76403C7.672 4.82656 7.69823 4.91008 7.68817 4.99329L7.45484 6.87163C7.44594 6.94527 7.38318 7.00049 7.309 6.99996H6.69067C6.6165 7.00049 6.55374 6.94527 6.54484 6.87163L6.3115 4.99329C6.30144 4.91008 6.32767 4.82656 6.3835 4.76403C6.43932 4.70151 6.51935 4.66602 6.60317 4.66663Z"
          fill="#B77E10"
        />
      </svg>
    </div>
  ) : null;

const ExpenseIcon = ({ canEdit }) => (
  <div
    className={cx(
      { [styles.entryExpenseIcon]: canEdit },
      { [styles.entryViewExpenseIcon]: !canEdit }
    )}
  >
    {currencySymbol}
  </div>
);

export default Entry;
