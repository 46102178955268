import * as React from "react";

import { ModalTrapProvider } from "./hooks/useModalTrap/context";

import { boxSizing, elements, generic, legacy, normalize } from "./global.css";

/* Unified Provider
  ============================================ */

export const PlanProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  boxSizing();
  normalize();
  generic();
  elements();
  legacy();

  return <ModalTrapProvider>{children}</ModalTrapProvider>;
};
