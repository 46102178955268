/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
const ReactRailsUJS = require("react_ujs");
const _ = require("lodash");
const components = require("../components/**/*.jsx");

const componentsContext = _.reduce(
  components.filenames,
  (prev, curr, idx) => {
    if (!components.default[idx].default) {
      return prev;
    }

    const truncatedPath = curr
      .replace(".jsx", "")
      .replace("../components/", "");

    // eslint-disable-next-line no-param-reassign
    prev[truncatedPath] = components.default[idx].default;
    return prev;
  },
  {}
);

ReactRailsUJS.getConstructor = (name) => componentsContext[name];
ReactRailsUJS.handleEvent("turbo:load", ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent("turbo:frame-load", ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent(
  "turbo:before-render",
  ReactRailsUJS.handleUnmount,
  false
);
