import * as React from "react";

import { createComponent, styled, theme } from "@plan/core";

import * as AvatarBase from "@radix-ui/react-avatar";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

const component = createComponent();

const StyledFallback = styled(AvatarBase.Fallback, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100%",
  minWidth: "100%",
  textTransform: "uppercase",
  color: "$colors$neutral40",
  fontWeight: "$semibold",
  fontSize: "inherit",
});

const StyledAvatar = styled(AvatarBase.Root, {
  ...component,
  $$avatarSize: "$space$8",
  height: "$$avatarSize",
  width: "$$avatarSize",
  minWidth: "$$avatarSize",
  minHeight: "$$avatarSize",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  verticalAlign: "middle",
  overflow: "hidden",
  userSelect: "none",
  fontSize: "$fontSizes$lg",
  variants: {
    ...component.variants,
    size: {
      xsmall: {
        $$avatarSize: "$space$4",
        fontSize: "$fontSizes$xs",
      },
      small: {
        $$avatarSize: "$sizes$md",
      },
      medium: {
        $$avatarSize: "$space$8",
      },
      large: {
        $$avatarSize: "$sizes$xl",
        fontSize: "$fontSizes$2xl",
      },
      xxlarge: {
        $$avatarSize: "$space$60",
        fontSize: "$fontSizes$7xl",
      },
    },
    shape: {
      circle: {
        borderRadius: "50%",
      },
      square: {
        borderRadius: "0",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    size: "medium",
  },
});

const StyledImage = styled(AvatarBase.Image, {
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

type AvatarProps = React.ComponentProps<typeof StyledAvatar> &
  Polymorphic.OwnProps<typeof AvatarBase.Root> & {
    initials?: string;
    src?: string;
    fallbackColor?: keyof typeof theme.colors;
  };

export const Avatar = ({
  size = "medium",
  shape = "circle",
  initials = "",
  fallbackColor = "-neutral30",
  src,
  ...props
}: AvatarProps) => {
  return (
    <StyledAvatar size={size} shape={shape} {...props}>
      <StyledImage src={src} />
      <StyledFallback css={{ backgroundColor: `$colors$${fallbackColor}` }}>
        {initials}
      </StyledFallback>
    </StyledAvatar>
  );
};
