import React from "react";
import ReactSelect, { components } from "react-select";
import CreateSelect from "react-select/creatable";

export const Option = (props) => (
  <span>
    <components.Option {...props} />
  </span>
);

function CreateSelectStyles(props) {
  const { withPadding, size, showSeparator } = props;
  return {
    container: (provided, state) => ({
      width: "100%",
      height: "100%",
      position: "relative",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: 0,
      color: "var(--color-neutral-10)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow: "visible",
      fontSize: "1rem",
      lineHeight: "1",
      color: "#011627",
      margin: 0,
    }),
    control: (provided, state) => ({
      background: "transparent",
      fontSize: "1rem",
      height: "100%",
      lineHeight: "2rem",
      outline: "none",
      // padding: '0.875rem .5rem 0 0.75rem',
      padding: "0.875rem 0 0 0.75em",
      paddingTop: size === "small" ? 0 : null,
      paddingLeft: size === "small" ? 0 : null,
      display: "flex",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
    }),
    indicatorSeparator: (provided, state) => ({
      display: showSeparator ? "block" : "none",
      padding: withPadding ? provided.padding : 0,
      backgroundColor: "var(--color-legacy-gray-3)",
      width: size === "small" ? "1px" : "",
      alignSelf: "stretch",
      margin: ".5rem",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      width: "1rem",
      color: "var(--color-legacy-gray-4)",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      paddingLeft: size === "small" ? ".75rem" : 0,
      // fix some obscure alignment issue
      marginTop: "1px",
      // overflow: 'visible',
    }),
    input: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      width: "100%",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: withPadding ? provided.padding : 0,
      lineHeight: 1.5,
      zIndex: 100,
      whiteSpace: "nowrap",
      width: "auto",
      minWidth: "100%",
    }),
    option: (provided, state) => {
      const isAddButton = state.data.value === "--add-button";
      return {
        ...provided,
        background: state.isFocused
          ? "var(--color--brand-10)"
          : state.isSelected
          ? "var(--color-brand)"
          : state.isActive
          ? "var(--color-brand-10)"
          : "#fff",
        color:
          state.isFocused || state.isSelected
            ? "white"
            : isAddButton
            ? "var(--color-brand)"
            : state.isFocused && isAddButton
            ? "var(--color-white)"
            : "black",
        cursor: "pointer",
        ":active": {
          ...provided[":active"],
          backgroundColor: "var(--color-brand-10)",
        },
      };
    },
  };
}

export function CreateableSelect(props) {
  const { handleChange, handleInputChange, handleCreate, options } = props;

  const styles = CreateSelectStyles(props);
  return (
    <CreateSelect
      {...props}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onCreateOption={handleCreate}
      styles={styles}
      options={options}
    />
  );
}

export function Select(props) {
  const { handleChange, options } = props;

  const styles = CreateSelectStyles(props);

  return (
    <ReactSelect
      {...props}
      styles={styles}
      options={options}
      onChange={handleChange}
    />
  );
}

export default React.memo(Select);
