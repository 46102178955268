/* eslint-disable react/prop-types */
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import React from "react";
import moment from "moment";
import WeekPicker from "Components/blocks/WeekPicker/WeekPicker";
import Field from "Components/blocks/forms/Field";
import {
  Permissions,
  useIsAllowed,
} from "Components/blocks/permissions/Permissions";
import { MultiSelect, Toggle } from "@plan";
import { eventTracker } from "Blocks/tracker";
import { useSchedule } from "../state/ScheduleContext";
import GridViewToggle from "./grid-view-toggle/GridView";
import styles from "./filters.module.css";
import { defaultFilters, formatDate } from "../Schedule";

export const getOptionsFromIds = (options, ids) => {
  if (!ids) return null;
  const idsArray = typeof ids === "string" ? ids.split(",") : ids;
  return idsArray.map((id) => options.find((option) => option.value === id));
};

const buildFilterOptions = (filterableDataItem) =>
  filterableDataItem.allIds.map((id) => ({
    label: filterableDataItem.byId[id].name,
    value: filterableDataItem.byId[id].id,
  }));

const sortOptions = [
  { label: "Project Number", value: "number" },
  { label: "Project Name", value: "name" },
];

const Filters = ({
  updateFilters,
  currentFilters,
  loading,
  clearFilter,
  filterableData,
}) => {
  const { date, projectId, categoryIds, profileIds, projectSort, gridView } =
    currentFilters;

  const { viewFinancials, setViewFinancials } = useIsAllowed([
    "canViewFinancialInformation",
  ])
    ? useSchedule()
    : false;

  const { projects, categories, profiles } = filterableData;

  const projectFilterOptions = buildFilterOptions(projects);
  const categoryFilterOptions = buildFilterOptions(categories);
  const profileFilterOptions = buildFilterOptions(profiles).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const handleFinancialsToggle = () => {
    setViewFinancials(!viewFinancials);
    eventTracker("trackResourceFinancialToggleEdit", { value: viewFinancials });
  };

  const handleProjectFilter = (e) => {
    if (!e) {
      clearFilter("projectId");
      eventTracker("trackResourceFilterCleared", { filter: "Project" });
      return;
    }

    updateFilters({ projectId: e.value });
    eventTracker("trackResourceFilterApplied", {
      filter: "Project",
      value: e.label,
    });
  };

  const handleProjectSort = (newSort) => {
    eventTracker("trackResourceSort", {
      prevSort: projectSort,
      newSort: newSort?.label || "default",
    });

    updateFilters({
      projectSort: newSort?.value || defaultFilters.projectSort,
    });
  };

  const handleCategoryFilter = (e) => {
    if (!e || _isEmpty(e)) {
      clearFilter("categoryIds");
      eventTracker("trackResourceFilterCleared", { filter: "Category" });
      return;
    }

    const selectedCategoryIds = _map(e, (category) => category.value);
    updateFilters({ categoryIds: selectedCategoryIds });

    eventTracker("trackResourceFilterApplied", {
      filter: "Category",
      value: `${selectedCategoryIds.length} Categories`,
    });
  };

  const handleProfileFilter = (e) => {
    if (!e || _isEmpty(e)) {
      clearFilter("profileIds");
      eventTracker("trackResourceFilterCleared", { filter: "Profiles" });
      return;
    }

    const selectedProfileIds = _map(e, (profile) => profile.value);

    eventTracker("trackResourceFilterApplied", {
      filter: "Profiles",
      value: `${selectedProfileIds.length} Profiles`,
    });

    updateFilters({ profileIds: selectedProfileIds });
  };

  const handleGridViewChange = (newGridView) =>
    updateFilters({ gridView: newGridView });

  const handleSetDate = (newDate) => {
    updateFilters({ date: formatDate(newDate) });
    eventTracker("trackResourceDateChanged", {
      prevDate: formatDate(date),
      newDate: formatDate(newDate),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className="grid-xs-12">
        <div className={styles.filters}>
          <WeekPicker date={moment(date)} setDate={handleSetDate} />
          <Field
            as="select"
            size="small"
            placeholder="Sort By"
            value={sortOptions.find((option) => option.value === projectSort)}
            isDisabled={loading}
            options={sortOptions}
            handleChange={handleProjectSort}
            blockClasses={styles.select}
          />
          <Field
            as="select"
            size="small"
            placeholder="Project"
            isDisabled={loading}
            value={projectFilterOptions.find(
              (option) => option.value === projectId
            )}
            options={projectFilterOptions}
            handleChange={handleProjectFilter}
            blockClasses={styles.select}
            isClearable
          />
          <div style={{ zIndex: 15 }}>
            <MultiSelect
              value={getOptionsFromIds(categoryFilterOptions, categoryIds)}
              placeholder="Category"
              selectedLabel="Category"
              options={categoryFilterOptions}
              handleChange={handleCategoryFilter}
              size="small"
              isMulti
            />
          </div>
          <div style={{ zIndex: 15 }}>
            <MultiSelect
              value={getOptionsFromIds(profileFilterOptions, profileIds)}
              placeholder="People"
              selectedLabel="People"
              options={profileFilterOptions}
              handleChange={handleProfileFilter}
              size="small"
              isMulti
            />
          </div>
          <div className={styles.filtersRight}>
            <Permissions needsPermissions={["canViewFinancialInformation"]}>
              <div
                className={styles.financials}
                onClick={handleFinancialsToggle}
                role="button"
              >
                {/* Element is just for display so we don't need an
                onChange function but the component requires it */}
                <Toggle value={viewFinancials} onChange={() => null} />
                Financials
              </div>
            </Permissions>
            <GridViewToggle
              gridView={gridView}
              setGridView={handleGridViewChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
