import { FC, useRef, useState } from "react";
import { useClickAway } from "react-use";

import { styled } from "@plan/core";

import { useTimerContext } from "../../contexts/TimerContext";
import {
  TimerModalBodyView,
  TimerModalViewContext,
} from "../../contexts/TimerModalViewContext";
import { TimerLostTimeWarning } from "../TimerLostTimeWarning";

import { TimerModalBody } from "./TimerModalBody";
import { TimerModalHeader } from "./TimerModalHeader";
import { TimerModalNav } from "./TimerModalNav";

const ModalContainer = styled("div", {
  position: "absolute",
  borderRadius: "5px",
  display: "none",
  transform: "translateY(-$space$1)",
  top: "calc(100% + $space$2)",
  left: "0",
  backgroundColor: "$white",
  color: "$colors$neutral40",
  width: "25rem",
  boxShadow: "0 $space$1 $space$4 rgba(1, 22, 39, 0.125)",
  zIndex: "$modal",
  cursor: "default",
  transition: "all 160ms ease-out",
  variants: {
    isActive: {
      true: {
        display: "block",
        transform: "translateY(0)",
        transition: "all 160ms ease-in",
      },
    },
  },
});

export const TimerModal: FC = () => {
  const {
    state: { isTimerModalVisible },
    actions: { hideTimerModal },
  } = useTimerContext();

  const timerRef = useRef(null);

  const [timerModalBodyView, setTimerModalBodyView] =
    useState<TimerModalBodyView>("form");

  const modalViewContextValues = {
    timerModalView: timerModalBodyView,
    showEntryList: () => setTimerModalBodyView("list"),
    showEntryForm: () => setTimerModalBodyView("form"),
  };

  useClickAway(timerRef, () => {
    if (!isTimerModalVisible) return;

    hideTimerModal();
  });

  return (
    <TimerModalViewContext.Provider value={modalViewContextValues}>
      <TimerLostTimeWarning />
      <ModalContainer isActive={isTimerModalVisible} ref={timerRef}>
        <TimerModalNav />
        <TimerModalHeader />
        <TimerModalBody />
      </ModalContainer>
    </TimerModalViewContext.Provider>
  );
};
