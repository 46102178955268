import * as React from "react";

import { styled } from "@plan/core";
import { Box } from "@plan/layout";

import { format, isFirstDayOfMonth, parse } from "date-fns";

const StyledWrapper = styled("text", {});

const StyledLabel = styled("tspan", {
  fontSize: "$fontSizes$sm",
  lineHeight: "1",
  fill: "$colors$neutral10",
  variants: {
    uppercase: {
      true: {
        textTransform: "uppercase",
      },
    },
    partial: {
      true: {
        fontSize: "$fontSizes$xs",
        fontWeight: "$semibold",
      },
    },
    highlighted: {
      true: {
        fill: "$black",
      },
    },
    negative: {
      true: {
        fill: "$colors$danger10",
      },
    },
    align: {
      start: {
        textAnchor: "start",
      },
      middle: {
        textAnchor: "middle",
      },
      end: {
        textAnchor: "end",
      },
    },
  },
  defaultVariants: {
    align: "start",
  },
});

type DayLabelProps = {
  dayOfWeek: string | number;
  isFirstOfMonth: boolean;
  month: string | Date;
  dayNumber: number | string;
  hour?: string;
};
const DayLabel = ({
  dayOfWeek,
  isFirstOfMonth,
  month,
  dayNumber,
  hour,
}: DayLabelProps) => {
  const parsedMonth = typeof month === "string" ? month : format(month, "MMM");
  return (
    <>
      {dayOfWeek}{" "}
      <Box as="tspan" css={{ fill: "$colors$neutral10" }}>
        {dayNumber}
      </Box>
      {hour?.length ? (
        <Box as="tspan" css={{ fill: "$colors$neutral10" }}>
          {" "}
          {hour}
        </Box>
      ) : (
        <>
          {isFirstOfMonth && (
            <StyledLabel uppercase> {parsedMonth}</StyledLabel>
          )}
        </>
      )}
    </>
  );
};

type MonthLabelProps = {
  month: string | number;
  year: string | number;
  isPartial?: boolean;
};
const MonthLabel = ({ month, year, isPartial }: MonthLabelProps) => (
  <>
    <Box as="tspan" css={{ textTransform: "uppercase" }}>
      {month}
    </Box>
    {month === "Jan" && <> {year}</>}
    {isPartial && <StyledLabel partial> (part) </StyledLabel>}
  </>
);

type QuarterLabelProps = {
  quarter: string | number;
  year: string | number;
  isPartial?: boolean;
};
const QuarterLabel = ({ quarter, year, isPartial }: QuarterLabelProps) => (
  <>
    {quarter}
    {quarter === "Q1" && <> {year}</>}
    {isPartial && <StyledLabel> (part) </StyledLabel>}
  </>
);

type DateLabelValueProps = {
  value: string;
  dateIncrement?: string;
  isPartial?: boolean;
};

const DateLabelValue = ({
  value,
  dateIncrement,
  isPartial,
}: DateLabelValueProps) => {
  const date = parse(value, "yyyy-MM-dd", new Date());
  const hour = format(date, "hh");
  const dayOfWeek = format(date, "EEEEE");
  const dayNumber = format(date, "d");
  const month = format(date, "MMM");
  const quarter = format(date, "QQQ");
  const year = format(date, "yyyy");
  const isFirstOfMonth = isFirstDayOfMonth(date);

  switch (dateIncrement) {
    case "HOUR":
      return (
        <DayLabel
          dayOfWeek={dayOfWeek}
          isFirstOfMonth={isFirstOfMonth}
          month={month}
          dayNumber={dayNumber}
          hour={hour}
        />
      );
    case "MONTH":
      return <MonthLabel year={year} month={month} isPartial={isPartial} />;
    case "QUARTER":
      return (
        <QuarterLabel quarter={quarter} year={year} isPartial={isPartial} />
      );
    default:
      return (
        <DayLabel
          dayOfWeek={dayOfWeek}
          isFirstOfMonth={isFirstOfMonth}
          month={month}
          dayNumber={dayNumber}
        />
      );
  }
};

const truncateText = (text: string, characters: number) => {
  if (text.length > characters) {
    return `${text.substring(0, characters - 1)}...`;
  }
  return text;
};

type LabelProps = React.ComponentProps<typeof StyledLabel> & {
  x: number | null;
  y: number | null;
  dy?: number;
  dx?: number;
  rotated?: boolean;
};

const getPosWithRotatedProp = (x: number, y: number, rotated?: boolean) => {
  // If rotated is true, we want to transform the text to 45 degree and not pass x and y values
  const xPos = rotated ? "" : x;
  const yPos = rotated ? "" : y;
  const transform = rotated ? `translate(${x}, ${y}) rotate(-45)` : undefined;
  const transformBox = rotated ? "fill-box" : "none";
  return { xPos, yPos, transform, transformBox };
};

type TextLabelProps = LabelProps & {
  lineLength?: number;
  value: string;
};

export const TextLabel = React.forwardRef<SVGTSpanElement, TextLabelProps>(
  (
    {
      value,
      x,
      y,
      dy,
      dx,
      align,
      highlighted,
      rotated,
      lineLength = 15,
      ...props
    },
    forwardRef
  ) => {
    const { xPos, yPos, transform } = getPosWithRotatedProp(x, y, rotated);
    return (
      <StyledWrapper
        x={xPos}
        y={yPos}
        dy={dy}
        dx={dx}
        transform={transform}
        ref={forwardRef}
      >
        <StyledLabel
          align={rotated ? "end" : align}
          highlighted={highlighted}
          {...props}
        >
          {truncateText(value, lineLength)}
        </StyledLabel>
      </StyledWrapper>
    );
  }
);

type DateLabelProps = LabelProps & {
  value: string;
  isPartial?: boolean;
  dateIncrement?: "HOUR" | "DAY" | "MONTH" | "QUARTER";
};

export const DateLabel = React.forwardRef<SVGTSpanElement, DateLabelProps>(
  (
    {
      value,
      x,
      y,
      dy,
      dx,
      align,
      highlighted,
      dateIncrement,
      isPartial,
      rotated,
      ...props
    },
    forwardRef
  ) => {
    const { xPos, yPos, transform } = getPosWithRotatedProp(x, y, rotated);

    return (
      <StyledWrapper
        x={xPos}
        y={yPos}
        dy={dy}
        dx={dx}
        transform={transform}
        ref={forwardRef}
      >
        <StyledLabel
          align={rotated ? "end" : align}
          highlighted={highlighted}
          ref={forwardRef}
          {...props}
        >
          <DateLabelValue
            value={value}
            dateIncrement={dateIncrement}
            isPartial={isPartial}
          />
        </StyledLabel>
      </StyledWrapper>
    );
  }
);

/**
 * ## HourLabel Props:
 * - value: string, value to display
 * - x: number, position of label
 * - y: number, position of label
 * - dx / dy: number, shifts element position
 * - align: 'start' | 'middle' | 'end'
 * - uppercase: boolean, uppercase the label
 */
type HourLabelProps = React.ComponentProps<typeof StyledLabel> & {
  value: string;
  x: number;
  y: number;
  dy?: number;
  dx?: number;
  align?: "start" | "middle" | "end";
};
export const HourLabel = React.forwardRef<SVGTSpanElement, HourLabelProps>(
  ({ value, x, y, dy, dx, align, ...props }, forwardRef) => {
    return (
      <StyledWrapper x={x} y={y} dx={dx} dy={dy} ref={forwardRef}>
        <StyledLabel align={align} {...props}>
          {value} h
        </StyledLabel>
      </StyledWrapper>
    );
  }
);

type EmptyDataLabelProps = React.ComponentProps<typeof StyledLabel>;

export const EmptyDataLabel = React.forwardRef<
  SVGTSpanElement,
  EmptyDataLabelProps
>(({ children, ...props }, forwardRef) => {
  return (
    <>
      <StyledWrapper x="50%" y="50%">
        <StyledLabel align="middle" ref={forwardRef} {...props}>
          {children}
        </StyledLabel>
      </StyledWrapper>
    </>
  );
});

type ChartLabelProps = LabelProps & {
  value?: string;
  children?: React.ReactNode;
};

/**
 * ## Description
 * A label is text component that can be used to display information for axis, bars, etc.
 *
 * ## Label props:
 * - `x` (Required): X position of the label.
 * - `y` (Required): Y position of the label.
 * - `dy`/`dx`: Shifts the element's position vertically/horizontally by the passed value.
 * - `align`: Text for the label. Variants: 'start' | 'middle' | 'end'. Default 'start'.
 * - `highlighted`: Text for the label.
 * - `css`: Additional css for the label.
 * - `children`: ReactNode to be rendered inside the label.
 *
 */
export const ChartLabel = React.forwardRef<SVGTSpanElement, ChartLabelProps>(
  (
    { children, x, y, dy, dx, align, highlighted, rotated, ...props },
    forwardRef
  ) => {
    const { xPos, yPos, transform, transformBox } = getPosWithRotatedProp(
      x,
      y,
      rotated
    );

    return (
      <>
        <StyledWrapper
          x={xPos}
          y={yPos}
          dy={dy}
          dx={dx}
          transform={transform}
          css={{
            transformBox,
          }}
        >
          <StyledLabel
            align={align}
            highlighted={highlighted}
            ref={forwardRef}
            {...props}
          >
            {children}
          </StyledLabel>
        </StyledWrapper>
      </>
    );
  }
);
