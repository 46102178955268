import React from "react";
import cx from "classnames";
import styles from "./week-picker.module.css";

export default function NavButton({
  handleClick,
  disabled,
  variant,
  children,
}) {
  const classes = cx(styles.navButton, { [styles[variant]]: variant });
  return (
    <button
      type="button"
      disabled={disabled}
      className={classes}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
