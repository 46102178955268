import React from "react";
import { useFieldState } from "./FieldContext";
import styles from "./field.module.css";

export function Error({ children }) {
  const { error, errorMessage } = useFieldState();
  if (!error) return null;
  return (
    <div className={styles.field__error}>
      {errorMessage}
      {children}
    </div>
  );
}

export default React.memo(Error);
