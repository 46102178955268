import React from "react";
import PropTypes from "prop-types";
import Alert from "Components/blocks/alert/Alert";

export const Form = ({ children, onSubmit, error, errorMessage }) => (
  <form onSubmit={onSubmit}>
    {error && (
      <Alert
        message={errorMessage}
        variant="warning"
        style={{ margin: "0 0 1rem" }}
      />
    )}
    {children}
  </form>
);

Form.defaultProps = {
  errorMessage: "There was an issue with your request",
  error: false,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default React.memo(Form);
