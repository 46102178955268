import React from "react";
import PropTypes from "prop-types";
import Table from "Components/blocks/Table/Table";
import Avatar from "Components/blocks/avatar/Avatar";
import TimesheetStatus from "Components/timesheets/TimesheetStatus";
import TableActions from "Components/timesheets/TableActions";
import TableButton from "Components/blocks/buttons/TableButton";
import ToggleLock from "Components/timesheets/ToggleLock";
import PermissionCheck from "Components/blocks/PermissionCheck";
import DateDisplay from "Components/blocks/DateDisplay";
import ajax from "Blocks/utils/ajax";
import { renderSuccess } from "Blocks/alert";
import { downloadAsCSV } from "Blocks/utils/util";
import { eventTracker } from "Blocks/tracker";

function ProfileTable({ profile, timesheets }) {
  const handleViewButton = (timesheet) => {
    eventTracker("trackTimesheetSelect");
    Turbolinks.visit(`/timesheets/${profile.slug}/${timesheet.slug}`);
  };

  const handleDownloadButton = (timesheet) => {
    ajax({
      method: "POST",
      path: `/timesheets/export/${timesheet.id}`,
      success: (response) => {
        const fileName = `Timesheet ${timesheet.begin_date} ${profile.lname}, ${profile.fname}.csv`;
        downloadAsCSV(response, fileName);
        renderSuccess("Created CSV File");
      },
    });
  };

  const loading = "Loading";

  const columns = [
    {
      Header: "Week",
      accessor: "begin_date",
      Cell: (cell) => {
        const timesheet = cell.data[cell.row.index];
        return (
          <PermissionCheck
            permission={timesheet.permissions.can_edit}
            value={timesheet.begin_date}
          >
            <a href={`/timesheets/${profile.slug}/${timesheet.begin_date}`}>
              {timesheet.begin_date}
            </a>
          </PermissionCheck>
        );
      },
    },
    {
      Header: "Status",
      accessor: "is_submitted",
      Cell: ({ cell }) => <TimesheetStatus isSubmitted={cell.value} />,
      sortType: "basic",
    },
    {
      Header: "Hours",
      accessor: "total_hours",
    },
    {
      Header: "Avatar",
      accessor: "profile.image",
      Cell: <Avatar image={profile.image} size="small" />,
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "profile.name",
      Cell: (cell) => {
        const timesheet = cell.data[cell.row.index];
        return (
          <PermissionCheck
            permission={timesheet.permissions.can_edit}
            value={profile.name}
          >
            <a href={`/timesheets/${profile.slug}/${timesheet.slug}`}>
              {profile.name}
            </a>
          </PermissionCheck>
        );
      },
    },
    {
      Header: "Last Edited",
      accessor: "updated_at",
      Cell: ({ cell }) => <DateDisplay date={cell.value} withTrue />,
    },
    {
      Header: "Actions",
      accessor: "slug",
      Cell: (cell) => {
        const timesheet = cell.data[cell.row.index];

        return (
          <TableActions>
            <PermissionCheck permission={timesheet.permissions.can_view}>
              <TableButton
                text="View"
                action={() => handleViewButton(timesheet)}
              />
            </PermissionCheck>
            <PermissionCheck
              permission={
                timesheet.permissions.can_lock && timesheet.is_lockable
              }
            >
              <ToggleLock timesheet={timesheet} />
            </PermissionCheck>
            <PermissionCheck permission={timesheet.permissions.can_edit}>
              <TableButton
                text="Download CSV"
                action={() => handleDownloadButton(timesheet)}
              />
            </PermissionCheck>
          </TableActions>
        );
      },
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Table
        as="sticky"
        profile={profile}
        data={timesheets}
        columns={columns}
        emptyState="No Timesheets for this Week"
        loading={loading}
        hideFooter
      />
    </>
  );
}
ProfileTable.propTypes = {
  profile: PropTypes.shape({}),
  timesheets: PropTypes.array,
};

export default ProfileTable;
