import React, { useEffect, useState, useCallback } from "react";
import { useInterval } from "Hooks";
import { Plus, Minus, Close } from "Components/blocks/icons";
import styles from "./increment.module.css";

export const Increment = ({
  total,
  setTotal,
  step = 1,
  setFocus,
  max = 999,
  min = 0,
  removeFn = () => console.log("removed"),
}) => {
  // Handle typing the value
  const [input, setInput] = useState(total);
  const handleChange = useCallback(
    (e) => {
      // Handles .25 or .5
      const inputHasDecimal = e.target.value?.indexOf(".") === -1;
      if (inputHasDecimal) return setInput(e.target.value);

      // Handles -1 or 999+
      const val = parseFloat(e.target.value);
      if (val <= min) return setInput(min);
      if (val >= max) return setInput(max);

      // Otherwise use the input
      setInput(val);
    },
    [min, setInput]
  );

  const checkInput = useCallback((val) => {
    if (!val) return 0;
    return parseFloat(val);
  }, []);

  // Handle math for increment/decrement of value
  const inc = () => {
    const val = checkInput(input) + parseFloat(step);
    if (val > max) return setInput(max);
    return setInput(val);
  };

  const dec = () => {
    const val = checkInput(input) - parseFloat(step);
    if (val < min) return setInput(min);
    return setInput(val);
  };

  // Logic to temporarily clear 0 values
  // on the input when typing
  const handleFocus = useCallback(() => {
    setFocus(true);
    if (input === 0) setInput("");
  }, [input]);

  const handleBlur = useCallback(() => {
    setFocus(false);
    if (input === 0 || input === "" || isNaN(input)) setInput(min);
  }, [input]);

  useEffect(() => {
    if (isNaN(input) || !input) setTotal(min);
    else setTotal(input);
  }, [input]);

  // These handle long-press on the incrementer, they will inc/dec
  // based on the time interval (200 or null in this case) when pressed
  const [pressed, setPressed] = useState(false);

  const handlePressInc = useCallback(() => setPressed("inc"), []);
  const handlePressDec = useCallback(() => setPressed("dec"), []);
  const handleMouseUp = useCallback(() => setPressed(false), []);

  useInterval(inc, pressed === "inc" ? 200 : null);
  useInterval(dec, pressed === "dec" ? 200 : null);

  return (
    <div className={styles.incrementer}>
      <button
        type="button"
        onMouseDown={handlePressDec}
        onMouseLeave={handleMouseUp}
        onClick={input <= min || isNaN(input) || !input ? removeFn : dec}
        // onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseUp={handleMouseUp}
        aria-label={`Decrease Allocation by ${step}`}
        className={styles.button}
      >
        {input <= min || isNaN(input) || !input ? (
          <Close fill="var(--color-red)" />
        ) : (
          <Minus />
        )}
      </button>
      <input
        type="number"
        placeholder={min}
        value={input}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={step}
        className={styles.field}
      />
      <button
        type="button"
        className={styles.button}
        onMouseDown={handlePressInc}
        onClick={inc}
        // onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        aria-label={`Increase Allocation by ${step}`}
      >
        <Plus />
      </button>
    </div>
  );
};

export default React.memo(Increment);
