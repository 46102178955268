import React, { createContext, useContext } from "react";
import { useOverflow } from "Hooks";
import DataTable from "./DataTable";

const TableOverflowContext = createContext();

export const useTableOverflow = () => useContext(TableOverflowContext);

export const StickyTable = ({
  data,
  columns,
  emptyState,
  hideFooter,
  loading,
  ...props
}) => {
  const { wrapperRef, innerRef, overflow } = useOverflow();

  return (
    <TableOverflowContext.Provider value={{ overflow }}>
      <div
        ref={wrapperRef}
        style={
          overflow
            ? { overflowX: "scroll", "--header-height-offset": 0 }
            : { "--header-height-offset": "0px" }
        }
        className="page-module__scrollable"
      >
        <DataTable
          data={data}
          columns={columns}
          emptyState={emptyState}
          loading={loading}
          forwardRef={innerRef}
          hideFooter={hideFooter}
          sticky
          {...props}
        />
      </div>
    </TableOverflowContext.Provider>
  );
};

export default StickyTable;
