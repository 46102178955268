import { FC } from "react";

import { styled } from "@plan/core";
import { IconNewMessage } from "@plan/icons";

import { useTimerContext } from "../contexts/TimerContext";

import { TimerButton } from "./Modal/styles/TimerButton";
import { TimerButtonCircle } from "./Modal/styles/TimerButtonCircle";
import { TimerButtonCircleGraphic } from "./Modal/styles/TimerButtonCircleGraphic";
import { TimerButtonContent } from "./Modal/styles/TimerButtonContent";

const TimerDropdown = styled("button", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  border: "none",
  padding: "0",
  backgroundColor: "transparent",
  color: "$white",
  cursor: "pointer",
  variants: {
    collapsed: {
      true: {
        opacity: "0",
        visibility: "hidden",
      },
    },
  },
});

const TimerDropdownText = styled("div", {
  position: "relative",
  marginRight: "$space$2",
});

const TimerDropdownTime = styled("div", {
  lineHeight: "1",
  textAlign: "left",
});

const TimeUnit = styled("span", {
  fontSize: "$fontSizes$sm",
  fontWeight: "$normal",
  lineHeight: "1",
  letterSpacing: "0.0375rem",
  textTransform: "uppercase",
});

const DropdownProject = styled("div", {
  lineHeight: "1",
  textAlign: "left",
  color: "$colors$-neutral10",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontWeight: "$normal",
  fontSize: "$fontSizes$xs",
  width: "5rem",
  "@bpXl": {
    width: "7.375rem",
    fontSize: "$fontSizes$sm",
  },
});

const DropdownToggle = styled("div", {
  marginLeft: "auto",
  marginTop: "$space$0_5",
  marginRight: "$space$0_5",
});

interface TimerSidebarNavItemProps {
  sidebarOpen: boolean;
}

export const TimerSidebarNavItem: FC<TimerSidebarNavItemProps> = ({
  sidebarOpen,
}) => {
  const {
    actions: { toggleTimerModal, toggleTimerRecording },
    state: { entry, isRecording, recordingDisplayTime },
  } = useTimerContext();

  const handleButtonClick = () =>
    entry ? toggleTimerRecording() : toggleTimerModal();

  return (
    <>
      <TimerButton
        collapsed={!sidebarOpen}
        type="button"
        onClick={handleButtonClick}
      >
        <TimerButtonCircle isActive={isRecording}>
          <TimerButtonCircleGraphic isActive={isRecording} />
        </TimerButtonCircle>
        <TimerButtonContent collapsed={!sidebarOpen} isActive={isRecording} />
      </TimerButton>
      <TimerDropdown
        collapsed={!sidebarOpen}
        type="button"
        onClick={toggleTimerModal}
      >
        <TimerDropdownText>
          <TimerDropdownTime>
            {recordingDisplayTime} <TimeUnit>hrs</TimeUnit>
          </TimerDropdownTime>
          <DropdownProject>
            {entry?.project?.name || "Select a project..."}
          </DropdownProject>
        </TimerDropdownText>
        <DropdownToggle>
          <IconNewMessage label="Edit" color="neutral" />
        </DropdownToggle>
      </TimerDropdown>
    </>
  );
};
