import * as React from "react";

import { createComponent, styled } from "@plan/core";

import type * as Polymorphic from "@radix-ui/react-polymorphic";
import * as PopoverPrimitive from "@radix-ui/react-popover";

const component = createComponent();

const StyledPopoverContent = styled(PopoverPrimitive.Content, {
  ...component,
  minWidth: "max-content",
  backgroundColor: "$white",
  boxShadow: "$shadow100",
  borderRadius: "$radii$default",
  padding: "$space$4",
  zIndex: "$popup",
});

type PopoverContentProps = Polymorphic.OwnProps<
  typeof PopoverPrimitive.Content
> &
  React.ComponentProps<typeof StyledPopoverContent>;

export const PopoverContent = (props: PopoverContentProps) => (
  <StyledPopoverContent {...props} />
);
