import React from "react";
import Edit from "Components/blocks/icons/Edit";
import FieldAction from "./FieldAction";

export function EditButton({ handleClick, children, ghosted, disabled }) {
  return (
    <FieldAction
      handleClick={handleClick}
      ghosted={ghosted}
      disabled={disabled}
    >
      <Edit />
      {children}
    </FieldAction>
  );
}

export default React.memo(EditButton);
