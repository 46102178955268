import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import $ from "Blocks/utils/jQuerySelector";

class ProfilesWorkload extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-sort", () => {
      this.sort();
    });
    bindListenerToDocument("click", ["js-filter", "js-select-reset"], () => {
      this.filter();
    });
    bindListenerToDocument("click", "js-filter-active", () => {
      this.filterActive();
    });
  }

  load() {
    this.setProfileData();
  }

  // beforeCache() {}

  // Methods
  setProfileData() {
    this.profileData = [];
    this.filteredProfileData = [];
    Array.from($(".js-profile")).forEach((profileNode) => {
      const node = profileNode;
      const firstName = profileNode.querySelector(".js-first-name").innerHTML;
      const lastName = profileNode.querySelector(".js-last-name").innerHTML;
      const { title } = profileNode.dataset;
      const profile = { node, firstName, lastName, title };

      this.profileData.push(profile);
    });
  }

  filter() {
    this.resetFilters();
    this.filterByTitle();
    this.sort();
  }

  resetFilters() {
    this.profileData = this.profileData.concat(this.filteredProfileData);
    this.filteredProfileData = [];
  }

  filterByTitle() {
    const titleSelectNode = $(".js-title-select")[0];
    const filterValue = titleSelectNode.dataset.value;
    if (filterValue) {
      this.profileData = this.profileData.filter((profile) => {
        if (!(profile.title === filterValue)) {
          this.filteredProfileData.push(profile);
        }
        return profile.title === filterValue;
      });
    }
  }

  // This method is a one off. It doesn't interact with the profileData object
  // because it is not filtering profiles, but the projects associated.
  // It hides/shows projects using a class on $(".profiles").
  filterActive() {
    const activeFilter = $(".js-active-select")[0].dataset.value;
    const profilesNode = $(".js-profiles")[0];
    if (activeFilter === "active") {
      profilesNode.classList.add("show-active");
      profilesNode.classList.remove("show-inactive");
    } else if (activeFilter === "inactive") {
      profilesNode.classList.add("show-inactive");
      profilesNode.classList.remove("show-active");
    } else {
      profilesNode.classList.remove("show-inactive");
      profilesNode.classList.remove("show-active");
    }
  }

  sort() {
    const selectNode = $(".js-sort-select")[0];
    const query = selectNode.dataset.value;
    this.profileData.sort((a, b) => {
      if (a[query] < b[query]) {
        return -1;
      }
      if (a[query] > b[query]) {
        return 1;
      }
      return 0;
    });

    this.updateDom();
  }

  updateDom() {
    const profilesContainer = $(".js-profiles")[0];
    profilesContainer.innerHTML = "";
    this.profileData.forEach((profile) => {
      profilesContainer.appendChild(profile.node);
    });
  }

  // Helpers
}

const profilesWorkload = new ProfilesWorkload();
export default profilesWorkload;
