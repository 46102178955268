import React, { useState, useMemo } from "react";
import axios from "axios";
import isEqual from "lodash/isEqual";
import { renderError } from "Blocks/alert";
import useSWR from "swr";

// https://www.notion.so/monographhq/useApi-Hook-2dfe3aa7bd614aada3776cc22938ea4a

export function useApiCached(url, initialParams, config) {
  const [params, setParams] = useState({ ...initialParams });
  const [cancelToken, setCancelToken] = useState();

  const stringifiedParams = useMemo(() => JSON.stringify(params), [params]);

  async function fetchData() {
    const token = axios.CancelToken;
    setCancelToken(token.source());
    try {
      const res = await axios.get(url, { params }, { cancelToken });
      return { ...res.data };
    } catch (err) {
      if (config?.renderErrors) renderError();
      throw new Error(err);
    }
  }

  const { data, error } = useSWR([url, stringifiedParams], fetchData, {
    dedupingInterval: 10000,
    revalidateOnFocus: false,
  });

  const changeParams = (newParams) => {
    // Check to see if the arguments have changed
    if (isEqual(newParams, params)) return;
    // Cancel any existing requests
    if (cancelToken) cancelToken.cancel("Request canceled by the user.");
    setParams(newParams);
  };

  const loading = !data && !error;

  return [data, loading, error, changeParams, params, fetchData];
}

export default useApiCached;
