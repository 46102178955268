import React from "react";
import ReactDOM from "react-dom";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFrame from "./ModalFrame";
import ModalButtons from "./ModalFrame";

export * from "./useModal";

export function Modal(props) {
  const { children } = props;

  return ReactDOM.createPortal(
    <ModalFrame {...props}>
      <ModalHeader />
      <ModalBody>{children}</ModalBody>
      <ModalButtons />
    </ModalFrame>,
    // render the modal into the
    // document body outside of react
    document.body
  );
}

Modal.defaultProps = {
  isOpen: false,
  actions: {
    open: () => console.log("modal open"),
    close: () => console.log("modal close"),
    toggle: () => console.log("modal toggle"),
  },
  children: null,
  noPadding: false,
  content: null,
  buttons: null,
  title: null,
  subHeading: null,
  size: "small",
};

export default Modal;
