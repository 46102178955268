/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import ajax from "Blocks/utils/ajax";
import util from "Blocks/utils/util";
import Segment from "Components/blocks/Segment.jsx";
import Field from "Components/blocks/Field.jsx";
import Select from "Components/blocks/Select.jsx";
import React from "react";
import Url from "domurl";

export default class ProjectsEditInformation extends React.Component {
  constructor(props) {
    super(props);

    this.updateProject = this.updateProject.bind(this);
    this.remainingCategories = this.remainingCategories.bind(this);
    this.createCategorization = this.createCategorization.bind(this);
    this.addClientToProject = this.addClientToProject.bind(this);
    this.deleteCategorization = this.deleteCategorization.bind(this);

    const { project, categories, projectCategoryIds, clientId } = this.props;
    this.state = {
      clientId,
      project,
      categories,
      projectCategoryIds,
    };
  }

  updateProject(data, callback) {
    this.setState({ project: { ...this.state.project, ...data } });

    let path;
    let body;

    if (this.props.isTemplate) {
      path = `/api/v1/projects/templates/${this.props.project.slug}`;
      body = { project_template: data };
    } else {
      path = `/projects/${this.props.project.slug}`;
      body = { project: data };
    }

    ajax({
      method: "PUT",
      path,
      data: body,
      success: (response) => {
        console.log(response);

        if (callback) {
          callback(response);
        }

        util.endSave();
      },
    });
  }

  remainingCategories() {
    return this.props.categories.filter(
      (category) => !this.state.projectCategoryIds.includes(category.id)
    );
  }

  createCategorization(categoryId) {
    util.startSave();

    const data = {
      category_id: categoryId,
    };

    this.setState({
      projectCategoryIds: this.state.projectCategoryIds.concat([categoryId]),
    });

    ajax({
      method: "POST",
      path: `/projects/${this.props.project.slug}/categorizations`,
      data,
      success: (response) => {
        util.endSave();
      },
    });
  }

  addClientToProject(clientId) {
    util.startSave();

    const data = {
      client_id: clientId,
    };

    ajax({
      method: "POST",
      path: `/projects/${this.props.project.slug}/update_client`,
      data,
      success: () => {
        this.setState({ clientId });
        util.endSave();
      },
    });
  }

  deleteCategorization(categoryId) {
    util.startSave();
    this.setState({
      projectCategoryIds: this.state.projectCategoryIds.filter(
        (id) => id !== categoryId
      ),
    });

    ajax({
      method: "DELETE",
      path: `/projects/${this.props.project.slug}/categorizations/${categoryId}`,
      success: (response) => {
        console.log(response);
        util.endSave();
      },
    });
  }

  renderCategories() {
    if (this.props.isTemplate) {
      return null;
    }

    return (
      <div className="grid-xs-12 mb-16">
        <h5 className="page-module__subtitle">Categories</h5>
        <Select
          label="Categories"
          selectedValue={null}
          size="large"
          reset={false}
          onSelect={this.createCategorization}
          items={this.remainingCategories().map((category) => ({
            name: category.name,
            value: category.id,
          }))}
        />
        <ul className="page-categories">
          {this.state.projectCategoryIds.map((categoryId) => {
            const category = this.state.categories.find(
              (category) => category.id === categoryId
            );
            return (
              <li className="page-category" key={category.id}>
                <span className="page-category__title">{category.name}</span>
                <button
                  className="page-category__delete"
                  onClick={() => this.deleteCategorization(category.id)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderClientList() {
    if (this.props.isTemplate) {
      return null;
    }

    return (
      <div className="grid-xs-12 mb-16">
        <h5 className="page-module__subtitle">Client</h5>
        <Select
          label="Client"
          selectedValue={this.state.clientId}
          size="large"
          reset={false}
          onSelect={this.addClientToProject}
          items={this.props.clients.map((client) => ({
            name: client.display_name,
            value: client.id,
          }))}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="grid">
        <div className="grid-xs-12 mb-16">
          <Field
            selectedValue={this.state.project.name}
            label="Name"
            toggleEdit
            keyName="name"
            onChange={(data) =>
              this.updateProject(data, (response) => {
                if (JSON.parse(response).slug) {
                  const newSlug = JSON.parse(response).slug;
                  const u = new Url();
                  const paths = u.paths();

                  if (this.props.isTemplate) {
                    paths[2] = newSlug;
                  } else {
                    paths[1] = newSlug;
                  }
                  u.paths(paths);
                  location.replace(u);
                }
              })
            }
          />
        </div>

        <div className="grid-xs-12 mb-16">
          <Segment
            options={[
              { name: "Billable", value: true },
              { name: "Non-Billable", value: false },
            ]}
            selectedValue={this.state.project.is_billable}
            onSelect={(value) => this.updateProject({ is_billable: value })}
          />
        </div>

        <div className="grid-xs-12 grid-sm-4">
          <Field
            label="Number"
            keyName="number"
            selectedValue={this.state.project.number}
            onChange={this.updateProject}
          />
        </div>

        <div className="grid-xs-12 grid-sm-8">
          {/* This is using vanilla JS listeners from old selects so I don't rebuild functionality */}
          <div className="select select--large select--color js-select">
            <label className="select__label js-select-title">
              Select A Color
            </label>
            <div
              className="select__data select__selected-color js-select-data"
              style={{ backgroundColor: this.state.project.color }}
              data-color={this.state.project.color}
              data-color-number={
                this.props.colors.find(
                  (color) => color.hex === this.state.project.color
                ).number
              }
            />
            <div className="select__list select__color-list js-select-list">
              {this.props.colors.map((singleColor) => {
                const { number } = singleColor;
                const color = singleColor.hex;
                return (
                  <div
                    key={color}
                    className={`select__color js-select-item${
                      this.state.project.color === color ? " is-selected" : ""
                    }`}
                    style={{ backgroundColor: color }}
                    data-color={color}
                    data-color-number={number}
                    onClick={() => this.updateProject({ color })}
                  />
                );
              })}
            </div>
            <div className="select__arrows">
              <span className="select__arrow select__arrow--top" />
              <span className="select__arrow select__arrow--bottom" />
            </div>
          </div>
        </div>
        {this.renderClientList()}
        {this.renderCategories()}
      </div>
    );
  }
}
