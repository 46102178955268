import * as React from "react";

import { createLayout, styled } from "@plan/core";

import { FORM_SIZES } from "../constants";

const layout = createLayout();

const StyledForm = styled("form", {
  ...layout,
  variants: {
    ...layout.variants,
    size: FORM_SIZES,
  },
  defaultVariants: {
    ...layout.defaultVariants,
    size: "md",
  },
});

export type FormProps = React.ComponentProps<typeof StyledForm>;

export const Form = React.forwardRef<HTMLFormElement, FormProps>(
  (props, ref) => (
    <StyledForm
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref as unknown}
      {...props}
    />
  )
);
