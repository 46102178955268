import React from "react";
import { useModalState } from "./ModalContext";
import styles from "./modal.module.css";

export default function ModalHeader() {
  const { title, subHeading, actions } = useModalState();
  return (
    <div className={styles.header}>
      <div className={styles.titleWrap}>
        {title && <div className={styles.title}>{title}</div>}
        {subHeading && <div className={styles.subheading}>{subHeading}</div>}
      </div>
      <button className={styles.close} onClick={() => actions.close()}>
        Close
      </button>
    </div>
  );
}
