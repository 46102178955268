import applicationIndex from "Custom/application-index";
import invoicesEdit from "Custom/invoices-edit";
import invoicesIndex from "Custom/invoices-index";
import invoicesShow from "Custom/invoices-show";
import onboardingOrganization from "Custom/onboarding-organization";
import phasesShow from "Custom/phases-show";
import profilesWorkload from "Custom/profiles-workload";
import projectsArchive from "Custom/projects-archive";
import projectsIndex from "Custom/projects-index";
import projectsInvoices from "Custom/projects-invoices";
import projectsShow from "Custom/projects-show";
import projectsTimesheets from "Custom/projects-timesheets";
import projectsWorkload from "Custom/projects-workload";
import projectTemplatesShow from "Custom/project-templates-show";
import registrationsNew from "Custom/registrations-new";
import shareableShow from "Custom/shareable-show";
import tasksIndex from "Custom/tasks-index";
import tasksShow from "Custom/tasks-show";
import timesheetsAudit from "Custom/timesheets-audit";
import timesheetsIndex from "Custom/timesheets-index";
import timesheetsOrganizationTimesheets from "Custom/timesheets-organization-timesheets";

// This calls the appropriate javascript depending on the page and the turbolinks hook
// Turbolinks just calls something like controller.load() rather than using conditional statements.
const controller = {
  current: "",
  currentJs: null,
  load() {
    this.current = $("body")[0].dataset.js;
    this.currentJs = this.js[this.current];
    if (this.currentJs) {
      this.currentJs._load();
    }
  },
  beforeCache() {
    if (this.currentJs) {
      this.currentJs.beforeCache();
    }
  },
  js: {
    "application-index": applicationIndex,
    "invoices-edit": invoicesEdit,
    "invoices-index": invoicesIndex,
    "invoices-show": invoicesShow,
    "onboarding-organization": onboardingOrganization,
    "phases-show": phasesShow,
    "projects-index": projectsIndex,
    "projects-archive": projectsArchive,
    "projects-invoices": projectsInvoices,
    "projects-show": projectsShow,
    "projects-timesheets": projectsTimesheets,
    "profiles-workload_profiles": profilesWorkload,
    "profiles-workload_projects": projectsWorkload,
    "registrations-new": registrationsNew,
    "shareable-show": shareableShow,
    "tasks-index": tasksIndex,
    "tasks-show": tasksShow,
    "templates-show": projectTemplatesShow,
    "timesheets-audit_timesheets": timesheetsAudit,
    "timesheets-index": timesheetsIndex,
    "timesheets-organization_timesheets": timesheetsOrganizationTimesheets,
  },
};

export default controller;
