import { ColumnShape } from "../";

interface ColumnProp {
  name: string;
  accessor: string;
}

interface CreateColumnsProps {
  columns: ColumnProp[];
  options: ColumnShape;
}

export const createColumns = ({ columns, options }: CreateColumnsProps) => {
  return columns.map((column: ColumnProp) => {
    return {
      Header: column.name,
      accessor: column.accessor,
      id: column.accessor,
      ...options,
    };
  });
};
