import React from "react";

export const Column = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="4" height="12" fill="#A0A8AE" />
    <rect x="6" width="4" height="16" fill="#A0A8AE" />
    <rect x="12" width="4" height="8" fill="#A0A8AE" />
  </svg>
);
