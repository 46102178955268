export const semanticScales = {
  brand: {
    "-brand50": "#EFEBFF",
    "-brand40": "#E0D9FD",
    "-brand30": "#C2B3FB",
    "-brand20": "#9F8CF5",
    "-brand10": "#826DEC",
    brand: "#5840E0",
    brand10: "#422EC0",
    brand20: "#2F20A1",
    brand30: "#1F1481",
    brand40: "#140C6B",
  },
  success: {
    "-success50": "#CFFAE5",
    "-success40": "#A2F5D4",
    "-success30": "#6EE2BF",
    "-success20": "#47C5AB",
    "-success10": "#18A090",
    success: "#0F7B7D",
    success10: "#0C686D",
    success20: "#09555E",
    success30: "#06424E",
    success40: "#032F3E",
  },
  warning: {
    "-warning50": "#FFFAE5",
    "-warning40": "#FFF7D2",
    "-warning30": "#FFEDA5",
    "-warning20": "#FFE179",
    "-warning10": "#FFD457",
    warning: "#FFC020",
    warning10: "#DB9E17",
    warning20: "#B77E10",
    warning30: "#93600A",
    warning40: "#7A4B06",
  },
  danger: {
    "-danger50": "#FFEFEB",
    "-danger40": "#FDE1D9",
    "-danger30": "#FBBDB3",
    "-danger20": "#F5908C",
    "-danger10": "#E04058",
    danger: "#C02E52",
    danger10: "#A22548",
    danger20: "#841B3E",
    danger30: "#661234",
    danger40: "#48082A",
  },
  neutral: {
    "-neutral50": "#F9FAFB",
    "-neutral40": "#F3F4F6",
    "-neutral30": "#E5E7EB",
    "-neutral20": "#D1D5DB",
    "-neutral10": "#8E95A0",
    neutral: "#6B7280",
    neutral10: "#4B5563",
    neutral20: "#374151",
    neutral30: "#1F2937",
    neutral40: "#111827",
  },
  monochrome: {
    black: "#111827", // $neutral40
    white: "#ffffff",
  },
};

/**
 * shades
 *
 * Stores the 050-900 shades of each color in a structured object with each color as a key
 */
export const shades = {
  orange: {
    orange050: "#fff7ed",
    orange100: "#ffedd5",
    orange200: "#fed7aa",
    orange300: "#fdba74",
    orange400: "#fdba74",
    orange500: "#f97316",
    orange600: "#ea580c",
    orange700: "#c2410c",
    orange800: "#9a3412",
    orange900: "#7c2d12",
  },
  lime: {
    lime050: "#f5fee7",
    lime100: "#eafcca",
    lime200: "#d8f99f",
    lime300: "#bef264",
    lime400: "#abea41",
    lime500: "#88cb15",
    lime600: "#6da50d",
    lime700: "#537b0f",
    lime800: "#456312",
    lime900: "#3b5214",
  },
  green: {
    green050: "#ecfdf5",
    green100: "#d1fae5",
    green200: "#a7f3d0",
    green300: "#6ee7b7",
    green400: "#34d399",
    green500: "#10b981",
    green600: "#059669",
    green700: "#047857",
    green800: "#065f46",
    green900: "#064e3b",
  },
  cyan: {
    cyan050: "#ecfeff",
    cyan100: "#cffafe",
    cyan200: "#a5f3fc",
    cyan300: "#67e8f9",
    cyan400: "#22d3ee",
    cyan500: "#06b6d4",
    cyan600: "#0891b2",
    cyan700: "#0e7490",
    cyan800: "#155e75",
    cyan900: "#164e63",
  },
  blue: {
    blue050: "#f5f5ff",
    blue100: "#e6e9fe",
    blue200: "#cdd4fe",
    blue300: "#aab4fd",
    blue400: "#7484fb",
    blue500: "#596cf7",
    blue600: "#4458ee",
    blue700: "#3549e3",
    blue800: "#3241b0",
    blue900: "#202d92",
  },
  violet: {
    violet050: "#f8f5ff",
    violet100: "#ece7fe",
    violet200: "#e1d7fe",
    violet300: "#c7b4fd",
    violet400: "#ad89fa",
    violet500: "#8e5af6",
    violet600: "#7f41ea",
    violet700: "#6826d9",
    violet800: "#5721b5",
    violet900: "#491d95",
  },
  purple: {
    purple050: "#faf5ff",
    purple100: "#ede9fe",
    purple200: "#e9d5ff",
    purple300: "#d8b4fe",
    purple400: "#c084fc",
    purple500: "#a855f7",
    purple600: "#9333ea",
    purple700: "#7e22ce",
    purple800: "#6b21a8",
    purple900: "#581c87",
  },
  fuchsia: {
    fuchsia050: "#fefaff",
    fuchsia100: "#fcebff",
    fuchsia200: "#f6cdfe",
    fuchsia300: "#f1abfc",
    fuchsia400: "#e879f9",
    fuchsia500: "#d441ea",
    fuchsia600: "#bc27d3",
    fuchsia700: "#a01db4",
    fuchsia800: "#891b98",
    fuchsia900: "#741c82",
  },
  pink: {
    pink050: "#fdf2f9",
    pink100: "#fce8f5",
    pink200: "#fbd0eb",
    pink300: "#f9a9dc ",
    pink400: "#f471c4",
    pink500: "#ea41ab",
    pink600: "#db299a",
    pink700: "#bf1881",
    pink800: "#9b176b",
    pink900: "#81185b",
  },
};

/**
 * colors
 *
 * Flat list of all "primitive" colors, created from `shades`, plus white and black
 */
export const colorPrimitives = {
  ...shades.orange,
  ...shades.lime,
  ...shades.green,
  ...shades.cyan,
  ...shades.blue,
  ...shades.purple,
  ...shades.fuchsia,
  ...shades.violet,
  ...shades.pink,
};

export const colors = {
  ...colorPrimitives,
  ...semanticScales.monochrome,
  ...semanticScales.brand,
  ...semanticScales.success,
  ...semanticScales.warning,
  ...semanticScales.danger,
  ...semanticScales.neutral,
};
