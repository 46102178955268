import React, { useEffect } from "react";
import { useModal } from "Components/blocks/modal/useModal";
import eventTracker from "Blocks/tracker";
import UpgradeModal from "./UpgradeModal";
import UPGRADE_LANDER_DETAILS from "./constants";
import styles from "./upgradePage.module.css";

const getFileExtension = (filename) => filename.split(".").pop();

export const UpgradeLander = ({
  feature,
  mediaSrc,
  prepaidSeatCount,
  size,
}) => {
  const [isOpen, modalActions] = useModal();
  const {
    title,
    description,
    learnMoreLink,
    path: pathString,
    textWidth,
    gapWidth,
    mediaWidth,
  } = UPGRADE_LANDER_DETAILS[feature];

  const buttonText = "Upgrade to Grow";
  const upgradeModalTitle = "Upgrade your plan";
  const isVideo = getFileExtension(mediaSrc) === "mp4";

  useEffect(() => {
    eventTracker("trackUpgradeLander", { pathString });
  }, []);

  const openModal = () => {
    eventTracker("trackUpgradeLanderCtaClick", { pathString, buttonText });
    eventTracker("trackPlanModalDisplay", { upgradeModalTitle, pathString });
    modalActions.open();
  };

  return (
    <div className={styles.autoLayoutTop}>
      <div className={styles.autoLayoutCenter} style={{ gap: gapWidth || 75 }}>
        <div className={styles.flex} style={{ width: textWidth || 350 }}>
          <div className={styles.heading6}>Unlock with the GROW plan</div>
          <div className={styles.heading1}>{title}</div>
          <div className={styles.box}>
            <p className={styles.text}>
              {description}&nbsp;
              {learnMoreLink ? (
                <span>
                  <a
                    href={learnMoreLink}
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </span>
              ) : null}
            </p>
          </div>
          <div className={styles.box}>
            <button
              type="submit"
              className={styles.button}
              onClick={() => openModal()}
            >
              {buttonText}
            </button>
          </div>
        </div>
        {isVideo ? (
          <div
            style={{ filter: "drop-shadow(0px 0px 15px rgb(1, 22, 39, 0.15))" }}
          >
            <video
              loop
              autoPlay
              muted
              style={{ borderRadius: 12, width: mediaWidth || 490 }}
            >
              <source src={mediaSrc} type="video/mp4" />
            </video>
          </div>
        ) : (
          <img
            alt=""
            src={mediaSrc}
            className={styles.resourceImg}
            style={{ borderRadius: 12, width: mediaWidth || 490 }}
          />
        )}
      </div>
      <UpgradeModal
        isOpen={isOpen}
        modalActions={modalActions}
        prepaidSeatCount={prepaidSeatCount}
        size={size}
      />
    </div>
  );
};

export default UpgradeLander;
