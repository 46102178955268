/* eslint-disable react/destructuring-assignment */
import ajax from "Blocks/utils/ajax";
import util from "Blocks/utils/util";
import { useApi } from "@hooks";
import InvoiceControls from "Components/invoices/InvoiceControls";
import InvoiceEditor, { selectStyles } from "Components/invoices/InvoiceEditor";
import Modal from "Components/blocks/Modal";
import ClientModal from "Components/invoices/ClientModal";
import Address from "Components/blocks/Address";
import AWS from "Blocks/aws";
import numeral from "numeral";
import React from "react";
import cx from "classnames";
import { Message } from "Components/blocks/alert/Message";
import ReactSelect from "react-select";
import LoadingSkeleton from "./LoadingSkeleton";

const RequestWrapper = (props) => {
  const [data, loading, error] = useApi(
    `/invoices/${props?.invoice?.slug}/invoice_data`,
    {},
    { renderErrors: true }
  );
  if (loading === "fetching" || error) return <LoadingSkeleton />;

  return <Invoice {...data} display={props.display} />;
};

const CollapseIcon = (props) => (
  <button
    type="button"
    className={cx("invoice-collapse-icon", {
      "show-controls": props.showControls,
    })}
    onClick={props.toggleControls}
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.707 3.707a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L2.414 6l2.293-2.293zM10.707 3.707a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L8.414 6l2.293-2.293z" />
    </svg>
  </button>
);

export class Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.updateInvoiceState = this.updateInvoiceState.bind(this);
    this.updateInvoiceObject = this.updateInvoiceObject.bind(this);
    this.updateInvoice = this.updateInvoice.bind(this);
    this.updateType = this.updateType.bind(this);
    this.getProjectData = this.getProjectData.bind(this);
    this.sendInvoice = this.sendInvoice.bind(this);
    this.appendLineItems = this.appendLineItems.bind(this);
    this.replaceLineItems = this.replaceLineItems.bind(this);
    this.createLineItemsFromProjectData =
      this.createLineItemsFromProjectData.bind(this);
    this.createLineItemData = this.createLineItemData.bind(this);
    this.createLineItem = this.createLineItem.bind(this);
    this.updateLineItemState = this.updateLineItemState.bind(this);
    this.updateLineItem = this.updateLineItem.bind(this);
    this.updateLineItemStateOrder = this.updateLineItemStateOrder.bind(this);
    this.updateLineItemOrder = this.updateLineItemOrder.bind(this);
    this.deleteLineItem = this.deleteLineItem.bind(this);
    this.createClient = this.createClient.bind(this);
    this.assignClient = this.assignClient.bind(this);
    this.updateClient = this.updateClient.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.imgixifyImage = this.imgixifyImage.bind(this);
    this.createLineItemsFromExpenses =
      this.createLineItemsFromExpenses.bind(this);
    this.createExpenseLineItemData = this.createExpenseLineItemData.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.updateOrganizationLogo = this.updateOrganizationLogo.bind(this);
    this.updatePhasePercent = this.updatePhasePercent.bind(this);
    this.createLineItemFromPhase = this.createLineItemFromPhase.bind(this);
    this.getExpenses = this.getExpenses.bind(this);

    this.toggleControls = this.toggleControls.bind(this);

    // Round the invoice phase_percent number
    const { invoice } = this.props;
    if (invoice.phasePercent) {
      invoice.phase_percent = numeral(invoice.phasePercent).format("0[.][00]");
    }

    // Round line item values
    const { lineItems } = this.props;
    lineItems.forEach((lineItem) => {
      this.roundValuesInLineItem(lineItem);
    });

    this.state = {
      projectData: [],
      expenseData: [],
      invoice,
      lineItems,
      expenses: [],
      loading: false,
      client: this.props.client,
      clientAddress: this.props.clientAddress,
      shouldShowClientModal: false,
      display: this.props.display || "show",
      organizationClients: this.props.organizationClients,
      phaseFilter: null,
      organizationAddress: this.props.organizationAddress,
      organizationLogo: this.props.organizationLogo,
      temporaryLogo: null,

      showPopulateModal: false,
      showNewClientModal: false,
      showIntegrationModal: false,
      showEditClientModal: false,
      showNewAddressModal: false,
      showLogoModal: false,
      showControls: true,
    };

    this.creatingPhaseId = null;
    this.closeOut = false;
  }

  // Life cycle methods
  componentDidMount() {
    if (this.state.invoice.slug) {
      if (this.props.display !== "preview") {
        this.getProjectData();
      } else {
        this.getExpenses();
      }
    }

    // Set invoice font size
    const invoice = $(".js-invoice")[0];
    if (invoice) {
      invoice.style.fontSize = `${(invoice.offsetWidth / 612).toString()}rem`;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.invoice.begin_date !== prevState.invoice.begin_date ||
      this.state.invoice.end_date !== prevState.invoice.end_date
    ) {
      util.delay(this.getProjectData, 600, "getProjectData");
    } else if (
      this.state.invoice.type !== prevState.invoice.type ||
      this.state.invoice.phase_id !== prevState.invoice.phase_id ||
      this.state.phaseFilter !== prevState.phaseFilter
    ) {
      this.getProjectData();
    }
  }

  // Helpers
  // This is called when invoice data is received for the controller
  roundValuesInLineItemData(lineItem) {
    ["amount", "rate", "quantity", "hours"].forEach((key) => {
      if (lineItem[key]) {
        if (key === "quantity") {
          lineItem[key] = numeral(lineItem[key]).format("0[.][000000]");
        } else {
          lineItem[key] = numeral(lineItem[key]).format("0[.][00]");
        }
      }
    });
  }

  // This is called when a lineItem is rendered and when a lineItem is created
  roundValuesInLineItem(lineItem) {
    ["amount", "unit_price", "quantity", "rate"].forEach((key) => {
      if (lineItem[key]) {
        if (key === "quantity") {
          // Pretty sure we want them to have a lot of potential decimal places, not four.
          lineItem[key] = numeral(lineItem[key]).format("0[.][000000000000]");
        } else {
          lineItem[key] = numeral(lineItem[key]).format("0.00");
        }
      }
    });
  }

  // Updating Invoice, Project Data, and Expense Data
  updateInvoiceState(data) {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        ...data,
      },
    }));
  }

  updateInvoiceObject() {
    const data = this.state.invoice;
    util.startSave();
    ajax({
      method: "PUT",
      path: `/invoices/${this.state.invoice.slug}/`,
      data,
      success: (response) => {
        console.log(response);
        util.endSave();
      },
    });
  }

  updateInvoice(data) {
    this.updateInvoiceState(data);
    util.delay(this.updateInvoiceObject, 1000, "invoice");
  }

  updateType(type) {
    // Break if there was no change
    if (this.state.invoice.type === type) return;

    this.setState((prevState) => ({
      invoice: { ...prevState.invoice, type },
      projectData: [],
    }));

    util.delay(this.updateInvoiceObject, 1000, "invoice");
  }

  getProjectData() {
    const data = {
      begin_date: this.state.invoice.begin_date,
      end_date: this.state.invoice.end_date,
      type: this.state.invoice.type,
    };

    if (this.state.invoice.phase_id) {
      data.phase = this.state.invoice.phase_id;
    }

    // Add phaseFilter if it exists
    if (this.state.phaseFilter) {
      data.phase = this.state.phaseFilter;
    }

    if (data.begin_date && data.end_date && data.type) {
      this.setState({ loading: true });

      ajax({
        method: "GET",
        path: `/invoices/${this.state.invoice.slug}/data`,
        data,
        success: (response) => {
          const parsedResponse = JSON.parse(response);
          let projectData = parsedResponse.data;
          const expenseData = parsedResponse.expenses;

          projectData.forEach((lineItem) =>
            this.roundValuesInLineItemData(lineItem)
          );

          if (this.state.invoice.type === "Phase") {
            projectData = projectData.map((projectDatum) => ({
              ...projectDatum,
              pastData: projectDatum.line_items,
            }));
          }

          this.setState({
            projectData,
            expenseData,
            loading: false,
          });
        },
      });
    }
  }

  toggleControls() {
    const show = this.state.showControls;
    this.setState({ showControls: !show });
  }

  getExpenses() {
    util.startSave();

    ajax({
      method: "GET",
      path: `/invoices/${this.state.invoice.slug}/expenses`,
      success: (response) => {
        console.log(response);
        const expenses = JSON.parse(response);
        this.setState({ expenses });
        util.endSave();
      },
    });
  }

  updatePhasePercent(phaseId, newPercent) {
    const phaseIndex = this.state.projectData.findIndex(
      (phase) => phase.id === phaseId
    );

    this.setState((prevState) => {
      prevState.projectData[phaseIndex].in_given_time.percent = numeral(
        newPercent / 100
      ).format("0[.][000000]");

      return {
        projectData: prevState.projectData,
      };
    });
  }

  // Creating, assigning, updating, and deleting clients
  // Not sure how optimistic to be with renders
  createClient(data) {
    util.startSave();

    this.setState({
      showNewClientModal: false,
    });

    ajax({
      method: "POST",
      path: `/invoices/${this.props.invoice.id}/clients`,
      data,
      success: (response) => {
        console.log(response);

        const parsedResponse = JSON.parse(response);

        this.setState((prevState) => ({
          client: parsedResponse.client,
          clientAddress: parsedResponse.address,
          organizationClients: prevState.organizationClients.concat(
            parsedResponse.client
          ),
        }));

        util.endSave();
      },
    });
  }

  assignClient(clientData) {
    util.startSave();

    ajax({
      method: "PUT",
      path: `/invoices/${this.props.invoice.slug}/assign_client`,
      data: clientData,
      success: (response) => {
        console.log(response);

        const parsedResponse = JSON.parse(response);

        this.setState({
          client: parsedResponse.client,
          clientAddress: parsedResponse.address,
        });

        util.endSave();
      },
    });
  }

  updateClient(data) {
    util.startSave();

    this.setState((prevState) => ({
      showEditClientModal: false,
      client: {
        ...prevState.client,
        ...data.client,
      },
      clientAddress: {
        ...prevState.clientAddress,
        ...data.address,
      },
    }));

    ajax({
      method: "PUT",
      path: `/clients/${this.state.client.id}`,
      data,
      success: (response) => {
        console.log(response);
        util.endSave();
      },
    });
  }

  deleteClient() {
    util.startSave();

    const clientId = this.state.client.id;

    this.setState((prevState) => ({
      showEditClientModal: false,
      client: null,
      clientAddress: null,
    }));

    ajax({
      method: "DELETE",
      path: `/clients/${clientId}`,
      success: (response) => {
        console.log(response);

        // Remove the client from this.state.organizationClients so it doesn't show up in drop down
        this.setState((prevState) => ({
          organizationClients: prevState.organizationClients.filter(
            (client) => client.id !== clientId
          ),
        }));

        util.endSave();
      },
    });
  }

  sendInvoice() {
    const invoiceSlug = this.state.invoice.slug;
    if (!this.state.client.qbo_customer_id) {
      this.setState({ shouldShowClientModal: true });
    } else {
      window.location.href = `/invoices/${invoiceSlug}/send_to_quickbooks`;
    }
  }

  // Update Logo
  uploadLogo(event) {
    event.preventDefault();
    const file = event.target.files[0];
    AWS.getSignedUrl(file, (logo) => {
      this.setState({ temporaryLogo: logo });
    });
  }

  updateOrganizationLogo() {
    util.startSave();

    const data = { logo: this.state.temporaryLogo };

    this.setState({
      organizationLogo: this.state.temporaryLogo,
      showLogoModal: false,
    });

    ajax({
      method: "PUT",
      path: `/organizations/update_logo`,
      data: { organization: data },
      success: (response) => {
        console.log(response);
        util.endSave();
      },
    });
  }

  imgixifyImage(imageUrl) {
    const modifiedImageUrl = imageUrl.split("amazonaws.com/")[1];
    return `https://monograph-dashboard-media.imgix.net/${modifiedImageUrl}?fit=clip&h=100&w=160`;
  }

  // Creating Line Items
  appendLineItems() {
    this.createLineItemsFromProjectData({ overwrite: false });
    this.setState({ showPopulateModal: false });
  }

  replaceLineItems() {
    this.createLineItemsFromProjectData({ overwrite: true });
    this.setState({ showPopulateModal: false });
  }

  createLineItemFromPhase(phaseId, closeOut = false) {
    // Saving these states straight on the object because they will not
    // manipulate the DOM, and this is faster than using state
    if (closeOut) {
      this.closeOut = true;
    }
    this.creatingPhaseId = phaseId;

    if (this.state.lineItems.length > 0) {
      this.setState({ showPopulateModal: true });
    } else {
      this.createLineItemsFromProjectData({ overwrite: true });
    }
  }

  createLineItemsFromProjectData(options = {}) {
    util.startSave();

    const lineItems = this.createLineItemData();
    const { phase_id } = this.state.invoice;

    ajax({
      method: "POST",
      path: `/invoices/${this.state.invoice.slug}/populate_invoice`,
      data: {
        line_items: lineItems,
        overwrite: options.overwrite,
        phase_id,
      },
      success: (response) => {
        const lineItems = JSON.parse(response);
        lineItems.forEach((lineItem) => {
          this.roundValuesInLineItem(lineItem);
        });

        if (options.overwrite) {
          this.setState({ lineItems });
        } else {
          this.setState((prevState) => ({
            lineItems: prevState.lineItems.concat(lineItems),
          }));
        }
        util.endSave();
      },
    });
  }

  createLineItemsFromExpenses() {
    util.startSave();

    const lineItems = this.createExpenseLineItemData();

    ajax({
      method: "POST",
      path: `/invoices/${this.state.invoice.slug}/populate_invoice`,
      data: { line_items: lineItems, overwrite: false },
      success: (response) => {
        const lineItems = JSON.parse(response);
        lineItems.forEach((lineItem) => {
          this.roundValuesInLineItem(lineItem);
        });

        this.setState((prevState) => ({
          lineItems: prevState.lineItems.concat(lineItems),
        }));
        util.endSave();
      },
    });
  }

  createLineItemData() {
    let lineItems = [];

    if (this.state.invoice.type === "Phase") {
      const phase = this.state.projectData.find(
        (phase) => phase.id === this.creatingPhaseId
      );

      if (phase.budget && phase.fee_type === "fixed") {
        const phaseBudget = parseFloat(phase.budget);
        let phasePercent;

        if (this.closeOut) {
          phasePercent = 1 - parseFloat(phase.total) / parseFloat(phase.budget);
          this.closeOut = false;
        } else {
          phasePercent = parseFloat(phase.in_given_time.percent);
        }

        const lineItem = {
          type: phase.name,
          description: "",
          quantity: phasePercent,
          unit_price: phaseBudget,
          amount: phasePercent * phaseBudget,
          phase_id: phase.id,
        };

        lineItems = [lineItem];

        // If there is no phase budget or if phase.fee_type is not fixed,
        // calculate it differently
      } else {
        const lineItem = {
          type: phase.name,
          description: "",
          quantity: parseFloat(phase.in_given_time.hours),
          unit_price: phase.in_given_time.budget / phase.in_given_time.hours,
          amount: phase.in_given_time.budget,
          phase_id: phase.id,
        };

        lineItems = [lineItem];
      }
    } else if (
      this.state.invoice.type === "Role" ||
      this.state.invoice.type === "Activity"
    ) {
      lineItems = this.state.projectData.map((datum) => {
        // Define rate if it is not defined (as it isn't with activities)
        // If there is no amount, set rate to 0, else calculate it with hours and amount
        let rate = parseFloat(datum.rate);
        if (!rate) {
          if (!datum.amount) {
            rate = 0;
          } else {
            rate = parseFloat(datum.amount) / parseFloat(datum.hours);
          }
        }

        return {
          type: datum.name,
          description: "",
          quantity: parseFloat(datum.hours),
          unit_price: rate,
          amount: parseFloat(datum.amount),
        };
      });
    }

    return lineItems;
  }

  createExpenseLineItemData() {
    let lineItems = [];

    lineItems = this.state.expenseData.map((expense) => ({
      type: expense.category,
      description: expense.note,
      quantity: 1,
      unit_price: parseFloat(expense.amount),
      amount: parseFloat(expense.amount),
      expense_id: expense.id,
    }));

    return lineItems;
  }

  createLineItem() {
    ajax({
      method: "POST",
      path: `/invoices/${this.state.invoice.slug}/line_items`,
      success: (response) => {
        const lineItem = JSON.parse(response);
        this.setState((prevState) => {
          prevState.lineItems.push(lineItem);
          return prevState;
        });
        util.endSave();
      },
    });
  }

  // Updating Line Items
  updateLineItemState(updatedLineItem) {
    this.setState((prevState) => {
      const lineItemIndex = prevState.lineItems.findIndex(
        (lineItem) => lineItem.id === updatedLineItem.id
      );

      prevState.lineItems[lineItemIndex] = updatedLineItem;

      return {
        lineItems: prevState.lineItems,
      };
    });

    util.delay(
      () => {
        this.updateLineItem(updatedLineItem.id);
      },
      1000,
      `lineItem${updatedLineItem.id}`
    );
  }

  updateLineItem(lineItemId) {
    const { lineItems } = this.state;
    const data = lineItems.find((lineItem) => lineItem.id === lineItemId);

    // If you don't find a lineItem, why try updating?
    if (!data) return;

    util.startSave();
    ajax({
      method: "PUT",
      path: `/line_items/${lineItemId}`,
      data,
      success: () => {
        util.endSave();
      },
    });
  }

  updateLineItemStateOrder(orderedIds, sortable, event) {
    this.setState((prevState) => {
      const newLineItems = orderedIds.map((id) =>
        prevState.lineItems.find((lineItem) => lineItem.id === parseInt(id, 10))
      );

      return {
        lineItems: newLineItems,
      };
    });

    // Update line item order
    this.updateLineItemOrder(event.oldIndex, event.newIndex);
  }

  updateLineItemOrder(oldIndex, newIndex) {
    const { invoice } = this.state;
    util.startSave();
    const data = {
      old_index: oldIndex,
      new_index: newIndex,
    };
    ajax({
      method: "PUT",
      path: `/invoices/${invoice.slug}/line_items`,
      data,
      success: () => {
        util.endSave();
      },
    });
  }

  // Deleting Line Items
  deleteLineItem(lineItemId) {
    const { invoice } = this.state;

    // Not sure if I want to eager load this
    this.setState((prevState) => ({
      lineItems: prevState.lineItems.filter(
        (lineItem) => lineItem.id !== lineItemId
      ),
    }));

    util.startSave();
    ajax({
      method: "DELETE",
      path: `/invoices/${invoice.slug}/line_items/${lineItemId}`,
      success: () => {
        util.endSave();
      },
    });
  }

  // Almost identical to #name method in backend.
  // Keep for now and then this logic will go away when there's no more invoices 1.0
  renderClientName(client) {
    if (!client) {
      return null;
    }
    if (client.display_name) {
      return client.display_name;
    }
    if (client.organization_name && client.lname) {
      return `${client.organization_name}, ${client.fname} ${client.lname}`;
    }
    if (client.organization_name) {
      return `${client.organization_name}`;
    }
    return `${client.fname} ${client.lname}`;
  }

  renderClientsDropdown() {
    const orgClients = this.state.organizationClients ?? [];
    const clientOptions = orgClients
      .map((client) => ({
        value: client.id,
        label: this.renderClientName(client),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <ReactSelect
        styles={selectStyles}
        isClearable
        name="client_id"
        placeholder="Select Client..."
        options={clientOptions}
        onChange={this.assignClient}
      />
    );
  }

  render() {
    const invoiceEditorStyle = {
      maxWidth: this.state.showControls ? "960px" : "100%",
    };

    return (
      <div style={{ display: "flex" }}>
        <InvoiceControls
          data={this.state.projectData}
          expenseData={this.state.expenseData}
          expenses={this.state.expenses}
          display={this.state.display}
          method={this.state.invoice.type}
          phaseId={this.state.invoice.phase_id}
          loading={this.state.loading}
          invoice={this.state.invoice}
          updatePhasePercent={this.updatePhasePercent}
          createLineItemFromPhase={this.createLineItemFromPhase}
          editable={this.state.display === "edit"}
          organizationIsConnectedToQbo={this.props.organizationIsConnectedToQbo}
          client={this.props.client}
          sendInvoice={this.sendInvoice}
          updateType={this.updateType}
          phaseFilter={this.state.phaseFilter}
          updateState={(data) => this.setState(data)}
          lineItems={this.state.lineItems}
          projectDataCount={this.state.projectData.length}
          createLineItemsFromProjectData={this.createLineItemsFromProjectData}
          createLineItemsFromExpenses={this.createLineItemsFromExpenses}
          assignments={this.props.assignments}
          updateInvoice={this.updateInvoice}
          projectSlug={this.props.projectSlug}
          showControls={this.state.showControls}
        />
        <CollapseIcon
          toggleControls={this.toggleControls}
          showControls={this.state.showControls}
        />
        <div className="invoice-editor" style={invoiceEditorStyle}>
          <InvoiceEditor
            invoice={this.state.invoice}
            editable={this.state.display === "edit"}
            organizationName={this.props.organizationName}
            organizationLogo={this.state.organizationLogo}
            organizationAddress={this.state.organizationAddress}
            client={this.state.client}
            clientAddress={this.state.clientAddress}
            update={this.updateInvoice}
            lineItems={this.state.lineItems}
            updateLineItem={this.updateLineItemState}
            updateLineItemOrder={this.updateLineItemStateOrder}
            createLineItem={this.createLineItem}
            deleteLineItem={this.deleteLineItem}
            organizationClients={this.state.organizationClients}
            assignClient={this.assignClient}
            assignments={this.props.assignments}
            openIntegrationModal={() =>
              this.setState({ showIntegrationModal: true })
            }
            organizationTerms={this.props.organizationTerms}
            openNewClientModal={() =>
              this.setState({ showNewClientModal: true })
            }
            openEditClientModal={() =>
              this.setState({ showEditClientModal: true })
            }
            openNewAddressModal={() =>
              this.setState({ showNewAddressModal: true })
            }
            openLogoModal={() => this.setState({ showLogoModal: true })}
            toggleControls={this.toggleControls}
            showControls={this.state.showControls}
            allowsVersionSwitch={this.props.allowsVersionSwitch}
          />
        </div>

        <Modal
          closeModal={() => this.setState({ showPopulateModal: false })}
          show={this.state.showPopulateModal}
          title="Append or replace line items?"
          buttons={
            <>
              <button
                className="modal-button modal-button--primary-fill"
                onClick={this.appendLineItems}
              >
                Append
              </button>
              <button
                className="modal-button modal-button--primary-fill"
                onClick={this.replaceLineItems}
              >
                Replace
              </button>
            </>
          }
        />

        <ClientModal
          closeModal={() => this.setState({ showNewClientModal: false })}
          show={this.state.showNewClientModal}
          title="Create a new client"
          createClient={this.createClient}
          action="create"
        />

        <ClientModal
          closeModal={() => this.setState({ showEditClientModal: false })}
          show={this.state.showEditClientModal}
          title="Create a new client"
          client={this.state.client}
          clientAddress={this.state.clientAddress}
          updateClient={this.updateClient}
          deleteClient={this.deleteClient}
          action="edit"
        />

        <Modal
          closeModal={() => this.setState({ showIntegrationModal: false })}
          show={this.state.showIntegrationModal}
          title="Integrate with your accounting software"
          content={
            <div>
              <a href="/quickbooks/oauth_sessions/new" className="choice">
                <div className="choice-logo">
                  <svg
                    className="icon icon-quickbooks"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none">
                      <circle fill="#2CA01C" cx="8" cy="8" r="8" />
                      <path
                        d="M2.262 7.952c0 1.699 1.378 3.076 3.077 3.076h.44V9.886h-.44a1.936 1.936 0 0 1-1.934-1.934c0-1.067.868-1.935 1.934-1.935h1.056v5.978c0 .632.512 1.143 1.143 1.143V4.875H5.34a3.077 3.077 0 0 0-3.077 3.077zm8.353-3.077h-.44v1.143h.44c1.067 0 1.934.868 1.934 1.934a1.936 1.936 0 0 1-1.934 1.934H9.56V3.908c0-.631-.512-1.143-1.143-1.143v8.264h2.2a3.077 3.077 0 0 0 0-6.154z"
                        fill="#FFF"
                      />
                    </g>
                  </svg>
                </div>

                <div className="choice-content">
                  <div className="choice-content__title">QuickBooks</div>
                  <div className="choice-content__text">Connect</div>
                </div>
              </a>
            </div>
          }
        />

        <Modal
          title="Organization Address"
          closeModal={() => this.setState({ showNewAddressModal: false })}
          show={this.state.showNewAddressModal}
          content={
            <>
              <Address
                manualUpdate
                ref="organizationAddressComponent"
                address={this.state.organizationAddress}
              />
            </>
          }
          buttons={
            <>
              <button
                className="modal-button modal-button--primary-fill"
                onClick={() => {
                  this.setState({
                    organizationAddress:
                      this.refs.organizationAddressComponent.state.address,
                    showNewAddressModal: false,
                  });
                  this.refs.organizationAddressComponent.updateAddressObject();
                }}
              >
                Save
              </button>
              <button
                className="modal-button modal-button--gray-outline"
                onClick={() => this.setState({ showNewAddressModal: false })}
              >
                Cancel
              </button>
            </>
          }
        />

        <Modal
          title="Select new client"
          closeModal={() => this.setState({ shouldShowClientModal: false })}
          show={this.state.shouldShowClientModal}
          content={
            <>
              <Message
                message="The selected client is not present in Quickbooks Online and your
                invoice cannot be sent. Please select a new client."
                variant="danger"
              />
              {this.renderClientsDropdown()}
            </>
          }
          buttons={
            <>
              <button
                className="modal-button modal-button--primary-fill"
                onClick={() => this.sendInvoice()}
              >
                Save and Send
              </button>
              <button
                className="modal-button modal-button--gray-outline"
                onClick={() => this.setState({ shouldShowClientModal: false })}
              >
                Cancel
              </button>
            </>
          }
        />

        <Modal
          title="Organization Logo"
          closeModal={() => this.setState({ showLogoModal: false })}
          show={this.state.showLogoModal}
          content={
            <>
              <div className="upload">
                {this.state.temporaryLogo ? (
                  <>
                    <div className="upload__media">
                      <img
                        className="invoice-header__image"
                        src={this.imgixifyImage(this.state.temporaryLogo)}
                      />
                      <input
                        type="file"
                        name="image"
                        className="upload__input"
                        onChange={this.uploadLogo}
                      />
                    </div>
                    <button
                      className="upload__delete is-active"
                      onClick={() => this.setState({ temporaryLogo: null })}
                    >
                      &times;
                    </button>
                  </>
                ) : (
                  <div className="upload__media is-empty">
                    <div className="upload__placeholder" />
                    <input
                      name="image"
                      className="upload__input"
                      type="file"
                      onChange={this.uploadLogo}
                    />
                  </div>
                )}
                <div className="upload__text">
                  <span className="upload__title">Logo / Wordmark</span>
                  <span className="upload__description">
                    JPG or PNG, minimum 500px on the shortest side.
                  </span>
                </div>
              </div>
            </>
          }
          buttons={
            <>
              <button
                className="modal-button modal-button--primary-fill"
                onClick={() => this.updateOrganizationLogo()}
              >
                Save
              </button>
              <button
                className="modal-button modal-button--gray-outline"
                onClick={() => this.setState({ showLogoModal: false })}
              >
                Cancel
              </button>
            </>
          }
        />
      </div>
    );
  }
}

export default RequestWrapper;
