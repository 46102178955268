import * as React from "react";

import type { AsChildProp } from "@plan/core";
import { styled } from "@plan/core";

import { useField } from "./useField";

import { Slot } from "@radix-ui/react-slot";

const FORM_LABEL_ELEMENT = "label";

const StyledFieldLabel = styled(FORM_LABEL_ELEMENT, {
  color: "$colors$neutral10",
  textStyle: "large",
  variants: {
    disabled: {
      false: {},
      true: { color: "$colors$-neutral10" },
    },
    required: {
      false: {},
      true: {
        "&:after": {
          content: "*",
          marginLeft: "$1",
          color: "$colors$danger",
        },
      },
    },
    visuallyHidden: {
      true: {
        position: "absolute",
        width: "1px",
        height: "1px",
        padding: "0",
        margin: "-1px",
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        borderWidth: "0",
        "& + *": {
          marginTop: 0, // Override Field spacing for the adjacent input
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      disabled: true,
      required: true,
      css: { "&:after": { color: "$colors$-danger20" } },
    },
  ],
  defaultVariants: {
    disabled: false,
    required: false,
    visuallyHidden: false,
  },
});

export interface FieldLabelProps
  extends AsChildProp,
    Omit<React.ComponentProps<typeof StyledFieldLabel>, "as"> {}

export const FieldLabel = React.forwardRef<HTMLLabelElement, FieldLabelProps>(
  ({ asChild, children, ...props }, ref) => {
    const { getFieldLabelElement, getFieldLabelProps } = useField();

    const DEFAULT_ELEMENT = getFieldLabelElement();
    const formLabelProps = getFieldLabelProps(props);

    const Comp = asChild ? Slot : DEFAULT_ELEMENT;

    return (
      <StyledFieldLabel as={Comp} ref={ref} {...formLabelProps}>
        {children}
      </StyledFieldLabel>
    );
  }
);
