import * as React from "react";

import { createComponent, keyframes, styled } from "@plan/core";

import * as BaseHoverCard from "@radix-ui/react-hover-card";

const component = createComponent();

const slideUp = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const slideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

export const HoverCardHeading = styled("div", {
  ...component,
  color: "$colors$neutral",
  fontSize: "$fontSizes$md",
  fontWeight: "$bold",
  lineHeight: "$base",
  marginBottom: "$space$1",
  textAlign: "center",
  textTransform: "uppercase",
});

const StyledContent = styled(BaseHoverCard.Content, {
  ...component,
  $$background: "$colors$black",
  $$text: "$colors$white",
  backgroundColor: "$$background",
  borderRadius: "$radii$default",
  color: "$$text",
  fontSize: "$fontSizes$md",
  lineHeight: "$base",
  filter: "drop-shadow(0px 1px 8px rgba(21, 37, 50, 0.12))",
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  "& a": {
    textDecoration: "underline",
  },
  variants: {
    ...component.variants,
    width: {
      sm: {
        width: "$space$48",
      },
      default: {
        width: "$space$64",
      },
      lg: {
        width: "$space$96",
      },
      content: {
        width: "max-content",
      },
    },
    padding: {
      singleLine: {
        padding: "$space$2",
      },
      multiLine: {
        padding: "$space$4",
      },
      none: {
        padding: 0,
      },
    },
    colorScheme: {
      dark: {},
      light: {
        $$background: "$colors$white",
        $$text: "$colors$neutral30",
        "& a": {
          color: "$colors$brand",
          textDecoration: "none",
          transition: "all .2s ease-in-out",
          "&:hover": {
            textDecoration: "underline",
            color: "$colors$brand30",
          },
        },
      },
    },
  },
});

const ChildrenWrapper = styled("div", {
  ...component,
  variants: {
    ...component.variants,
    maxHeight: {
      small: {
        maxHeight: "35vh",
        overflow: "auto",
        padding: "$space$2",
      },
    },
  },
});

const Arrow = styled(BaseHoverCard.Arrow, {
  fill: "$$background",
});

export type HoverCardContentProps = React.ComponentProps<typeof StyledContent> &
  Partial<Pick<React.ComponentProps<typeof ChildrenWrapper>, "maxHeight">> &
  React.ComponentProps<typeof BaseHoverCard.Content> & {
    arrowOffset?: number;
  };

export const HoverCardContent = ({
  width = "default",
  arrowOffset = 10,
  children,
  maxHeight,
  ...props
}: HoverCardContentProps) => (
  <StyledContent
    width={width}
    padding={maxHeight ? "none" : undefined}
    {...props}
  >
    <ChildrenWrapper maxHeight={maxHeight}>{children}</ChildrenWrapper>
    <Arrow offset={arrowOffset} />
  </StyledContent>
);

const StyledTrigger = styled(BaseHoverCard.Trigger, {
  cursor: "pointer",
  position: "relative",
  "&::after": {
    content: "",
    position: "absolute",
    top: "calc(100% - $space$8)",
    right: "0",
    width: "100%",
    height: "calc(100% + $space$4)",
  },
});

// FIXME: drop on first refactor
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const HoverCardTrigger = ({ children, ...props }) => (
  <StyledTrigger {...props}>{children}</StyledTrigger>
);

export type HoverCardProps = React.ComponentProps<typeof BaseHoverCard.Root> & {
  defaultOpen?: boolean;
  openDelay?: number;
  closeDelay?: number;
  children?: React.ReactNode;
};

export const HoverCard = ({
  defaultOpen = false,
  openDelay = 0,
  closeDelay = 0,
  children,
  ...props
}: HoverCardProps) => (
  <BaseHoverCard.Root
    defaultOpen={defaultOpen}
    openDelay={openDelay}
    closeDelay={closeDelay}
    {...props}
  >
    {children}
  </BaseHoverCard.Root>
);
