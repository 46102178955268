/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconCheckmarkCircleOutlinedProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconCheckmarkCircleOutlined = ({
  label,
  ...props
}: SvgIconCheckmarkCircleOutlinedProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm3.12-11.51a.5.5 0 0 1 .7 0l.5.53a.5.5 0 0 1 0 .71l-5.62 5.62a.48.48 0 0 1-.7 0l-2.35-2.37a.5.5 0 0 1 0-.71l.53-.53a.48.48 0 0 1 .7 0l1.5 1.49 4.74-4.74Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconCheckmarkCircleOutlined;
