import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Download from "Components/blocks/icons/Download";
import Button from "./Button";
import styles from "./buttons.module.css";

export function DownloadButton({ action, loading, disabledWhileLoading }) {
  const onClick = useCallback(() => {
    if (disabledWhileLoading && loading) return;
    return action();
  }, [disabledWhileLoading, loading, action]);

  return (
    <>
      <Button buttonStyle="outline-icon" size="medium" action={onClick}>
        <div className={styles.buttonExport}>
          {loading ? "Exporting" : "Export"}
        </div>
        <Download loading={loading} />
      </Button>
    </>
  );
}

DownloadButton.propTypes = {
  action: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabledWhileLoading: PropTypes.bool,
};

DownloadButton.defaultProps = {
  loading: false,
  disabledWhileLoading: false,
};

export default DownloadButton;
