import * as React from "react";

import { styled } from "@plan/core";
import { AutoLayout, Box } from "@plan/layout";

const RelativeBox = styled(Box, {
  position: "relative",
});

const ConfettiCircle = styled("div", {
  position: "absolute",
  width: "$space$3",
  height: "$space$3",
  border: "3px solid $colors$-neutral30",
  borderRadius: "$radii$round",
  "&:nth-child(1)": {
    marginTop: "$space$28",
    marginLeft: "18%",
  },
  "&:nth-child(3)": {
    marginTop: "$space$20",
    marginLeft: "80%",
  },
  "&:nth-child(5)": {
    marginTop: "$space$72",
    marginLeft: "70%",
  },
});

const ConfettiCross = styled("div", {
  position: "absolute",
  width: "$space$4",
  height: "$space$4",
  "&:nth-child(2)": {
    marginTop: "$space$52",
    marginLeft: "10%",
  },
  "&:nth-child(4)": {
    marginTop: "$space$64",
    marginLeft: "33%",
  },
  "&:nth-child(6)": {
    marginTop: "$space$12",
    marginLeft: "55%",
  },
  "&::before, &::after": {
    $$crossDiameter: "$space$3",
    $$crossRadius: "calc($$crossDiameter / 4)",
    content: '""',
    display: "block",
    width: "$$crossDiameter",
    height: "$$crossRadius",
    borderRadius: "$radii$default",
    backgroundColor: "$colors$-neutral30",
  },
  "&::before": {
    transform: "rotate(45deg)",
  },
  "&::after": {
    marginTop: "-$$crossRadius",
    transform: "rotate(-45deg)",
  },
});

type EmptyStateProps = {
  children?: React.ReactNode;
};

export const TableEmptyState = ({ children }: EmptyStateProps) => (
  <>
    <RelativeBox resizingX="fill-container" resizingY="fill-container">
      <ConfettiCircle />
      <ConfettiCross />
      <ConfettiCircle />
      <ConfettiCross />
      <ConfettiCircle />
      <ConfettiCross />
      <AutoLayout
        direction="vertical"
        alignment="center"
        resizingX="fill-container"
        resizingY="fill-container"
        css={{ minHeight: "$space$80" }}
      >
        {children}
      </AutoLayout>
    </RelativeBox>
  </>
);
