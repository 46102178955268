import React, { useRef, useState, useEffect } from "react";
import max from "lodash/max";
import ResizeObserver from "resize-observer-polyfill";
import { useSelectedPhase, useGridView } from "../state/Store";

// The grid's height changes when the view is toggled, so we update the height as necessary
export default function useGridHeight(ref, initial) {
  const [height, setHeight] = useState(initial);
  const gridView = useGridView();
  const [selectedPhase] = useSelectedPhase();

  const INTERCOM_BUBBLE_HEIGHT = 70;

  // There is some issue with this where if you scroll to the bottom and refresh
  // the page, the page keeps getting taller
  const calcHeight = () => {
    if (ref.current) {
      const gridAndWindow =
        window.innerHeight +
        200 +
        INTERCOM_BUBBLE_HEIGHT -
        ref.current.getBoundingClientRect().top;
      const currentScrollHeight = ref.current.scrollHeight;
      setHeight(max([gridAndWindow, currentScrollHeight]));
    }
  };

  const observer = useRef(
    new ResizeObserver(() => {
      calcHeight();
    })
  );

  useEffect(() => {
    if (ref.current && observer) observer.current.observe(ref.current);
    return () => {
      if (ref.current && observer)
        return observer.current.unobserve(ref.current);
    };
  }, [ref.current, observer.current]);

  useEffect(() => {
    calcHeight();
  }, [ref, gridView, selectedPhase]);

  useEffect(() => {
    window.addEventListener("resize", calcHeight);
    return () => window.removeEventListener("resize", calcHeight);
  }, []);

  return height;
}
