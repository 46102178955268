/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconNewMessageProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconNewMessage = ({ label, ...props }: SvgIconNewMessageProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 11.5V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5v14h14v-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5Zm-.29-7.79-.42-.42a1 1 0 0 0-.7-.29h-.3a1 1 0 0 0-.7.29l-1.8 1.8a.5.5 0 0 0 0 .7l1.42 1.42a.5.5 0 0 0 .7 0l1.8-1.8a1 1 0 0 0 .29-.7v-.3a1 1 0 0 0-.29-.7ZM7.04 16.29l.79-2a2 2 0 0 1 .44-.68L14.85 7a.5.5 0 0 1 .7 0L17 8.45a.5.5 0 0 1 0 .7l-6.63 6.56a2.001 2.001 0 0 1-.68.44l-2 .79a.5.5 0 0 1-.65-.65Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconNewMessage;
