import React from "react";
import cx from "classnames";
import { FieldProvider } from "./FieldContext";
import Input from "./Input";
import Label from "./Label";
import Error from "./Error";
import FieldWrapper from "./FieldWrapper";
import styles from "./field-group.module.css";
import fieldStyle from "./field.module.css";

export function Field(props) {
  const { children, grouped, blockClasses } = props;
  function fieldStyles() {
    if (props.borderless) {
      return {
        border: "none",
      };
    }
    if (props.warning) {
      return {
        background: "#FFFAE5",
        borderColor: "#FFC020",
      };
    }
    return {};
  }

  return (
    <FieldProvider {...props}>
      <FieldWrapper>
        <div
          className={cx(
            fieldStyle.field__block,
            { [styles.fieldGroupBlock]: grouped },
            { [blockClasses]: blockClasses }
          )}
          style={fieldStyles()}
        >
          <Label />
          <Input />
          {children}
        </div>
        <Error />
      </FieldWrapper>
    </FieldProvider>
  );
}

export default React.memo(Field);
