import * as React from "react";

import type { AutoLayoutProps, BoxProps } from "@plan/layout";
import { AutoLayout, Box } from "@plan/layout";

import { Slot } from "@radix-ui/react-slot";

export interface FormListProps
  extends React.HTMLAttributes<HTMLUListElement>,
    Pick<AutoLayoutProps, "direction"> {}

export const FormList = React.forwardRef<HTMLUListElement, FormListProps>(
  (props, ref) => (
    <AutoLayout
      // Override dodgy Stitches `ref` types 🥴
      as={"ul" as unknown as typeof Slot}
      direction="vertical"
      ref={ref}
      role="list"
      spacing="4"
      {...props}
    />
  )
);

export type FormListItemProps = Omit<BoxProps, "as">;
export const FormListItem = React.forwardRef<HTMLLIElement, FormListItemProps>(
  (props, ref) => (
    <Box
      // Override dodgy Stitches `ref` types 🥴
      as={"li" as unknown as typeof Slot}
      ref={ref}
      resizingX="fill-container"
      resizingY="fill-container"
      {...props}
    />
  )
);
