import { ComponentProps } from "react";

import { createComponent, createTextStyleVariant, styled } from "@plan/core";

const component = createComponent();

const textStyle = createTextStyleVariant();

export type HeadingProps = ComponentProps<typeof Heading>;

export const Heading = styled("h2", {
  ...component,
  fontFamily: "$fonts$sans",
  variants: {
    ...component.variants,
    textStyle,
  },
  defaultVariants: {
    ...component.defaultVariants,
    textStyle: "heading2",
  },
});
