import { ComponentProps } from "react";

import { createComponent, createTextStyleVariant, styled } from "@plan/core";

const component = createComponent();

const textStyle = createTextStyleVariant();

export type TextProps = ComponentProps<typeof Text>;

export const Text = styled("p", {
  ...component,
  fontFamily: "$fonts$sans",
  variants: {
    ...component.variants,
    textStyle,
    ellipsis: {
      true: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    textStyle: "medium",
  },
});
