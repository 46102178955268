import React, { useRef, useEffect, useMemo } from "react";
import FlexWrapper from "Components/blocks/wrappers/FlexWrapper";
import { useAllocations, usePhaseOrder, useProfiles } from "../../state/Store";
import ColumnHeader from "./ColumnHeader";
import ProfileHeader from "./ProfileHeader";
import { COLUMN_MIN_WIDTH, COLUMN_MAX_WIDTH } from "../../scheduleConfig";
import { TeamColumnAnimation } from "../columns/TeamColumn";
import { setStatusColor } from "./setStatusColor";

export const Headers = ({ order, scroll }) => {
  const header = useRef(null);
  const allocations = useAllocations();
  const phaseOrder = usePhaseOrder();
  const profiles = useProfiles();

  // We listen for scroll events in the grid and apply them to the header
  useEffect(() => {
    const unsubscribeScroll = scroll.onChange((value) => {
      header.current.scrollLeft = value;
    });
    return unsubscribeScroll;
  }, []);

  const totalAllocationHours = useMemo(
    () =>
      Object.keys(allocations).reduce(
        (acc, id) => Number(acc) + Number(allocations[id].allocation),
        []
      ),
    [allocations]
  );

  const totalTeamHours = useMemo(
    () =>
      Object.keys(profiles).reduce(
        (total, id) =>
          Number(total) + Number(profiles[id.toString()].hoursPerWeek),
        0
      ),
    [profiles]
  );

  const badgeColor = setStatusColor(totalAllocationHours, totalTeamHours);

  const stickyHeaderStyles = {
    display: "grid",
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "visible",
    position: "sticky",
    top: 0,
    zIndex: 3,
  };

  const teamStickyHeaderStyles = {
    position: "sticky",
    left: 0,
    top: 0,
    zIndex: 15,
  };

  const headerGridStyles = {
    display: "grid",
    gridTemplateColumns: `repeat(${order.length}, minmax(${COLUMN_MIN_WIDTH}, ${COLUMN_MAX_WIDTH}))`,
    width: "100%",
    height: "100%",
    background: "var(--color-legacy-gray-2)",
    overflowX: "hidden",
    overflowY: "visible",
    position: "sticky",
    top: 0,
    zIndex: 3,
  };

  return (
    <div style={stickyHeaderStyles} ref={header}>
      <FlexWrapper>
        <TeamColumnAnimation styles={teamStickyHeaderStyles}>
          <ColumnHeader
            name="Team"
            count={phaseOrder.length}
            hours={totalAllocationHours}
            hoursPerWeek={totalTeamHours}
            badgeColor={badgeColor}
            team
          />
        </TeamColumnAnimation>
        <div style={headerGridStyles}>
          {order.map((id) => (
            <ProfileHeader key={id} id={id} />
          ))}
        </div>
      </FlexWrapper>
    </div>
  );
};

export default React.memo(Headers);
