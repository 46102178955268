// Binds event to body to be triggered when the event target is the elementClass
// Also works if elementClass is an array of classes (not sure how necessary this is)
// Also, when this is called it records the data of the body so as to only call this
// on the current page, keeping event listeners scoped to each page
const bindListenerToDocument = (event, elementClass, callback) => {
  const bodyData = document.body.dataset.js;
  if (Array.isArray(elementClass)) {
    document.addEventListener(event, (event) => {
      if (
        document.body.dataset.js === bodyData &&
        elementClass.some((element) => event.target.classList.contains(element))
      ) {
        callback(event);
      }
    });
  } else {
    document.addEventListener(event, (event) => {
      if (
        document.body.dataset.js === bodyData &&
        event.target.classList.contains(elementClass)
      ) {
        callback(event);
      }
    });
  }
};

export default bindListenerToDocument;
