import moment from "moment";

// takes a moment date
function makeWeek(date) {
  const mon = date.clone().startOf("isoweek");
  const tue = mon.clone().add(1, "d").format("YYYY-MM-DD");
  const wed = mon.clone().add(2, "d").format("YYYY-MM-DD");
  const thu = mon.clone().add(3, "d").format("YYYY-MM-DD");
  const fri = mon.clone().add(4, "d").format("YYYY-MM-DD");
  const sat = mon.clone().add(5, "d").format("YYYY-MM-DD");
  const sun = mon.clone().add(6, "d").format("YYYY-MM-DD");
  return [
    { date: mon.format("YYYY-MM-DD") },
    { date: tue },
    { date: wed },
    { date: thu },
    { date: fri },
    { date: sat },
    { date: sun },
  ];
}

export const makeWeekDates = (date) => {
  const mon = date.clone().startOf("isoweek");
  const tue = mon.clone().add(1, "d").format("YYYY-MM-DD");
  const wed = mon.clone().add(2, "d").format("YYYY-MM-DD");
  const thu = mon.clone().add(3, "d").format("YYYY-MM-DD");
  const fri = mon.clone().add(4, "d").format("YYYY-MM-DD");
  const sat = mon.clone().add(5, "d").format("YYYY-MM-DD");
  const sun = mon.clone().add(6, "d").format("YYYY-MM-DD");
  return [mon.format("YYYY-MM-DD"), tue, wed, thu, fri, sat, sun];
};

export const makeWeekHours = (date) => {
  const week = makeWeek(date);
  return week.map((day) => {
    const newDay = { ...day };
    newDay["hours"] = 0;
    return newDay;
  });
};

const formatAsData = (dateArray) => {
  const dateObject = Object.assign(...dateArray.map((k) => ({ [k]: "" })));
  const data = {
    hours: dateObject,
    notes: dateObject,
  };
};

export default makeWeek;
