import React from "react";

export const Warning = ({ fill = "#EA4157" }) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5278 0.5C10.1429 -0.166667 9.18062 -0.166666 8.79572 0.5L0.135467 15.5C-0.249434 16.1667 0.231691 17 1.00149 17H18.322C19.0918 17 19.5729 16.1667 19.188 15.5L10.5278 0.5ZM9.15161 11.9736C9.30217 12.116 9.49137 12.1873 9.71924 12.1873C9.9471 12.1873 10.1343 12.116 10.2808 11.9736C10.4313 11.8312 10.5066 11.6522 10.5066 11.4365V4.96069C10.5066 4.74504 10.4313 4.566 10.2808 4.42358C10.1302 4.2771 9.94304 4.20386 9.71924 4.20386C9.49544 4.20386 9.30827 4.2771 9.15772 4.42358C9.00716 4.566 8.93189 4.74504 8.93189 4.96069C8.93189 5.52222 8.92578 6.3645 8.91358 7.48755C8.90544 8.6106 8.90137 9.45288 8.90137 10.0144C8.90137 10.1731 8.90544 10.4111 8.91358 10.7285C8.92578 11.0418 8.93189 11.2778 8.93189 11.4365C8.93189 11.6522 9.00513 11.8312 9.15161 11.9736ZM9.71729 14.748C10.2005 14.748 10.5923 14.3563 10.5923 13.873C10.5923 13.3898 10.2005 12.998 9.71729 12.998C9.23404 12.998 8.84229 13.3898 8.84229 13.873C8.84229 14.3563 9.23404 14.748 9.71729 14.748Z"
        fill={fill}
      />
    </svg>
  );
};

export default React.memo(Warning);
