import React, { useContext, useReducer } from "react";
import moment from "moment";

export const GlobalContext = React.createContext({});
export const GlobalDispatchContext = React.createContext();
export const HeadersTotalsContext = React.createContext();

// defining actions
export const RESET_STATE = "RESET_STATE";
export const UPDATE_ENTRY = "UPDATE_ENTRY";
export const ENTRY_NOTE = "ENTRY_NOTE";
export const UPDATE_SELECTD_ENTRY = "UPDATE_SELECTD_ENTRY";
export const NEW_ROW_ACTIVITY = "NEW_ROW_ACTIVITY";
export const UPDATE_ROW_ACTIVITY = "UPDATE_ROW_ACTIVITY";
export const DELETE_ROW = "DELETE_ROW";
export const DELETE_GROUP = "DELETE_GROUP";
export const NEW_PROJECT_BLOCK = "NEW_PROJECT_BLOCK";
export const DELETE_BLOCK = "DELETE_BLOCK";
export const NEW_OVERHEAD_BLOCK = "NEW_OVERHEAD_BLOCK";
export const DELETE_OVERHEAD_GROUP = "DELETE_OVERHEAD_GROUP";
export const NEW_EXPENSE = "NEW_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE EXPENSE";
export const UPDATE_TIMESHEET_LOCK = "UPDATE_TIMESHEET_LOCK";

// action creators, input payload
// type, payload object
// want the type to match our action type
export function resetState(payload) {
  return {
    type: RESET_STATE,
    payload,
  };
}

export function updateEntry(payload) {
  return {
    type: UPDATE_ENTRY,
    payload,
  };
}

export function entryNote(payload) {
  return {
    type: ENTRY_NOTE,
    payload,
  };
}

export function updateSelectedEntry(payload) {
  return {
    type: UPDATE_SELECTD_ENTRY,
    payload,
  };
}

export function addRow(payload) {
  return {
    type: NEW_ROW_ACTIVITY,
    payload,
  };
}

export function updateRow(payload) {
  return {
    type: UPDATE_ROW_ACTIVITY,
    payload,
  };
}

export function deleteRow(payload) {
  return {
    type: DELETE_ROW,
    payload,
  };
}

export function deleteGroup(payload) {
  return {
    type: DELETE_GROUP,
    payload,
  };
}

export function addProject(payload) {
  return {
    type: NEW_PROJECT_BLOCK,
    payload,
  };
}

export function deleteBlock(payload) {
  return {
    type: DELETE_BLOCK,
    payload,
  };
}

export function newOverhead(payload) {
  return {
    type: NEW_OVERHEAD_BLOCK,
    payload,
  };
}

export function deleteOverhead(payload) {
  return {
    type: DELETE_OVERHEAD_GROUP,
    payload,
  };
}

export function newExpense(payload) {
  return {
    type: NEW_EXPENSE,
    payload,
  };
}

export function updateExpense(payload) {
  return {
    type: UPDATE_EXPENSE,
    payload,
  };
}

export function deleteExpense(payload) {
  return {
    type: DELETE_EXPENSE,
    payload,
  };
}

export function updateTimesheetLock(payload) {
  return {
    type: UPDATE_TIMESHEET_LOCK,
    payload,
  };
}

const orderProjects = (projects) =>
  projects.allIds
    .sort((a, b) => projects.byId[a].name.localeCompare(projects.byId[b].name))
    .sort((a, b) => {
      a = projects.byId[a].number || " ";
      b = projects.byId[b].number || " ";
      return a.localeCompare(b);
    });
const orderEntryGroups = (ids, projectGroups) =>
  ids.sort((a, b) => {
    a = projectGroups.byId[a].phaseId || " ";
    b = projectGroups.byId[b].phaseId || " ";
    return a.localeCompare(b);
  });
const orderOverheads = (overheads) =>
  overheads.allIds
    .sort((a, b) =>
      overheads.byId[a].name.localeCompare(overheads.byId[b].name)
    )
    .map((o) => overheads.byId[o].entryRowId);
const orderEntryRows = (
  orderedSelectedProjects,
  orderedGroupsByProjectId,
  orderedOverheadRows,
  projectGroups
) => {
  const sortedRows = [];
  orderedSelectedProjects.forEach((proj) => {
    const sortedGroups = orderedGroupsByProjectId[proj];
    sortedGroups.forEach((group) => {
      sortedRows.push(...projectGroups.byId[group].entryRows);
    });
  });
  sortedRows.push(...orderedOverheadRows);
  return sortedRows;
};

const setSelectedEntryDate = (beginDate) => {
  const todaysDate = moment();
  const timesheetBegin = moment(beginDate);
  const timesheetEnd = timesheetBegin.clone().add(6, "days");
  const selectedDate = todaysDate.isBetween(timesheetBegin, timesheetEnd)
    ? todaysDate
    : timesheetBegin;
  return selectedDate.format("YYYY-MM-DD");
};

// with every action, return an updated state
// no action, return default state
export default function reducer(state, action) {
  const deleteEntries = (newState, rowId) => {
    delete newState.entriesByRowId[rowId];
    return newState;
  };

  const deleteRow = (newState, groupId, rowId) => {
    const newrows = newState.rows.allIds.filter((id) => id !== rowId);
    newState.rows.allIds = newrows;
    delete newState.rows.byId[rowId];

    const newRowsInGroup = newState.projectGroups.byId[
      groupId
    ].entryRows.filter((id) => id !== rowId);
    newState.projectGroups.byId[groupId].entryRows = newRowsInGroup;

    newState = deleteEntries(newState, rowId);
    return newState;
  };

  const deleteGroup = (newState, projectId, groupId) => {
    // delete all rows in group
    newState.projectGroups.byId[groupId].entryRows.forEach((rowId) => {
      newState = deleteRow(newState, groupId, rowId);
    });
    // remove group from project entryGroups
    const newProjectGroups = newState.selectedProjects.byId[
      projectId
    ].entryGroups.filter((id) => id !== groupId);
    newState.selectedProjects.byId[projectId].entryGroups = newProjectGroups;
    // delete group from groups
    const newGroups = newState.projectGroups.allIds.filter(
      (g) => g !== groupId
    );
    newState.projectGroups.allIds = newGroups;
    delete newState.projectGroups.byId[groupId];
    return newState;
  };

  switch (action.type) {
    case RESET_STATE: {
      const { newData, date, references } = action.payload;
      const orderedSelectedProjects = orderProjects(newData.selectedProjects);
      const orderedGroupsByProjectId = newData.selectedProjects.allIds.reduce(
        (ac, proj) => {
          const ids = newData.selectedProjects.byId[proj].entryGroups;
          const orderedGroups = orderEntryGroups(ids, newData.projectGroups);
          return { ...ac, [proj]: orderedGroups };
        },
        []
      );

      const orderedOverheadRows = orderOverheads(newData.selectedOverheads);
      const orderedRows = orderEntryRows(
        orderedSelectedProjects,
        orderedGroupsByProjectId,
        orderedOverheadRows,
        newData.projectGroups
      );
      const selectedEntry = { rowId: 0, date: "" };

      const overheadSelectOptions = newData.overheadOptions.map((overhead) => ({
        label: overhead.name,
        value: overhead.id,
      }));

      const newState = {
        timesheet: newData.timesheet,
        date,
        timeOffRequests: newData.timeOffRequests,
        timeOffHolidays: newData.timeOffHolidays,
        entriesByRowId: newData.entriesByRowId,
        expenses: newData.expenses,
        rows: newData.rows,
        projectGroups: newData.projectGroups,
        selectedProjects: newData.selectedProjects,
        selectedOverheads: newData.selectedOverheads,
        overheadOptions: overheadSelectOptions,
        expenseCategoryOptions: newData.expenseCategoryOptions,
        expenseCategoryOptionsByProjectId:
          newData.expenseCategoryOptionsByProjectId,
        references,
        selectedEntry,
        orderedSelectedProjects,
        orderedGroupsByProjectId,
        orderedOverheadRows,
        orderedRows,
      };
      return newState;
      break;
    }
    case UPDATE_ENTRY: {
      const { rowId, date, hours, notes } = action.payload;
      const newState = { ...state };
      newState.entriesByRowId[rowId][date].hours = hours;
      newState.entriesByRowId[rowId][date].notes = notes;

      return newState;
      break;
    }
    case UPDATE_SELECTD_ENTRY: {
      const { rowId, date } = action.payload;
      const newState = { ...state };
      newState.selectedEntry = { rowId, date };
      return newState;
      break;
    }
    case NEW_ROW_ACTIVITY: {
      const { rowId, groupId, projectId, isApproved, entries } = action.payload;
      const newState = { ...state };
      newState.projectGroups.byId[groupId].entryRows.push(rowId);

      const newRow = {
        activityName: "",
        activityId: "",
        id: rowId,
        isApproved,
      };
      newState.rows.allIds.push(rowId);
      newState.rows.byId[rowId] = newRow;
      newState.entriesByRowId[rowId] = entries;

      const newOrderedRows = orderEntryRows(
        newState.orderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newState.orderedOverheadRows,
        newState.projectGroups
      );
      newState.orderedRows = newOrderedRows;

      return newState;
      break;
    }
    case UPDATE_ROW_ACTIVITY: {
      const { rowId, activityId, activityName } = action.payload;
      const newState = { ...state };
      newState.rows.byId[rowId].activityId = activityId;
      newState.rows.byId[rowId].activityName = activityName;
      return newState;
      break;
    }
    case DELETE_ROW: {
      const { projectId, groupId, rowId } = action.payload;
      const currState = { ...state };

      const newState = deleteRow(currState, groupId, rowId);
      const newOrderedRows = orderEntryRows(
        newState.orderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newState.orderedOverheadRows,
        newState.projectGroups
      );
      newState.orderedRows = newOrderedRows;
      return newState;
      break;
    }
    case DELETE_GROUP: {
      const { projectId, groupId } = action.payload;
      const currState = { ...state };

      const newState = deleteGroup(currState, projectId, groupId);

      const ids = newState.selectedProjects.byId[projectId].entryGroups;
      const newOrderedGroups = orderEntryGroups(ids, newState.projectGroups);
      newState.orderedGroupsByProjectId[projectId] = newOrderedGroups;

      const newOrderedRows = orderEntryRows(
        newState.orderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newState.orderedOverheadRows,
        newState.projectGroups
      );
      newState.orderedRows = newOrderedRows;
      return newState;
      break;
    }
    case NEW_PROJECT_BLOCK: {
      const { project, group, row, entries, projectId, reference } =
        action.payload;
      const newState = { ...state };
      // if the project is not yet in the timesheet, add to blocks
      if (!newState.selectedProjects.byId[projectId]) {
        newState.selectedProjects.allIds.push(projectId);
        newState.selectedProjects.byId[projectId] = project;
        newState.references[projectId] = reference;
      } else if (
        !newState.selectedProjects.byId[projectId].entryGroups.includes(
          group.id
        )
      ) {
        newState.selectedProjects.byId[projectId].entryGroups.push(group.id);
      }
      newState.projectGroups.byId[group.id] = group;

      if (!newState.projectGroups.allIds.includes(group.id)) {
        newState.projectGroups.allIds.push(group.id);
      }

      newState.rows.allIds.push(row.id);
      newState.rows.byId[row.id] = row;
      newState.entriesByRowId[row.id] = entries;

      const newOrderedSelectedProjects = orderProjects(
        newState.selectedProjects
      );
      newState.orderedSelectedProjects = newOrderedSelectedProjects;

      const groupIds = newState.selectedProjects.byId[projectId].entryGroups;
      const newOrderedGroups = orderEntryGroups(
        groupIds,
        newState.projectGroups
      );
      newState.orderedGroupsByProjectId[projectId] = newOrderedGroups;

      const newOrderedRows = orderEntryRows(
        newOrderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newState.orderedOverheadRows,
        newState.projectGroups
      );
      newState.orderedRows = newOrderedRows;

      const newSelectedEntry = {
        rowId: row.id,
        date: setSelectedEntryDate(newState.date),
      };
      newState.selectedEntry = newSelectedEntry;

      return newState;
      break;
    }
    case DELETE_BLOCK: {
      const { projectId } = action.payload;
      let newState = { ...state };
      // delete all groups in project, which calls delete rows and entries
      newState.selectedProjects.byId[projectId]?.entryGroups.forEach(
        (groupId) => {
          newState = deleteGroup(newState, projectId, groupId);
        }
      );

      const newProjects = newState.selectedProjects.allIds.filter(
        (id) => id !== projectId
      );
      newState.selectedProjects.allIds = newProjects;
      delete newState.selectedProjects.byId[projectId];

      const newOrderedSelectedProjects =
        newState.orderedSelectedProjects.filter((p) => p !== projectId);
      delete newState.orderedGroupsByProjectId[projectId];
      const newOrderedRows = orderEntryRows(
        newOrderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newState.orderedOverheadRows,
        newState.projectGroups
      );

      newState.orderedSelectedProjects = newOrderedSelectedProjects;
      newState.orderedRows = newOrderedRows;

      return newState;
      break;
    }
    case NEW_OVERHEAD_BLOCK: {
      const { overheadId, group, row, entries } = action.payload;
      const newState = { ...state };
      newState.selectedOverheads.byId[overheadId] = group;
      newState.selectedOverheads.allIds.push(overheadId);

      newState.rows.byId[row.id] = row;
      newState.rows.allIds.push(row.id);

      newState.entriesByRowId[row.id] = entries;

      const newOrderedOverheadRows = orderOverheads(newState.selectedOverheads);
      const newOrderedRows = orderEntryRows(
        newState.orderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newOrderedOverheadRows,
        newState.projectGroups
      );
      newState.orderedRows = newOrderedRows;

      const newSelectedEntry = {
        rowId: row.id,
        date: setSelectedEntryDate(newState.date),
      };
      newState.selectedEntry = newSelectedEntry;

      newState.overheadOptions = state.overheadOptions.filter(
        (overheadOption) => overheadOption.value !== overheadId
      );

      return newState;
      break;
    }
    case DELETE_OVERHEAD_GROUP: {
      const { rowId, overheadId } = action.payload;
      const newState = { ...state };

      const newRows = newState.rows.allIds.filter((id) => id !== rowId);
      newState.rows.allIds = newRows;
      delete newState.rows.byId[rowId];

      delete newState.entriesByRowId[rowId];

      const newOverheads = newState.selectedOverheads.allIds.filter(
        (id) => id !== overheadId
      );
      newState.selectedOverheads.allIds = newOverheads;
      delete newState.selectedOverheads.byId[overheadId];

      const newOrderedOverheadRows = orderOverheads(newState.selectedOverheads);
      const newOrderedRows = orderEntryRows(
        newState.orderedSelectedProjects,
        newState.orderedGroupsByProjectId,
        newOrderedOverheadRows,
        newState.projectGroups
      );
      newState.orderedRows = newOrderedRows;

      return newState;
      break;
    }
    case NEW_EXPENSE: {
      const { rowId, date, expenseId, expense } = action.payload;
      const newState = { ...state };

      newState.expenses.byId[expenseId] = expense;
      newState.expenses.allIds.push(expenseId);

      newState.entriesByRowId[rowId][date].expenses.push(expenseId);

      return newState;
      break;
    }
    case UPDATE_EXPENSE: {
      const { expense } = action.payload;
      const newState = { ...state };
      newState.expenses.byId[expense.id] = expense;
      return newState;
      break;
    }
    case DELETE_EXPENSE: {
      const { rowId, date, expenseId } = action.payload;
      const newState = { ...state };

      newState.expenses.allIds = newState.expenses.allIds.filter(
        (id) => id !== expenseId
      );
      delete newState.expenses.byId[expenseId];
      const newExpensesInEntry = newState.entriesByRowId[rowId][
        date
      ].expenses.filter((x) => x !== expenseId);

      newState.entriesByRowId[rowId][date].expenses = newExpensesInEntry;

      return newState;
      break;
    }
    case UPDATE_TIMESHEET_LOCK: {
      const { isUnlocked } = action.payload;
      const newState = { ...state };

      newState.timesheet.isUnlocked = isUnlocked;
      return newState;
      break;
    }
    default:
      return state;
  }
}

export function GlobalProvider({
  children,
  timesheet,
  timeOffRequests,
  timeOffHolidays,
  date,
  entriesByRowId,
  expenses,
  rows,
  projectGroups,
  selectedProjects,
  selectedOverheads,
  expenseCategoryOptions,
  overheadOptions,
  expenseCategoryOptionsByProjectId,
  references,
}) {
  const orderedSelectedProjects = orderProjects(selectedProjects);
  const orderedGroupsByProjectId = selectedProjects.allIds.reduce(
    (ac, proj) => {
      const ids = selectedProjects.byId[proj].entryGroups;
      const orderedGroups = orderEntryGroups(ids, projectGroups);
      return { ...ac, [proj]: orderedGroups };
    },
    []
  );

  const orderedOverheadRows = orderOverheads(selectedOverheads);
  const orderedRows = orderEntryRows(
    orderedSelectedProjects,
    orderedGroupsByProjectId,
    orderedOverheadRows,
    projectGroups
  );
  const selectedEntry = { rowId: 0, date: "" };

  // useReducer, takes initial state object, returns values and setValues (dispatch)
  // action is dispatched, such as AddRow, what we react to when mutating state
  const initial = {
    date,
    timesheet,
    timeOffRequests,
    timeOffHolidays,
    entriesByRowId,
    expenses,
    rows,
    projectGroups,
    selectedProjects,
    selectedOverheads,
    expenseCategoryOptions,
    overheadOptions,
    expenseCategoryOptionsByProjectId,
    references,
    selectedEntry,
    orderedSelectedProjects,
    orderedGroupsByProjectId,
    orderedOverheadRows,
    orderedRows,
  };

  const [state, dispatch] = useReducer(reducer, initial);

  return (
    <GlobalContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalContext.Provider>
  );
}

const useStateObject = () => useContext(GlobalContext);
const useGlobalStateDispatch = () => useContext(GlobalDispatchContext);

export const useGlobalState = () => ({
  state: useStateObject(),
  dispatch: useGlobalStateDispatch(),
});
