import React, { useMemo } from "react";
import numeral from "numeral";
import util from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import ExpenseItem from "Components/timesheets/ExpenseItem";
import Modal from "Components/blocks/modal/Modal";
import ActionButtons from "Components/blocks/buttons/ActionButtons";
import ExpenseSelect from "./timesheet/expense/ExpenseSelect";
import {
  useGlobalState,
  newExpense,
  updateExpense,
  deleteExpense,
} from "./timesheet/state/GlobalContext.jsx";

const ExpenseModal = ({
  show,
  closeModal,
  editable,
  entryId,
  entryRowId,
  date,
  projectName,
  projectId,
  isOverhead = false,
}) => {
  const { state, dispatch } = useGlobalState();

  const expenseCategories =
    state.expenseCategoryOptionsByProjectId[projectId] ||
    state.expenseCategoryOptions;

  const expenses = state.entriesByRowId[entryRowId][date].expenses.map(
    (expenseId) => state.expenses.byId[expenseId]
  );

  const formattedCategories = expenseCategories.map((expense) => ({
    label: expense.name,
    value: expense.id,
  }));

  const actions = {
    toggle: () => {},
    open: () => {},
    close: closeModal,
  };

  const newExpenseHandler = (option) => {
    const expenseCategoryId = option.value;
    const data = {
      entry_row_id: entryRowId,
      date,
      expense_category_id: expenseCategoryId,
      is_billable: !isOverhead,
    };
    ajax({
      method: "POST",
      path: `/expenses`,
      data,
      success: (response) => {
        const expense = JSON.parse(response);
        const expenseId = expense.id;
        dispatch(newExpense({ rowId: entryRowId, date, expenseId, expense }));
      },
    });
  };
  const updateExpenseHandler = ({ expenseId, updates }) => {
    ajax({
      method: "PUT",
      path: `/expenses/${expenseId}`,
      data: {
        ...updates,
      },
      success: (response) => {
        const expense = JSON.parse(response);
        dispatch(updateExpense({ expense }));
      },
      failure: () => closeModal(),
    });
  };

  const deleteExpenseHandler = (expenseId) => {
    ajax({
      method: "DELETE",
      path: `/expenses/${expenseId}`,
      success: (response) => {
        dispatch(
          deleteExpense({ rowId: entryRowId, entryId, date, expenseId })
        );
      },
      failure: () => closeModal(),
    });
  };

  const amountSum = () => {
    if (!expenses) return numeral(0).format("0.00");
    const sum = Object.keys(expenses).reduce((sum, expenseId) => {
      if (parseFloat(expenses[expenseId].amount)) {
        return sum + parseFloat(expenses[expenseId].amount);
      }
      return sum;
    }, 0);

    return numeral(sum).format("0.00");
  };

  const totalExpenseDollars = useMemo(
    () => util.toCurrencyWithCents(amountSum()),
    [amountSum]
  );
  const subheading = `${projectName} · ${date} · ${totalExpenseDollars}`;

  if (!show) return null;

  return (
    <Modal
      title="Add Expense"
      subHeading={subheading}
      size="medium"
      isOpen
      actions={actions}
    >
      <div className="modal-section modal-section--no-padding">
        <ul className="expense-list">
          {expenses &&
            expenses.map((expense) => {
              const categoryName =
                expenseCategories.find(
                  (option) => option.id === expense.expenseCategoryId
                )?.name || "Deleted Expense Category";
              const expenseCategory = {
                value: expense.expenseCategoryId,
                label: categoryName,
              };
              return (
                <ExpenseItem
                  key={expense.id}
                  editable={editable}
                  hasLineItem={expense.hasLineItem}
                  expense={expense}
                  expenseCategories={formattedCategories}
                  updateExpense={updateExpenseHandler}
                  deleteExpense={deleteExpenseHandler}
                  amount={expense.amount}
                  category={expenseCategory}
                  isOverhead={isOverhead}
                />
              );
            })}
        </ul>
      </div>

      <div
        style={{
          borderTop: "1px solid #eee",
          paddingTop: "1rem",
        }}
      >
        <ExpenseSelect
          entryId={entryId}
          selectedValue={123}
          handleChange={newExpenseHandler}
          projectId={projectId}
        />
      </div>
      <ActionButtons
        buttons={[{ color: "action", text: "Done", action: actions.close }]}
      />
    </Modal>
  );
};

export default ExpenseModal;
