import { ComponentProps } from "react";

import { createLayout, styled } from "@plan/core";
import { AutoLayout } from "@plan/layout";

// We use `createLayout()` props intentionally to leverage padding variants
const layout = createLayout();

export type CardProps = ComponentProps<typeof Card>;

export const Card = styled("div", {
  ...layout,
  border: "1px solid $colors$-neutral20",
  borderRadius: "$radii$default",
  backgroundColor: "$white",
  overflow: "hidden",
  // TODO: Investigate how to avoid bespoke flex-basis
  //       As we use `overflow: hidden` to ensure content is kept within
  //       `border-radius`, this will cause content collapse in `AutoLayout`
  //       (due to the default `flex-basis` being set to `0` for equal spacing)
  [`${AutoLayout} &`]: {
    flexBasis: "auto",
  },
  variants: {
    ...layout.variants,
    corners: {
      rounded: {
        borderRadius: "$radii$default",
      },
      square: {
        borderRadius: 0,
      },
    },
  },
  defaultVariants: {
    ...layout.defaultVariants,
    corners: "rounded",
  },
});
