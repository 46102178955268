import ScrollBooster from "scrollbooster";

class Gantt {
  constructor() {
    this.mount();
  }

  mount() {
    this.today = $(".js-gantt-today")[0];

    // To support sticky headers in the gantt chart the calendar
    // (header) element and the rest of the chart must live in
    // separate scroll areas. This is because if an ancestor
    // of a `postion: sticky;` element has `overflow: scroll` set
    // the sticky positioning will be ignored.
    //
    // This may be helpful for some background:
    // https://uxdesign.cc/position-stuck-96c9f55d9526
    this.calendar = $(".js-gantt-calendar")[0];
    this.rowList = $(".js-gantt-row-list")[0];
    this.rowWindow = $(".js-gantt-row-window")[0];

    if (!this.rowList || !this.rowWindow || !this.calendar) {
      return;
    }

    // Match the width of the two scroll areas
    this.rowList.style.width = this.calendar.scrollWidth + "px";

    // Syncronize scrolling between the two scroll areas
    this.onScrollRow = () => {
      this.calendar.scrollLeft = this.rowWindow.scrollLeft;
    };
    this.onScrollCalendar = () => {
      this.rowWindow.scrollLeft = this.calendar.scrollLeft;
    };
    this.rowWindow.addEventListener("scroll", this.onScrollRow);
    this.calendar.addEventListener("scroll", this.onScrollCalendar);

    this.scrollToToday();
    this.createHistory();
    this.initScrollBooster();
  }

  scrollTo(offsetLeft) {
    this.calendar.scrollLeft = offsetLeft;
    this.rowWindow.scrollLeft = offsetLeft;
  }

  createHistory() {
    if (!this.today || $(".gantt-display__calendar-history").length > 0) {
      return;
    }
    const history = document.createElement("div");
    history.classList.add("gantt-display__calendar-history");
    history.style.width = this.today.offsetLeft + 2 + "px";
    this.rowWindow.appendChild(history);
  }

  scrollToToday() {
    if (!this.today) {
      return;
    }
    this.scrollTo(this.today.offsetLeft - 200);
  }

  initScrollBooster() {
    // Draggable scroll area
    this.rowDrag = new ScrollBooster({
      viewport: this.rowWindow,
      content: this.rowList,
      mode: "x",
      bounce: false,
      onUpdate: (data) => {
        this.scrollTo(data.position.x);
      },
    });
    this.calendarDrag = new ScrollBooster({
      viewport: this.calendar,
      content: this.calendar,
      mode: "x",
      bounce: false,
      onUpdate: (data) => {
        this.scrollTo(data.position.x);
      },
    });
  }

  unmount() {
    if (this.rowDrag) {
      this.rowDrag.destroy();
    }
    if (this.onScrollRow) {
      this.rowWindow.removeEventListener("scroll", this.onScrollRow);
    }
    if (this.onScrollCalendar) {
      this.calendar.removeEventListener("scroll", this.onScrollCalendar);
    }
  }
}

export default Gantt;
