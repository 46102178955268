import Field from "Components/blocks/Field";
import React from "react";

// This is deprecated (but still in use on /reports/Reports.jsx)
// Instead use the date range option of the Field component
export default class DateRange extends React.Component {
  constructor(props) {
    super(props);

    this.defaultMin = "1970-01-01";
    this.defaultMax = "2050-01-01";
  }

  // These functions don't seem to be working,
  // min and max aren't functional.
  maxBeginDate() {
    if (this.props.max && this.props.endDate) {
      return this.props.max < this.props.endDate
        ? this.props.max
        : this.props.endDate;
    }
    return this.props.endDate || this.props.max;
  }

  minEndDate() {
    if (this.props.min && this.props.beginDate) {
      return this.props.min > this.props.beginDate
        ? this.props.min
        : this.props.beginDate;
    }
    return this.props.beginDate || this.props.min;
  }

  render() {
    return (
      <div className="date-range" style={this.props.style}>
        <div className="date-range__begin-date">
          <Field
            type="date"
            size={this.props.size}
            selectedValue={this.props.beginDate}
            label="Begin Date"
            keyName={this.props.snakeCase ? "begin_date" : "beginDate"}
            onChange={this.props.onChange}
            min={this.props.min || this.defaultMin}
            max={this.maxBeginDate()}
          />
        </div>
        <div className="date-range__end-date">
          <Field
            type="date"
            size={this.props.size}
            selectedValue={this.props.endDate}
            label="End Date"
            keyName={this.props.snakeCase ? "end_date" : "endDate"}
            onChange={this.props.onChange}
            min={this.minEndDate()}
            max={this.props.max || this.defaultMax}
          />
        </div>
      </div>
    );
  }
}
