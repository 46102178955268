import React from "react";
import { useFieldState } from "../FieldContext";
import { Search } from "../Search";
import styles from "../field.module.css";

export function SearchInput() {
  const props = useFieldState();

  const {
    name,
    size,
    value,
    required,
    isDisabled,
    options,
    handleChange,
    handleInputChange,
    defaultValue,
    handleCreate,
    handleBlur,
    placeholder,
    isCreateable = false,
    isClearable,
    focused,
    components,
    noSeparator,
  } = props;

  // if (isCreateable)
  //   return (
  //     <CreateableSearch
  //       {...props}
  //       isClearable
  //       className={styles.field__input}
  //       handleChange={handleChange}
  //       handleInputChange={handleInputChange}
  //       handleCreate={handleCreate}
  //       options={options}
  //       placeholder={placeholder}
  //       value={value}
  //       components={components}
  //       size={size}
  //       noSeparator={noSeparator}
  //       focused={focused}
  //       disabled={isDisabled}
  //     />
  //   );

  return (
    <Search
      {...props}
      isClearable
      className={styles.field__input}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
      options={options}
      placeholder={placeholder}
      value={value}
      components={components}
      size={size}
      noSeparator={noSeparator}
      focused={focused}
      disabled={isDisabled}
    />
  );
}

export default React.memo(SearchInput);
