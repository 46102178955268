import { useCallback } from "react";

import { useCurrentAccountQuery } from "../../../../../apps/web/packages/app/generated/graphql";

export const useIsSuperAdmin = (superAdminCallback: () => void) => {
  const { data: userData } = useCurrentAccountQuery();

  const usingSuperAdmin = !!userData?.me?.loggedInAsSomeoneElse;

  useCallback(() => {
    if (!usingSuperAdmin) return;
    superAdminCallback();
  }, [usingSuperAdmin, superAdminCallback]);
};
