import React, { useEffect } from "react";
import { useRecentlyViewedProjects } from "Hooks";
import propTypes from "prop-types";

export const TrackProjectView = ({ profileId, projectId }) => {
  const { projectIds, projectViewed } = useRecentlyViewedProjects({
    profileId,
    projectId,
  });

  useEffect(() => {
    if (projectId && !projectIds.some((p) => `${p.id}` === projectId)) {
      projectViewed(projectId);
    }
  }, []);

  return <></>;
};

TrackProjectView.propTypes = {
  profileId: propTypes.string.isRequired,
  projectId: propTypes.string.isRequired,
};

export default TrackProjectView;
