/* eslint-disable max-classes-per-file */
/* global localStorage */

import util from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import Gantt from "Blocks/gantt";
import { selectMethods } from "Blocks/select";

class SelectOption {
  constructor(params) {
    this.selectClass = params.selectClass;
    this.localStorageKey = params.localStorageKey;
    this.action = params.action;
    this.filterMethod = params.filterMethod;
    [this.node] = $(this.selectClass);
    this.exists = !!this.node;
  }

  preselectValueFromLocalStorage() {
    if (this.localStorageKey) {
      const localStorageValue = localStorage.getItem(this.localStorageKey);
      if (localStorageValue) {
        selectMethods.selectItemByValue(
          this.node.parentNode,
          localStorageValue
        );
      }
    }
  }

  setLocalStorageData() {
    if (this.localStorageKey) {
      localStorage.setItem(this.localStorageKey, this.node.dataset.value);
    }
  }

  value() {
    return this.node.dataset.value;
  }
}

export class ProjectsIndex extends Route {
  initialLoad() {
    bindListenerToDocument(
      "click",
      ["js-filter-gantt", "js-select-reset", "js-sort-gantt"],
      () => {
        this.updateGantt();
      }
    );
  }

  load() {
    this.ganttExists = !!$(".js-gantt-display")[0];
    // Don't fire gantt js if there is no gantt on the page
    if (this.ganttExists) {
      this.gantt = new Gantt();
      this.createProjectData();
      this.createSelectOptions();
      this.selectOptions.forEach((selectOption) =>
        selectOption.preselectValueFromLocalStorage()
      );
      [this.projectList] = $(".js-gantt-project-list");
      [this.projectDisplayList] = $(".js-gantt-row-list");

      // Filter + Sort
      this.updateGantt();
    }
  }

  beforeCache() {
    if (this.ganttExists) {
      this.gantt.unmount();
    }
  }

  createProjectData() {
    this.projects = Array.from($(".js-gantt-project-li")).map(
      (projectListItem) => ({
        activePhaseTypes: JSON.parse(projectListItem.dataset.activePhaseTypes),
        beginDate: projectListItem.dataset.beginDate,
        categories: JSON.parse(projectListItem.dataset.categories),
        clientId: parseInt(projectListItem.dataset.clientId, 10),
        id: projectListItem.dataset.id,
        status: projectListItem.dataset.status,
        isAssigned: projectListItem.dataset.isAssigned,
        listItem: projectListItem,
        name: projectListItem.dataset.name,
        number: projectListItem.dataset.number,
        row: $(
          `.js-gantt-project-row[data-id="${projectListItem.dataset.id}"]`
        )[0],
        show() {
          this.row.classList.add("is-visible");
          this.listItem.classList.add("is-visible");
        },
        hide() {
          this.row.classList.remove("is-visible");
          this.listItem.classList.remove("is-visible");
        },
        setAsTopRow() {
          this.row.classList.add("top-row");
          this.listItem.classList.add("top-row");
        },
        clearTopRow() {
          this.row.classList.remove("top-row");
          this.listItem.classList.remove("top-row");
        },
      })
    );
  }

  createSelectOptions() {
    this.selectOptions = [];
    this.filters = [];
    this.sorter = null;

    const selectOptionsData = [
      {
        selectClass: ".js-sort-select",
        action: "sort",
        localStorageKey: "sortValue",
      },
      {
        selectClass: ".js-status-select",
        action: "filter",
        localStorageKey: "statusFilter",
        filterMethod(project) {
          const status = this.value();
          if (status === "") return true;
          if (project.status !== status) {
            return false;
          }
          return true;
        },
      },
      {
        selectClass: ".js-assigned-select",
        action: "filter",
        localStorageKey: "assignedFilter",
        filterMethod(project) {
          const assignment = this.value();
          if (assignment === "") return true;
          if (project.isAssigned !== assignment) {
            return false;
          }
          return true;
        },
      },
      {
        selectClass: ".js-category-select",
        action: "filter",
        localStorageKey: "assignedCategory",
        filterMethod(project) {
          const category = this.value();
          if (category === "") return true;
          if (project.categories.indexOf(parseInt(category, 10)) === -1) {
            return false;
          }
          return true;
        },
      },
      {
        selectClass: ".js-active-phase-type-select",
        action: "filter",
        localStorageKey: "phaseTypeFilter",
        filterMethod(project) {
          const activePhaseType = this.value();
          if (activePhaseType === "") return true;
          if (
            !project.activePhaseTypes.includes(parseInt(activePhaseType, 10))
          ) {
            return false;
          }
          return true;
        },
      },
      {
        selectClass: ".js-client-select",
        action: "filter",
        localStorageKey: "clientFilter",
        filterMethod(project) {
          const clientId = this.value();
          if (clientId === "" || parseInt(clientId, 10) === project.clientId) {
            return true;
          }

          return false;
        },
      },
    ];

    selectOptionsData.forEach((selectOptionData) => {
      const selectOption = new SelectOption(selectOptionData);
      if (selectOption.exists) {
        this.selectOptions.push(selectOption);

        if (selectOption.action === "filter") {
          this.filters.push(selectOption);
        }
        if (selectOption.action === "sort") {
          this.sorter = selectOption;
        }
      }
    });
  }

  setTopRow() {
    const visibleRow = this.projects.find((project) =>
      project.row.classList.contains("is-visible")
    );
    if (visibleRow !== undefined) {
      visibleRow.setAsTopRow();
    }
  }

  updateGantt() {
    if (this.ganttExists) {
      this.selectOptions.forEach((filter) => filter.setLocalStorageData());
      this.filter();
      this.sort();
      this.setTopRow();
      this.updateDom();
    }
  }

  filter() {
    let count = 0;
    this.projects.forEach((project) => {
      project.clearTopRow();
      if (
        this.filters.every((selectOption) => selectOption.filterMethod(project))
      ) {
        project.show();
        count += 1;
      } else {
        project.hide();
      }
    });
    $(".js-project-count")[0].innerHTML = count;
  }

  sort() {
    const sortValue = this.sorter.value();
    this.projects.sort((a, b) => {
      a.clearTopRow();

      if (sortValue === "alphabetical") {
        return util.spaceship(a.name, b.name);
      }
      if (sortValue === "reverseAlphabetical") {
        return util.spaceship(b.name, a.name);
      }
      if (sortValue === "number") {
        return util.spaceship(a.number, b.number);
      }
      if (sortValue === "reverseNumber") {
        return util.spaceship(b.number, a.number);
      }
      if (sortValue === "beginDate") {
        return util.spaceship(a.beginDate, b.beginDate);
      }
      if (sortValue === "reverseBeginDate") {
        return util.spaceship(b.beginDate, a.beginDate);
      }
      return util.spaceship(a.name, b.name);
    });
  }

  updateDom() {
    this.projectList.innerHTML = "";
    this.projectDisplayList.innerHTML = "";
    this.projects.forEach((project) => {
      this.projectList.appendChild(project.listItem);
      this.projectDisplayList.appendChild(project.row);
    });
  }
}

const projectsIndex = new ProjectsIndex();
export default projectsIndex;
