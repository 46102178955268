import React, { useContext } from "react";

export const ModalContext = React.createContext({});

export function ModalProvider(props) {
  const { children } = props;
  return (
    <ModalContext.Provider value={{ ...props }}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModalState = () => useContext(ModalContext);
