import { ComponentProps, FC } from "react";

import { createComponent, semanticScales, styled } from "@plan/core";

const component = createComponent();

/**
 * Formatter is a styled `span`, use it to wrap formatters.
 * Use prop `negative` to display value in red.
 */
const StyledFormatter = styled("span", {
  ...component,
  variants: {
    ...component.variants,
    negative: {
      true: {
        color: "$$dangerColor",
      },
    },
  },
});

export type FormatterProps = ComponentProps<typeof StyledFormatter> & {
  dangerColor?: keyof typeof semanticScales.danger;
};

export const Formatter: FC<FormatterProps> = ({
  negative,
  dangerColor = "danger10",
  children,
  css,
  ...props
}) => (
  <StyledFormatter
    negative={negative}
    css={{
      ...css,
      ...{
        $$dangerColor: `$colors$${dangerColor}`,
      },
    }}
    {...props}
  >
    {children}
  </StyledFormatter>
);
