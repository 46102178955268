import React from "react";

// This is deprecated for the 'modal/Modal' component
// and related hook.

// I think I should make this into a function
export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  // I don't understand this
  handleClick(event) {
    if (event.target.classList.contains("react-modal")) {
      this.props.closeModal();
    }
  }

  // This allows for components inheriting from the Modal class to use this method for custom renders,
  // while still keeping this modal framework
  renderContent() {
    return false;
  }

  renderButtons() {
    return false;
  }

  renderContentArea() {
    if (this.renderContent() || this.props.content) {
      return (
        <div
          className={`modal-section ${
            this.props.noPadding ? " modal-section--no-padding" : ""
          }`}
        >
          {this.renderContent() ? this.renderContent() : this.props.content}
        </div>
      );
    }
  }

  renderButtonArea() {
    if (this.renderButtons() || this.props.buttons) {
      return (
        <div className="modal-buttons">
          {this.renderButtons() ? this.renderButtons() : this.props.buttons}
        </div>
      );
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="react-modal" onClick={this.handleClick}>
        <div className="react-modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-header__text">
                <div className="modal-header__title">{this.props.title}</div>
              </div>
              <div
                className="modal-header__close"
                onClick={this.props.closeModal}
              />
            </div>
            {this.renderContentArea()}
            {this.renderButtonArea()}
          </div>
        </div>
      </div>
    );
  }
}
