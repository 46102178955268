import { useLocalStorage } from "react-use";

import { TimerEntryFragment } from "../../../../../apps/web/packages/app/generated/graphql";
import { LocalStorageTimer } from "../hooks/useSyncTimerToLocalStorage";

export interface TimerReducerState {
  isTimerModalVisible: boolean;
  lostTimeMs?: number;
  entry?: TimerEntryFragment;
  recordingStartTime?: number;
  lastRecordingAt?: number;
}

type TimerReducerAction =
  | { type: "START" }
  | { type: "STOP" }
  | { type: "CLEAR_ENTRY" }
  | { type: "RESET_TIME" }
  | { type: "RESET_EVERYTHING" }
  | { type: "SHOW_MODAL" }
  | { type: "HIDE_MODAL" }
  | { type: "HIDE_LOST_TIME_WARNING" }
  | { type: "UPDATE_LAST_RECORDING_AT" }
  | { type: "SELECT_ENTRY"; payload: TimerEntryFragment }
  | { type: "SELECT_ENTRY_AND_START"; payload: TimerEntryFragment };

export const useTimerReducerInitialState = () => {
  const [storedLocalStorageTimer] = useLocalStorage<LocalStorageTimer>(
    "timer",
    {}
  );

  const lostTimeMs = storedLocalStorageTimer?.lastRecordingAt
    ? Date.now() - storedLocalStorageTimer?.lastRecordingAt
    : undefined;

  const isRecording = !!storedLocalStorageTimer?.start;
  const entry = storedLocalStorageTimer?.entry;
  const isSomehowRecordingWithoutEntry = isRecording && !entry;

  return {
    isTimerModalVisible: false,
    lostTimeMs,
    entry: storedLocalStorageTimer?.entry,
    recordingStartTime: isSomehowRecordingWithoutEntry
      ? undefined
      : storedLocalStorageTimer?.start,
    lastRecordingAt: isSomehowRecordingWithoutEntry
      ? undefined
      : storedLocalStorageTimer?.lastRecordingAt,
  };
};

export const timerReducer = (
  state: TimerReducerState,
  action: TimerReducerAction
): TimerReducerState => {
  switch (action.type) {
    case "START":
      return {
        ...state,
        recordingStartTime: Date.now(),
        lastRecordingAt: Date.now(),
      };
    case "STOP":
      return {
        ...state,
        recordingStartTime: undefined,
        lastRecordingAt: undefined,
      };
    case "CLEAR_ENTRY":
      return {
        ...state,
        entry: undefined,
        recordingStartTime: undefined,
        lastRecordingAt: undefined,
      };
    case "SELECT_ENTRY":
      return { ...state, entry: action.payload };
    case "SELECT_ENTRY_AND_START":
      return {
        ...state,
        entry: action.payload,
        recordingStartTime: Date.now(),
        lastRecordingAt: Date.now(),
      };
    case "RESET_TIME":
      return {
        ...state,
        recordingStartTime: undefined,
        lastRecordingAt: undefined,
      };
    case "HIDE_MODAL":
      return { ...state, isTimerModalVisible: false };
    case "SHOW_MODAL":
      return { ...state, isTimerModalVisible: true };
    case "HIDE_LOST_TIME_WARNING":
      return { ...state, lostTimeMs: undefined };
    case "UPDATE_LAST_RECORDING_AT":
      return { ...state, lastRecordingAt: Date.now() };
    case "RESET_EVERYTHING":
      return {
        ...state,
        recordingStartTime: undefined,
        lastRecordingAt: undefined,
        entry: undefined,
        isTimerModalVisible: false,
      };
    default:
      return { ...state };
  }
};
