import { createContext, useContext } from "react";

import {
  CreateEntryInput,
  TimerEntryFragment,
  UpdateEntryInput,
} from "../../../../../apps/web/packages/app/generated/graphql";

export interface TimerContextActions {
  startTimer: () => void;
  stopTimer: () => Promise<boolean>;
  toggleTimerRecording: () => void;
  clearTimerEntry: () => void;
  resetTimer: () => void;
  createTimerEntry: (entry: CreateEntryInput) => Promise<boolean>;
  updateTimerEntry: (entry: UpdateEntryInput) => Promise<boolean>;
  selectTimerEntry: (entry: TimerEntryFragment) => void;
  resetTimerTime: () => void;
  toggleTimerModal: () => void;
  acceptLostTime: () => void;
  rejectLostTime: () => void;
  hideTimerModal: () => void;
  showTimerModal: () => void;
}

export interface TimerContextState {
  isTimerModalVisible: boolean;
  isRecording: boolean;
  recordingDisplayTime: string;
  lostTimeTotal?: number;
  entry?: TimerEntryFragment;
}

interface TimerContext {
  state: TimerContextState;
  actions: TimerContextActions;
}

const timerContextInitialState: TimerContextState = {
  isTimerModalVisible: false,
  isRecording: false,
  recordingDisplayTime: "0.00",
};

const timerContextInitialActions: TimerContextActions = {
  startTimer: () => null,
  stopTimer: async () => true,
  toggleTimerRecording: () => null,
  createTimerEntry: async () => true,
  updateTimerEntry: async () => true,
  clearTimerEntry: () => null,
  selectTimerEntry: () => null,
  resetTimerTime: () => null,
  toggleTimerModal: () => null,
  hideTimerModal: () => null,
  showTimerModal: () => null,
  resetTimer: () => null,
  acceptLostTime: () => null,
  rejectLostTime: () => null,
};

export const TimerContext = createContext<TimerContext>({
  state: { ...timerContextInitialState },
  actions: { ...timerContextInitialActions },
});

export const useTimerContext = () => useContext(TimerContext);
