import util from "Blocks/utils/util";
import autosize from "autosize";
import numeral from "numeral";
import React from "react";
import InvoiceAssignmentSelector from "./InvoiceAssignmentSelector";

export default class InvoiceLineItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.deleteLineItem = this.deleteLineItem.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentDidMount() {
    autosize(this.refs.textarea);
  }

  componentDidUpdate() {
    autosize.update(this.refs.textarea);
  }

  handleChange(event) {
    this.props.update({
      ...this.props.lineItem,
      [event.target.name]: event.target.value,
    });
  }

  changeValues(event) {
    const inputName = event.target.name;
    const editableUnitPrice = !(
      this.props.lineItem.phase_id &&
      this.props.lineItem.phase_fee_type === "fixed"
    );
    let { quantity, unit_price, amount } = this.props.lineItem;

    // Format field values on the way in, in case someone entered non-numbers:
    amount = numeral(util.toFloat(amount)).format("0.00");
    unit_price = numeral(unit_price).format("0.00");
    quantity = numeral(quantity).format("0[.][000000000]");

    if (inputName === "quantity") {
      // If we also have unit_price, update amount
      amount = parseFloat(quantity * unit_price);
    } else if (inputName === "unit_price") {
      // If we also have quantity, update amount
      amount = parseFloat(quantity * unit_price);
    } else if (inputName === "amount") {
      // Conditions for field states
      const isQuantityEntered = parseFloat(quantity);
      const isUnitPriceEntered = parseFloat(unit_price);
      const isFixedUnitPrice = !editableUnitPrice;

      // Conditions for below
      const both = isQuantityEntered && isUnitPriceEntered;
      const onlyUnitPrice = !isQuantityEntered && isUnitPriceEntered;
      const onlyQuantity = isQuantityEntered && !isUnitPriceEntered;

      if (isFixedUnitPrice) {
        quantity = parseFloat(amount / unit_price); // If unit_price isn't editable, changing amount affects quantity
      } else if (both) {
        quantity = parseFloat(amount / unit_price); // If we have both quantity and unit_price, changing amount affects quantity
      } else if (onlyUnitPrice) {
        quantity = parseFloat(amount / unit_price); // If we only have unit_price, changing amount affects quantity
      } else if (onlyQuantity) {
        unit_price = parseFloat(amount / quantity); // If we only have quantity, changing amount affects unit_price
      }
    }

    // Format field values:
    amount = numeral(util.toFloat(amount)).format("0.00");
    unit_price = numeral(unit_price).format("0.00");

    this.props.update({
      ...this.props.lineItem,
      ...{ quantity, unit_price, amount },
    });
  }

  deleteLineItem() {
    this.props.delete(this.props.lineItem.id);
  }

  render() {
    const lineItem = this.props.lineItem || {};
    const {
      role_id,
      phase_id,
      is_v1_other_item,
      is_v1_expense,
      consultant_id,
      expense_id,
    } = lineItem;
    if (this.props.editable) {
      return (
        <li
          className={`invoice-item${
            this.props.lineItem.phase_id ? " is-phase" : ""
          }`}
          data-id={this.props.lineItem.id}
        >
          <div className="invoice-item__group">
            <div className="invoice-item__handle js-handle" />
            <div className="invoice-item__input--phase">
              <InvoiceAssignmentSelector
                assignments={this.props.assignments}
                roleId={role_id}
                phaseId={phase_id}
                isOtherItem={is_v1_other_item}
                isExpense={is_v1_expense || expense_id}
                consultantId={consultant_id}
                onChange={(values) =>
                  this.props.update({
                    ...this.props.lineItem,
                    ...values,
                  })
                }
              />
            </div>
            <input
              className="invoice-item__input invoice-item__input--type"
              value={this.props.lineItem.type}
              name="type"
              onChange={this.handleChange}
            />
            <textarea
              className="invoice-item__input invoice-item__input--description"
              defaultValue={this.props.lineItem.description}
              name="description"
              onChange={this.handleChange}
              ref="textarea"
              rows={1}
            />
            <input
              className="invoice-item__input invoice-item__input--quantity"
              value={this.props.lineItem.quantity || ""}
              name="quantity"
              onChange={this.handleChange}
              onBlur={this.changeValues}
            />
            {this.props.lineItem.phase_id &&
            this.props.lineItem.phase_fee_type === "fixed" ? (
              <input
                className="invoice-item__input invoice-item__input--unit-price is-locked"
                value={this.props.lineItem.unit_price || ""}
                name="unit_price"
                readOnly
              />
            ) : (
              <input
                className="invoice-item__input invoice-item__input--unit-price"
                value={this.props.lineItem.unit_price || ""}
                name="unit_price"
                onChange={this.handleChange}
                onBlur={this.changeValues}
              />
            )}
            <input
              className="invoice-item__input invoice-item__input--amount"
              value={this.props.lineItem.amount || ""}
              name="amount"
              onChange={this.handleChange}
              onBlur={this.changeValues}
            />
            <button
              className="invoice-item__delete"
              onClick={this.deleteLineItem}
            />
          </div>
        </li>
      );
    }
    return (
      <li className="invoice-item">
        <div className="invoice-item__input invoice-item__input--type">
          {this.props.lineItem.type}
        </div>
        <div className="invoice-item__input invoice-item__input--description">
          {this.props.lineItem.description}
        </div>
        <div className="invoice-item__input invoice-item__input--quantity">
          {this.props.lineItem.quantity}
        </div>
        <div className="invoice-item__input invoice-item__input--unit-price">
          {util.toCurrencyWithCents(this.props.lineItem.unit_price)}
        </div>
        <div className="invoice-item__input invoice-item__input--amount">
          {util.toCurrencyWithCents(this.props.lineItem.amount)}
        </div>
      </li>
    );
  }
}
