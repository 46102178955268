import React from "react";

const Loading = ({ title, text, children }) => {
  return (
    <>
      <div className="project-gantt__welcome-confetti">
        <div className="confetti-circle" />
        <div className="confetti-cross" />
        <div className="confetti-circle" />
        <div className="confetti-cross" />
        <div className="confetti-circle" />
        <div className="confetti-cross" />
      </div>
      <div className="projects-gantt__welcome">
        {title && <h2 className="project-gantt__welcome-title">{title}</h2>}
        {text && <p>{text}</p>}
        {children}
      </div>
    </>
  );
};

export default Loading;
