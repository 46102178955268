import React, { useState, useMemo } from "react";
import { useApi } from "@hooks";
import { LoadingRing } from "Components/blocks/icons";
import { useDeleteProjectTemplate } from "Components/api/projects";
import { PermissionsProvider } from "Components/blocks/permissions/PermissionsContext";
import { Permissions } from "Components/blocks/permissions/Permissions";
import { eventTracker } from "Blocks/tracker";
import {
  Table,
  Card,
  TableButton,
  FlexWrapper,
  Modal,
  useModal,
  Message,
  ActionButtons,
  Badge,
  Currency,
  Tooltip,
  useTooltip,
  Button,
} from "@plan";
import styles from "Components/blocks/buttons/buttons.module";

const showTemplateRoute = (slug) => `/projects/templates/${slug}`;

const DeleteTemplateConfirmation = ({
  name,
  slug,
  handleDelete,
  actions,
  isOpen,
  loading,
}) => (
  <>
    <Modal
      isOpen={isOpen}
      actions={actions}
      size="medium"
      title={`Delete Template: ${name}`}
    >
      <Message
        message="Warning: this action cannot be undone"
        variant="warning"
      />
      <ActionButtons
        buttons={[
          {
            color: "cancel",
            text: "Cancel",
            action: actions.close,
          },
          {
            color: "delete",
            buttonType: "submit",
            text: "Delete",
            disabled: loading,
            action: () => handleDelete(slug, actions.close),
          },
        ]}
      />
    </Modal>
  </>
);

const LoadingState = () => (
  <Card>
    <FlexWrapper center>
      <LoadingRing />
    </FlexWrapper>
  </Card>
);

const InfoOutlined = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="gray"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: "4px" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM13 12.5C13 12.2239 12.7761 12 12.5 12H11.5C11.2239 12 11 12.2239 11 12.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V12.5ZM12.5 8C12.7761 8 13 8.22386 13 8.5V9.5C13 9.77614 12.7761 10 12.5 10H11.5C11.2239 10 11 9.77614 11 9.5V8.5C11 8.22386 11.2239 8 11.5 8H12.5Z"
    />
  </svg>
);

function SaveColumn({ action, template }) {
  return (
    <>
      <TableButton text="Save as Project" action={() => action(template.id)} />
      <span style={{ marginRight: "16px" }} />
    </>
  );
}

export function Templates({ colors = [] }) {
  const [res, loading, error, _cp, _p, refetchData] = useApi(
    "/api/v1/projects/templates"
  );

  const [deleteRequest, deleteProject] = useDeleteProjectTemplate({
    renderErrors: true,
  });

  // Manage the delete modal state
  const [isDeleteOpen, deleteModalActions] = useModal(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({});

  // onClick to pass the modal the correct template info and open modal
  const confirmDelete = ({ name, slug }) => {
    setDeleteConfirmation({
      name,
      slug,
    });
    deleteModalActions.open();
  };

  // Delete the template and revalidate the data
  const handleDelete = async (slug) => {
    try {
      await deleteProject(slug);
      eventTracker("trackProjectTemplateDelete");
      await refetchData();
      return deleteModalActions.close();
    } catch (err) {
      throw new Error("Error deleting template");
    }
  };

  function handleSaveAsProject(templateId) {
    window.location.href = `/projects/create?templateId=${templateId}`;
    return false;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Number",
        accessor: "number",
        Cell: ({ cell, row }) => (
          <Badge color={row.original.color}>{cell.value}</Badge>
        ),
      },
      {
        Header: "Template Name",
        accessor: "name",
        Cell: ({ cell, row }) => (
          <a href={showTemplateRoute(row.original.slug)}>
            <strong>{cell.value}</strong>
          </a>
        ),
      },
      {
        Header: "Budget",
        accessor: "budget",
        Cell: ({ cell }) => <Currency format="withCents" value={cell.value} />,
      },
      {
        Header: "Total Fee",
        accessor: "totalFee",
        Cell: ({ cell }) => <Currency format="withCents" value={cell.value} />,
      },
      {
        Header: "",
        id: "actions",
        headerClass: "page-table__title page-table__cell--align-right",
        Cell: ({ row }) => (
          <FlexWrapper style={{ justifyContent: "flex-end" }}>
            <Permissions needsPermissions={["canEditAllProjects"]}>
              <TableButton
                text="Edit"
                action={() => {
                  window.location.href = `/projects/templates/${row.original.slug}/edit/planner`;
                  return false;
                }}
              />
            </Permissions>
            <Permissions needsPermissions={["canDeleteAllProjects"]}>
              <TableButton
                text="Delete"
                action={() =>
                  confirmDelete({
                    name: row.original.name,
                    slug: row.original.slug,
                  })
                }
              />
            </Permissions>
            <Permissions needsPermissions={["canCreateAllProjects"]}>
              <SaveColumn
                action={(id) => handleSaveAsProject(id)}
                template={row.original}
              />
            </Permissions>
          </FlexWrapper>
        ),
      },
    ],
    []
  );

  return (
    <PermissionsProvider>
      {loading === "fetching" && <LoadingState />}
      {res?.data && (
        <>
          <Table
            columns={columns}
            data={res.data}
            as="sticky"
            emptyState="No Templates Created"
            hideFooter
          />
          <DeleteTemplateConfirmation
            actions={deleteModalActions}
            isOpen={isDeleteOpen}
            name={deleteConfirmation?.name}
            slug={deleteConfirmation?.slug}
            handleDelete={handleDelete}
            loading={deleteRequest?.loading}
          />
        </>
      )}
    </PermissionsProvider>
  );
}

export default Templates;
