import * as React from "react";

import { createComponent, styled } from "@plan/core";
import { IconMoreHorizontal } from "@plan/icons";

import * as PopoverBase from "@radix-ui/react-popover";

const component = createComponent();

const PopoverButton = styled("button", {
  ...component,
  background: "$white",
  cursor: "pointer",
  border: "1px solid $colors$-neutral30",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "$radii$default",
  height: "$sizes$lg",
  width: "$sizes$lg",
  "& path": {
    fill: "$colors$-neutral10",
  },
  '&:focus, &[data-state="open"]': {
    boxShadow: "$focus",
  },
  '&:hover, &[data-state="open"]': {
    border: "1px solid $colors$-neutral10",
    "& path": {
      fill: "$colors$neutral10",
    },
  },
});

type PopoverTriggerProps = React.ComponentProps<typeof PopoverButton>;

export const PopoverTrigger = ({ children, ...props }: PopoverTriggerProps) => (
  <PopoverBase.Trigger asChild>
    {children || (
      <PopoverButton {...props}>
        <IconMoreHorizontal label="More" color="neutral" />
      </PopoverButton>
    )}
  </PopoverBase.Trigger>
);
