import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Loading from "Components/blocks/Loading";
import EmptyState from "Components/blocks/EmptyState";
import Table from "Components/blocks/Table/Table";
import WeekPickerBar from "Components/blocks/WeekPicker/WeekPickerBar";
import WeekPicker from "Components/blocks/WeekPicker/WeekPicker";
import Avatar from "Components/blocks/avatar/Avatar";
import TimesheetStatus from "Components/timesheets/TimesheetStatus";
import TableActions from "Components/timesheets/TableActions";
import TableButton from "Components/blocks/buttons/TableButton";
import ToggleLock from "Components/timesheets/ToggleLock";
import PermissionCheck from "Components/blocks/PermissionCheck";
import DateDisplay from "Components/blocks/DateDisplay";
import { useApi } from "Hooks";
import orderBy from "lodash/orderBy";
import { eventTracker } from "Blocks/tracker";

function OrganizationTable({ selectedWeek }) {
  const [date, setDate] = useState(moment(selectedWeek));

  // Request the data
  const [data, loading, error, changeWeek] = useApi(`/timesheets.json/`, {
    date: date.format("YYYY-MM-DD"),
  });

  const updateQueryParamAjeraExport = (date) => {
    const qbd = document.querySelector(".js-ajera-export");
    if (qbd) {
      const url = new URL(qbd.href);
      url.searchParams.set("begin_date", date);
      qbd.href = url.href;
    }
  };

  // When the date changes, update the URL and request the data
  useEffect(() => {
    const dateParam = date.format("YYYY-MM-DD");
    const url = `${location.pathname}?date=${dateParam}`;
    history.pushState({ turbolinks: true, url }, "", url);
    changeWeek({ date: dateParam });
    updateQueryParamAjeraExport(dateParam);
  }, [date]);

  if (error) return <EmptyState title="Error Fetching Data" />;
  if (loading === "fetching") return <Loading title="Loading..." />;

  const handleEditButton = (timesheet) => {
    Turbolinks.visit(
      `/timesheets/${timesheet.profile.slug}/${timesheet.slug}/edit`
    );
  };

  const handleViewButton = (timesheet) => {
    eventTracker("trackTimesheetOrganizationSelect");
    Turbolinks.visit(`/timesheets/${timesheet.profile.slug}/${timesheet.slug}`);
  };

  const columns = [
    {
      Header: "Status",
      accessor: "is_submitted",
      Cell: ({ cell }) => <TimesheetStatus isSubmitted={cell.value} />,
      sortType: "basic",
    },
    {
      Header: "Hours",
      accessor: "total_hours",
    },
    {
      Header: "Avatar",
      accessor: "profile.image",
      Cell: ({ cell }) => <Avatar image={cell.value} size="small" />,
      disableSortBy: true,
    },
    {
      Header: "First Name",
      accessor: "profile.fname",
      Cell: (cell) => {
        const timesheet = cell.data[cell.row.index];
        return (
          <PermissionCheck
            permission={timesheet.permissions.can_view}
            value={timesheet.profile.fname}
          >
            <a href={`/timesheets/${timesheet.profile.slug}`}>
              {timesheet.profile.fname}
            </a>
          </PermissionCheck>
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "profile.lname",
      Cell: (cell) => {
        const timesheet = cell.data[cell.row.index];
        return (
          <PermissionCheck
            permission={timesheet.permissions.can_view}
            value={timesheet.profile.lname}
          >
            <a href={`/timesheets/${timesheet.profile.slug}`}>
              {timesheet.profile.lname}
            </a>
          </PermissionCheck>
        );
      },
    },
    {
      Header: "Last Edited",
      accessor: "updated_at",
      Cell: ({ cell }) => <DateDisplay date={cell.value} withTime />,
    },
    {
      Header: "Actions",
      accessor: "slug",
      Cell: (cell) => {
        const timesheet = cell.data[cell.row.index];

        return (
          <TableActions>
            <PermissionCheck permission={timesheet.permissions.can_view}>
              <TableButton
                text="View"
                action={() => handleViewButton(timesheet)}
              />
            </PermissionCheck>
            <PermissionCheck permission={timesheet.permissions.can_lock}>
              <ToggleLock timesheet={timesheet} />
            </PermissionCheck>
          </TableActions>
        );
      },
      disableSortBy: true,
    },
  ];

  const tableData = orderBy(data.data, [(row) => row.profile.lname], ["asc"]);

  return (
    <>
      <div className="header-controls">
        <WeekPickerBar>
          <WeekPicker date={date} setDate={setDate} />
        </WeekPickerBar>
      </div>
      <div className="page">
        <Table
          as="sticky"
          data={tableData}
          columns={columns}
          emptyState="No Timesheets for this Week"
          loading={loading}
          hideFooter
        />
      </div>
    </>
  );
}
OrganizationTable.propTypes = {
  selectedWeek: PropTypes.string,
};

export default OrganizationTable;
