import PropTypes from "prop-types";
import { usePermissions } from "./PermissionsContext";

// The permissions component is intended for use with the Permission context
// isAllowed can be used separate from the context

// hasPermissions comes in as an object: { permission: true, persmission2: false }
// isAdmin is in the permissions request that can be pulled from PermissionsContext
export const isAllowed = (hasPermissions, needsPermissions = [], isAdmin) => {
  if (isAdmin) return true;
  const permissionComparison = needsPermissions.map(
    (permission) => hasPermissions[permission]
  );
  return permissionComparison.every((item) => item === true);
};

// Hook that returns on a boolean (does the user have permissions or not)
// This relies on the permissions context
export function useIsAllowed(needsPermissions = []) {
  const { permissions, isAdmin } = usePermissions();
  return isAllowed(permissions, needsPermissions, isAdmin);
}

// Component to control rendering of children based on permissions
export function Permissions({
  children,
  needsPermissions = [],
  restrictedView = null,
}) {
  const { permissions, isAdmin } = usePermissions();
  // If the user has permissions return the child components
  if (isAllowed(permissions, needsPermissions, isAdmin)) return children;
  // If the user doesn't have permissions we pass an optional alternate component
  // by default it will just return nothing (null)
  return restrictedView;
}

Permissions.propTypes = {
  needsPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  restrictedView: PropTypes.oneOf([PropTypes.element, null]),
};

export default Permissions;
