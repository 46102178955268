import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { useHover } from "Hooks";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import cx from "classnames";
import { Increment } from "./Increment";
import styles from "./allocation.module.css";

export const NoAllocation = () => (
  <div className={styles.inc}>
    <svg
      className={styles.allocation}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        stroke="var(--color-red)"
        strokeWidth="2"
      />
      <rect x="16" y="19" width="8" height="2" fill="var(--color-red)" />
    </svg>
  </div>
);

export const IncAnimation = ({ isOpen, children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={isOpen ? "open" : "closed"}
    variants={{
      open: { opacity: 1, scale: 1 },
      closed: { opacity: 0, scale: 0.5 },
    }}
    transition={{ duration: 0.15 }}
    className={styles.increment}
  >
    {children}
  </motion.div>
);

export const AllocationWheel = ({
  circle,
  progress,
  circumference,
  offset,
  size,
  overage,
}) => (
  <svg
    className={cx(styles.allocation, {
      [styles.overage]: overage,
    })}
    fill="none"
    viewBox="0 0 40 40"
  >
    <circle cx="20" cy="20" r="19" strokeWidth="2" className={styles.total} />
    <circle
      cx="20"
      cy="20"
      r="19"
      ref={circle}
      strokeWidth="2"
      className={styles.progress}
      style={{
        opacity: progress === 0 ? 0 : 1,
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: offset || 0,
      }}
    />
  </svg>
);

export const Allocation = ({
  total,
  setTotal,
  removeFn,
  progress,
  step,
  editable,
  variant,
  size,
  overage,
}) => {
  if (total === null || total === false) return <NoAllocation />;

  const circle = useRef(null);
  const [circumference, setCircumference] = useState(0);
  const [offset, setOffset] = useState(0);

  const [isFocused, setFocus] = useState(false);
  const [isIncrementOpen, setIncrementOpen] = useState(false);
  const [hoverRef, isContainerHovered] = useHover();

  // Sets the base measurements for the circle
  useLayoutEffect(() => {
    if (circle?.current) {
      const radius = circle.current.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      setCircumference(circumference);
    }
  }, [circle]);

  // Sets the progress bar offset within the outer circle
  useLayoutEffect(() => {
    if (circle?.current) {
      const offset = circumference - (progress / total) * circumference;
      if (offset < 0) return setOffset(0);
      setOffset(offset);
    }
  }, [circle, circumference, progress, total]);

  // Handles whether the increment is open
  useEffect(() => {
    if (!editable) return setIncrementOpen(false);
    if (isFocused || isContainerHovered) return setIncrementOpen(true);
    setIncrementOpen(false);
  }, [isContainerHovered, isFocused]);

  return (
    <div
      className={cx(
        styles.inc,
        { [styles[variant]]: variant },
        { [styles[size]]: size }
      )}
      ref={hoverRef}
    >
      {editable && (
        <IncAnimation isOpen={isIncrementOpen}>
          <Increment
            total={total}
            setFocus={setFocus}
            setTotal={setTotal}
            step={step}
            removeFn={removeFn}
            min={0}
          />
        </IncAnimation>
      )}
      <div
        style={{ visibility: isIncrementOpen ? "hidden" : "visible" }}
        className={styles.amount}
      >
        {total}
      </div>
      <AllocationWheel
        circle={circle}
        progress={progress}
        circumference={circumference}
        offset={offset}
        overage={overage}
      />
    </div>
  );
};

Allocation.propTypes = {
  overage: PropTypes.bool,
  variant: PropTypes.oneOf(["fill", null]),
  // progress: PropTypes.oneOf([PropTypes.number, null]).isRequired,
  // total: PropTypes.oneOf([PropTypes.number, null]).isRequired,
  // step: PropTypes.oneOf([PropTypes.number]),
  editable: PropTypes.bool,
  setTotal: PropTypes.func,
  removeFn: PropTypes.func,
};

Allocation.defaultProps = {
  overage: false,
  variant: null,
  progress: 0,
  total: 0,
  step: 1,
  editable: false,
  size: "medium",
  setTotal: () => console.log("Set Total"),
  removeFn: () => console.log("Removed"),
};

export default React.memo(Allocation);
