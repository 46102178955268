import React from "react";
import makeWeek from "Blocks/utils/makeWeek";
import Entry from "Components/timesheets/timesheet/Entry";
import { useGlobalState } from "./state/GlobalContext.jsx";
import styles from "./timesheet.module.css";

function Entries({
  groupId,
  rowId,
  isApproved,
  blockName,
  canEdit,
  projectAndPhaseAndActivityId,
  hasActivity,
  activityRequired,
  noteExample,
  notesRequired,
  projectId,
  isOverhead = false,
}) {
  const { state } = useGlobalState();

  // Change to use makeWeekDates
  const daysOfWeek = makeWeek(state.date);

  return (
    <>
      <div
        className={
          isApproved
            ? `${styles.entries}`
            : `${styles.entries} ${styles.entriesIsRejected}`
        }
        style={
          isApproved
            ? {}
            : {
                "--project-color": "#EA4157",
                "--project-color-rgb": "250, 207, 213, 1",
              }
        }
      >
        {daysOfWeek.map((day) => {
          const uniqueId = `${rowId}-${day.date}`;

          return (
            <Entry
              key={uniqueId}
              blockName={blockName}
              groupId={groupId}
              rowId={rowId}
              date={day.date}
              canEdit={canEdit}
              projectAndPhaseAndActivityId={projectAndPhaseAndActivityId}
              hasActivity={hasActivity}
              activityRequired={activityRequired}
              noteExample={noteExample}
              notesRequired={notesRequired}
              projectId={projectId}
              isOverhead={isOverhead}
            />
          );
        })}
      </div>
    </>
  );
}

export default Entries;
