import { createContext, useContext } from "react";

export type TimerModalBodyView = "list" | "form";

interface TimerModalViewContext {
  timerModalView: TimerModalBodyView;
  showEntryList: () => void;
  showEntryForm: () => void;
}

export const TimerModalViewContext = createContext<TimerModalViewContext>({
  timerModalView: "list",
  showEntryList: () => null,
  showEntryForm: () => null,
});

export const useTimerModalViewContext = () => useContext(TimerModalViewContext);
