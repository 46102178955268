import SelectList from "Components/blocks/SelectList";
import React from "react";

// This is deprecated, use the select option in the /forms/Field component

// Props:
// selectedValue: "selectedValue",
// size: "small" || "large",
// reset: true,
// disabled: false,
// onSelect: fn(),
// items: [{value: 1, title: "option 1"}...],
// type: "????",
// required: false,
// requiredError: "This field is required"
// keyName: "keyName"
// showCurrentValue: scrolls to current value

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.selectValue = this.selectValue.bind(this);
    this.deselect = this.deselect.bind(this);
    this.rootRef = React.createRef();

    this.state = {
      isOpen: false,
    };

    this.multiselect = Array.isArray(this.props.selectedValue);
  }

  handleOpenWithCurrentValue = () => {
    if (
      this.props.showCurrentValue &&
      this.rootRef.current &&
      this.props.selectedValue
    ) {
      const selectedList = this.rootRef.current.querySelector(".select__list");
      const selectedItem = this.rootRef.current.querySelector(".is-selected");
      if (selectedList) {
        selectedList.scrollTo(selectedItem);
      }
    }
  };

  open() {
    if (!this.props.disabled) {
      this.setState({ isOpen: true }, this.handleOpenWithCurrentValue);
    }
  }

  close() {
    this.setState({ isOpen: false });
  }

  selectValue(value) {
    if (this.props.keyName) {
      if (this.multiselect) {
        this.props.onSelect({
          [this.props.keyName]: this.props.selectedValue.concat(value),
        });
      } else {
        this.props.onSelect({
          [this.props.keyName]: value,
        });
      }
    } else {
      this.props.onSelect(value);
    }
  }

  deselect(value) {
    if (this.props.keyName) {
      if (this.multiselect) {
        const newSelectedValues = this.props.selectedValue.filter(
          (propValue) => propValue !== value
        );
        this.props.onSelect({
          [this.props.keyName]: newSelectedValues,
        });
      } else {
        this.props.onSelect({
          [this.props.keyName]: null,
        });
      }
    } else {
      this.selectValue(null);
    }
  }

  resetValue(event) {
    // Stop propagation to prevent the click from propogating to the parent div
    // and firing open.
    event.stopPropagation();

    if (this.multiselect) {
      this.selectValue([]);
    } else {
      this.selectValue(null);
    }
  }

  hasValue() {
    if (this.multiselect) {
      return this.props.selectedValue.length > 0;
    }
    return this.props.selectedValue !== null;
  }

  selectedOption() {
    return this.props.items.find(
      (option) => option.value === this.props.selectedValue
    );
  }

  renderReset() {
    if (this.props.reset === true) {
      return (
        <div
          className="select__reset"
          onClick={(event) => this.resetValue(event)}
        />
      );
    }
  }

  renderArrows() {
    if (!this.props.disabled) {
      return (
        <div className="select__arrows">
          <span className="select__arrow select__arrow--top" />
          <span className="select__arrow select__arrow--bottom" />
        </div>
      );
    }
  }

  renderErrors() {
    if (this.props.required) {
      return (
        <div className="select__error">
          {this.props.requiredError || "This field is required"}
        </div>
      );
    }
  }

  renderSelectedValue() {
    if (this.multiselect) {
      return this.props.label;
    }
    if (this.selectedOption() && this.selectedOption().name) {
      return this.selectedOption().name;
    }
    return this.props.selectedValue;
  }

  // I think at some point we can get rid of the .select__data div, when I'm
  // sure that we aren't accessing the react code anywhere else.
  // Tab index is necessary for onBlur
  render() {
    return (
      <>
        <div
          className={`select select--${this.props.size || "large"}${
            this.props.type ? ` select--${this.props.type}` : ""
          }${this.props.disabled ? " is-disabled" : ""}${
            this.props.reset ? " select--reset" : ""
          }${this.state.isOpen ? " is-open" : ""}${
            this.hasValue() ? " has-value" : ""
          }${this.multiselect ? " is-multiselect" : ""}`}
          onClick={this.open}
          onBlur={this.close}
          tabIndex={0}
          ref={this.rootRef}
        >
          <div className="select__label">{this.props.label}</div>
          {this.renderReset()}
          <div className="select__data" data-value={this.props.selectedValue}>
            {this.renderSelectedValue()}
          </div>
          <SelectList
            onSelect={this.selectValue}
            deselect={this.deselect}
            selectedValue={this.props.selectedValue}
            items={this.props.items}
            close={this.close}
          />
          {this.renderArrows()}
        </div>
        {this.renderErrors()}
      </>
    );
  }
}
