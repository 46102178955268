import React from "react";
import TimeOffRequestDaysEntries from "./TimeOffRequestDaysEntries";
import RowTotals from "../RowTotals";
import styles from "../overhead/overhead.module.css";

function TimeOffRequestRow({ timeOffRequest }) {
  const totalHours = timeOffRequest.requestDays.reduce(
    (sum, requestDay) => sum + parseFloat(requestDay.hours),
    0
  );

  return (
    <>
      <TimeOffRequestDaysEntries timeOffRequest={timeOffRequest} />
      <RowTotals hours={totalHours} showExpense={false} />
      <div className={styles.rowViewDeleteColumn} />
    </>
  );
}

export default TimeOffRequestRow;
