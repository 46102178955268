/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconChevronDownProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconChevronDown = ({ label, ...props }: SvgIconChevronDownProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        d="M12.72 15.78a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22l-5.13-5.14a.5.5 0 0 1 0-.71l.71-.71a.49.49 0 0 1 .7 0L12 13.67l4.44-4.45a.5.5 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71l-5.13 5.14Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconChevronDown;
