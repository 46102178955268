import { makeRequest } from "./request";

// PHASES

// Create Phase
// data {
//    phase_template_id: ID,
//    project_id: ID
//    abbreviation: String (optional, if no phase_template_Id)
//    fee_type: String (optional, if no phase_template_id)
//  }
export const createPhase = async (data) =>
  makeRequest({
    method: "post",
    url: "/phases",
    data: {
      phase: data,
    },
  });

// Delete Warnings
// /phases/:id/delete_warnings
export const deletePhaseWarnings = async (id) =>
  makeRequest({
    method: "get",
    url: `/phases/${id}/delete_warnings`,
  });

// Delete Phase
// No data, instead pass an ID in the URL
export const deletePhase = async (id) =>
  makeRequest({
    method: "delete",
    url: `/phases/${id}`,
  });

// Create Phase and Phase Template
// data {
// phase_template_id: ID,
// project_id: ID
// }
export const createPhaseTemplateWithPhase = async (data) => {
  return makeRequest({
    method: "post",
    url: "/phase_templates/create_with_phase",
    data,
  });
};

// Create Phase and Phase Template
// data {
// name
// abbreviation
// fee_type
// }
export const createPhaseTemplate = async (data) =>
  makeRequest({
    method: "post",
    url: "/phase_templates",
    data,
    // We use this route in legacy JS in settings to return a partial
    // eventually we will no longer need this
    params: { partial: false },
  });

// Update a Phase
// data {

// }
export const updatePhase = async (id, data) =>
  makeRequest({
    method: "put",
    url: `/phases/${id}`,
    data,
  });

// Update Phase Order within a project
// data = { position: event.newIndex + 1 };
export const updatePhaseOrder = async (id, data) =>
  makeRequest({
    method: "put",
    url: `/phases/${id}/update_position`,
    data,
  });
