import React from "react";

export function Drag() {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="1.5" r="1.5" fill="#DFE2E4" />
      <circle cx="6.5" cy="5.5" r="1.5" fill="#DFE2E4" />
      <circle cx="6.5" cy="9.5" r="1.5" fill="#DFE2E4" />
      <circle cx="1.5" cy="1.5" r="1.5" fill="#DFE2E4" />
      <circle cx="1.5" cy="5.5" r="1.5" fill="#DFE2E4" />
      <circle cx="1.5" cy="9.5" r="1.5" fill="#DFE2E4" />
    </svg>
  );
}

export default React.memo(Drag);
