import React from "react";

export const AlertIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.166504 5.99984C0.166504 2.77818 2.77818 0.166504 5.99984 0.166504C7.54693 0.166504 9.03066 0.781085 10.1246 1.87505C11.2186 2.96901 11.8332 4.45274 11.8332 5.99984C11.8332 9.2215 9.2215 11.8332 5.99984 11.8332C2.77818 11.8332 0.166504 9.2215 0.166504 5.99984ZM1.33317 5.99984C1.33317 8.57717 3.42251 10.6665 5.99984 10.6665C7.23751 10.6665 8.4245 10.1748 9.29967 9.29967C10.1748 8.4245 10.6665 7.23751 10.6665 5.99984C10.6665 3.42251 8.57717 1.33317 5.99984 1.33317C3.42251 1.33317 1.33317 3.42251 1.33317 5.99984ZM5.70817 7.1665C5.54709 7.1665 5.4165 7.29709 5.4165 7.45817V8.0415C5.4165 8.20259 5.54709 8.33317 5.70817 8.33317H6.2915C6.45259 8.33317 6.58317 8.20259 6.58317 8.0415V7.45817C6.58317 7.29709 6.45259 7.1665 6.2915 7.1665H5.70817ZM5.60317 3.6665H6.3965C6.48032 3.6659 6.56035 3.70138 6.61618 3.76391C6.672 3.82643 6.69823 3.90996 6.68817 3.99317L6.45484 5.8715C6.44594 5.94514 6.38318 6.00037 6.309 5.99984H5.69067C5.6165 6.00037 5.55374 5.94514 5.54484 5.8715L5.3115 3.99317C5.30144 3.90996 5.32767 3.82643 5.3835 3.76391C5.43932 3.70138 5.51935 3.6659 5.60317 3.6665Z"
      fill="#E04058"
    />
  </svg>
);

export default AlertIcon;
