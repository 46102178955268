import { FC, useState } from "react";

import { styled } from "@plan/core";

import { useTimerContext } from "../../contexts/TimerContext";
import { useTimerModalViewContext } from "../../contexts/TimerModalViewContext";

import { ConfirmTimerReset } from "./ConfirmTimerReset";

const TimerNav = styled("nav", {
  display: "flex",
  borderBottom: "1px solid $colors$-neutral20",
  marginBottom: "$space$4",
});

const TimerNavButton = styled("button", {
  padding: "$space$4 $space$4 $space$3",
  color: "$colors$brand",
  fontSize: "$fontSizes$md",
  fontWeight: "$normal",
  lineHeight: "1",
  backgroundColor: "transparent",
  border: "none",
  "&:hover": {
    color: "$black",
    cursor: "pointer",
  },
  variants: {
    type: {
      reset: {
        marginLeft: "auto",
      },
    },
  },
});

const BackButton: FC = () => {
  const {
    state: { isRecording },
    actions: { clearTimerEntry },
  } = useTimerContext();
  const { showEntryList } = useTimerModalViewContext();

  if (isRecording) return <></>;

  const handleButtonClick = () => {
    showEntryList();
    clearTimerEntry();
  };

  return (
    <TimerNavButton onClick={handleButtonClick}>
      ‹ Recent entries
    </TimerNavButton>
  );
};

interface ResetButtonProps {
  showResetConfirmation: () => void;
}

const ResetButton: FC<ResetButtonProps> = ({ showResetConfirmation }) => {
  return (
    <TimerNavButton type={"reset"} onClick={showResetConfirmation}>
      Reset
    </TimerNavButton>
  );
};

export const TimerModalNav = () => {
  const [resetConfirmationVisible, setResetConfirmationVisible] =
    useState(false);
  const { timerModalView } = useTimerModalViewContext();

  if (timerModalView === "list") return <></>;

  return (
    <TimerNav>
      {resetConfirmationVisible && (
        <ConfirmTimerReset
          hideResetConfirmation={() => setResetConfirmationVisible(false)}
        />
      )}
      <BackButton />
      <ResetButton
        showResetConfirmation={() => setResetConfirmationVisible(true)}
      />
    </TimerNav>
  );
};
