import { styled } from "@plan/core";
import { Box } from "@plan/layout";
import { formatDateDisplay } from "@plan/formatters";

import { useTimerContext } from "../../contexts/TimerContext";
import { useTimerModalViewContext } from "../../contexts/TimerModalViewContext";

import { TimerButton } from "./styles/TimerButton";
import { TimerButtonCircle } from "./styles/TimerButtonCircle";
import { TimerButtonCircleGraphic } from "./styles/TimerButtonCircleGraphic";
import { TimerButtonContent } from "./styles/TimerButtonContent";

const TimerHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  padding: "0 $space$4",
});

const TimerHeaderContentSubHeader = styled("div", {
  position: "relative",
  color: "$colors$-neutral10",
  fontSize: "$fontSizes$sm",
  fontWeight: "$normal",
  letterSpacing: "0.025rem",
  lineHeight: "1",
  textTransform: "uppercase",
  margin: "0 0 $space$2",
});

const TimerHeaderTime = styled("h2", {
  position: "relative",
  margin: "0 0 0 auto",
  fontSize: "$space$8",
  lineHeight: "1",
});

const CreateEntryHeader = styled("h2", {
  fontSize: "$space$6",
  fontWeight: "$semibold",
});

const TimerHeaderTimeUnit = styled("span", {
  fontSize: "$fontSizes$lg",
  fontWeight: "$normal",
  lineHeight: "1",
  letterSpacing: "0.0375rem",
  textTransform: "uppercase",
  marginLeft: "-0.325rem",
});

const NoEntryHeader = () => {
  return (
    <Box css={{ padding: "20px $space$4 0" }}>
      <CreateEntryHeader>Time Entry</CreateEntryHeader>
    </Box>
  );
};

export const TimerModalHeader = () => {
  const todayDate = formatDateDisplay(new Date(), "weekdayInMonth") as string;
  const {
    state: { isRecording, entry, recordingDisplayTime },
    actions: { toggleTimerRecording },
  } = useTimerContext();
  const { timerModalView } = useTimerModalViewContext();

  if (timerModalView === "list") return <></>;
  if (!entry) return <NoEntryHeader />;

  return (
    <TimerHeader>
      <TimerButton large onClick={toggleTimerRecording}>
        <TimerButtonCircle isActive={isRecording}>
          <TimerButtonCircleGraphic isActive={isRecording} large />
        </TimerButtonCircle>
        <TimerButtonContent large isActive={isRecording} />
      </TimerButton>
      <div>
        <TimerHeaderContentSubHeader>{todayDate}</TimerHeaderContentSubHeader>
        <TimerHeaderTime>
          {recordingDisplayTime} <TimerHeaderTimeUnit>hrs</TimerHeaderTimeUnit>
        </TimerHeaderTime>
      </div>
    </TimerHeader>
  );
};
