import React from "react";
import { useFieldState } from "../FieldContext";
import { Select, CreateableSelect } from "../Select";
import styles from "../field.module.css";

export function SelectInput() {
  const props = useFieldState();

  const {
    name,
    size,
    value,
    required,
    isDisabled,
    options,
    handleChange,
    handleInputChange,
    defaultValue,
    handleCreate,
    handleBlur,
    placeholder,
    isCreateable = false,
    formatOptionLabel,
    isClearable,
    focused,
    components,
    noSeparator,
  } = props;

  if (isCreateable)
    return (
      <CreateableSelect
        isClearable={isClearable}
        className={styles.field__input}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        handleCreate={handleCreate}
        options={options}
        placeholder={placeholder}
        value={value}
        components={components}
        formatOptionLabel={formatOptionLabel}
        size={size}
        noSeparator={noSeparator}
        focused={focused}
        isDisabled={isDisabled}
      />
    );

  return (
    <Select
      className={styles.field__input}
      name={name}
      options={options}
      value={value}
      size={size}
      required={required}
      tabIndex={isDisabled ? -1 : 0}
      isDisabled={isDisabled}
      handleChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      onBlur={handleBlur}
      placeholder={placeholder}
      defaultValue={defaultValue}
      isClearable={isClearable}
      components={components}
      noSeparator={noSeparator}
      focused={focused}
      {...props}
    />
  );
}

export default React.memo(SelectInput);
