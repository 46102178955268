import React from "react";
import Portal from "Components/blocks/portal/Portal";

import { useTimerContext } from "@plan/timer";

const logout = async () => {
  await fetch(`/logout`, {
    method: "DELETE",
    redirect: "manual",
    headers: {
      "Content-Type": "text/html",
      Accept: "text/html",
    },
  });

  document.location = "/login";
};

const LogoutLink = () => {
  const {
    actions: { stopTimer, resetTimer },
    state: { isRecording },
  } = useTimerContext();

  const handleLogoutLink = async () => {
    if (!isRecording) {
      return logout();
    }

    // Stop the recorder/save the entry to the DB,
    // Then reset the timer
    await stopTimer();
    resetTimer();

    // Logout
    return logout();
  };

  return (
    <Portal selector="#logout-link-sidebar">
      <button
        className="manager-item__link"
        type="button"
        onClick={handleLogoutLink}
      >
        <span className="manager-item__icon">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M.5 0a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-2a.5.5 0 00-1 0V11H1V1h6v1.5a.5.5 0 001 0v-2a.5.5 0 00-.5-.5h-7z" />
            <path d="M9.146 3.646a.5.5 0 01.708 0l2 2a.5.5 0 010 .708l-2 2a.5.5 0 11-.708-.708L10.293 6.5H4.5a.5.5 0 010-1h5.793L9.146 4.354a.5.5 0 010-.708z" />
          </svg>
        </span>
        <span className="manager-item__name">Logout</span>
      </button>
    </Portal>
  );
};

export default LogoutLink;
