/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconCaretRightProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconCaretRight = ({ label, ...props }: SvgIconCaretRightProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        d="M11.06 16.14a.5.5 0 0 1-.71 0l-.2-.2a.49.49 0 0 1-.15-.36V8.42a.49.49 0 0 1 .15-.36l.2-.2a.5.5 0 0 1 .71 0l3.79 3.79a.48.48 0 0 1 0 .7l-3.79 3.79Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconCaretRight;
