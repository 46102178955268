import React, { useMemo } from "react";
import Progress from "Components/blocks/progress/Progress";
import { useIsAllowed } from "Components/blocks/permissions/Permissions";
import { useSchedule } from "../../../state/ScheduleContext";
import BudgetRow from "../BudgetRow";
import styles from "../card.module.css";

const ExpandedRoleInfo = ({ role, canBudgetandAllocate, allocation }) => {
  const { viewFinancials } = useIsAllowed(["canViewFinancialInformation"])
    ? useSchedule()
    : false;
  const name = useMemo(() => role?.name ?? "No Role Assigned", [role]);

  // Financials
  const budget = useMemo(() => (role ? Number(role?.budget) : 0), [role]);
  const budgetConsumed = useMemo(
    () => (role ? Number(role?.budgetConsumed) : 0),
    [role]
  );
  const budgetRemaining = useMemo(
    () => (role ? Number(role?.budget) - Number(role?.budgetConsumed) : 0),
    [role]
  );
  const showBudgets = useMemo(
    () => canBudgetandAllocate && viewFinancials,
    [canBudgetandAllocate, viewFinancials]
  );

  // Hours
  const hours = useMemo(() => (role ? Number(role?.hoursBudgeted) : 0), [role]);
  const hoursConsumed = useMemo(
    () => (role ? Number(role?.hoursConsumed) : 0),
    [role]
  );
  const hoursRemaining = useMemo(
    () =>
      role ? Number(role?.hoursBudgeted) - Number(role?.hoursConsumed) : 0,
    [role]
  );
  const hoursForecasted = useMemo(
    () => (role ? Number(role?.averagedHours) : 0),
    [role]
  );

  return (
    <>
      <div className={styles.role}>{name}</div>
      <div className={styles.roleProgress}>
        <Progress
          total={hours}
          consumed={hoursConsumed}
          average={hoursForecasted}
          allocated={allocation}
        />
      </div>
      <div className={styles.roleBudget}>
        <BudgetRow
          title="Budgeted"
          showBudget={showBudgets}
          budget={budget}
          hours={hours}
        />
        <BudgetRow
          title="Consumed"
          showBudget={showBudgets}
          budget={budgetConsumed}
          hours={hoursConsumed}
        />
        <BudgetRow
          title="Remaining"
          showBudget={showBudgets}
          budget={budgetRemaining}
          hours={hoursRemaining}
        />
      </div>
    </>
  );
};

export default ExpandedRoleInfo;
