import React from "react";

export function Edit() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      style={{ overflow: "visible" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58398 1.66663H1.66732C1.4463 1.66663 1.23434 1.75442 1.07806 1.9107C0.921782 2.06698 0.833984 2.27895 0.833984 2.49996V8.33329C0.833984 8.55431 0.921782 8.76627 1.07806 8.92255C1.23434 9.07883 1.4463 9.16663 1.66732 9.16663H7.50065C7.72166 9.16663 7.93363 9.07883 8.08991 8.92255C8.24619 8.76627 8.33398 8.55431 8.33398 8.33329V5.41663"
        stroke="#A0A8AE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.54241 2.70829L8.95898 2.29172C9.12474 2.12596 9.21787 1.90114 9.21787 1.66672C9.21787 1.4323 9.12474 1.20748 8.95898 1.04172C8.79322 0.87596 8.5684 0.782837 8.33398 0.782837C8.09956 0.782837 7.87474 0.87596 7.70898 1.04172L7.29232 1.45839M8.54241 2.70829L5.00065 6.25005L3.33398 6.66672L3.75065 5.00005L7.29232 1.45839M8.54241 2.70829L7.29232 1.45839"
        stroke="#A0A8AE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(Edit);
