import { useToasts } from "@plan/components";

import {
  CreateEntryInput,
  TimerEntryFragment,
  TimerEntryFragmentDoc,
  useCreateTimerEntryMutation,
} from "../../../../../apps/web/packages/app/generated/graphql";

import { useLogOnboardingEvent } from "./useLogOnboardingEvent";

interface useCreateTimerEntryProps {
  successCallback: (newEntry: TimerEntryFragment) => void;
}

export const useCreateTimerEntry = ({
  successCallback,
}: useCreateTimerEntryProps) => {
  const { addToast } = useToasts();
  const logOnboardingEvent = useLogOnboardingEvent();
  const [createEntryMutation] = useCreateTimerEntryMutation({
    onCompleted(data) {
      if (!data.createEntry?.entry) return;
      const newEntry = data.createEntry?.entry;
      logOnboardingEvent();
      addToast({
        message: `Successfully created timesheet entry`,
        intent: "success",
      });
      successCallback(newEntry);
    },
    onError(error) {
      addToast({
        message: `Error creating timesheet entry: ${error.toString()}`,
        intent: "danger",
      });
    },
    update: (cache, { data }) => {
      const newEntry = data?.createEntry?.entry;
      if (!newEntry) return;
      cache.modify({
        fields: {
          timer(existingTimer) {
            const newEntryRef = cache.writeFragment({
              data: newEntry,
              fragment: TimerEntryFragmentDoc,
            });
            const existingEntries = existingTimer.entries || [];

            return {
              ...existingTimer,
              entries: [...existingEntries, newEntryRef],
            };
          },
        },
      });
    },
  });

  const createTimerEntry = async (entry: CreateEntryInput) => {
    await createEntryMutation({
      variables: {
        input: {
          ...entry,
        },
      },
    });

    return true;
  };

  return createTimerEntry;
};
