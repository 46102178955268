import { makeRequest } from "./request";

// PROFILES

// Add a team member / profile
// data { }
export const createProfile = async (data) =>
  makeRequest({
    method: "post",
    url: "/team",
    data,
  });
