import React from "react";
import { Card } from "@plan";
import styles from "../consultants.module.css";
import { AddConsultant } from "./AddConsultant";

export const EmptyState = () => {
  return (
    <Card classes={styles.emptyStateStarter}>
      <div className={styles.emptyStateSection}>
        <h4 className={styles.emptyStateHeading}>Add a consultant</h4>
        <p className={styles.emptyStateInstructions}>
          Start planning consultant work on this project.
        </p>
        <div className={styles.emptyStateAction}>
          <AddConsultant />
        </div>
      </div>
    </Card>
  );
};
