import React from "react";
import moment from "moment";

// This should be refactored to be structured more like
// the Currency/Hours components

export const DateFormat = ({ date, withTime }) => {
  if (date === null) return null;

  const dateString = moment(date).format("MMM Do, YYYY");

  if (withTime) {
    const timeString = moment(date).format("LT");
    return (
      <>
        {dateString} @ {timeString}
      </>
    );
  }

  return <>{dateString}</>;
};

export default DateFormat;
