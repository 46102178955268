import React from "react";
import { FieldProvider } from "./FieldContext";
import FieldWrapper from "./FieldWrapper";
import styles from "./checkbox.module.css";

export function CheckBox(props) {
  const { label, isChecked, handleToggle, name } = props;
  return (
    <FieldProvider {...props} as="checkbox">
      <FieldWrapper>
        <label className={styles.label}>
          <input
            type="checkbox"
            name={name}
            checked={isChecked}
            onChange={handleToggle}
            className={styles.checkbox}
          />
          {label}
        </label>
      </FieldWrapper>
    </FieldProvider>
  );
}

CheckBox.defaultProps = {
  label: "Add a Label",
  isChecked: false,
  toggleChecked: () => console.log("add a function"),
};

export default CheckBox;
