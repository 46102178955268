import React, { useState, useEffect, useMemo, useCallback } from "react";
import cx from "classnames";
import { eventTracker } from "Blocks/tracker";
import { useSelectedPhase, useGridView } from "../../state/Store";
import styles from "./card.module.css";

export function useCard(phaseId) {
  const gridView = useGridView();
  const [selectedPhase, setSelectedPhase] = useSelectedPhase();
  const [isOpen, setOpen] = useState(false);
  const selectPhase = useCallback(() => {
    eventTracker("trackResourceAllocationSelect");
    setSelectedPhase(phaseId);
  }, [phaseId]);

  useEffect(() => {
    if (phaseId === selectedPhase) setOpen(true);
    else setOpen(false);
  }, [phaseId, selectedPhase]);

  const ghosted = useMemo(
    () => selectedPhase && phaseId !== selectedPhase,
    [selectedPhase, phaseId]
  );

  return {
    ghosted,
    gridView,
    selectPhase,
    open: isOpen,
  };
}

export const Card = ({ id, color, classes, height, children }) => {
  const { ghosted, gridView, open } = useCard(id);

  const classNames = useMemo(
    () =>
      cx(
        ...classes,
        { [styles.ghosted]: ghosted },
        { [styles.gridRow]: gridView === "row" },
        { [styles.open]: open }
      ),
    [ghosted, gridView, open, classes]
  );

  return (
    <div
      className={classNames}
      style={{ "--project-color": color, "--row-height": `${height}px` }}
      data-card={id}
    >
      {children}
    </div>
  );
};

export default React.memo(Card);
