import React from "react";

export default function StatusActive(classes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <circle cx="16" cy="16" r="16" fill="#F7F9FA" />
      <path
        d="M21.5708 15.1425C22.2182 15.5309 22.2182 16.4691 21.5708 16.8575L14.5145 21.0913C13.848 21.4912 13 21.0111 13 20.2338V11.7662C13 10.9889 13.848 10.5088 14.5145 10.9087L21.5708 15.1425Z"
        fill="#DFE2E4"
      />
    </svg>
  );
}
