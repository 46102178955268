import React from "react";
import PropTypes from "prop-types";

export const CreateProjectMenuLink = ({ templateId }) => (
  <button
    type="button"
    className="menu-page-button__link menu-page-button__link--archive"
    onClick={() => {
      window.location.href = `/projects/create?templateId=${templateId}`;
      return null;
    }}
  >
    <h3 className="menu-page-button__title">Save as Project</h3>
    <p className="menu-page-button__description">
      Save this template, its phases, roles, milestones, and tasks as a project.
    </p>
  </button>
);

CreateProjectMenuLink.propTypes = {
  templateId: PropTypes.string.isRequired,
};

export default CreateProjectMenuLink;
