import { FC, PropsWithChildren } from "react";

import { styled } from "@plan/core";
import { IconCheckmarkCircleOutlined } from "@plan/icons";
import { Box, Flex } from "@plan/layout";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipTriggerDisabled,
} from "@plan/components";

import { CreateEntryInput } from "../../../../../../../apps/web/packages/app/generated/graphql";
import { useTimerContext } from "../../../contexts/TimerContext";

import { useFormikContext } from "formik";

interface ConditionalTooltipProps {
  disabled: boolean;
  onClick?: () => void;
}

const tooltipText = "Select a project & phase to create entry";

// This is duplicated from the web app
const DisabledTooltip: FC<PropsWithChildren<ConditionalTooltipProps>> = ({
  disabled,
  children,
}) => {
  if (!disabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <TooltipTriggerDisabled>{children}</TooltipTriggerDisabled>
      </TooltipTrigger>
      <TooltipContent side="top">{tooltipText}</TooltipContent>
    </Tooltip>
  );
};

const TimerFooter = styled(Box, {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 $space$4 $space$4",
});

const UpdateButtons: FC = () => {
  const { submitForm, dirty, status } = useFormikContext();
  const {
    actions: { toggleTimerModal },
  } = useTimerContext();

  if (!dirty)
    return (
      <Flex
        css={{
          height: "40px",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        {status.saved && (
          <Flex css={{ gap: "5px" }}>
            <IconCheckmarkCircleOutlined
              label="Saved"
              css={{ color: "$colors$-success10" }}
            />
            <Box css={{ color: "$colors$-success10" }}>Saved!</Box>
          </Flex>
        )}
      </Flex>
    );

  return (
    <>
      <Button intent="secondary" onClick={toggleTimerModal}>
        Cancel
      </Button>
      <Button intent="primary" onClick={submitForm}>
        Save updates
      </Button>
    </>
  );
};

const CreateButtons = () => {
  const {
    actions: { clearTimerEntry, toggleTimerModal },
  } = useTimerContext();
  const { submitForm, values } = useFormikContext<CreateEntryInput>();

  const handleCancelClick = () => {
    clearTimerEntry();
    toggleTimerModal();
  };

  return (
    <>
      <Button intent="secondary" onClick={handleCancelClick}>
        Cancel
      </Button>
      <DisabledTooltip disabled={!values.phaseId}>
        <Button
          intent="primary"
          onClick={submitForm}
          disabled={!values.phaseId}
        >
          Start timer
        </Button>
      </DisabledTooltip>
    </>
  );
};

export const FormButtons: FC = () => {
  const {
    status: { intent },
  } = useFormikContext();
  const isUpdating = intent === "update";

  return (
    <TimerFooter>
      {isUpdating && <UpdateButtons />}
      {!isUpdating && <CreateButtons />}
    </TimerFooter>
  );
};
