import React from "react";
import Skeleton from "Components/blocks/skeleton/Skeleton";
import Avatar from "Components/blocks/avatar/Avatar";
import styles from "./headers.module.css";

export const LoadingHeader = () => (
  <div className={styles.header}>
    <div className={styles.profile}>
      <Avatar size="xSmall" />{" "}
      <Skeleton width="6rem" height="1rem" marginBottom={0} />
    </div>
    <div className={styles.totals}>
      <div className={styles.projects}>
        <Skeleton width="2rem" height="1rem" marginBottom={0} />
      </div>
      <div className={styles.hours}>
        <Skeleton width="1rem" height="1rem" marginBottom={0} />
      </div>
    </div>
  </div>
);

export default LoadingHeader;
