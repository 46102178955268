import Highcharts from "highcharts";
import util from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

export default class ProfileProjectsDistribution {
  constructor() {
    bindListenerToDocument("click", "js-select-profile-time", (event) => {
      this.getTime(event);
    });

    this.createChart();
  }

  // Methods
  createChart() {
    this.renderChart();

    this.time = "month";
    const data = { time: this.time };
    this.updateData(data);
  }

  renderChart() {
    this.chart = Highcharts.chart("profile-projects-distribution", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 300,
        type: "pie",
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "<b>{point.y:.1f} hours</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          point: {
            events: {
              legendItemClick(event) {
                return false;
              },
            },
          },
        },
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        itemMarginTop: 4,
        itemMarginBottom: 4,
        labelFormatter() {
          let { name } = this;
          if (this.name.length > 14) {
            name = `${this.name.slice(0, 15).trim()}...`;
          }
          return `<a href="/projects/${util.slugify(this.name)}">${name}</a>`;
        },
      },
      series: [
        {
          colorByPoint: true,
          data: [],
          innerSize: "50%",
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }

  updateChart(projectData) {
    // Remove legends because they don't remove themselves on update
    this.chart.legend.destroy();

    const series = [{ data: this.massageProjectsHours(projectData) }];

    this.chart.update({ series });
  }

  updateData(data) {
    this.chart.showLoading();
    ajax({
      method: "GET",
      path: `/highcharts/profile_project_hours`,
      data,
      success: (response) => {
        this.chart.hideLoading();
        if (this.chart.series) {
          const projectData = JSON.parse(response);
          this.updateChart(projectData);
        }
      },
    });
  }

  getTime(event) {
    const newTime = event.target.dataset.value;
    if (this.time === newTime) {
    } else {
      this.time = newTime;
      this.updateData({ time: newTime });
    }
  }

  // Helpers
  massageProjectsHours(projectData) {
    const massagedProjectsHours = projectData.map((project) => ({
      name: project.name,
      y: project.hours,
      color: project.color,
    }));
    return massagedProjectsHours;
  }

  // Destroy
  destroyChart() {
    this.chart.destroy();
  }
}
