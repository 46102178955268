import { Box } from "@plan/layout";

import { Button } from "../Button";
import { Input } from "../Input";
import { useToasts } from "../Toast";

export type CopyableLinkProps = {
  disabled?: boolean;
  label?: string;
  onCopy?: () => void;
  onCopyMessage?: string;
  value: string;
};

export const CopyableLink = ({
  disabled,
  label,
  onCopy,
  onCopyMessage,
  value,
}: CopyableLinkProps) => {
  const { addToast } = useToasts();

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    addToast({
      intent: "success",
      message: onCopyMessage || "Copied to clipboard",
    });

    onCopy && onCopy();
  };

  return (
    <Box>
      <Input
        label={label}
        onClick={(e) => {
          const target = e.currentTarget;
          target.setSelectionRange(0, target.value.length);
        }}
        disabled={disabled}
        readOnly={true}
        value={value}
        suffix={
          <Button
            intent="secondary"
            size="sm"
            disabled={disabled}
            onClick={handleCopy}
          >
            Copy
          </Button>
        }
      />
    </Box>
  );
};
