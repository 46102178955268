import React from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

export function Accordion({ isOpen, children, linear = true }) {
  const transition = linear
    ? { easing: "linear", stiffness: 200, damping: 200, duration: 0.2 }
    : {};
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          transition={transition}
          initial={{ opacity: 0.5, height: 0, overflow: "hidden" }}
          animate={{ opacity: 1, height: "auto", overflow: "visible" }}
          exit={{ opacity: 0.5, height: 0, overflow: "hidden" }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  isOpen: false,
};

export default React.memo(Accordion);
