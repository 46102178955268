import { gql } from "@apollo/client";

/**
 * Get consultant templates for a given organization
 */
export const GET_CONSULTANT_TEMPLATES = gql`
  query consultantTemplates($organizationId: ID!) {
    consultantTemplates(organizationId: $organizationId) {
      name
      id
    }
  }
`;

/**
 * Get consultants for a project
 */
export const GET_PROJECT_CONSULTANTS = gql`
  query projectConsultants($slug: String!) {
    project(slug: $slug) {
      id
      totalFee
      consultantsFee
      projectConsultants {
        budget
        budgetIsFrozen
        id
        consultantTemplate {
          name
        }
      }
    }
  }
`;

/**
 * Get consultants for a project template
 */
export const GET_PROJECT_TEMPLATE_CONSULTANTS = gql`
  query projectTemplateConsultants($slug: String!) {
    projectTemplate(slug: $slug) {
      id
      totalFee
      consultantsFee
      projectConsultants {
        budget
        id
        consultantTemplate {
          name
        }
      }
    }
  }
`;

/**
 * Create a project consultant
 */
export const CREATE_CONSULTANT = gql`
  mutation createProjectConsultant($input: CreateInput!) {
    createProjectConsultant(input: $input) {
      projectConsultant {
        budget
        id
      }
    }
  }
`;

/**
 * Update budget on a project consultant
 */
export const UPDATE_BUDGET = gql`
  mutation updateProjectConsultant($input: UpdateProjectConsultantInput!) {
    updateProjectConsultant(input: $input) {
      projectConsultant {
        budget
        id
        project {
          id
          totalFee
        }
      }
    }
  }
`;

/**
 * Delete a project consultant
 */
export const DELETE_CONSULTANT = gql`
  mutation deleteProjectConsultant($input: DeleteInput!) {
    deleteProjectConsultant(input: $input) {
      projectConsultant {
        id
      }
    }
  }
`;
