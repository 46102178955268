import React from "react";
import SelectInput from "./inputs/SelectInput";
import SearchInput from "./inputs/SearchInput";
import TextInput from "./inputs/TextInput";
import NumberInput from "./inputs/NumberInput";
import { DateInput, SingleDateInput } from "./inputs/DateInput";
import DisplayInput from "./inputs/DisplayInput";
import { useFieldState } from "./FieldContext";

export function Input() {
  const { as } = useFieldState();
  switch (as) {
    case "text":
      return <TextInput />;
    case "select":
      return <SelectInput />;
    case "search":
      return <SearchInput />;
    case "number":
      return <NumberInput />;
    case "dates" || "dateRange":
      return <DateInput />;
    case "date" || "singleDate":
      return <SingleDateInput />;
    case "display":
      return <DisplayInput />;
    default:
      return <TextInput />;
  }
}

export default React.memo(Input);
