import React from "react";

export default class InvoiceDateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.updateDate = this.updateDate.bind(this);
  }

  updateDate(e) {
    this.props.updateDate({ [e.target.name]: e.target.value });
  }

  render() {
    const { begin_date, end_date } = this.props;

    return (
      <div className="invoice-controls__date-filters">
        <div className="field has-value">
          <div className="field__block">
            <label className="field__label">Begin Date</label>
            <input
              type="date"
              className="field__input"
              value={begin_date}
              name="begin_date"
              onChange={this.updateDate}
            />
          </div>
        </div>
        <div className="field has-value">
          <div className="field__block">
            <label className="field__label">End Date</label>
            <input
              type="date"
              className="field__input"
              value={end_date}
              name="end_date"
              onChange={this.updateDate}
            />
          </div>
        </div>
      </div>
    );
  }
}
