/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconWarningProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconWarning = ({ label, ...props }: SvgIconWarningProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.905 19.63 12.813 2.37a.76.76 0 0 0-.65-.37h-.3a.76.76 0 0 0-.65.37L1.1 19.63a.79.79 0 0 0 0 .76l.14.23a.73.73 0 0 0 .65.38h20.225a.73.73 0 0 0 .65-.38l.14-.23a.79.79 0 0 0 0-.76Zm-9.902-3.13a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1Zm-.47-3.5a.25.25 0 0 0 .25-.22l.4-3.22a.5.5 0 0 0-.5-.56h-1.36a.5.5 0 0 0-.5.56l.4 3.22a.25.25 0 0 0 .25.22h1.06Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconWarning;
