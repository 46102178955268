import React from "react";
import { Skeleton } from "@plan";

export default function LoadingSkeleton() {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Skeleton
        width="33.75em"
        height="calc(100vh - 108px)"
        background="var(--color-white)"
        animated={false}
        style={{
          borderRight: "1px solid #e7e9eb",
          padding: "1.5em 1em",
          minWidth: "540px",
        }}
      />
      <div style={{ width: "100%", padding: "1.5em" }}>
        <Skeleton
          width="100%"
          height="calc(100vh - 200px)"
          background="var(--color-legacy-gray-2)"
          style={{ width: "960px", border: "1px solid #e7e9eb" }}
        />
      </div>
    </div>
  );
}
