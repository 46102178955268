import numeral from "numeral";
import React, { useState, useRef, useEffect, useCallback } from "react";
import Avatar from "Components/blocks/avatar/Avatar";
import Status from "Components/blocks/badge/Status";
import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { Permissions } from "Components/blocks/permissions/Permissions";
import cx from "classnames";
import OverheadHeader from "./OverheadHeader";
import TimeOffHeader from "./TimeOffHeader";

import Search from "./Search";

import styles from "./headers.module.css";

// We position the search input outside of the header
// This is due to restrictions with overflow and scroll syncing
// within the header itself
const useSearchPosition = () => {
  const searchArea = useRef(null);
  const searchInput = useRef(null);
  const [search, setSearch] = useState(false);
  const [searchPosition, setSearchPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  useClickAway(searchInput, () => setSearch(false));

  const set = useCallback(() => {
    const searchRect = searchArea?.current.getBoundingClientRect();
    setSearchPosition({
      top: searchRect?.top,
      left: searchRect?.left,
      bottom: searchRect?.bottom,
      width: searchRect?.right - searchRect?.left,
    });
  }, [searchArea]);

  useEffect(() => {
    window.addEventListener("scroll", set);
    return () => window.removeEventListener("scroll", set);
  }, []);

  useEffect(() => set(), [searchArea.current, search]);

  useEffect(() => {
    if (search) searchInput?.current?.focus();
  }, [search, searchInput?.current]);

  return { searchPosition, searchArea, searchInput, search, setSearch };
};

const ColumnHeader = ({
  id,
  name,
  avatar,
  hours,
  count,
  team,
  badgeColor,
  hoursPerWeek,
  overheads,
  timeOffHours,
  profileSlug,
}) => {
  const { searchPosition, searchArea, searchInput, setSearch, search } =
    useSearchPosition();

  const handleAdd = () => setSearch(true);
  return (
    <div className={cx(styles.header, { [styles.team]: team })}>
      <div className={styles.profile}>
        {!team && <Avatar size="xSmall" image={avatar} />} {name}
        <div className={styles.hours}>
          <Status color={badgeColor} />
          {numeral(hours).format("0.[00]")}
          <div className={styles.hoursLabel}> / {hoursPerWeek} HRS</div>
        </div>
      </div>
      <Permissions needsPermissions={["canManageStaffing"]}>
        <OverheadHeader profileSlug={profileSlug} overheads={overheads} />
      </Permissions>
      <Permissions
        needsPermissions={["canViewAllTimesheets", "canManageStaffing"]}
      >
        <TimeOffHeader profileSlug={profileSlug} hours={timeOffHours} />
      </Permissions>
      <div ref={searchArea} className={styles.searchWrap}>
        <AnimatePresence initial={false} exitBeforeEnter>
          {!search && (
            <Subheader team={team} count={count} handleAdd={handleAdd} />
          )}
          {search && (
            <Search
              id={id}
              searchInputRef={searchInput}
              searchPosition={searchPosition}
              setSearch={setSearch}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const Subheader = ({ team, count, handleAdd }) => (
  <motion.div
    key="phases"
    className={styles.totals}
    transition={{ duration: 0.1 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 0 }}
  >
    <div className={styles.projects}>{count} Phases</div>
    {!team && (
      <Permissions needsPermissions={["canManageStaffing"]}>
        <button className={styles.add} onClick={handleAdd}>
          Add +
        </button>
      </Permissions>
    )}
  </motion.div>
);

export default React.memo(ColumnHeader);
