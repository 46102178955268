import classNames from "classnames";
import React from "react";

// Props
// selectedValue: "value"
// onChange: fn()

export default class SelectList extends React.Component {
  constructor(props) {
    super(props);

    this.isSelected = this.isSelected.bind(this);

    this.multiselect = Array.isArray(this.props.selectedValue);
  }

  onClick(event, item) {
    // If onClick is defined on the item, call that instead
    if (item.onClick) {
      event.stopPropagation();
      item.onClick();
      this.props.close();
    } else if (this.multiselect) {
      // Don't close the select list if it is a multiselect
      if (this.isSelected(item)) {
        this.props.deselect(item.value);
      } else {
        this.props.onSelect(item.value);
      }
    } else {
      // Stop propagation to prevent the onClick of the Select element from calling
      // open after this closes the selectList.
      event.stopPropagation();
      this.props.onSelect(item.value);
      this.props.close();
    }
  }

  isSelected(item) {
    if (this.multiselect) {
      return this.props.selectedValue.includes(item.value);
    }
    return item.value === this.props.selectedValue;
  }

  render() {
    return (
      <ul className="select__list">
        {this.props.items.map((item) => {
          return (
            <li
              className={
                classNames({
                  select__item: true,
                  "is-selected": this.isSelected(item),
                }) + (item.className ? ` ${item.className}` : "")
              }
              key={item.value}
              onClick={(event) => this.onClick(event, item)}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  }
}
