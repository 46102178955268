import React from "react";
import EmptyState from "Components/blocks/EmptyState.jsx";

const ArchivesEmptyState = () => {
  return (
    <>
      <EmptyState
        title={"No Archived Projects!"}
        text={
          'To archive a project click "More Actions" button on top right corner of the specific project and choose "Archive"'
        }
      ></EmptyState>
    </>
  );
};

export default ArchivesEmptyState;
