import React, { useMemo } from "react";
import Accordion from "Components/blocks/accordion/Accordion";
import Allocation from "Components/blocks/allocation/Allocation";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import cx from "classnames";
import { motion } from "framer-motion";
import { useIsAllowed } from "Components/blocks/permissions/Permissions";
import Badge from "Components/blocks/badge/Badge";
import {
  RoleIcon as RoleIconSvg,
  NoRoleIcon as NoRoleIconSvg,
} from "Components/blocks/icons";
import { IconEdit } from "@plan/icons";
import ExpandedRoleInfo from "./ExpandedRoleInfo";
import styles from "../card.module.css";
import Card, { useCard } from "../Card";
import useUpdateAllocations from "./useUpdateAllocations";

export function ProjectCard({
  number,
  name,
  abbrev,
  color,
  role,
  id,
  allocation,
  consumed,
  phaseId,
  added,
  allocatedByUser,
  height,
  projectSlug,
}) {
  const { handleChange, handleRemove } = useUpdateAllocations(id);
  const { ghosted, selectPhase, open } = useCard(phaseId);

  const canBudgetandAllocate = useIsAllowed(["canManageStaffing"]);
  const canViewAllProjects = useIsAllowed(["canViewAllProjects"]);

  const editable = useMemo(
    () => !ghosted && canBudgetandAllocate,
    [ghosted, canBudgetandAllocate]
  );

  const allocationTotal = parseFloat(allocation);
  const overage = allocationTotal < consumed;

  const handleProjectLinkClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Card
      id={phaseId}
      color={color}
      classes={[styles.cardWrapper]}
      height={height}
    >
      <CardAnimation phaseId={phaseId} ghosted={ghosted} added={added}>
        <div className={styles.info} onClick={selectPhase} data-card={phaseId}>
          {allocatedByUser && (
            <>
              <div className={styles.allocatedByUser} title="Edited">
                <IconEdit
                  label="Edited"
                  css={{ width: "10px", height: "10px" }}
                />
              </div>
            </>
          )}
          <Badge
            value={number}
            size="small"
            style={{ marginBottom: ".25rem", display: "block" }}
          />
          <h3 className={styles.name} title={name}>
            {canViewAllProjects ? (
              <a
                onClick={handleProjectLinkClick}
                className={styles.projectLink}
                href={`/projects/${projectSlug}`}
                target="_blank"
                rel="noreferrer"
              >
                {name}
              </a>
            ) : (
              name
            )}
          </h3>
          <div className={styles.abbrev}>
            <div className={styles.abbrevText} title={abbrev}>
              {abbrev}
            </div>
            <RoleIcon role={role} />
          </div>
        </div>
        <div className={styles.allocation}>
          <Allocation
            total={allocationTotal}
            progress={consumed}
            setTotal={handleChange}
            removeFn={handleRemove}
            editable={editable}
            size="large"
            overage={overage}
          />
        </div>
        <div className={styles.expanded}>
          <Accordion isOpen={open}>
            <ExpandedRoleInfo
              role={role}
              canBudgetandAllocate={canBudgetandAllocate}
              allocation={Number(allocation)}
            />
          </Accordion>
        </div>
      </CardAnimation>
    </Card>
  );
}

const CardAnimation = ({ children, ghosted, phaseId, added }) => {
  const classes = useMemo(
    () => cx(styles.card, { [styles.ghosted]: ghosted }),
    [ghosted]
  );

  const initial = added
    ? { opacity: 0.9, scale: 1.025, y: -10 }
    : { opacity: 1, scale: 1, y: 0 };

  return (
    <motion.div
      className={classes}
      initial={initial}
      transition={{ duration: 0.2 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0.9, scale: 0.95, y: 10 }}
      whileHover={{ scale: 1.02 }}
      key={phaseId}
      layoutId={phaseId}
      layout
    >
      {children}
    </motion.div>
  );
};

const RoleIcon = ({ role }) => {
  const [roleTooltip, roleNode] = useTooltip();
  const tooltipText =
    role?.name ??
    "No role assigned. To assign default roles go to your Directory.";

  return (
    <div className={styles.roleIcon} ref={roleNode}>
      {role ? <RoleIconSvg /> : <NoRoleIconSvg />}
      <Tooltip tooltip={roleTooltip} text={tooltipText} key="role" />
    </div>
  );
};

export default React.memo(ProjectCard);
