import { useAction } from "@hooks";
import { makeRequest } from "./request";

export const generateProjectReportCSV = async (params) =>
  makeRequest({
    method: "get",
    url: `/reports/projects_data.csv`,
    params,
  });

export const useGenerateProjectReportCSV = () =>
  useAction(generateProjectReportCSV);
