import { useEffect, useState } from "react";
import * as React from "react";

import { createComponent, styled, useModalTrap } from "@plan/core";
import { IconClear } from "@plan/icons";
import { AutoLayout, Box } from "@plan/layout";

import { Heading } from "../Heading";
import { Text } from "../Text";

import * as DialogPrimitive from "@radix-ui/react-dialog";

const component = createComponent();

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  position: "fixed",
  zIndex: "$overlay",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  alignItems: "center",
  background: "rgba(247, 249, 250, 0.95)",
  backdropFilter: "blur(0.13rem)",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
  WebkitOverflowScrolling: "touch",
  overflowY: "auto",
  textAlign: "center",
});

const StyledHeader = styled(Box, {
  ...component,
  display: "grid",
  gridTemplateColumns: "1fr $space$8",
  marginBottom: "$space$5",
});

const StyledContent = styled(DialogPrimitive.Content, {
  ...component,
  position: "fixed",
  zIndex: "$modal",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid $colors$-neutral30",
  maxHeight: "85vh",
  padding: "$space$5",
  marginTop: "-5vh",
  backgroundColor: "$white",
  borderRadius: "$radii$default",
  "&:focus": {
    outline: "none",
  },
  variants: {
    ...component.variants,
    // FIXME (BREAKING CHANGE)
    // Amend `rem` values to closest $space key (or calc value) on first refactor
    size: {
      xs: {
        maxWidth: "25rem",
      },
      sm: {
        maxWidth: "27.5rem",
      },
      md: {
        maxWidth: "32rem",
      },
      lg: {
        maxWidth: "75rem",
      },
    },
    allowOverflow: {
      true: {
        overflow: "visible",
      },
    },
    allowOverflowScrollY: {
      true: {
        overflowY: "scroll",
      },
    },
    customLayout: {
      true: {
        width: "auto",
      },
      false: {
        width: "100%",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    size: "md",
    customLayout: false,
  },
});

const StyledClose = styled(DialogPrimitive.Close, {
  ...component,
  position: "absolute",
  top: "$space$5",
  right: "$space$5",
  background: "none",
  border: "none",
  color: "$colors$-neutral10",
  cursor: "pointer",
  fontSize: "$2xl",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: "1.5rem",
  margin: "0",
  transition: "color 0.2s $cb",
  "&:hover, &:focus": { color: "$colors$neutral20", outline: "none" },
});

export type ModalProps = React.ComponentProps<typeof Modal>;

export const Modal = DialogPrimitive.Root;

export interface ModalContentProps
  extends Omit<React.ComponentProps<typeof StyledContent>, "title">,
    DialogPrimitive.DialogProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
}
export const ModalContent: React.FC<ModalContentProps> = ({
  title,
  subtitle,
  size,
  customLayout,
  children,
  onPointerDownOutside,
  ...props
}) => {
  type OmitUndefinedParameters<T> = T extends undefined ? never : T;
  type PointerDownEvent = Parameters<
    OmitUndefinedParameters<typeof onPointerDownOutside>
  >[0];
  const [modalContainer, setModalContainer] = useState<HTMLElement | null>(
    null
  );
  const [isModalTrapped] = useModalTrap();

  const handleOnPointerDownOutside = (event?: PointerDownEvent) => {
    if (!event) return;
    if (isModalTrapped === true || !onPointerDownOutside)
      event?.preventDefault();
    typeof onPointerDownOutside === "function" && onPointerDownOutside(event);
  };

  useEffect(() => {
    setModalContainer(
      document?.getElementById("__next") ||
        document?.getElementById("railsAppModalContainer")
    );
  }, []);

  const standardLayout = (
    <>
      <StyledHeader>
        <AutoLayout direction="vertical" resizingX="fill-container" spacing="2">
          <Heading textStyle="heading4">{title}</Heading>
          {subtitle && (
            <Text
              textStyle="large"
              css={{ color: "$neutral10", marginTop: "20px" }}
            >
              {subtitle}
            </Text>
          )}
        </AutoLayout>
      </StyledHeader>
      {children}
      <StyledClose aria-label="Close">
        <IconClear label="Close" />
      </StyledClose>
    </>
  );

  return (
    <DialogPrimitive.Portal container={modalContainer}>
      <StyledOverlay />
      <StyledContent
        size={size}
        onPointerDownOutside={handleOnPointerDownOutside}
        customLayout={customLayout}
        {...props}
      >
        {customLayout ? children : standardLayout}
      </StyledContent>
    </DialogPrimitive.Portal>
  );
};

export const ModalTrigger = DialogPrimitive.Trigger;
export const ModalClose = DialogPrimitive.Close;
