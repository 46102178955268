import React from "react";
import Avatar from "./Avatar";
import styles from "./avatar.module.css";

// This component can be used with a select element
// to display the label + user avatar
// its passed using the formatOptionLabel prop on the select
// <Select formatOptionLabel={TeamSelectOption} />
// (works on fields as selects as well)

export const AvatarSelectOption = ({ label, image }) => (
  <div className={styles.avatarSelectOption}>
    <div className={styles.avatarSelect}>
      <Avatar size="xSmall" image={image} />
    </div>
    <div>{label}</div>
  </div>
);

export default AvatarSelectOption;
