import React from "react";
import PropTypes from "prop-types";
import { sumBy } from "lodash";
import Loading from "Components/blocks/Loading";
import EmptyState from "Components/blocks/EmptyState";
import Table from "Components/blocks/Table/Table";
import { useTableData, tableColumns } from "./useTableData";
import ColumnHeaders from "./ColumnHeaders";
import ExpandableRow from "./ExpandableRow";
import styles from "./table.module.css";
import { useTimelogContext } from "../TimelogContext";

export default function Activities() {
  const [data, loading, error] = useTableData("activities");
  const { canEditAllTimesheets } = useTimelogContext();

  if (error) return <EmptyState title="Error Fetching Data" />;
  if (loading === "fetching") return <Loading title="Loading..." />;

  const { data: tableData } = data;

  if (tableData.length === 0)
    return <EmptyState title="No Activities for this Date Range" />;

  return (
    <>
      <ColumnHeaders group="activities" />
      {tableData.map(({ name, data: activityData }) => {
        const hours = sumBy(activityData, (activity) =>
          parseFloat(activity.hours)
        );
        const value = sumBy(activityData, (activity) =>
          parseFloat(activity.value)
        );
        const entries = activityData.length;
        const billable = activityData && activityData[0].activity_billable;
        return (
          <div key={name}>
            <ExpandableRow
              rowHeader={name}
              groupAttribute={billable}
              entries={entries}
              hours={hours}
              value={value}
            >
              <div className={styles.table}>
                <Table
                  data={activityData}
                  columns={tableColumns(canEditAllTimesheets)}
                  emptyState="No Data for Date Range"
                  hideFooter
                />
              </div>
            </ExpandableRow>
          </div>
        );
      })}
    </>
  );
}
