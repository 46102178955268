import * as React from "react";

import { createComponent, styled } from "@plan/core";
import { AutoLayout, Flex } from "@plan/layout";

import { Skeleton } from "../Skeleton";

import { Grid } from "@visx/grid";
import { Legend, LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { LegendProps } from "@visx/legend/lib/legends/Legend";
import { ScaleOrdinal } from "d3-scale";

const component = createComponent();

// This makes the default props of LegendOrdinal explicit,
// so it can be used by ChartLegendWithPattern.
const LEGEND_SHAPE_WIDTH = 15;
const LEGEND_SHAPE_HEIGHT = 15;
const LEGEND_SHAPE_MARGIN = "2px 4px 2px 0px";

export const ChartGrid = styled(Grid, {});

// Since we just use one legend style for now, we can export as ChartLegend, in the future we can have ChartLegendOrdinal, etc
export const ChartLegend = styled(LegendOrdinal, {
  shapeWidth: LEGEND_SHAPE_WIDTH,
  shapeHeight: LEGEND_SHAPE_HEIGHT,
  shapeMargin: LEGEND_SHAPE_MARGIN,
});

export const ChartLegendWithPattern: React.FC<
  LegendProps<ScaleOrdinal<string, string>> & {
    shapePattern: (index: number) => string | null;
    itemMargin: string;
  }
> = ({ scale, direction, shapePattern, itemMargin }) => (
  <ChartLegend scale={scale} direction={direction}>
    {(labels) => (
      <Flex
        css={{
          alignItems: "center",
        }}
      >
        {labels.map((label, index) => (
          <LegendItem key={`legend-item-${index}`} margin={itemMargin}>
            <svg
              width={LEGEND_SHAPE_WIDTH}
              height={LEGEND_SHAPE_HEIGHT}
              style={{ margin: LEGEND_SHAPE_MARGIN }}
            >
              <rect
                width={"100%"}
                height={"100%"}
                fill={
                  shapePattern(label.index)
                    ? `url(#${shapePattern(label.index)})`
                    : label.value
                }
              />
            </svg>
            <LegendLabel>{label.text}</LegendLabel>
          </LegendItem>
        ))}
      </Flex>
    )}
  </ChartLegend>
);

export const ChartCustomLegend = styled(Legend, {});

const StyledGraph = styled("svg", {
  ...component,
  overflow: "visible",
});

export const ChartLegendWrapper = styled(AutoLayout, {
  marginTop: "$space$12",
  "& .visx-legend-label": {
    color: "$colors$neutral10",
    fontSize: "$fontSizes$sm",
  },
  variants: {
    largeMarginTop: {
      true: {
        marginTop: "$space$20",
      },
    },
  },
});

type ChartWrapperProps = {
  children?: React.ReactNode;
  loading?: boolean;
  width: number;
  height: number;
};

/**
 * ## Description
 * Chart component uses [visx](https://airbnb.io/visx/) library.
 *
 * ## Composing Chart
 * ChartParentSize, ChartWrapper, ChartGrid and ChartGroup are base components needed to construct chart. Based on chart type and label type needed, import BarSegment, BarStack, Label, Axis, LegendOrdinal components.
 * Use HighlightedReportProvider and useHighlightedContext in order to reduce opacity of other bars in bar stacks.
 * See examples in Bar Chart and Stacked Bar Chart stories.
 *
 * ## ChartWrapper props:
 * - `loading`: Boolean that defines whether to show chart loading state.
 * - `children` (Required): All chart content.
 * We use [visx ParentSize](https://airbnb.io/visx/docs/responsive) to make the chart responsive. The chart always uses parent's width and height.
 *
 */
export const ChartWrapper = ({
  loading = false,
  children,
  width,
  height,
}: ChartWrapperProps) => (
  <>
    {loading ? (
      <Skeleton />
    ) : (
      <StyledGraph key="grid" width={width} height={height}>
        {children}
      </StyledGraph>
    )}
  </>
);

export default ChartWrapper;
