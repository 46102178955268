import React from "react";
import Button from "./Button";
import styles from "./buttons.module.css";

export function ActionButtons({
  buttons = [
    {
      buttonStyle: "action",
      text: "Button",
      action: () => console.log("clicked!"),
      disabled: false,
    },
  ],
}) {
  return (
    <div className={styles.actionButtons}>
      {buttons.map(
        ({ buttonStyle, text, action, disabled, color, variant }) => (
          <Button
            key={text}
            buttonStyle={buttonStyle}
            text={text}
            action={action}
            disabled={disabled}
            color={color}
            variant={variant}
          />
        )
      )}
    </div>
  );
}

export default ActionButtons;
