import bindListenerToDocumentGlobal from "Blocks/utils/bindListenerToDocumentGlobal";

// Not sure what this is/was doing
// bindListenerToDocument("click", "js-select-title", (event) => {
//   event.target.parentElement.classList.toggle("is-open");
// });

export function initSelects() {
  // Open list on click and close all other selects' lists
  bindListenerToDocumentGlobal("click", "js-select", (event) => {
    const closed = !event.target.classList.contains("is-open");
    Array.from($(".js-select")).forEach((select) => {
      select.classList.remove("is-open");
    });
    if (closed && !event.target.classList.contains("is-disabled")) {
      event.target.classList.add("is-open");
    }
  });

  // Select list item
  bindListenerToDocumentGlobal("click", "js-select-item", (event) => {
    const selectContainer = event.target.closest(".js-select");
    const selectData =
      selectContainer.getElementsByClassName("js-select-data")[0];
    const selectInput =
      selectContainer.getElementsByClassName("js-select-input")[0];
    const selectUl =
      selectContainer.getElementsByClassName("js-select-list")[0];

    Array.from(selectUl.getElementsByClassName("js-select-item")).forEach(
      (item) => {
        item.classList.remove("is-selected");
      }
    );
    event.target.classList.add("is-selected");

    selectData.dataset.value = event.target.dataset.value;
    selectData.innerHTML = event.target.innerHTML;
    if (selectInput) {
      selectInput.value = event.target.dataset.value;
    }

    selectContainer.classList.remove("is-open");
    selectContainer.classList.add("has-value");
  });

  // Reset select
  bindListenerToDocumentGlobal("click", "js-select-reset", (event) => {
    const selectContainer = event.target.closest(".js-select");
    const selectData =
      selectContainer.getElementsByClassName("js-select-data")[0];
    const selectInput =
      selectContainer.getElementsByClassName("js-select-input")[0];
    const selectUl =
      selectContainer.getElementsByClassName("js-select-list")[0];

    Array.from(selectUl.getElementsByClassName("js-select-item")).forEach(
      (item) => {
        item.classList.remove("is-selected");
      }
    );

    selectData.dataset.value = "";
    selectData.innerHTML = "";
    if (selectInput) {
      selectInput.value = "";
    }

    selectContainer.classList.remove("has-value");
    selectContainer.classList.remove("is-open");
  });

  // Should this be focus?
  // Close selectors when not clicking inside of them
  document.addEventListener("click", (event) => {
    if ($(".js-select.is-open").length) {
      if (!event.target.closest(".js-select.is-open")) {
        Array.from($(".js-select.is-open")).forEach((select) => {
          select.classList.remove("is-open");
        });
      }
    }
  });
}

// Continue refactoring and adding methods to this object
export const selectMethods = {
  // Searches for selected items, sets values for the select element, and adds appropriate classes
  initializeSelects() {
    Array.from($(".js-select-item.is-selected")).forEach((selectedItem) => {
      const selectContainer = selectedItem.closest(".select");
      const selectData =
        selectContainer.getElementsByClassName("js-select-data")[0];
      const selectInput =
        selectContainer.getElementsByClassName("js-select-input")[0];

      selectContainer.classList.add("has-value");
      selectData.innerHTML = selectedItem.innerHTML;
      selectData.dataset.value = selectedItem.dataset.value;
      if (selectInput) {
        selectInput.value = selectedItem.dataset.value;
      }
    });
  },

  selectItemByValue(selectElement, itemValue) {
    const selectContainer = selectElement.closest(".select");
    const selectData =
      selectElement.getElementsByClassName("js-select-data")[0];
    const selectInput =
      selectContainer.getElementsByClassName("js-select-input")[0];
    const selectUl = selectElement.getElementsByClassName("js-select-list")[0];
    const selectItem = selectUl.querySelector(
      `.js-select-item[data-value="${itemValue}"]`
    );

    if (selectItem) {
      Array.from(selectUl.getElementsByClassName("js-select-item")).forEach(
        (item) => {
          item.classList.remove("is-selected");
        }
      );
      selectItem.classList.add("is-selected");

      selectData.dataset.value = itemValue;
      selectData.innerHTML = selectItem.innerHTML;
      if (selectInput) {
        selectInput.value = itemValue;
      }

      selectElement.classList.remove("is-open");
      selectElement.classList.add("has-value");
    }
  },

  deselect(selectElement) {
    const selectContainer = selectElement.closest(".select");
    const selectData = selectElement.querySelector(".js-select-data");
    const selectInput =
      selectContainer.getElementsByClassName("js-select-input")[0];

    selectElement.classList.remove("has-value");
    selectData.innerHTML = "";
    selectData.dataset.value = "";
    if (selectInput) {
      selectInput.value = "";
    }
    if (selectElement.querySelector(".js-select-item.is-selected")) {
      selectElement
        .querySelector(".js-select-item.is-selected")
        .classList.remove("is-selected");
    }
  },
};
