import React from "react";

export const TriangleIcon = () => (
  <svg
    width="50"
    height="58"
    viewBox="0 0 50 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_3593_17200)">
      <path
        d="M28.3482 22.5134C29.231 23.3079 29.231 24.6921 28.3482 25.4866L20 33L20 15L28.3482 22.5134Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3593_17200"
        x="0"
        y="0"
        width="49.0103"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.0862745 0 0 0 0 0.152941 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3593_17200"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3593_17200"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default TriangleIcon;
