import React from "react";
import ajax from "Blocks/utils/ajax";
import { downloadAsCSV } from "Blocks/utils/util";
import { renderSuccess } from "Blocks/alert";
import Download from "Components/blocks/icons/Download";
import PropTypes from "prop-types";

export default function ExportTimesheet({ timesheet }) {
  const { id, beginDate, profileFname, profileLname } = timesheet;

  // Date, fname, lname
  const fileName = () =>
    `Timesheet ${beginDate} ${profileLname}, ${profileFname}.csv`;

  const handleExport = () => {
    ajax({
      method: "POST",
      path: `/timesheets/export/${id}`,
      success: (response) => {
        downloadAsCSV(response, fileName());
        renderSuccess("Created CSV File");
      },
    });
  };

  return (
    <button
      type="button"
      className="header-actions__button"
      onClick={handleExport}
    >
      <Download />
      <span style={{ marginLeft: "8px" }}>Export</span>
    </button>
  );
}

ExportTimesheet.propTypes = {
  timesheet: PropTypes.shape({
    id: PropTypes.string,
    beginDate: PropTypes.string,
    profileFname: PropTypes.string,
    profileLname: PropTypes.string,
  }).isRequired,
};

ExportTimesheet.defaultProps = {};
