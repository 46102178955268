import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";

class OnboardingOrganization extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-create-organization", (event) => {
      this.toggleLoading(event);
    });
  }

  load() {}

  beforeCache() {}

  // Methods

  toggleLoading(event) {
    document.body.classList.add("is-loading");
  }
}

const onboardingOrganization = new OnboardingOrganization();
export default onboardingOrganization;
