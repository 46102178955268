import React, { useState } from "react";
import ajax from "Blocks/utils/ajax";
import PropTypes from "prop-types";
import { useGlobalState, updateTimesheetLock } from "./state/GlobalContext.jsx";

export default function LockButton({ permissions }) {
  const { state, dispatch } = useGlobalState();
  const { timesheet } = state;
  const { isLockable } = timesheet;
  const userCanLock = permissions.canEditAllTimesheets;

  const [isUnlocked, setIsUnlocked] = useState(timesheet.isUnlocked);
  const [isUpdating, setIsUpdating] = useState(false);

  if (!isLockable || !userCanLock) return null;

  const buttonText = () => {
    if (isUpdating) {
      return "Updating";
    }
    return isUnlocked ? "Lock" : "Unlock";
  };

  const handleToggleLock = () => {
    setIsUpdating(true);
    const newUnlockedState = !isUnlocked;
    const data = {
      is_unlocked: newUnlockedState,
    };

    ajax({
      method: "PUT",
      path: `/timesheets/${timesheet.id}`,
      data,
      success: () => {
        setIsUnlocked(newUnlockedState);
        dispatch(updateTimesheetLock({ isUnlocked: newUnlockedState }));
        setIsUpdating(false);
      },
    });
  };

  return (
    <button
      type="button"
      className="header-actions__button"
      onClick={handleToggleLock}
    >
      {buttonText()}
    </button>
  );
}

LockButton.propTypes = {};

LockButton.defaultProps = {};
