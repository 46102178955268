/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconErrorOutlinedProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconErrorOutlined = ({
  label,
  ...props
}: SvgIconErrorOutlinedProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2A10 10 0 1 1 2 12Zm2 0a8 8 0 1 0 16 0 8 8 0 0 0-16 0Zm7.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-.18-6h1.36a.5.5 0 0 1 .5.56l-.4 3.22a.25.25 0 0 1-.25.22h-1.06a.25.25 0 0 1-.25-.22l-.4-3.22a.5.5 0 0 1 .5-.56Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconErrorOutlined;
