import React from "react";

export class Toggle extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.props.disabled) {
      const value = !this.props.value;
      if (this.props.keyName) {
        this.props.onChange({
          [this.props.keyName]: value,
        });
      } else {
        this.props.onChange(value);
      }
    }
  }

  render() {
    return (
      <div
        className={`toggle${this.props.value ? " has-value" : ""}${
          this.props.disabled ? " is-disabled" : ""
        }`}
        onClick={this.onClick}
      >
        <div className="toggle__switch" />
        <label className="toggle__label">{this.props.label}</label>
      </div>
    );
  }
}

export default Toggle;
