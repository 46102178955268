import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { renderSuccess, renderError } from "Blocks/alert";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import ActionButtons from "Components/blocks/buttons/ActionButtons";
import Modal from "Components/blocks/modal/Modal";
import Allocation from "Components/blocks/allocation/Allocation";
import EditButtonTimesheet from "Components/blocks/buttons/EditButtonTimesheet";
import { Field, useModal } from "@plan";
import { useGlobalState } from "../state/GlobalContext";
import styles from "./project.module.css";

const UPDATE_ENTRY_GROUP_PHASE = gql`
  mutation updateEntryGroupPhase($id: ID!, $phaseId: ID!, $profileId: ID!) {
    updateEntryGroupPhase(
      input: { id: $id, phaseId: $phaseId, profileId: $profileId }
    ) {
      entryGroup {
        id
      }
    }
  }
`;

function ProjectGroupTitle({
  groupId,
  phasesData,
  profileData,
  hasOpenInvoice,
  timesheetLoading,
  permissions,
  currentProfile,
  timesheetIsLocked,
}) {
  if (timesheetLoading) return null;

  const [tooltip, tooltipNode] = useTooltip();

  const { state } = useGlobalState();
  const group = state.projectGroups.byId[groupId];

  const entriesForRow = (rowId) => {
    if (!state.entriesByRowId[rowId]) {
      return;
    }
    return Object.values(state.entriesByRowId[rowId]);
  };

  const entries = group.entryRows.map((rowId) => entriesForRow(rowId)).flat();

  const totalHours = entries.reduce(
    (totalHours, entry) => totalHours + Number(entry.hours),
    0
  );

  const phaseData = () => {
    const role = group.roleName ? (
      group.roleName
    ) : (
      <span className={styles.noRole}>No Assigned Role</span>
    );
    return (
      <div className={styles.groupRole}>
        {group.phaseAbbreviation} • {role}
      </div>
    );
  };

  const overage = totalHours > Number(group.allocation);

  const [modal, modalActions] = useModal(false);
  const [selectedPhase, setSelectedPhase] = useState({
    label: "",
    value: "",
  });

  const [updatePhase] = useMutation(UPDATE_ENTRY_GROUP_PHASE, {
    refetchQueries: ["timesheets"],
    onError: () => renderError(),
  });

  const [phaseName, setPhaseName] = useState({
    label: group.phaseName,
    value: group.phaseId,
  });

  const [entryGroupId, setEntryGroupId] = useState(group.id);

  const phases = phasesData?.project?.phases.map((phase) => ({
    label: phase.name,
    value: phase.id,
  }));

  const onSubmit = () => {
    updatePhase({
      variables: {
        id: entryGroupId,
        phaseId: selectedPhase.value,
        profileId: profileData?.profile?.id,
      },
      onCompleted: (data) => {
        setEntryGroupId(data.updateEntryGroupPhase.entryGroup.id);
        renderSuccess("Phase updated successfully");
        window.location.reload();
      },
    });
    setPhaseName({ label: selectedPhase.label });
    modalActions.close();
  };

  const toolTipText =
    "There are open invoices with expenses from this timesheet entry, so the phase and role cannot be changed.";

  const canEditTimesheet =
    permissions.canEditAllTimesheets ||
    currentProfile.id.toString() === profileData?.profile?.id;

  return (
    <>
      <div className={styles.groupHeader}>
        <div className={styles.groupTitle}>
          {phaseData()}
          <div className={styles.groupNameAndIcon}>
            <span className={styles.groupName}>{phaseName.label}</span>
            <span className={styles.groupIcon} ref={tooltipNode}>
              {canEditTimesheet && (
                <EditButtonTimesheet
                  handleClick={() => modalActions.open()}
                  disabled={hasOpenInvoice}
                  ghosted
                />
              )}
              {hasOpenInvoice && (
                <Tooltip
                  text={toolTipText}
                  tooltip={tooltip}
                  style={{
                    maxWidth: "210px",
                    textAlign: "center",
                  }}
                />
              )}
            </span>
          </div>
          {canEditTimesheet && (
            <Modal
              isOpen={modal}
              actions={modalActions}
              title="Change Phase"
              size="medium"
            >
              <Field
                as="select"
                label="Phase"
                name="phase"
                defaultValue={{
                  label: phaseName.label,
                  value: phaseName.value,
                }}
                placeholder="Select a phase"
                classes={styles.phase}
                required
                options={phases}
                handleChange={(e) => {
                  setSelectedPhase({
                    label: e.label,
                    value: e.value,
                  });
                }}
              />
              <ActionButtons
                buttons={[
                  {
                    color: "cancel",
                    text: "Cancel",
                    action: modalActions.close,
                  },
                  {
                    color: "action",
                    buttonType: "submit",
                    text: "Update",
                    action: onSubmit,
                  },
                ]}
              />
            </Modal>
          )}
        </div>
        <div className={styles.allocation}>
          <Allocation
            progress={totalHours}
            total={Number(group.allocation)}
            overage={overage}
          />
        </div>
      </div>
    </>
  );
}

export default ProjectGroupTitle;
