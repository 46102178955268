import { config, TCSS } from "./stitches.config";
import { textStyles } from "./typography";

export const createTextStyleVariant = () =>
  Object.keys(textStyles).reduce(
    (acc, textStyle) => ({
      ...acc,
      [textStyle]: { textStyle },
    }),
    {} as Record<keyof typeof textStyles, TCSS>
  );

const { theme } = config;

type Theme = typeof theme;
type ScaleKey<Scale extends keyof Theme> = keyof Theme[Scale];

type ScaleVariant<Scale extends keyof Theme> = Record<ScaleKey<Scale>, TCSS>;
type ExtendVariant<Extend> = Record<keyof Extend, TCSS>;

type CreateScaleVariantToken<Scale extends keyof Theme, Extend> =
  | ScaleKey<Scale>
  | Extend[keyof Extend];
type CreateScaleVariantOptions<Scale extends keyof Theme, Extend> = {
  scale: Scale;
  extend?: Extend;
  css: (token: CreateScaleVariantToken<Scale, Extend>) => TCSS;
};
type CreateScaleVariantReturn<
  Scale extends keyof Theme,
  Extend
> = ScaleVariant<Scale> & ExtendVariant<Extend>;

export const createScaleVariant = <
  Scale extends keyof Theme,
  Extend extends Record<keyof Extend, string | number>
>({
  scale,
  extend,
  css,
}: CreateScaleVariantOptions<Scale, Extend>): CreateScaleVariantReturn<
  Scale,
  Extend
> => {
  const scaleOnly = typeof extend === "undefined";

  const scaleVariants = Object.keys(theme[scale]).reduce(
    (acc, key) => ({
      ...acc,
      [key]: css(`$${scale}$${key}` as CreateScaleVariantToken<Scale, Extend>),
    }),
    {} as ScaleVariant<Scale>
  );

  const extendedVariants = scaleOnly
    ? ({} as ExtendVariant<Extend>)
    : Object.entries(extend).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: css(value as CreateScaleVariantToken<Scale, Extend>),
        }),
        {} as ExtendVariant<Extend>
      );

  return Object.assign({}, scaleVariants, extendedVariants);
};
