import React from "react";
import { useModal } from "Components/blocks/modal/useModal";
import DeleteModal from "./DeleteModal.jsx";
import styles from "./timesheet.module.css";

function DeleteButton({
  deleteHandler,
  type,
  name,
  timesheetId,
  projectId,
  rowId,
  disabled,
}) {
  const [modal, modalActions] = useModal(false);

  return (
    <>
      <button
        className={styles.deleteButton}
        onClick={modalActions.open}
        disabled={disabled}
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.26602 4L0 7.26602L0.733979 8L4 4.73398L7.26602 8L8 7.26602L4.73398 4L8 0.733979L7.26602 0L4 3.26602L0.733979 0L0 0.733979L3.26602 4Z"
            className={
              disabled ? styles.deleteButtonSvgDisabled : styles.deleteButtonSvg
            }
          />
        </svg>
      </button>
      <DeleteModal
        isOpen={modal}
        handleDelete={deleteHandler}
        subHeading={type}
        actions={modalActions}
        name={name}
        timesheetId={timesheetId}
        type={type}
        projectId={projectId}
        rowId={rowId}
      />
    </>
  );
}

export default DeleteButton;
