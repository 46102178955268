import Select from "Components/blocks/Select";
import InvoiceDateFilter from "Components/invoices/InvoiceDateFilter";
import classNames from "classnames";
import util from "Blocks/utils/util";
import React from "react";
import numeral from "numeral";
import { eventTracker } from "Blocks/tracker";
import _map from "lodash/map";
import { ExportCSVWrapper } from "./ExportCSV";
import { QuickBooksButtonWrapper } from "./QuickbooksButton";

export default class InvoiceControls extends React.Component {
  constructor(props) {
    super(props);

    // Eventually I think we'll want the null value option to be a
    // default method that disappears once something is selected.
    this.selectOptions = [
      // {value: null, name: "Select a method"},
      { value: "Activity", name: "Activity" },
      { value: "Role", name: "Role" },
      { value: "Phase", name: "Phase" },
    ];
  }

  generateReportRoute(reportPath, params) {
    const data = { ...params };
    const { invoice, projectSlug } = this.props;

    if (invoice.begin_date) {
      data.begin_date = invoice.begin_date;
    }
    if (invoice.end_date) {
      data.end_date = invoice.end_date;
    }
    if (invoice.phase_id) {
      data.phase = invoice.phase_id;
    }

    return `/projects/${projectSlug}/${reportPath}?${util.serialize(data)}`;
  }

  renderHeader() {
    const headerStyle = this.props.showControls ? {} : { opacity: "0" };

    if (this.props.display === "preview") {
      return (
        <div className="invoice-controls__header" style={headerStyle}>
          <h3 className="invoice-controls__title">Invoice Preview</h3>
          <p className="invoice-controls__description">
            Double check your numbers, and when you're ready print and/or send
            it to your bookkeeping software.
          </p>
          <h5 className="invoice-controls__subtitle">Actions</h5>
          <div className="invoice-controls__actions">
            <button className="invoice-controls__button" onClick={window.print}>
              Print Invoice
            </button>
            {this.props.organizationIsConnectedToQbo && (
              <QuickBooksButtonWrapper
                client={this.props.client}
                lineItemCount={this.props.lineItems.count}
                sendInvoice={this.props.sendInvoice}
              />
            )}
          </div>
          <ExportCSVWrapper slug={this.props.invoice.slug} />
        </div>
      );
    }
    return (
      <div className="invoice-controls__header" style={headerStyle}>
        {this.props.display === "edit" ? (
          <Select
            label="Invoice By"
            selectedValue={this.props.invoice.type}
            items={this.selectOptions}
            onSelect={this.props.updateType}
          />
        ) : (
          <>
            <div className="invoice-controls__preview">
              <h3 className="invoice-controls__title">Preview Your Invoice</h3>
              <p className="invoice-controls__description">
                Double check your numbers, and when you're ready print and/or
                send it to your bookkeeping software.
              </p>
              <h5 className="invoice-controls__subtitle">Actions</h5>
              <div className="invoice-controls__actions">
                <button
                  className="invoice-controls__button"
                  onClick={window.print}
                >
                  Print Invoice
                </button>
                {this.props.organizationIsConnectedToQbo && (
                  <QuickBooksButtonWrapper
                    client={this.props.client}
                    lineItemCount={this.props.lineItems.count}
                    sendInvoice={this.props.sendInvoice}
                  />
                )}
              </div>
              <ExportCSVWrapper slug={this.props.invoice.slug} />
            </div>
            <div className="invoice-controls__text">
              <h3 className="invoice-controls__kicker">Invoicing Method</h3>
              <p className="invoice-controls__display">
                By {this.props.invoice.type}
              </p>
            </div>
          </>
        )}

        {this.renderConditionalControls()}
      </div>
    );
  }

  // There theoretically can still be some issues here.
  // If someone creates an invoice for a project before there are any phases,
  // and then adds phases, there will be no default phase on the invoice and
  // this page will break.
  renderConditionalControls() {
    if (this.props.invoice.type === null) {
      return null;
    }
    if (this.props.display === "edit") {
      if (this.props.invoice.type === "Phase") {
        return (
          <InvoiceDateFilter
            updateDate={this.props.updateInvoice}
            begin_date={this.props.invoice.begin_date}
            end_date={this.props.invoice.end_date}
          />
        );
      }
      return (
        <>
          <Select
            label="Phase"
            selectedValue={this.props.invoice.phase_id}
            items={[{ name: "All Phases", value: null }].concat(
              _map(this.props.assignments, (assignment) => {
                return {
                  value: Number(assignment.id),
                  name: assignment.name,
                };
              })
            )}
            onSelect={this.props.updateInvoice}
            keyName="phase_id"
          />
          <InvoiceDateFilter
            updateDate={this.props.updateInvoice}
            begin_date={this.props.invoice.begin_date}
            end_date={this.props.invoice.end_date}
          />
        </>
      );
    }
    return (
      <div className="invoice-controls__text">
        <h3 className="invoice-controls__kicker">Date Range</h3>
        <p className="invoice-controls__display">
          {this.props.invoice.begin_date} – {this.props.invoice.end_date}
        </p>
      </div>
    );
  }

  renderApplyButton() {
    if (this.props.invoice !== "Phase") {
      let content;
      if (this.props.invoice.type === "Activity") {
        content = "Add All Activities";
      } else if (this.props.invoice.type === "Role") {
        content = "Add All Roles";
      } else {
        return "";
      }

      let disabled = false;
      if (this.props.projectDataCount === 0) {
        disabled = true;
      }

      let onClick;
      if (this.props.lineItems.length > 0) {
        onClick = () => this.props.updateState({ showPopulateModal: true });
      } else {
        onClick = () =>
          this.props.createLineItemsFromProjectData({ overwrite: true });
      }

      return (
        <button
          className="invoice-controls__button"
          onClick={onClick}
          disabled={disabled}
        >
          {content}
        </button>
      );
    }
  }

  renderApplyExpensesButton() {
    if (this.props.expenseData.length > 0) {
      return (
        <button
          className="invoice-controls__button"
          onClick={() => this.props.createLineItemsFromExpenses()}
        >
          Add All Expenses
        </button>
      );
    }
  }

  renderPhaseItem(phase) {
    return (
      <>
        <div className="invoice-item__header">
          {this.renderPhaseItemHeader(phase)}
        </div>
        <div className="invoice-item__content">
          <ul className="invoice-sublist">
            {phase.pastData.map((pastDatum) => {
              if (
                phase.budget &&
                phase.fee_type === "fixed" &&
                pastDatum.quantity
              ) {
                return (
                  <li
                    className="invoice-subitem"
                    key={phase.name + pastDatum.id}
                  >
                    <span className="invoice-subitem__date">
                      {pastDatum.date}
                    </span>
                    <span className="invoice-subitem__number">
                      #{pastDatum.number}
                    </span>
                    <span className="invoice-subitem__total">
                      {util.toCurrency(parseFloat(pastDatum.total))}
                    </span>
                    <span className="invoice-subitem__percent">
                      {numeral(pastDatum.quantity * 100).format("0,0[.][00]")}%
                    </span>
                  </li>
                );
              }
              return (
                <li className="invoice-subitem" key={phase.name + pastDatum.id}>
                  <span className="invoice-subitem__date">
                    {pastDatum.date}
                  </span>
                  <span className="invoice-subitem__number">
                    #{pastDatum.number}
                  </span>
                  <span className="invoice-subitem__total">
                    {util.toCurrency(parseFloat(pastDatum.total))}
                  </span>
                  <span className="invoice-subitem__percent">–</span>
                </li>
              );
            })}
          </ul>
          {this.renderPhaseActions(phase)}
        </div>
      </>
    );
  }

  renderPhaseItemHeader(phase) {
    if (phase.budget) {
      return (
        <>
          <span
            className="invoice-item__title"
            onClick={(event) =>
              event.target
                .closest(".invoice-item")
                .classList.toggle("is-expanded")
            }
          >
            {phase.name}
          </span>
          <span className="invoice-item__amount">{`${util.toCurrency(
            phase.total
          )} / ${util.toCurrency(phase.budget)}`}</span>
          <span className="invoice-item__percent">
            {numeral((phase.total / phase.budget) * 100).format("0,0[.][00]")}%
          </span>
          {this.renderPageItemToggle()}
        </>
      );
    }
    return (
      <>
        <span className="invoice-item__title">{phase.name}</span>
        <span className="invoice-item__amount">
          {util.toCurrency(phase.total)} / –
        </span>
        <span className="invoice-item__percent">–</span>
        {this.renderPageItemToggle()}
      </>
    );
  }

  renderPhaseActions(phase) {
    if (this.props.editable) {
      let actions;
      if (phase.budget && phase.fee_type === "fixed") {
        actions = (
          <div className="invoice-actions__buttons">
            <div className="invoice-actions__button-group">
              <input
                name="Percent"
                type="number"
                value={numeral(
                  numeral(phase.in_given_time.percent).value() * 100
                ).format("0[.][00]")}
                onChange={(event) =>
                  this.props.updatePhasePercent(
                    phase.id,
                    parseFloat(event.target.value)
                  )
                }
                className="invoice-actions__input"
                min="0"
                max="100"
                onInput={this.onChange}
              />
              <button
                onClick={() => this.props.createLineItemFromPhase(phase.id)}
                className="invoice-actions__button"
              >
                Add to Invoice
              </button>
            </div>
            <button
              className="invoice-actions__button"
              onClick={() => this.props.createLineItemFromPhase(phase.id, true)}
            >
              Close Out Phase
            </button>
          </div>
        );
      } else {
        actions = (
          <div className="invoice-actions__buttons">
            <button
              onClick={() => this.props.createLineItemFromPhase(phase.id)}
              className="invoice-actions__button"
            >
              Add to Invoice
            </button>
          </div>
        );
      }

      return (
        <div className="invoice-actions">
          <p className="invoice-actions__description">
            You've consumed{" "}
            <strong>
              {numeral(phase.in_given_time.hours).format("0,0[.][00]")} hours /{" "}
              {util.toCurrency(parseFloat(phase.in_given_time.budget))}
            </strong>{" "}
            in the given time period.
          </p>
          {actions}
        </div>
      );
    }
  }

  renderExpenseReportRoute() {
    return this.generateReportRoute("expenses", { billable: true });
  }

  renderReportRoute() {
    return this.generateReportRoute("time-log", { display: "activities" });
  }

  renderExpenses() {
    return (
      <>
        <div className="invoice-list__header">
          <h4 className="invoice-list__title">Expenses</h4>
          <a
            className="invoice-list__link"
            href={this.renderExpenseReportRoute()}
            target="_blank"
          >
            View Expense Report
          </a>
        </div>
        <ul className="invoice-list invoice-list--roles invoice-list--expenses">
          {this.props.expenseData.map((expense) => {
            return (
              <li className="invoice-item" key={expense.id}>
                <div className="invoice-item__header">
                  <span className="invoice-item__category invoice-item__category--expense">
                    {expense.category}
                  </span>
                  <span className="invoice-item__amount">
                    {util.toCurrency(expense.amount)}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  renderExpenseDownloads() {
    // This is in .invoice-controls__contnet, but it doesn't necessarily need to be, it can be in the header too.
    const expensesWithAttachments = this.props.expenses.filter(
      (expense) => expense.attachment
    );

    if (expensesWithAttachments.length > 0) {
      return (
        <>
          <h5 className="invoice-controls__subtitle">Downloads</h5>
          <ul>
            {expensesWithAttachments.map((expense) => {
              return (
                <li key={expense.id}>
                  <a
                    href={expense.attachment}
                    rel="nofollow"
                    download=""
                    target="_blank"
                  >
                    <span>{util.massageFileName(expense.attachment)}</span>
                    <button>&#8681;</button>
                  </a>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  }

  renderContent() {
    const contentStyle = this.props.showControls ? {} : { opacity: "0" };

    if (this.props.display === "preview") {
      return (
        <div className="invoice-controls__content" style={contentStyle}>
          {this.renderExpenseDownloads()}
        </div>
      );
    }
    if (this.props.loading === true) {
      return (
        <div className="invoice-controls__placeholder" style={contentStyle}>
          Loading...
        </div>
      );
    }
    if (this.props.method === null) {
      return (
        <div className="invoice-controls__placeholder" style={contentStyle}>
          No invoicing method selected
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <>
          <div className="invoice-controls__placeholder" style={contentStyle}>
            No data for selected dates
          </div>
          {this.renderExpenses()}

          {this.props.display === "edit"
            ? this.renderApplyExpensesButton()
            : null}
        </>
      );
    }
    if (this.props.method === "Activity") {
      return (
        <div className="invoice-controls__content" style={contentStyle}>
          <div className="invoice-list__header">
            <h4 className="invoice-list__title">Activities</h4>
            <a
              className="invoice-list__link"
              href={this.renderReportRoute()}
              target="_blank"
            >
              View Activity Report
            </a>
          </div>
          <ul className="invoice-list invoice-list--activities">
            {this.props.data.map((activity) => {
              return (
                <li className="invoice-item" key={activity.name}>
                  <div className="invoice-item__header">
                    <span
                      className="invoice-item__title"
                      onClick={(event) =>
                        event.target
                          .closest(".invoice-item")
                          .classList.toggle("is-expanded")
                      }
                    >
                      {activity.name}
                    </span>
                    <span className="invoice-item__hours">
                      {activity.hours}
                    </span>
                    <span className="invoice-item__amount">
                      {util.toCurrency(activity.amount)}
                    </span>
                    {this.renderPageItemToggle()}
                  </div>
                  <div className="invoice-item__content">
                    <ul className="invoice-sublist">
                      {activity.roles.map((role) => {
                        return (
                          <li
                            className="invoice-subitem"
                            key={activity.name + role.name}
                          >
                            <span className="invoice-subitem__title">
                              {role.name}
                            </span>
                            <span className="invoice-subitem__rate">
                              {util.toCurrency(role.rate)}
                            </span>
                            <span className="invoice-subitem__hours">
                              {role.hours}
                            </span>
                            <span className="invoice-subitem__amount">
                              {util.toCurrency(role.amount)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul>

          {this.props.display === "edit" ? this.renderApplyButton() : null}

          {this.renderExpenses()}

          {this.props.display === "edit"
            ? this.renderApplyExpensesButton()
            : null}
        </div>
      );
    }
    if (this.props.method === "Role") {
      return (
        <div className="invoice-controls__content" style={contentStyle}>
          <div className="invoice-list__header">
            <h4 className="invoice-list__title">Roles</h4>
            <a
              className="invoice-list__link"
              href={this.renderReportRoute()}
              target="_blank"
            >
              View Roles Report
            </a>
          </div>
          <ul className="invoice-list invoice-list--roles">
            {this.props.data.map((role) => {
              return (
                <li className="invoice-item" key={role.name + role.rate}>
                  <div className="invoice-item__header">
                    <span
                      className="invoice-item__title"
                      onClick={(event) =>
                        event.target
                          .closest(".invoice-item")
                          .classList.toggle("is-expanded")
                      }
                    >
                      {role.name}
                    </span>
                    <span className="invoice-item__rate">
                      {util.toCurrency(role.rate)}
                    </span>
                    <span className="invoice-item__hours">{role.hours}</span>
                    <span className="invoice-item__amount">
                      {util.toCurrency(role.amount)}
                    </span>
                    {this.renderPageItemToggle()}
                  </div>
                  <div className="invoice-item__content">
                    <ul className="invoice-sublist">
                      {role.activities.map((activity) => {
                        return (
                          <li
                            className="invoice-subitem"
                            key={role.name + activity.name}
                          >
                            <span className="invoice-subitem__title">
                              {activity.name}
                            </span>
                            <span className="invoice-subitem__no-rate" />
                            <span className="invoice-subitem__hours">
                              {activity.hours}
                            </span>
                            <span className="invoice-subitem__amount">
                              {util.toCurrency(activity.amount)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul>

          {this.props.display === "edit" ? this.renderApplyButton() : null}

          {this.renderExpenses()}

          {this.props.display === "edit"
            ? this.renderApplyExpensesButton()
            : null}
        </div>
      );
    }
    if (this.props.method === "Phase") {
      return (
        <div className="invoice-controls__content" style={contentStyle}>
          <div className="invoice-list__header">
            <h4 className="invoice-list__title">Phases</h4>
            <a
              className="invoice-list__link"
              href={this.renderReportRoute()}
              target="_blank"
            >
              View Phases Report
            </a>
          </div>
          <ul className="invoice-list invoice-list--phases">
            {this.props.data.map((phase) => {
              return (
                <li
                  className={classNames({
                    "invoice-item": true,
                    "is-expanded": phase.id === this.props.phaseId,
                  })}
                  key={phase.name}
                >
                  {this.renderPhaseItem(phase)}
                </li>
              );
            })}
          </ul>

          {this.renderExpenses()}

          {this.props.display === "edit"
            ? this.renderApplyExpensesButton()
            : null}
        </div>
      );
    }
  }

  renderPageItemToggle() {
    return (
      <span
        className="invoice-item__toggle"
        onClick={(event) =>
          event.target.closest(".invoice-item").classList.toggle("is-expanded")
        }
      />
    );
  }

  render() {
    let invoiceControlsStyle = {};
    if (!this.props.showControls) {
      invoiceControlsStyle = {
        // transform: 'translateX(-35rem)', // probably can't hardcode 35rem
        transform: "none",
        maxWidth: "2rem",
        transition: "all 160ms ease-out",
      };
    }
    return (
      <div className="invoice-controls" style={invoiceControlsStyle}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}
