import { TimerEntryFragment } from "../../../../../apps/web/packages/app/generated/graphql";

const entryHoursToMs = (entry?: TimerEntryFragment) => {
  if (!entry?.hours) return 0;

  return entry.hours * 60 * 60 * 1000;
};

const recordingDurationMs = (recordingStartTime?: number) => {
  if (!recordingStartTime) return 0;

  return Date.now() - recordingStartTime;
};

export const millisecondsToFixedHours = (milliseconds: number) => {
  const timeHours = !milliseconds ? 0 : milliseconds / 3600 / 1000;
  return (Math.round(timeHours / 0.01) * 0.01).toFixed(2);
};

export const getRecordingDisplayTime = (
  entry?: TimerEntryFragment,
  recordingStartTime?: number
) => {
  const recordingMs = recordingDurationMs(recordingStartTime);
  const storedEntryTimeMs = entryHoursToMs(entry);
  const totalRecordedTimeMs = recordingMs + storedEntryTimeMs;

  return millisecondsToFixedHours(totalRecordedTimeMs);
};

export const getLostTimeEntryHours = (
  entry: TimerEntryFragment,
  lostTimeMs: number,
  recordingStartTime: number
) => {
  const storedEntryTimeMs = entryHoursToMs(entry);
  const whenTheyLeftTheSiteMs = Date.now() - lostTimeMs;
  const recordingDurationMs = whenTheyLeftTheSiteMs - recordingStartTime;

  const newEntryTimeHours = millisecondsToFixedHours(
    storedEntryTimeMs + recordingDurationMs
  );

  return parseFloat(newEntryTimeHours);
};
