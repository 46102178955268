import React, { useCallback, useState } from "react";
import { string, func, arrayOf, shape, bool } from "prop-types";
import Select from "Components/blocks/Select";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import classNames from "classnames/bind";
import styles from "./InvoiceAssignmentSelector.module.css";

const cx = classNames.bind(styles);

const fakeExpense = {
  name: "Expense",
  id: "expense",
  type: "expense",
};

const fakeOtherItem = {
  name: "Other",
  id: "other",
  type: "other",
};

const getAssignmentParams = (assignments, assignmentId) => {
  const currentAssignment = assignments.find((a) => a.id === assignmentId);
  const { type } = currentAssignment;
  const defaults = {
    is_v1_other_item: false,
    is_v1_expense: false,
    role_id: null,
    phase_id: null,
    consultant_id: null,
  };
  switch (type) {
    case "fixed":
      return {
        ...defaults,
        phase_id: assignmentId,
      };

    case "hourly":
      return {
        ...defaults,
        phase_id: currentAssignment.phase_id,
        role_id: assignmentId,
      };

    case fakeOtherItem.type:
      return {
        ...defaults,
        is_v1_other_item: true,
      };

    case fakeExpense.type:
      return {
        ...defaults,
        is_v1_expense: true,
      };

    case "consultant":
      return { ...defaults, consultant_id: assignmentId };

    default:
      return {};
  }
};

const assignmentsToOptions = (assignments, currentId) =>
  (assignments || []).map(({ id, name }) => ({
    value: id,
    name,
    ...(String(id) === String(currentId)
      ? { className: styles.selectedItem }
      : {}),
  }));

const getCurrentId = (
  isOtherItem,
  isExpense,
  consultantId,
  roleId,
  phaseId
) => {
  if (isOtherItem) return fakeOtherItem.id;
  if (isExpense) return fakeExpense.id;
  if (consultantId) return consultantId;
  if (roleId) return roleId;
  if (phaseId) return phaseId;
  return null;
};

const InvoiceAssignmentSelector = ({
  assignments = [],
  onChange,
  isOtherItem,
  isExpense,
  consultantId,
  roleId,
  phaseId,
}) => {
  const assignmentsWithFakes = assignments.concat(fakeExpense, fakeOtherItem);
  const [tooltipProps, tooltipRef] = useTooltip();
  const [showTooltip, setShowTooltip] = useState(false);
  const [newerShowTooltip, setNeverShowTooltip] = useState(false);

  const currentId = getCurrentId(
    isOtherItem,
    isExpense,
    consultantId,
    roleId,
    phaseId
  );

  const options = assignmentsToOptions(assignmentsWithFakes, currentId);

  const empty = {};
  const currentOption =
    options.find((o) => o.value === String(currentId)) || empty;

  const handleChange = useCallback(
    (id) => {
      onChange(getAssignmentParams(assignmentsWithFakes, id));
      setShowTooltip(false);
      setNeverShowTooltip(false);
    },
    [onChange, assignments]
  );

  const handleLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);
  const handleEnter = useCallback(() => setShowTooltip(true), []);

  const shouldShowTooltip =
    !!currentOption.name &&
    !newerShowTooltip &&
    currentOption.name !== fakeOtherItem.name &&
    currentOption.name !== fakeExpense.name;

  return (
    <div
      className={cx("invoiceAssignmentSelector", {
        empty: !currentId || currentOption === empty,
      })}
      ref={tooltipRef}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onClick={() => setNeverShowTooltip(true)}
    >
      <Select
        name="phase"
        size="small"
        label="Assignment"
        selectedValue={currentOption.name}
        items={options}
        onSelect={handleChange}
        required
        showCurrentValue
      />
      {shouldShowTooltip && (
        <Tooltip tooltip={{ ...tooltipProps, shown: showTooltip }}>
          <div className={styles.tooltipContent}>{currentOption.name}</div>
        </Tooltip>
      )}
    </div>
  );
};

InvoiceAssignmentSelector.propTypes = {
  onChange: func.isRequired,
  assignments: arrayOf(shape({ name: string, id: string, role_id: string })),
  isOtherItem: bool,
  isExpense: bool,
  consultantId: string,
  phaseId: string,
  roleId: string,
};

export default InvoiceAssignmentSelector;
