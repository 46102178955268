import { useState, useEffect, useCallback } from "react";
import { useFirstMountState } from "react-use";
import util from "Utils/util";

// Hook Reference: https://usehooks.com/useDebounce/
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

// Hook for debounced saving state to API
export function useDebouncedSave(
  value,
  callback,
  { delay = 2000, utilSave = false }
) {
  const isInitialRender = useFirstMountState();
  const [isSaving, setSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState();
  const debouncedValue = useDebounce(value, delay);

  const saving = (saveStatus) => {
    if (utilSave && saveStatus) util.startSave();
    if (utilSave && !saveStatus) util.endSave();
    setSaving(saveStatus);
  };

  const runCallback = useCallback(async () => {
    // We don't need to save when the component mounts
    if (isInitialRender) return;
    saving(true);
    await callback();
    saving(false);
  }, [isInitialRender, callback]);

  // Only save after debounce
  useEffect(() => {
    runCallback();
    setLastSaved(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return [isSaving, lastSaved];
}

export default useDebounce;
