import { CSSProperties, ReactNode } from "react";

import { styled } from "@plan/core";

import { AutoLayout } from "../../../layout/src/AutoLayout";
import { Box } from "../../../layout/src/Box";
import { Avatar } from "../Avatar";

const StepLayout = styled(AutoLayout, {
  alignItems: "center !important",
  gap: "$space$3_5",
  marginBottom: "$space$9",
});

export type ListItemProps = {
  content: ReactNode;
  shouldShow?: boolean;
};

type ListProps = {
  list: ListItemProps[];
  css?: CSSProperties;
};

export const List = ({ list, css }: ListProps) => {
  const filteredList = list.filter(({ shouldShow = true }) => !!shouldShow);

  return (
    <Box>
      {filteredList.map((listItem, index) => (
        <StepLayout key={index} css={{ ...css }}>
          <Avatar
            initials={`${index + 1}`}
            shape="circle"
            fallbackColor="-brand40"
          />
          <Box css={{ width: "100%", textAlign: "left" }}>
            {listItem.content}
          </Box>
        </StepLayout>
      ))}
    </Box>
  );
};
