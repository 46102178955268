import { FC, useState } from "react";
import { useMount } from "react-use";

import { styled } from "@plan/core";
import { Flex } from "@plan/layout";

import { TimerModal } from "./components/Modal";
import { TimerSidebarNavItem } from "./components/TimerSidebarNavItem";
import { useTimerContext } from "./contexts/TimerContext";

const TimerContainer = styled(Flex, {
  alignItems: "center",
  position: "relative",
  height: "2.5em",
  padding: "0.5em 0.25em",
  margin: "1em 1em 0",
  color: "$white",
  border: "1px solid #213342", // put in our color scheme?
  borderRadius: "$radii$default",
  cursor: "pointer",
  "@bpXl": {
    height: "$space$12",
    margin: "1.375rem $space$5 $space$1",
    padding: "$space$3 $space$2",
  },
  variants: {
    collapsed: {
      true: {
        border: "none",
        padding: "0",
      },
    },
  },
});

export interface TimerProps {
  sidebarOpen: boolean;
}

export const Timer: FC<TimerProps> = ({ sidebarOpen }) => {
  const {
    state: { isRecording, recordingDisplayTime },
  } = useTimerContext();

  const [originalTitle, setOriginalTitle] = useState<string>("Monograph");

  // Wait until it's mounted in the browser to render anything,
  // or else SSR makes things weird
  const [hasMounted, setHasMounted] = useState(false);
  useMount(() => {
    setHasMounted(true);
    setOriginalTitle(document.title);
  });

  if (!hasMounted) {
    return <></>;
  } else {
    if (isRecording) {
      document.title = `► ${recordingDisplayTime} hrs — Monograph`;
    } else if (parseFloat(recordingDisplayTime)) {
      document.title = `◼ ${recordingDisplayTime} hrs — Monograph`;
    } else {
      document.title = originalTitle;
    }
  }

  return (
    <TimerContainer collapsed={!sidebarOpen}>
      <TimerModal />
      <TimerSidebarNavItem sidebarOpen={sidebarOpen} />
    </TimerContainer>
  );
};
