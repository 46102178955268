import { useState, useCallback } from "react";
import { useKeyPressEvent } from "react-use";

export function useModal(initial = false) {
  const [state, setState] = useState(initial);

  const toggle = useCallback(() => setState((prev) => !prev), [setState]);
  const open = useCallback(() => setState(true), [setState]);
  const close = useCallback(() => setState(false), [setState]);

  const actions = {
    toggle,
    open,
    close,
  };

  useKeyPressEvent("Escape", () => actions.close());

  return [state, actions];
}

export default useModal;
