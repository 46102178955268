import React, { useMemo, useEffect } from "react";
import orderBy from "lodash/orderBy";

export const PhaseOrderContext = React.createContext([]);

export default function PhaseOrderProvider({
  allIds,
  byId,
  projectSort,
  selectedPhase,
  setSelectedPhaseId,
  gridView,
  allocations,
  children,
}) {
  // When a sort option is selected, reorder the phases
  const phaseOrder = useMemo(() => {
    if (projectSort === "number")
      return orderBy(
        allIds,
        [(id) => byId[id].number, (id) => byId[id].abbreviation],
        ["asc", "asc"]
      );
    if (projectSort === "name")
      return orderBy(
        allIds,
        [(id) => byId[id].name.toLowerCase(), (id) => byId[id].abbreviation],
        ["asc", "asc"]
      );
  }, [byId, allIds, projectSort]);

  const phaseHasAllocations = (id) =>
    Object.keys(allocations).filter(
      (allocId) => allocations[allocId].phaseId === id
    );

  const getPhasesWithAllocations = (phaseList) =>
    phaseList.filter((phase) => phaseHasAllocations(phase).length > 0);

  const reorderWhenSelected = (phaseList, selected, view) => {
    if (selected && view === "column") {
      const selectedList = phaseList.filter((item) => item === selected);
      const unselectedList = phaseList.filter((item) => item !== selected);
      return [...selectedList, ...unselectedList];
    }
    return phaseList;
  };

  // Bring the selected phase to the top when in the column view
  const displayPhases = useMemo(() => {
    const phaseList = getPhasesWithAllocations(phaseOrder);
    return reorderWhenSelected(phaseList, selectedPhase, gridView);
  }, [phaseOrder, getPhasesWithAllocations, selectedPhase, gridView]);

  // If a user has selected a phase, and that phase has no allocations,
  // (for instance, deletes the last allocation) clear the selection
  useEffect(() => {
    if (selectedPhase) {
      const selectedPhaseAllocs = phaseHasAllocations(selectedPhase);
      if (selectedPhaseAllocs.length === 0) setSelectedPhaseId("");
    }
  }, [displayPhases, selectedPhase]);

  return (
    <PhaseOrderContext.Provider value={displayPhases}>
      {children}
    </PhaseOrderContext.Provider>
  );
}
