import React, { useMemo } from "react";
import PropTypes from "prop-types";
import util, { formatCurrencyNoSymbol } from "Utils/util";
import numeral from "numeral";
import styles from "./currency.module.css";

const formatters = {
  // 1 => $1 / 1.60 => $1.60
  default: util.toCurrency,
  // 1 => $1 / 1.60 => $2
  noCents: util.toCurrencyWithoutCents,
  // 1 => $1.00 / 1.60 => $1.60
  withCents: util.toCurrencyWithCents,
  // 1000 --> 1,000
  noSymbol: formatCurrencyNoSymbol,
  // 1000 --> 1K
  abbreviated: (value) => numeral(value).format("($0.[00]a)"),
  // using locale to format decimal, returns value without symbol
};

function formatCurrency(format, value) {
  const selectedFormat = formatters[format];
  return selectedFormat(value);
}

export function Currency({ value: initialVal, format, forSvg }) {
  // clean up the value that comes in from props (sometimes is a string with a comma, etc)
  const value = numeral(initialVal).format("0.[00]");

  const isNegative = value < 0;
  const amount = useMemo(() => formatCurrency(format, value), [format, value]);

  // render in tspan instead of spans for SVGs (charting)
  if (forSvg)
    return (
      <tspan className={isNegative ? styles.negative : ""}>{amount}</tspan>
    );
  return <span className={isNegative ? styles.negative : ""}>{amount}</span>;
}

Currency.propTypes = {
  format: PropTypes.oneOf([
    "default",
    "noCents",
    "noSymbol",
    "withCents",
    "abbreviated",
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Currency.defaultProps = {
  format: "default",
  value: 0,
};

export default Currency;
