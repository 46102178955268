import React, { useState } from "react";
import moment from "moment";
import { GlobalProvider } from "./state/GlobalContext.jsx";
import Timesheet from "./Timesheet.jsx";

export function TimesheetGlobal({
  dateFormatted,
  editData,
  profile,
  permissions,
  activityRequired,
  noteExample,
  notesRequired,
  currentProfile,
  timeOffRequests,
  timeOffHolidays,
  timeOffEnabled,
  timeOffHolidayEnabled,
}) {
  const newData = editData;

  const [data, setTimesheetData] = useState(newData);
  const date = moment(dateFormatted);

  const references = {};
  data.selectedProjects.allIds.forEach((projId) => {
    references[projId] = React.createRef();
  });
  if (!references.overhead) {
    references.overhead = React.createRef();
  }

  const overheadOptions = data.overheadOptions.map((overhead) => ({
    label: overhead.name,
    value: overhead.id,
  }));

  return (
    <GlobalProvider
      timesheet={data.timesheet}
      timeOffRequests={data.timeOffRequests}
      timeOffHolidays={data.timeOffHolidays}
      date={date}
      entriesByRowId={data.entriesByRowId}
      expenses={data.expenses}
      rows={data.rows}
      projectGroups={data.projectGroups}
      selectedProjects={data.selectedProjects}
      selectedOverheads={data.selectedOverheads}
      expenseCategoryOptions={data.expenseCategoryOptions}
      overheadOptions={overheadOptions}
      expenseCategoryOptionsByProjectId={data.expenseCategoryOptionsByProjectId}
      references={references}
    >
      <Timesheet
        activityOptions={data.activityOptions}
        profile={profile}
        permissions={permissions}
        setTimesheetData={setTimesheetData}
        activityRequired={activityRequired}
        noteExample={noteExample}
        notesRequired={notesRequired}
        currentProfile={currentProfile}
        timeOffRequests={timeOffRequests}
        timeOffHolidays={timeOffHolidays}
        timeOffEnabled={timeOffEnabled}
        timeOffHolidayEnabled={timeOffHolidayEnabled}
      />
    </GlobalProvider>
  );
}

export default TimesheetGlobal;
