import React from "react";
import { gql, useQuery, ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";
import { CreditCards } from "./icons";

const LinkComp = () => {
  const GET_RECEIVED_INVOICES_COUNT = gql`
    query useReceivedInvoicesCountQuery {
      receivedInvoices {
        invoices {
          totalCount
        }
      }
    }
  `;

  const { data } = useQuery(GET_RECEIVED_INVOICES_COUNT);

  const invoicesCount = data?.receivedInvoices?.invoices.totalCount || 0;

  if (invoicesCount === 0) return null;

  return (
    <a href="/payment-methods" className="manager-item__link">
      <span className="manager-item__icon">
        <CreditCards />
      </span>
      <span className="manager-item__name">Manage payment methods</span>
    </a>
  );
};

export const PaymentMethodsLink = () => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <LinkComp />
    </ApolloProvider>
  );
};

export default React.memo(PaymentMethodsLink);
