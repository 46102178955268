import React from "react";
import styles from "./ActiveStatusIcon.module.css";

export default function ActiveStatusIcon({ status }) {
  return (
    <div
      className={`${styles.activeStatusIcon} ${
        styles[`activeStatusIcon--${status}`]
      } `}
    />
  );
}
