import { ReactNode } from "react";

import { Formatter, FormatterProps } from "../Formatter";

import numeral from "numeral";
const formatters = {
  // .5 => .5 // 1 => 1
  default: "0,0.[00]",
  // .5 => 1 // 12456.66 => 12457
  rounded: "0",
  // 12456.66 => 12,457
  roundedWithCommas: "0,0",
  // .5 => .50
  verbose: "0,0.00",
  // .5 => .5 // 1 => 1
  hrs: "0,0.[00]",
};

export type HoursProps = FormatterProps & {
  value: string | number;
  format?: keyof typeof formatters;
  as?: ReactNode;
};

export const Hours = ({ value, format = "default", ...props }: HoursProps) => {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  const isNegative = numberValue < 0;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const formatHours = (value, format) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return numeral(value).format(formatters[format]);
  };
  const amount = formatHours(numberValue, format);
  return (
    <Formatter negative={isNegative} {...props}>
      {format === "hrs" ? `${amount} hrs` : amount}
    </Formatter>
  );
};
