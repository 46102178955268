import * as React from "react";

import { createComponent, keyframes, styled } from "@plan/core";

import type * as Polymorphic from "@radix-ui/react-polymorphic";
import * as BaseTooltip from "@radix-ui/react-tooltip";

export const slideUp = keyframes({
  "0%": { opacity: 0, transform: "translateY(4px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
export const slideRight = keyframes({
  "0%": { opacity: 0, transform: "translateX(-4px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});
export const slideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-4px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
export const slideLeft = keyframes({
  "0%": { opacity: 0, transform: "translateX(4px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const component = createComponent();

export const TooltipHeading = styled("div", {
  ...component,
  fontSize: "$xs",
  fontWeight: "$bold",
  lineHeight: "$base",
  textAlign: "left",
  textTransform: "uppercase",
  color: "$colors$-neutral10",
  pointerEvents: "none",
  marginBottom: "$space$2",
});

const StyledContent = styled(BaseTooltip.Content, {
  ...component,
  $$background: "$colors$black",
  $$text: "$colors$white",
  backgroundColor: "$$background",
  borderRadius: "$radii$default",
  color: "$$text",
  fontSize: "$fontSizes$md",
  lineHeight: "$base",
  pointerEvents: "none",
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="right"]': { animationName: slideRight },
  '&[data-side="bottom"]': { animationName: slideDown },
  '&[data-side="left"]': { animationName: slideLeft },
  '&[data-state="closed"]': {
    animation: `${hide} 0ms`,
  },
  animationDuration: "0.3s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  filter: "drop-shadow(0px 1px 8px rgba(21, 37, 50, 0.12))",
  variants: {
    ...component.variants,
    padding: {
      singleLine: {
        padding: "$space$2",
      },
      multiLine: {
        padding: "$space$4",
      },
      none: {
        padding: 0,
      },
    },
    colorScheme: {
      dark: {},
      light: {
        $$background: "$colors$white",
        $$text: "$colors$neutral20",
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    padding: "singleLine",
  },
});

const Arrow = styled(BaseTooltip.Arrow, {
  ...component,
  fill: "$$background",
  pointerEvents: "none",
});

export type TooltipContentProps = React.ComponentProps<typeof StyledContent> &
  Polymorphic.OwnProps<typeof BaseTooltip.Content> & {
    offset?: number;
  };

/**
 * # TooltipContent
 *
 * ## Props
 *
 * Use `side` to determine placement of tooltip with respect to the trigger
 * and use `sideOffset` to set distance from tooltip to trigger in pixels.
 *
 * To change default arrow center placement, use `align`: ['start','end','center'] and `arrowPadding` in pixels to offset tooltip rounded corner.
 */
export const TooltipContent: React.FC<TooltipContentProps> = ({
  children,
  arrowPadding = 10,
  sideOffset = 4,
  side = "bottom",
  ...props
}) => (
  <BaseTooltip.Portal>
    <StyledContent
      side={side}
      sideOffset={sideOffset}
      arrowPadding={arrowPadding}
      {...props}
    >
      {children}
      <Arrow />
    </StyledContent>
  </BaseTooltip.Portal>
);

export type TooltipProps = Polymorphic.OwnProps<typeof BaseTooltip.Root> & {
  children?: React.ReactNode;
  defaultOpen?: boolean;
  delayDuration?: number;
};

type TooltipTriggerProps = React.ComponentProps<typeof BaseTooltip.Trigger>;

export const TooltipTrigger = React.forwardRef<
  HTMLButtonElement,
  TooltipTriggerProps
>(({ asChild, disabled, children, ...props }, ref) => (
  <BaseTooltip.Trigger
    ref={ref}
    asChild={asChild}
    disabled={disabled}
    {...props}
  >
    {children}
  </BaseTooltip.Trigger>
));

const StyledTooltipTriggerDisabled = styled("span", {
  button: { pointerEvents: "none" },
});

export const TooltipTriggerDisabled = React.forwardRef<
  HTMLSpanElement,
  React.ComponentProps<typeof StyledTooltipTriggerDisabled>
>((props, ref) => (
  <StyledTooltipTriggerDisabled ref={ref} tabIndex={0} {...props} />
));

export const Tooltip: React.FC<TooltipProps> = ({
  defaultOpen = false,
  delayDuration = 100,
  children,
  ...props
}) => (
  <BaseTooltip.Provider>
    <BaseTooltip.Root
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      {...props}
    >
      {children}
    </BaseTooltip.Root>
  </BaseTooltip.Provider>
);
