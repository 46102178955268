import React from "react";
import Filters from "./Filters";
import Table from "./table/Table";
import { TimelogProvider } from "./TimelogContext";
import styles from "./timelog.module.css";

export default function Timelog({
  projectSlug,
  projectId,
  queryParams,
  canEditAllTimesheets,
}) {
  return (
    <>
      <TimelogProvider
        projectId={projectId}
        projectSlug={projectSlug}
        queryParams={queryParams}
        canEditAllTimesheets={canEditAllTimesheets}
      >
        <div className={styles.timelog}>
          <Filters queryParams={queryParams} />
          <Table />
        </div>
      </TimelogProvider>
    </>
  );
}
