import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./badge.module.css";

export function Badge({
  color,
  size,
  value,
  variant,
  style,
  children,
  intent,
  className,
}) {
  const _style = color ? { "--project-color": color, ...style } : { ...style };
  let badgeClasses = cx(
    styles.badge,
    { [styles[size]]: size },
    { [styles[variant]]: variant },
    { [styles[intent]]: intent }
  );

  if (className) {
    badgeClasses = `${badgeClasses} ${className}`;
  }

  return (
    <div className={badgeClasses} style={_style} title={value}>
      {value}
      {children}
    </div>
  );
}

Badge.propTypes = {
  value: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.oneOf(["outline"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
  children: PropTypes.node,
  intent: PropTypes.string,
  className: PropTypes.string,
};

Badge.defaultProps = {
  color: null,
  size: "medium",
  value: null,
  variant: null,
  children: null,
  style: {},
  intent: null,
  className: null,
};

export default React.memo(Badge);
