import util from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import React from "react";
import ReactDOM from "react-dom";
import numeral from "numeral";

export default class EntryItemPopup extends React.Component {
  constructor(props) {
    super(props);

    this.setComponentRef = this.setComponentRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.postNotes = this.postNotes.bind(this);

    this.state = {
      notes: this.props.notes,
      isSaving: false,
      hasSaved: false,
      canEdit: this.props.canEdit,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onBlur);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onBlur);
  }

  setComponentRef(domNode) {
    this.componentRef = domNode;
  }

  onChange(event) {
    const text = event.target.value;
    this.setState({
      notes: text,
      isSaving: true,
      hasSaved: false,
    });
    util.delay(
      () => this.postNotes(text),
      1200,
      this.props.entryId + this.props.date
    );
  }

  onBlur(event) {
    // If the target is outside of the componentNode, make the notes not visibile
    if (this.componentRef && !this.componentRef.contains(event.target)) {
      this.props.setNotesVisibility(false);
    }
  }

  postNotes(text) {
    this.setState({
      isSaving: false,
      hasSaved: true,
    });
    this.props.setNotesOnParent(text);
  }

  sumExpenses() {
    if (!this.props.expenses) return 0;
    const sum = Object.values(this.props.expenses).reduce((sum, expense) => {
      if (parseFloat(expense.amount)) {
        return sum + parseFloat(expense.amount);
      }
      return sum;
    }, 0);

    return sum;
  }

  formatSumExpenses() {
    const sum = this.sumExpenses();
    return numeral(sum).format("0.00");
  }

  renderExpenseSum() {
    if (this.props.expenses) {
      return util.toCurrencyWithCents(this.formatSumExpenses());
    }
    return util.toCurrencyWithCents(0);
  }

  notePlaceHolder() {
    return this.props.reminderExample;
  }

  renderExpenses() {
    return (
      <div className="entry-expenses">
        <div className="entry-expenses__total">{this.renderExpenseSum()}</div>
        {this.props.canEdit &&
          (this.props.expenses.length > 0 ? (
            <button
              className="entry-expenses__button"
              onClick={this.props.showExpenseModal}
            >
              Edit Expenses
            </button>
          ) : (
            <button
              className="entry-expenses__button"
              onClick={this.props.showExpenseModal}
            >
              + Add Expense
            </button>
          ))}
      </div>
    );
  }

  placeHolder() {
    if (this.props.canEdit) {
      if (this.props.notesRequired && this.props.noteExample) {
        return `Requested note format: ${this.props.noteExample}`;
      }
      return "Add a note...";
    }
    return "";
  }

  render() {
    return (
      <div
        className="entry-popup"
        onBlur={this.onBlur}
        onFocus={this.onBlur}
        tabIndex={-1}
        ref={this.setComponentRef}
        key={this.props.entryId}
        onKeyDown={this.props.keyHandler}
      >
        <div
          className={`entry-popup__spinner ${
            this.state.isSaving || this.state.hasSaved
              ? "is-visible "
              : "is-hidden "
          }${this.state.hasSaved ? "has-saved" : ""}`}
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            xmlns="http://www.w3.org/2000/svg"
            className="entry-popup__checked"
          >
            <path
              d="M2 4.80395382L3.73159525 6 6 2"
              strokeWidth="1.33333333"
              stroke="#FFF"
              fill="none"
              strokeLinecap="round"
            />
          </svg>
        </div>
        {this.props.showNotesWarning && (
          <div
            style={{
              margin: "12px 0 -12px 12px",
              display: "flex",
            }}
          >
            <div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: "100%" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.1665 6.99996C1.1665 3.7783 3.77818 1.16663 6.99984 1.16663C8.54693 1.16663 10.0307 1.78121 11.1246 2.87517C12.2186 3.96913 12.8332 5.45286 12.8332 6.99996C12.8332 10.2216 10.2215 12.8333 6.99984 12.8333C3.77818 12.8333 1.1665 10.2216 1.1665 6.99996ZM2.33317 6.99996C2.33317 9.57729 4.42251 11.6666 6.99984 11.6666C8.23751 11.6666 9.4245 11.175 10.2997 10.2998C11.1748 9.42462 11.6665 8.23764 11.6665 6.99996C11.6665 4.42263 9.57717 2.33329 6.99984 2.33329C4.42251 2.33329 2.33317 4.42263 2.33317 6.99996ZM6.70817 8.16663C6.54709 8.16663 6.4165 8.29721 6.4165 8.45829V9.04163C6.4165 9.20271 6.54709 9.33329 6.70817 9.33329H7.2915C7.45259 9.33329 7.58317 9.20271 7.58317 9.04163V8.45829C7.58317 8.29721 7.45259 8.16663 7.2915 8.16663H6.70817ZM6.60317 4.66663H7.3965C7.48032 4.66602 7.56035 4.70151 7.61618 4.76403C7.672 4.82656 7.69823 4.91008 7.68817 4.99329L7.45484 6.87163C7.44594 6.94527 7.38318 7.00049 7.309 6.99996H6.69067C6.6165 7.00049 6.55374 6.94527 6.54484 6.87163L6.3115 4.99329C6.30144 4.91008 6.32767 4.82656 6.3835 4.76403C6.43932 4.70151 6.51935 4.66602 6.60317 4.66663Z"
                  fill="#B77E10"
                />
              </svg>
            </div>
            <div
              style={{
                marginLeft: "4px",
                color: "#4B5563",
              }}
            >
              Add Note
            </div>
          </div>
        )}
        <textarea
          value={this.state.notes}
          onChange={this.onChange}
          className="entry-popup__input"
          placeholder={this.placeHolder()}
          disabled={!this.props.canEdit}
        />
        {this.renderExpenses()}
      </div>
    );
  }
}
