import React, { useMemo } from "react";
import { motion } from "framer-motion";
import Portal from "Components/blocks/portal/Portal";
import moment from "moment";
import ProjectSearch from "../add/project-search/ProjectSearch";
import { useSchedule } from "../../state/ScheduleContext";
import { useAllocations } from "../../state/Store";

import styles from "./headers.module.css";

// Create list of project > phase > roles that can be added to a column
// based on the user's ID and existing allocations
const useFilteredOptions = (id) => {
  const { date } = useSchedule();
  const allocations = useAllocations();

  const shouldShowRoleName = (phaseId, options) => {
    // compare with other groups in list of projectItems
    const samePhaseOptions = options.filter(
      (option) => phaseId === option.phaseId
    );
    const hasMultipleRolesInPhase = samePhaseOptions.length > 1;
    return hasMultipleRolesInPhase;
  };

  function filterOptions(list) {
    // Get all the allocations for this profile
    const allocationsByProfile = Object.keys(allocations).filter(
      (alloc) => allocations[alloc].profileId === id
    );
    const projectList = list.map((project) => {
      // map through project.options and see if they match phase/role combos of the profile
      const filteredOptions = project.options.filter(
        ({ roleId, phaseId }) =>
          !allocationsByProfile.some(
            (alloc) =>
              allocations[alloc].phaseId === phaseId &&
              allocations[alloc].roleId === roleId
          )
      );
      // map through options and determine if role name should be shown
      const options = filteredOptions.flatMap((option) => {
        const { phaseId, beginDate, endDate } = option;
        // if phase is not in the date range, skip phase entirelly
        if (
          moment(beginDate).isAfter(moment(date).add(1, "weeks")) ||
          moment(endDate).isBefore(date)
        ) {
          return [];
        }

        const showRoleName = shouldShowRoleName(phaseId, filteredOptions);
        return { ...option, showRoleName };
      });
      return { ...project, options };
    });

    return projectList;
  }
  return filterOptions;
};

const Search = ({ id, searchInputRef, searchPosition, setSearch }) => {
  const filterOptions = useFilteredOptions(id);
  const { date } = useSchedule();

  const requestData = useMemo(
    () => ({
      profile_id: id,
      begin_date: date,
      end_date: moment(date).add(1, "weeks").format("YYYY-MM-DD"),
    }),
    [id, date]
  );

  return (
    <Portal>
      <SearchAnimation
        searchInputRef={searchInputRef}
        searchPosition={searchPosition}
      >
        <ProjectSearch
          blockClasses={styles.projectSearch}
          inputClasses={styles.projectSearchInput}
          placeholder="Search projects to add..."
          date={date}
          autoFocus
          requestData={requestData}
          filterResults={filterOptions}
          setSearch={setSearch}
          small
        />
      </SearchAnimation>
    </Portal>
  );
};

const SearchAnimation = ({ children, searchInputRef, searchPosition }) => (
  <motion.div
    key="search"
    className={styles.search}
    ref={searchInputRef}
    style={{
      position: "fixed",
      top: searchPosition.top,
      left: searchPosition.left,
      width: searchPosition.width,
      height: searchPosition.top - searchPosition.bottom,
      zIndex: 20,
      borderLeft: "1px solid var(--color--neutral-20)",
    }}
    transition={{ duration: 0.1 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
  >
    {children}
  </motion.div>
);

export default React.memo(Search);
