import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import eventTracker from "Blocks/tracker.js";
import { useModal } from "@plan";
import { UpgradeModal, ContactAdminModal } from "./create/modals";

const UpgradeAlert = ({
  stripePubKey,
  policyIsAdmin,
  hasPermission,
  projectLimit,
}) => {
  const [upgradeIsOpen, upgradeModalActions] = useModal();
  const [display, setDisplay] = useState("block");
  const upgradeModalTitle = "Need more projects?";
  const pathString = "/projects";

  const openUpgradeModal = () => {
    eventTracker("trackPlanModalDisplay", { upgradeModalTitle, pathString });
    upgradeModalActions.open();
  };

  const projectBundleonSubmit = async (projectAddOn) => {
    const stripeKey = stripePubKey || "";
    const stripe = await loadStripe(stripeKey);

    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Project bundle",
      buttonText: "Purchase now",
      planIntervalSetting: null,
    });

    fetch(`/stripe/checkout?add_on=${projectAddOn}`)
      .then((response) => response.json())
      .then((json) => {
        if (stripe) {
          stripe
            .redirectToCheckout({
              sessionId: json.session_id,
            })
            .then((result) => {
              console.log(result);
            });
        }
      });
  };

  const upgradePlanOnSubmit = async () => {
    eventTracker("trackPlanModalClick", {
      upgradeModalTitle,
      pathString,
      columnTitle: "Pro",
      buttonText: "Upgrade to Grow",
      planIntervalSetting: null,
    });

    eventTracker("trackModalGrowUpgradeClick", { pathString });

    fetch("/stripe/billing.json", { method: "POST" })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = json.portal_url;
      });
  };

  const upgradeModal = () => {
    if (policyIsAdmin || hasPermission) {
      return (
        <UpgradeModal
          projectBundleonSubmit={projectBundleonSubmit}
          upgradePlanOnSubmit={upgradePlanOnSubmit}
          upgradeIsOpen={upgradeIsOpen}
          upgradeModalActions={upgradeModalActions}
          upgradeModalTitle={upgradeModalTitle}
        />
      );
    }
    if (policyIsAdmin === false || hasPermission === false) {
      return (
        <ContactAdminModal
          upgradeIsOpen={upgradeIsOpen}
          upgradeModalActions={upgradeModalActions}
          projectLimit={projectLimit}
        />
      );
    }
    if (policyIsAdmin === undefined) {
      return null;
    }
  };

  return (
    <>
      <div className="page-warning__container" style={{ display }}>
        <div className="page-warning">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.333252 6.99992C0.333252 3.31802 3.31802 0.333252 6.99992 0.333252C8.76803 0.333252 10.4637 1.03563 11.714 2.28587C12.9642 3.53612 13.6666 5.23181 13.6666 6.99992C13.6666 10.6818 10.6818 13.6666 6.99992 13.6666C3.31802 13.6666 0.333252 10.6818 0.333252 6.99992ZM1.66659 6.99992C1.66659 9.94544 4.0544 12.3333 6.99992 12.3333C8.41441 12.3333 9.77096 11.7713 10.7712 10.7712C11.7713 9.77096 12.3333 8.41441 12.3333 6.99992C12.3333 4.0544 9.94544 1.66659 6.99992 1.66659C4.0544 1.66659 1.66659 4.0544 1.66659 6.99992ZM6.66659 8.33325C6.48249 8.33325 6.33325 8.48249 6.33325 8.66658V9.33325C6.33325 9.51735 6.48249 9.66659 6.66659 9.66659H7.33325C7.51735 9.66659 7.66659 9.51735 7.66659 9.33325V8.66658C7.66659 8.48249 7.51735 8.33325 7.33325 8.33325H6.66659ZM6.54659 4.33325H7.45325C7.54904 4.33256 7.64051 4.37311 7.70431 4.44457C7.76811 4.51603 7.79808 4.61148 7.78658 4.70659L7.51992 6.85325C7.50975 6.93741 7.43802 7.00053 7.35325 6.99992H6.64658C6.56181 7.00053 6.49009 6.93741 6.47992 6.85325L6.21325 4.70659C6.20175 4.61148 6.23173 4.51603 6.29553 4.44457C6.35933 4.37311 6.45079 4.33256 6.54659 4.33325Z"
              fill="#DB9E17"
            />
          </svg>
          <span style={{ fontWeight: "600", marginLeft: "0.5rem" }}>
            You’ve reached the project limit for your plan.
          </span>
          &nbsp;Get a project bundle to add-on to your plan or upgrade to Grow
          for unlimited projects.&nbsp;
          <div
            onClick={() => openUpgradeModal()}
            style={{
              textDecoration: "underline",
              color: "#111827",
              cursor: "pointer",
            }}
          >
            Learn more
          </div>
          <span
            className="js-hide-warning"
            style={{ cursor: "pointer", margin: "0 0 0 auto" }}
            onClick={() => setDisplay("none")}
          >
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.89958 6.96006C7.96268 7.02265 7.99817 7.10785 7.99817 7.19673C7.99817 7.28561 7.96268 7.37081 7.89958 7.4334L7.43291 7.90006C7.37032 7.96317 7.28512 7.99866 7.19624 7.99866C7.10736 7.99866 7.02216 7.96317 6.95958 7.90006L3.99958 4.94006L1.03958 7.90006C0.976987 7.96317 0.891788 7.99866 0.802909 7.99866C0.71403 7.99866 0.628832 7.96317 0.566243 7.90006L0.099576 7.4334C0.0364718 7.37081 0.000976562 7.28561 0.000976562 7.19673C0.000976562 7.10785 0.0364718 7.02265 0.099576 6.96006L3.05958 4.00006L0.099576 1.04006C0.0364718 0.977475 0.000976563 0.892277 0.000976563 0.803398C0.000976563 0.714518 0.0364718 0.62932 0.099576 0.566731L0.566243 0.100064C0.628832 0.0369601 0.71403 0.00146484 0.802909 0.00146484C0.891788 0.00146484 0.976987 0.0369601 1.03958 0.100064L3.99958 3.06006L6.95958 0.100064C7.02216 0.0369601 7.10736 0.00146484 7.19624 0.00146484C7.28512 0.00146484 7.37032 0.0369601 7.43291 0.100064L7.89958 0.566731C7.96268 0.62932 7.99817 0.714518 7.99817 0.803398C7.99817 0.892277 7.96268 0.977475 7.89958 1.04006L4.93958 4.00006L7.89958 6.96006Z"
                fill="#111827"
              />
            </svg>
          </span>
        </div>
      </div>
      {upgradeModal()}
    </>
  );
};

export default UpgradeAlert;
