/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconEditProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconEdit = ({ label, ...props }: SvgIconEditProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 8 8", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.634 1.146.22.22c.093.093.146.22.146.353v.252a.5.5 0 0 1-.146.36l-.616.605a.166.166 0 0 1-.236 0l-.934-.948a.166.166 0 0 1 0-.233l.602-.609A.499.499 0 0 1 6.029 1h.253c.132 0 .259.053.352.146ZM5.606 3.562a.166.166 0 0 0 0-.233l-.95-.938a.166.166 0 0 0-.237 0L1.735 5.075a.665.665 0 0 0-.15.226l-.571 1.444a.166.166 0 0 0 .063.206.166.166 0 0 0 .18.037L2.7 6.402a.665.665 0 0 0 .226-.15l2.68-2.69Z"
        fill="#6B7280"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconEdit;
