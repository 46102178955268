import * as React from "react";

import { createComponent, styled } from "@plan/core";

import {
  MenuContent,
  MenuItem,
  MenuTrigger,
  MoreActionsItem,
} from "./elements";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

const component = createComponent();

export const MenuWrapper = styled(DropdownMenu.Root, {
  ...component,
  backgroundColor: "$white",
});

const Arrow = styled(DropdownMenu.Arrow, {
  fill: "white",
});

type MenuProps = Pick<React.ComponentProps<typeof MenuContent>, "align"> & {
  trigger?: React.ReactNode;
} & React.ComponentProps<typeof MenuTrigger>;

export const Menu: React.FC<MenuProps> = ({
  children,
  trigger,
  align = "start",
  ...rest
}) => (
  <MenuWrapper>
    <MenuTrigger {...rest}>{trigger}</MenuTrigger>
    <MenuContent align={align}>
      <Arrow />
      {children}
    </MenuContent>
  </MenuWrapper>
);

export { MenuContent, MenuItem, MenuTrigger, MoreActionsItem };
