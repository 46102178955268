import React from "react";
import Lock from "../icons/Lock";
import FieldAction from "./FieldAction";

export function LockButton({
  handleClick,
  isLocked,
  children,
  ghosted,
  classes,
  disabled,
}) {
  return (
    <FieldAction
      handleClick={handleClick}
      classes={classes}
      ghosted={ghosted}
      disabled={disabled}
    >
      <Lock locked={isLocked} />
      {children}
    </FieldAction>
  );
}

export default LockButton;
