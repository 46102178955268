import React, { useEffect, useState } from "react";
import axios from "axios";
import isEqual from "lodash/isEqual";
import { renderError } from "Blocks/alert";

// https://www.notion.so/monographhq/useApi-Hook-2dfe3aa7bd614aada3776cc22938ea4a

export function useApi(url, initialParams, config) {
  const [params, setParams] = useState({ ...initialParams });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState("fetching");
  const [error, setError] = useState(null);

  const [cancelToken, setCancelToken] = useState();

  const changeParams = (newParams) => {
    // Check to see if the arguments have changed
    if (isEqual(newParams, params)) return;
    // Cancel any existing requests
    if (cancelToken) cancelToken.cancel("Request canceled by the user.");
    // If we've already fetched data set reloading state
    if (loading !== "fetching") setLoading("reloading");
    setParams(newParams);
  };

  const fetchData = async () => {
    const token = axios.CancelToken;
    setCancelToken(token.source());

    setError(false);
    if (loading !== "fetching") setLoading("reloading");
    try {
      const res = await axios.get(
        url,
        { params: { ...params } },
        { cancelToken }
      );
      setData({ ...res.data });
      setLoading("loaded");
    } catch (err) {
      setError("Error Loading Data");
      // Shows the 'something went wrong, refresh the page' banner
      if (config?.renderErrors) renderError();
      throw new Error(err);
    } finally {
      setLoading("loaded");
    }
  };

  // Refetch the data when params are are set or updated
  useEffect(() => {
    fetchData();
  }, [params]);

  return [data, loading, error, changeParams, params, fetchData];
}

export default useApi;
