/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconDoubleCaretVerticalProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconDoubleCaretVertical = ({
  label,
  ...props
}: SvgIconDoubleCaretVerticalProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        d="M16.14 8.94a.5.5 0 0 1 0 .71l-.2.2a.49.49 0 0 1-.36.15H8.42a.49.49 0 0 1-.36-.15l-.2-.2a.5.5 0 0 1 0-.71l3.79-3.79a.48.48 0 0 1 .7 0l3.79 3.79Z"
        fill="currentColor"
      />,
      <path
        d="M7.86 15.06a.5.5 0 0 1 0-.71l.2-.2a.49.49 0 0 1 .36-.15h7.16a.49.49 0 0 1 .36.15l.2.2a.5.5 0 0 1 0 .71l-3.79 3.79a.48.48 0 0 1-.7 0l-3.79-3.79Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconDoubleCaretVertical;
