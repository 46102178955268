import React from "react";
import FieldAction from "./FieldAction";
import EditPhase from "../icons/EditPhase";

export function EditButtonTimesheet({
  handleClick,
  children,
  ghosted,
  disabled,
  tooltipNode,
}) {
  return (
    <div ref={tooltipNode}>
      <FieldAction
        handleClick={handleClick}
        ghosted={ghosted}
        disabled={disabled}
      >
        <EditPhase disabled={disabled} />
        {children}
      </FieldAction>
    </div>
  );
}

export default React.memo(EditButtonTimesheet);
