import { useEffect } from "react";

// a hundredth of an hour
const TIMER_TICK_INTERVAL_MS = 17000;

export const useTimerTickUpdate = (tickCallback: () => void) => {
  useEffect(() => {
    const interval = setInterval(() => {
      tickCallback();
    }, TIMER_TICK_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [tickCallback]);
};
