// This file contains styled components of the Table component.
import { createComponent, styled } from "@plan/core";
import { Icon, IconDoubleCaretVertical } from "@plan/icons";

const component = createComponent();

// Base Table Components

// Moving TableRow, TableHeading and TableCell to top in order to have sticky and size variants in TableBase
const TableRow = styled("tr", {
  $$borderStyle: "$borderStyles$primary",
  borderTop: "$$borderStyle",
  textAlign: "left",
  height: "$space$14",
  contentVisibility: "auto",
  variants: {
    intent: {
      primary: {},
      secondary: {
        $$borderStyle: "1px solid $colors$-neutral40",
        height: "auto",
      },
    },
    type: {
      header: {
        height: "$sizes$xl",
        borderTop: "none",
        borderBottom: "$$borderStyle",
      },
      footer: {
        borderTop: "$$borderStyle",
      },
    },
  },
});

const TableHeading = styled("th", {
  backgroundColor: "$colors$-neutral40",
  fontSize: "$fontSizes$sm",
  fontWeight: "$bold",
  color: "$colors$neutral10",
  textTransform: "uppercase",
  minWidth: "max-content",
  whiteSpace: "nowrap",
  position: "relative",
  top: "0",
  transition: "color 200ms $toggle",
  variants: {
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    position: {
      fixed: {
        height: "100%",
        width: "100%",
      },
      fixedLeft: {
        minWidth: "$space$60",
        left: "0",
        padding: "0 $space$6",
        position: "sticky",
        borderRight: "2px solid $colors$-neutral30",
      },
    },
    columnPadding: {
      xs: {
        padding: "$space$0_5 $space$1",
      },
      sm: {
        padding: "$space$0_5 $space$2",
        "&:first-child": {
          paddingLeft: "$space$4",
        },
        "&:last-child": {
          paddingRight: "$space$4",
        },
      },
      md: {
        padding: "$space$0_5 $space$6",
      },
    },
  },
  defaultVariants: {
    columnPadding: "sm",
    align: "left",
  },
});

const TableCell = styled("td", {
  color: "$black",
  lineHeight: "1",
  variants: {
    dragDropHandle: {
      true: {
        width: "10px",
        [`${Icon}`]: {
          marginTop: "3px",
          color: "$colors$-neutral10",
          cursor: "grab",
        },
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    canSort: {
      true: {},
      false: {},
    },
    position: {
      fixed: {
        height: "100%",
        width: "100%",
      },
      fixedLeft: {
        background: "$white",
        minWidth: "$space$60",
        whiteSpace: "nowrap",
        left: "0",
        position: "sticky",
        borderRight: "2px solid $colors$-neutral30",
      },
    },
    nowrap: {
      true: {
        whiteSpace: "nowrap",
      },
    },
    columnPadding: {
      xs: {
        padding: "$space$2_5 $space$1",
      },
      sm: {
        padding: "$space$2_5 $space$2",
        "&:first-child": {
          paddingLeft: "$space$4",
        },
        "&:last-child": {
          paddingRight: "$space$4",
        },
      },
      md: {
        padding: "$space$2_5 $space$6",
      },
    },
  },
  defaultVariants: {
    columnPadding: "sm",
    align: "left",
  },
  compoundVariants: [
    {
      align: "right",
      canSort: true,
      columnPadding: "xs",
      css: {
        paddingRight: "$7",
      },
    },
    {
      align: "right",
      canSort: true,
      columnPadding: "sm",
      css: {
        paddingRight: "calc($space$1 + $7)",
        "&:last-child": {
          paddingRight: "calc($space$2 + $7)",
        },
      },
    },
    {
      align: "right",
      canSort: true,
      columnPadding: "md",
      css: {
        paddingRight: "calc($space$3 + $7)",
      },
    },
  ],
});

const TableBase = styled("table", {
  position: "relative",
  width: "100%",
  borderCollapse: "collapse",
  overflowX: "auto",
  variants: {
    sticky: {
      true: {
        [`& ${TableHeading}`]: {
          position: "sticky",
          top: "0",
          zIndex: "$sticky",
        },
      },
    },
  },
});

const TableBody = styled("tbody", {
  position: "relative",
  borderCollapse: "separate",
  width: "100%",
});

const TableHead = styled("thead", {
  position: "relative",
  borderCollapse: "collapse",
  color: "$colors$neutral10",
});

const TableFooter = styled("tfoot", {
  position: "relative",
  width: "100%",
  borderCollapse: "collapse",
});

const SortSVG = styled(IconDoubleCaretVertical, {
  fontSize: "$fontSizes$lg",
  fill: "$colors$-neutral10",
  variants: {
    sort: {
      none: {},
      asc: {
        "> path:first-of-type": {
          fill: "$black",
        },
      },
      desc: {
        "> path:nth-of-type(2)": {
          fill: "$black",
        },
      },
    },
  },
  defaultVariants: {
    sort: "none",
  },
});

// Table wrapper for border and overflow
const TableWrapper = styled("div", {
  ...component,
  border: "$borderStyles$primary",
  borderRadius: "$radii$default",
  backgroundColor: "$white",
  variants: {
    ...component.variants,
    withPagination: {
      true: {
        border: "none",
        borderRadius: "0",
      },
    },
    intent: {
      primary: {},
      secondary: {
        border: "none",
        borderRadius: "0",
        [`& ${TableHeading}`]: {
          backgroundColor: "transparent",
          fontWeight: "$normal",
          fontSize: "$xs",
          padding: "$2 $1",
        },
        [`& ${TableCell}`]: {
          fontSize: "$sm",
          padding: "$2 $1",
        },
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "fill-container",
    intent: "primary",
  },
});

const TableOverflowWrapper = styled("div", {
  overflow: "hidden",
  [`${TableWrapper}`]: {
    overflowX: "auto",
  },
});

export {
  SortSVG,
  TableBase,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeading,
  TableOverflowWrapper,
  TableRow,
  TableWrapper,
};
