import React from "react";
import { motion } from "framer-motion";

export const Carot = React.memo(({ toggle }) => {
  return (
    <motion.svg
      animate={toggle ? "up" : "down"}
      variants={{
        up: { rotate: 0 },
        down: { rotate: -180 },
      }}
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37987 0.870484C4.54921 0.829841 4.73423 0.878329 4.86616 1.01595L8.43316 4.73686C8.63686 4.94934 8.63686 5.29385 8.43316 5.50633C8.22947 5.71882 7.89921 5.71882 7.69551 5.50633L4.48149 2.15365L1.26003 5.5141C1.05633 5.72658 0.726072 5.72658 0.522375 5.5141C0.318678 5.30161 0.318678 4.9571 0.522375 4.74462L4.08938 1.02371C4.17125 0.938316 4.27356 0.88724 4.37987 0.870484Z"
        fill="#A0A8AE"
      />
    </motion.svg>
  );
});

export default React.memo(Carot);
