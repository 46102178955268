import { useEffect } from "react";
import { useLocalStorage } from "react-use";

import { uniq } from "lodash";

const getRecentProjectsKey = (profileId) => `mg-recent-projects-${profileId}`;

// implementation of a local FE tracking of viewed projects
export const useRecentlyViewedProjects = ({ profileId, projectId }) => {
  const [recentProjectIds, setRecentProjectIds] = useLocalStorage(
    getRecentProjectsKey(profileId || ""),
    []
  );

  const projectViewed = (id) => {
    setRecentProjectIds(uniq([`${id}`, ...(recentProjectIds || [])]));
  };

  useEffect(() => {
    // `recentProjectIds` here are strings since they come from localstorage
    // but the `projectId` that gets passed to this hook is a number
    // eslint-disable-next-line eqeqeq
    if (projectId && !recentProjectIds.some((id) => id == projectId)) {
      projectViewed(projectId);
    }
  }, [projectViewed, projectId, recentProjectIds]);

  return {
    projectViewed,
    projectIds: recentProjectIds,
  };
};

export default useRecentlyViewedProjects;
