import React from "react";
import { string, arrayOf, shape, bool } from "prop-types";
import { Alert, Warning } from "@plan";
import classNames from "classnames/bind";
import styles from "./InvoiceAssignmentWarning.module.css";

const cx = classNames.bind(styles);

const noAssignment = ({
  is_v1_other_item,
  is_v1_expense,
  phase_id,
  consultant_id,
}) => !(is_v1_other_item || is_v1_expense || phase_id || consultant_id);

const InvoiceAssignmentWarning = ({ lineItems = [] }) => {
  const countUnassignedLineItems = lineItems.filter(noAssignment).length;
  if (countUnassignedLineItems === 0) return null;
  return (
    <Alert className={cx("invoiceAssignmentWarning")}>
      <div className={cx("icon")}>
        <Warning />
      </div>
      There {countUnassignedLineItems === 1 ? "is" : "are"}{" "}
      {countUnassignedLineItems} unassigned line{" "}
      {countUnassignedLineItems === 1 ? "item" : "items"}. To increase report
      and invoice accuracy, please assign all line items.
    </Alert>
  );
};

InvoiceAssignmentWarning.propTypes = {
  lineItems: arrayOf(
    shape({
      id: string,
      is_v1_other_item: bool,
      is_v1_expense: bool,
      role_id: string,
      phase_id: string,
      consultant_id: string,
    })
  ),
};

export default InvoiceAssignmentWarning;
