import React from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import isEmpty from "lodash/isEmpty";
import { useFirstMountState } from "react-use";
import max from "lodash/max";
import { COLUMN_MIN_WIDTH } from "../../scheduleConfig";
import {
  usePhases,
  usePhaseOrder,
  useSelectedPhase,
  useGridView,
  useTeamOrder,
  useAllocations,
} from "../../state/Store";
import TeamCard from "../cards/team/TeamCard";
import styles from "./columns.module.css";

export const TeamColumnAnimation = React.memo(
  ({ children, styles: addStyles }) => {
    const grid = useGridView();
    const [selected] = useSelectedPhase();
    const isInitialRender = useFirstMountState();

    function showOrHide() {
      if (grid === "row" || !isEmpty(selected)) return "visible";
      return "hidden";
    }

    const animation = showOrHide();

    const animate = {
      x: "0%",
      minWidth: COLUMN_MIN_WIDTH,
      width: COLUMN_MIN_WIDTH,
      transition: { easing: "linear", duration: 0.175 },
    };

    const exit = {
      x: "-100%",
      minWidth: "0px",
      width: "0px",
      transition: { easing: "linear", duration: 0.175 },
    };

    const initial = exit;

    return (
      <AnimatePresence initial={!isInitialRender}>
        {animation === "visible" && (
          <motion.div
            key="teamcolumn"
            className={cx(
              { [styles.selected]: selected },
              { [styles.rowColumn]: grid === "row" }
            )}
            style={{
              position: "sticky",
              left: 0,
              zIndex: 2,
              minHeight: "100%",
              overflow: "hidden",
              borderRight: "2px solid var(--color--neutral-20)",
              transformOrigin: "left center",
              ...addStyles,
            }}
            initial={initial}
            animate={animate}
            exit={exit}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

export function TeamColumn() {
  const phaseOrder = usePhaseOrder();
  const phases = usePhases();
  const teamOrder = useTeamOrder();
  const allocations = useAllocations();
  const [selected] = useSelectedPhase();
  const grid = useGridView();

  return (
    <TeamColumnAnimation>
      {phaseOrder.map((phase) => {
        if (!phases[phase]) return;
        const {
          name,
          abbreviation,
          color,
          number,
          hours,
          budget,
          budgetConsumed,
          hoursConsumed,
          hoursBudgeted,
          daysRemaining,
          projectSlug,
        } = phases[phase];

        const calcRowHeight = (phase, selected) => {
          const maxNumberOfAllocations = () => {
            const allocationsForPhase = Object.keys(allocations).filter(
              (id) => allocations[id].phaseId === phase
            );
            const numberOfAllocationsByProfile = teamOrder.map((id) =>
              allocationsForPhase.reduce((acc, alloc) => {
                if (allocations[alloc].profileId === id) return acc + 1;
                return acc;
              }, 0)
            );
            return max(numberOfAllocationsByProfile);
          };
          // Would be great to dynamically grab these heights at some point
          if (selected && selected === phase)
            return 240 * maxNumberOfAllocations();
          return 93 * maxNumberOfAllocations();
        };

        const rowHeight = calcRowHeight(phase, selected);

        if (grid === "row" && rowHeight === 0) return <></>;

        return (
          <TeamCard
            key={phase}
            name={name}
            color={color}
            abbreviation={abbreviation}
            number={number}
            id={phase}
            height={rowHeight}
            progress={hoursConsumed}
            total={hoursBudgeted}
            budget={budget}
            budgetConsumed={budgetConsumed}
            daysRemaining={daysRemaining}
            hoursConsumed={hoursConsumed}
            hours={hours}
            projectSlug={projectSlug}
          />
        );
      })}
    </TeamColumnAnimation>
  );
}

export default React.memo(TeamColumn);
