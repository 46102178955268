import React from "react";
import PropTypes from "prop-types";
import { gql, ApolloProvider, useQuery } from "@apollo/client";
import { useLocalStorage } from "react-use";
import { Tooltip, useTooltip } from "@plan";
import { Info } from "Components/blocks/icons";
import { useApollo } from "../hooks/useApollo";
import styles from "./projects.module.css";
import util from "../../blocks/utils/util";

const DollarIcon = () => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5C39 14.3283 36.9455 9.36838 33.2886 5.71142C29.6316 2.05446 24.6717 0 19.5 0ZM19.5 35.1002C10.8843 35.1002 3.89995 28.1158 3.89995 19.5002C3.89995 10.8845 10.8843 3.90018 19.5 3.90018C28.1156 3.90018 35.1 10.8845 35.1 19.5002C35.1 23.6376 33.4564 27.6055 30.5308 30.531C27.6053 33.4566 23.6373 35.1002 19.5 35.1002ZM21.45 8.77536V9.75036C23.6039 9.75036 25.35 11.4965 25.35 13.6504V15.1129C25.35 15.6513 24.9134 16.0879 24.375 16.0879H22.9125C22.374 16.0879 21.9375 15.6513 21.9375 15.1129V13.1629H17.0625V14.8789C17.0543 15.0241 17.1204 15.1636 17.238 15.2494L23.9265 20.7679C24.825 21.5065 25.347 22.6077 25.35 23.7709V25.3504C25.35 27.5043 23.6039 29.2504 21.45 29.2504V30.2254C21.45 30.7638 21.0134 31.2004 20.475 31.2004H18.525C17.9865 31.2004 17.55 30.7638 17.55 30.2254V29.2504C15.396 29.2504 13.65 27.5043 13.65 25.3504V23.8879C13.65 23.3494 14.0865 22.9129 14.625 22.9129H16.0875C16.6259 22.9129 17.0625 23.3494 17.0625 23.8879V25.8379H21.9375V24.1219C21.9456 23.9766 21.8795 23.8371 21.762 23.7514L15.0735 18.2329C14.1749 17.4942 13.6529 16.3931 13.65 15.2299V13.6504C13.65 11.4965 15.396 9.75036 17.55 9.75036V8.77536C17.55 8.23689 17.9865 7.80036 18.525 7.80036H20.475C21.0134 7.80036 21.45 8.23689 21.45 8.77536Z"
      fill="#5840E0"
    />
  </svg>
);

const ClearIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8999 10.9596C11.963 11.0222 11.9985 11.1074 11.9985 11.1962C11.9985 11.2851 11.963 11.3703 11.8999 11.4329L11.4333 11.8996C11.3707 11.9627 11.2855 11.9982 11.1966 11.9982C11.1077 11.9982 11.0225 11.9627 10.9599 11.8996L7.99994 8.93958L5.03994 11.8996C4.97735 11.9627 4.89215 11.9982 4.80328 11.9982C4.7144 11.9982 4.6292 11.9627 4.56661 11.8996L4.09994 11.4329C4.03684 11.3703 4.00134 11.2851 4.00134 11.1962C4.00134 11.1074 4.03684 11.0222 4.09994 10.9596L7.05994 7.99958L4.09994 5.03958C4.03684 4.97699 4.00134 4.89179 4.00134 4.80291C4.00134 4.71403 4.03684 4.62883 4.09994 4.56624L4.56661 4.09958C4.6292 4.03647 4.7144 4.00098 4.80328 4.00098C4.89215 4.00098 4.97735 4.03647 5.03994 4.09958L7.99994 7.05958L10.9599 4.09958C11.0225 4.03647 11.1077 4.00098 11.1966 4.00098C11.2855 4.00098 11.3707 4.03647 11.4333 4.09958L11.8999 4.56624C11.963 4.62883 11.9985 4.71403 11.9985 4.80291C11.9985 4.89179 11.963 4.97699 11.8999 5.03958L8.93994 7.99958L11.8999 10.9596Z"
      fill="#4B5563"
    />
  </svg>
);

const GetProjectRetainerSummary = gql`
  query getProject($slug: String!) {
    project(slug: $slug) {
      invoicesCount
      retainerSummary {
        openReceived
        received
        remaining
        applied
      }
    }
  }
`;

const Retainers = ({ projectId, slug, canEdit, canCreateInvoices }) => {
  const apolloClient = useApollo();

  return (
    <ApolloProvider client={apolloClient}>
      <RetainersPage
        projectId={projectId}
        slug={slug}
        canEdit={canEdit}
        canCreateInvoices={canCreateInvoices}
      />
    </ApolloProvider>
  );
};

const RetainersPage = ({ projectId, slug, canEdit, canCreateInvoices }) => {
  const { data, loading } = useQuery(GetProjectRetainerSummary, {
    variables: { slug },
  });

  if (loading) return null;

  return (
    <>
      {data.project.invoicesCount == 0 ? (
        <CreateRetainerView
          projectId={projectId}
          canCreateInvoices={canCreateInvoices}
        />
      ) : (
        <RetainerSummary
          projectId={projectId}
          canEdit={canEdit}
          retainerSummary={data.project.retainerSummary}
        />
      )}
    </>
  );
};

const CreateRetainerView = ({ projectId, canCreateInvoices }) => {
  const [show, setShow] = useLocalStorage(
    `show-retainer-modal-${projectId}`,
    true
  );

  if (!show) return null;
  if (!canCreateInvoices) return null;

  return (
    <div className="page-module" style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 12, right: 16 }}>
        <div style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
          <ClearIcon />
        </div>
      </div>
      <div className={styles.retainersCard}>
        <DollarIcon />
        <p className={styles.invoicingForRetainers}>Collect a retainer</p>
        <p className={styles.textMuted}>Start by creating an invoice</p>
        <a href={`/invoices?action=create&projectId=${projectId}&tab=other`}>
          <button type="button" className={styles.createInvoiceButton}>
            Create Invoice
          </button>
        </a>
      </div>
    </div>
  );
};

const RetainerSummary = ({ projectId, retainerSummary, canEdit }) => {
  const [tooltip, ref] = useTooltip();
  const text = (
    <div>The sum of all retainers collected, applied, and remaining.</div>
  );

  if (!canEdit) return null;

  return (
    <div className="page-module">
      <h4 className="page-module__title">
        Retainer Summary
        <span
          ref={ref}
          style={{
            color: "#4B5563",
            display: "inline-block",
            marginLeft: ".25rem",
            top: "0.125rem",
            position: "relative",
            height: "16px",
            width: "16px",
            cursor: "pointer",
          }}
        >
          <Info ref={ref} />
          <Tooltip tooltip={tooltip} text={text} position="bottom" />
        </span>
      </h4>
      <div className="details-table">
        <div className="details-table__row">
          <span className={styles.textMuted}>Open Invoice</span>
          <span>
            {util.toCurrencyWithCents(retainerSummary?.openReceived || 0)}
          </span>
        </div>
        <div className="details-table__row">
          <span className={styles.textMuted}>Received</span>
          <span>
            {util.toCurrencyWithCents(retainerSummary?.received || 0)}
          </span>
        </div>
        <div className="details-table__row">
          <span className={styles.textMuted}>Applied</span>
          <span>
            {util.toCurrencyWithCents(Math.abs(retainerSummary?.applied || 0))}
          </span>
        </div>
        <div className="details-table__row">
          <span className={styles.textMuted}>Remaining</span>
          <span>
            {util.toCurrencyWithCents(retainerSummary?.remaining || 0)}
          </span>
        </div>
      </div>
      {!!retainerSummary?.remaining && retainerSummary?.remaining > 0 && (
        <div
          className="page-module__submodule"
          style={{ marginTop: "1rem", paddingBottom: 0 }}
        >
          <p className={styles.textMuted}>
            Need to refund the remaining retainer balance?{" "}
            <a
              href={`/invoices?action=create&projectId=${projectId}&tab=designer`}
              className={styles.createInvoiceLink}
            >
              Create an invoice
            </a>
          </p>
        </div>
      )}
    </div>
  );
};
Retainers.propTypes = {
  projectId: PropTypes.string.isRequired,
};

CreateRetainerView.propTypes = {
  projectId: PropTypes.bool.isRequired,
};

RetainersPage.propTypes = {
  projectId: PropTypes.bool.isRequired,
};

export default Retainers;
