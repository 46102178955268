import React from "react";
import LoadingHeader from "../headers/LoadingHeader";
import { COLUMN_MIN_WIDTH, COLUMN_MAX_WIDTH } from "../../scheduleConfig";
import styles from "./empty-state.module.css";

const Column = () => (
  <div>
    <LoadingHeader />
    <div className={styles.loadingColumn} />
  </div>
);

export default function LoadingGrid() {
  // sets the number of fake columns to show
  const length = 20;
  const loadingGrid = {
    width: "100%",
    display: "grid",
    overflowX: "scroll",
    height: "125vh",
    rowGap: "1rem",
    gridTemplateColumns: `repeat(${length}, minmax(${COLUMN_MIN_WIDTH}, ${COLUMN_MAX_WIDTH}))`,
    position: "relative",
  };

  return (
    <div style={loadingGrid}>
      {Array.from({ length }, (_, i) => (
        <Column key={`loading${i}`} />
      ))}
    </div>
  );
}
