import React from "react";

export const Row = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="16"
      width="4"
      height="10"
      transform="rotate(-90 2 16)"
      fill="#DFE2E4"
    />
    <rect
      x="4"
      y="10"
      width="4"
      height="12"
      transform="rotate(-90 4 10)"
      fill="#DFE2E4"
    />
    <rect
      y="4"
      width="4"
      height="8"
      transform="rotate(-90 0 4)"
      fill="#DFE2E4"
    />
  </svg>
);
