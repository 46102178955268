import moment from "moment";
import Url from "domurl";
import util, { simpleFormat } from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import { renderSuccess } from "Blocks/alert";
import $ from "Blocks/utils/jQuerySelector";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import { selectMethods } from "Blocks/select";

class TasksShow extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-toggle-edit-task", (event) => {
      this.toggleEditTask(event);
    });
    bindListenerToDocument("click", "js-assign", (event) => {
      this.assign(event);
    });
    bindListenerToDocument("click", "js-unassign", (event) => {
      this.unassign(event);
    });
    bindListenerToDocument("click", "js-update-task", (event) => {
      this.updateTask(event);
    });
    bindListenerToDocument("click", "js-destroy-task", (event) => {
      this.destroyTask(event);
    });
    bindListenerToDocument("click", "js-toggle-edit-comment", (event) => {
      this.toggleEditComment(event);
    });
    bindListenerToDocument("click", "js-update-comment", (event) => {
      this.updateComment(event);
    });
    bindListenerToDocument("click", "js-destroy-comment", (event) => {
      this.destroyComment(event);
    });
    bindListenerToDocument(
      "click",
      ["js-update-milestone", "js-select-reset"],
      (event) => {
        this.updateMilestone(event);
      }
    );
    bindListenerToDocument("click", "js-open-task", (event) => {
      this.openTask(event);
    });
    bindListenerToDocument("click", "js-complete-task", (event) => {
      this.completeTask(event);
    });
    bindListenerToDocument("click", "js-toggle-delete-task", (event) => {
      this.toggleDeleteTask(event);
    });
    bindListenerToDocument("click", "js-toggle-delete-comment", (event) => {
      this.toggleDeleteComment(event);
    });
    bindListenerToDocument("click", "js-update-phase", (event) => {
      this.updatePhase(event);
    });
    bindListenerToDocument("input", "js-update-due-date", (event) => {
      this.updateDueDate(event);
    });
  }

  isTemplate() {
    const u = new Url();
    return u.paths()[1] === "templates";
  }

  load() {
    const u = new Url();

    if (this.isTemplate()) {
      this.projectSlug = `templates/${u.paths()[2]}`;
      this.taskId = u.paths()[4];
    } else {
      this.projectSlug = u.paths()[1];
      this.taskId = u.paths()[3];
    }
  }

  beforeCache() {}

  toggleEditTask() {
    const taskNode = $(".js-task")[0];
    const taskShowNode = taskNode.querySelector(".js-task-show");
    const taskEditNode = taskNode.querySelector(".js-task-edit");
    taskShowNode.classList.toggle("is-visible");
    taskEditNode.classList.toggle("is-visible");

    const taskActionsNode = $(".js-task-actions")[0];
    taskActionsNode.classList.toggle("is-editable");
  }

  updateTask(event) {
    this.startSave();

    const name = $(".js-task-edit-name")[0].value;
    const description = $(".js-task-edit-description")[0].value;
    const data = { name, description };

    const taskNode = $(".js-task")[0];

    ajax({
      method: "PUT",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}`,
      data,
      success: (response) => {
        $(".js-task-show-name")[0].innerHTML = name;
        $(".js-task-show-description")[0].innerHTML = simpleFormat(description);

        this.toggleEditTask();
        this.endSave();
      },
    });
  }

  assign(event) {
    this.startSave();
    const profile_id = event.target.dataset.value;
    const data = { profile_id };

    const assignees = $(".js-assignees")[0];
    const selectList = event.target.closest(".js-select-list");
    const selectElement = selectList.closest(".js-select");

    ajax({
      method: "POST",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}/assign`,
      data,
      success: (response) => {
        console.log(response);
        const assigneeNode = util.htmlToElement(response);
        assignees.appendChild(assigneeNode);
        util.deleteElement(event.target);
        selectMethods.deselect(selectElement);
        this.endSave();
      },
    });
  }

  unassign(event) {
    // Grab innerHTML and the id of the assignee that is about to be deleted
    this.startSave();
    const assigneeNode = event.target.closest(".js-assignee");
    const profile_id = assigneeNode.dataset.profileId;
    const name = assigneeNode.querySelector(".js-assignee-name").innerHTML;
    const data = { profile_id };

    ajax({
      method: "DELETE",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}/unassign`,
      data,
      success: (response) => {
        console.log(response);
        // Delete the assignee
        util.deleteElement(assigneeNode);

        // Populate the select with the id and the name of the assignee
        const newLiString = `<li class="select__item js-select-item js-assign" data-value="${profile_id}">${name}</li>`;
        const newLi = util.htmlToElement(newLiString);
        const assigneeSelectData = $(".js-select-assignee")[0];
        const assigneeSelect = assigneeSelectData.closest(".js-select");
        const assigneeSelectList =
          assigneeSelect.querySelector(".js-select-list");
        console.log(assigneeSelectList);
        assigneeSelectList.appendChild(newLi);

        this.endSave();
      },
    });
  }

  destroyTask(event) {
    ajax({
      method: "DELETE",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}`,
      success: (response) => {
        const u = new Url();
        const newPaths = u.paths();
        newPaths.pop();
        u.paths(newPaths);
        location.href = u;
      },
    });
  }

  toggleEditComment(event = false) {
    const comment = event.target.closest(".js-comment");
    comment.classList.toggle("is-editable");
    comment
      .querySelector(".comments__actions")
      .classList.toggle("editing-controls");
  }

  updateComment(event) {
    this.startSave;
    const comment = event.target.closest(".js-comment");
    const comment_id = comment.dataset.id;
    const descriptionNode = comment.querySelector(
      ".js-comment-show-description"
    );
    const descriptionEditNode = comment.querySelector(
      ".js-comment-edit-description"
    );
    const description = descriptionEditNode.value;
    const data = { description };

    ajax({
      method: "PUT",
      path: `/comments/${comment_id}`,
      data,
      success: (response) => {
        console.log(response);
        descriptionNode.innerHTML = simpleFormat(description);
        this.toggleEditComment(event);
        this.endSave();
      },
    });
  }

  toggleDeleteComment(event) {
    event.target.closest(".js-comment").classList.toggle("is-deletable");
  }

  destroyComment(event) {
    this.startSave();
    const comment = event.target.closest(".js-comment");
    const comment_id = comment.dataset.id;

    ajax({
      method: "DELETE",
      path: `/comments/${comment_id}`,
      success: (response) => {
        util.deleteElement(comment);
        this.endSave();
      },
    });
  }

  updateMilestone(event) {
    this.startSave();
    const selectElement = event.target.closest(".js-select");
    const milestone_id = selectElement.querySelector(".js-select-milestone")
      .dataset.value;
    const data = { milestone_id };

    ajax({
      method: "PUT",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}`,
      data,
      success: (response) => {
        // If the milestone is nowhere else to be found then no page reload.
        // location.reload();
        this.endSave();
      },
    });
  }

  updatePhase(event) {
    this.startSave();
    const selectElement = event.target.closest(".js-select");
    const phase_id = parseInt(
      selectElement.querySelector(".js-select-phase").dataset.value
    );
    const data = { phase_id };

    ajax({
      method: "PUT",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}`,
      data,
      success: (response) => {
        location.reload();
        this.endSave();
      },
    });
  }

  updateDueDate(event) {
    const due_date = event.target.value;
    const dueDateMoment = moment(due_date);
    util.delay(
      (event) => {
        this.startSave();
        let data;
        if (this.validDate(dueDateMoment)) {
          data = { due_date };
        } else {
          data = { due_date: null };
        }
        ajax({
          method: "PUT",
          path: `/projects/${this.projectSlug}/tasks/${this.taskId}`,
          data,
          success: (response) => {
            $(".js-task-show-due-date")[0].innerHTML =
              moment(due_date).format("MM/DD/YYYY");
            renderSuccess("New due date saved");
            this.endSave();
          },
        });
      },
      1000,
      "updateDueDate"
    );
  }

  openTask() {
    ajax({
      method: "PUT",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}/open`,
      success: (response) => {
        location.reload();
      },
    });
  }

  completeTask() {
    ajax({
      method: "PUT",
      path: `/projects/${this.projectSlug}/tasks/${this.taskId}/complete`,
      success: (response) => {
        location.reload();
      },
    });
  }

  // Helper
  validDate(date) {
    return (
      date.isValid() &&
      date >= moment("1980-01-01") &&
      date <= moment("2050-01-01")
    );
  }
}

const tasksShow = new TasksShow();
export default tasksShow;
