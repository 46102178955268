import * as React from "react";
import { CSSProperties } from "react";

import { styled } from "@plan/core";
import { AutoLayout, AutoLayoutProps } from "@plan/layout";

const StyledValue = styled("div", {
  fontWeight: "$normal",
  fontSize: "$fontSizes$4xl",
  lineHeight: "1",
  color: "$colors$neutral10",
  "@bpMd": {
    fontSize: "$fontSizes$5xl",
  },
  variants: {
    highlighted: {
      true: {
        fontWeight: "$semibold",
        color: "$colors$neutral40",
      },
    },
  },
});

const StyledLabel = styled("div", {
  fontWeight: "$normal",
  fontSize: "$fontSizes$sm",
  lineHeight: "1",
  color: "$colors$neutral10",
  paddingLeft: "$space$0_5",
});

interface FigureProps
  extends Pick<
      AutoLayoutProps,
      "css" | "className" | "resizingX" | "resizingY"
    >,
    React.ComponentProps<typeof StyledValue> {
  bottomLabel?: React.ReactNode;
  topLabel?: React.ReactNode;
  value: React.ReactNode;
  valueCss?: CSSProperties;
  labelCss?: CSSProperties;
}

export const Figure = ({
  bottomLabel = undefined,
  topLabel = undefined,
  value = undefined,
  highlighted = false,
  valueCss,
  labelCss,
  ...props
}: FigureProps) => (
  <AutoLayout
    as="div"
    direction="vertical"
    spacing="2"
    paddingY="2"
    paddingX="6"
    {...props}
  >
    {topLabel && <StyledLabel css={{ ...labelCss }}>{topLabel}</StyledLabel>}
    <StyledValue highlighted={highlighted} css={{ ...valueCss }}>
      {value}
    </StyledValue>
    {bottomLabel && (
      <StyledLabel css={{ ...labelCss }}>{bottomLabel}</StyledLabel>
    )}
  </AutoLayout>
);
