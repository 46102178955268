import { FC, useState } from "react";

import { styled } from "@plan/core";
import { Flex } from "@plan/layout";
import { Select, SelectOptionTypeBase, Textarea } from "@plan/components";

import {
  FilterOption,
  useTimerOrgDataQuery,
  useTimerPhaseDataQuery,
  useTimerProjectDataQuery,
} from "../../../../../../../apps/web/packages/app/generated/graphql";

import { FormButtons } from "./FormButtons";

import { useField, useFormikContext } from "formik";

type SelectOption = SelectOptionTypeBase | null;

const isStringValid = (val: string): string | void => {
  if (typeof val !== "string" || !val.length) return "This field is required";
};

const InputContainer = styled("div", {
  position: "relative",
  padding: "$space$4",
});

const TimerTextarea = styled(Textarea, {
  position: "relative",
  width: "calc(100% - $space$8)",
  margin: "0 $space$4 $space$4",
  padding: "$space$3",
  outline: "none",
  resize: "none",
  border: "1px solid $colors$-neutral40",
  borderRadius: "$radii$default",
  fontSize: "$fontSizes$lg",
  lineHeight: "1.25",
  "&::-webkit-input-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
    transition: "color 450ms $transition",
  },
  "&:focus::-webkit-input-placeholder": {
    color: "$colors$neutral",
  },
  "&::-ms-input-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
  },
  "&:focus::-ms-input-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
    transition: "opacity 450ms $transition",
  },
  "&::-moz-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
  },
  "&:focus::-moz-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
    transition: "opacity 450ms $transition",
  },
  "&:-moz-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
  },
  "&:focus:-moz-placeholder": {
    opacity: "1",
    color: "$colors$neutral",
    transition: "opacity 450ms $transition",
  },
  "&:disabled": {
    background: "$colors$-neutral50",
  },
});

const getCurrentOption = (options: FilterOption[], value: string) =>
  options.find((o) => o.value === value);

export const FormInputs: FC = () => {
  const {
    status: { intent, entryProjectId },
  } = useFormikContext();

  const isUpdatingEntry = intent === "update";

  const [projectId, setProjectId] = useState(entryProjectId || "");

  const [{ value: phaseId }, , { setValue: setPhaseId }] = useField({
    name: "phaseId",
    validate: isStringValid,
  });

  const [{ value: roleId }, , { setValue: setRoleId }] = useField("roleId");
  const [{ value: activityId }, , { setValue: setActivityId }] =
    useField("activityId");
  const [{ value: notes }, , { setValue: setNotes }] = useField("notes");

  const { data: orgData } = useTimerOrgDataQuery();

  const { data: projectData } = useTimerProjectDataQuery({
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const { data: phaseData } = useTimerPhaseDataQuery({
    variables: {
      phaseId,
    },
    skip: !phaseId,
  });

  const projects = orgData?.timer.projects || [];
  const activities = orgData?.activities || [];
  const phases = projectData?.timer.projectPhases || [];
  const roles = phaseData?.currentProfile?.roles || [];
  const projectOptions = projects.map((project) => {
    return {
      label: project.name,
      value: project.id,
    };
  });

  const activityOptions = activities.map((activity) => {
    return {
      label: activity.name,
      value: activity.id,
    };
  });

  const phaseOptions = phases.map((phase) => {
    return {
      label: phase.name,
      value: phase.id,
    };
  });

  const roleOptions = roles.map((role) => {
    return {
      label: role.name,
      value: role.id,
    };
  });

  if (roleOptions.length === 1 && roleId !== roleOptions[0].value) {
    setRoleId(roleOptions[0].value);
  }

  const handleProjectIdChange = (selected: SelectOption) => {
    const value = selected?.value || null;
    setProjectId(value);
  };

  const handlePhaseIdChange = (selected: SelectOption) => {
    const value = selected?.value || null;
    setPhaseId(value);
  };

  const handleRoleIdChange = (selected: SelectOption) => {
    const value = selected?.value || null;
    setRoleId(value);
  };

  const handleActivityIdChange = (selected: SelectOption) => {
    const value = selected?.value || null;
    setActivityId(value);
  };

  return (
    <>
      <InputContainer>
        <Select
          showLabel={false}
          label="Project Name"
          placeholder="Project"
          value={getCurrentOption(projectOptions, projectId)}
          onChange={handleProjectIdChange}
          options={projectOptions}
          isDisabled={isUpdatingEntry}
          css={{ margin: "0 0 $space$2" }}
        />
        <Select
          showLabel={false}
          label="Phase Name"
          placeholder="Phase"
          value={getCurrentOption(phaseOptions, phaseId)}
          onChange={handlePhaseIdChange}
          options={phaseOptions}
          isDisabled={!phaseOptions.length}
          css={{ margin: "0 0 $space$2" }}
        />
        <Flex>
          <Select
            showLabel={false}
            label="Role"
            placeholder="Role"
            value={getCurrentOption(roleOptions, roleId)}
            onChange={handleRoleIdChange}
            options={roleOptions}
            isDisabled={!roleOptions.length}
            css={{ margin: "0 $space$1 $space$2 0" }}
          />
          <Select
            showLabel={false}
            label="Activity"
            placeholder="Activity"
            value={getCurrentOption(activityOptions, activityId)}
            onChange={handleActivityIdChange}
            options={activityOptions}
            isDisabled={!activityOptions.length}
            css={{ margin: "0 0 $space$2 $space$1" }}
          />
        </Flex>
      </InputContainer>
      <TimerTextarea
        value={notes}
        label="Notes"
        showLabel={false}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        placeholder="Add a note (optional)"
        disabled={!projectId}
      />
      <FormButtons />
    </>
  );
};
