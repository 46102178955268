import { eventTracker } from "Blocks/tracker";
import Route from "Blocks/route";
import bindListenerToDocument from "Blocks/utils/bindListenerToDocument";
import ajax from "Blocks/utils/ajax";

class InvoicesIndex extends Route {
  initialLoad() {
    bindListenerToDocument("click", "js-toggle-paid", (event) => {
      this.toggleInvoiceIsPaid(event);
    });
    bindListenerToDocument("click", "js-select-project", (event) => {
      this.enableInvoiceCreation(event);
    });
  }

  load() {}

  beforeCache() {}

  toggleInvoiceIsPaid(event) {
    const button = event.target;
    const row = button.closest(".js-invoice");
    const { invoiceSlug } = row.dataset;
    const invoiceIsPaid = button.dataset.isPaid === "true";

    const data = {
      is_paid: !invoiceIsPaid,
    };

    ajax({
      method: "PUT",
      path: `/invoices/${invoiceSlug}`,
      data,
      success: (response) => {
        location.reload(true);
      },
    });
  }

  enableInvoiceCreation() {
    const createInvoiceButton = $(".js-create-invoice")[0];
    createInvoiceButton.classList.remove("is-disabled");
    createInvoiceButton.disabled = false;
  }
}

const invoicesIndex = new InvoicesIndex();
export default invoicesIndex;
