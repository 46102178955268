import React from "react";

const EditPhase = ({ disabled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.66667V12.6667C14 13.403 13.403 14 12.6667 14H3.33333C2.59695 14 2 13.403 2 12.6667V3.33333C2 2.59695 2.59695 2 3.33333 2H8.33333C8.51743 2 8.66667 2.14924 8.66667 2.33333V3C8.66667 3.18409 8.51743 3.33333 8.33333 3.33333H3.33333V12.6667H12.6667V7.66667C12.6667 7.48257 12.8159 7.33333 13 7.33333H13.6667C13.8508 7.33333 14 7.48257 14 7.66667ZM13.5267 2.19333C13.4025 2.07017 13.2349 2.00074 13.06 2H12.86C12.6851 2.00074 12.5175 2.07017 12.3933 2.19333L11.1933 3.39333C11.0663 3.52295 11.0663 3.73039 11.1933 3.86L12.14 4.80667C12.2696 4.93371 12.4771 4.93371 12.6067 4.80667L13.8067 3.60667C13.9298 3.48249 13.9993 3.31489 14 3.14V2.94C13.9993 2.76511 13.9298 2.59751 13.8067 2.47333L13.5267 2.19333ZM4.69333 10.86L5.22 9.52667C5.28584 9.35713 5.38566 9.20286 5.51333 9.07333L9.9 4.66667C10.0296 4.53962 10.2371 4.53962 10.3667 4.66667L11.3333 5.63333C11.4604 5.76295 11.4604 5.97039 11.3333 6.1L6.91333 10.4733C6.78381 10.601 6.62954 10.7008 6.46 10.7667L5.12667 11.2933C5.00281 11.3432 4.86115 11.3143 4.76667 11.22C4.67233 11.1255 4.64347 10.9839 4.69333 10.86Z"
      fill={disabled ? "#9CA3AF" : "#5840E0"}
    />
  </svg>
);

export default EditPhase;
