/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useDebounce } from "@plan/core";
import { Field, Badge } from "@plan/components";
import { ORG_FIELDS, TableWrapper } from "./Table";

export const SEARCH_ORGANIZATIONS = gql`
  query organizationSearch($query: String!, $isQualified: Boolean) {
    admin {
      organizationSearch(filter: { query: $query, isQualified: $isQualified }) {
        ...OrgFields
      }
    }
  }
  ${ORG_FIELDS}
`;

const SearchHeading = ({ query, numberOfResults }) => (
  <>
    Results for "{query}" <Badge value={numberOfResults} size="small" />
  </>
);

export const OrgSearch = ({ isQualified = true }) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);
  const [search, { data, loading, error }] = useLazyQuery(
    SEARCH_ORGANIZATIONS,
    {
      variables: { query: debouncedQuery, isQualified },
    }
  );

  useEffect(() => {
    if (debouncedQuery) search();
  }, [debouncedQuery]);

  const isLoading = query && loading && !data;
  const hasData = data && query;

  return (
    <>
      <div style={{ width: "33%" }}>
        <Field
          as="text"
          label="Search Organizations"
          placeholder="Organization Name..."
          handleChange={(e) => setQuery(e.target.value)}
          noFloatingLabel
        />
      </div>
      {(isLoading || error || hasData) && (
        <TableWrapper
          heading={
            <SearchHeading
              query={debouncedQuery}
              numberOfResults={data?.organizationSearch?.length ?? 0}
            />
          }
          loading={isLoading}
          error={error}
          data={data?.admin?.organizationSearch ?? []}
        />
      )}
    </>
  );
};

export default OrgSearch;
