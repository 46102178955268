import { isValidElement, ReactNode } from "react";

import { Formatter, FormatterProps } from "../Formatter";

const defaultFormat = {
  currencySign: "accounting",
};

const formatters = {
  // 1 => $1 // 1.60 => $1.60
  default: {
    ...defaultFormat,
  },
  // 1 => $1 // 1.60 => $2
  rounded: {
    ...defaultFormat,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
  // 1 => $1.00 // 1.60 => $1.60
  verbose: {
    ...defaultFormat,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  // 1000 => $1K
  abbreviated: {
    ...defaultFormat,
    notation: "compact",
    compactDisplay: "short",
  },
} as const;

type FormatValue = (
  value: number,
  localeTag: string,
  formatStyle: string,
  currencyCode: string,
  format: keyof typeof formatters,
  options?: Intl.NumberFormatOptions
) => string;

type UnstableFormatOptionValuesType =
  | "currencyDisplay"
  | "currencySign"
  | "style"
  | "notation";

export const getReducedFormatOptions = (
  numberFormatOptions: Intl.NumberFormatOptions
): Intl.NumberFormatOptions => {
  // Remove these key/values from numberFormatOptions
  const unstableFormatOptionValues: {
    [key in UnstableFormatOptionValuesType]: string;
  } = {
    currencyDisplay: "narrow",
    currencySign: "accounting",
    style: "unit",
    notation: "compact",
  };

  // Remove these keys from numberFormatOptions
  const unstableFormatOptions = ["signDisplay", "compactDisplay"];

  const reducedFormatOptions = {};

  for (const [key, value] of Object.entries(numberFormatOptions)) {
    if (
      unstableFormatOptionValues[key as UnstableFormatOptionValuesType] &&
      value ===
        unstableFormatOptionValues[key as UnstableFormatOptionValuesType]
    ) {
      continue;
    }
    if (unstableFormatOptions.includes(key)) {
      continue;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (reducedFormatOptions as any)[key] = value;
  }

  return reducedFormatOptions;
};

// In some OS / Safari 14.x combinations Intl.NumberFormat will throw an error
// on some params in the options object. If an error is thrown, we
// will try to remove some of those problematic keys or key/values
export const getFormattedValue = ({
  localeTag,
  formatStyle,
  currencyCode,
  format,
  options,
  value,
}: {
  localeTag: string;
  formatStyle: string;
  currencyCode: string;
  format: keyof typeof formatters;
  options?: Intl.NumberFormatOptions;
  value: number;
}) => {
  const numberFormatOptions = {
    style: formatStyle,
    currency: currencyCode,
    ...formatters[format],
    ...options,
  };

  try {
    return new Intl.NumberFormat(localeTag, numberFormatOptions).format(value);
  } catch (error) {
    console.warn(error);
  }

  const reducedFormatOptions = getReducedFormatOptions(numberFormatOptions);

  try {
    return new Intl.NumberFormat(localeTag, reducedFormatOptions).format(value);
  } catch {
    return new Intl.NumberFormat(localeTag).format(value);
  }
};

const formatValue: FormatValue = (
  value,
  localeTag,
  formatStyle,
  currencyCode,
  format,
  options
) => {
  const formattedValue = getFormattedValue({
    localeTag,
    formatStyle,
    currencyCode,
    format,
    options,
    value,
  });

  const needReformatting = value < -999;
  // Since notation 'compact' and currency sign 'accounting' doesn't transform -1000 => ($1k), we will have to handle this case separately
  if (needReformatting && format === "abbreviated") {
    // Remove minus sign
    const valueWithoutMinusSign = formattedValue.replace("-", "");
    // Adding parenthesis around the value
    return `(${valueWithoutMinusSign})`;
  }
  return formattedValue;
};

type FormatWithDefaults<
  T extends Record<string, string | number | Record<string, string>>,
  K
> = (defaults: T) => (args: Partial<T>) => K;

type FormatterOptions = {
  value: number;
  localeTag: string;
  formatStyle: string;
  currencyCode: string;
  format: keyof typeof formatters;
  options?: Record<string, string>;
};

const formatWithDefaults: FormatWithDefaults<FormatterOptions, string> =
  (defaults) => (args) => {
    const { value, localeTag, formatStyle, currencyCode, format, options } = {
      ...defaults,
      ...args,
    };
    return formatValue(
      value,
      localeTag,
      formatStyle,
      currencyCode,
      format,
      options
    );
  };

export type CustomFormatter = (
  formatFn: (c: Partial<FormatterOptions>) => string,
  options: FormatterOptions
) => ReactNode;

export type CurrencyProps = Omit<FormatterProps, "negative"> & {
  value: string | number;
  format?: keyof typeof formatters;
  symbol?: boolean | ReactNode;
  localeTag?: string;
  currencyCode?: string;
  as?: ReactNode;
  customFormatter?: CustomFormatter;
  customFormatOptions?: Record<string, string>;
};

export const Currency = ({
  value,
  symbol = true,
  format = "default",
  localeTag = "en-US",
  currencyCode = "USD",
  customFormatOptions = {},
  customFormatter,
  ...props
}: CurrencyProps) => {
  // Convert string value to number and removing commas before converting
  const numberValue =
    typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : value;
  const isNegative = numberValue < 0;

  const formatCurrency = (value: number, format: keyof typeof formatters) => {
    const formatStyle = symbol ? "currency" : "decimal";

    const formattedValue = formatValue(
      value,
      localeTag,
      formatStyle,
      currencyCode,
      format,
      customFormatOptions
    );

    const options = {
      value,
      localeTag,
      formatStyle,
      currencyCode,
      format,
      customFormatOptions,
    };

    if (customFormatter) {
      return customFormatter(formatWithDefaults(options), options);
    }

    // Add custom elements outside of the numeral formatting
    if (isValidElement(symbol) || typeof symbol === "string")
      return `${symbol}${formattedValue}`;

    return formattedValue;
  };

  const amount = formatCurrency(numberValue, format);
  return (
    <Formatter negative={isNegative} {...props}>
      {amount}
    </Formatter>
  );
};
