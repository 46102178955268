import * as React from "react";

import { createComponent, keyframes, styled, theme } from "@plan/core";

const component = createComponent();

const loading = keyframes({
  "100%": { transform: "translateX(100%)" },
});

const LoadingSkeleton = styled("div", {
  ...component,
  position: "relative",
  borderRadius: "$radii$default",
  overflow: "hidden",
  "-ms-overflow-style": "none" /* IE and Edge */,
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  variants: {
    ...component.variants,
    animated: {
      true: {
        "&::after": {
          position: "absolute",
          animation: `${loading} 750ms infinite`,
          background:
            "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent)",
          backgroundRepeat: "no-repeat",
          content: "",
          display: "block",
          height: "100%",
          transform: "translateX(-100%)",
          width: "125%",
        },
      },
    },
  },
});

export type SkeletonProps = React.ComponentProps<typeof LoadingSkeleton> & {
  width?: string;
  height?: string;
  backgroundColor?: keyof typeof theme.colors;
};

export const Skeleton = ({
  width = "100%",
  height = "100%",
  backgroundColor = "-neutral40",
  animated = true,
  css,
  ...props
}: SkeletonProps) => (
  <LoadingSkeleton
    css={{
      width,
      height,
      backgroundColor: `$colors$${backgroundColor}`,
      ...css,
    }}
    animated={animated}
    {...props}
  />
);
