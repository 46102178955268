import { useEffect, useState } from "react";

export type FeedbackIntent = "success" | "error" | null;

export const useInputFeedback = () => {
  const [intent, setIntent] = useState<FeedbackIntent>(null);

  useEffect(() => {
    if (intent) {
      setTimeout(() => {
        setIntent(null);
      }, 100);
    }
  }, [intent]);

  return [intent, setIntent] as const;
};
