import React, { useState, useEffect } from "react";
import { Field } from "@plan";
import { components } from "react-select";
import styles from "./multiselect.module.css";

const ValueContainer = (props) => {
  if (!props.hasValue)
    return (
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    );
  return (
    <components.ValueContainer {...props}>
      {props.selectProps.selectedLabel}{" "}
      <div className={styles.badge}>{props.getValue().length}</div>
    </components.ValueContainer>
  );
};

export const MultiSelect = ({
  options: optionList,
  size,
  placeholder,
  component,
  selectedLabel,
  handleChange: _handleChange,
  ...props
}) => {
  const [selected, setSelected] = useState();
  const [options, setOptions] = useState(optionList);

  const handleChange = (e) => {
    setSelected(e);
    _handleChange(e);
  };

  useEffect(() => {
    const isNotSelected = (list, selectedOptions) =>
      list.filter(
        (item) => !selectedOptions.some((option) => item.value === option.value)
      );
    const groupSelectedOptions = (list, selectedOptions) => {
      if (!selectedOptions || !selectedOptions.length) return setOptions(list);
      const unselectedOptions = isNotSelected(list, selectedOptions);
      const groupedOptions = [
        { label: "Selected", options: selectedOptions },
        { label: "Not Selected", options: unselectedOptions },
      ];
      return setOptions(groupedOptions);
    };
    groupSelectedOptions(optionList, selected);
  }, [selected, optionList]);

  return (
    <Field
      as="select"
      size="small"
      placeholder={placeholder}
      isMulti
      selectedLabel={selectedLabel}
      // closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={options}
      components={{ ValueContainer }}
      handleChange={handleChange}
      {...props}
    />
  );
};

export default MultiSelect;
