import { defaultFilters } from "Components/schedule/Schedule";

import React, { useMemo, useCallback } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import {
  useAllocations,
  usePhases,
  usePhaseOrder,
  useSelectedPhase,
  useRoles,
  useGridView,
} from "../../state/Store";
import CardSwitch from "../cards/CardSwitch";
import styles from "./columns.module.css";

const ColumnWrap = ({ children }) => {
  const [selectedPhase, setSelectedPhase] = useSelectedPhase();
  const gridView = useGridView();

  const deselectPhase = useCallback(
    (e) => {
      // Each card has a data-card attribute that holds the phaseId
      const phaseOfCard = e.target
        .closest("[data-card]")
        ?.getAttribute("data-card");
      // Allow people to interact with cards that are selected
      if (phaseOfCard === selectedPhase) return;
      // Otherwise deselect the phase and return the grid to the default state
      setSelectedPhase(defaultFilters.selectedPhaseId);
    },
    [selectedPhase]
  );

  const classes = useMemo(
    () =>
      cx(
        styles.column,
        { [styles.selected]: selectedPhase },
        { [styles.rowColumn]: gridView === "row" }
      ),
    [selectedPhase, gridView]
  );

  // Use onTap provided from motion so we can cancel it onDrag
  return (
    <motion.div className={classes} onTap={deselectPhase}>
      {children}
    </motion.div>
  );
};

function Column({ id, i }) {
  const allocations = useAllocations();
  const phases = usePhases();
  const roles = useRoles();
  const phaseOrder = usePhaseOrder();
  const [selectedPhase] = useSelectedPhase();

  const getAllProfileEntries = useCallback((entries, profileId) => {
    const entryIds = Object.keys(entries);
    const profileEntryIds = entryIds.filter(
      (entry) =>
        entries[entry].profileId === profileId && entries[entry].phaseId
    );
    return profileEntryIds;
  }, []);

  const allEntries = useMemo(
    () => getAllProfileEntries(allocations, id),
    [allocations, id]
  );

  return (
    <ColumnWrap totalColumns={allEntries.length}>
      {phaseOrder.map((phase) => (
        <CardSwitch
          key={`${i}-${phase}-${id}`}
          allEntries={allEntries}
          phase={phase}
          selectedPhase={selectedPhase}
          allocations={allocations}
          phases={phases}
          roles={roles}
        />
      ))}
    </ColumnWrap>
  );
}

export default React.memo(Column);
