import React from "react";
import styles from "./project-search.module.css";
// import { useGlobalState } from "./state/GlobalContext.jsx";

const Option = ({ phaseName, roleName, abbreviation, showRoleName }) => {
  return (
    <div>
      <div className={styles.phase}>
        {abbreviation} • {phaseName} {showRoleName && `• ${roleName}`}
      </div>
    </div>
  );
};

export default Option;
