import * as React from "react";

import { styled } from "@plan/core";

interface Bar {
  width: number;
  height: number;
  x: number;
  y: number;
  color: string;
  key?: string | number;
}

export type BarSegmentProps = React.ComponentProps<typeof Rect> & {
  bar: Bar;
  rx?: number;
  skinny?: boolean | number;
  gap?: number;
  hoverScale?: number;
  horizontal?: boolean;
};

const Rect = styled("rect", {
  cursor: "pointer",
  opacity: 0.66,
  overflow: "hidden",
  "&:hover": {
    opacity: 1,
    filter: "drop-shadow(0 2px 8px rgba(0,0,0,.075))",
  },
  variants: {
    isHighlighted: {
      true: {
        opacity: 1,
      },
    },
  },
});

/**
 * ## Description
 * BarSegment is used to compose Bar charts. Use BarSegment to compose simple bar chart or use BarStack to compose stacked bar chart.
 *
 * ## BarSegment props:
 * - `bar`: (Required) Bar object that contains the following props:
 *  - `width`: (Required) Width of the bar.
 *  - `height`: (Required) Height of the bar.
 *  - `x`: (Required) X position of the bar.
 *  - `y`: (Required) Y position of the bar.
 *  - `color`: (Required) Bar color.
 *  - `key`: (Required) Bar key.
 * - `rx`: Top corners radius of the bar.
 * - `skinny`: Whether the bar should be skinnier than default width, or a number to customize the width ratio. Default to true (ratio = 0.2)
 * - `hoverScale`: The number by how much should the bar scale up on hover. Currently deprecated.
 * - `isHighlighted`: Sets opacity of the bar to 1.
 *
 * ## BarStack props:
 * - `data`: (Required) Array of data for which generates a stack.
 * - `keys`: Array of keys corresponding to stack layers.
 * - `x`: (Required) Returns the value mapped to the x of a bar.
 * - `value`: Sets the value accessor for a Datum, which defaults to dkey.
 * - `xScale`: (Required) visx scale that takes an x value and maps it to an x axis position.
 * - `yScale`: (Required) visx scale that takes a y value and maps it to an y axis position.
 * - `color`: (Required) Returns the desired color for a bar with a given key and index.
 *
 * For more information about BarStack and it's props, visit https://airbnb.io/visx/docs/shape#BarStack
 *
 */
export const BarSegment = React.forwardRef<SVGRectElement, BarSegmentProps>(
  (
    {
      bar,
      rx = 0,
      hoverScale = 1.5,
      skinny = true,
      isHighlighted = false,
      horizontal = false,
      gap = 0,
      ...props
    },
    forwardRef
  ) => {
    let height, width, x, y;

    // Measurements
    if (horizontal === false) {
      height = bar.height > gap ? bar.height - gap : bar.height;
      y = bar.y;

      // since we want the bar to be skinnier than the default width
      // we take the default x and width and calc position based on our new, desired width
      const skinnyRatio: number = typeof skinny === "number" ? skinny : 0.2;
      width = skinny ? bar.width * skinnyRatio : bar.width;
      x = skinny ? bar.x + bar.width / 2 - width / 2 : bar.x;
    }

    if (horizontal === true) {
      x = bar.x;
      width = bar.width;

      height = bar.height;
      y = bar.y;
    }

    return (
      <g ref={forwardRef}>
        <Rect
          ref={forwardRef}
          x={x}
          y={y}
          height={height}
          width={width}
          fill={bar.color}
          isHighlighted={isHighlighted}
          // Since we have decided not to do the scale animation, we could either remove this or leave for future use
          css={{ $$hoverScale: hoverScale, $$width: width }}
          {...props}
        />
        {rx && (
          <Rect
            x={x}
            y={y}
            width={width}
            height={rx + rx}
            rx={rx}
            fill={bar.color}
            isHighlighted={isHighlighted}
            {...props}
          />
        )}
      </g>
    );
  }
);
