import React, { useCallback, useMemo } from "react";
import Tooltip, { useTooltip } from "Components/blocks/tooltip/Tooltip";
import cx from "classnames";
import { formatPercent } from "Utils/util";
import numeral from "numeral";
import PropTypes from "prop-types";
import styles from "./progress.module.css";

export function Progress({ consumed, total, average, allocated }) {
  // Setup the tooltips for each section of the bar
  const [progTooltip, progNode] = useTooltip();
  const [avgTooltip, avgNode] = useTooltip();
  const [remTooltip, remNode] = useTooltip();
  const [overTooltip, overNode] = useTooltip();
  const [allocationTooltip, allocNode] = useTooltip();

  function limitToRange(value, min = 0, max = 100) {
    if (value === null || isNaN(value) || value === undefined) return min;
    if (value > max) return max;
    return value;
  }

  // Calculate values and percentages for display
  const remaining = useMemo(
    () => Math.max(0, total - consumed - allocated),
    [total, consumed, allocated]
  );
  const overage = useMemo(() => {
    if (consumed > total) return consumed - total;
    return 0;
  }, [total, consumed]);

  const consumedDecimal = useMemo(() => consumed / total, [consumed, total]);
  const remainingDecimal = useMemo(() => remaining / total, [remaining, total]);
  const averageDecimal = useMemo(() => average / total, [average, total]);
  const overageDecimal = useMemo(() => {
    if (!overage || overage < 0) return 0;
    return overage / total;
  }, [overage, total]);

  const allocationDecimal = useMemo(
    () => allocated / total,
    [allocated, total]
  );

  const totalHoursWidth = useMemo(() => {
    if (!overage) return total;
    return overage + total;
  }, [overage, total]);
  const overageWidth = useMemo(() => {
    if (!overage) return 0;
    return formatPercent(overage / totalHoursWidth);
  }, [overage, totalHoursWidth]);
  const meterWidth = formatPercent(total / totalHoursWidth);

  const consumedPercent = useMemo(() => {
    if (consumed > total) return 100;
    return limitToRange(consumedDecimal * 100);
  }, [consumedDecimal, consumed, total]);

  const remainingPercent = useMemo(
    () => limitToRange(remainingDecimal * 100),
    [remainingDecimal]
  );
  const averagePercent = useMemo(
    () => limitToRange(averageDecimal * 100),
    [averageDecimal]
  );
  const overagePercent = useMemo(
    () => limitToRange(overageDecimal * 100),
    [overageDecimal]
  );

  const allocationPercent = useMemo(
    () => limitToRange(allocationDecimal * 100),
    [allocationDecimal]
  );

  // Create the tooltip text
  const toolTipText = useCallback(
    (amount, percent) =>
      `${numeral(amount).format("0.[00]")} (${numeral(
        formatPercent(percent)
      ).format("0.[00]")}%)`,
    [formatPercent]
  );

  const consumedToolTip = useMemo(
    () => toolTipText(consumed, consumedDecimal),
    [consumed, consumedPercent]
  );
  const remainingToolTip = useMemo(
    () => toolTipText(remaining, remainingDecimal),
    [consumed, consumedPercent]
  );
  const averageToolTip = useMemo(
    () => toolTipText(average, averageDecimal),
    [consumed, consumedPercent]
  );
  const overageToolTip = useMemo(
    () => toolTipText(overage, overageDecimal),
    [consumed, consumedPercent]
  );
  const allocatedToolTip = useMemo(
    () => toolTipText(allocated, allocationDecimal),
    [allocated, consumedPercent]
  );

  return (
    <>
      <div
        className={styles.progress}
        style={{
          "--overage-width": `${overageWidth}%`,
          "--meter-width":
            !meterWidth && !overageWidth ? "100%" : `${meterWidth}%`,
        }}
      >
        <div className={styles.meter}>
          <div
            className={cx(styles.consumed, styles.meterSection)}
            ref={progNode}
            style={{ width: `${consumedPercent}%` }}
          />
          {allocationPercent > 0 && !overagePercent && (
            <div
              className={cx(styles.allocated, styles.meterSection)}
              ref={allocNode}
              style={{
                width: `${allocationPercent}%`,
                "--background-color": "var(--project-color)",
              }}
            />
          )}
          <div
            className={cx(styles.remaining, styles.meterSection)}
            ref={remNode}
            style={{ width: `${remainingPercent}%` }}
          />
          {averagePercent > 0 && (
            <div
              className={styles.averaged}
              ref={avgNode}
              style={{ left: `${averagePercent}%` }}
            />
          )}
          <Tooltip
            heading="Consumed Hours"
            text={consumedToolTip}
            key="progress"
            tooltip={progTooltip}
          />
          <Tooltip
            heading="Remaining Hours"
            text={remainingToolTip}
            key="remaining"
            tooltip={remTooltip}
          />
          <Tooltip
            heading="Allocated this Week"
            text={allocatedToolTip}
            key="allocation"
            tooltip={allocationTooltip}
          />
          <Tooltip
            heading="Forecasted Pace"
            text={averageToolTip}
            key="avg"
            tooltip={avgTooltip}
          />
        </div>
        <div
          className={styles.overageMeter}
          style={{ width: `${overagePercent}%` }}
        >
          <Tooltip
            heading="Overage Hours"
            text={overageToolTip}
            key="overage"
            tooltip={overTooltip}
          />
          <div
            ref={overNode}
            className={cx(styles.overage, styles.meterSection)}
            style={{ width: "100%" }}
          />
          {allocationPercent > 0 && overagePercent > 0 && (
            <div
              className={cx(styles.allocated, styles.meterSection)}
              ref={allocNode}
              style={{
                cursor: "pointer",
                width: `${allocationPercent}%`,
                "--background-color": "var(--color-red)",
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

Progress.propTypes = {
  consumed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  average: PropTypes.number,
};

Progress.defaultProps = {
  average: null,
};

export default React.memo(Progress);
