import * as React from "react";

import type { AsChildProp } from "@plan/core";
import { styled } from "@plan/core";

import { useField } from "./useField";

import { Slot } from "@radix-ui/react-slot";

const FORM_DESCRIPTION_ELEMENT = "div";

export const StyledFieldDescription = styled(FORM_DESCRIPTION_ELEMENT, {
  textStyle: "small",
  color: "$colors$neutral10",
  variants: {
    disabled: {
      false: {},
      true: { color: "$colors$-neutral10" },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export interface FieldDescriptionProps
  extends AsChildProp,
    Omit<React.ComponentProps<typeof StyledFieldDescription>, "as"> {}

export const FieldDescription = React.forwardRef<
  HTMLDivElement,
  FieldDescriptionProps
>(({ asChild, children, ...props }, ref) => {
  const Comp = asChild ? Slot : FORM_DESCRIPTION_ELEMENT;

  const { getFieldDescriptionProps, setHasFieldDescription } = useField();
  const formDescriptionProps = getFieldDescriptionProps(props);

  React.useEffect(() => {
    setHasFieldDescription(true);

    return () => {
      setHasFieldDescription(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledFieldDescription ref={ref} as={Comp} {...formDescriptionProps}>
      {children}
    </StyledFieldDescription>
  );
});
