import React from "react";
import { useColumnHeaders } from "./useTableData";
import styles from "./table.module.css";

export default function ColumnHeaders({ group }) {
  const groupColumnHeaders = useColumnHeaders(group);
  return (
    <>
      <div className={styles.columnHeaderWrapper}>
        <div className={styles.columnTitleWrapper}>
          {groupColumnHeaders.map((columnTitle, key) => {
            return (
              <span className={styles.columnTitle} key={key}>
                {columnTitle}
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
}
