// check whether any of the other filteredOption have same phase, different role
// options array of objects
export const shouldShowRoleName = (phaseId, options) => {
  // compare with other groups in list of projectItems
  const samePhaseOptions = options.filter(
    (option) => phaseId === option.phaseId
  );
  const hasMultipleRolesInPhase = samePhaseOptions.length > 1;
  return hasMultipleRolesInPhase;
};

export const calculateColumn = (date, entry) => {};

export const nextEntry = (dir, entryId, date) => {};

export const hexToRgb = (hex) => {
  if (!hex) {
    return;
  }
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
};
