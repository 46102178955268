import { FC } from "react";

import { CreateEntryInput } from "../../../../../../../apps/web/packages/app/generated/graphql";
import { useTimerContext } from "../../../contexts/TimerContext";

import { FormInputs } from "./FormInputs";

import { Formik } from "formik";

export const CreateEntryForm: FC = () => {
  const {
    actions: { createTimerEntry },
  } = useTimerContext();

  return (
    <Formik<CreateEntryInput>
      initialValues={{
        phaseId: "",
        date: new Date().toString(),
      }}
      initialStatus={{ intent: "create" }}
      onSubmit={createTimerEntry}
    >
      <FormInputs />
    </Formik>
  );
};
