import { useEffect, useState } from "react";
import * as React from "react";
import ReactSelect, {
  ActionMeta,
  OptionsType,
  OptionTypeBase,
} from "react-select";

import { MultiValue, multiValueStyle } from "./elements/MultiValue";
import { MultiValueLabel } from "./elements/MultiValueLabel";
import { BaseSelectProps } from "./Select";

import { partition } from "lodash";

export const AsyncMultiSelect = ({
  multiSelectLabel,
  loadOptions,
  onChange: handleChange,
  components: propsComponents,
  styles,
  ...props
}: BaseSelectProps) => {
  const components = {
    MultiValue,
    MultiValueLabel,
    ...propsComponents,
  };

  const [options, setOptions] = useState<OptionTypeBase[]>([]);
  const [searchResultOptions, setSearchResultOptions] = useState<
    OptionTypeBase[] | null
  >();
  const [selectedOptions, unselectedOptions] = partition(
    options,
    (option) => option.isSelected
  );

  // Load initial values
  useEffect(() => {
    if (!loadOptions) return;

    loadOptions("").then((options) => {
      setOptions(options);
    });
  }, [loadOptions]);

  const displayOptionsList =
    selectedOptions.length > 0
      ? [
          { label: "Selected", options: selectedOptions },
          { label: "Unselected", options: unselectedOptions },
        ]
      : options;

  const onInputChange = (inputValue: string) => {
    if (!loadOptions) return;

    loadOptions(inputValue).then((options) => {
      // When searching, display results in a separate state
      if (inputValue) {
        setSearchResultOptions(options);
      } else {
        setSearchResultOptions(null);
        setOptions(options);
      }
    });
  };

  const onChange = (
    value: OptionTypeBase | OptionsType<OptionTypeBase> | null,
    inputAction: ActionMeta<OptionTypeBase>
  ) => {
    if (handleChange) handleChange(value, inputAction);
  };

  return (
    // Although we're implementing an async behavior, we're not using the `ReactSelect` async component itself,
    // as it does have some limitations we can't really work around. Instead, we're using the standard multi
    // `ReactSelect` component and implementing the async behavior ourselves.
    <ReactSelect
      isMulti={true}
      hideSelectedOptions={false}
      multiSelectLabel={multiSelectLabel}
      closeMenuOnSelect={false}
      value={selectedOptions}
      filterOption={() => true}
      components={components}
      onInputChange={onInputChange}
      onChange={onChange}
      styles={{
        ...styles,
        multiValue: (base) => ({
          ...base,
          ...multiValueStyle,
        }),
      }}
      {...props}
      options={searchResultOptions || displayOptionsList}
    />
  );
};
