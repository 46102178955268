import util from "Blocks/utils/util";
import ajax from "Blocks/utils/ajax";
import React from "react";
import autosize from "autosize";

export default class ProjectsEditNotes extends React.Component {
  constructor(props) {
    super(props);

    this.updateNotesState = this.updateNotesState.bind(this);

    this.state = {
      notes: this.props.project.notes || "",
    };
  }

  componentDidMount() {
    autosize(this.refs.textarea);
  }

  componentDidUpdate() {
    autosize.update(this.refs.textarea);
  }

  updateNotesState(event) {
    util.startSave();
    let path;
    let data;

    if (this.props.isTemplate) {
      path = `/api/v1/projects/templates/${this.props.project.slug}`;
      data = { project_template: { notes: event.target.value } };
    } else {
      path = `/projects/${this.props.project.slug}`;
      data = { notes: event.target.value };
    }

    this.setState({ notes: event.target.value });
    util.delay(
      () => {
        ajax({
          method: "PUT",
          path: path,
          data: data,
          success: (response) => {
            console.log(response);
            util.endSave();
          },
        });
      },
      600,
      "updateNotes"
    );
  }

  render() {
    return (
      <div className="grid">
        <div className="grid-xs-12">
          <textarea
            className="project-edit__notes"
            value={this.state.notes}
            onChange={this.updateNotesState}
            ref="textarea"
          />
        </div>
      </div>
    );
  }
}
