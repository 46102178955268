import { CSSProperties, FC, ReactNode } from "react";

import { createComponent, styled } from "@plan/core";

import { Skeleton } from "../Skeleton";

import { clamp } from "lodash";

const component = createComponent();

const Container = styled("div", {
  ...component,
  display: "flex",
  gap: "$space$7",
});

const StepContainer = styled("div", {
  width: "100%",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  gap: "$space$2_5",

  justifyContent: "space-between",
});

const StepIndicator = styled("span", {
  width: "100%",
  height: "$space$2_5",
  borderRadius: "$space$7",
  display: "block",
  position: "relative",
  background: "$colors$-neutral20",
});

const StepIndicatorFill = styled("span", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "0%",
  height: "100%",
  borderRadius: "inherit",
  background: "$colors$brand",

  variants: {
    disableAnimation: {
      false: {
        transition: "width 0.3s ease-in-out",
      },
    },
    completed: {
      true: {
        width: "100%",
      },
      false: {
        width: "0%",
      },
    },
  },
  compoundVariants: [],
});

const StepLabel = styled("h2", {
  fontSize: "$fontSizes$xl",
  fontWeight: "$fontWeights$semibold",
  lineHeight: "$lineHeights$tight",
});

const StepDescription = styled("p", {
  lineHeight: "$lineHeights$tight",
  color: "$colors$neutral10",
});

type StepProps = {
  completed: boolean;
  label: ReactNode;
  description?: ReactNode;
  disableAnimation?: boolean;
  loading?: boolean;
};

const isString = (value: ReactNode): value is string => {
  return typeof value === "string";
};

const Step: FC<StepProps> = ({
  completed,
  description,
  label,
  disableAnimation,
  loading,
}) => {
  const labelComponent = isString(label) ? (
    <StepLabel>{label}</StepLabel>
  ) : (
    label
  );

  let descriptionComponent: ReactNode = null;
  if (description) {
    if (isString(description)) {
      descriptionComponent = <StepDescription>{description}</StepDescription>;
    } else {
      descriptionComponent = description;
    }
  }

  return (
    <StepContainer>
      {loading ? (
        <Skeleton
          css={{
            width: "10rem",
            height: "$fontSizes$lg",
          }}
        />
      ) : (
        labelComponent
      )}

      {loading ? (
        <Skeleton
          css={{
            width: "15rem",
            height: "$fontSizes$lg",
          }}
        />
      ) : (
        descriptionComponent
      )}

      <StepIndicator>
        {loading ? (
          <Skeleton css={{ height: "100%" }} />
        ) : (
          <StepIndicatorFill
            completed={completed}
            disableAnimation={disableAnimation}
          />
        )}
      </StepIndicator>
    </StepContainer>
  );
};

type StepperProps = {
  steps: Omit<StepProps, "completed" | "disableAnimation">[];
  currentStep?: number;
  disableAnimation?: boolean;
  css?: CSSProperties;
  loading?: boolean;
};

export const Stepper = ({
  steps,
  currentStep = 0,
  disableAnimation,
  css,
  loading,
}: StepperProps) => {
  return (
    <Container css={{ ...css }}>
      {steps.map((step, index) => (
        <Step
          key={index}
          completed={index <= clamp(currentStep, 0, steps.length - 1)}
          label={step.label}
          description={step.description}
          disableAnimation={disableAnimation}
          loading={loading}
        />
      ))}
    </Container>
  );
};
