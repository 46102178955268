// @todo: remove ts-nocheck and fix types
// @ts-nocheck | don't check this for now

import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  Row,
  SortingRule,
  useExpanded,
  UseExpandedOptions,
  useRowSelect,
  UseRowSelectRowProps,
  useSortBy,
  useTable,
} from "react-table";

import { ICSSProp, TCSS, VariantProps } from "@plan/core";
import { IconDragAndDrop } from "@plan/icons";
import { AutoLayout, Box } from "@plan/layout";

import { Expander } from "../Expander";
import { Heading } from "../Heading";
import { Skeleton } from "../Skeleton";

import { ConditionalDragDropRow } from "./elements/dragDrop/ConditionalDragDropRow";
import { ConditionalDragDropWrapper } from "./elements/dragDrop/ConditionalDragDropWrapper";
import { DeepPaths } from "./types/deepKeys";
import { checkboxColumn, TableEmptyState } from "./elements";
// Importing styled table components
import {
  SortSVG,
  TableBase,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeading,
  TableOverflowWrapper,
  TableRow,
  TableWrapper,
} from "./TableComponents";

import { DragEndEvent, DragOverEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { isEmpty } from "lodash";
// React table docs (v7)  https://react-table-v7.tanstack.com/

export type SortByProps = SortingRule<string>;
export interface ColumnProps {
  align?: "left" | "center" | "right";
  columnPadding?: "xs" | "sm" | "md";
  disableSortBy?: boolean;
  /**
   * Used in conjunction with toggleable columns when
   * a toggleable column has a non-string Header
   */
  label?: string;
  nowrap?: boolean;
  cellCss?: TCSS;
  position?: "fixed" | "fixedLeft";
  preventToggle?: boolean;
  isVisible?: boolean;
}

export type ColumnSort = {
  id: string;
  desc: boolean;
};

export type ColumnInput = Record<string, unknown>;
export type ColumnShape<T extends ColumnInput = ColumnInput> = Omit<
  Column<T>,
  "Cell" | "accessor"
> & {
  Cell?: React.ComponentType<CellProps<T>> | undefined;
  accessor?: DeepPaths<T> | ((entry: T) => unknown);
} & ColumnProps &
  ICSSProp;
export type SelectedRow = Row<ColumnProps & ColumnInput>;
export type RowSelectRowProps<T extends Record<string, unknown>> = Row<T> &
  UseRowSelectRowProps<T>;

interface OrderChangeCallback {
  id: string;
  newPosition: number;
}

// Note: We extend stitches variants of components that have variants controlled by passed props.
type TableProps<T extends ColumnInput = ColumnInput> = VariantProps<
  typeof TableBase | typeof TableBody | typeof TableHeading | typeof TableCell
> &
  ICSSProp & {
    columns: ColumnShape<T>[];
    data: T[];
    emptyState?: React.ReactNode;
    showFooter?: boolean;
    showHeader?: boolean;
    sticky?: boolean;
    // eslint-disable-next-line
    renderRowSubComponent?: Function | null;
    allExpandable?: boolean;
    tableHookArgs?: UseExpandedOptions<T>;
    handleSort?: (sortBy: SortByProps) => void;
    loading?: boolean;
    skeletonCount?: number;
    withPagination?: boolean;
    isDragDroppable?: boolean;
    onDragEnd?: () => void;
    onOrderChange?: (newPositionInfo: OrderChangeCallback) => void;
    intent?: "primary" | "secondary";
    onRowSelection?: (rows: SelectedRow[]) => void;
    allowDeselectAll?: boolean;
    tableHeader?: string;
    // FIXME: drop on first refactor
    // eslint-disable-next-line
    initialState?: { sortBy: ColumnSort[] };
  };

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const EMPTY_ARRAY = [];

export function Table<T extends Record<string, unknown>>({
  columns,
  data,
  emptyState = "No Data",
  showFooter = true,
  showHeader = true,
  sticky = false,
  allExpandable = false,
  renderRowSubComponent,
  handleSort,
  isDragDroppable,
  onDragEnd,
  onOrderChange,
  loading = false,
  skeletonCount = 5,
  withPagination = false,
  tableHookArgs,
  intent = "primary",
  onRowSelection,
  allowDeselectAll = false,
  tableHeader,
  initialState = { sortBy: [] },
  ...props
}: TableProps<T>) {
  const manualSort = typeof handleSort !== "undefined";

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    visibleColumns,
    // FIXME: drop on first refactor
    // eslint-disable-next-line
    // @ts-ignore
    selectedFlatRows,
    // FIXME: drop on first refactor
    // eslint-disable-next-line
    // @ts-ignore
    state: { sortBy },
  } = useTable<ColumnProps & ColumnInput>(
    {
      // FIXME: drop on first refactor
      // eslint-disable-next-line
      // @ts-ignore
      columns: columns || EMPTY_ARRAY,
      // eslint-disable-next-line
      // @ts-ignore
      data: data || EMPTY_ARRAY,
      manualSortBy: manualSort,
      autoResetPage: !manualSort,
      autoResetSortBy: !manualSort,
      // FIXME: drop on first refactor
      // eslint-disable-next-line
      // @ts-ignore
      initialState: initialState,
      ...tableHookArgs,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    // adds row selection checkbox component
    (hooks) => {
      if (!onRowSelection) return;
      hooks.visibleColumns.push((columns) => [
        checkboxColumn({ allowDeselectAll }),
        ...columns,
      ]);
    }
  );

  const initialDataOrder = useMemo(() => data.map((datum) => datum.id), [data]);

  const [idOrder, setIdOrder] = useState(initialDataOrder);

  // Don't mess with `useEffect`-ing `data` if you can help it --
  // it might work in dev and break on prod/staging. Who knows.
  useEffect(() => {
    if (initialDataOrder.length !== idOrder.length) {
      setIdOrder(initialDataOrder);
    }
  }, [initialDataOrder, idOrder]);

  // Handle update when rows are selected
  useEffect(() => {
    if (!onRowSelection) return;

    onRowSelection(selectedFlatRows);
  }, [selectedFlatRows, onRowSelection]);

  // Store new sort state and call handleSort to fetch new data
  useEffect(() => {
    handleSort?.(sortBy?.[0]);
  }, [sortBy, handleSort]);

  if (loading)
    return (
      <>
        {[...new Array(skeletonCount)].map((_, i) => (
          <Skeleton
            width="100%"
            height="$space$14"
            key={`row${i}`}
            css={{ marginBottom: "$space$2" }}
          />
        ))}
      </>
    );

  // If data is empty with emptyState message, then display empty message
  if (isEmpty(data) && emptyState)
    return (
      <TableWrapper withPagination={withPagination}>
        <TableEmptyState>{emptyState}</TableEmptyState>
      </TableWrapper>
    );

  // If data is not array and if its empty, return nothing
  if (isEmpty(data)) return null;

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = idOrder.findIndex((id) => id === active.id);
    const newIndex = idOrder.findIndex((id) => id === over.id);

    setIdOrder(arrayMove(idOrder, oldIndex, newIndex));
  };

  // Callback for when an element gets dropped
  const handleDragEnd = (event: DragEndEvent) => {
    if (!isDragDroppable) return;

    const { active, over } = event;

    if (onDragEnd) {
      onDragEnd();
    }

    if (!over) return;

    const newIndex = idOrder.findIndex((id) => id === over.id);

    if (onOrderChange) {
      onOrderChange({
        id: active.id as string,
        // positions in the DB are 1-indexed smh
        newPosition: newIndex + 1,
      });
    }
  };

  let sortedRows = rows;

  if (isDragDroppable) {
    sortedRows = idOrder
      .map((id) => rows.find((row) => row.original.id === id))
      .filter((row) => row !== undefined) as Row<ColumnProps & ColumnInput>[];
  }

  return (
    <TableOverflowWrapper>
      <ConditionalDragDropWrapper
        items={idOrder as string[]}
        handleDragEnd={handleDragEnd}
        isDragDroppable={!!isDragDroppable}
        handleDragOver={handleDragOver}
      >
        {tableHeader && <Heading textStyle="heading5">{tableHeader}</Heading>}
        <TableWrapper withPagination={withPagination} intent={intent}>
          {/* @todo: fix the typing below. `props` is overloaded */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <TableBase sticky={sticky} {...getTableProps()} {...props}>
            {showHeader && (
              <TableHead>
                {headerGroups.map((headerGroup, i) => {
                  return (
                    <TableRow
                      {...headerGroup.getHeaderGroupProps()}
                      intent={intent}
                      type="header"
                      key={i}
                    >
                      {isDragDroppable && (
                        <TableHeading key="-1">{null}</TableHeading>
                      )}
                      {/* FIXME: drop on first refactor */}
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {headerGroup.headers.map((column: any, i: number) => (
                        <TableHeading
                          key={i}
                          columnPadding={column.columnPadding}
                          position={column.position}
                          align={column.align}
                          css={column.css}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <AutoLayout
                            as="span"
                            spacing="1"
                            alignment={column?.align || "left"}
                          >
                            <Box as="span">{column.render("Header")}</Box>
                            {/* Only render the sort arrow if the column has a visible header name */}
                            {Boolean(column.render("Header")) &&
                              !column.disableSortBy && (
                                <SortSVG
                                  color="-neutral10"
                                  label="Sort"
                                  sort={
                                    column.isSorted
                                      ? column.isSortedDesc
                                        ? "desc"
                                        : "asc"
                                      : "none"
                                  }
                                />
                              )}
                          </AutoLayout>
                        </TableHeading>
                      ))}
                    </TableRow>
                  );
                })}
              </TableHead>
            )}
            <TableBody {...getTableBodyProps()}>
              {/* FIXME: drop on first refactor */}
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {sortedRows.map((row: any, i: number) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <ConditionalDragDropRow
                      rowId={row.original.id}
                      isDragDroppable={!!isDragDroppable}
                    >
                      <TableRow
                        {...rowProps}
                        {...((row.canExpand || allExpandable) &&
                          row.getToggleRowExpandedProps({
                            style: {
                              paddingLeft: `${row.depth * 2}rem`,
                            },
                            title: null,
                          }))}
                        intent={intent}
                        key={i}
                      >
                        {isDragDroppable && (
                          <TableCell dragDropHandle columnPadding="xs">
                            <IconDragAndDrop label="Drag and drop" />
                          </TableCell>
                        )}
                        {/* FIXME: drop on first refactor */}
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {row.cells.map((cell: any, i: number) => (
                          <TableCell
                            {...cell.getCellProps()}
                            key={i}
                            columnPadding={cell.column.columnPadding}
                            align={cell.column.align}
                            canSort={cell.column.canSort}
                            nowrap={cell.column.nowrap}
                            position={cell.column.position}
                            css={cell.column.cellCss || cell.column.css}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    </ConditionalDragDropRow>
                    {/** If renderRowSubComponent function was passed, we use that to render custom subrows, if not subrow will be rendered if there is subRow array in data object */}
                    {row.isExpanded &&
                      typeof renderRowSubComponent === "function" &&
                      renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </React.Fragment>
                );
              })}
            </TableBody>
            {showFooter && (
              <TableFooter>
                {footerGroups.map((group, i) => (
                  <TableRow
                    {...group.getFooterGroupProps()}
                    key={i}
                    intent={intent}
                    type="footer"
                  >
                    {isDragDroppable && <TableCell key="-1">{null}</TableCell>}
                    {/* FIXME: drop on first refactor */}
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {group.headers.map((column: any, i: number) => (
                      <TableCell
                        {...column.getFooterProps()}
                        key={i}
                        columnPadding={column.columnPadding}
                        canSort={column.canSort}
                        align={column.align}
                        nowrap={column.nowrap}
                        position={column.position}
                        css={column.cellCss || column.css}
                      >
                        {column.render("Footer")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableFooter>
            )}
          </TableBase>
        </TableWrapper>
      </ConditionalDragDropWrapper>
    </TableOverflowWrapper>
  );
}

export function ExpanderCell<
  T extends ColumnInput = ColumnInput
>(): ColumnShape<T> {
  return {
    Header: " ",
    id: "expander",
    preventToggle: true,
    accessor: () => "",
    disableSortBy: true,
    css: { paddingRight: "0", width: "$sizes$xl" },
    // @ts-ignore: `isExpanded` is there, but is missing from the signatures
    Cell: ({ row: { isExpanded } }) => <Expander isExpanded={isExpanded} />,
  } as ColumnShape<T>;
}
