import { gql, useQuery, ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";

const getKillInvoicesV1Flag = gql`
  query featureFlags {
    featureFlags {
      killInvoicesV1
    }
  }
`;

const QuickBooksButton = ({ client, lineItemCount, sendInvoice }) => {
  const { data } = useQuery(getKillInvoicesV1Flag);

  const orgFlagOn = Boolean(data?.featureFlags["killInvoicesV1"]);

  if (!data || orgFlagOn) return null;

  if (!client) {
    return <div>Add a client to send to Quickbooks</div>;

    // Quickbooks won't accept invoices without a line item,
    // show the warning and remove button if they have no line items
  }
  if (lineItemCount === 0) {
    return <div>Add a line item to send to Quickbooks</div>;
  }
  return (
    <button className="invoice-controls__button" onClick={sendInvoice}>
      Send to QuickBooks
    </button>
  );
};

export const QuickBooksButtonWrapper = ({
  client,
  lineItemCount,
  sendInvoice,
}) => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <QuickBooksButton
        client={client}
        lineItemCount={lineItemCount}
        sendInvoice={sendInvoice}
      />
    </ApolloProvider>
  );
};
