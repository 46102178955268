import InvoiceLineItem from "Components/invoices/InvoiceLineItem";
import Select from "Components/blocks/Select";
import ReactSelect from "react-select";
import React from "react";
import moment from "moment";
import autosize from "autosize";
import ReactSortable from "react-sortablejs";
import cx from "classnames";
import util from "Blocks/utils/util";
import InvoiceSwitchVersion from "./InvoiceSwitchBanner";
import InvoiceAssignColumn from "./InvoiceAssignColumn";
import InvoiceAssignmentWarning from "./InvoiceAssignmentWarning";

export const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    border: "1px solid #dfe2e4",
    boderColor: state.isSelected && "#a0a8ae",
    borderRadius: ".25em",
    boxShadow: state.isFocused ? "none" : "none",
    height: "2.5rem",
    width: "100%",
    transition: "all .1s ease-in-out",
    outline: state.isFocused || state.isSelected ? "none" : "",
    "&:hover": {
      borderColor: "#a0a8ae",
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    outline: state.isFocused || state.isSelected ? "none" : "",
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "none" : "none",
    borderRadius: ".25em",
    boxShadow: "none",
    height: "100%",
    outline: state.isFocused || state.isSelected ? "none" : "",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused
      ? "rgba(255, 190,	11, .75)"
      : state.isSelected
      ? "rgba(255, 190,	11, 1)"
      : state.isActive
      ? "rgba(255, 190,	11, .85)"
      : "#fff",
    color: state.isFocused || state.isSelected ? "white" : "black",
    cursor: "pointer",
  }),
};

export default class InvoiceEditor extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.lineItemsSum = this.lineItemsSum.bind(this);
    this.renderClientAddress = this.renderClientAddress.bind(this);
    this.renderClientName = this.renderClientName.bind(this);
    this.updateTerm = this.updateTerm.bind(this);
    this.updateIssueDate = this.updateIssueDate.bind(this);
    this.checkForOrganizationAddress =
      this.checkForOrganizationAddress.bind(this);
  }

  componentWillUnmount() {
    autosize.destroy(document.querySelectorAll("textarea"));
  }

  update(event) {
    this.props.update({
      [event.target.name]: event.target.value,
    });
  }

  updateTerm(data) {
    const term = this.props.organizationTerms.find(
      (term) => term.id === data.term_id
    );

    // If the the term has a length in days, apply it to the due_date.
    if (term && term.days !== null) {
      const newDueDate = moment(this.props.invoice.issue_date)
        .add(term.days, "days")
        .format("YYYY-MM-DD");
      this.props.update({ due_date: newDueDate, ...data });
    } else {
      this.props.update(data);
    }
  }

  updateIssueDate(event) {
    const issueDate = event.target.value;
    const term = this.props.organizationTerms.find(
      (term) => term.id === this.props.invoice.term_id
    );

    if (term && term.days) {
      const newDueDate = moment(issueDate)
        .add(term.days, "days")
        .format("YYYY-MM-DD");
      this.props.update({
        issue_date: issueDate,
        due_date: newDueDate,
      });
    } else {
      this.props.update({ issue_date: issueDate });
    }
  }

  lineItemsSum() {
    return this.props.lineItems.reduce((sum, li) => {
      if (Number.isNaN(parseFloat(li.amount))) {
        return sum;
      }
      return sum + parseFloat(li.amount);
    }, 0);
  }

  checkForOrganizationAddress() {
    return [
      "city",
      "country",
      "line_1",
      "line_2",
      "parcel",
      "state",
      "zipcode",
    ].some((column) => {
      return (
        this.props.organizationAddress[column] !== null &&
        this.props.organizationAddress[column] !== ""
      );
    });
  }

  phaseName(phaseId) {
    // TODO: phase -> assignment
    const phase = this.props.assignments.find(
      (phase) => phase.id === String(phaseId)
    );
    if (phase) {
      return phase.name;
    }
    return null;
  }

  renderLogo() {
    if (this.props.editable && !this.props.organizationLogo) {
      return (
        <div
          className="invoice-header__logo-placeholder"
          onClick={this.props.openLogoModal}
        >
          <span>Upload</span>
          <span>a Logo</span>
        </div>
      );
    }
    return (
      <a href="/settings" target="_blank">
        <img
          className="invoice-header__image"
          src={this.props.organizationLogo}
        />
      </a>
    );
  }

  // Almost identical to #name method in backend.
  // Keep for now and then this logic will go away when there's no more invoices 1.0
  renderClientName(client) {
    if (!client) {
      return null;
    }
    if (client.display_name) {
      return client.display_name;
    }
    if (client.organization_name && client.lname) {
      return `${client.organization_name}, ${client.fname} ${client.lname}`;
    }
    if (client.organization_name) {
      return `${client.organization_name}`;
    }
    return `${client.fname} ${client.lname}`;
  }

  renderClientAddress() {
    // If project already has a client and a client address
    // -> Render project client address

    // If has >= 1 clients
    // -> Render client list and add client button

    // If organization has 0 clients
    // -> Render client button
    const clientName = this.renderClientName(this.props.client);
    if (this.props.editable) {
      if (this.props.client) {
        // Invoice has a client and a client address
        return (
          <div className="invoice-group invoice-group--fourTenths">
            <div className="invoice-group__label">
              <span className="invoice-group__text">Invoice To</span>
              <span
                className="invoice-group__link"
                onClick={() => this.props.assignClient({ client_id: null })}
              >
                Clear
              </span>
              <span
                className="invoice-group__link"
                onClick={this.props.openEditClientModal}
              >
                Edit
              </span>
            </div>
            <div className="invoice-group__input invoice-group__input--client-address is-uneditable">
              <div>
                {this.props.client.fname} {this.props.client.lname}
              </div>

              <div>{this.props.client.organization_name}</div>

              <div>{this.props.client.department}</div>

              {this.props.clientAddress && (
                <>
                  <div>{this.props.clientAddress.line_1}</div>
                  <div>{this.props.clientAddress.line_2}</div>
                  <div>
                    {this.props.clientAddress.city},{" "}
                    {this.props.clientAddress.state}{" "}
                    {this.props.clientAddress.zipcode}
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }
      if (this.props.organizationClients?.length > 0) {
        // Organization has >= 1 clients
        return (
          <div className="invoice-group invoice-group--fourTenths">
            <div className="invoice-group__label">Invoice To</div>
            <div className="invoice-group__badge">
              {this.renderClientsDropdown()}
              <span className="invoice-group__divider">Or</span>
              <a
                className="invoice-group__button"
                onClick={this.props.openNewClientModal}
              >
                <span className="invoice-group__button-text invoice-group__button-text--create">
                  New Client
                </span>
                <span className="invoice-group__button-icon invoice-group__button-icon--create" />
              </a>
            </div>
          </div>
        );
      }
      // Organization has 0 clients
      return (
        <div className="invoice-group invoice-group--fourTenths">
          <div className="invoice-group__label">Invoice To</div>
          <div className="invoice-group__badge">
            <a
              className="invoice-group__button"
              onClick={this.props.openIntegrationModal}
            >
              <span className="invoice-group__button-text invoice-group__button-text--connect">
                Connect
              </span>
              <span className="invoice-group__button-icon invoice-group__button-icon--quickbooks" />
              {/* <span className="invoice-group__button-icon invoice-group__button-icon--xero"></span> */}
              <span className="invoice-group__button-icon invoice-group__button-icon--connect" />
            </a>
            <span className="invoice-group__divider">Or</span>
            <a
              className="invoice-group__button"
              onClick={this.props.openNewClientModal}
            >
              <span className="invoice-group__button-text invoice-group__button-text--create">
                New Client
              </span>
              <span className="invoice-group__button-icon invoice-group__button-icon--create" />
            </a>
          </div>
        </div>
      );
    }
    if (this.props.client) {
      return (
        <div className="invoice-group invoice-group--oneHalf">
          <div className="invoice-group__label">
            <span className="invoice-group__text">Invoice To</span>
          </div>
          <div className="invoice-group__input is-uneditable">
            <div>
              {this.props.client.fname} {this.props.client.lname}
            </div>

            <div>{this.props.client.organization_name}</div>

            <div>{this.props.client.department}</div>
            {this.props.clientAddress && (
              <>
                <div>{this.props.clientAddress.line_1}</div>
                <div>{this.props.clientAddress.line_2}</div>
                <div>
                  {this.props.clientAddress.city},{" "}
                  {this.props.clientAddress.state}{" "}
                  {this.props.clientAddress.zipcode}
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
    return <div className="invoice-group invoice-group--oneHalf" />;
  }

  renderClientsDropdown() {
    const clientOptions = this.props.organizationClients
      ?.map((client) => {
        return {
          value: client.id,
          label: this.renderClientName(client),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <ReactSelect
        styles={selectStyles}
        isClearable
        name="client_id"
        placeholder="Select Client..."
        options={clientOptions}
        onChange={this.props.assignClient}
      />
    );
  }

  renderTerms() {
    if (!this.props.editable && this.props.invoice.term_id) {
      return (
        <div className="invoice-group">
          <div className="invoice-group__label">Terms</div>
          <div className="invoice-group__input">
            {this.props.invoice.term_id &&
            this.props.organizationTerms.find(
              (term) => term.id === this.props.invoice.term_id
            )
              ? this.props.organizationTerms.find(
                  (term) => term.id === this.props.invoice.term_id
                ).name
              : "No term assigned"}
          </div>
        </div>
      );
    }
  }

  renderLineItems() {
    if (this.props.editable) {
      return (
        <ReactSortable
          tag="ul"
          className="invoice-list__content"
          onChange={this.props.updateLineItemOrder}
          options={{
            handle: ".js-handle",
          }}
        >
          {this.props.lineItems.map((lineItem) => (
            <InvoiceLineItem
              key={lineItem.id}
              lineItem={lineItem}
              editable
              phaseName={this.phaseName(lineItem.phase_id)}
              update={this.props.updateLineItem}
              delete={this.props.deleteLineItem}
              assignments={this.props.assignments}
            />
          ))}
        </ReactSortable>
      );
    }
    // Don't show empty line items in non-editable views
    const lineItemsWithContent = this.props.lineItems.filter((lineItem) => {
      return (
        lineItem.type ||
        lineItem.description ||
        lineItem.quantity ||
        lineItem.unit_price ||
        lineItem.amount
      );
    });
    return lineItemsWithContent.map((lineItem) => (
      <InvoiceLineItem
        key={lineItem.id}
        lineItem={lineItem}
        editable={false}
        phaseName={this.phaseName(lineItem.phase_id)}
      />
    ));
  }

  renderMessage() {
    if (this.props.editable) {
      return (
        <div className="invoice-group invoice-group--oneHalf">
          <div className="invoice-group__label">Message</div>
          <textarea
            className="invoice-group__input invoice-group__input--textarea"
            defaultValue={this.props.invoice.message}
            name="message"
            onChange={this.update}
          />
        </div>
      );
    }
    if (this.props.invoice.message) {
      return (
        <div className="invoice-group invoice-group--oneHalf">
          <div className="invoice-group__label">Message</div>
          <div className="invoice-group__input invoice-group__input--textarea">
            {this.props.invoice.message}
          </div>
        </div>
      );
    }
  }

  render() {
    const { editable, lineItems, allowsVersionSwitch } = this.props;

    return (
      <>
        <article
          className={cx("invoice", "js-invoice", {
            "is-editable": editable,
          })}
          id="invoice"
          style={{ maxWidth: this.props.showToggle ? "960px" : "100%" }}
        >
          <header className="invoice-header">
            {this.checkForOrganizationAddress() ? (
              <a
                href="/settings"
                target="_blank"
                className="invoice-header__info"
              >
                <div>
                  <strong>{this.props.organizationName}</strong>
                </div>
                <div>{this.props.organizationAddress.line_1}</div>
                <div>{this.props.organizationAddress.line_2}</div>
                <div>
                  {this.props.organizationAddress.city},{" "}
                  {this.props.organizationAddress.state}{" "}
                  {this.props.organizationAddress.zipcode}
                </div>
              </a>
            ) : (
              <div className="invoice-header__info">
                <div
                  className="invoice-header__info-placeholder"
                  onClick={this.props.openNewAddressModal}
                >
                  <span>Add Your</span>
                  <span>Address</span>
                </div>
              </div>
            )}
            <div className="invoice-header__logo">{this.renderLogo()}</div>
          </header>
          <section className="invoice-details">
            <div className="invoice-details__header">
              <div
                className="invoice-details__title"
                contentEditable={!editable}
                suppressContentEditableWarning
              >
                Invoice
              </div>
              {editable ? (
                <div className="invoice-details__group">
                  #
                  <input
                    defaultValue={this.props.invoice.number}
                    name="number"
                    onChange={this.update}
                    className="invoice-details__input"
                  />
                </div>
              ) : (
                <div className="invoice-details__number">
                  #{this.props.invoice.number}
                </div>
              )}
            </div>
            <div className="invoice-details__content">
              {this.renderClientAddress()}
              <div className="invoice-group invoice-group--issue-date">
                <div className="invoice-group__label">Issue Date</div>
                {editable ? (
                  <>
                    <input
                      type="date"
                      className="invoice-group__input"
                      value={this.props.invoice.issue_date}
                      name="issue_date"
                      onChange={this.updateIssueDate}
                    />
                    <Select
                      label="Select Terms"
                      items={this.props.organizationTerms.map((term) => ({
                        value: term.id,
                        name: term.name,
                      }))}
                      onSelect={this.updateTerm}
                      selectedValue={
                        this.props.organizationTerms.find(
                          (term) => term.id === this.props.invoice.term_id
                        )
                          ? this.props.invoice.term_id
                          : null
                      }
                      keyName="term_id"
                      size="small"
                      reset
                    />
                  </>
                ) : (
                  <div className="invoice-group__input">
                    {this.props.invoice.issue_date}
                  </div>
                )}
              </div>
              <div className="invoice-group invoice-group--due-date">
                <div className="invoice-group__label">Due Date</div>
                {editable ? (
                  <input
                    type="date"
                    className="invoice-group__input"
                    value={this.props.invoice.due_date}
                    name="due_date"
                    onChange={this.update}
                  />
                ) : (
                  <div className="invoice-group__input">
                    {this.props.invoice.due_date}
                  </div>
                )}
              </div>
              {this.renderTerms()}
            </div>
          </section>
          {editable && <InvoiceAssignmentWarning lineItems={lineItems} />}
          <section className="invoice-content">
            <div className="invoice-list__header">
              {editable && <InvoiceAssignColumn />}
              <div className="invoice-list__column invoice-list__column--type">
                Type
              </div>
              <div className="invoice-list__column invoice-list__column--description">
                Description
              </div>
              <div className="invoice-list__column invoice-list__column--quantity">
                QTY
              </div>
              <div className="invoice-list__column invoice-list__column--unit-price">
                Unit Price
              </div>
              <div className="invoice-list__column invoice-list__column--amount">
                Amount
              </div>
            </div>
            {this.renderLineItems()}
            <div className="invoice-list__footer">
              <div className="invoice-list__actions">
                {editable && (
                  <button
                    className="invoice-list__button"
                    onClick={this.props.createLineItem}
                  >
                    Line Item
                  </button>
                )}
              </div>
              <div className="invoice-list__totals">
                <div className="invoice-list__total">
                  <div className="invoice-list__title">Total</div>
                  <div className="invoice-list__value">
                    {util.toCurrencyWithCents(this.lineItemsSum())}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="invoice-footer">
            <div className="invoice-footer__content">
              {this.renderMessage()}
            </div>
          </footer>
        </article>
        {editable && allowsVersionSwitch && (
          <InvoiceSwitchVersion slug={this.props.invoice.slug} />
        )}
      </>
    );
  }
}
